var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseService } from '../base/base.service';
import { isNullOrUndefined } from 'util';
import { HttpClient } from '@angular/common/http';
import { ErrorModalProvider } from '../base/error/error.modal.service';
import { catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../base/error/error.modal.service";
var ResetPasswordService = /** @class */ (function (_super) {
    __extends(ResetPasswordService, _super);
    function ResetPasswordService(httpClient, errorModalProvider) {
        return _super.call(this, httpClient, errorModalProvider) || this;
    }
    ResetPasswordService.prototype.sendRest = function (email, idNum) {
        var postData;
        if (!isNullOrUndefined(idNum)) {
            postData = {
                email: email,
                id_number: idNum
            };
        }
        else {
            postData = {
                email: email
            };
        }
        return this.httpClient.post('/dl/data/sendReset', postData, this.httpOptions(false)).pipe(catchError(this.handleHTTPError()));
    };
    ResetPasswordService.prototype.resetPassword = function (data) {
        return this.httpClient.post('/dl/data/setPassword', data, this.httpOptions(false)).pipe(catchError(this.handleHTTPError()));
    };
    ResetPasswordService.ngInjectableDef = i0.defineInjectable({ factory: function ResetPasswordService_Factory() { return new ResetPasswordService(i0.inject(i1.HttpClient), i0.inject(i2.ErrorModalProvider)); }, token: ResetPasswordService, providedIn: "root" });
    return ResetPasswordService;
}(BaseService));
export { ResetPasswordService };
