var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ErrorModalProvider } from '../base/error/error.modal.service';
import { ErrorVO } from '../base/error/error.vo';
import { SpDataService } from './sp.data.service';
import { of, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { SpDisclosuresProvider } from './sp.disclosures.provider.service';
import { BaseService } from '../base/base.service';
import { Log } from 'ng2-logger/browser';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./sp.data.service";
import * as i3 from "../base/error/error.modal.service";
import * as i4 from "./sp.disclosures.provider.service";
var log = Log.create('sp-service');
var SpBaseService = /** @class */ (function (_super) {
    __extends(SpBaseService, _super);
    function SpBaseService(httpClient, spDataService, errorModalProvider, disclosuresProvider) {
        var _this = _super.call(this, httpClient, errorModalProvider) || this;
        _this.httpClient = httpClient;
        _this.spDataService = spDataService;
        _this.errorModalProvider = errorModalProvider;
        _this.disclosuresProvider = disclosuresProvider;
        return _this;
    }
    SpBaseService.prototype.handleHTTPError = function (heading, result) {
        var _this = this;
        return function (error) {
            var vo = ErrorVO.toErrorVO(error);
            if (result == null) {
                _this.errorModalProvider.showError(vo, heading);
                _this.spDataService.continueBusy = false;
                _this.disclosuresProvider.stopSavingSpinner();
                return throwError(vo);
            }
            return of(result);
        };
    };
    SpBaseService.prototype.sendNavigation = function (step, code) {
        var url = '/dl/data/sp/' + this.spDataService.getSalesProcessID() + '/saveNavigation';
        this.httpClient.post(url, { 'step': step, 'code': code }, this.httpOptions()).subscribe();
    };
    return SpBaseService;
}(BaseService));
export { SpBaseService };
var SalesProcessService = /** @class */ (function (_super) {
    __extends(SalesProcessService, _super);
    function SalesProcessService(httpClient, spDataService, errorModalProvider, disclosuresProvider) {
        return _super.call(this, httpClient, spDataService, errorModalProvider, disclosuresProvider) || this;
    }
    SalesProcessService.prototype.load = function (step, expired) {
        var _this = this;
        if (this.spDataService.getSalesProcessID() == null) {
            log.warn('no sp id');
            return of(null);
        }
        var url = '/dl/data/sp/' + this.spDataService.getSalesProcessID() + '/' + this.spDataService.getMode() + '/' + step;
        if (expired) {
            url += '?expired=true';
        }
        log.info('sp load data url: ' + url);
        return this.httpClient.get(url).pipe(tap(function (pageData) {
            _this.spDataService.updateBaseData(pageData);
        }), catchError(this.handleHTTPError()));
    };
    SalesProcessService.ngInjectableDef = i0.defineInjectable({ factory: function SalesProcessService_Factory() { return new SalesProcessService(i0.inject(i1.HttpClient), i0.inject(i2.SpDataService), i0.inject(i3.ErrorModalProvider), i0.inject(i4.SpDisclosuresProvider)); }, token: SalesProcessService, providedIn: "root" });
    return SalesProcessService;
}(SpBaseService));
export { SalesProcessService };
var SalesProcessResolver = /** @class */ (function () {
    function SalesProcessResolver(salesProcessService) {
        this.salesProcessService = salesProcessService;
    }
    SalesProcessResolver.prototype.resolve = function (route, state) {
        if (route.queryParams != null && route.queryParams.expired === 'true') {
            return this.salesProcessService.load(route.data.step, true);
        }
        return this.salesProcessService.load(route.data.step);
    };
    SalesProcessResolver.ngInjectableDef = i0.defineInjectable({ factory: function SalesProcessResolver_Factory() { return new SalesProcessResolver(i0.inject(SalesProcessService)); }, token: SalesProcessResolver, providedIn: "root" });
    return SalesProcessResolver;
}());
export { SalesProcessResolver };
