import {Injectable} from '@angular/core';

/**
 * Nice way to get to the window object on the dom
 */
function _window(): any {
    return window;
}

@Injectable({
  providedIn: 'root'
})
export class WindowRef {

    get nativeWindow(): any {
        return _window();
    }
}
