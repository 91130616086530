<div class="dl-benefit">
    <div *ngIf="!isEnhancement()">
        <div class="dl-row row">
            <div class="dl-col-md-12 text-center dl-toggle-calc col s12">
                <label>Move the slider to change your </label>
                <br class="dl-hidden-lg dl-hidden-md dl-hidden-sm hide-on-med-and-up">
                <mat-radio-group [value]="benefit.coverOrPremium" [(ngModel)]="benefit.coverOrPremium">
                    <mat-radio-button [value]="'Cover'">Cover</mat-radio-button>
                    <label> or </label>
                    <mat-radio-button [value]="'Premium'">Premium</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div class="dl-row row">
            <div class="dl-col-sm-1 dl-hidden-xs text-center col m1 hide-on-small-and-down">
                <button mat-raised-button (click)="decrement()" style="min-width: 0;" [attr.kings-watch]="'7STEP-PRODUCT-' + benefit!.name.replaceAll(' ', '') + '-amount-decrement'"><span class="fa fa-minus"></span></button>
            </div>
            <div class="dl-col-sm-10 col m10 s12" style="min-height: 30px;">
                <mat-slider
                        *ngIf="isCover()"
                        [displayWith]="formatCoverForDisplay"
                        [invert]="false"
                        [max]="escalatedCover(benefit.maxCover)"
                        [min]="escalatedCover(benefit.minCover)"
                        [step]="escalatedCover(benefit.coverIncrements)"
                        [thumbLabel] = "true"
                        [value]="benefit.cover"
                        (input)="updateCover($event)">
                </mat-slider>
                <mat-slider
                        *ngIf="isPremium()"
                        [displayWith]="formatPremiumForDisplay"
                        [invert]="false"
                        [max]="escalatedPremium(benefit.maxPremium)"
                        [min]="escalatedPremium(benefit.minPremium)"
                        [step]="escalatedPremium(benefit.premiumIncrements)"
                        [thumbLabel] = "true"
                        [value]="benefit.premium"
                        (input)="updatePremium($event)">
                </mat-slider>
                <div *ngIf="coverSweetSpot || coverSweetSpot">
                    <div class="dl-sweet-spot" [ngStyle]="getSweetSpotStyle()"></div>
                </div>
            </div>
            <div class="dl-col-sm-1 dl-hidden-xs text-center col m1 hide-on-small-and-down">
                <button mat-raised-button (click)="increment()" style="min-width: 0;" [attr.kings-watch]="'7STEP-PRODUCT-' + benefit!.name.replaceAll(' ', '') + '-amount-increment'"><span class="fa fa-plus"></span></button>
            </div>
        </div>
        <div class="dl-row row">
            <div class="dl-col-sm-1 dl-hidden-xs col m1 hide-on-small-and-down"></div>
            <div class="dl-col-sm-10 col m10 s12">
                <label class="min" *ngIf="isCover()">{{escalatedCover(benefit.minCover) | dl_currency:true:0}}</label>
                <label class="min" *ngIf="isPremium()">{{escalatedPremium(benefit.minPremium) | dl_currency:true:0}}</label>
                <label class="max" *ngIf="isCover()">{{escalatedCover(benefit.maxCover) | dl_currency:true:0}}</label>
                <label class="max" *ngIf="isPremium()">{{escalatedPremium(benefit.maxPremium) | dl_currency:true:0}}</label>
            </div>
        </div>
        <div class="dl-row dl-hidden-lg dl-hidden-md dl-hidden-sm row hide-on-med-and-up">
            <div class="dl-col-xs-6 col s6">
                <button mat-raised-button (click)="decrement()"><span class="fa fa-minus"></span></button>
            </div>
            <div class="dl-col-xs-6 col s6">
                <button mat-raised-button (click)="increment()" class="dl-pull-right right"><span class="fa fa-plus"></span></button>
            </div>
        </div>
        <div *ngIf="benefit.features" class="dl-features">
            <ng-container *ngFor="let feature of benefit.features">
                <div *ngIf="feature.type == 'escalation_options'" class="dl-row row dl-feature">
                    Premium escalates at <strong class="premium-escalation-value">{{feature.params.premiumIncrease.displayValue}}</strong>
                    and Cover escalates at <strong class="cover-escalation-value">{{feature.params.sumAssuredIncrease.displayValue}}</strong>
                    &nbsp;<a href="" class="edit-escalations" *ngIf="feature.editable && isCover()" (click)="editFeature(feature)">Change <i class="fa fa-pencil"></i></a>
                </div>
            </ng-container>
        </div>
        <div *ngIf="benefit.addons" class="dl-addons">
            <ng-container *ngFor="let addon of benefit.addons">
                <div *ngIf="addon.premium >= 1" class="dl-row row dl-addon">
                    <div class="col-md-12 col s12">
                        <span (click)="toggleAddon(addon)">
                            <span class="fa {{addon.added ? 'fa-check-square-o' : 'fa-square-o'}} dl-addon-check"></span>
                            <strong>{{addon.name}}</strong><span *ngIf="addon.cover > 0"> of {{addon.cover | dl_currency:true:0}}</span> for {{addon.premium | dl_currency:true:0}} p.m.
                        </span>
                        <span
                                #tooltip="matTooltip"
                                matTooltip="{{addon.description}}"
                                [matTooltipPosition]="'left'"
                                [matTooltipClass]="'info-popup'"
                                (click)="tooltip.toggle()"
                                class="fa fa-question dl-pull-right right"></span>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div *ngIf="isEnhancement()">
        <div class="dl-row row">
            <div class="dl-col-sm-12 col s12">
                <strong>{{benefit.name}}</strong> for {{benefit.premium | dl_currency:true:0}} p.m.
            </div>
        </div>
    </div>
</div>
