import {Dynamic} from './component';
import {FormControl, Validators} from '@angular/forms';
import {isNullOrUndefined} from 'util';
import {Component} from '@angular/core';
import { PageComponentVO } from '../page.data.vo';

@Component({
    selector: 'uk-postcode-input',
    templateUrl: 'uk.postcode.component.html'
})
export class UkPostcodeComponent extends Dynamic<PageComponentVO> {

    ukPostcode: FormControl = new FormControl('');

    ukPostalCodeRegex = '^([Gg][Ii][Rr] ?0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z]{2}[0-9]{1,2})' +
        '|(([A-Za-z][0-9][A-Za-z])|([A-Za-z]{2}[0-9]?[A-Za-z])))) ?[0-9][A-Za-z]{2})$';

    onChange(): void {
        this.component.value = this.ukPostcode.value;
        this.component.isValid = this.ukPostcode.valid;
        if (!isNullOrUndefined(this.change)) {
            this.change.emit(this.component.counter);
        }
    }

    getFormControl(): FormControl {
        let validators = [];
        // if the data is not coming from the server this cause a nasty error
        if (isNullOrUndefined(this.component.required) || this.component.required) {
            validators = [Validators.required, Validators.pattern(this.ukPostalCodeRegex)];
        } else {
            validators = [Validators.pattern(this.ukPostalCodeRegex)];
        }
        this.ukPostcode.setValidators(validators);
        return this.ukPostcode;
    }

    getErrorMessage(): string {
        return this.ukPostcode.hasError('required') ? 'You must enter a value' :
            this.ukPostcode.hasError('pattern') ? 'Invalid Code' :
                '';
    }
}
