import {Component} from '@angular/core';
import {Benefit} from '../base/benefit';
import {QqDynamicComponent} from './qq.dynamic.component';

/**
 * Created by corneliusbotha on 2017/03/29.
 */

@Component({
    selector: 'org-result-block',
    templateUrl: './dl.org.result.block.component.html'
})
export class OrgResultBlockComponent extends QqDynamicComponent {
    benefit: Benefit;
    causetext: string;

  public setBenefit(benefit: Benefit): void {
      this.benefit = benefit;
  }

  public setCauseText(cause: string): void {
      this.causetext = cause;
  }
}
