import { Dynamic } from './component';
import { Component } from '@angular/core';
import { BeneficiaryDetailVO, PageComponentVO } from '../page.data.vo';
import { isNullOrUndefined } from 'util';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RsaId, RsaIdValidationCallback, rsaIdDateOfBirthValidation, rsaIdValidation } from '../../base/rsa.id.validator';
import { minMaxValidation } from '../../base/min.max.validator';
import { SelectItem } from '../../base/select.vo';
import { DLUtil } from '../../base/dl.util';
import { PhoneNumberValidator } from '../../base/phone-number';
import { Log, Logger } from 'ng2-logger/client';

const log = Log.create('BeneficiariesComponent');
@Component({
    selector: 'beneficiaries',
    templateUrl: 'beneficiaries.component.html'
})
export class BeneficiariesComponent extends Dynamic<PageComponentVO> implements RsaIdValidationCallback {

    beneficiariesForm: FormGroup;

    beneficiaries: BeneficiaryDetailVO[];
    counter = 0;
    showTotalError = false;
    listWarnClass = '';
    total: number;

    editMode: boolean = false;
    editCounter: number;
    skipping = false;
    canSkip = false;
    hideId = false;
    hasEmail = false;
    featureSS3217_COMPULSORY_BENEFICIARY_ID: boolean = true;

    relationOptions: SelectItem[] = [
        { value: 'spouse', label: 'Spouse' },
        { value: 'child', label: 'Child' },
        { value: 'parent', label: 'Parent' },
        { value: 'estate', label: 'Estate' },
        { value: 'other', label: 'Other' }
    ];

    constructor(protected fb: FormBuilder) {
        super();
        this.buildForm();
    }

    selectionChange(id: string) {
        log.info("Value ID: " + id);
    }

    onIdValidationChange(rsaId: RsaId): void {
        if (this.featureSS3217_COMPULSORY_BENEFICIARY_ID)
            !rsaId.isValid() ? this.beneficiariesForm.controls['idDob'].setErrors({ 'invalid': true }) : this.beneficiariesForm.controls['idDob'].setErrors(null);
    }

    protected buildForm() {
        this.beneficiariesForm = this.fb.group({
            name: ['', Validators.required],
            relation: ['', Validators.required],
            idDob: ['', this.featureSS3217_COMPULSORY_BENEFICIARY_ID ? [Validators.required, rsaIdValidation(false, this)] : [rsaIdDateOfBirthValidation()]],
            percentage: ['', [
                Validators.required, minMaxValidation(1, 100),
                Validators.pattern('^(100|[0-9]{1,2})(\\.[0-9]{1,2})?$')
            ]],
            email: ['', [Validators.email, Validators.pattern(DLUtil.emailPattern)]],
            phone_number: ['', [Validators.required, PhoneNumberValidator.validator()]]
        });

        this.beneficiariesForm.controls.relation.valueChanges.subscribe((relationValue) => {
            this.changeFormEstateFields(relationValue);
        });
    }

    processExtraData(): void {
        // init the model
        if (isNullOrUndefined(this.component.value)) {
            this.component.value = [];
        }
        this.beneficiaries = this.component.value as BeneficiaryDetailVO[];
        this.counter = this.beneficiaries.length;
        this.total = 0;
        for (let i = 0; i < this.beneficiaries.length; i++) {
            this.beneficiaries[i].counter = i;
            // this is a work around as the percentage gets sent to the app as a string.
            let temp: string = this.beneficiaries[i].percentage.toString();
            this.beneficiaries[i].percentage = parseFloat(temp);
            this.total += this.beneficiaries[i].percentage;
        }
        this.validateTotal();
    }

    addItem(): void {
        let beneficiary;
        if (this.editMode) {
            beneficiary = this.beneficiaries.find(item => item.counter === this.editCounter);
            this.editMode = false;
        } else {
            beneficiary = new BeneficiaryDetailVO();
            beneficiary.counter = this.counter++;
            this.beneficiaries.push(beneficiary);
        }
        if (this.beneficiariesForm.get('relation').value === 'estate') {
            this.beneficiaries = [];
        }

        beneficiary.name = this.beneficiariesForm.get('name').value;
        beneficiary.personal_relation = this.beneficiariesForm.get('relation').value;
        if (!this.hideId) {
            beneficiary.idDob = this.beneficiariesForm.get('idDob').value;
        }
        if (this.hasEmail) {
            beneficiary.email = this.beneficiariesForm.get('email').value;
        }
        beneficiary.percentage = parseInt(this.beneficiariesForm.get('percentage').value);
        beneficiary.phone_number = this.beneficiariesForm.get('phone_number').value;
        beneficiary.email = this.beneficiariesForm.get('email').value;
        this.validateTotal();
        this.beneficiariesForm.reset();
    }

    removeItem(item: BeneficiaryDetailVO): void {
        // remove the item from the list.
        this.beneficiaries = this.beneficiaries.filter(ben => ben.counter !== item.counter);
        this.editMode = false;
        this.editCounter = null;
        this.validateTotal();
    }

    editItem(beneficiary: BeneficiaryDetailVO): void {
        this.editMode = true;
        this.editCounter = beneficiary.counter;
        this.beneficiariesForm.get('name').setValue(beneficiary.name);
        this.beneficiariesForm.get('relation').setValue(beneficiary.personal_relation);
        if (!this.hideId) {
            this.beneficiariesForm.get('idDob').setValue(beneficiary.idDob);
        }
        if (this.hasEmail) {
            this.beneficiariesForm.get('email').setValue(beneficiary.email);
        }
        this.beneficiariesForm.get('percentage').setValue(beneficiary.percentage);
        this.beneficiariesForm.get('phone_number').setValue(beneficiary.phone_number);
        this.beneficiariesForm.get('email').setValue(beneficiary.email);
    }

    validateTotal(): void {
        this.total = 0;
        for (let i = 0; i < this.beneficiaries.length; i++) {
            this.total += this.beneficiaries[i].percentage;
        }
        if (this.total !== 100) {
            this.showTotalError = true;
            this.listWarnClass = 'dsp-list-warn';
        } else {
            this.listWarnClass = '';
            this.showTotalError = false;
        }
        this.component.isValid = this.beneficiaries.length > 0 && !this.showTotalError;
        this.component.value = this.beneficiaries;
        if (!isNullOrUndefined(this.change)) {
            this.change.emit(this.component.counter);
        }
    }

    changeFormEstateFields(value) {
        log.info('Value: ' + value);
        switch (value) {
            case 'estate':
                this.beneficiariesForm.get('idDob').disable();
                this.beneficiariesForm.get('email').disable();
                this.beneficiariesForm.get('phone_number').disable();
                this.beneficiariesForm.get('percentage').setValue(100);
                this.beneficiariesForm.get('percentage').disable();
                break;
            default:
                this.beneficiariesForm.get('idDob').enable();
                this.beneficiariesForm.get('email').enable();
                this.beneficiariesForm.get('phone_number').enable();
                this.beneficiariesForm.get('percentage').enable();
        }
    }

    getFormControl(): FormControl {
        return null;
    }

    skip() {
        // this.skipping = true;
        // this.validated.emit({ success: true, componentId: this.component.id });
    }

    onChange(): void { }

    setupFromControl() { }
}
