import {Injectable} from '@angular/core';
import {DisclosuresService} from './disclosures.service';
import {ErrorModalProvider} from '../error/error.modal.service';
import {MatDialog, MatDialogRef} from '@angular/material';
import {DisclosuresCallback, DisclosuresModalComponent} from './disclosures-modal.component';
import {DisclosureCategory, DisclosureType, DisclosureVO} from './disclosures.vos';
import {isNullOrUndefined} from 'util';
import {WindowRef} from '../window.ref';

@Injectable({
  providedIn: 'root'
})
export class DisclosuresProvider {

  private dialogConfig: any = {
    disableClose: true,
    position: {top: '20px'},
    maxWidth: '99vw',
    panelClass: 'dl-modal'
  };
  private modalRef: MatDialogRef<DisclosuresModalComponent> = null;

  constructor(
    private disclosuresService: DisclosuresService,
    private errorModalProvider: ErrorModalProvider,
    private dialog: MatDialog,
    private windowRef: WindowRef
  ) {}

  public showDisclosure(title: string,
                        page: string,
                        category: DisclosureCategory,
                        data: any,
                        type: DisclosureType,
                        callback: DisclosuresCallback): void {
    this.getData(title, page, type, category, data, callback);
  }

  public close() {
    if (!isNullOrUndefined(this.modalRef)) {
      this.modalRef.close();
    }
  }

  private createModal(callback: DisclosuresCallback): void {
    if (isNullOrUndefined(this.modalRef) || isNullOrUndefined(this.modalRef.componentInstance)) {
      this.modalRef = this.dialog.open(DisclosuresModalComponent, this.dialogConfig);
    }
    this.modalRef.afterClosed().subscribe(() => {
      if (!isNullOrUndefined(this.modalRef)) {
        this.windowRef.nativeWindow.scrollTo(0, 0);
      }
      this.modalRef = null;
      if (!isNullOrUndefined(callback)) {
          callback.onDisclosuresClosed();
      }
    });
  }

  private getData(title: string,
                  page: string,
                  type: DisclosureType,
                  category: DisclosureCategory,
                  data: any,
                  callback: DisclosuresCallback,
                  spId?: string): void {
    if (isNullOrUndefined(data)) {
      data = {};
    }
    // add the sales process id to the data
    if (!isNullOrUndefined(spId) && spId !== '') {
      data.sales_process_id = spId;
    }
    // get the disclosures from the server
    this.disclosuresService.getDisclosureGroup(category, page, type, data).subscribe(response => {
      this.handleResult(title, page, type, category, callback, response.disclosures, spId);
    }, () => {
      if (!isNullOrUndefined(callback)) {
        callback.onDisclosuresFailed();
      }
    });
  }

  private setModalItems(title: string, page: string, type: string, category: DisclosureCategory, callback: DisclosuresCallback): void {
    this.modalRef.disableClose = true;
    this.modalRef.componentInstance.saving = false;
    if (isNullOrUndefined(title) || title.length === 0) {
      this.modalRef.componentInstance.title = DisclosuresModalComponent.DEFAULT_TITLE;
    } else {
      this.modalRef.componentInstance.title = title;
    }
    this.modalRef.componentInstance.prefix = category;
    this.modalRef.componentInstance.category = category;
    this.modalRef.componentInstance.page = page;
    this.modalRef.componentInstance.type = type;
    this.modalRef.componentInstance.callback = callback;
  }

  private handleResult(title: string,
                       page: string,
                       type: DisclosureType,
                       category: DisclosureCategory,
                       callback: DisclosuresCallback,
                       result: DisclosureVO[],
                       spId?: string): void {
    // if there is an SP id apply it to all disclosures
    if (!isNullOrUndefined(spId) && spId !== '') {
        result.forEach(dis => {dis.sales_process_id = spId});
    }
    // on entry already has modal open, the others we load data 1st then show modal
    if (isNullOrUndefined(this.modalRef)) {
      this.createModal(callback);
      callback.onDisclosuresLoaded();
      this.setModalItems(title, page, type, category, callback);
    }
    this.modalRef.componentInstance.disclosures = result;
    // remove the spinner. Needed when we load more than one disclosure.
    this.modalRef.componentInstance.saving = false;
    setTimeout(() => {
      this.windowRef.nativeWindow.document.querySelector('mat-dialog-content').scrollTop = 0;
    });
  }

}
