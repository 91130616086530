import {Component, Inject, OnInit} from '@angular/core';
import {ErrorModalDialog} from '../../base/error/error.modal.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {DotOrgDataService, ProjectVO} from './dot-org-data.service';

@Component({
    selector: 'dot-org-modal',
    templateUrl: './dot-org-modal.component.html',
    styleUrls: ['./dot-org-modal.component.scss']
})
export class DotOrgModalComponent implements OnInit {

    loading = true;
    projects: ProjectVO[];

    constructor(
        public dialogRef: MatDialogRef<ErrorModalDialog>,
        private dotOrgDataService: DotOrgDataService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    get title(): string {
        return this.data.title;
    }

    get blurb(): string {
        return this.data.blurb;
    }

    ngOnInit() {
        this.dotOrgDataService.getActiveProjects(this.data.area).subscribe(projects => {
            this.projects = projects;
            this.loading = false;
        });
    }

    choseProject(project: ProjectVO) {
        this.dialogRef.close(project);
    }
}
