var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/**
 * Confirmation info component.
 */
import { Injector, NgZone } from '@angular/core';
import { SpPage } from '../sp-page';
import { ConfirmationPageData, ConfirmationService } from './confirmation.service';
import { MatDialog, MatDialogRef, MatTabGroup } from '@angular/material';
import { Log } from 'ng2-logger/browser';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PasswordMatchValidator } from '../../base/passwordMatch.validator';
import { isNullOrUndefined } from 'util';
import { LoginService } from '../../login/login.service';
import { WindowRef } from '../../base/window.ref';
var log = Log.create("Confirmation" /* CONFIRMATION */);
var ConfirmationComponent = /** @class */ (function (_super) {
    __extends(ConfirmationComponent, _super);
    function ConfirmationComponent(injector, confirmationService, loginService, winRef, ngZone, dialog) {
        var _this = _super.call(this, injector) || this;
        _this.confirmationService = confirmationService;
        _this.loginService = loginService;
        _this.winRef = winRef;
        _this.ngZone = ngZone;
        _this.dialog = dialog;
        _this.showSetPassword = true;
        _this.buttonText = 'Set Password';
        _this.formSubmitted = false;
        _this.showCaseCreationOptions = false;
        _this.addressNotes = '';
        _this.caseNotes = '';
        _this.newIpDate = '';
        _this.data = new ConfirmationPageData();
        _this.formGroup = new FormGroup({
            password: new FormControl('', [
                Validators.required,
                Validators.pattern(PasswordMatchValidator.passwordPattern()),
            ]),
            confirmPassword: new FormControl('', [Validators.required]),
            reason: new FormControl(),
            createPostDocCase: new FormControl(false),
            createPostSaleCase: new FormControl(false),
            createMoveOfIpCase: new FormControl(false)
        }, PasswordMatchValidator.matchPasswords());
        _this.winRef.nativeWindow.spLoginWrapper = { component: _this, zone: _this.ngZone };
        return _this;
    }
    ConfirmationComponent.prototype.setPayload = function (data) {
        this.setTitle("Confirmation" /* CONFIRMATION */);
        this.data = data;
        if (!data.set_password || this.spDataService.isExternal() || this.spDataService.isEmbedded()) {
            this.showSetPassword = false;
            this.buttonText = 'Save';
            this.formGroup.get('password').disable();
            this.formGroup.get('confirmPassword').disable();
        }
        if (this.spDataService.isEmbedded()) {
            this.showCaseCreationOptions = true;
        }
    };
    ConfirmationComponent.prototype.onPostDocsChanged = function () {
        var _this = this;
        log.info('post doc clicked');
        if (this.getBooleanValueFromForm('createPostDocCase')) {
            log.info('post doc clicked and true');
            var dialogRef = this.dialog.open(NotesDialogComponent, {
                disableClose: true,
                position: { top: '20px' },
                maxWidth: '99vw',
                panelClass: 'dl-modal',
                data: { title: 'Please specify the address', notes: '' }
            });
            dialogRef.afterClosed().subscribe(function (result) {
                console.log('The dialog was closed: ' + result);
                _this.addressNotes = 'Address: \n' + result;
            });
        }
    };
    ConfirmationComponent.prototype.onPostSaleCaseChanged = function () {
        var _this = this;
        log.info('post sale clicked');
        if (this.getBooleanValueFromForm('createPostSaleCase')) {
            log.info('post sale clicked and true');
            var dialogRef = this.dialog.open(NotesDialogComponent, {
                disableClose: true,
                position: { top: '20px' },
                maxWidth: '99vw',
                panelClass: 'dl-modal',
                data: { title: 'What does the client need assistance with?', notes: '' }
            });
            dialogRef.afterClosed().subscribe(function (result) {
                console.log('The dialog was closed: ' + result);
                _this.caseNotes = result;
            });
        }
    };
    ConfirmationComponent.prototype.getWebStep = function () {
        return "Confirmation" /* CONFIRMATION */;
    };
    ConfirmationComponent.prototype.hasExitDisclosures = function () {
        return false;
    };
    ConfirmationComponent.prototype.continueToNext = function () {
        var _this = this;
        this.formGroup.disable();
        this.spDataService.continueBusy = true;
        this.formSubmitted = false;
        if (this.isReasonPopulated()) {
            this.confirmationService.saveReason(this.formGroup.get('reason').value).subscribe(function () {
                if (_this.showSetPassword) {
                    _this.setPassword();
                }
                else {
                    _this.spDataService.continueBusy = false;
                    _this.formSubmitted = true;
                }
            });
        }
        else if (this.showSetPassword) {
            this.setPassword();
        }
        if (this.getBooleanValueFromForm('createPostDocCase')) {
            log.info('creating post doc case');
            this.confirmationService.createPostDocCase(this.addressNotes).subscribe(function () {
                log.info('post doc case created');
            });
        }
        if (this.getBooleanValueFromForm('createPostSaleCase')) {
            log.info('creating post sale case');
            this.confirmationService.createPostSaleServiceCase(this.caseNotes).subscribe(function () {
                log.info('post sale case created');
            });
        }
    };
    ConfirmationComponent.prototype.getBooleanValueFromForm = function (formKey) {
        return this.formGroup.get(formKey).value;
    };
    ConfirmationComponent.prototype.setPassword = function () {
        var _this = this;
        this.loginService.setPassword({
            action: 'set',
            sales_process_id: this.spDataService.getSalesProcessID(),
            idnumber: this.data.id_number,
            username: this.data.username,
            password: this.formGroup.get('password').value,
        }).subscribe(function (res) {
            log.info('set password result', res);
            _this.spDataService.continueBusy = false;
            _this.formSubmitted = true;
            if (res.success) {
                log.info('successfully set password, calling signin');
                _this.signin(_this.data.username, _this.formGroup.get('password').value);
            }
            else if (!isNullOrUndefined(res.message)) {
                log.info('Error setting password', res.message);
            }
        });
    };
    ConfirmationComponent.prototype.signin = function (userName, password) {
        var _this = this;
        this.loginService.checkUser(userName, password).subscribe(function (res) {
            if (!isNullOrUndefined(_this.spDataService.getPageData().baseAuthUrl)
                && _this.spDataService.getPageData().baseAuthUrl.length > 1) {
                _this.loginService.loginToOrg(_this.spDataService.getPageData().baseAuthUrl, _this.spDataService.getPageData().oauthId, userName, password, _this)
                    .subscribe(function () { }, function () {
                    _this.processSignIn(false, 'Error communicating to the login server, please try again');
                });
            }
            else {
                // SG sign in.
                _this.processSignIn(true);
            }
        }, function () {
            _this.processSignIn(false, 'Error communicating to the login server, please try again');
        });
    };
    ConfirmationComponent.prototype.processSignIn = function (success, message) {
        if (success) {
            log.info('redirect to profile');
            this.winRef.nativeWindow.location = '/dl/profile';
        }
        else {
            log.info('there was an error', message);
            this.spDataService.continueBusy = false;
        }
    };
    ConfirmationComponent.prototype.getExitDisclosureData = function () {
        return null;
    };
    ConfirmationComponent.prototype.getDisclosureTitle = function (type) {
        return null;
    };
    ConfirmationComponent.prototype.disableContinue = function () {
        if (this.formGroup.invalid || this.formSubmitted) {
            return true;
        }
        // if password disabled there must be a value in the reason
        return (this.formGroup.get('password').disabled && !this.isReasonPopulated());
    };
    ConfirmationComponent.prototype.isReasonPopulated = function () {
        return !isNullOrUndefined(this.formGroup.get('reason').value) && this.formGroup.get('reason').value.length > 0;
    };
    Object.defineProperty(ConfirmationComponent.prototype, "passwordHelpText", {
        get: function () {
            return PasswordMatchValidator.passwordPatternHelpText();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfirmationComponent.prototype, "embeded", {
        get: function () {
            return this.spDataService.isExternal() || this.spDataService.isEmbedded();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfirmationComponent.prototype, "hasDifferentOrg", {
        get: function () {
            return !isNullOrUndefined(this.data.org_opt_in);
        },
        enumerable: true,
        configurable: true
    });
    return ConfirmationComponent;
}(SpPage));
export { ConfirmationComponent };
var NotesDialogComponent = /** @class */ (function () {
    function NotesDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    NotesDialogComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    return NotesDialogComponent;
}());
export { NotesDialogComponent };
