import {AbstractControl, ValidatorFn} from '@angular/forms';

export class PasswordMatchValidator {

    static matchPasswords(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (control.get('confirmPassword').disabled) {
                return null;
            }
            if (control.get('confirmPassword').value === control.get('password').value) {
                control.get('confirmPassword').setErrors(null);
                return null;
            }
            if (control.get('confirmPassword').dirty) {
                control.get('confirmPassword').setErrors({passwordsMatch: false});
            }
            return {passwordsMatch: false};
        };
    }

    static passwordPattern(): RegExp {
        return /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[?!@#$%^&*()_+|\\\/~`'']).{8,20})$/;
    }

    static passwordPatternHelpText(): string {
        return 'This should have a minimum length of 8, a maximum length of 20, ' +
            'include an upper and lower case letter, a digit and a symbol (like ! @ #  % ^ &)';
    }

}
