var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Dynamic } from './component';
import { OnInit } from '@angular/core';
import { DspService } from '../dsp.service';
import { DLUtil } from '../../base/dl.util';
var LLCMainComponent = /** @class */ (function (_super) {
    __extends(LLCMainComponent, _super);
    function LLCMainComponent(dspService) {
        var _this = _super.call(this) || this;
        _this.dspService = dspService;
        _this.coverOrPremium = 'Cover';
        return _this;
    }
    LLCMainComponent.prototype.setupFromControl = function () {
        this.component.loaded = true;
        // tel my parent I'm loaded.
        this.loaded.emit(this.component.id);
    };
    Object.defineProperty(LLCMainComponent.prototype, "ptyName", {
        get: function () {
            return this._show_options;
        },
        set: function (val) {
            this._show_options = val;
        },
        enumerable: true,
        configurable: true
    });
    LLCMainComponent.prototype.initialSliderValues = function () {
        if (this.val.options[0].length > 1) {
            var initial = Math.ceil(this.val.options[0].length * 1);
            this.val.premium_amount = this.val.options[0][initial - 1].premium_amount;
            this.val.benefit_amount = this.val.options[0][initial - 1].benefit_amount;
            this.previousCoverSelection_benefit_amount = this.val.benefit_amount;
            this.previousCoverSelection_premium_amount = this.val.premium_amount;
            var initialP = Math.ceil(this.val.options[1].length * 1);
            this.previousPremiumSelection_benefit_amount = this.val.options[1][initialP - 1].benefit_amount;
            this.previousPremiumSelection_premium_amount = this.val.options[1][initialP - 1].premium_amount;
        }
    };
    LLCMainComponent.prototype.ngOnInit = function () {
        // Called after the constructor and called  after the first ngOnChanges()
        this.initialSliderValues();
    };
    LLCMainComponent.prototype.getFormControl = function () {
        return undefined;
    };
    Object.defineProperty(LLCMainComponent.prototype, "val", {
        get: function () {
            return this.component.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LLCMainComponent.prototype, "name", {
        get: function () {
            return this.dspService.currentData.leadName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LLCMainComponent.prototype, "minCover", {
        get: function () {
            if (this.isCover()) {
                return this.val.options[0][0].benefit_amount;
            }
            else if (this.isPremium()) {
                return this.val.options[1][0].benefit_amount;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LLCMainComponent.prototype, "maxCover", {
        get: function () {
            if (this.isCover()) {
                return this.val.options[0][this.val.options[0].length - 1].benefit_amount;
            }
            else if (this.isPremium()) {
                return this.val.options[1][this.val.options[1].length - 1].benefit_amount;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LLCMainComponent.prototype, "minPremium", {
        get: function () {
            if (this.isCover()) {
                return this.val.options[0][0].premium_amount;
            }
            else if (this.isPremium()) {
                return this.val.options[1][0].premium_amount;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LLCMainComponent.prototype, "maxPremium", {
        get: function () {
            if (this.isCover()) {
                return this.val.options[0][this.val.options[0].length - 1].premium_amount;
            }
            else if (this.isPremium()) {
                return this.val.options[1][this.val.options[1].length - 1].premium_amount;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LLCMainComponent.prototype, "increments", {
        get: function () {
            if (this.isCover()) {
                if (this.val.options[0].length > 1) {
                    return (this.val.options[0][1].benefit_amount - this.val.options[0][0].benefit_amount);
                }
                return 5000;
            }
            else if (this.isPremium()) {
                if (this.val.options[1].length > 1) {
                    return (this.val.options[1][1].premium_amount - this.val.options[1][0].premium_amount);
                }
                return 5;
            }
            else {
                return 5000;
            }
        },
        enumerable: true,
        configurable: true
    });
    LLCMainComponent.prototype.formatDisplay = function (value) {
        return DLUtil.compactFormat(value, false);
    };
    LLCMainComponent.prototype.switchValues = function ($event) {
        if ($event.source.name === 'rgCPSwitcher') {
            if (this.isCover()) {
                this.val.benefit_amount = this.previousCoverSelection_benefit_amount;
                this.val.premium_amount = this.previousCoverSelection_premium_amount;
            }
            else if (this.isPremium()) {
                this.val.benefit_amount = this.previousPremiumSelection_benefit_amount;
                this.val.premium_amount = this.previousPremiumSelection_premium_amount;
            }
        }
    };
    LLCMainComponent.prototype.updateCover = function (val) {
        var _this = this;
        if (this.isCover()) {
            this.val.options[0].forEach(function (opt) {
                if (val.value === opt.benefit_amount) {
                    _this.val.benefit_amount = opt.benefit_amount;
                    _this.val.premium_amount = opt.premium_amount;
                    _this.previousCoverSelection_benefit_amount = _this.val.benefit_amount;
                    _this.previousCoverSelection_premium_amount = _this.val.premium_amount;
                }
            });
        }
        else if (this.isPremium()) {
            this.val.options[1].forEach(function (opt) {
                if (val.value === opt.premium_amount) {
                    _this.val.benefit_amount = opt.benefit_amount;
                    _this.val.premium_amount = opt.premium_amount;
                    _this.previousPremiumSelection_benefit_amount = _this.val.benefit_amount;
                    _this.previousPremiumSelection_premium_amount = _this.val.premium_amount;
                }
            });
        }
    };
    Object.defineProperty(LLCMainComponent.prototype, "fullCover", {
        get: function () {
            var basePremium = this.val.premium_amount;
            var baseCover = -((basePremium * this.val.denominatorFactor) + this.val.numeratorFactor) / this.val.multiplierFactor;
            var cover = baseCover; // * productData.coverEscalationFactor;
            cover = (Math.round(cover * 100) / 100);
            return cover;
        },
        enumerable: true,
        configurable: true
    });
    LLCMainComponent.prototype.isCover = function () {
        return this.coverOrPremium === 'Cover';
    };
    LLCMainComponent.prototype.isPremium = function () {
        return this.coverOrPremium === 'Premium';
    };
    return LLCMainComponent;
}(Dynamic));
export { LLCMainComponent };
