var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ErrorModalProvider } from '../base/error/error.modal.service';
import { ErrorVO } from '../base/error/error.vo';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { throwError } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../base/error/error.modal.service";
/**
 * Created by corneliusbotha on 2017/07/18.
 */
export var httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
var DspInjectable = /** @class */ (function () {
    function DspInjectable() {
        this.baseUrl = '/dl/data/dsp';
    }
    DspInjectable.prototype.handleError = function (errorDialogService, heading) {
        return function (error) {
            var vo = ErrorVO.toErrorVO(error);
            errorDialogService.showError(vo, heading);
            return throwError(error);
        };
    };
    return DspInjectable;
}());
export { DspInjectable };
var DspService = /** @class */ (function (_super) {
    __extends(DspService, _super);
    function DspService(http, errorModalProvider) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.errorModalProvider = errorModalProvider;
        _this.postUrl = _this.baseUrl + '/submit';
        _this._resumeStorage = null;
        return _this;
    }
    Object.defineProperty(DspService.prototype, "groupId", {
        get: function () {
            return this._groupId;
        },
        set: function (val) {
            this._groupId = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DspService.prototype, "resumeStorage", {
        get: function () {
            return this._resumeStorage;
        },
        set: function (data) {
            this._resumeStorage = data;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DspService.prototype, "currentData", {
        get: function () {
            return this._currentData;
        },
        set: function (data) {
            this._currentData = data;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DspService.prototype, "productCode", {
        get: function () {
            if (!isNullOrUndefined(this._currentData)) {
                return this._currentData.productCode;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DspService.prototype, "pageName", {
        get: function () {
            if (!isNullOrUndefined(this._currentData)) {
                return this._currentData.pageName;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    DspService.prototype.postPageData = function (pageData) {
        var _this = this;
        return this.http.post(this.postUrl, pageData, httpOptions).pipe(tap(function (res) { return _this.setCurrentData(res); }), catchError(this.handleError(this.errorModalProvider)));
    };
    DspService.prototype.createPostDocCase = function (policyId, notes) {
        var url = '/dl/data/sp/case/createPostDocCase/' + policyId;
        return this.http.post(url, { 'notes': notes }, httpOptions).pipe(catchError(this.handleError(this.errorModalProvider)));
    };
    DspService.prototype.createPostSaleServiceCase = function (contactId, notes) {
        var url = '/dl/data/sp/case/createServicingCase/' + contactId;
        return this.http.post(url, { 'notes': notes }, httpOptions).pipe(catchError(this.handleError(this.errorModalProvider)));
    };
    DspService.prototype.updateTheCaseForWebAvsInvalid = function (spId) {
        var url = '/dl/data/sp/case/webAvsFailed/' + spId;
        return this.http.post(url, {}, httpOptions).pipe(catchError(this.handleError(this.errorModalProvider))).toPromise();
    };
    DspService.prototype.setCurrentData = function (data) {
        this.currentData = data;
        return this.currentData;
    };
    DspService.ngInjectableDef = i0.defineInjectable({ factory: function DspService_Factory() { return new DspService(i0.inject(i1.HttpClient), i0.inject(i2.ErrorModalProvider)); }, token: DspService, providedIn: "root" });
    return DspService;
}(DspInjectable));
export { DspService };
