import {Component} from '@angular/core';
import {Dynamic} from './component';
import {isNullOrUndefined} from 'util';
import {FormControl, Validators} from '@angular/forms';
import {DateValidationVO, PageComponentVO} from '../page.data.vo';
import {DLUtil} from '../../base/dl.util';

@Component({
    selector: 'date-input',
    templateUrl: 'date.component.html'
})
export class DateComponent extends Dynamic<PageComponentVO> {
    textControl: FormControl = new FormControl('', [Validators.required]);

    get extraInput(): DateValidationVO {
        return this.component.extraInput as DateValidationVO;
    }

    getFormControl(): FormControl {
        return this.textControl;
    }

    dobChange(dob: Date) {
        this.component.isValid = this.textControl.valid;
        if (!isNullOrUndefined(dob)) {
            this.component.value = DLUtil.formatDateToString(dob);
            if (!isNullOrUndefined(this.change)) {
                this.change.emit(this.component.counter);
            }
        }
    }

}
