import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { DotOrgDataService } from './dot-org-data.service';
var DotOrgModalComponent = /** @class */ (function () {
    function DotOrgModalComponent(dialogRef, dotOrgDataService, data) {
        this.dialogRef = dialogRef;
        this.dotOrgDataService = dotOrgDataService;
        this.data = data;
        this.loading = true;
    }
    Object.defineProperty(DotOrgModalComponent.prototype, "title", {
        get: function () {
            return this.data.title;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DotOrgModalComponent.prototype, "blurb", {
        get: function () {
            return this.data.blurb;
        },
        enumerable: true,
        configurable: true
    });
    DotOrgModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dotOrgDataService.getActiveProjects(this.data.area).subscribe(function (projects) {
            _this.projects = projects;
            _this.loading = false;
        });
    };
    DotOrgModalComponent.prototype.choseProject = function (project) {
        this.dialogRef.close(project);
    };
    return DotOrgModalComponent;
}());
export { DotOrgModalComponent };
