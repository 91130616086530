import {Dynamic, ParentEventService} from './component';
import {Component, OnDestroy} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Subscription} from 'rxjs';
import {AjaxService} from './ajax.service';
import {isNullOrUndefined} from 'util';
import {AjaxVO, PageComponentVO} from '../page.data.vo';

@Component({
    templateUrl: 'ajax.button.component.html',
    selector: 'ajax-button',
})
export class AjaxButtonComponent extends Dynamic<PageComponentVO> implements OnDestroy  {

    isDisabled = false;
    isBusy = false;
    isLink = false;
    private parentBusySubscription: Subscription;

    constructor(
        private parentEventService: ParentEventService,
        private ajaxService: AjaxService) {
        super();
        this.parentBusySubscription = parentEventService.getPageBusyObservable().subscribe(event => {
            this.isDisabled = event;
            if (this.isBusy && !event) {
                this.isBusy = false;
            }
        });
    }

    setupFromControl() {
        this.component.loaded = true;
        if (this.component.valueExtra !== undefined && this.component.valueExtra === 'link') {
            this.isLink = true;
        }
        // tel my parent I'm loaded.
        this.loaded.emit(this.component.id);
    }

    getFormControl(): FormControl {
        return null;
    }

    ngOnDestroy(): void {
        this.parentBusySubscription.unsubscribe();
    }

    click() {
        if (!this.isBusy) {
            this.isBusy = true;
            this.parentEventService.publishPageBusyStatus(true);
            this.ajaxService.postRequest(this.component.value as AjaxVO).subscribe(
                (res) => this.handleResponse(res),
                () => this.parentEventService.publishPageBusyStatus(false));
        }
        return false;
    }

    handleResponse(res: any): void {
        this.parentEventService.publishPageBusyStatus(false);
        if (!isNullOrUndefined(this.component.extraInput) && this.component.extraInput !== '') {
            this.component.name = this.component.extraInput as string;
        } else {
            this.component.name = 'Done';
        }
    }
}
