/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../base/date.picker.component.ngfactory";
import * as i2 from "../../base/date.picker.component";
import * as i3 from "../../base/match.media.service";
import * as i4 from "@angular/material/core";
import * as i5 from "./date-picker.component";
var styles_DatePickerComponent = [];
var RenderType_DatePickerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DatePickerComponent, data: {} });
export { RenderType_DatePickerComponent as RenderType_DatePickerComponent };
export function View_DatePickerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "dl-date-picker-input", [], null, null, null, i1.View_DatePickerComponent_0, i1.RenderType_DatePickerComponent)), i0.ɵdid(1, 49152, null, 0, i2.DatePickerComponent, [i3.MatchMediaService, i4.DateAdapter], { startView: [0, "startView"], isDob: [1, "isDob"], minAge: [2, "minAge"], maxAge: [3, "maxAge"], formControlRef: [4, "formControlRef"], required: [5, "required"], format: [6, "format"], placeHolder: [7, "placeHolder"], name: [8, "name"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dateValidationVO.pickerStartView; var currVal_1 = _co.dateValidationVO.dob; var currVal_2 = _co.dateValidationVO.minAge; var currVal_3 = _co.dateValidationVO.maxAge; var currVal_4 = _co.dateControl; var currVal_5 = true; var currVal_6 = _co.dateValidationVO.format; var currVal_7 = _co.component.name; var currVal_8 = _co.component.id; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
export function View_DatePickerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "date-picker-input", [], null, null, null, View_DatePickerComponent_0, RenderType_DatePickerComponent)), i0.ɵdid(1, 49152, null, 0, i5.DatePickerComponent, [i3.MatchMediaService, i4.DateAdapter], null, null)], null, null); }
var DatePickerComponentNgFactory = i0.ɵccf("date-picker-input", i5.DatePickerComponent, View_DatePickerComponent_Host_0, { flowId: "flowId", formGroup: "formGroup", component: "component", pageName: "pageName", _productCode: "_productCode", groupId: "groupId", _spMode: "_spMode" }, { change: "change", loaded: "loaded", validated: "validated", resume: "resume", altContinue: "altContinue" }, []);
export { DatePickerComponentNgFactory as DatePickerComponentNgFactory };
