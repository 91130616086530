import {NgModule} from '@angular/core';
import {QqComponent} from './qq.component';
import {CommonModule} from '@angular/common';
import {TextMaskModule} from 'angular2-text-mask';
import {
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatCardModule,
    MatProgressSpinnerModule
} from '@angular/material';
import {ErrorModule} from '../base/error/error.module';
import {HttpClientModule} from '@angular/common/http';
import {ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {BaseModule} from '../base/base.module';
import {QQResultComponent} from './qq.result.component';
import {QQMobileWarningComponent} from './qq.mobile.warning.component';
import {DynamicQQResultBlockComponent} from './dynamic.qq.result.block.component';
import {SGResultBlockComponent} from './sg.result.block.component';
import {OrgResultBlockComponent} from './dl.org.result.block.component';
import {DspModule} from '../dsp/dsp.module';
import {AdminOverrideModalComponent} from '../sp/payment/override/admin.override.modal.component';
import { QqResultNoSpComponent } from './qq.result.no.sp/qq.result.no.sp.component';
import {LimitedLifeQuickQuoteComponent} from '../sp/limited-life-cover-qq/limited-life-quick-quote.component';
import { QqMlfComponent } from './qq-mlf.modal.component';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BaseModule,
        ErrorModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        MatSelectModule,
        MatCheckboxModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatRadioModule,
        TextMaskModule,
        MatSliderModule,
        MatProgressSpinnerModule,
        DspModule,
        MatCardModule,
        RouterModule
    ],
    declarations: [
        QqComponent,
        QQResultComponent,
        QQMobileWarningComponent,
        DynamicQQResultBlockComponent,
        OrgResultBlockComponent,
        SGResultBlockComponent,
        QqResultNoSpComponent,
        LimitedLifeQuickQuoteComponent,
        QqMlfComponent
    ],
    entryComponents: [
        QqComponent,
        QQResultComponent,
        QQMobileWarningComponent,
        QqResultNoSpComponent,
        LimitedLifeQuickQuoteComponent,
        QqMlfComponent
    ],
    exports: [
        QqComponent,
        QqResultNoSpComponent,
        LimitedLifeQuickQuoteComponent,
        QqMlfComponent
    ]
})
export class QqModule {
}
