/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./donut.component";
import * as i2 from "./donut.service";
var styles_DonutComponent = [];
var RenderType_DonutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DonutComponent, data: {} });
export { RenderType_DonutComponent as RenderType_DonutComponent };
export function View_DonutComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { chart: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["chart", 1]], null, 0, "div", [], [[8, "id", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "chart_", _co._id, ""); _ck(_v, 1, 0, currVal_0); }); }
export function View_DonutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "donut-graph", [], null, null, null, View_DonutComponent_0, RenderType_DonutComponent)), i0.ɵdid(1, 114688, null, 0, i1.DonutComponent, [i2.DonutService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DonutComponentNgFactory = i0.ɵccf("donut-graph", i1.DonutComponent, View_DonutComponent_Host_0, { id: "id", dataUrl: "data-url", height: "height", width: "width", ringWidth: "ringWidth", showLabels: "showLabels", showTooltip: "showTooltip", showCenterLabel: "showCenterLabel" }, {}, []);
export { DonutComponentNgFactory as DonutComponentNgFactory };
