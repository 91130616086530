var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy } from '@angular/core';
import { Dynamic, ParentEventService } from './component';
import { isNullOrUndefined } from 'util';
var InfoCardComponent = /** @class */ (function (_super) {
    __extends(InfoCardComponent, _super);
    function InfoCardComponent(parentEventService) {
        var _this = _super.call(this) || this;
        _this.parentEventService = parentEventService;
        _this.isBusy = false;
        _this.isDisabled = true;
        _this.parentValidSubscription = parentEventService.getPageValidateObservable().subscribe(function (event) { return _this.isDisabled = !event; });
        _this.parentBusySubscription = parentEventService.getPageBusyObservable().subscribe(function (event) {
            _this.isDisabled = event;
            if (_this.isBusy && !event) {
                _this.isBusy = false;
            }
        });
        return _this;
    }
    InfoCardComponent.prototype.ngOnDestroy = function () {
        this.parentValidSubscription.unsubscribe();
        this.parentBusySubscription.unsubscribe();
    };
    InfoCardComponent.prototype.setupFromControl = function () {
        this.component.loaded = true;
        // tel my parent I'm loaded.
        this.loaded.emit(this.component.id);
    };
    InfoCardComponent.prototype.getFormControl = function () {
        return null;
    };
    InfoCardComponent.prototype.onChange = function () {
    };
    InfoCardComponent.prototype.getVO = function () {
        if (!isNullOrUndefined(this.component.extraInput)) {
            return this.component.extraInput;
        }
        return null;
    };
    Object.defineProperty(InfoCardComponent.prototype, "title", {
        get: function () {
            if (!isNullOrUndefined(this.getVO()) && !isNullOrUndefined(this.getVO().title)) {
                return this.getVO().title;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InfoCardComponent.prototype, "actionValue", {
        get: function () {
            if (!isNullOrUndefined(this.getVO()) && !isNullOrUndefined(this.getVO().action) &&
                !isNullOrUndefined(this.getVO().action.value) && this.getVO().action.value.length > 0) {
                return this.getVO().action.value;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InfoCardComponent.prototype, "actionText", {
        get: function () {
            if (!isNullOrUndefined(this.getVO()) && !isNullOrUndefined(this.getVO().action) &&
                !isNullOrUndefined(this.getVO().action.text) && this.getVO().action.text.length > 0) {
                return this.getVO().action.text;
            }
            return 'Continue';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InfoCardComponent.prototype, "isDefaultBtn", {
        get: function () {
            if (!isNullOrUndefined(this.getVO()) && !isNullOrUndefined(this.getVO().action) &&
                !isNullOrUndefined(this.getVO().action.default) && this.getVO().action.default) {
                return 'primary';
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InfoCardComponent.prototype, "linkHref", {
        get: function () {
            if (!isNullOrUndefined(this.getVO()) && !isNullOrUndefined(this.getVO().link) &&
                !isNullOrUndefined(this.getVO().link.href) && this.getVO().link.href.length > 0) {
                return this.getVO().link.href;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InfoCardComponent.prototype, "linkText", {
        get: function () {
            if (!isNullOrUndefined(this.getVO()) && !isNullOrUndefined(this.getVO().link) &&
                !isNullOrUndefined(this.getVO().link.text) && this.getVO().link.text.length > 0) {
                return this.getVO().link.text;
            }
            return 'Link';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InfoCardComponent.prototype, "content", {
        get: function () {
            return this.component.value;
        },
        enumerable: true,
        configurable: true
    });
    InfoCardComponent.prototype.click = function () {
        this.isBusy = true;
        this.altContinue.emit(this.actionValue);
    };
    return InfoCardComponent;
}(Dynamic));
export { InfoCardComponent };
