import { ErrorModalProvider } from '../error/error.modal.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs/index';
import { ErrorVO } from '../error/error.vo';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../error/error.modal.service";
var httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
var PricingService = /** @class */ (function () {
    function PricingService(httpClient, errorModalProvider) {
        this.httpClient = httpClient;
        this.errorModalProvider = errorModalProvider;
        this.postUrl = '/dl/data/pricing';
    }
    PricingService.prototype.requestPremium = function (request) {
        var simplePremiumUrl = this.postUrl + '/simple-premium';
        return this.httpClient
            .post(simplePremiumUrl, request, httpOptions)
            .pipe(catchError(this.handleError(this.errorModalProvider)));
    };
    PricingService.prototype.handleError = function (errorDialogService, heading) {
        return function (error) {
            var vo = ErrorVO.toErrorVO(error);
            errorDialogService.showError(vo, heading);
            return throwError(error);
        };
    };
    PricingService.ngInjectableDef = i0.defineInjectable({ factory: function PricingService_Factory() { return new PricingService(i0.inject(i1.HttpClient), i0.inject(i2.ErrorModalProvider)); }, token: PricingService, providedIn: "root" });
    return PricingService;
}());
export { PricingService };
var SimplePremiumRequest = /** @class */ (function () {
    function SimplePremiumRequest() {
    }
    return SimplePremiumRequest;
}());
export { SimplePremiumRequest };
var SimplePremiumResponse = /** @class */ (function () {
    function SimplePremiumResponse() {
    }
    return SimplePremiumResponse;
}());
export { SimplePremiumResponse };
