import {Component, EventEmitter, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
// import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RsaId} from '../rsa.id.validator';
// import {BehaviorSubject} from 'rxjs';
// import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {isNullOrUndefined} from 'util';
import {DLUtil} from '../dl.util';
import {AdditionalProductsService, AdditionalProductsUtil, AdditionalProductVO} from './additional-products.service';
import {Log} from 'ng2-logger/client';
import {DropDownValuesVO} from '../select.vo';
import {LLCDependantVO} from '../llc-summary/llc-summary.service';

const log = Log.create('AdditionalProductsModalComponent');

@Component({
    selector: 'additional-products-modal',
    templateUrl: './additional-products-modal.component.html'
})
export class AdditionalProductsModalComponent {
    // implements RsaIdValidationCallback

    // form: FormGroup;
    // coverOptions$: BehaviorSubject<CoverOptionsVO>;
    sliderPoints: any;
    changeEvents$: EventEmitter<ChangeEvent>;
    isValid = false;
    isBusy = false;
    benefit_amount: number;
    premium_amount: number;
    previouslyDuplicate = false;
    product_master_id: string;
    pricing: any;
    rsaId = new RsaId();
    productDescriptions: any = {
        LLC_PPPH: 'Premium holiday notes',
        LLC_AF: 'Accelerated funeral notes'
    };

    constructor(private dialogRef: MatDialogRef<AdditionalProductsModalComponent>,
                // private fb: FormBuilder,
                private additionalProductsService: AdditionalProductsService,
                @Inject(MAT_DIALOG_DATA) public data: AdditionalProductsModalData) {
        this.setupForm(data.main);
        // if (data.main) {
        //     this.fetchCoverOptions(null, data.dependant.product);
        // }
        if (!isNullOrUndefined(data.product.cover)) {
            this.benefit_amount = data.product.cover;
        }
        if (!isNullOrUndefined(data.product.premium)) {
            this.premium_amount = data.product.premium;
        }
    }

    setupForm(main: boolean): void {
        // this.coverOptions$ = new BehaviorSubject(new CoverOptionsVO('invalid'));
        this.changeEvents$ = new EventEmitter();
        if (main) {
            log.info('Main Member');
            // this.form = this.fb.group({x: ['']});
        } else {
            log.info('Product');

            // export class AdditionalProductVO {
            //     id: string;
            //     code: string;
            //     name: string;
            //     description: string;

            //     cover: number;
            //     premium: number;
            //     deleting: boolean;
            //     disabled: boolean;
            // }

            // this.form = this.fb.group({
            //     name: [this.data.product.name, [Validators.required]],
            //     description: [this.data.product.description, [Validators.required]],
            //     cover: [this.data.product.cover, [Validators.required]],
            //     premium: [this.data.product.premium, [Validators.required]]
            // });
            // this.form.get('name').valueChanges.pipe(debounceTime(450)).subscribe(() => this.checkDuplicate(true));
            // this.form.get('description').valueChanges.pipe(debounceTime(450)).subscribe(() => this.checkDuplicate(true));
            // this.form.get('id_or_dob').valueChanges.pipe(debounceTime(450)).subscribe(() => {
            //     this.checkDuplicate(true);
            //     this.rsaId.setId(this.form.get('id_or_dob').value);
            //     if (!isNullOrUndefined(this.rsaId.getGender())) {
            //         this.form.get('gender').setValue((this.rsaId.getGender() === 'Male' ? 'M' : 'F'));
            //     }
            // });
            // this.form.get('product').valueChanges.subscribe(() => this.productSelected());
            // this.form.get('gender').valueChanges.subscribe(() => this.genderChanged());
            // Initialize an Observable for consolidated, debounced change events
            // this.changeEvents$.pipe(
            //     debounceTime(650),
            //     distinctUntilChanged((a, b) => {
            //         return a.rsaId.getId() === b.rsaId.getId() && a.product === b.product && a.gender === b.gender;
            //     })
            // ).subscribe(
            //     // Update premiums for change events
            //     ($event) => this.fetchCoverOptions($event.rsaId, $event.product, $event.gender)
            // );
        }
    }

    get title(): string {
        /*if (this.isDependant) {
            return (this.data.edit ? 'Edit' : 'Add') + ' additional life';
        }
        return 'Edit Main Member';*/
        return this.data.product.name;
    }

    get saveButtonText(): string {
        // return this.data.edit ? 'Save' : 'Add';
        return this.data.product.selected ? 'Already Added' : 'Add';
    }

    get isDependant(): boolean {
        return !this.data.main;
    }

    get descriptionText(): string {
        return this.data.product.description;
    }

    get coverAmount(): string {
        if (this.data.product.cover) {
            return 'R' + this.data.product.cover;
        } else {
            return 'N/A';
        }
    }

    get premiumAmount(): string {
        return 'R' + this.data.product.premium;
    }

    cancel(): void {
        this.dialogRef.close('cancel');
    }

    save(): void {
        this.isBusy = true;
        const data = new AdditionalProductsModalData();
        DLUtil.copyFields(this.data, data);
        // data.product = new AdditionalProductsModalData();
        DLUtil.copyFields(this.data.product, data.product);
        this.data.product.selected = true;
        // DLUtil.copyFields(this.form.value, data.dependant);
        // data.dependant.benefit_amount = this.benefit_amount;
        // data.dependant.premium_amount = this.premium_amount;
        // data.dependant.product_master_id = this.product_master_id;
        // data.dependant.pricing = this.pricing;
        // data.dependant.age = this.rsaId.getAge();
        this.dialogRef.close(data);
    }

    get leadName(): string {
        return this.data.leadName;
    }

    // onIdValidationChange(rsaId: RsaId): void {
    //     if (!isNullOrUndefined(this.form) &&
    //         !isNullOrUndefined(this.form.get('product')) &&
    //         !isNullOrUndefined(this.form.get('product').value) &&
    //         !isNullOrUndefined(this.form.get('gender').value)) {
    //         const product = this.form.get('product').value as string;
    //         const gender = this.form.get('gender').value as string;
    //         this.changeEvents$.emit(new ChangeEvent(rsaId, product, gender));
    //     }
    // }

    // private checkDuplicate(fetch: boolean): boolean {
    //     let isDuplicate = false;
    //     this.data.dependants.forEach(d => {
    //         if (this.data.dependant.id !== d.id) {
    //             if (d.first_name === this.form.get('first_name').value &&
    //                 d.last_name === this.form.get('last_name').value &&
    //                 d.id_or_dob === this.form.get('id_or_dob').value &&
    //                 d.gender === this.form.get('gender').value) {
    //                 log.info('duplicate dependant', d);
    //                 isDuplicate = true;
    //                 this.isValid = false;
    //                 const coverOptions = new CoverOptionsVO('invalid');
    //                 coverOptions.error = 'Duplicate';
    //                 this.coverOptions$.next(coverOptions);
    //             }
    //         }
    //     });
    //     if (!isDuplicate && fetch && this.previouslyDuplicate) {
    //         const coverOptions = new CoverOptionsVO('invalid');
    //         this.coverOptions$.next(coverOptions);
    //         const rsaId = new RsaId();
    //         rsaId.setId(this.form.get('id_or_dob').value);
    //         const product = this.form.get('product').value;
    //         const gender = this.form.get('gender').value;
    //         this.fetchCoverOptions(rsaId, product, gender);
    //     }
    //     this.previouslyDuplicate = isDuplicate;
    //     return isDuplicate;
    // }

    // private invalidateSlider(message: string) {
    //     log.info(message);
    //     this.coverOptions$.next(new CoverOptionsVO('invalid'));
    // }

    // private fetchCoverOptions(rsaId: RsaId, product: string, inGender: string = null) {
    //     let age = 0;
    //     let gender = null;
    //     if (!this.data.main) {
    //         if (isNullOrUndefined(product) || product.length === 0) {
    //             this.invalidateSlider('no product selected');
    //             return;
    //         }
    //         if (isNullOrUndefined(rsaId.getId()) || (rsaId.getId().length > 6 && !rsaId.isValid())) {
    //             this.invalidateSlider('id is invalid');
    //             return;
    //         }
    //         if (!rsaId.isValidDOB() || isNullOrUndefined(product)) {
    //             this.invalidateSlider('dob is invalid or no product');
    //             return;
    //         }
    //         age = rsaId.getAge();
    //         if (isNullOrUndefined(rsaId.getGender())) {
    //             gender = inGender;
    //         } else {
    //             gender = rsaId.getGender() === 'Male' ? 'M' : 'F';
    //         }
    //         if (isNullOrUndefined(gender)) {
    //             this.invalidateSlider('gender is invalid');
    //             return;
    //         }
    //         if (this.checkDuplicate(false)) {
    //             this.invalidateSlider('');
    //             return;
    //         }
    //     }
    //     log.info('loading cover options for ' + product + ', ' + age);
    //     this.isValid = false;
    //     this.form.disable({onlySelf: true, emitEvent: false});
    //     this.coverOptions$.next(new CoverOptionsVO('loading'));
    //     const req: MLFSimplePricingRequestVO = {
    //         spId: this.data.spId,
    //         benefitId: this.data.benefitId,
    //         product: product,
    //         age: age,
    //         gender: gender
    //     };
    //     this.summaryService.getPricing(req).subscribe((res) => {
    //         this.updateCoverOptions(rsaId, product, res);
    //         this.form.enable({onlySelf: true, emitEvent: false});
    //     }, (error: any) => {
    //         log.error('Error getting Dependant Info', error);
    //         this.form.enable({onlySelf: true, emitEvent: false});
    //         const coverOptions = new CoverOptionsVO('invalid');
    //         coverOptions.error = ErrorVO.toErrorVO(error).message;
    //         this.coverOptions$.next(coverOptions);
    //     });
    // }

    // productSelected(): void {
    //     const rsaId = new RsaId();
    //     rsaId.setId(this.form.get('id_or_dob').value);
    //     const gender = this.form.get('gender').value;
    //     const product = this.form.get('product').value;
    //     this.changeEvents$.emit(new ChangeEvent(rsaId, product, gender));
    // }

    // genderChanged(): void {
    //     const rsaId = new RsaId();
    //     rsaId.setId(this.form.get('id_or_dob').value);
    //     let gender = this.form.get('gender').value;
    //     if (!isNullOrUndefined(rsaId.getGender())) {
    //         gender = rsaId.getGender() === 'Male' ? 'M' : 'F';
    //     }
    //     if (gender !== this.form.get('gender').value) {
    //         // Flip it back to what ID number says...
    //         this.form.get('gender').setValue(gender);
    //         return;
    //     }
    //     const product = this.form.get('product').value;
    //     this.changeEvents$.emit(new ChangeEvent(rsaId, product, gender));
    // }

    // get productHint(): string {
    //     const product = this.data.product;
    //     if (!isNullOrUndefined(product) && !isNullOrUndefined(this.productDescriptions[product.code])) {
    //         return this.productDescriptions[product.code];
    //     }
    //     return null;
    // }

    // updateCoverOptions(rsaId: RsaId, product: string, res: CoverOptionsVO) {
    //     log.info('results from pricing', res);
    //     if (isNullOrUndefined(res)) {
    //         return;
    //     }
    //     if (res.hasOwnProperty('error')) {
    //         const coverOptions = new CoverOptionsVO('invalid');
    //         coverOptions.error = res.error;
    //         this.isValid = false;
    //         this.coverOptions$.next(coverOptions);
    //     } else {
    //         res.status = 'valid';
    //         if (!isNullOrUndefined(this.benefit_amount) && this.benefit_amount < res.max) {
    //             res.cover = this.benefit_amount;
    //         } else {
    //             res.cover = res.max;
    //         }
    //         this.sliderPoints = res.options;
    //         this.benefit_amount = res.cover;
    //         this.premium_amount = this.findPremiumForCover(res.cover);
    //         this.product_master_id = res.product_master_id;
    //         this.pricing = res.pricing;
    //         this.isValid = true;
    //         this.coverOptions$.next(res);
    //     }
    // }

    formatDisplay(value: number | null): string {
        return DLUtil.compactFormat(value, false);
    }

    // updateCover(val: MatSliderChange): void {
    //     this.benefit_amount = val.value;
    //     this.premium_amount = this.findPremiumForCover(val.value);
    //     this.form.markAsDirty();
    // }
    //
    // private findPremiumForCover(cover): number {
    //     let premium = 0;
    //     this.sliderPoints.forEach((opt) => {
    //         if (cover === opt.cover) {
    //             premium = opt.premium;
    //         }
    //     });
    //     return premium;
    // }

    get disableAdd(): boolean {
        return this.data.product.selected; // || this.form.pristine || this.form.invalid;
    }

    getDisabledText(item: DropDownValuesVO) {
        if (item.disabled && !isNullOrUndefined(AdditionalProductsUtil.validation[item.id])) {
            return ' (Max: ' + AdditionalProductsUtil.validation[item.id] + ')';
        }
        return '';
    }

}

export class AdditionalProductsModalData {
    spId: string;
    benefitId: string;
    leadName: string;
    edit: boolean;
    main: boolean;
    product: AdditionalProductVO;
    dependant: LLCDependantVO;
}

class ChangeEvent {
    rsaId: RsaId;
    product: string;
    gender: string;

    constructor(rsaId: RsaId, product: string, gender: string) {
        this.rsaId = rsaId;
        this.product = product;
        this.gender = gender;
    }
}
