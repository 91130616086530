/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i2 from "@angular/material/button";
import * as i3 from "@angular/cdk/platform";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "@angular/common";
import * as i7 from "./resume.button.component";
import * as i8 from "../../base/window.ref";
var styles_ResumeButtonComponent = [];
var RenderType_ResumeButtonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ResumeButtonComponent, data: {} });
export { RenderType_ResumeButtonComponent as RenderType_ResumeButtonComponent };
function View_ResumeButtonComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "col s12 m6 offset-m3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "button", [["class", "mat-primary dl-full-width dl-action-btn"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MatButton_0, i1.RenderType_MatButton)), i0.ɵdid(3, 180224, null, 0, i2.MatButton, [i0.ElementRef, i3.Platform, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵted(4, 0, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i0.ɵnov(_v, 3).disabled || null); var currVal_1 = (i0.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.component.name; _ck(_v, 4, 0, currVal_2); }); }
function View_ResumeButtonComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "mat-primary dl-full-width dl-action-btn"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MatButton_0, i1.RenderType_MatButton)), i0.ɵdid(1, 180224, null, 0, i2.MatButton, [i0.ElementRef, i3.Platform, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵted(2, 0, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i0.ɵnov(_v, 1).disabled || null); var currVal_1 = (i0.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.component.name; _ck(_v, 2, 0, currVal_2); }); }
export function View_ResumeButtonComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResumeButtonComponent_1)), i0.ɵdid(1, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResumeButtonComponent_2)), i0.ɵdid(3, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.component.shareRow; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.component.shareRow; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ResumeButtonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "resume-button", [], null, null, null, View_ResumeButtonComponent_0, RenderType_ResumeButtonComponent)), i0.ɵdid(1, 49152, null, 0, i7.ResumeButtonComponent, [i8.WindowRef], null, null)], null, null); }
var ResumeButtonComponentNgFactory = i0.ɵccf("resume-button", i7.ResumeButtonComponent, View_ResumeButtonComponent_Host_0, { flowId: "flowId", formGroup: "formGroup", component: "component", pageName: "pageName", _productCode: "_productCode", groupId: "groupId", _spMode: "_spMode" }, { change: "change", loaded: "loaded", validated: "validated", resume: "resume", altContinue: "altContinue" }, []);
export { ResumeButtonComponentNgFactory as ResumeButtonComponentNgFactory };
