/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../base/stripe/stripe.component.ngfactory";
import * as i2 from "../../base/stripe/stripe.component";
import * as i3 from "../../base/stripe/stripe.script.service";
import * as i4 from "./stripe.element.component";
var styles_StripeElementComponent = [];
var RenderType_StripeElementComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_StripeElementComponent, data: {} });
export { RenderType_StripeElementComponent as RenderType_StripeElementComponent };
export function View_StripeElementComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "dl-stripe-input", [], null, [[null, "stateChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("stateChange" === en)) {
        var pd_0 = (_co.onStripeElementStatusChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_StripeComponent_0, i1.RenderType_StripeComponent)), i0.ɵdid(1, 245760, null, 0, i2.StripeComponent, [i0.ChangeDetectorRef, i0.NgZone, i3.StripeScriptService], { publishToken: [0, "publishToken"], postalCode: [1, "postalCode"] }, { stateChange: "stateChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stripInput.publishKey; var currVal_1 = _co.stripInput.postalCode; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_StripeElementComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "stripe-input", [], null, null, null, View_StripeElementComponent_0, RenderType_StripeElementComponent)), i0.ɵdid(1, 49152, null, 0, i4.StripeElementComponent, [i0.ChangeDetectorRef, i0.NgZone, i3.StripeScriptService], null, null)], null, null); }
var StripeElementComponentNgFactory = i0.ɵccf("stripe-input", i4.StripeElementComponent, View_StripeElementComponent_Host_0, { flowId: "flowId", formGroup: "formGroup", component: "component", pageName: "pageName", _productCode: "_productCode", groupId: "groupId", _spMode: "_spMode" }, { change: "change", loaded: "loaded", validated: "validated", resume: "resume", altContinue: "altContinue" }, []);
export { StripeElementComponentNgFactory as StripeElementComponentNgFactory };
