/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./info-card.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/card";
import * as i3 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "@angular/common";
import * as i9 from "../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i10 from "./offer-info-card.component";
import * as i11 from "./component";
var styles_OfferInfoCardComponent = [i0.styles];
var RenderType_OfferInfoCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OfferInfoCardComponent, data: {} });
export { RenderType_OfferInfoCardComponent as RenderType_OfferInfoCardComponent };
function View_OfferInfoCardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-card-title", [["class", "title mat-card-title"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatCardTitle, [], null, null), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); }); }
function View_OfferInfoCardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["class", "right link"], ["target", "_blank"]], [[8, "href", 4], [1, "data-event", 0], [1, "data-event-category", 0], [1, "data-event-name", 0], [1, "data-event-label", 0]], null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.linkHref; var currVal_1 = "true"; var currVal_2 = ("DSP-" + _co.productCode); var currVal_3 = (_co.pageName + " - Link"); var currVal_4 = ("link to " + _co.linkText); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.linkText; _ck(_v, 2, 0, currVal_5); }); }
function View_OfferInfoCardComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-circle-o-notch fa-spin"]], null, null, null, null, null))], null, null); }
function View_OfferInfoCardComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "mat-card-actions", [["class", "mat-card-actions"]], [[2, "mat-card-actions-align-end", null]], null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatCardActions, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "col s8 offset-s2 m6 offset-m3 l4 offset-l4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "button", [["class", "dl-full-width"], ["mat-raised-button", ""], ["tabindex", "1"]], [[1, "data-name", 0], [1, "data-event", 0], [1, "data-event-category", 0], [1, "data-event-name", 0], [1, "data-event-label", 0], [8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(5, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i1.ɵted(6, 0, [" ", "\u00A0"])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_OfferInfoCardComponent_4)), i1.ɵdid(8, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = (_co.isDisabled || _co.isBusy); var currVal_9 = i1.ɵinlineInterpolate(1, "", _co.isDefaultBtn, ""); _ck(_v, 5, 0, currVal_8, currVal_9); var currVal_11 = _co.isBusy; _ck(_v, 8, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1).align === "end"); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.actionValue; var currVal_2 = "true"; var currVal_3 = ("DSP-" + _co.productCode); var currVal_4 = ((_co.pageName + " - ") + _co.actionValue); var currVal_5 = ("Continue to " + _co.actionValue); var currVal_6 = (i1.ɵnov(_v, 5).disabled || null); var currVal_7 = (i1.ɵnov(_v, 5)._animationMode === "NoopAnimations"); _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_10 = _co.actionText; _ck(_v, 6, 0, currVal_10); }); }
export function View_OfferInfoCardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "mat-card", [["class", "mat-card"]], null, null, null, i9.View_MatCard_0, i9.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i2.MatCard, [], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_OfferInfoCardComponent_1)), i1.ɵdid(3, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 4, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i2.MatCardContent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OfferInfoCardComponent_2)), i1.ɵdid(8, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_OfferInfoCardComponent_3)), i1.ɵdid(10, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); var currVal_2 = _co.linkHref; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.actionValue; _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.content; _ck(_v, 6, 0, currVal_1); }); }
export function View_OfferInfoCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "offer-info-card", [], null, null, null, View_OfferInfoCardComponent_0, RenderType_OfferInfoCardComponent)), i1.ɵdid(1, 180224, null, 0, i10.OfferInfoCardComponent, [i11.ParentEventService], null, null)], null, null); }
var OfferInfoCardComponentNgFactory = i1.ɵccf("offer-info-card", i10.OfferInfoCardComponent, View_OfferInfoCardComponent_Host_0, { flowId: "flowId", formGroup: "formGroup", component: "component", pageName: "pageName", _productCode: "_productCode", groupId: "groupId", _spMode: "_spMode" }, { change: "change", loaded: "loaded", validated: "validated", resume: "resume", altContinue: "altContinue" }, []);
export { OfferInfoCardComponentNgFactory as OfferInfoCardComponentNgFactory };
