import { DisclosureCategory, DisclosureType } from './disclosures.vos';
import { DisclosuresService } from './disclosures.service';
import { MatDialogRef } from '@angular/material';
import { isNullOrUndefined } from 'util';
import { DisclosuresComponent } from "./disclosures.component";
var DisclosuresModalComponent = /** @class */ (function () {
    function DisclosuresModalComponent(disclosuresService, dialogRef) {
        this.disclosuresService = disclosuresService;
        this.dialogRef = dialogRef;
        this.saving = false;
        this.stopping = false;
        this.title = DisclosuresModalComponent.DEFAULT_TITLE;
        this.canClose = false;
        this.canCloseAlways = false;
    }
    Object.defineProperty(DisclosuresModalComponent.prototype, "category", {
        get: function () {
            if (isNullOrUndefined(this._category)) {
                this.category = DisclosureCategory.SALES_PROCESS;
            }
            return this._category;
        },
        set: function (val) {
            if (val === DisclosureCategory.SALES_PROCESS) {
                this._category = 'Sales Process';
            }
            else {
                this._category = val;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DisclosuresModalComponent.prototype, "busy", {
        get: function () {
            return this.saving || this.stopping;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DisclosuresModalComponent.prototype, "canSave", {
        get: function () {
            if (this.saving || this.stopping) {
                return false;
            }
            return this.disclosuresView.isValid();
        },
        enumerable: true,
        configurable: true
    });
    DisclosuresModalComponent.prototype.showStop = function () {
        return !this.canClose && this.containsStop();
    };
    DisclosuresModalComponent.prototype.showClose = function () {
        return this.canClose && this.containsStop() || this.callback && this.canCloseAlways;
    };
    DisclosuresModalComponent.prototype.containsStop = function () {
        if (isNullOrUndefined(this.disclosures) || this.disclosures.length === 0) {
            return false;
        }
        for (var _i = 0, _a = this.disclosures; _i < _a.length; _i++) {
            var disclosure = _a[_i];
            if (!isNullOrUndefined(disclosure.stop_message) &&
                disclosure.stop_message.length > 0 && disclosure.answer === 'no') {
                return true;
            }
        }
        return false;
    };
    DisclosuresModalComponent.prototype.saveDisclosures = function () {
        var _this = this;
        var disc = this.page + this.type;
        this.saving = true;
        if (this.type !== DisclosureType.INFO) {
            this.disclosuresService.save(this.disclosures).subscribe(function (res) {
                _this.handleSave(res.success);
            });
        }
        else {
            this.handleSave(true);
        }
    };
    DisclosuresModalComponent.prototype.handleSave = function (result) {
        if (result) {
            // we keep the saving spinner running on the exit disclosure
            // as the page will be saving its data and redirecting to the next page
            if (this.type !== DisclosureType.EXIT) {
                this.saving = false;
                this.dialogRef.close();
            }
            else {
                this.callback.onDisclosuresSaved(this.disclosures);
            }
        }
        else {
            this.saving = false;
        }
    };
    DisclosuresModalComponent.prototype.stopAndExit = function () {
        this.stopping = true;
        this.disclosuresService.stop(this.prefix, this.page, this.type, this.disclosures).subscribe(function (res) {
            location.href = res.out_page;
        });
    };
    DisclosuresModalComponent.prototype.close = function () {
        if (!isNullOrUndefined(this.callback)) {
            if (this.callback.onDisclosuresClosing(this.disclosures)) {
                this.stopAndExit();
                return;
            }
            else {
                this.callback.onDisclosuresClosed();
            }
        }
        this.dialogRef.close();
    };
    DisclosuresModalComponent.prototype.setDisclosureCategory = function (category) {
        this.category = category;
    };
    DisclosuresModalComponent.DEFAULT_TITLE = 'Important Information';
    return DisclosuresModalComponent;
}());
export { DisclosuresModalComponent };
