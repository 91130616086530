import { HttpClient, HttpHeaders } from '@angular/common/http';
import { isNullOrUndefined } from 'util';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
var MLFSummaryService = /** @class */ (function () {
    function MLFSummaryService(http) {
        this.http = http;
    }
    MLFSummaryService.prototype.getPricing = function (request, sp) {
        if (!isNullOrUndefined(sp) && sp === 'LLC')
            return this.http.post('/dl/data/pricing/simple-for-llc', request, httpOptions);
        return this.http.post('/dl/data/pricing/simple-for-sp', request, httpOptions);
    };
    MLFSummaryService.ngInjectableDef = i0.defineInjectable({ factory: function MLFSummaryService_Factory() { return new MLFSummaryService(i0.inject(i1.HttpClient)); }, token: MLFSummaryService, providedIn: "root" });
    return MLFSummaryService;
}());
export { MLFSummaryService };
var MLFUtil = /** @class */ (function () {
    function MLFUtil() {
    }
    MLFUtil.validation = {
        MLF_S: 1,
        MLF_C: 8,
        MLF_E: 4,
    };
    return MLFUtil;
}());
export { MLFUtil };
var MLFSimplePricingRequestVO = /** @class */ (function () {
    function MLFSimplePricingRequestVO() {
    }
    return MLFSimplePricingRequestVO;
}());
export { MLFSimplePricingRequestVO };
var CoverOptionsVO = /** @class */ (function () {
    function CoverOptionsVO(status) {
        this.status = status;
    }
    return CoverOptionsVO;
}());
export { CoverOptionsVO };
var MLFDependantVO = /** @class */ (function () {
    function MLFDependantVO() {
    }
    return MLFDependantVO;
}());
export { MLFDependantVO };
