import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ErrorModalProvider} from '../base/error/error.modal.service';
import {Observable, of, throwError} from 'rxjs';
import {ErrorVO} from '../base/error/error.vo';
import {catchError} from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class ContactBlockService {

  constructor(private http: HttpClient,
              private errorModalProvider: ErrorModalProvider) { }

    public submitCallMeRequest(leadData: ContactMeVO): Observable<any> {
        return this.http.post('/dl/data/contact-me', JSON.stringify(leadData), httpOptions).pipe(
            catchError(this.handleError())
        );
    }

    public submitCallMeRequestNoEmail(leadData: ContactMeVO): Observable<any> {
      console.log('About to send a request to create an email and skip creating a lead');
        return this.http.post('/dl/data/contact-me-no-lead', JSON.stringify(leadData), httpOptions).pipe(
            catchError(this.handleError())
        );
    }

  private handleError<T>() {
    return (error: any):  Observable<T> => {
      const vo: ErrorVO = ErrorVO.toErrorVO(error);
      this.errorModalProvider.showError(vo);
      return throwError(vo);
    };
  }
}

export class ContactMeVO {
  firstname: string;
  lastname: string;
  leadtype = 'life_call_me';
  leadsource = 'web';
  leadsourcedescription: string;
  referrer_code: string;
  campaign_code: string;
  provider_id: string;
  referrer: string;
  phonemobile: string;
  alt_phone: string;
  email: string;
  control: string;
}

