import {Component, Input, OnInit, AfterViewInit, ElementRef} from '@angular/core';
import {CloseEventData, InputData, PostData, QQForm, QQFormFields, QQResult} from './qq.vo';
import {RsaId, rsaIdValidation, RsaIdValidationCallback} from '../base/rsa.id.validator';
import {isNullOrUndefined, isUndefined} from 'util';
import {MatDialog, MatDialogRef} from '@angular/material';
import {QQMobileWarningComponent} from './qq.mobile.warning.component';
import {QQResultComponent} from './qq.result.component';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SelectItem} from '../base/select.vo';
import {QQPricingRequestVO, QQPricingResponseVO} from './qq.pricing.vos';
import {WindowRef} from '../base/window.ref';
import {QQService} from './qq.service';
import {ErrorModalProvider} from '../base/error/error.modal.service';
import {minMaxValidation} from '../base/min.max.validator';
import {QQResultBaseComponent} from './qq.result.base.component';
import {DLUtil} from '../base/dl.util';
import {ProductGroup} from '../base/benefit';
import {QqResultNoSpComponent} from './qq.result.no.sp/qq.result.no.sp.component';
import { QqMlfComponent } from './qq-mlf.modal.component';
import {Dynamic, ParentEventService} from './../dsp/component/component';
import { PageComponent } from '../dsp/loader/page.component';
import { DspService } from '../dsp/dsp.service';
import { PageComponentVO } from '../dsp/page.data.vo';

// declare let KingsWatch: any;

export interface QQContinue {
    continueToPersonal(result: QQResult): void;
}

@Component({
    selector: 'quick-quote',
    templateUrl: './qq.component.html',
    styleUrls: ['./qq.component.scss']
})
export class QqComponent extends Dynamic<PageComponentVO> implements OnInit, AfterViewInit, RsaIdValidationCallback, QQContinue {
    getFormControl(): FormControl {
        return;
    }

    inputData: InputData;
    qqMlfComponentFlag: boolean = false;

    @Input('qq-type')
    set qqType(val: string) {
        this.inputData.type = val;
    }

    // inputs
    @Input('qq-title')
    set qqTitle(val: string) {
        this.inputData.title = val;
    }

    @Input('security-group')
    set securityGroup(val: string) {
        this.inputData.securityGroup = val;
    }

    @Input('product-code')
    set productCode(val: string) {
        this.inputData.productCode = val;
    }

    @Input('button-text')
    set buttonText(val: string) {
        this.inputData.buttonText = val;
    }

    @Input('bg-colour')
    set bgColour(val: string) {
        this.inputData.bgColour = val;
        this.rgbaColor = 'rgba(' + this.inputData.bgColour + ',' + this.inputData.opacity + ')';
    }

    @Input()
    set opacity(val: number) {
        this.inputData.opacity = val;
        this.rgbaColor = 'rgba(' + this.inputData.bgColour + ',' + this.inputData.opacity + ')';
    }

    @Input('campaign-code')
    set campaignCode(val: string) {
        this.inputData.campaignCode = val;
    }

    @Input('referrer-code')
    set referrerCode(val: string) {
        this.inputData.referrerCode = val;
    }

    @Input('http-referrer')
    set httpReferrer(val: string) {
        this.inputData.httpReferrer = val;
    }

    @Input('cause-text')
    set causeText(val: string) {
        this.inputData.causeText = val;
    }

    @Input()
    set source(val: string) {
        this.inputData.source = val;
    }

    @Input('lead-id')
    set leadId(val: string) {
        this.inputData.leadId = val;
    }

    @Input('sp-id')
    set spId(val: string) {
        this.inputData.spId = val;
    }

    @Input()
    set optionality(val: string) {
        this.inputData.optionality = val;
    }

    @Input('lead-capture')
    set leadCapture(val: boolean) {
        this.inputData.leadCapture = val;
    }

    @Input('class')
    set _class(val: string) {
        this.inputData.theme = '';
        if (val.indexOf('dark') > -1) {
            this.inputData.theme = 'dark';
        }
    }

    private _external = false;
    @Input('qq-external')
    set index(val: boolean) {
        this._external = val;
    }

    @Input()
    set embedded(val: boolean) {
        this.setEmbedded(val);
    }

    qqMobileWarning: MatDialogRef<QQMobileWarningComponent>;
    qqResult: MatDialogRef<QQResultBaseComponent>;
    qqNoSpResult: MatDialogRef<QqResultNoSpComponent>;
    qqMlfResult: MatDialogRef<QqMlfComponent>;
    qqForm: FormGroup;
    qqMlfForm: FormGroup;
    showProduct = true;
    smokerItems: SelectItem[] = [
        {label: 'Smoker', value: 'S'},
        {label: 'Non Smoker', value: 'N'}
    ];
    genderItems: SelectItem[] = [
        {label: 'Female', value: 'F'},
        {label: 'Male', value: 'M'}
    ];
    products: SelectItem[] = [];
    qqLoading = true;
    education: SelectItem[] = [
        {label: 'No Matric', value: 'no_matric'},
        {label: 'Matric', value: 'matric'},
        {value: 'matric_certificate', label: 'Matric plus certificate'},
        {value: 'matric_3_yr_diploma', label: '3 yr Diploma'},
        {value: 'matric_4_yr_diploma', label: '4 yr Diploma'},
        {value: 'degree_3_yr', label: '3 yr Degree'},
        {value: 'degree_4_yr', label: '4 yr Degree'},
        {value: 'degree_post_grad', label: 'Postgraduate Degree'}
    ];

    qqRequest: QQPricingRequestVO;
    productGroupCode: string;
    loading = false;
    noId = false;
    toOld = false;
    noIncome = false;
    rgbaColor: string;
    numMask: any;
    leadInvalidated = false;
    formFields: QQFormFields;
    message: string;
    mlfIdData: any = {
        dob: '',
        age: '',
        gender: ''
    }

    constructor(public winRef: WindowRef,
                public qqService: QQService,
                public errorModalProvider: ErrorModalProvider,
                public dialog: MatDialog,
                public windowRef: WindowRef,
                public parentEventService: ParentEventService,
                public fb: FormBuilder,
                public dspService: DspService) {
        super();

        this.inputData = new InputData();

        this.createForm();
        this.numMask = {
            mask: function (rawValue: string) {
                const numValue = rawValue.replace(/\D+/g, '');
                const mask = [];
                for (let i = numValue.length - 1; i >= 0; i--) {
                    if ((numValue.length - i) > 2 && (numValue.length - i) % 3 === 1) {
                        mask.unshift(',');
                    }
                    mask.unshift(/\d/);
                }
                return mask;
            }
        };
    }

    /**
     * Called on init.
     */
    ngOnInit(): void {
        this.rgbaColor = 'rgba(' + this.inputData.bgColour + ',' + this.inputData.opacity + ')';
        this.setEmbedded(this.inputData.embedded);

        if (this.inputData.type === 'mlf') {
            this.qqMlfComponentFlag = true;

            this.qqMlfForm = this.fb.group({
                rsaId: ['', [Validators.pattern('[0-9]*'), rsaIdValidation(true, this)]],
                income: ['', [Validators.required, minMaxValidation(0, 999999999)]]
            });

            this.qqLoading = false;
        } else if (!isNullOrUndefined(this.component)) {
            if ((this.component.type === 'mlf' && this.component.name === 'qq-mlf')) {
                this.qqMlfComponentFlag = true;

                this.qqMlfForm = this.fb.group({
                    rsaId: ['', [Validators.pattern('[0-9]*'), rsaIdValidation(true, this)]],
                    income: ['', [Validators.required, minMaxValidation(0, 999999999)]]
                });

                this.qqMlfForm.valueChanges.subscribe((formChange) => {
                    if (this.qqMlfForm.get('rsaId').valid && this.qqMlfForm.get('income').valid) {
                        this.component.isValid = true;
                    } else {
                        this.component.isValid = true;
                    }
                });

                this.qqLoading = false;
            }
        } else {
            this.qqService.getForm(this.inputData.embedded, this.inputData.leadId, this.inputData.campaignCode).subscribe(
                res => this.initializeQQ(res),
                () => {
                    this.qqLoading = false;
                    this.qqForm.disable();
                }
            );
        }
    }

    ngAfterViewInit(): void {
        try {
            // KingsWatch.aim();
        } catch (error) {
            console.log('Kings Watch failed to aim', error);
        }
    }

    public setEmbedded(embed: boolean) {
        this.inputData.embedded = embed;
        if (embed) {
            this.qqForm.get('rsaId').enable();
        } else {
            this.qqForm.get('rsaId').disable();
        }
    }

    createForm(): void {
        this.qqForm = this.fb.group({
            product: ['', Validators.required],
            rsaId: ['', [Validators.required, Validators.pattern('[0-9]*'), rsaIdValidation(true, this, 18, 64)]],
            age: ['', [Validators.required, minMaxValidation(18, 64)]],
            birth_date: ['', [Validators.required, minMaxValidation(18, 80)]],
            income: ['', Validators.required],
            education: [this.education[0].value, Validators.required],
            smoker: ['', Validators.required],
            gender: ['', Validators.required]
        });
    }

    private initializeQQ(qqForm: QQForm): void {
        if (qqForm && qqForm.products) {
            this.productGroupCode = qqForm.productGroupCode;
            this.inputData.campaignId = qqForm.campaignId;
            this.inputData.orgProjects = qqForm.orgProjects;
            this.products = qqForm.products;
            qqForm.products.forEach(product => {
                if (product.value === this.inputData.productCode) {
                    this.qqForm.get('product').setValue(product.value);
                    this.qqForm.get('product').disable();
                    this.showProduct = false;
                }
            });
            if (this.showProduct) {
                if (qqForm.products.length > 0) {
                    this.inputData.productCode = qqForm.products[0].value;
                    this.qqForm.get('product').setValue(this.inputData.productCode);
                    if (qqForm.products.length === 1) {
                        this.qqForm.get('product').disable();
                        this.showProduct = false;
                    }
                } else {
                    this.errorModalProvider.showErrorMessage('There are no products for the Quick Quote');
                    this.qqForm.disable();
                }
            }
            this.formFields = qqForm.fields;
            if (!qqForm.fields.age) {
                this.qqForm.get('age').disable();
            }
            if (!qqForm.fields.id_number) {
                this.qqForm.get('rsaId').disable();
            }
            if (!qqForm.fields.income) {
                this.qqForm.get('income').disable();
            }
            if (!qqForm.fields.education) {
                this.qqForm.get('education').disable();
            }
            if (!qqForm.fields.gender) {
                this.qqForm.get('gender').disable();
            }
            if (!qqForm.fields.smoker) {
                this.qqForm.get('smoker').disable();
            }
            if (!qqForm.fields.birth_date) {
                this.qqForm.get('birth_date').disable();
            }
        } else {
            this.message = qqForm.message;
        }
        this.qqLoading = false;
    }

    public getPricing(): void {
        this.loading = true;

        try {
            // KingsWatch.fire();
        } catch (error) {
            console.log('Kings Watch failed to fire', error);
        }

        this.qqRequest = new QQPricingRequestVO();
        this.qqRequest.product_code = this.qqForm.get('product').value;
        if (this.formFields.age && this.qqForm.get('age').value) {
            this.qqRequest.starting_age_year = this.qqForm.get('age').value;
        }
        if (this.formFields.birth_date && this.qqForm.get('birth_date').value) {
            const date = new Date(this.qqForm.get('birth_date').value);
            this.qqRequest.birth_date = DLUtil.formatDateToString(date);
        }
        if (this.formFields.gender && this.qqForm.get('gender').value) {
            this.qqRequest.gender = this.qqForm.get('gender').value;
        }
        if (this.formFields.smoker && this.qqForm.get('smoker').value) {
            this.qqRequest.smoker = this.qqForm.get('smoker').value;
        }
        if (this.formFields.income && this.qqForm.get('income').value) {
            if (this.qqForm.get('income').value.replace == null) {
                this.qqRequest.income_gross_monthly = this.qqForm.get('income').value;
            } else {
                this.qqRequest.income_gross_monthly = this.qqForm.get('income').value.replace(/\D+/g, '');
            }
        }
        if (this.formFields.education && this.qqForm.get('education').value) {
            this.qqRequest.education = this.qqForm.get('education').value;
        }
        this.qqRequest.product_group = this.productGroupCode;
        if (this.inputData.leadId != null) {
            this.qqRequest.lead_id = this.inputData.leadId;
        }
        if (this.inputData.campaignId != null) {
            this.qqRequest.campaign_id = this.inputData.campaignId;
        }
        if (this.inputData.needsId != null) {
            this.qqRequest.needs_id = this.inputData.needsId;
        }
        if (this.inputData.needsBenefitId != null) {
            this.qqRequest.needs_benefit_id = this.inputData.needsBenefitId;
        }
        this.qqService.getPricing(this.qqRequest).subscribe(
            response => this.handlePricingResult(response),
            () => this.loading = false
        );
    }

    public qqMlfSubmit(event: Event) {
        event.preventDefault();
        this.loading = true;

        this.qqService.getMlfPricing({
            id: this.qqMlfForm.get('rsaId').value,
            dob: this.mlfIdData.dob,
            age: this.mlfIdData.age,
            gender: this.mlfIdData.gender,
            assumed_income: Number(this.qqMlfForm.get('income').value.replace(/\D+/g, '')),
            product_group: 'SG_MLF',
            product_code: 'MLF'
        }).subscribe(
            response => {
                this.handlePricingResult(response, event);
            },
            () => this.loading = false
        );
    }

    // ====== function called in embedded only =========//
    public sendNoId(): void {
        if (this.noId) {
            this.noId = false;
            this.qqForm.get('rsaId').enable();
        } else {
            this.noId = true;
            this.qqForm.get('rsaId').disable();
            this.sendInvalidReason('Citizenship: Not RSA Citizen', {'idnumber': 'no id'});
        }
    }

    public onIdValidationChange(rsaId: RsaId) {
        if (this.inputData.type === 'mlf') {
            this.mlfIdData.dob = rsaId.getDOB(),
            this.mlfIdData.age = rsaId.getAge();
            this.mlfIdData.gender = rsaId.getGender();
        } else if (!isNullOrUndefined(this.component)) {
            if ((this.component.type === 'mlf' && this.component.name === 'qq-mlf')) {
                this.mlfIdData.dob = rsaId.getDOB(),
                this.mlfIdData.age = rsaId.getAge();
                this.mlfIdData.gender = rsaId.getGender();
            }
        } else {
            if (!isUndefined(rsaId.getAge())) {
                this.qqForm.get('age').setValue(rsaId.getAge());
            }
        }
    }

    public sendTooOld(): void {
        if (this.toOld) {
            this.toOld = false;
            this.qqForm.get('age').enable();
        } else {
            this.toOld = true;
            this.qqForm.get('age').disable();
            this.sendInvalidReason('Age: Over 65');
        }
    }

    public getAgeErrorMessage() {
        if (this.qqForm.get('age').hasError('not_a_number')) {
            return 'Not a valid Age';
        } else if (this.qqForm.get('age').hasError('number_to_small')) {
            return 'Sorry too young';
        } else if (this.qqForm.get('age').hasError('number_to_big')) {
            return 'Sorry too old';
        }
        return '';
    }

    public sendNoIncome(): void {
        if (this.noIncome) {
            this.noIncome = false;
            this.qqForm.get('income').enable();
        } else {
            this.noIncome = true;
            this.qqForm.get('income').disable();
            this.sendInvalidReason('Income: None');
        }
    }

    get invalidLead(): boolean {
        return (this.noId || this.toOld || this.noIncome);
    }

    private sendInvalidReason(reason: string, values?: { [p: string]: any }): void {
        const postData = new PostData();
        postData.age = this.qqForm.get('age').value;
        postData.gender = this.qqForm.get('gender').value;
        if (isUndefined(this.qqForm.get('income').value.replace)) {
            postData.income = this.qqForm.get('income').value;
        } else {
            postData.income = this.qqForm.get('income').value.replace(/\D+/g, '');
        }
        postData.education = this.qqForm.get('education').value;
        postData.smoker_status = this.qqForm.get('smoker').value;
        postData.product_code = this.qqForm.get('product').value;
        postData.campaign_code = this.inputData.campaignCode;
        postData.referrer = this.inputData.httpReferrer;
        postData.referrer_code = this.inputData.referrerCode;
        postData.quickquote_source = this.inputData.source;
        postData.leadid = this.inputData.leadId;
        postData.idnumber = this.qqForm.get('rsaId').value;
        if (!isUndefined(values)) {
            for (const key in values) {
                if (values.hasOwnProperty(key)) {
                    postData[key] = values[key];
                }
            }
        }
        postData.error = reason;
        // noinspection JSIgnoredPromiseFromCall
        this.qqService.postInvalidReason(postData).subscribe(() => {
            this.leadInvalidated = true;
            // post a message to auto wrap sidebar case
            let data = 'invalidate_lead_id';
            if (reason.toLowerCase().indexOf('income') >= 0) {
                data = 'invalidate_lead_income';
            } else if (reason.toLowerCase().indexOf('age') >= 0) {
                data = 'invalidate_lead_age';
            }
            if (!isNullOrUndefined(this.winRef.nativeWindow.parent)) {
                console.log('Sending message: ' + data);
                this.winRef.nativeWindow.parent.postMessage(data, '*');
            }
        });
    }

    public handlePricingResult(response: QQPricingResponseVO | any, event?: Event): void {
        if (event) {
            event.preventDefault();
        }

        this.loading = false;
        if (response !== null && response.qq_type === 'MLF') {
            this.qqMlfResult = this.dialog.open(QqMlfComponent,
                {   data: {
                        pricingResponse: response,
                        mainMemberInformation: {
                            id: this.qqMlfForm.get('rsaId').value,
                            dob: this.mlfIdData.dob,
                            age: this.mlfIdData.age,
                            gender: this.mlfIdData.gender,
                            assumed_income: Number(this.qqMlfForm.get('income').value.replace(/\D+/g, '')),
                        },
                        pageInfo: this.component
                    },
                    disableClose: false,
                    panelClass: 'dl-modal',
                    maxWidth: '99vw',
                }
            );
        } else if (response != null && response.valid) {
            // Got a valid response back. Need to open the cool slider modal.
            this.inputData.needsId = response.needs_id;
            this.inputData.needsBenefitId = response.needs_benefit_id;
            let product: SelectItem;
            for (let i = 0; i < this.products.length; i++) {
                if (this.products[i].value === this.qqForm.get('product').value) {
                    product = this.products[i];
                    break;
                }
            }
            const modalConfig = {
                position: {top: '20px'},
                panelClass: 'dl-modal',
                maxWidth: '99vw'
            };
            if (this._external) {
                this.qqNoSpResult = this.dialog.open(QqResultNoSpComponent,
                    {
                        panelClass: 'dl-modal',
                        maxWidth: '99vw'
                    }
                );
                this.qqNoSpResult.disableClose = true;
                this.qqNoSpResult.componentInstance.openResult(
                    this.inputData, this.qqRequest, this.qqForm.get('rsaId').value, response, product, this);
                this.qqNoSpResult.afterClosed().subscribe(closeEvent => {
                    document.body.style.overflowY = 'inherit';
                    this.resultModalClose(closeEvent);
                    this.qqNoSpResult = undefined;
                });
            } else {
                this.qqResult = this.dialog.open(QQResultComponent, modalConfig);
                this.qqResult.disableClose = true;
                this.qqResult.componentInstance.inputData = this.inputData;
                this.qqResult.componentInstance.openResult(
                    this.qqRequest, this.qqForm.get('rsaId').value, response, product, this);
                this.qqResult.afterClosed().subscribe(closeEvent => {
                    document.body.style.overflowY = 'inherit';
                    this.resultModalClose(closeEvent);
                    this.qqResult = undefined;
                });
            }
            document.body.style.overflowY = 'hidden';
        } else if (response != null && response.errorReason != null) {
            this.errorModalProvider.showErrorMessage(response.errorReason, 'Information');
        }
    }

    public resultModalClose(closeEvent: CloseEventData): void {
        this.qqResult = undefined;
        if (closeEvent.cancel) {
            if (this.inputData.leadCapture) {
                if (!isNullOrUndefined(closeEvent.message)) {
                    this.errorModalProvider.showErrorMessage(closeEvent.message, 'Thank you');
                }
                // reset qq input
                this.qqForm.reset();
                this.qqForm.get('product').setValue(this.inputData.productCode);
            }
            return;
        }
        if (closeEvent.error) {
            this.errorModalProvider.showErrorMessage(closeEvent.message, 'Information');
            return;
        }
        if (closeEvent.mobileWarning) {
            setTimeout(() => {
                this.qqMobileWarning = this.dialog.open(QQMobileWarningComponent,
                    {
                        panelClass: 'dl-modal',
                        maxWidth: '99vw'
                    }
                );
                this.qqMobileWarning.disableClose = true;
                this.qqMobileWarning.componentInstance.showWarning(closeEvent.qqResponse, this);
                this.qqMobileWarning.afterClosed().subscribe(mobileWarningCloseEvent => {
                    document.body.style.overflowY = 'inherit';
                    this.qqMobileWarning = undefined;
                    if (closeEvent.info || closeEvent.error) {
                        this.errorModalProvider.showErrorMessage(mobileWarningCloseEvent.message, 'Information');
                    }
                });
                document.body.style.overflowY = 'hidden';
                // may need to send a Google Tag Custom event for this modal...
            });
        }
    }

    continueToPersonal(result: QQResult): void {
        setTimeout(() => {
            this.winRef.nativeWindow.location = result.url;
        }, 50);
    }

    get autocomplete(): string {
        if (this.inputData.embedded) {
            // according to Mozilla.org set the value to an invalid option to fool the browser, their example is nope
            return 'nope';
        }
        return 'on';
    }

    selectionChange(id: string) {
    }
}
