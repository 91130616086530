import {SelectItem} from './select.vo';

export class BaseDropDownValues {
  public static education: SelectItem[] = [
    {label: 'No Matric', value: 'no_matric'},
    {label: 'Matric', value: 'matric'},
    {value: 'matric_certificate', label: 'Matric plus certificate'},
    {value: 'matric_3_yr_diploma', label: '3 yr Diploma'},
    {value: 'matric_4_yr_diploma', label: '4 yr Diploma'},
    {value: 'degree_3_yr', label: '3 yr Degree'},
    {value: 'degree_4_yr', label: '4 yr Degree'},
    {value: 'degree_post_grad', label: 'Postgraduate Degree'}
  ];

  public static smokerItems: SelectItem[] = [
    {label: 'Smoker', value: 'S'},
    {label: 'Non Smoker', value: 'N'}
  ];

  public static genderItems: SelectItem[] = [
    {label: 'Female', value: 'F'},
    {label: 'Male', value: 'M'}
  ];

  public static yesNo: SelectItem[] = [
    {label: 'Yes', value: 'yes'},
    {label: 'No', value: 'no'}
  ];

  public static getEducationValues(): SelectItem[] {
    return BaseDropDownValues.education;
  }

  public static getSmokerValues(): SelectItem[] {
    return BaseDropDownValues.smokerItems;
  }

  public static getGenderValues(): SelectItem[] {
    return BaseDropDownValues.genderItems;
  }

  public static getYesNoValues(): SelectItem[] {
    return BaseDropDownValues.yesNo;
  }
}
