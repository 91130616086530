import {Component} from '@angular/core';
import {InfoCardComponent} from './info-card.component';
import {DlOfferTextPipe, OfferVO} from '../../base/dl.offer-text.pipe';

@Component({
    selector: 'offer-info-card',
    templateUrl: './info-card.component.html',
    styleUrls: ['./info-card.component.scss']
})
export class OfferInfoCardComponent extends InfoCardComponent {

    get content(): string {
        return new DlOfferTextPipe().transform(this.component.value as OfferVO);
    }

}
