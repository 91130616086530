
        <div class="mlf-summary">
            <div class="row">
                <div class="col s12">
                    <table class="dl-table">
                        <thead>
                        <tr>
                            <th class="text-left">Name</th>
                            <th class="text-right hide-on-small-and-down">Cover Amount</th>
                            <th class="text-right">Premium</th>
                            <th></th>
                        </tr>
                        </thead>
                        <ng-container *ngFor="let addtionalProduct of additionalProducts; let index = index">
                            <tr *ngIf="addtionalProduct.selected">
                                <td class="text-left">
                                    <span class="dl-badge">{{getBadge(addtionalProduct)}}</span>
                                    {{addtionalProduct.name}}
                                </td>
                                <td *ngIf="addtionalProduct.cover" class="text-right hide-on-small-and-down">{{addtionalProduct.cover | dl_currency:true:0}}</td>
                                <td *ngIf="!addtionalProduct.cover" class="text-right hide-on-small-and-down">N/A</td>
                                <td class="text-right">{{addtionalProduct.premium | dl_currency:true:2}}</td>
                                <td class="text-right">
                                    <span *ngIf="index === 0" class="hide-on-small-and-down spacer"></span>
                                    <span *ngIf="index !== 0" class="hide-on-med-and-up"><br></span>
                                    <i *ngIf="index !== 0" (click)="clickRemove(addtionalProduct.id, 'addon')" class="fa fa-times"></i>
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngFor="let dependant of dependants; let index = index">
                            <tr>
                                <td class="text-left">
                                    <span class="dl-badge">{{getBadge(dependant)}}</span>
                                    {{dependant.first_name}} {{dependant.last_name}}
                                    <span class="hide-on-med-and-up"><br>{{dependant.benefit_amount | dl_currency:true:0}}</span>
                                </td>
                                <td class="text-right hide-on-small-and-down">{{dependant.benefit_amount | dl_currency:true:0}}</td>
                                <td class="text-right">{{dependant.premium_amount | dl_currency:true:2}}</td>
                                <td class="text-right">
                                    <i (click)="clickEdit(dependant, index === 0)" class="fa fa-pencil"></i>
                                    <span *ngIf="index === 0" class="hide-on-small-and-down spacer"></span>
                                    <span *ngIf="index !== 0" class="hide-on-med-and-up"><br></span>
                                    <i (click)="clickRemove(dependant.id, 'dependant')" class="fa fa-times"></i>
                                </td>
                            </tr>
                        </ng-container>
                        <tfoot>
                        <tr>
                            <td><b>Total</b></td>
                            <td class="hide-on-small-and-down"></td>
                            <td class="text-right"><b>{{getTotalPremium | dl_currency:true:2}}</b></td>
                            <td></td>
                        </tr>
                        <tfoot>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col s12">
                    <p class="text-center">Do you want to add additional features to your selection? Select one to learn more.</p>
                </div>
                <div class="col s8 offset-s2 m6 offset-m3 l4 offset-l4">
                    <button class="dl-full-width"
                            mat-raised-button
                            tabindex="1"
                            [attr.data-name]="'add'"
                            [attr.data-event]="'true'"
                            [attr.data-event-category]="eventCategory"
                            [attr.data-event-name]="'add-edit dependant'"
                            [attr.data-event-label]="'add-edit dependant'"
                            (click)="clickAdd('addon', 'LLC_AF')">Accelerated Funeral</button>
                </div>
                <div class="col s8 offset-s2 m6 offset-m3 l4 offset-l4">
                    <button class="dl-full-width"
                            mat-raised-button
                            tabindex="1"
                            [attr.data-name]="'add'"
                            [attr.data-event]="'true'"
                            [attr.data-event-category]="eventCategory"
                            [attr.data-event-name]="'add-edit dependant'"
                            [attr.data-event-label]="'add-edit dependant'"
                            (click)="clickAdd('addon', 'LLC_PPPH')">Premium Holiday</button>
                </div>
                <div class="col s8 offset-s2 m6 offset-m3 l4 offset-l4">
                    <button class="dl-full-width"
                            mat-raised-button
                            tabindex="1"
                            [attr.data-name]="'add'"
                            [attr.data-event]="'true'"
                            [attr.data-event-category]="eventCategory"
                            [attr.data-event-name]="'add-edit dependant'"
                            [attr.data-event-label]="'add-edit dependant'"
                            (click)="clickAdd('dependant')">Additional Lives</button>
                </div>
            </div>
        </div>
    