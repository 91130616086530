import {Component, EventEmitter, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatSliderChange} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RsaId, rsaIdValidation, RsaIdValidationCallback} from '../rsa.id.validator';
import {BehaviorSubject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {isNullOrUndefined} from 'util';
import {DLUtil} from '../dl.util';
import {CoverOptionsVO, LLCDependantVO, LLCSimplePricingRequestVO, LlcSummaryService, LLCUtil} from './llc-summary.service';
import {Log} from 'ng2-logger/client';
import {DropDownValuesVO} from '../select.vo';
import {ErrorVO} from '../error/error.vo';
import {PolicyEditService} from '../../profile/action/policy-edit/policy-edit.service';
import {AdditionalProductVO} from '../additional-products/additional-products.service';

const log = Log.create('LLCDependantModalComponent');

@Component({
    selector: 'llc-dependant-modal',
    templateUrl: './llc-dependant-modal.component.html',
    styleUrls: ['../../sp/limited-life-cover-qq/limited-life-quick-quote.component.scss', '../../../scss/dsp/styles.css', '../../../scss/dsp/bootstrap.css']
})
export class LlcDependantModalComponent implements RsaIdValidationCallback {

    form: FormGroup;
    coverOptions$: BehaviorSubject<CoverOptionsVO>;
    sliderPoints: any;
    changeEvents$: EventEmitter<ChangeEvent>;
    isValid = false;
    isBusy = true;
    benefit_amount: number;
    premium_amount: number;
    previouslyDuplicate = false;
    product_master_id: string;
    pricing: any;
    options: any;
    private _more_cover: any;
    private _more_premium: any;

    private value: any | number;
    rsaId = new RsaId();
    productDescriptions: any = {
        LLC_LC_S: 'A <b>Spouse</b> is a person who is married to the policyholder in terms of law, common law, customary or as a partner (lived together for more than 12-months). Age Min: 18, Max: 65',
        LLC_LC_C: 'A <b>Child</b> is a child born to the policyholder or spouse, or partner as well as legally adopted and guardian children. Age Max: 65',
        LLC_LC_E: '<b>Parents / In-Laws</b> are the biological parents of the policyholder, or the biological parents of the policy holders spouse. Age Min: 18, Max: 75'
    };

    constructor(
        private dialogRef: MatDialogRef<LlcDependantModalComponent>,
        private fb: FormBuilder,
        private summaryService: LlcSummaryService,
        private policyEditService: PolicyEditService,
        @Inject(MAT_DIALOG_DATA) public data: LLCModalData
    ) {
        console.log(data);
        if (data.products.length === 0 && !data.edit && !data.main) {
            this.data.products = [
                {
                    clearAll: false,
                    display: 'Spouse',
                    id: 'LLC_F_S',
                    info: null,
                    disabled: false
                },
                {
                    clearAll: false,
                    display: 'Child',
                    id: 'LLC_F_C',
                    info: null,
                    disabled: false
                },
                {
                    clearAll: false,
                    display: 'Parent/In-Law',
                    id: 'LLC_F_E',
                    info: null,
                    disabled: false
                }
            ]
        }
        this.setupForm(data.main);
        if (data.main || data.edit) {
            this.fetchCoverOptions(null, data.dependant.product);
        }
        if (!isNullOrUndefined(data.dependant.benefit_amount)) {
            this.benefit_amount = data.dependant.benefit_amount;
        }
        if (!isNullOrUndefined(data.dependant.premium_amount)) {
            this.premium_amount = data.dependant.premium_amount;
        }
        this.isBusy = false;
    }

    setupForm(main: boolean): void {
        this.coverOptions$ = new BehaviorSubject(new CoverOptionsVO('invalid'));
        this.changeEvents$ = new EventEmitter();
        if (main) {
            log.info('Main Member');
            this.form = this.fb.group({x: ['']});
        } else {
            log.info('Dependant');
            this.form = this.fb.group({
                product: [this.data.dependant.product, [Validators.required]],
                first_name: [this.data.dependant.first_name, [Validators.required]],
                last_name: [this.data.dependant.last_name, [Validators.required]],
                gender: [this.data.dependant.gender, [Validators.required]],
                id_or_dob: [this.data.dependant.product === 'LLC_F_S' || this.data.dependant.product === 'LLC_F_C' || this.data.dependant.product === 'LLC_F_E' ? '' : this.data.dependant.id_or_dob, [Validators.required, Validators.pattern('[0-9]*'), rsaIdValidation(true, this)]]
            });
            this.form.get('first_name').valueChanges.pipe(debounceTime(450)).subscribe(() => this.checkDuplicate(true));
            this.form.get('last_name').valueChanges.pipe(debounceTime(450)).subscribe(() => this.checkDuplicate(true));
            this.form.get('id_or_dob').valueChanges.pipe(debounceTime(450)).subscribe(() => {
                this.checkDuplicate(true);
                this.rsaId.setId(this.form.get('id_or_dob').value);
                if (!isNullOrUndefined(this.rsaId.getGender())) {
                    this.form.get('gender').setValue((this.rsaId.getGender() === 'Male' ? 'M' : 'F'));
                }
            });
            this.form.get('product').valueChanges.subscribe(() => this.productSelected());
            this.form.get('gender').valueChanges.subscribe(() => this.genderChanged());
            // Initialize an Observable for consolidated, debounced change events
            this.changeEvents$.pipe(
                debounceTime(650),
                distinctUntilChanged((a, b) => {
                    return a.rsaId.getId() === b.rsaId.getId() && a.product === b.product && a.gender === b.gender;
                })
            ).subscribe(
                // Update premiums for change events
                ($event) => this.fetchCoverOptions($event.rsaId, $event.product, $event.gender)
            );
        }
    }

    get title(): string {
        if (this.isDependant) {
            return (this.data.edit ? 'Edit' : 'Add') + ' additional life';
        }
        return 'Edit Main Member';
    }

    get saveButtonText(): string {
        return this.data.edit ? 'Save' : 'Add';
    }

    get isDependant(): boolean {
        return !this.data.main;
    }

    cancel(): void {
        this.dialogRef.close('cancel');
    }

    save(): void {
        this.isBusy = true;
        const data = new LLCModalData();
        DLUtil.copyFields(this.data, data);
        data.dependant = new LLCDependantVO();
        DLUtil.copyFields(this.data.dependant, data.dependant);
        DLUtil.copyFields(this.form.value, data.dependant);
        data.dependant.benefit_amount = this.benefit_amount;
        data.dependant.premium_amount = this.premium_amount;
        data.dependant.product_master_id = this.product_master_id;
        data.dependant.pricing = this.pricing;
        data.dependant.product_master_id = this.data.benefitId;
        console.log(data);
        this.dialogRef.close(data);
    }

    get name(): string {
        return 'Hello';
    }

    get minCover(): number {
        if (!isNullOrUndefined(this.options)) {
            if (this.options.length > 1) {
                return this.options[0].cover;
            }
        }
        
        return 0;
    }

    get maxCover(): number {
        if (!isNullOrUndefined(this.options)) {
            if (this.options.length > 1) {
                return this.options[this.options.length - 1].cover;
            }
        }
        
        return 0;
    }

    get increments(): number {
        if (!isNullOrUndefined(this.options) && this.options.length > 1) {
            return (this.options[1].cover - this.options[0].cover);
        }
        return 5000;
    }

    get more_cover(): number {
        return this._more_cover;
    }

    get more_premium(): number {
        return this._more_premium;
    }

    get ageHint(): string {
        if (this.rsaId.isValidDOB()) {
            return 'Age: ' + this.rsaId.getAge() + ', Birthdate: ' + new Date(this.rsaId.getDOB()).toDateString();
        }
        return '';
    }

    get leadName(): string {
        return this.data.leadName;
    }

    onIdValidationChange(rsaId: RsaId): void {
        if (!isNullOrUndefined(this.form) &&
            !isNullOrUndefined(this.form.get('product')) &&
            !isNullOrUndefined(this.form.get('product').value) &&
            !isNullOrUndefined(this.form.get('gender').value)) {
            const product = this.form.get('product').value as string;
            const gender = this.form.get('gender').value as string;
            this.changeEvents$.emit(new ChangeEvent(rsaId, product, gender));
        }
    }

    private checkDuplicate(fetch: boolean): boolean {
        let isDuplicate = false;
        this.data.dependants.forEach(d => {
            if (this.data.dependant.id !== d.id) {
                if (d.first_name === this.form.get('first_name').value &&
                    d.last_name === this.form.get('last_name').value &&
                    d.id_or_dob === this.form.get('id_or_dob').value &&
                    d.gender === this.form.get('gender').value) {
                    log.info('duplicate dependant', d);
                    isDuplicate = true;
                    this.isValid = false;
                    const coverOptions = new CoverOptionsVO('invalid');
                    coverOptions.error = 'Duplicate';
                    this.coverOptions$.next(coverOptions);
                }
            }
        });
        if (!isDuplicate && fetch && this.previouslyDuplicate) {
            const coverOptions = new CoverOptionsVO('invalid');
            this.coverOptions$.next(coverOptions);
            const rsaId = new RsaId();
            rsaId.setId(this.form.get('id_or_dob').value);
            const product = this.form.get('product').value;
            const gender = this.form.get('gender').value;
            this.fetchCoverOptions(rsaId, product, gender);
        }
        this.previouslyDuplicate = isDuplicate;
        return isDuplicate;
    }

    private invalidateSlider(message: string) {
        log.info(message);
        this.coverOptions$.next(new CoverOptionsVO('invalid'));
    }

    private fetchCoverOptions(rsaId: RsaId, product: string, inGender: string = null) {
        let age = 0;
        let gender = null;
        if (!this.data.main && !this.data.edit) {
            if (isNullOrUndefined(product) || product.length === 0) {
                this.invalidateSlider('no product selected');
                return;
            }
            if (isNullOrUndefined(rsaId.getId()) || (rsaId.getId().length > 8 && !rsaId.isValid())) {
                this.invalidateSlider('id is invalid');
                return;
            }
            if (!rsaId.isValidDOB() || isNullOrUndefined(product)) {
                this.invalidateSlider('dob is invalid or no product');
                return;
            }
            age = rsaId.getAge();
            if (isNullOrUndefined(rsaId.getGender())) {
                gender = inGender;
            } else {
                gender = rsaId.getGender() === 'Male' ? 'M' : 'F';
            }
            if (isNullOrUndefined(gender)) {
                this.invalidateSlider('gender is invalid');
                return;
            }
            if (this.checkDuplicate(false)) {
                this.invalidateSlider('');
                return;
            }
        }
        
        if (this.data.edit) {
            if (!isNullOrUndefined(this.data.dependant.gender) || !isNullOrUndefined(this.data.dependant.id_or_dob)) {
                if (this.data.dependant.gender === 'M' || this.data.dependant.gender === 'F') {
                    gender = this.data.dependant.gender;
                } else {
                    switch (this.data.dependant.gender) {
                        case 'Male': gender = 'M';
                            break;
                        case 'Female': gender = 'F';
                            break;
                        default: gender = ''
                            break;
                    }
                }

                this.rsaId.setId(this.data.dependant.id_or_dob);

                age = this.rsaId.getAge();
            }
        }
        log.info('loading cover options for ' + product + ', ' + age);
        this.isValid = false;
        this.form.disable({onlySelf: true, emitEvent: false});
        this.coverOptions$.next(new CoverOptionsVO('loading'));
        const req: LLCSimplePricingRequestVO = {
            spId: this.data.spId,
            benefitId: this.data.benefitId,
            product: product,
            age: age,
            gender: gender
        };

        if (this.data.main) {
            this.policyEditService.getProductLLCPricingData(this.data.benefitId).subscribe(res => {
                // this.updateCoverOptions(rsaId, product, res);
                this.options = res['pricing'][0];
                this.initialSliderValues(res['premium'], res['cover']);
                this.form.enable({onlySelf: true, emitEvent: false});
            }, (error: any) => {
                log.error('Error getting Dependant Info', error);
                this.form.enable({onlySelf: true, emitEvent: false});
                const coverOptions = new CoverOptionsVO('invalid');
                coverOptions.error = ErrorVO.toErrorVO(error).message;
                this.coverOptions$.next(coverOptions);
            });
        }

        if (!this.data.main && this.data.edit) {
            this.summaryService.getPricing(req, 'LLC').subscribe((res) => {
                // this.updateCoverOptions(rsaId, product, res);
                this.options = res.options;
                this.initialSliderValues(this.options[this.options.length - 1].premium, this.options[this.options.length - 1].cover);
                this.form.enable({onlySelf: true, emitEvent: false});
            }, (error: any) => {
                log.error('Error getting Dependant Info', error);
                this.form.enable({onlySelf: true, emitEvent: false});
                const coverOptions = new CoverOptionsVO('invalid');
                coverOptions.error = ErrorVO.toErrorVO(error).message;
                this.coverOptions$.next(coverOptions);
            });
        }

        if (!this.data.main && !this.data.edit) {
            this.summaryService.getPricing(req, 'LLC').subscribe((res) => {
                this.options = res.options;
                this.updateCoverOptions(rsaId, product, res);
    
                this.form.enable({onlySelf: true, emitEvent: false});
            }, (error: any) => {
                this.form.enable({onlySelf: true, emitEvent: false});
    
                const coverOptions = new CoverOptionsVO('invalid');
                
                coverOptions.error = ErrorVO.toErrorVO(error).message;
    
                this.coverOptions$.next(coverOptions);
            });
        }

        // if (this.data.main && this.data.edit) {
        //     this.policyEditService.getProductLLCPricingData(this.data.benefitId).subscribe(res => {
        //         // this.updateCoverOptions(rsaId, product, res);
        //         this.options = res['pricing'][0];
        //         this.initialSliderValues(res['premium'], res['cover']);
        //         this.form.enable({onlySelf: true, emitEvent: false});
        //     }, (error: any) => {
        //         log.error('Error getting Dependant Info', error);
        //         this.form.enable({onlySelf: true, emitEvent: false});
        //         const coverOptions = new CoverOptionsVO('invalid');
        //         coverOptions.error = ErrorVO.toErrorVO(error).message;
        //         this.coverOptions$.next(coverOptions);
        //     });
        // }

        
    }

    productSelected(): void {
        const rsaId = new RsaId();
        rsaId.setId(this.form.get('id_or_dob').value);
        const gender = this.form.get('gender').value;
        const product = this.form.get('product').value;
        this.changeEvents$.emit(new ChangeEvent(rsaId, product, gender));
    }

    initialSliderValues(premium, cover) {
        log.info('Premium and cover data is: ' + premium + ', cover ' + cover);
        if (this.options.length > 1) {
            const initial = Math.ceil(this.options.length * 1);
            this.premium_amount = premium;
            this.benefit_amount = cover;
            this._more_cover = this.options[initial - 1].more_cover;
            this._more_premium = this.options[initial - 1].more_premium;
            this.isBusy = false;
        }
    }

    updateCover($event: MatSliderChange) {
        this.options.forEach((opt) => {
            if ($event.value === opt.cover) {
                this.benefit_amount = opt.cover;
                this.premium_amount = opt.premium;
                this._more_cover = opt.more_cover;
                this._more_premium = opt.more_premium;
            }
        });
    }
    genderChanged(): void {
        const rsaId = new RsaId();
        rsaId.setId(this.form.get('id_or_dob').value);
        let gender = this.form.get('gender').value;
        if (!isNullOrUndefined(rsaId.getGender())) {
            gender = rsaId.getGender() === 'Male' ? 'M' : 'F';
        }
        if (gender !== this.form.get('gender').value) {
            // Flip it back to what ID number says...
            this.form.get('gender').setValue(gender);
            return;
        }
        const product = this.form.get('product').value;
        this.changeEvents$.emit(new ChangeEvent(rsaId, product, gender));
    }

    get productHint(): string {
        const product = this.form.get('product').value;
        if (!isNullOrUndefined(product) && !isNullOrUndefined(this.productDescriptions[product])) {
            return this.productDescriptions[product];
        }
        return null;
    }

    updateCoverOptions(rsaId: RsaId, product: string, res: CoverOptionsVO) {
        log.info('results from pricing', res);
        if (isNullOrUndefined(res)) {
            return;
        }
        if (res.hasOwnProperty('error')) {
            const coverOptions = new CoverOptionsVO('invalid');
            coverOptions.error = res.error;
            this.isValid = false;
            this.coverOptions$.next(coverOptions);
        } else {
            res.status = 'valid';
            if (!isNullOrUndefined(this.benefit_amount) && this.benefit_amount < res.max) {
                res.cover = this.benefit_amount;
            } else {
                res.cover = res.max;
            }
            this.sliderPoints = res.options;
            this.benefit_amount = res.cover;
            this.premium_amount = this.findPremiumForCover(res.cover);
            this.product_master_id = res.product_master_id;
            this.pricing = res.pricing;
            this.isValid = true;
            this.coverOptions$.next(res);
        }
    }

    formatDisplay(value: number | null): string {
        return DLUtil.compactFormat(value, false);
    }

    /* updateCover(val: MatSliderChange): void {
        this.benefit_amount = val.value;
        this.premium_amount = this.findPremiumForCover(val.value);
        this.form.markAsDirty();
    } */

    private findPremiumForCover(cover): number {
        let premium = 0;
        this.sliderPoints.forEach((opt) => {
            if (cover === opt.cover) {
                premium = opt.premium;
            }
        });
        return premium;
    }

    get disableSave(): boolean {
        return !this.isValid;
    }

    getDisabledText(item: DropDownValuesVO) {
        if (item.disabled && !isNullOrUndefined(LLCUtil.validation[item.id])) {
            return ' (Max: ' + LLCUtil.validation[item.id] + ')';
        }
        return '';
    }

}

export class LLCModalData {
    spId: string;
    benefitId: string;
    leadName: string;
    edit: boolean;
    main: boolean;
    dependant: LLCDependantVO;
    products: DropDownValuesVO[];
    dependants: LLCDependantVO[];
}

class ChangeEvent {
    rsaId: RsaId;
    product: string;
    gender: string;

    constructor(rsaId: RsaId, product: string, gender: string) {
        this.rsaId = rsaId;
        this.product = product;
        this.gender = gender;
    }
}
