import {Params} from '@angular/router/src/shared';

export interface SuccessVO {
    success: boolean;
    message?: string;
}

export class BasePayLoad {
    redirect: boolean = false;
    redirectPage?: string;
    redirectParams?: Params;
}
