/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/@angular/material/checkbox/typings/index.ngfactory";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/material/checkbox";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "../../../../node_modules/@angular/material/radio/typings/index.ngfactory";
import * as i7 from "@angular/material/radio";
import * as i8 from "@angular/cdk/collections";
import * as i9 from "@angular/common";
import * as i10 from "./disclosures.component";
var styles_DisclosuresComponent = [];
var RenderType_DisclosuresComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DisclosuresComponent, data: {} });
export { RenderType_DisclosuresComponent as RenderType_DisclosuresComponent };
function View_DisclosuresComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "mat-checkbox", [["class", "mat-checkbox"]], [[8, "id", 0], [2, "mat-checkbox-indeterminate", null], [2, "mat-checkbox-checked", null], [2, "mat-checkbox-disabled", null], [2, "mat-checkbox-label-before", null], [2, "_mat-animation-noopable", null]], [[null, "change"]], function (_v, en, $event) { var ad = true; if (("change" === en)) {
        var pd_0 = ((_v.parent.context.$implicit.answer = ($event.checked ? "ok" : "")) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MatCheckbox_0, i1.RenderType_MatCheckbox)), i0.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.MatCheckbox]), i0.ɵdid(2, 4374528, null, 0, i3.MatCheckbox, [i0.ElementRef, i0.ChangeDetectorRef, i4.FocusMonitor, i0.NgZone, [8, null], [2, i3.MAT_CHECKBOX_CLICK_ACTION], [2, i5.ANIMATION_MODULE_TYPE]], null, { change: "change" }), (_l()(), i0.ɵted(-1, 0, ["OK"]))], null, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).id; var currVal_1 = i0.ɵnov(_v, 2).indeterminate; var currVal_2 = i0.ɵnov(_v, 2).checked; var currVal_3 = i0.ɵnov(_v, 2).disabled; var currVal_4 = (i0.ɵnov(_v, 2).labelPosition == "before"); var currVal_5 = (i0.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }); }
function View_DisclosuresComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "mat-radio-button", [["class", "mat-radio-button"], ["value", "dont_know"]], [[1, "data-val", 0], [2, "mat-radio-checked", null], [2, "mat-radio-disabled", null], [2, "_mat-animation-noopable", null], [1, "id", 0]], [[null, "focus"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1)._inputElement.nativeElement.focus() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatRadioButton_0, i6.RenderType_MatRadioButton)), i0.ɵdid(1, 4440064, [[1, 4]], 0, i7.MatRadioButton, [[2, i7.MatRadioGroup], i0.ElementRef, i0.ChangeDetectorRef, i4.FocusMonitor, i8.UniqueSelectionDispatcher, [2, i5.ANIMATION_MODULE_TYPE]], { value: [0, "value"] }, null), (_l()(), i0.ɵted(-1, 0, ["Maybe"]))], function (_ck, _v) { var currVal_5 = "dont_know"; _ck(_v, 1, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = "dont_know"; var currVal_1 = i0.ɵnov(_v, 1).checked; var currVal_2 = i0.ɵnov(_v, 1).disabled; var currVal_3 = (i0.ɵnov(_v, 1)._animationMode === "NoopAnimations"); var currVal_4 = i0.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
function View_DisclosuresComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "mat-radio-group", [["align", "start"], ["class", "mat-radio-group"], ["role", "radiogroup"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; if (("change" === en)) {
        var pd_0 = ((_v.parent.context.$implicit.answer = $event.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.MatRadioGroup]), i0.ɵdid(2, 1064960, null, 1, i7.MatRadioGroup, [i0.ChangeDetectorRef], null, { change: "change" }), i0.ɵqud(603979776, 1, { _radios: 1 }), (_l()(), i0.ɵeld(4, 0, null, null, 2, "mat-radio-button", [["class", "mat-radio-button"], ["value", "yes"]], [[1, "data-val", 0], [2, "mat-radio-checked", null], [2, "mat-radio-disabled", null], [2, "_mat-animation-noopable", null], [1, "id", 0]], [[null, "focus"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5)._inputElement.nativeElement.focus() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatRadioButton_0, i6.RenderType_MatRadioButton)), i0.ɵdid(5, 4440064, [[1, 4]], 0, i7.MatRadioButton, [[2, i7.MatRadioGroup], i0.ElementRef, i0.ChangeDetectorRef, i4.FocusMonitor, i8.UniqueSelectionDispatcher, [2, i5.ANIMATION_MODULE_TYPE]], { value: [0, "value"] }, null), (_l()(), i0.ɵted(-1, 0, ["Yes"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DisclosuresComponent_4)), i0.ɵdid(8, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 2, "mat-radio-button", [["class", "mat-radio-button"], ["value", "no"]], [[1, "data-val", 0], [2, "mat-radio-checked", null], [2, "mat-radio-disabled", null], [2, "_mat-animation-noopable", null], [1, "id", 0]], [[null, "focus"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i0.ɵnov(_v, 10)._inputElement.nativeElement.focus() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatRadioButton_0, i6.RenderType_MatRadioButton)), i0.ɵdid(10, 4440064, [[1, 4]], 0, i7.MatRadioButton, [[2, i7.MatRadioGroup], i0.ElementRef, i0.ChangeDetectorRef, i4.FocusMonitor, i8.UniqueSelectionDispatcher, [2, i5.ANIMATION_MODULE_TYPE]], { value: [0, "value"] }, null), (_l()(), i0.ɵted(-1, 0, ["No"]))], function (_ck, _v) { var currVal_5 = "yes"; _ck(_v, 5, 0, currVal_5); var currVal_6 = (_v.parent.context.$implicit.type == "YesNoMaybe"); _ck(_v, 8, 0, currVal_6); var currVal_12 = "no"; _ck(_v, 10, 0, currVal_12); }, function (_ck, _v) { var currVal_0 = "yes"; var currVal_1 = i0.ɵnov(_v, 5).checked; var currVal_2 = i0.ɵnov(_v, 5).disabled; var currVal_3 = (i0.ɵnov(_v, 5)._animationMode === "NoopAnimations"); var currVal_4 = i0.ɵnov(_v, 5).id; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_7 = "no"; var currVal_8 = i0.ɵnov(_v, 10).checked; var currVal_9 = i0.ɵnov(_v, 10).disabled; var currVal_10 = (i0.ɵnov(_v, 10)._animationMode === "NoopAnimations"); var currVal_11 = i0.ɵnov(_v, 10).id; _ck(_v, 9, 0, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); }); }
function View_DisclosuresComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "dl-row row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p", [["class", "col sm12 dl-col-lg-12 disclosure-stop-message help-block"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.stop_message; _ck(_v, 2, 0, currVal_0); }); }
function View_DisclosuresComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "dis-question"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "label", [["class", "dis-question-head"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 6, "div", [["class", "dl-row row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "p", [], [[8, "className", 0], [8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 4, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DisclosuresComponent_2)), i0.ɵdid(6, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DisclosuresComponent_3)), i0.ɵdid(8, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DisclosuresComponent_5)), i0.ɵdid(10, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_4 = (_v.context.$implicit.type == "Ok"); _ck(_v, 6, 0, currVal_4); var currVal_5 = (_v.context.$implicit.type != "Ok"); _ck(_v, 8, 0, currVal_5); var currVal_6 = (_v.context.$implicit.answer == "no"); _ck(_v, 10, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 1, 0, currVal_0); var currVal_1 = i0.ɵinlineInterpolate(1, "col ", ((_v.context.$implicit.type == "YesNoMaybe") ? "dl-col-lg-9 m9 s12" : "dl-col-lg-10 m10 s12"), " disclosure-wording"); var currVal_2 = _v.context.$implicit.content; _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_3 = i0.ɵinlineInterpolate(1, "col ", ((_v.context.$implicit.type == "YesNoMaybe") ? "dl-col-lg-3 m3 s12" : "dl-col-lg-2 m2 s12"), " answer-buttons-wrapper"); _ck(_v, 4, 0, currVal_3); }); }
export function View_DisclosuresComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_DisclosuresComponent_1)), i0.ɵdid(1, 278528, null, 0, i9.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disclosures; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_DisclosuresComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "dl-disclosures", [], null, null, null, View_DisclosuresComponent_0, RenderType_DisclosuresComponent)), i0.ɵdid(1, 49152, null, 0, i10.DisclosuresComponent, [], null, null)], null, null); }
var DisclosuresComponentNgFactory = i0.ɵccf("dl-disclosures", i10.DisclosuresComponent, View_DisclosuresComponent_Host_0, { disclosures: "disclosures" }, {}, []);
export { DisclosuresComponentNgFactory as DisclosuresComponentNgFactory };
