/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../base/disclosures/disclosures.component.ngfactory";
import * as i3 from "../../base/disclosures/disclosures.component";
import * as i4 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "./ok-disclosure.component";
import * as i10 from "../../base/disclosures/disclosures.service";
var styles_OkDisclosureComponent = [];
var RenderType_OkDisclosureComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_OkDisclosureComponent, data: {} });
export { RenderType_OkDisclosureComponent as RenderType_OkDisclosureComponent };
function View_OkDisclosureComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "dl-loading"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [["class", "fa fa-circle-o-notch fa-spin"]], null, null, null, null, null))], null, null); }
function View_OkDisclosureComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-chevron-right"]], null, null, null, null, null))], null, null); }
function View_OkDisclosureComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-circle-o-notch fa-spin"]], null, null, null, null, null))], null, null); }
export function View_OkDisclosureComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { disclosuresView: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OkDisclosureComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "dl-disclosures", [], null, null, null, i2.View_DisclosuresComponent_0, i2.RenderType_DisclosuresComponent)), i0.ɵdid(4, 49152, [[1, 4], ["disclosuresView", 4]], 0, i3.DisclosuresComponent, [], { disclosures: [0, "disclosures"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 0, "div", [["class", "dl-col-md-4 col s12 m4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "div", [["class", "dl-col-md-4 col s12 m4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 7, "div", [["class", "dl-col-md-4 col s12 m4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 6, "button", [["class", "dl-full-width dl-action-btn"], ["color", "primary"], ["id", "disclosures-modal-submit"], ["mat-raised-button", ""]], [[1, "data-event", 0], [1, "data-event-category", 0], [1, "data-event-name", 0], [1, "data-event-label", 0], [8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveDisclosures() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i0.ɵdid(9, 180224, null, 0, i5.MatButton, [i0.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i0.ɵted(-1, 0, [" Continue\u00A0 "])), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_OkDisclosureComponent_2)), i0.ɵdid(12, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_OkDisclosureComponent_3)), i0.ɵdid(14, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.component.value; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.component.value; _ck(_v, 4, 0, currVal_1); var currVal_8 = !_co.canSave; var currVal_9 = "primary"; _ck(_v, 9, 0, currVal_8, currVal_9); var currVal_10 = !_co.saving; _ck(_v, 12, 0, currVal_10); var currVal_11 = _co.saving; _ck(_v, 14, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = "true"; var currVal_3 = _co.category; var currVal_4 = "Disclosure Continue"; var currVal_5 = ((_co.category + " Disclosure Continue - ") + _co.component.name); var currVal_6 = (i0.ɵnov(_v, 9).disabled || null); var currVal_7 = (i0.ɵnov(_v, 9)._animationMode === "NoopAnimations"); _ck(_v, 8, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_OkDisclosureComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ok-disclosure-view", [], null, null, null, View_OkDisclosureComponent_0, RenderType_OkDisclosureComponent)), i0.ɵdid(1, 49152, null, 0, i9.OkDisclosureComponent, [i10.DisclosuresService], null, null)], null, null); }
var OkDisclosureComponentNgFactory = i0.ɵccf("ok-disclosure-view", i9.OkDisclosureComponent, View_OkDisclosureComponent_Host_0, { flowId: "flowId", formGroup: "formGroup", component: "component", pageName: "pageName", _productCode: "_productCode", groupId: "groupId", _spMode: "_spMode" }, { change: "change", loaded: "loaded", validated: "validated", resume: "resume", altContinue: "altContinue" }, []);
export { OkDisclosureComponentNgFactory as OkDisclosureComponentNgFactory };
