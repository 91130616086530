var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Dynamic } from './component';
import { isNullOrUndefined } from 'util';
import { FormControl, Validators } from '@angular/forms';
import { PhoneNumberValidator } from '../../base/phone-number';
var TelComponent = /** @class */ (function (_super) {
    __extends(TelComponent, _super);
    function TelComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.telControl = new FormControl('');
        _this.flowId = document.querySelector('dl-dsp').getAttribute('flow-id');
        _this.pageName = document.querySelector('dsp-page').getAttribute('page-name');
        return _this;
    }
    TelComponent.prototype.onChange = function () {
        this.component.isValid = this.telControl.valid;
        this.component.value = this.telControl.value;
        if (!isNullOrUndefined(this.change)) {
            this.change.emit(this.component.counter);
        }
    };
    TelComponent.prototype.getFormControl = function () {
        if (isNullOrUndefined(this.component.required) || this.component.required) {
            this.telControl.setValidators([Validators.required, PhoneNumberValidator.validator()]);
        }
        else {
            this.telControl.setValidators([PhoneNumberValidator.validator()]);
        }
        return this.telControl;
    };
    return TelComponent;
}(Dynamic));
export { TelComponent };
