<div class="dl-mlf-dependant">
    <div mat-dialog-title class="dl-modal-header">
        <a class="close dl-pull-right right" (click)="cancel()"><span class="fa fa-times" tabindex="-1"></span></a>
        <h4 class="modal-title">{{title}}</h4>
    </div>
    <mat-dialog-content>
        <div class="dl-modal-body">
            <form [formGroup]="form" id="dependant">
                <div *ngIf="isDependant">
                    <div class="row">
                        <div class="col s12 m6" data-comp-id="dependant.first_name">
                            <mat-form-field class="dl-full-width">
                                <input matInput
                                       tabindex="1"
                                       placeholder="Name"
                                       [formControl]="form.controls['first_name']"
                                       [attr.data-name]="'first_name'"
                                >
                            </mat-form-field>
                        </div>
                        <div class="col s12 m6" data-comp-id="dependant.last_name">
                            <mat-form-field class="dl-full-width">
                                <input matInput
                                       tabindex="1"
                                       placeholder="Surname"
                                       [formControl]="form.controls['last_name']"
                                       [attr.data-name]="'last_name'"
                                >
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col s12 m6" data-comp-id="dependant.id_number">
                            <mat-form-field class="dl-full-width">
                                <input matInput
                                       tabindex="1"
                                       placeholder="ID Number or Date of Birth (YYYYMMDD)"
                                       [formControl]="form.controls['id_or_dob']"
                                       [attr.data-name]="'id_number'"
                                >
                                <mat-hint>{{ageHint}}</mat-hint>
                                <mat-error align="start">Invalid RSA ID or Date of Birth (YYYYMMDD)</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col s12 m6" data-comp-id="dependant.product">
                            <mat-form-field class="dl-full-width">
                                <mat-select
                                    placeholder="Relationship"
                                    tabindex="1"
                                    [formControl]="form.controls['product']"
                                    [attr.data-name]="'product'"
                                    id="product"
                                    (selectionChange)="selectionChange('product')">
                                    <mat-option
                                        *ngFor="let item of data.products"
                                        [attr.data-val]="item.id"
                                        [disabled]="item.disabled"
                                        [value]="item.id">{{item.display}}{{getDisabledText(item)}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col s12 hide-on-med-and-up" style="margin-top: -20px;">
                            <small [innerHTML]="productHint"></small>
                        </div>
                    </div>
                    <div class="row" style="margin-top: 10px">
                        <div class="col s12 m6" data-comp-id="dependant.gender">
                            <mat-radio-group class="dl-full-width" [formControl]="form.controls['gender']">
                                <mat-radio-button
                                    class="mat-button-toggle-2"
                                    color="primary"
                                    [tabIndex]="1"
                                    [value]="'M'"
                                    [attr.data-val]="'M'">Male</mat-radio-button>
                                <mat-radio-button
                                    class="mat-button-toggle-2"
                                    color="primary"
                                    [tabIndex]="1"
                                    [value]="'F'"
                                    [attr.data-val]="'F'">Female</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="col m6 hide-on-small-and-down" style="margin-top: -30px;">
                            <small [innerHTML]="productHint"></small>
                        </div>
                    </div>
                </div>
                <div *ngIf="coverOptions$ | async as values">
                    <div class="row">
                        <div class="col s12">
                            <span *ngIf="values.status == 'loading'" class="fa fa-circle-o-notch fa-spin"></span>
                            <mat-error *ngIf="values.error" >ERROR: {{ values.error }}</mat-error>
                            <p *ngIf="values.status == 'invalid'">
                                Enter ID number or date of birth, select gender and dependant relationship
                            </p>
                        </div>
                    </div>
                    <div class="row" *ngIf="values.status == 'valid'" style="margin-top: 15px;">
                        <div class="col s12">
                            <p>{{leadName}}, we can offer you
                                <b>{{ benefit_amount | dl_currency:true:0 }}</b> cover for
                                <span *ngIf="isDependant"> this dependant at </span>
                                <b>{{ premium_amount | dl_currency:true:2 }}</b>&nbsp;p/m.
                            </p>
                        </div>
                    </div>
                    <div *ngIf="values.status == 'valid' && values.min !== values.max">
                        <div class="row">
                            <div class="col s12">
                                <mat-slider
                                    [invert]="false"
                                    [max]="values.max"
                                    [min]="values.min"
                                    [step]="values.step"
                                    [thumbLabel]="true"
                                    [displayWith]="formatDisplay"
                                    [value]="values.cover"
                                    (input)="updateCover($event)">
                                </mat-slider>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col s12" style="margin-top: -15px;">
                                <div class="left">{{values.min | dl_currency:true:0}}</div>
                                <div class="right">{{values.max | dl_currency:true:0}}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col s12">
                                <small>Change slider to change the cover amount</small>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="dl-modal-footer">
            <div class="dl-container-no-padding">
                <div class="row">
                    <div class="col m4 s12"></div>
                    <div class="col m4 s12">
                        <button
                            mat-raised-button
                            class="dl-action-btn dl-full-width"
                            tabindex="-1"
                            [attr.data-name]="'cancel'"
                            (click)="cancel()">Cancel</button>
                    </div>
                    <div class="col m4 s12">
                        <button
                            mat-raised-button
                            color="primary"
                            class="dl-action-btn dl-full-width"
                            tabindex="1"
                            [disabled]="disableSave"
                            [attr.data-name]="'save'"
                            (click)="save()">{{saveButtonText}}&nbsp;<i *ngIf="isBusy" class="fa fa-circle-o-notch fa-spin"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
</div>
