import {Component, Input} from '@angular/core';

@Component({
    selector: 'dl-reset-password',
    templateUrl: './reset.password.component.html'
})
export class ResetPasswordComponent {

    _action: string;
    @Input()
    set action(val: string) {
        this._action = val;
    }
    get action(): string {
        return this._action;
    }

    _orgUrl: string;
    @Input()
    set orgUrl(value: string) {
        this._orgUrl = value;
    }
    get orgUrl(): string {
        return this._orgUrl;
    }

    _oauthClientId: string;
    @Input()
    set oauthClientId(value: string) {
        this._oauthClientId = value;
    }
    get oauthClientId(): string {
        return this._oauthClientId;
    }

    _token: string;
    @Input()
    set token(val: string) {
        this._token = val;
    }
    get token(): string {
        return this._token;
    }

    _userName: string;
    @Input()
    set userName(val: string) {
        this._userName = val;
    }
    get userName(): string {
        return this._userName;
    }

    _errorMsg: string;
    @Input()
    set errorMessage(val: string) {
        this._errorMsg = val;
    }
    get errorMessage(): string {
        return this._errorMsg;
    }
}
