import {ChangeDetectorRef, Component, NgZone} from '@angular/core';
import {Dynamic} from './component';
import {FormControl} from '@angular/forms';
import {Log} from 'ng2-logger/client';
import {PageComponentVO, StripeInput} from '../page.data.vo';
import {StripeScriptService} from '../../base/stripe/stripe.script.service';
import {StripeComponentState} from '../../base/stripe/stripe.types';
import {st} from '@angular/core/src/render3';

const log = Log.create('StripeElementComponent');

@Component({
    selector: 'stripe-input',
    templateUrl: 'stripe.element.component.html'
})
export class StripeElementComponent extends Dynamic<PageComponentVO> {

    error: string;
    busy = false;

    cardValidation = false;

    stripInput: StripeInput;

    constructor(private cd: ChangeDetectorRef,
                private zone: NgZone,
                private stripeScript: StripeScriptService) {
        super();
    }

    getFormControl(): FormControl {
        // This component will mage its own validation.
        return null;
    }

    onStripeElementStatusChange(status: StripeComponentState): void {
        this.busy = status.busy;
        this.error = status.error;
        this.component.isValid = status.valid;
        if (status.token !== null && status.token !== undefined) {
            this.component.value = status.token;
        }
        if (!status.busy) {
            if (this.cardValidation) {
                this.broadCastCardSubmitted();
            } else {
                this.broadCastStateChanged();
            }
        }
    }

    // need to override
    setupFromControl() {
        this.stripInput = this.component.extraInput as StripeInput;
        this.component.isValid = false;
        this.component.loaded = true;
        this.loaded.emit(this.component.id);
    }

    validate(): boolean {
        this.cardValidation = true;
        this.stripeScript.submitCard();
        return true;
    }

    broadCastStateChanged() {
        this.change.emit(this.component.counter);
        setTimeout(() => {
           this.zone.run(() => {
               log.data('Do zone redraw');
           });
        });
    }

    broadCastCardSubmitted(): void {
        this.validated.emit({componentId: this.component.id, success: this.component.isValid});
        this.cardValidation = false;
    }
}
