<div id="mobileWarningModal" style="max-width: 350px">
    <div id="mobile_popup">
        <div mat-dialog-title class="dl-modal-header">
            <h4 class="modal-title">Please note the following
              <button
                type="button"
                class="close right"
                aria-label="Close"
                [disabled]="busy"
                [attr.data-event]="'true'"
                [attr.data-event-category]="'Quick Quote'"
                [attr.data-event-name]="'Mobile Warning Close'"
                [attr.data-event-label]="'Quick Quote Mobile Warning Close'"
                (click)="close()">
                <span class="fa fa-times" aria-hidden="true"></span>
              </button>
            </h4>
        </div>
        <div mat-dialog-content class="dl-modal-body">
            <div id="inital">
                <p><strong>Completing this process on mobile devices may not be very efficient.</strong></p>
                <p>We suggest letting us call you or continuing from the email we have just sent you.</p>
                <button
                        mat-raised-button
                        type="button"
                        id="continueCall"
                        class="dl-full-width dl-action-btn"
                        [disabled]="busy"
                        [attr.data-event]="'true'"
                        [attr.data-event-category]="'Quick Quote'"
                        [attr.data-event-name]="'Mobile Warning Call Me'"
                        [attr.data-event-label]="'Quick Quote Mobile Warning Call Me'"
                        (click)="callMe()">
                    {{pleaseCallBtnText}}
                    <i *ngIf="loadingCall" class="fa fa-circle-o-notch fa-spin"></i>
                </button>
                <br/>
                <button
                        mat-raised-button
                        type="button"
                        id="continueEmail"
                        class="dl-full-width dl-action-btn"
                        [disabled]="busy"
                        [attr.data-event]="'true'"
                        [attr.data-event-category]="'Quick Quote'"
                        [attr.data-event-name]="'Mobile Warning Email Me'"
                        [attr.data-event-label]="'Quick Quote Mobile Warning Email Me'"
                        (click)="emailMe()">I'll continue from email
                </button>
                <button
                        mat-raised-button
                        color="primary"
                        type="button"
                        id="continueMobile"
                        class="dl-full-width dl-action-btn"
                        [disabled]="busy"
                        [attr.data-event]="'true'"
                        [attr.data-event-category]="'Quick Quote'"
                        [attr.data-event-name]="'Mobile Warning Continue'"
                        [attr.data-event-label]="'Quick Quote Mobile Warning Continue'"
                        (click)="continueToSp()">
                    Continue on mobile
                    <i *ngIf="!continuing" class="fa fa-chevron-right"></i>
                    <i *ngIf="continuing" class="fa fa-circle-o-notch fa-spin"></i>
                </button>
            </div>
        </div>
    </div>
</div>
