import {isNullOrUndefined, isUndefined} from 'util';
import {AbstractControl, NG_VALIDATORS, Validator, ValidatorFn} from '@angular/forms';
import {Directive, EventEmitter, Input, Output} from '@angular/core';
import {Log} from 'ng2-logger/browser';

const log = Log.create('rsa-id-validator');

/**
 * Created by corneliusbotha on 2017/02/25.
 * This is type script version created form the rsaid.js file.
 */


export class RsaId {

    private idNumber: string;
    private age: number;
    private gender: number;
    private dobYear: number;
    private dobMonth: number;
    private dobDay: number;
    private citizen: number;
    private checkSum: number;

    public static isValidDate(year: number, month: number, day: number): boolean {
        if (year < 1900 || year > new Date().getFullYear()) {
            log.error('invalid year');
            return false;
        }
        if (month < 1 || month > 12 || (year === new Date().getFullYear() && month > (new Date().getMonth() + 1))) {
            log.error('invalid month');
            return false;
        }
        if (day < 1 || day > 31 || (month === (new Date().getMonth() + 1) && day > new Date().getDate() && year >= new Date().getFullYear())) {
            log.error('invalid day');
            return false;
        }

        if ((month === 4 || month === 6 || month === 9 || month === 11) && day === 31) {
            log.error('invalid day');
            return false;
        }
        if (month === 2) {
            // check for february 29th
            const isleap = (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0));
            if (day > 29 || (day === 29 && !isleap)) {
                log.error('invalid day');
                return false;
            }
        }
        return true;
    }

    public static getAge(dobYear: number, dobMonth: number, dobDay: number, nextMonth: boolean): number {
        if (!this.isValidDate(dobYear, dobMonth, dobDay)) {
            return null;
        }
        const today = new Date();
        if (nextMonth) {
            // move the date to the 1st of next month
            today.setDate(1);
            // changing month from 12 to 11 as the Javascript Date.getMonth starts at 0
            if (today.getMonth() === 11) {
                today.setFullYear(today.getFullYear() + 1);
                today.setMonth(0);
            } else {
                today.setMonth(today.getMonth() + 1);
            }
        }
        let age = today.getFullYear() - dobYear;
        // Adding 2 rather than 1 to accomodate for month starting at 0
        if (dobMonth === (today.getMonth() + 2)) {
            if (dobDay && dobDay >= today.getDate()) {
                // not past dob day remove a year
                age--;
            }
        } else if (dobMonth > (today.getMonth() + 2)) { // Adding 2 rather than 1 to accomodate for month starting at 0
            // not past the dob month remove a year
            age--;
        }
        return age;
    }

    private resetField(): void {
        this.idNumber = undefined;
        this.age = undefined;
        this.gender = undefined;
        this.dobYear = undefined;
        this.dobMonth = undefined;
        this.dobDay = undefined;
        this.citizen = undefined;
        this.checkSum = undefined;
    }

    private extractAge(): void {
        let cent = '19';

        if (this.idNumber.length > 8) {
            if (parseInt(this.idNumber.substr(0, 2), 10) <
                parseInt(String(new Date().getFullYear()).substr(0, 2), 10)) {
                cent = '20';
            }

            this.dobYear = parseInt(cent + this.idNumber.substr(0, 2), 10);
            this.dobMonth = parseInt(this.idNumber.substr(2, 2), 10);
            this.dobDay = parseInt(this.idNumber.substr(4, 2), 10);
        } else {
            cent = String(parseInt(this.idNumber.substr(0, 4).substr(0, 2), 10));

            this.dobYear = parseInt(cent + this.idNumber.substr(0, 4).substr(2, 2), 10);
            this.dobMonth = parseInt(this.idNumber.substr(4, 2), 10);
            this.dobDay = parseInt(this.idNumber.substr(6, 2), 10);
        }

        this.age = RsaId.getAge(this.dobYear, this.dobMonth, this.dobDay, true);
    }

    public setId(idNumber: string): void {
        this.resetField();
        if (isUndefined(idNumber) || idNumber == null) {
            return;
        }
        this.idNumber = idNumber;
        // extract the date of birth and calculate the age
        this.extractAge();
        // extract the gender
        if (this.idNumber.length >= 10) {
            this.gender = parseInt(idNumber.substr(6, 4), 10);
        }

        // extract the citizen
        if (this.idNumber.length >= 12) {
            this.citizen = parseInt(idNumber.substr(10, 2), 10);
        }

        // extract the checkSum
        if (this.idNumber.length >= 12) {
            this.checkSum = parseInt(idNumber.substr(12, 1), 10);
        }
    }

    public getId(): string {
        return this.idNumber;
    }

    public getDOB(): string {
        if (isUndefined(this.getDOBYear()) || isUndefined(this.getDOBMonth()) || isUndefined(this.getDOBDay())) {
            return undefined;
        }
        return this.getDOBYear() + '-' + this.getDOBMonth() + '-' + this.getDOBDay();
    }

    public getDOBYear(): string {
        if (isUndefined(this.dobYear) || this.dobYear == null) {
            return undefined;
        }
        return String(this.dobYear);
    }

    public getDOBMonth() {
        if (isUndefined(this.dobMonth) || this.dobMonth == null) {
            return undefined;
        }
        if (this.dobMonth < 10) {
            return '0' + String(this.dobMonth);
        }
        return String(this.dobMonth);
    }

    public getDOBDay(): string {
        if (this.dobDay === undefined || this.dobDay == null) {
            return undefined;
        }
        if (this.dobDay < 10) {
            return '0' + String(this.dobDay);
        }
        return String(this.dobDay);
    }

    public getAge(): number {
        return this.age;
    }

    public getGender(): string {
        if (isUndefined(this.gender) || this.gender == null) {
            return undefined;
        }
        if (this.gender >= 5000) {
            return 'Male';
        }
        return 'Female';
    }

    public isValidDOB(): boolean {
        if (this.getDOBYear() === undefined || this.getDOBMonth() === undefined || this.getDOBDay() === undefined) {
            return false;
        }
        return RsaId.isValidDate(parseInt(this.getDOBYear(), 10), parseInt(this.getDOBMonth(), 10), parseInt(this.getDOBDay(), 10));
    }



    public isValid(): boolean {
        if (isUndefined(this.idNumber)) {
            return false;
        }
        if (this.idNumber.length === 13 && this.isValidDOB()) {
            if (this.gender < 0 || this.gender >= 10000) {
                log.error('invalid gender');
                return false;
            }

            /* GET CHECKSUM VALUE */
            let check_sum_odd = 0;
            let check_sum_even = 0;
            let check_sum_even_temp = '';
            let check_sum_value: string;
            let count: number;
            // Get ODD Value
            for (count = 0; count < 11; count += 2) {
                check_sum_odd += parseInt(this.idNumber.substr(count, 1), 10);
            }
            // Get EVEN Value
            for (count = 0; count < 12; count += 2) {
                check_sum_even_temp = check_sum_even_temp + this.idNumber.substr(count + 1, 1);
            }
            check_sum_even_temp = String(parseInt(check_sum_even_temp, 10) * 2);
            for (count = 0; count < check_sum_even_temp.length; count++) {
                check_sum_even += parseInt(check_sum_even_temp.substr(count, 1), 10);
            }
            // GET Checksum Value
            check_sum_value = String(check_sum_odd + check_sum_even);
            check_sum_value = check_sum_value + 'xxx';
            check_sum_value = String(10 - (parseInt(check_sum_value.substr(1, 1), 10)));
            if (check_sum_value === '10') {
                check_sum_value = '0';
            }
            /* DO CHECKSUM TEST */
            if (check_sum_value !== String(this.checkSum)) {
                log.error('Checksum not valid');
                return false;
            }
            return true;
        }
        return false;
    }
}

/**
 * Created by corneliusbotha on 2017/02/19.
 * Class used to validate min max values.
 */
@Directive({
    selector: '[validateRsaId]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: RSAIdValidator, multi: true }
    ]
})
export class RSAIdValidator implements Validator {

    @Input('allowDOB') allowDOB = false;
    @Output('afterValidationCallback') afterValidationCallback: EventEmitter<RsaId> = new EventEmitter();

    validate(c: AbstractControl): {[p: string]: any} {
        if (!isUndefined(c.value) && c.value != null) {
            const rsaId = new RsaId();
            rsaId.setId(c.value);
            if (this.allowDOB) {
                if (c.value.length === 6 && rsaId.isValidDOB()) {
                    this.afterValidationCallback.emit(rsaId);
                    return null;
                }
            }
            if (!rsaId.isValid()) {
                this.afterValidationCallback.emit(rsaId);
                return {'not_a_valid_id': true};
            }
            this.afterValidationCallback.emit(rsaId);
            return null;
        }
        return null;
    }
}

export interface RsaIdValidationCallback {
    onIdValidationChange(rsaId: RsaId): void;
}

export function rsaIdValidation(allowDOB: boolean = false, afterValidationCallback: RsaIdValidationCallback,
                                minAge?: number, maxAge?: number): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
        if (!isNullOrUndefined(control.value) && control.value !== '') {
            const rsaId = new RsaId();
            rsaId.setId(control.value);
            if (allowDOB) {
                if ((control.value.length === 6 || control.value.length === 8) && rsaId.isValidDOB()) {
                    if (!isNullOrUndefined(afterValidationCallback)) {
                        afterValidationCallback.onIdValidationChange(rsaId);
                    }
                    if (!isNullOrUndefined(minAge) && rsaId.getAge() < minAge) {
                        return {'too_young': true};
                    }
                    if (!isNullOrUndefined(maxAge) && rsaId.getAge() > maxAge) {
                        return {'too_old': true};
                    }
                    return null;
                }
            }
            if (!rsaId.isValid()) {
                if (!isNullOrUndefined(afterValidationCallback)) {
                    afterValidationCallback.onIdValidationChange(rsaId);
                }
                return {'not_a_valid_id': true};
            }
            if (!isNullOrUndefined(afterValidationCallback)) {
                afterValidationCallback.onIdValidationChange(rsaId);
            }
            if (!isNullOrUndefined(minAge) && rsaId.getAge() < minAge) {
                return {'too_young': true};
            }
            if (!isNullOrUndefined(maxAge) && rsaId.getAge() > maxAge) {
                return {'too_old': true};
            }
            return null;
        }
        return {'Entry is required': true};
    };
}

export function rsaIdDateOfBirthValidation(minAge?: number, maxAge?: number): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {

        if (!isNullOrUndefined(control.value) && control.value !== '') {
            const rsaId = new RsaId();
            rsaId.setId(control.value);
            if (!rsaId.isValid()) {
                if (rsaId.isValidDOB() && control.value.length === 6) {
                    if (!isNullOrUndefined(minAge) && rsaId.getAge() < minAge) {
                        return {'too_young': true};
                    }
                    if (!isNullOrUndefined(maxAge) && rsaId.getAge() > maxAge) {
                        return {'too_old': true};
                    }
                    return null;
                }
                if (control.value.length === 8) {
                    const year = parseInt(control.value.substring(0, 4), 10);
                    const month = parseInt(control.value.substring(4, 6), 10);
                    const day = parseInt(control.value.substring(6, 8), 10);
                    if (isNaN(year) || isNaN(month) || isNaN(day)) {
                        return {'not_a_valid_dob': true};
                    } else {
                        if (RsaId.isValidDate(year, month, day)) {
                            const age = RsaId.getAge(year, month, day, true);
                            if (!isNullOrUndefined(minAge) && age < minAge) {
                                return {'too_young': true};
                            }
                            if (!isNullOrUndefined(maxAge) && age > maxAge) {
                                return {'too_old': true};
                            }
                            return null;
                        }
                        return {'not_a_valid_dob': true};
                    }
                }
                return {'not_a_valid_id': true};
            }
            if (!isNullOrUndefined(minAge) && rsaId.getAge() < minAge) {
                return {'too_young': true};
            }
            if (!isNullOrUndefined(maxAge) && rsaId.getAge() > maxAge) {
                return {'too_old': true};
            }
            return null;
        }
        return null;
    };
}
