var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { InfoCardComponent } from './info-card.component';
import { AjaxService } from './ajax.service';
import { ParentEventService } from './component';
import { MLFBaseSummaryComponent } from '../../base/mlf-summary/mlf-base-summary.component';
import { DspService } from '../dsp.service';
import { DLUtil } from '../../base/dl.util';
var MLFSummaryComponent = /** @class */ (function (_super) {
    __extends(MLFSummaryComponent, _super);
    function MLFSummaryComponent(ajaxService, dspService, parentEventService) {
        var _this = _super.call(this, parentEventService) || this;
        _this.ajaxService = ajaxService;
        _this.dspService = dspService;
        return _this;
    }
    Object.defineProperty(MLFSummaryComponent.prototype, "dependants", {
        get: function () {
            return this.component.valueExtra;
        },
        set: function (val) {
            this.component.valueExtra = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MLFSummaryComponent.prototype, "products", {
        get: function () {
            return this.component.extraInput;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MLFSummaryComponent.prototype, "spId", {
        get: function () {
            return this.dspService.currentData.spId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MLFSummaryComponent.prototype, "benefitId", {
        get: function () {
            return this.dspService.currentData.benefitId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MLFSummaryComponent.prototype, "leadName", {
        get: function () {
            return this.dspService.currentData.leadName;
        },
        enumerable: true,
        configurable: true
    });
    MLFSummaryComponent.prototype.add = function (dependant) {
        this.sendAjaxUpdateRequest(dependant, 'updateDependantBenefit');
    };
    MLFSummaryComponent.prototype.edit = function (main, dependant) {
        var method = 'updateDependantBenefit';
        if (main) {
            method = 'updateMainBenefit';
        }
        this.sendAjaxUpdateRequest(dependant, method);
    };
    MLFSummaryComponent.prototype.sendAjaxUpdateRequest = function (dependant, method) {
        var _this = this;
        this.isBusy = true;
        this.parentEventService.publishPageBusyStatus(this.isBusy);
        var req = {
            method: method,
            data: {
                dependant: dependant
            }
        };
        this.ajaxService.postRequest(req).subscribe(function (res) {
            var updated = false;
            _this.dependants.forEach(function (d) {
                if (d.id === res.id) {
                    updated = true;
                    DLUtil.copyFields(res, d);
                }
            });
            if (!updated) {
                _this.dependants.push(res);
            }
            _this.isBusy = false;
            _this.parentEventService.publishPageBusyStatus(_this.isBusy);
        }, function () {
            _this.isBusy = false;
            _this.parentEventService.publishPageBusyStatus(_this.isBusy);
        });
    };
    MLFSummaryComponent.prototype.remove = function (dependant) {
        var _this = this;
        this.isBusy = true;
        this.parentEventService.publishPageBusyStatus(this.isBusy);
        var req = {
            method: 'removeDependantBenefit',
            data: {
                id: dependant.id
            }
        };
        this.ajaxService.postRequest(req).subscribe(function (res) {
            _this.dependants = MLFBaseSummaryComponent.removeDependant(_this.dependants, res.id);
            _this.isBusy = false;
            _this.parentEventService.publishPageBusyStatus(_this.isBusy);
        });
    };
    return MLFSummaryComponent;
}(InfoCardComponent));
export { MLFSummaryComponent };
