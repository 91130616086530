import {Injectable} from '@angular/core';
import {DisclosuresService} from '../base/disclosures/disclosures.service';
import {ErrorModalProvider} from '../base/error/error.modal.service';
import {MatDialog, MatDialogRef} from '@angular/material';
import {DisclosuresCallback, DisclosuresModalComponent} from '../base/disclosures/disclosures-modal.component';
import {DisclosureCategory, DisclosureType, DisclosureVO} from '../base/disclosures/disclosures.vos';
import {isNullOrUndefined} from 'util';
import {SpDataService} from './sp.data.service';
import {WindowRef} from '../base/window.ref';

// TODO:: need to find a way to extend the provider in the disclosures module.

@Injectable({
  providedIn: 'root'
})
export class SpDisclosuresProvider {

    private dialogConfig: any = {
        disableClose: true,
        position: {top: '20px'},
        maxWidth: '99vw',
        panelClass: 'dl-modal'
    };
    private modalRef: MatDialogRef<DisclosuresModalComponent> = null;

    constructor(
        private disclosuresService: DisclosuresService,
        private errorModalProvider: ErrorModalProvider,
        private spDataService: SpDataService,
        private dialog: MatDialog,
        private windowRef: WindowRef
    ) {}

    public setSpMode(mode: string): void {
        this.disclosuresService.setSpMode(mode);
    }

    private createModal(): void {
        if (isNullOrUndefined(this.modalRef) || isNullOrUndefined(this.modalRef.componentInstance)) {
            this.modalRef = this.dialog.open(DisclosuresModalComponent, this.dialogConfig);
        }
        this.modalRef.afterClosed().subscribe(() => {
            if (!isNullOrUndefined(this.modalRef)) {
                this.windowRef.nativeWindow.scrollTo(0, 0);
            }
            this.modalRef = null;
        });
    }

    public showEntry(title: string, page: string): void {
        if (this.spDataService.getEntryDisclosures().length > 0) {
            // show modal then load data
            this.createModal();
            this.setModalItems(title, page, DisclosureType.ENTRY, null);
            this.handleResult(title, page, DisclosureType.ENTRY, null, this.spDataService.getEntryDisclosures());
        } else {
            if (!isNullOrUndefined(this.modalRef)) {
                this.modalRef.close();
            }
        }
    }

    public showExit(title: string, page: string, data: any, callback: DisclosuresCallback): void {
        this.getData(title, page, DisclosureType.EXIT, data, callback);
    }

    private getData(title: string, page: string, type: string, data: any, callback: DisclosuresCallback): void {
        // add the sales process id to the data
        if (isNullOrUndefined(data)) {
            data = {};
        }
        data.sales_process_id = this.spDataService.getSalesProcessID();
        // get the disclosures from the server
        this.disclosuresService.getDisclosureGroup(DisclosureCategory.SALES_PROCESS, page, type, data).subscribe(response => {
            this.handleResult(title, page, type, callback, response.disclosures);
        });
    }

    private setModalItems(title: string, page: string, type: string, callback: DisclosuresCallback): void {
        this.modalRef.disableClose = true;
        this.modalRef.componentInstance.saving = false;
        if (isNullOrUndefined(title) || title.length === 0) {
            this.modalRef.componentInstance.title = DisclosuresModalComponent.DEFAULT_TITLE;
        } else {
            this.modalRef.componentInstance.title = title;
        }
        this.modalRef.componentInstance.prefix = DisclosureCategory.SALES_PROCESS;
        this.modalRef.componentInstance.category = DisclosureCategory.SALES_PROCESS;
        this.modalRef.componentInstance.page = page;
        this.modalRef.componentInstance.type = type;
        this.modalRef.componentInstance.callback = callback;
    }

    private handleResult(title: string, page: string, type: string, callback: DisclosuresCallback, result: DisclosureVO[]): void {
        // apply sp id to all disclosures
        result.forEach(dis => {dis.sales_process_id = this.spDataService.getSalesProcessID()});
        // on entry already has modal open, the others we load data 1st then show modal
        if (isNullOrUndefined(this.modalRef)) {
            this.createModal();
            callback.onDisclosuresLoaded();
            this.setModalItems(title, page, type, callback);
        }
        this.modalRef.componentInstance.disclosures = result;
        // remove the spinner. Needed when we load more than one disclosure.
        this.modalRef.componentInstance.saving = false;
        setTimeout(() => {
            this.windowRef.nativeWindow.document.querySelector('mat-dialog-content').scrollTop = 0;
        });
    }

    public close() {
        if (!isNullOrUndefined(this.modalRef)) {
            this.modalRef.close();
        }
    }

    public setCanClose(can: boolean, canAlways: boolean = false) {
        if (!isNullOrUndefined(this.modalRef)) {
            this.modalRef.componentInstance.canClose = can;
            this.modalRef.componentInstance.canCloseAlways = canAlways;
        }
    }

    public stopSavingSpinner() {
        if (!isNullOrUndefined(this.modalRef)) {
            this.modalRef.componentInstance.saving = false;
        }
    }

}
