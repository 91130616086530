var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { DspInjectable, DspService, httpOptions } from '../dsp.service';
import { HttpClient } from '@angular/common/http';
import { ErrorModalProvider } from '../../base/error/error.modal.service';
import { catchError } from 'rxjs/operators';
import { AjaxRequestVO } from '../page.data.vo';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../base/error/error.modal.service";
import * as i3 from "../dsp.service";
var AjaxService = /** @class */ (function (_super) {
    __extends(AjaxService, _super);
    function AjaxService(http, errorModalProvider, dspService) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.errorModalProvider = errorModalProvider;
        _this.dspService = dspService;
        _this.url = _this.baseUrl + '/ajax';
        return _this;
    }
    AjaxService.prototype.postRequest = function (val) {
        var data = AjaxRequestVO.fromPageInfo(this.dspService.currentData, val);
        return this.http.post(this.url, data, httpOptions).pipe(catchError(this.handleError(this.errorModalProvider)));
    };
    AjaxService.ngInjectableDef = i0.defineInjectable({ factory: function AjaxService_Factory() { return new AjaxService(i0.inject(i1.HttpClient), i0.inject(i2.ErrorModalProvider), i0.inject(i3.DspService)); }, token: AjaxService, providedIn: "root" });
    return AjaxService;
}(DspInjectable));
export { AjaxService };
