import {Component} from '@angular/core';
import {CloseEventData, QQResult} from './qq.vo';
import {QQService} from './qq.service';
import {MatDialogRef} from '@angular/material';
import {WindowRef} from '../base/window.ref';
import {QQContinue} from './qq.component';

/**
 * Created by corneliusbotha on 2017/02/24.
 * Modal component that will pop up to give mobile user a few extra options
 */
@Component({
    templateUrl: './qq.mobile.warning.component.html',
    selector: 'qq-mobile-warning'
})
export class QQMobileWarningComponent {

    loadingCall: boolean = false;
    continuing: boolean = false;
    pleaseCallBtnText: string = 'Please call me';
    private tmpPleaseCallBtnText: string;
    private qqResult: QQResult;
    private qqContinue: QQContinue;

    constructor(
        private winRef: WindowRef,
        private qqService: QQService,
        public qqWarningDialogRef: MatDialogRef<QQMobileWarningComponent>
    ) {}

    public showWarning(qqResult: QQResult, qqContinue: QQContinue) {
        this.continuing = false;
        this.qqResult = qqResult;
        this.qqContinue = qqContinue;
    }

    private resetCallMeButtonAndHide(): void {
        this.loadingCall = false;
        this.pleaseCallBtnText = this.tmpPleaseCallBtnText;
    }

    close(): void {
        this.qqWarningDialogRef.close(CloseEventData.createCancelEvent());
    }

    callMe(): void {
        this.tmpPleaseCallBtnText = this.pleaseCallBtnText;
        this.pleaseCallBtnText = 'Sending info... ';
        this.loadingCall = true;
        this.qqService.requestCallMe().subscribe(
            () => {
                this.resetCallMeButtonAndHide();
                this.qqWarningDialogRef.close(CloseEventData.createInvoEvent('Thank you, we will be in contact shortly.'));
            },
            () => {
                this.resetCallMeButtonAndHide();
                this.qqWarningDialogRef.close(CloseEventData.createErrorEvent('We regrettably could not process your request.'));
            }
        );
    }

    emailMe(): void {
        this.qqWarningDialogRef.close(CloseEventData.createInvoEvent('Thank you, we have sent you an email with a link in it. ' +
            'You can, at any time, continue this process on a larger device.'));
    }

    continueToSp(): void {
        this.continuing = true;
        // We are now moving to the SP:
        this.qqContinue.continueToPersonal(this.qqResult);
    }

    get busy(): boolean {
        return this.continuing || this.loadingCall
    }
}
