import {Component, Input} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ResetPasswordInfo, ResetPasswordService} from './reset.password.service';
import {PasswordMatchValidator} from '../base/passwordMatch.validator';
import {isNullOrUndefined} from 'util';
import {WindowRef} from '../base/window.ref';
import {LoginService, SignInProcessor} from './login.service';

@Component({
    selector: 'dl-set-password',
    templateUrl: './set.password.component.html'
})
export class SetPasswordComponent implements SignInProcessor {

    setPasswordForm: FormGroup;
    busy = false;
    errorMsg = '';

    _orgUrl: string;
    @Input()
    set orgUrl(value: string) {
        this._orgUrl = value;
    }
    get orgUrl(): string {
        return this._orgUrl;
    }

    _oauthClientId: string;
    @Input()
    set oauthClientId(value: string) {
        this._oauthClientId = value;
    }
    get oauthClientId(): string {
        return this._oauthClientId;
    }

    _token: string;
    @Input()
    set token(val: string) {
        this._token = val;
    }
    get token(): string {
        return this._token;
    }

    _userName: string;
    @Input()
    set userName(val: string) {
        this._userName = val;
    }
    get userName(): string {
        return this._userName;
    }

    get passwordHelpText(): string {
        return PasswordMatchValidator.passwordPatternHelpText();
    }

    constructor(private resetPasswordService: ResetPasswordService,
                private loginService: LoginService,
                private winRef: WindowRef) {
        this.setupForm();
    }

    private setupForm() {
        this.setPasswordForm = new FormGroup({
            password: new FormControl('', [
                Validators.required,
                Validators.pattern(PasswordMatchValidator.passwordPattern()),
            ]),
            confirmPassword: new FormControl('', [Validators.required])
        }, PasswordMatchValidator.matchPasswords());
    }

    sendReset(): void {
        this.busy = true;
        const data: ResetPasswordInfo = {
            action: 'reset',
            username: this.userName,
            token: this.token,
            password: this.setPasswordForm.get('password').value
        };
        this.resetPasswordService.resetPassword(data).subscribe(
            res => this.handleResetResponse(res),
            () => this.busy = false);
    }

    private handleResetResponse(res: ResetPasswordInfo): void {
        if (!isNullOrUndefined(res.success) && (res.success || res.success === 'true')) {
            if (!isNullOrUndefined(this.orgUrl) && this.orgUrl !== '') {
                this.loginService.loginToOrg(this.orgUrl, this.oauthClientId, this.userName,
                    this.setPasswordForm.get('password').value, this).subscribe(() => {},
                    () => {
                        this.processSignIn(false, 'Error communicating to the login server, please try again');
                    });
            } else {
                this.processSignIn(true);
            }
        } else {
            this.processSignIn(false, res.message);
        }
    }

    processSignIn(success: boolean, message?: string): void {
        if (success) {
            this.winRef.nativeWindow.location = '/dl/profile';
        } else {
            this.errorMsg = message;
        }
        this.busy = false;
    }
}
