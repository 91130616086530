import {Dynamic} from './component';
import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {PageComponentVO, ProductBenefitVO} from '../page.data.vo';
import {DspService} from '../dsp.service';
import {MatSliderChange, MatRadioChange} from '@angular/material';
import {DLUtil} from '../../base/dl.util';

@Component({
    templateUrl: 'llc-main.component.html',
    selector: 'llc-main',
    styleUrls: ['./info-card.component.scss']
})
export class LLCMainComponent extends Dynamic<PageComponentVO> implements OnInit {
    coverOrPremium = 'Cover';
    previousCoverSelection_premium_amount: number;
    previousCoverSelection_benefit_amount: number;
    previousPremiumSelection_premium_amount: number;
    previousPremiumSelection_benefit_amount: number;

    private _show_options: boolean;

    constructor(private dspService: DspService) {
        super();
    }

    setupFromControl() {
        this.component.loaded = true;
        // tel my parent I'm loaded.
        this.loaded.emit(this.component.id);
    }

    @Input('show-options')
    set ptyName(val: boolean) {
        this._show_options = val;
    }
    get ptyName(): boolean {
        return this._show_options;
    }

    initialSliderValues() {
        if (this.val.options[0].length > 1) {
            const initial = Math.ceil(this.val.options[0].length * 1);
            this.val.premium_amount = this.val.options[0][initial - 1].premium_amount;
            this.val.benefit_amount = this.val.options[0][initial - 1].benefit_amount;
            this.previousCoverSelection_benefit_amount = this.val.benefit_amount;
            this.previousCoverSelection_premium_amount = this.val.premium_amount;
            const initialP = Math.ceil(this.val.options[1].length * 1);
            this.previousPremiumSelection_benefit_amount = this.val.options[1][initialP - 1].benefit_amount;
            this.previousPremiumSelection_premium_amount = this.val.options[1][initialP - 1].premium_amount;
        }
    }

    ngOnInit() {
        // Called after the constructor and called  after the first ngOnChanges()
        this.initialSliderValues();
    }

    getFormControl(): FormControl {
        return undefined;
    }

    get val(): ProductBenefitVO {
        return this.component.value as ProductBenefitVO;
    }

    get name(): string {
        return this.dspService.currentData.leadName;
    }

    get minCover(): number {
        if (this.isCover()) {
            return this.val.options[0][0].benefit_amount;
        } else if (this.isPremium()) {
            return this.val.options[1][0].benefit_amount;
        }
    }

    get maxCover(): number {
        if (this.isCover()) {
            return this.val.options[0][this.val.options[0].length - 1].benefit_amount;
        } else if (this.isPremium()) {
            return this.val.options[1][this.val.options[1].length - 1].benefit_amount;
        }
    }

    get minPremium(): number {
        if (this.isCover()) {
            return this.val.options[0][0].premium_amount;
        } else if (this.isPremium()) {
            return this.val.options[1][0].premium_amount;
        }
    }

    get maxPremium(): number {
        if (this.isCover()) {
            return this.val.options[0][this.val.options[0].length - 1].premium_amount;
        } else if (this.isPremium()) {
            return this.val.options[1][this.val.options[1].length - 1].premium_amount;
        }
    }

    get increments(): number {
        if (this.isCover()) {
            if (this.val.options[0].length > 1) {
                return (this.val.options[0][1].benefit_amount - this.val.options[0][0].benefit_amount);
            }
            return 5000;
        } else if (this.isPremium()) {
            if (this.val.options[1].length > 1) {
                return (this.val.options[1][1].premium_amount - this.val.options[1][0].premium_amount);
            }
            return 5;
        } else {
            return 5000;
        }
    }

    formatDisplay(value: number | null): string {
        return DLUtil.compactFormat(value, false);
    }

    switchValues($event: MatRadioChange) {
        if ($event.source.name === 'rgCPSwitcher') {
            if (this.isCover()) {
                this.val.benefit_amount = this.previousCoverSelection_benefit_amount;
                this.val.premium_amount = this.previousCoverSelection_premium_amount;
            } else if (this.isPremium()) {
                this.val.benefit_amount = this.previousPremiumSelection_benefit_amount;
                this.val.premium_amount = this.previousPremiumSelection_premium_amount;
            }
        }
    }

    updateCover(val: MatSliderChange): void {
        if (this.isCover()) {
            this.val.options[0].forEach((opt) => {
                if (val.value === opt.benefit_amount) {
                    this.val.benefit_amount = opt.benefit_amount;
                    this.val.premium_amount = opt.premium_amount;
                    this.previousCoverSelection_benefit_amount = this.val.benefit_amount;
                    this.previousCoverSelection_premium_amount = this.val.premium_amount;
                }
            });
        } else if (this.isPremium()) {
            this.val.options[1].forEach((opt) => {
                if (val.value === opt.premium_amount) {
                    this.val.benefit_amount = opt.benefit_amount;
                    this.val.premium_amount = opt.premium_amount;
                    this.previousPremiumSelection_benefit_amount = this.val.benefit_amount;
                    this.previousPremiumSelection_premium_amount = this.val.premium_amount;
                }
            });
        }
    }

    get fullCover(): number {
        const basePremium = this.val.premium_amount;
        const baseCover = -((basePremium * this.val.denominatorFactor) + this.val.numeratorFactor) / this.val.multiplierFactor;
        let cover = baseCover; // * productData.coverEscalationFactor;
        cover = (Math.round(cover * 100) / 100);
        return cover;
    }

    isCover(): boolean {
        return this.coverOrPremium === 'Cover';
    }

    isPremium(): boolean {
        return this.coverOrPremium === 'Premium';
    }

}
