import {Dynamic} from './component';
import {FormControl} from '@angular/forms';
import {Component} from '@angular/core';
import {MatchMediaService} from '../../base/match.media.service';
import {DateAdapter} from '@angular/material/core';
import {DateValidationVO, PageComponentVO} from '../page.data.vo';
import {LocaleHelper} from '../../base/locale.helper';
import {DLUtil} from '../../base/dl.util';

@Component({
    selector: 'date-picker-input',
    templateUrl: 'date-picker.component.html'
})
export class DatePickerComponent extends Dynamic<PageComponentVO> {

    constructor(private matchMedia: MatchMediaService,
                private adapter: DateAdapter<any>) {
        super();
        this.adapter.setLocale(LocaleHelper.getCountryCode());
    }

    dateControl: FormControl = new FormControl('');
    _dateValidationVO: DateValidationVO = new DateValidationVO();
    set dateValidationVO(val: DateValidationVO) {
        this._dateValidationVO = val;
    }
    get dateValidationVO() {
        return this._dateValidationVO;
    }

    onChange(): void {
        if (this.dateControl.value !== undefined && this.dateControl.value !== null && this.dateControl.value !== '') {
            const date = new Date(this.dateControl.value);
            this.component.value = DLUtil.formatDateToString(date);
        } else {
            this.component.value = '';
        }
        this.component.isValid = this.dateControl.valid;
        if (this.change !== null && this.change !== undefined) {
            this.change.emit(this.component.counter);
        }
    }

    getFormControl(): FormControl {
        return this.dateControl;
    }

    setInitialValue(): void {
        if (this.component.initValue !== undefined && this.component.initValue !== null && this.component.initValue !== '') {
            const date = new Date(this.component.initValue);
            this.dateControl.setValue(date);
        }
        this.dateValidationVO = this.component.extraInput as DateValidationVO;
        if (this.dateValidationVO.pickerStartView === undefined || this.dateValidationVO.pickerStartView === null) {
            this.dateValidationVO.pickerStartView = 'month';
        }
    }
}
