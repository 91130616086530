import { Log } from 'ng2-logger/browser';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var log = Log.create('DonutService');
var DonutService = /** @class */ (function () {
    function DonutService(http) {
        this.http = http;
    }
    DonutService.prototype.getData = function (url) {
        if (url === 'test') {
            return of(this.getTestData());
        }
        else if (url === 'test1') {
            return of(this.getTest1Data());
        }
        else {
            return this.http.get(url);
        }
    };
    DonutService.prototype.getTestData = function () {
        log.info('Loading Test Data');
        var data = new GraphData();
        data.name = 'Test';
        data.centerText = 'Test Center';
        for (var i = 0; i < 4; i++) {
            var item = new GraphItem();
            item.id = 'test_' + i;
            item.label = 'Test label ' + i;
            item.description = 'Description - ' + i;
            item.value = 24 * (i + 1);
            switch (i) {
                case 0:
                    item.colour = '#f44842';
                    break;
                case 1:
                    item.colour = '#f4b841';
                    break;
                case 2:
                    item.colour = '#f4f141';
                    break;
                case 3:
                    item.colour = '#46f441';
                    break;
            }
            data.data.push(item);
        }
        return data;
    };
    DonutService.prototype.getTest1Data = function () {
        log.info('Loading Test Data');
        var data = new GraphData();
        data.name = 'Test';
        data.centerText = '';
        for (var i = 0; i < 4; i++) {
            var item = new GraphItem();
            item.id = 'test2_' + i;
            item.label = 'Test 2 label ' + i;
            item.description = 'Description 2 - ' + i;
            item.value = 45 * (i + 10);
            switch (i) {
                case 0:
                    item.colour = '#f4b841';
                    break;
                case 1:
                    item.colour = '#f4f141';
                    break;
                case 2:
                    item.colour = '#f44842';
                    break;
                case 3:
                    item.colour = '#46f441';
                    break;
            }
            data.data.push(item);
        }
        return data;
    };
    DonutService.ngInjectableDef = i0.defineInjectable({ factory: function DonutService_Factory() { return new DonutService(i0.inject(i1.HttpClient)); }, token: DonutService, providedIn: "root" });
    return DonutService;
}());
export { DonutService };
var GraphItem = /** @class */ (function () {
    function GraphItem() {
    }
    return GraphItem;
}());
export { GraphItem };
var GraphData = /** @class */ (function () {
    function GraphData() {
        this.data = [];
    }
    return GraphData;
}());
export { GraphData };
