<div [formGroup]="requestForm">
    <div *ngIf="showMailSent === false">
        <div class="dl-row row" *ngIf="errorMsg !== ''">
            <div class="dl-col-md-12 col s12">
                <p class="mat-warn">ERROR: {{ errorMsg }}</p>
            </div>
        </div>
        <div class="dl-row row" style="padding-bottom: 18px;">
            <div class="dl-col-md-12 col s12">
                <mat-form-field class="dl-full-width">
                    <input
                        matInput
                        required
                        placeholder="Email"
                        id="email"
                        type="text"
                        [formControl]="requestForm.controls['email']">
                    <mat-error align="start">Invalid email address</mat-error>
                    <mat-hint>Please enter your email address to reset your password</mat-hint>
                </mat-form-field>
            </div>
        </div>
        <div class="dl-row row" *ngIf="showIdField" style="padding-bottom: 18px;">
            <div class="dl-col-md-12 col s12">
                <mat-form-field class="dl-full-width">
                    <input
                        matInput
                        required
                        placeholder="RSA Id number"
                        id="rsaId"
                        type="text"
                        [formControl]="requestForm.controls['idNum']">
                    <mat-error align="start" *ngIf="idErrorMessage != ''">{{idErrorMessage}}</mat-error>
                    <mat-hint>We found multiple accounts for that email address, please provide your id number</mat-hint>
                </mat-form-field>
            </div>
        </div>
        <div class="dl-row row">
            <div class="dl-col-md-8 col m8 s12 offset-m4">
                <button
                    mat-raised-button
                    color="primary"
                    class="dl-full-width dl-action-btn dl-continue-btn"
                    type="submit"
                    id="login-continue"
                    [attr.data-event]="'sendRequest'"
                    [attr.data-event-category]="'Reset Password'"
                    [attr.data-event-name]="'Reset Password'"
                    [attr.data-event-label]="'Request reset password'"
                    [disabled]="requestForm.invalid"
                    (click)="sendRequest()">
                    Send Reset Email&nbsp;
                    <i *ngIf="!busy" class="fa fa-chevron-right"></i>
                    <i *ngIf="busy" class="fa fa-circle-o-notch fa-spin"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="dl-row row" *ngIf="showMailSent">
        <div class="col-md-12 col s12">
            <p>An email with a reset <i>link</i> is on its way to: <b>{{ requestForm.get('email').value }}</b></p>
        </div>
    </div>
</div>
