import {Injectable} from '@angular/core';
import {isNullOrUndefined, isUndefined} from 'util';
import {Log} from 'ng2-logger/browser';
import {DisclosureVO} from '../base/disclosures/disclosures.vos';

const log = Log.create('PageData');

export class PageRightBar {
    image: string;
}

export class PageDataVO {
    private _salesProcessId: string;
    private _secGroup = '';
    /** this will tell the app to behave as embedded, external or web */
    private _mode = 'web';
    private _titleSuffix = '';
    private _leadId = '';
    private _contactId = '';
    private _contactBlocked = false;
    private _policyId = '';
    private _crmUserId = '';
    private _ngoCode = '';
    private _entryDisclosures: DisclosureVO[] = [];
    private _rightbar: PageRightBar;
    private _gender: string;
    private _loginPage = false;
    private _baseAuthUrl = '';
    private _oauthId = '';
    private _ssoLogin = '';

    // this is a placeholder for the page payload to go to the server
    private _payload: any;

    constructor() {
        log.info('Page Data constructed');
    }

    get secGroup(): string {
        return this._secGroup;
    }

    set secGroup(value: string) {
        this._secGroup = value;
    }

    get salesProcessId(): string {
        return this._salesProcessId;
    }

    set salesProcessId(value: string) {
        log.info('Page Data set SPID');
        if (!isUndefined(value)) {
            this._salesProcessId = value;
        }
    }

    get mode(): string {
        return this._mode;
    }

    set mode(value: string) {
        log.info('Page Data Set Mode');
        if (!isUndefined(value)) {
            this._mode = value;
        }
    }

    get titleSuffix(): string {
        return this._titleSuffix;
    }

    set titleSuffix(value: string) {
        this._titleSuffix = value;
    }

    get leadId(): string {
        return this._leadId;
    }

    set leadId(value: string) {
        log.info('Page Data set LeadID : ' + value);
        if (!isUndefined(value)) {
            this._leadId = value;
        }
    }

    get contactId(): string {
        return this._contactId;
    }

    set contactId(value: string) {
        log.info('Page Data set contact ID : ' + value);
        if (!isUndefined(value)) {
            this._contactId = value;
        }
    }

    get contactBlocked(): boolean {
        return this._contactBlocked;
    }

    set contactBlocked(value: boolean) {
        if (!isUndefined(value)) {
            this._contactBlocked = value;
        }
    }

    get policyId(): string {
        return this._policyId;
    }

    set policyId(value: string) {
        if (!isUndefined(value)) {
            this._policyId = value;
        }
    }

    get crmUserId(): string {
        return this._crmUserId;
    }

    set crmUserId(value: string) {
        if (!isUndefined(value)) {
            this._crmUserId = value;
        }
    }

    get ngoCode(): string {
        return this._ngoCode;
    }

    set ngoCode(value: string) {
        if (!isUndefined(value)) {
            this._ngoCode = value;
        }
    }

    get payload(): any {
        return this._payload;
    }

    set payload(value: any) {
        if (!isUndefined(value)) {
            this._payload = value;
        }
    }

    get entryDisclosures(): DisclosureVO[] {
        return this._entryDisclosures;
    }

    set entryDisclosures(value: DisclosureVO[]) {
        this._entryDisclosures = value;
    }

    get rightbar(): PageRightBar {
        return this._rightbar;
    }

    set rightbar(value: PageRightBar) {
        this._rightbar = value;
    }

    get gender(): string {
        return this._gender;
    }

    set gender(value: string) {
        this._gender = value;
    }

    get loginPage(): boolean {
        return this._loginPage;
    }

    set loginPage(value: boolean) {
        this._loginPage = value;
    }

    setLoginPage(value: any) {
        if (!isNullOrUndefined(value)) {
            if (value === true || value === 'true' || value === '1' || value === 1) {
                this._loginPage = true;
                return;
            }
        }
        this._loginPage = false;
    }

    set baseAuthUrl(value: string) {
        this._baseAuthUrl = value;
    }

    get baseAuthUrl(): string {
        return this._baseAuthUrl;
    }

    set oauthId(value: string) {
        this._oauthId = value;
    }

    get oauthId(): string {
        return this._oauthId;
    }

    set ssoLogin(value: string) {
        this._ssoLogin = value;
    }

    get ssoLogin(): string {
        return this._ssoLogin;
    }

    public toJsonVersion(): any {
        let jsonObj: any = {
            salesProcessId: this.salesProcessId,
            mode: this.mode,
            leadId: this.leadId,
            contactId: this.contactId,
            contactBlocked: this.contactBlocked,
            policyId: this.policyId,
            crmUserId: this.crmUserId,
            ngoCode: this.ngoCode,
            payload: this.payload,
        };
        return jsonObj;
    }
}

@Injectable({
    providedIn: 'root'
})
export class SpDataService {

    public pageData: PageDataVO;
    private _loadingBusy: boolean;
    private _continueBusy: boolean;
    public caseTypeVal: string;

    constructor() {
        log.info('Constructed SpData Service');
        this.pageData = new PageDataVO();
    }

    /**
     * Get the current page state data
     * @returns {PageDataVO}
     */
    public getPageData(): PageDataVO {
        return this.pageData;
    }

    public updateBaseData(baseData: PageDataVO) {
        this.pageData.entryDisclosures = []; // always clear the entry disclosures
        for (let key in baseData) {
            let value = baseData[key];
            if (value != null) {
                this.pageData[key] = value;
            }
        }
    }

    public setSalesProcessId(spId: string) {
        this.pageData.salesProcessId = spId;
    }

    public setMode(mode: string) {
        this.pageData.mode = mode;
    }

    public setLeadId(leadId: string) {
        this.pageData.leadId = leadId;
    }

    public setContactId(contactId: string) {
        this.pageData.contactId = contactId;
    }

    public setContactBlocked(contactBlocked: boolean) {
        this.pageData.contactBlocked = contactBlocked;
    }

    public setPolicyId(policyId: string) {
        this.pageData.policyId = policyId;
    }

    public getPolicyId(): string {
        return this.pageData.policyId;
    }

    public setCrmUserId(crmUserId: string) {
        this.pageData.crmUserId = crmUserId;
    }

    public setNgoCode(ngoCode: string) {
        this.pageData.ngoCode = ngoCode;
    }

    public getSalesProcessID(): string {
        return this.pageData.salesProcessId;
    }

    public getMode(): string {
        return this.pageData.mode;
    }

    public isEmbedded(): boolean {
        console.log('isEmbedded: ' + this.pageData.mode);
        console.log('isEmbedded: ' + this.pageData.mode === 'embedded');
        if (!isNullOrUndefined(this.pageData.mode)) {
            return this.pageData.mode === 'embedded';
        }
        return false;
    }

    public isExternal(): boolean {
        if (!isNullOrUndefined(this.pageData.mode)) {
            return this.pageData.mode === 'external';
        }
        return false;
    }

    public getEntryDisclosures(): DisclosureVO[] {
        if (isNullOrUndefined(this.pageData.entryDisclosures)) {
            return [];
        }
        return this.pageData.entryDisclosures;
    }

    public setEntryDisclosures(entryDisclosures: DisclosureVO[]) {
        this.pageData.entryDisclosures = entryDisclosures;
    }

    public getTitleSuffix(): string {
        return this.pageData.titleSuffix;
    }

    get loadingBusy(): boolean {
        return this._loadingBusy;
    }

    set loadingBusy(value: boolean) {
        this._loadingBusy = value;
    }

    get continueBusy(): boolean {
        return this._continueBusy;
    }

    set continueBusy(value: boolean) {
        this._continueBusy = value;
    }

    getGender() {
        return this.pageData.gender;
    }

    getSecurityGroup(): string {
        return this.pageData.secGroup;
    }

    getCaseType(): string {
        return this.caseTypeVal;
    }
}
