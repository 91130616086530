var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Dynamic } from './component';
import { FormControl, Validators } from '@angular/forms';
import { PageComponentVO } from '../page.data.vo';
import { Log } from 'ng2-logger/client';
var log = Log.create('PantSizeComponent');
var PantSizeComponent = /** @class */ (function (_super) {
    __extends(PantSizeComponent, _super);
    function PantSizeComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.min = 2;
        _this.max = 50;
        _this.radioGroupControl = new FormControl('');
        _this.textControl = new FormControl('', [Validators.min(_this.min), Validators.max(_this.max)]);
        _this.pantSizeGroupControl = new FormControl('');
        _this.validationMessage = undefined;
        _this.selectorRadioText = ['Pant Size Number', 'Pant Size'];
        _this.pantSizeSelectorValues = ['Extra Extra Small or Smaller', 'Extra Small', 'Small',
            'Medium', 'Large', 'Extra Large', 'Extra Extra Large', 'Extra Extra Extra Large or Larger'];
        _this.currentMethodSelector = 0;
        return _this;
    }
    PantSizeComponent.prototype.onPantSizeSelectorChange = function (event) {
        log.info('Pantsize Selector Value : ' + event.value);
        this.component.value = event.value;
        this.component.isValid = true;
        this.validationMessage = this.buildValidationMessage();
    };
    PantSizeComponent.prototype.onPantSizeNumericChange = function () {
        log.info('Numeric Selector' + this.textControl.value);
        this.component.value = this.textControl.value;
        if (this.textControl.value >= 2 && this.textControl.value <= 50) {
            this.component.isValid = true;
        }
        else {
            this.component.isValid = false;
        }
        this.validationMessage = this.buildValidationMessage();
    };
    PantSizeComponent.prototype.optionChange = function (event) {
        this.component.isValid = false;
        log.info('Option Update Event' + event.value);
        this.currentMethodSelector = event.value === 'Pant Size' ? 1 : 2;
        this.validationMessage = this.buildValidationMessage();
    };
    PantSizeComponent.prototype.getFormControl = function () {
        return this.radioGroupControl;
    };
    PantSizeComponent.prototype.processExtraData = function () {
        this.validationMessage = this.buildValidationMessage();
    };
    PantSizeComponent.prototype.buildValidationMessage = function () {
        if (this.currentMethodSelector <= 1) {
            return this.component.name + ' is required';
        }
        else if (this.currentMethodSelector === 2) {
            return this.component.name + ' must be between ' + this.min + ' and ' + this.max;
        }
    };
    PantSizeComponent.prototype.setInitialValue = function () {
    };
    return PantSizeComponent;
}(Dynamic));
export { PantSizeComponent };
