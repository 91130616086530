import {Component, OnInit} from '@angular/core';
import {Log} from 'ng2-logger/browser';
import {PageComponentVO} from '../../dsp/page.data.vo';
import {FormControl, Validators} from '@angular/forms';
import {RsaId, rsaIdValidation, RsaIdValidationCallback} from '../../base/rsa.id.validator';
import {catchError} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {BaseService} from '../../base/base.service';
import {ErrorModalProvider} from '../../base/error/error.modal.service';
import {MatDialog, MatSliderChange} from '@angular/material';
import {DLUtil} from '../../base/dl.util';
import {WindowRef} from '../../base/window.ref';

const log = Log.create('king-price-ll-life-id');

@Component({
    selector: 'limited-life-quick-quote',
    templateUrl: './limited-life-quick-quote.component.html',
    styleUrls: ['./limited-life-quick-quote.component.scss', '../../../scss/dsp/styles.css', '../../../scss/dsp/bootstrap.css']
})
export class LimitedLifeQuickQuoteComponent extends BaseService implements OnInit, RsaIdValidationCallback {
    rsaIdControl: FormControl = new FormControl('', [Validators.required, Validators.pattern('[0-9]*'), rsaIdValidation(false, this)]);
    ageControl: FormControl = new FormControl('', [Validators.required, Validators.pattern('[0-9]*'), Validators.max(64), Validators.min(18)]);
    genderControl: FormControl = new FormControl('', [Validators.required]);
    valid = false;
    // inputData: InputData;
    // qqNoSpResult: MatDialogRef<QqResultNoSpComponent>;
    private _component: PageComponentVO;
    feedback: string = '';
    id: string;
    dob: string;
    age: number;
    dobMonth: string;
    private _name: string;
    benefit_amount: any;
    premium_amount: any;
    private _maxCover: any;
    private _minCover: any;
    private _increments: any;
    private value: any | number;
    assumedIncome = 10000;
    radioGender: string;
    options: any;
    showResults = false;
    loading = false;
    private _more_cover: any;
    private _more_premium: any;

    get component(): PageComponentVO {
        return this._component;
    }

    set component(value: PageComponentVO) {
        this._component = value;
    }

    constructor(
        protected httpClient: HttpClient,
        protected errorModalProvider: ErrorModalProvider,
        public dialog: MatDialog,
        public winRef: WindowRef
    ) {
        super(httpClient, errorModalProvider);
        // console.log('Constructor called');
    }

    ngOnInit() {
        this.valid = false;
        // console.log('On init method called');
    }

    getFormControl(): FormControl {
        return this.rsaIdControl;
    }

    onIdValidationChange(rsaId: RsaId): void {
        this.valid = rsaId.isValid();
        if (rsaId.isValid()) {
            this.feedback = rsaId.getDOB() + ', aged ' + rsaId.getAge() + ' years';
            this.id = rsaId.getId();
            this.dob = rsaId.getDOB();
            this.age = rsaId.getAge();
            this.dobMonth = rsaId.getDOBMonth();
        } else {
            this.feedback = 'Provided ID number is invalid';
        }
    }

    validate() {
        this.feedback = !this.ageControl.valid ? 'Age should be from 18 - 64' : !this.genderControl.valid ? 'Please select a gender' : '';
        this.valid = this.ageControl.valid && this.genderControl.valid ? true : false;
    }

    submit() {
        this.loading = true;
        const url = '/dl/data/needs/simple-qq';
        this.httpClient.post(url, {
            // 'id': this.id,
            // 'dob': this.dob,
            'age': this.ageControl.value,
            'gender': this.genderControl.value,
            'assumed_income': this.assumedIncome,
            'product_group': 'SG_LLC_F',
            'product_code': 'LLC_F'
        }, this.httpOptions()).subscribe(
            res => this.handleSuccess(res),
            () => catchError(this.handleHTTPError<LimitedLifeQQResult>())
        );
    }

    extractGender() {
        switch (this.genderControl.value) {
            case 'M': return 'Male';
            case 'F': return 'Female';
        }
        // const genderChar = this.id.substring(6, 7);
        // return Number(genderChar) >= 5 ? 'Male' : 'Female';
    }

    handleSuccess(response: any) {
        this.options = response['options'];
        this.showResults = true;
        this.loading = false;
        this.initialSliderValues();
    }

    initialSliderValues() {
        if (this.options.length > 1) {
            const initial = Math.ceil(this.options.length * 1);
            this.premium_amount = this.options[initial - 1].premium;
            this.benefit_amount = this.options[initial - 1].cover;
            this._more_cover = this.options[initial - 1].more_cover;
            this._more_premium = this.options[initial - 1].more_premium;
        }
    }

    updateCover($event: MatSliderChange) {
        this.options.forEach((opt) => {
            if ($event.value === opt.cover) {
                this.benefit_amount = opt.cover;
                this.premium_amount = opt.premium;
                this._more_cover = opt.more_cover;
                this._more_premium = opt.more_premium;
            }
        });
    }

    get name(): string {
        return 'Hello';
    }

    get minCover(): number {
        return this.options[0].cover;
    }

    get maxCover(): number {
        return this.options[this.options.length - 1].cover;
    }

    get increments(): number {
        if (this.options.length > 1) {
            return (this.options[1].cover - this.options[0].cover);
        }
        return 5000;
    }

    get more_cover(): number {
        return this._more_cover;
    }

    get more_premium(): number {
        return this._more_premium;
    }

    formatDisplay(value: number | null): string {
        return DLUtil.compactFormat(value, false);
    }

    moreCover() {
        this.continueToLife('/buy/express-life-cover');
        // this.navigate('/buy/express-life-cover');
    }

    buyNow() {
        this.continueToLife('/buy/express-life-funeral');
        // this.navigate('/buy/express-life-funeral');
    }

    continueToLife(location: string) {
        this.loading = true;
        const url = '/dl/data/needs/limited-life-qq-continue-to-life';
        this.httpClient.post(url, {
            'person': {
                // 'id': this.id,
                // 'dob': this.dob,
                'age': this.ageControl.value,
                'gender': this.genderControl.value,
                'assumed_income': this.assumedIncome,
                // 'dobMonth': this.dobMonth
            }, 
            // 'id': this.id, 
            'selected_premium': this.premium_amount, 
            'selected_cover': this.benefit_amount
        }, this.httpOptions()).subscribe((res) => this.navigate(location));
    }

    navigate(url: string): void {
        this.winRef.nativeWindow.location = url;
    }
}

export interface LimitedLifeQQResult {
    success: boolean;
    errorMessage: string;
    successMessage: string;
    options: any;
}
