<mat-card>
    <mat-card-title class="title">Your Cover</mat-card-title>
    <mat-card-content>
        <p style="margin-bottom: 20px;">{{name}}, we can offer you
            <b>{{ val.benefit_amount | dl_currency:true:0 }}</b> cover for
            <b>{{ val.premium_amount | dl_currency:true:2 }}</b>&nbsp;p/m.
        </p>
        <div class="row">
            <div class="col s12">
                <mat-slider
                    [invert]="false"
                    [max]="maxCover"
                    [min]="minCover"
                    [step]="increments"
                    [thumbLabel]="true"
                    [displayWith]="formatDisplay"
                    [value]="val.benefit_amount"
                    (input)="updateCover($event)">
                </mat-slider>
            </div>
        </div>
        <div class="row">
            <div class="col s12" style="margin-top: -20px;">
                <div class="left">{{minCover | dl_currency:true:0}}</div>
                <div class="right">{{maxCover | dl_currency:true:0}}</div>
            </div>
        </div>
        <div class="row">
            <div class="col s12">
                <small>Change slider to change the cover amount</small>
            </div>
        </div>
    </mat-card-content>
</mat-card>
