import { ComponentFactoryResolver, ComponentRef, Injector, ViewContainerRef } from '@angular/core';
var DynamicQQResultBlockComponent = /** @class */ (function () {
    function DynamicQQResultBlockComponent(resolver) {
        this.resolver = resolver;
        this.currentComponent = null;
    }
    Object.defineProperty(DynamicQQResultBlockComponent.prototype, "componentData", {
        set: function (data) {
            if (!data) {
                return;
            }
            // Inputs need to be in the following format to be resolved properly
            var staticInjector = Injector.create({
                providers: [],
                parent: this.dynamicComponentContainer.parentInjector
            });
            // We create a factory out of the component we want to create
            var factory = this.resolver.resolveComponentFactory(data.component);
            // We create the component using the factory and the injector
            var component = factory.create(staticInjector);
            component.instance.setBenefit(data.inputs.benefit);
            component.instance.setCauseText(data.inputs.cause);
            // We insert the component into the dom container
            this.dynamicComponentContainer.insert(component.hostView);
            // We can destroy the old component by calling destroy
            if (this.currentComponent) {
                this.currentComponent.destroy();
            }
            this.currentComponent = component;
        },
        enumerable: true,
        configurable: true
    });
    return DynamicQQResultBlockComponent;
}());
export { DynamicQQResultBlockComponent };
