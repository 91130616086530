import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ErrorModalDialog} from './error.modal.component';
import {ErrorModalProvider} from './error.modal.service';
import {MatButtonModule, MatDialogModule, MatExpansionModule} from '@angular/material';

/**
 * Created by corneliusbotha on 2017/03/25.
 * This is the needed module to display error messages.
 */
@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatExpansionModule
    ],
    declarations: [
        ErrorModalDialog
    ],
    entryComponents: [
        ErrorModalDialog
    ],
    providers: [
        ErrorModalProvider
    ]
})
export class ErrorModule {}
