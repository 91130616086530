import {Benefit} from '../base/benefit';
import {isNullOrUndefined} from 'util';
import {SelectItem} from '../base/select.vo';

export interface QQForm {
    productGroupCode?: string;
    campaignId?: string;
    products?: SelectItem[];
    fields?: QQFormFields;
    message?: string;
    orgProjects?: SelectItem[];
}

export interface QQFormFields {
    id_number: boolean;
    age: boolean;
    birth_date: boolean;
    income: boolean;
    education: boolean;
    gender: boolean;
    smoker: boolean;
}

export class InputData {
    spId: string;
    leadId: string;
    needsId: string;
    needsBenefitId: string;
    campaignId: string;
    title = 'Quick Quote';
    productCode = '';
    campaignCode = '';
    referrerCode: string;
    httpReferrer: string;
    source: string;
    buttonText = 'CALCULATE';
    embedded = false;
    bgColour  = '255,255,255';
    opacity = 0.6;
    optionality: string;
    securityGroup: string;
    leadCapture = false;
    causeText = 'a cause of your choice';
    theme: string;
    orgProjects: SelectItem[] = [];
    type: string;
}

/**
 * This is the lead data structure that needs to go to the server
 */
export class Lead {
    name: string;
    email: string;
    cell: string;
    lead_type: string;
    noEmail = false;
    birth_date: string;

    constructor(name: string, email: string, cell: string) {
        this.name = name;
        this.email = email;
        this.cell = cell;
    }

    setLeadType(type: string) {
        this.lead_type = type;
    }
}

export class PostData {
    qq_type: string;
    age: number;
    gender: string;
    income: number;
    education: string;
    smoker_status: string;
    pricing_engine_version: string;
    qq_product: string;
    product_code: string;
    product_group: string;
    campaign_id: string;
    campaign_code: string;
    referrer: string;
    referrer_code: string;
    quickquote_source: string;
    force: boolean;
    lead: Lead;
    embedded: boolean;
    benefits: QQBenefit[];
    leadid: string;
    idnumber: string;
    optionality: string;
    error: string;
    lead_capture: boolean;
    spid: string;
    needs_id: string;
    org_project: string;
    accepted_terms = true;
    is_external_qq = false;
    comments: string;
}

export interface QQBenefit extends Benefit {
    id: string;
}

export class QQResult {
    url: string;
    success: boolean|string;
    spid: string;
}

export class CloseEventData {
    info: boolean = false;
    error: boolean = false;
    cancel: boolean = false;
    mobileWarning: boolean = false;
    message: string = '';
    qqResponse: QQResult;

    constructor() {}

    public static createCancelEvent(message?: string): CloseEventData {
        let event = new CloseEventData();
        event.cancel = true;
        if (!isNullOrUndefined(message)) {
            event.message = message;
        }
        return event;
    }

    public static createErrorEvent(message: string): CloseEventData {
        let event = new CloseEventData();
        event.error = true;
        event.message = message;
        return event;
    }

    public static createInvoEvent(message: string): CloseEventData {
        let event = new CloseEventData();
        event.info = true;
        event.message = message;
        return event;
    }

    public static createMobileWarningEvent(qqResponse: QQResult): CloseEventData {
        let event = new CloseEventData();
        event.mobileWarning = true;
        event.qqResponse = qqResponse;
        return event;
    }
}
