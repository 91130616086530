import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Benefit, BenefitAddon, BenefitFeature, EscalationCombination} from './benefit';
import {BenefitCalculator} from './benefit.calculator';
import {MatDialog, MatSliderChange} from '@angular/material';
import {isNullOrUndefined} from 'util';
import {DLUtil} from './dl.util';
import {Log} from 'ng2-logger/browser';
import {ProductEscalationsComponent} from './base-product/product-escalations.component';

const log = Log.create('BenefitSliderComponent');

/**
 * Benefit Slider component.
 */
@Component({
    selector: 'benefit-slider',
    templateUrl: './benefit.slider.component.html',
    styleUrls: ['./benefit.slider.component.scss']
})
export class BenefitSliderComponent implements OnInit {
    constructor(private dialog: MatDialog) {
    }

    @Input('benefit') benefit: Benefit;
    @Input('spId') spId: string;
    @Output('change') change = new EventEmitter<void>();

    coverSweetSpot: any;
    premiumSweetSpot: any;

    ngOnInit(): void {
        this.calculateSweetSpots();
    }

    private escalatedCover(amount: number): number {
        return amount * this.benefit.coverEscalationFactor;
    }

    private escalatedPremium(amount: number): number {
        return amount * this.benefit.escalationFactor;
    }

    private calculateSweetSpots() {
        if (!isNullOrUndefined(this.benefit.min_cover_marker) && !isNullOrUndefined(this.benefit.max_cover_marker)) {
            this.coverSweetSpot = this.resolveSweetSpot(
                this.escalatedCover(this.benefit.min_cover_marker),
                this.escalatedCover(this.benefit.max_cover_marker));
        }
        if (!isNullOrUndefined(this.benefit.min_premium_marker) && !isNullOrUndefined(this.benefit.max_premium_marker)) {
            this.premiumSweetSpot = this.resolveSweetSpot(
                this.escalatedPremium(this.benefit.min_premium_marker),
                this.escalatedPremium(this.benefit.max_premium_marker));
        }
    }

    // noinspection JSMethodCanBeStatic
    private resolveSweetSpot(min: number, max: number) {
        min = Math.round(min);
        max = Math.round(max);
        // slight compensation for margins to make sure the bar stays in the current track...
        min += 1;
        if (max > 95) {
            max -= (max - 95);
        }
        const width = max - min;
        return {
            'margin-left': min + '%',
            'width': width + '%'
        };
    }

    isCover(): boolean {
        return !this.isEnhancement() && this.benefit.coverOrPremium === 'Cover';
    }

    isPremium(): boolean {
        return !this.isEnhancement() && this.benefit.coverOrPremium === 'Premium';
    }

    isEnhancement(): boolean {
        return !isNullOrUndefined(this.benefit.type) && this.benefit.type === 'enhancement';
    }

    updateCover(val: MatSliderChange): void {
        this.benefit.cover = Math.round(val.value);
        this.calculatePremium();
    }

    updatePremium(val: MatSliderChange): void {
        this.benefit.premium = (Math.round(val.value * 100) / 100);
        this.calculateCover();
    }

    calculatePremium() {
        this.benefit.premium = BenefitCalculator.calc(
            this.benefit.calcPremium ? this.benefit.calcPremium : 'primaryPremiumNoDiscount',
            this.benefit);
        this.calculateAddons();
        this.change.emit();
    }

    calculateCover() {
        this.benefit.cover = BenefitCalculator.calc(
            this.benefit.calcCover ? this.benefit.calcCover : 'primaryCover',
            this.benefit);
        this.calculateAddons();
        this.change.emit();
    }

    calculateAddons() {
        if (this.benefit.addons) {
            this.benefit.addons.forEach(addon => {
                addon.cover = BenefitCalculator.calcAddon(addon.calcCover, this.benefit, addon);
                addon.premium = BenefitCalculator.calcAddon(addon.calcPremium, this.benefit, addon);
            });
        }
    }

    private moveSlider(changeAmountFunction) {
        if (this.isCover()) {
            const minCover = this.escalatedCover(this.benefit.minCover);
            const maxCover = this.escalatedCover(this.benefit.maxCover);
            const coverIncrements = this.escalatedCover(this.benefit.coverIncrements);
            this.benefit.cover = changeAmountFunction(this.benefit.cover, minCover, maxCover, coverIncrements);
            this.calculatePremium();
        } else {
            const minPremium = this.escalatedPremium(this.benefit.minPremium);
            const maxPremium = this.escalatedPremium(this.benefit.maxPremium);
            const premiumIncrements = this.escalatedPremium(this.benefit.premiumIncrements);
            this.benefit.premium = changeAmountFunction(this.benefit.premium, minPremium, maxPremium, premiumIncrements);
            this.calculateCover();
        }
    }

    decrement() {
        this.moveSlider(function (amount, min, max, increment) {
            const amount_decremented = Math.ceil(amount) - increment;
            if (amount_decremented < min) {
                return min;
            }
            return Math.round(amount_decremented);
        });
    }

    increment() {
        this.moveSlider(function (amount, min, max, increment) {
            const amount_incremented = Math.ceil(amount) + increment;
            if (amount_incremented > max) {
                return max;
            }
            return Math.round(amount_incremented);
        });
    }

    toggleAddon(addon: BenefitAddon) {
        addon.added = !addon.added;
        this.change.emit();
    }

    editFeature(feature: BenefitFeature) {
        log.info('Edit feature clicked: ' + feature.type);
        if (!feature.editable) {
            return;
        }
        log.info('Editing allowed for ' + feature.type);
        if (feature.type === 'escalation_options') {
            if (this.isPremium()) {
                log.error('Cannot change escalation in "premium" mode');
            }
            this.showEscalationsModal(feature.params.escalationCombinations);
        }
        return false;
    }

    updateBenefit(benefit: Benefit) {
        log.info('Repriced benefit: ', benefit);
        DLUtil.copyFields(benefit, this.benefit, ['addons']);
        // Replace addons with updated ones, keeping local "added" flags
        this.benefit.addons.forEach(addon => {
            const newAddon = benefit.addons.find(a => {
                return a.code === addon.code;
            });
            DLUtil.copyFields(newAddon, addon, ['added']);
        });
        if (this.isCover()) {
            this.calculatePremium();
        } else {
            this.calculateCover();
        }
        this.change.emit();
    }

    showEscalationsModal(options: EscalationCombination[]): void {
        const dialog = this.dialog.open(ProductEscalationsComponent, {
            position: {top: '20px'},
            panelClass: 'dl-modal',
            maxWidth: '99vw'
        });
        dialog.componentInstance.spId = this.spId;
        dialog.componentInstance.benefit = this.benefit;
        dialog.componentInstance.escalationOptions = options;
        dialog.afterClosed().subscribe(newBenefit => {
            if (!isNullOrUndefined(newBenefit)) {
                this.updateBenefit(newBenefit);
            }
        });
    }

    getSweetSpotStyle() {
        if (this.isCover() && !isNullOrUndefined(this.coverSweetSpot)) {
            return this.coverSweetSpot;
        }
        if (this.isPremium() && !isNullOrUndefined(this.premiumSweetSpot)) {
            return this.premiumSweetSpot;
        }
        return {
            display: 'none'
        };
    }

    formatCoverForDisplay(value: number | null): string {
        return DLUtil.compactFormat(value, false);
    }

    formatPremiumForDisplay(value: number | null): string {
        return DLUtil.compactFormat(value, true);
    }

}
