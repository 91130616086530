import {Component} from '@angular/core';
import {Navigation} from './nav.component';
import {DspService} from '../dsp.service';
/**
 * Created by corneliusbotha on 2017/07/18.
 */

@Component({
    selector: 'dl-nav',
    templateUrl: 'dl.nav.component.html',
    styleUrls: ['dl.nav.component.scss']
})
export class DlNavComponent extends Navigation {
  constructor(public dspService: DspService) {
    super(dspService);
  }
}
