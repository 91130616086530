import { OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { minMaxValidation } from '../base/min.max.validator';
import { BaseDropDownValues } from '../base/base.dropdown.values';
import { isNullOrUndefined } from 'util';
import { DependentType, NeedsDataVO, NeedsDependentVO, ProductGroupRequestVO } from './needs.vo';
import { NeedsService } from './needs.service';
import { DisclosuresProvider } from '../base/disclosures/disclosures.provider';
import { DisclosureCategory, DisclosureType } from '../base/disclosures/disclosures.vos';
import { Log } from 'ng2-logger/browser';
import { MatDialog } from '@angular/material';
import { NeedsResultsComponent } from './needs.results.component';
import { DLUtil } from '../base/dl.util';
import { PhoneNumberValidator } from '../base/phone-number';
var log = Log.create('NeedsComponent');
var NeedsComponent = /** @class */ (function () {
    function NeedsComponent(fb, needsService, disclosureProvider, dialog) {
        this.fb = fb;
        this.needsService = needsService;
        this.disclosureProvider = disclosureProvider;
        this.dialog = dialog;
        this._title = 'Insurance Calculator';
        this._campaignCode = null;
        this._referrerCode = null;
        this._source = null;
        this._buttonText = 'Calculate';
        this._leadId = null;
        this.dependentsForm = null;
        this.numMask = DLUtil.numMask;
        this.educationValues = BaseDropDownValues.getEducationValues();
        this.smokerValues = [
            { label: 'Smoker', value: 'yes' },
            { label: 'Non Smoker', value: 'no' }
        ];
        this.homeLoanValues = [
            { label: 'Home Loan', value: 'yes' },
            { label: 'No Home Loan', value: 'no' }
        ];
        this.spouseValues = [
            { label: 'Partner', value: 'yes' },
            { label: 'No Partner', value: 'no' }
        ];
        this.genderValues = BaseDropDownValues.getGenderValues();
        this.dependentsValues = [];
        this.hasSpouse = false;
        this.dependentCount = 0;
        this.dependentsNumber = [];
        this.loading = false;
        this.disclosuresShown = false;
        this.starting = true;
        this.init();
        this.createFrom();
    }
    Object.defineProperty(NeedsComponent.prototype, "ptyName", {
        get: function () {
            return this._ptyName;
        },
        set: function (val) {
            this._ptyName = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NeedsComponent.prototype, "title", {
        get: function () {
            return this._title;
        },
        set: function (val) {
            this._title = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NeedsComponent.prototype, "campaignCode", {
        get: function () {
            return this._campaignCode;
        },
        set: function (val) {
            this._campaignCode = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NeedsComponent.prototype, "referrerCode", {
        get: function () {
            return this._referrerCode;
        },
        set: function (val) {
            this._referrerCode = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NeedsComponent.prototype, "httpReferrer", {
        get: function () {
            return this._httpReferrer;
        },
        set: function (val) {
            this._httpReferrer = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NeedsComponent.prototype, "source", {
        get: function () {
            return this._source;
        },
        set: function (val) {
            this._source = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NeedsComponent.prototype, "buttonText", {
        get: function () {
            return this._buttonText;
        },
        set: function (val) {
            this._buttonText = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NeedsComponent.prototype, "leadId", {
        get: function () {
            return this._leadId;
        },
        set: function (val) {
            this._leadId = val;
        },
        enumerable: true,
        configurable: true
    });
    NeedsComponent.prototype.createFrom = function () {
        var _this = this;
        this.needsForm = this.fb.group({
            name: [''],
            email: ['', [Validators.email, Validators.pattern(DLUtil.emailPattern)]],
            cell: ['', [PhoneNumberValidator.validator()]],
            age: ['', [Validators.required, minMaxValidation(16, 64)]],
            spouse: ['', Validators.required],
            education: ['', Validators.required],
            smoke: ['', Validators.required],
            gender: ['', Validators.required],
            homeLoan: ['', Validators.required],
            income: ['', Validators.required],
            dependents: [this.dependentsValues[0].value, Validators.required],
            spouseAge: [''],
            spouseIncome: [''],
            acceptTerms: [false, Validators.requiredTrue]
        });
        // add listeners on the form controllers.
        this.needsForm.get('spouse').valueChanges.subscribe(function () { return _this.onSpouseChange(); });
        this.needsForm.get('dependents').valueChanges.subscribe(function () { return _this.updateDependentForm(); });
    };
    NeedsComponent.prototype.init = function () {
        this.dependentsValues.push({ label: 'None', value: 0 });
        for (var i = 1; i <= 5; i++) {
            this.dependentsValues.push({ label: '' + i, value: i });
        }
    };
    NeedsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var productGroupReq = new ProductGroupRequestVO();
        productGroupReq.lead_id = isNullOrUndefined(this.leadId) ? '' : this.leadId;
        productGroupReq.campaign_code = isNullOrUndefined(this.campaignCode) ? '' : this.campaignCode;
        this.needsService.resolveProductGroup(productGroupReq).subscribe(function (res) {
            log.info('Product group loaded ' + res.productGroupCode);
            _this.starting = false;
            if (res.message != null) {
                _this.message = res.message;
            }
        });
        this.needsService.ptyName = this.ptyName;
    };
    NeedsComponent.prototype.onSpouseChange = function () {
        if (this.needsForm.get('spouse').value === 'yes') {
            this.hasSpouse = true;
            this.needsForm.get('spouseAge').setValidators([Validators.required, minMaxValidation(14, 110)]);
            this.needsForm.get('spouseIncome').setValidators([Validators.required]);
        }
        else {
            this.hasSpouse = false;
            this.needsForm.get('spouseAge').clearValidators();
            this.needsForm.get('spouseAge').reset();
            this.needsForm.get('spouseIncome').clearValidators();
            this.needsForm.get('spouseIncome').reset();
        }
    };
    NeedsComponent.prototype.updateDependentForm = function () {
        this.dependentCount = this.needsForm.get('dependents').value;
        if (this.dependentCount === 0) {
            if (!isNullOrUndefined(this.needsForm.get('dependentAges'))) {
                this.needsForm.removeControl('dependentAges');
                this.dependentsForm = null;
            }
            this.dependentsNumber = [];
            return;
        }
        if (isNullOrUndefined(this.needsForm.get('dependentAges'))) {
            this.dependentsForm = this.fb.group({});
            this.needsForm.addControl('dependentAges', this.dependentsForm);
        }
        else {
            this.dependentsForm = this.needsForm.get('dependentAges');
        }
        for (var i = 1; i <= 5; i++) {
            if (isNullOrUndefined(this.dependentsForm.get('childAge' + i)) && i <= this.dependentCount) {
                this.dependentsForm.addControl('childAge' + i, new FormControl('', [Validators.required, minMaxValidation(0, 65)]));
            }
            if (i > this.dependentCount && !isNullOrUndefined(this.dependentsForm.get('childAge' + i))) {
                this.dependentsForm.removeControl('childAge' + i);
            }
        }
        this.dependentsNumber = [];
        for (var i = 1; i <= this.dependentCount; i++) {
            this.dependentsNumber.push(i);
        }
    };
    NeedsComponent.prototype.getAgeErrorMessage = function (formGroup, element) {
        if (formGroup.get(element).hasError('not_a_number')) {
            return 'Not a valid Age';
        }
        else if (formGroup.get(element).hasError('number_to_small')) {
            return 'Sorry too young';
        }
        else if (formGroup.get(element).hasError('number_to_big')) {
            return 'Sorry too old';
        }
        return '';
    };
    NeedsComponent.prototype.populateDataVO = function () {
        var dataVO = new NeedsDataVO();
        dataVO.age = Number(this.needsForm.get('age').value);
        dataVO.education = this.needsForm.get('education').value;
        if (this.needsForm.get('smoke').value === 'yes') {
            dataVO.smoker = 'S';
        }
        else {
            dataVO.smoker = 'N';
        }
        dataVO.gender = this.needsForm.get('gender').value;
        dataVO.has_homeloan = this.needsForm.get('homeLoan').value === 'yes';
        dataVO.income = Number(this.needsForm.get('income').value.replace(/\D+/g, ''));
        if (this.needsForm.get('spouse').value === 'yes') {
            // add spouse as a depended.
            var spouse = new NeedsDependentVO();
            spouse.age = Number(this.needsForm.get('spouseAge').value);
            spouse.income = Number(this.needsForm.get('spouseIncome').value.replace(/\D+/g, ''));
            spouse.type = DependentType.PARTNER;
            dataVO.needsDependent.push(spouse);
        }
        if (this.dependentCount > 0) {
            for (var i = 1; i <= this.dependentCount; i++) {
                var child = new NeedsDependentVO();
                child.age = Number(this.dependentsForm.get('childAge' + i).value);
                child.type = DependentType.CHILD;
                dataVO.needsDependent.push(child);
            }
        }
        dataVO.product_group = this.needsService.productGroup;
        dataVO.campaign_id = this.needsService.campaignId;
        this.addLeadData(dataVO);
        return dataVO;
    };
    NeedsComponent.prototype.addLeadData = function (needsData) {
        this.needsService.setLeadData(this.needsForm.get('name').value, this.needsForm.get('email').value, this.needsForm.get('cell').value, this.leadId, this.httpReferrer, this.campaignCode, this.referrerCode);
        if (this.hasLeadData()) {
            needsData.lead = this.needsService.leadData;
        }
    };
    NeedsComponent.prototype.hasLeadData = function () {
        if (!isNullOrUndefined(this.needsForm.get('name').value) && this.needsForm.get('name').value !== '') {
            return true;
        }
        if (!isNullOrUndefined(this.needsForm.get('email').value) && this.needsForm.get('email').value !== '') {
            return true;
        }
        if (!isNullOrUndefined(this.needsForm.get('cell').value) && this.needsForm.get('cell').value !== '') {
            return true;
        }
        if (!isNullOrUndefined(this.leadId) && this.leadId !== '') {
            return true;
        }
        return false;
    };
    NeedsComponent.prototype.calculateNeeds = function () {
        var _this = this;
        this.loading = true;
        var dataToSend = this.populateDataVO();
        console.log(dataToSend);
        this.needsService.getNeedsInfo(dataToSend).subscribe(function (res) { return _this.handleNeedsResult(res); }, function () { return _this.resetButtons(); });
    };
    NeedsComponent.prototype.resetButtons = function () {
        this.loading = false;
    };
    NeedsComponent.prototype.handleNeedsResult = function (res) {
        console.log(res);
        if (!this.disclosuresShown) {
            this.disclosureProvider.showDisclosure('A few things to keep in mind', 'calculator', DisclosureCategory.CALCULATOR, null, DisclosureType.INFO, this);
        }
        else {
            this.resetButtons();
            this.showResults();
        }
        // open the needs dialog after this disclosures is closed. if this is a send attempt show the results.
    };
    NeedsComponent.prototype.showResults = function () {
        var _this = this;
        log.info('Opening the results');
        this.needsResults = this.dialog.open(NeedsResultsComponent, {
            disableClose: true,
            position: { top: '20px' },
            panelClass: 'dl-modal',
            maxWidth: '99vw'
        });
        this.needsResults.afterClosed().subscribe(function () { return _this.resetFormData(); });
    };
    NeedsComponent.prototype.resetFormData = function () {
        this.needsForm.reset();
        this.disclosuresShown = false;
        this.needsService.clearResults();
        this.resetButtons();
    };
    NeedsComponent.prototype.onDisclosuresClosed = function () {
        this.disclosuresShown = true;
        this.showResults();
    };
    NeedsComponent.prototype.onDisclosuresClosing = function (disclosures) {
        return false;
    };
    NeedsComponent.prototype.onDisclosuresLoaded = function () {
        this.resetButtons();
    };
    NeedsComponent.prototype.onDisclosuresSaved = function (disclosures) {
    };
    NeedsComponent.prototype.onDisclosuresFailed = function () {
        this.resetButtons();
    };
    return NeedsComponent;
}());
export { NeedsComponent };
