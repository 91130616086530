import {ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BaseModule} from '../base/base.module';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {MatButtonModule, MatFormFieldModule, MatInputModule} from '@angular/material';
import {ErrorModule} from '../base/error/error.module';
import {BrowserModule} from '@angular/platform-browser';
import {LoginComponent} from './login.component';
import {TextMaskModule} from 'angular2-text-mask';
import {ResetPasswordComponent} from './reset.password.component';
import {RequestPasswordComponent} from './request.password.component';
import {SetPasswordComponent} from './set.password.component';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BaseModule,
        ErrorModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        ErrorModule,
        TextMaskModule
    ],
    declarations: [
        LoginComponent,
        ResetPasswordComponent,
        RequestPasswordComponent,
        SetPasswordComponent
    ],
    exports: [
        LoginComponent,
        ResetPasswordComponent
    ],
    entryComponents: [
        LoginComponent,
        ResetPasswordComponent
    ]
})
export class LoginModule {}
