import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {StripeScriptService} from './stripe.script.service';
import {Stripe, StripeComponentState, StripeInstance} from './stripe.types';
import {Subscription} from 'rxjs';
import {Log} from 'ng2-logger/client';

const log = Log.create('StripeComponent');

@Component({
    selector: 'dl-stripe-input',
    templateUrl: 'stripe.component.html',
    styleUrls: ['stripe.component.scss']
})
export class StripeComponent implements OnDestroy, OnInit {

    @ViewChild('cardInfo') cardInfo: ElementRef;

    stripe: Stripe;
    stripeInstance: StripeInstance;

    cardNumber: any;
    cardHandler = this.onCardChange.bind(this);
    error: string;

    submitSubscription: Subscription;
    myState: StripeComponentState = {valid: false, busy: false};

    _publishToken: string;
    @Input()
    set publishToken(val: string) {
        this._publishToken = val;
    }
    get publishToken(): string {
        return this._publishToken;
    }

    _postalCode: string;
    @Input()
    set postalCode(val: string) {
        this._postalCode = val;
    }
    get postalCode(): string {
        return this._postalCode;
    }

    @Output()
    stateChange: EventEmitter<StripeComponentState> = new EventEmitter();

    constructor(private cd: ChangeDetectorRef,
                private zone: NgZone,
                private stripeScriptService: StripeScriptService) {
        this.stripeScriptService.injectStipeElement().then( res => this.stripe = res);
        this.submitSubscription = this.stripeScriptService.getSubmitTokenObservable().subscribe( () => this.submitStripeControl());
    }

    ngOnInit(): void {
        let elements: any;
        if ( this.stripe === undefined) {
            // wait for the script to load
            setTimeout( () => this.ngOnInit(), 500);
        } else {
            this.stripeInstance = this.stripe(this.publishToken);
            elements = this.stripeInstance.elements();
            if (this.postalCode !== null && this.postalCode !== undefined) {
                this.cardNumber = elements.create('card', {
                    value: {
                        postalCode: this.postalCode
                    },
                    hidePostalCode: true
                });
            } else {
                this.cardNumber = elements.create('card');
            }
            this.cardNumber.mount(this.cardInfo.nativeElement);
            this.cardNumber.addEventListener('change', this.cardHandler);
        }
    }

    ngOnDestroy(): void {
        this.submitSubscription.unsubscribe();
        if (this.cardNumber !== null && this.cardNumber !== undefined) {
            this.cardNumber.removeEventListener('change', this.cardHandler);
            this.cardNumber.destroy();
        }
    }

    onCardChange(error: any) {
        if (error !== null || error !== undefined) {
            if (error.complete && (error.error === null || error.error === undefined)) {
                this.error = null;
                this.myState.valid = true;
                this.myState.error = null;
            } else if (error.error !== null && error.error !== undefined) {
                this.error = error.error.message;
                this.myState.error = this.error;
                this.myState.valid = false;
            } else {
                this.error = '';
            }
        }
        this.cd.detectChanges();
        this.broadcastStateChanged();
    }

    submitStripeControl() {
        this.myState.busy = true;
        this.stateChange.emit(this.myState);
        this.submit().then(() => {
            this.broadcastStateChanged();
        });
    }

    async submit() {
        const {token, error} = await this.stripeInstance.createToken(this.cardNumber);
        if (error) {
            log.error(error);
            this.myState.valid = false;
            this.myState.busy = false;
            this.error = error.message;
            this.myState.error = this.error;
        } else {
            log.info('We have the token', token);
            this.myState.valid = true;
            this.myState.busy = false;
            this.myState.error = null;
            this.myState.token = token;
        }
    }

    broadcastStateChanged() {
        this.stateChange.emit(this.myState);
        setTimeout(() => {
            this.zone.run(() => {
                log.data('Do zone redraw');
            });
        });
    }
}
