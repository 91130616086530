var ResetPasswordComponent = /** @class */ (function () {
    function ResetPasswordComponent() {
    }
    Object.defineProperty(ResetPasswordComponent.prototype, "action", {
        get: function () {
            return this._action;
        },
        set: function (val) {
            this._action = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ResetPasswordComponent.prototype, "orgUrl", {
        get: function () {
            return this._orgUrl;
        },
        set: function (value) {
            this._orgUrl = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ResetPasswordComponent.prototype, "oauthClientId", {
        get: function () {
            return this._oauthClientId;
        },
        set: function (value) {
            this._oauthClientId = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ResetPasswordComponent.prototype, "token", {
        get: function () {
            return this._token;
        },
        set: function (val) {
            this._token = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ResetPasswordComponent.prototype, "userName", {
        get: function () {
            return this._userName;
        },
        set: function (val) {
            this._userName = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ResetPasswordComponent.prototype, "errorMessage", {
        get: function () {
            return this._errorMsg;
        },
        set: function (val) {
            this._errorMsg = val;
        },
        enumerable: true,
        configurable: true
    });
    return ResetPasswordComponent;
}());
export { ResetPasswordComponent };
