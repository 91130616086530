import {Injectable} from '@angular/core';
import {ErrorModalDialog} from './error.modal.component';
import {MatDialog, MatDialogRef} from '@angular/material';
import {isNullOrUndefined} from 'util';
import {ErrorVO} from './error.vo';

/**
 * Created by corneliusbotha on 2017/03/25.
 * This is a service that is used by all that needs to display an error Message.
 */
@Injectable()
export class ErrorModalProvider {

    private errorDialog: MatDialogRef<ErrorModalDialog>;

    public showErrorMessage(message: string, title?: string): void {
        const err = new ErrorVO();
        err.message = message;
        this.showError(err, title);
    }

    public showError(error: ErrorVO, title?: string): void {
        this.errorDialog = this.dialog.open(ErrorModalDialog, {
            disableClose: true,
            panelClass: 'dl-modal',
            maxWidth: '99vw'
        });
        if (!isNullOrUndefined(title) && title.length > 0) {
            this.errorDialog.componentInstance.title = title;
        } else {
            this.errorDialog.componentInstance.title = 'Error';
        }
        this.errorDialog.componentInstance.error = error;
        this.errorDialog.afterClosed().subscribe(() => this.errorDialog = undefined);
    }

    public showing(): boolean {
        return !isNullOrUndefined(this.errorDialog);
    }

    public closeError(): void {
        if (!isNullOrUndefined(this.errorDialog)) {
            this.errorDialog.close();
        }
    }

    constructor(public dialog: MatDialog) {}
}
