import { ErrorVO } from '../error/error.vo';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ErrorModalProvider } from '../error/error.modal.service';
import { throwError } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../error/error.modal.service";
var httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
var DisclosuresService = /** @class */ (function () {
    function DisclosuresService(http, errorModalProvider) {
        this.http = http;
        this.errorModalProvider = errorModalProvider;
        this.baseUrl = '/dl/data/disclosures/';
    }
    DisclosuresService.prototype.setSpMode = function (mode) {
        this.sp_mode = mode;
    };
    DisclosuresService.prototype.getDisclosureGroup = function (prefix, page, type, data) {
        var req = {
            type: type,
            data: data
        };
        return this.http.post('/dl/data/disclosureGroup/' + prefix + '/' + page + '/retrieve', req, httpOptions).pipe(catchError(this.handleError()));
    };
    DisclosuresService.prototype.save = function (disclosures) {
        return this.http.post(this.baseUrl + this.sp_mode + '/save', disclosures, httpOptions).pipe(catchError(this.handleError()));
    };
    DisclosuresService.prototype.stop = function (prefix, page, type, disclosures) {
        var req = {
            prefix: prefix,
            page: page,
            type: type,
            disclosures: disclosures
        };
        return this.http.post(this.baseUrl + this.sp_mode + '/stop', req, httpOptions).pipe(catchError(this.handleError()));
    };
    DisclosuresService.prototype.handleError = function () {
        var _this = this;
        return function (error) {
            var vo = ErrorVO.toErrorVO(error);
            _this.errorModalProvider.showError(vo);
            return throwError(vo);
        };
    };
    DisclosuresService.ngInjectableDef = i0.defineInjectable({ factory: function DisclosuresService_Factory() { return new DisclosuresService(i0.inject(i1.HttpClient), i0.inject(i2.ErrorModalProvider)); }, token: DisclosuresService, providedIn: "root" });
    return DisclosuresService;
}());
export { DisclosuresService };
