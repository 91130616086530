
<div class="dl-panel">
    <div class="dl-panel-heading">
        <p class="text-primary" style="margin: 0;">Get insured and <strong>{{benefit.premium | dl_currency:true:0}}</strong> will be donated to <strong id="projectname">{{ causetext }}</strong>.</p>
    </div>
</div>
<div class="dl-container-no-padding text-center" style="margin-top: 15px;">
    <div class="dl-col-md-12 col s12 center-block dl-hidden-xs hide-on-small-and-down">
        <img class="qqmodal-dl-logo center-block img-responsive svg_logo" src="/assets/img/dl/dl-omart-01.svg" alt="Different Life Old Mutual">
    </div>
    <div class="dl-col-md-12 col s12 center-block dl-hidden-lg dl-hidden-md dl-hidden-sm hide-on-med-and-up">
        <img class="qqmodal-dl-logo center-block img-responsive svg_logo" style="max-width: 320px" src="/assets/img/dl/dl-omart-mob-01.svg" alt="Different Life Old Mutual">
    </div>
</div>
