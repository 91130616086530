/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./mail.draft.doc.info.component";
import * as i3 from "./component";
import * as i4 from "./ajax.service";
var styles_MailDraftDocInfoComponent = [];
var RenderType_MailDraftDocInfoComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MailDraftDocInfoComponent, data: {} });
export { RenderType_MailDraftDocInfoComponent as RenderType_MailDraftDocInfoComponent };
function View_MailDraftDocInfoComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-circle-o-notch fa-spin"]], null, null, null, null, null))], null, null); }
function View_MailDraftDocInfoComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-check"], ["style", "padding-left: 5px"]], null, null, null, null, null))], null, null); }
export function View_MailDraftDocInfoComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [], [[1, "data-name", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["We can also "])), (_l()(), i0.ɵeld(3, 0, null, null, 5, "a", [["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doCall() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["email it"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MailDraftDocInfoComponent_1)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MailDraftDocInfoComponent_2)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, [" and your draft Policy Document for you to study."]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isBusy; _ck(_v, 6, 0, currVal_1); var currVal_2 = (_co.isDone && !_co.isBusy); _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.component.id; _ck(_v, 0, 0, currVal_0); }); }
export function View_MailDraftDocInfoComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "draft-doc-info", [], null, null, null, View_MailDraftDocInfoComponent_0, RenderType_MailDraftDocInfoComponent)), i0.ɵdid(1, 180224, null, 0, i2.MailDraftDocInfoComponent, [i3.ParentEventService, i4.AjaxService], null, null)], null, null); }
var MailDraftDocInfoComponentNgFactory = i0.ɵccf("draft-doc-info", i2.MailDraftDocInfoComponent, View_MailDraftDocInfoComponent_Host_0, { flowId: "flowId", formGroup: "formGroup", component: "component", pageName: "pageName", _productCode: "_productCode", groupId: "groupId", _spMode: "_spMode" }, { change: "change", loaded: "loaded", validated: "validated", resume: "resume", altContinue: "altContinue" }, []);
export { MailDraftDocInfoComponentNgFactory as MailDraftDocInfoComponentNgFactory };
