import {
  Component, ComponentFactoryResolver, ComponentRef, Injector, Input, ReflectiveInjector, Type, ViewChild,
  ViewContainerRef
} from '@angular/core';
import {OrgResultBlockComponent} from './dl.org.result.block.component';
import {SGResultBlockComponent} from './sg.result.block.component';
import {QqDynamicComponent, QqDynamicLoadInput} from './qq.dynamic.component';

@Component({
    selector: 'dynamic_qq_block',
    entryComponents: [OrgResultBlockComponent, SGResultBlockComponent],
    template: '<div #dynamicComponentContainer></div>'
})
export class DynamicQQResultBlockComponent {
    @ViewChild('dynamicComponentContainer', { read: ViewContainerRef }) dynamicComponentContainer: ViewContainerRef;
    currentComponent: ComponentRef<QqDynamicComponent> = null;

    @Input()
    set componentData(data: QqDynamicLoadInput) {
        if (!data) {
            return;
        }
        // Inputs need to be in the following format to be resolved properly
        const staticInjector = Injector.create({
          providers: [],
          parent: this.dynamicComponentContainer.parentInjector});

        // We create a factory out of the component we want to create
        const factory = this.resolver.resolveComponentFactory(data.component);
        // We create the component using the factory and the injector
        const component = factory.create(staticInjector);
        component.instance.setBenefit(data.inputs.benefit);
        component.instance.setCauseText(data.inputs.cause);
        // We insert the component into the dom container
        this.dynamicComponentContainer.insert(component.hostView);
        // We can destroy the old component by calling destroy
        if (this.currentComponent) {
            this.currentComponent.destroy();
        }
        this.currentComponent = component;
    }

    constructor(private resolver: ComponentFactoryResolver) {}
}

