<div class="dl-dsp-radio-group">
    <div class="dl-dsp-question" [attr.data-name]="'pantsize-option-radioselector'">
        <span>Pant Size Number or Pant Size</span>
    </div>
    <mat-radio-group class="dsp-radio-group" [formControl]="radioGroupControl"
        [attr.kings-watch]="flowId + '-' + pageName + '-' + component.id">
        <mat-radio-button class="dsp-radio-button" color="primary" *ngFor="let item of selectorRadioText"
            id="{{'pantsize-option-radioselector-' + item.toLowerCase().replace(' ', '-')}}" [tabIndex]="1"
            [value]="item" [attr.data-val]="item" (change)="optionChange($event)"
            [attr.data-name]="'pantsize-option-radioselector' + '-' + item.toLowerCase().replace(' ', '-')">
            <span [innerHTML]="item"></span>
        </mat-radio-button>
    </mat-radio-group>
</div>
<hr style="border:0.5px solid grey; border-radius: 0.5px; opacity: 0.4">
<div>
    <div *ngIf="currentMethodSelector == 1" class="dl-dsp-radio-group">
        <div class="dl-dsp-question">
            <span>Pant Size Selector</span>
        </div>
        <mat-radio-group class="dsp-radio-group" [formControl]="pantSizeGroupControl"
            [attr.kings-watch]="flowId + '-' + pageName + '-' + component.id">
            <mat-radio-button class="dsp-radio-button" color="primary" *ngFor="let item of pantSizeSelectorValues"
                id="{{'pantsize-radioselector-' + item.toLowerCase().replace(' ', '-')}}" [tabIndex]="1" [value]="item"
                [attr.data-val]="item" (change)="onPantSizeSelectorChange($event)"
                [attr.data-name]="'pantsize-radioselector' + '-' + item.toLowerCase().replace(' ', '-')">
                <span [innerHTML]="item"></span>
            </mat-radio-button>
        </mat-radio-group>
    </div>
    <div *ngIf="currentMethodSelector == 2">
        <mat-form-field class="dl-full-width">
            <input matInput tabindex="1" [name]="'pantsize-number-input'" placeholder="Numeric Pant Size" type="number"
                inputmode="numeric" (change)="onPantSizeNumericChange()" [formControl]="textControl"
                [attr.data-name]="'pantsize-number-input'"
                [attr.kings-watch]="flowId + '-' + pageName + '-' + component.id">
        </mat-form-field>
    </div>
    <mat-error *ngIf="!this.component.isValid" align="start">{{validationMessage}}</mat-error>
</div>
