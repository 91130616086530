import { MatDialogRef } from '@angular/material';
import { BaseProductsService } from './base-products.service';
import { isNullOrUndefined } from 'util';
import { Log } from 'ng2-logger/browser';
import { Subject } from 'rxjs';
var log = Log.create('escalations-modal');
var ProductEscalationsComponent = /** @class */ (function () {
    function ProductEscalationsComponent(productService, dialogRef) {
        this.productService = productService;
        this.dialogRef = dialogRef;
        this.loading = false;
        this.escalationPremiums$ = new Subject();
    }
    Object.defineProperty(ProductEscalationsComponent.prototype, "escalationOptions", {
        get: function () {
            return this._escalationOptions;
        },
        set: function (value) {
            var _this = this;
            this._escalationOptions = value;
            this._escalationOptions.map(function (e) {
                e.premium = undefined;
            });
            var benefit_code = this.benefit.code;
            var cover = this.benefit.cover;
            this.productService.getPremiumsForEscalationOptions(this.spId, benefit_code, cover, this._escalationOptions)
                .subscribe(function (premiums) {
                _this.escalationPremiums$.next(premiums.results[_this.primaryCode()]);
            }, function (error) {
                log.error(error);
            });
        },
        enumerable: true,
        configurable: true
    });
    ProductEscalationsComponent.prototype.primaryCode = function () {
        return this.benefit.code.replace('_ACC', '');
    };
    ProductEscalationsComponent.prototype.isPrimaryBenefit = function () {
        return this.primaryCode() === this.benefit.code;
    };
    ProductEscalationsComponent.prototype.currentOption = function () {
        var feature = this.benefit.features.find((function (f) { return f.type === 'escalation_options'; }));
        return {
            cover: feature.params.sumAssuredIncrease.value,
            premium: feature.params.premiumIncrease.value
        };
    };
    ProductEscalationsComponent.prototype.close = function (newBenefit) {
        if (!isNullOrUndefined(newBenefit)) {
            this.dialogRef.close(newBenefit);
        }
        else {
            this.dialogRef.close();
        }
    };
    ProductEscalationsComponent.prototype.setSelectedOption = function (option) {
        this.selectedOption = option;
    };
    ProductEscalationsComponent.prototype.hasSelectedOption = function () {
        return !isNullOrUndefined(this.selectedOption);
    };
    ProductEscalationsComponent.prototype.reprice = function () {
        var _this = this;
        var option = this.selectedOption;
        if (isNullOrUndefined(option)) {
            log.info('Nothing selected!');
            return;
        }
        var currentOption = this.currentOption();
        if (currentOption.premium === option.premium && currentOption.cover === option.cover) {
            log.info('Selected currently applied option!');
            return;
        }
        this.loading = true;
        log.info('reprice it: ', option);
        this.productService.reprice(this.spId, this.benefit.code, this.benefit.cover, option).subscribe(function (newBenefit) {
            log.info('Repriced benefit: ', newBenefit);
            _this.close(newBenefit);
        }, function (error) {
            log.error(error);
            _this.loading = false;
        });
    };
    return ProductEscalationsComponent;
}());
export { ProductEscalationsComponent };
