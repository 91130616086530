import {Injectable} from '@angular/core';
import {ErrorModalProvider} from '../error/error.modal.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {Observable, of, throwError} from 'rxjs/index';
import {ErrorVO} from '../error/error.vo';
import {SuccessVO} from '../../sp/common.vos';
import {isNullOrUndefined} from 'util';

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
    providedIn: 'root'
})
export class OrgOptInService {

    constructor(private httpClient: HttpClient,
                private errorModalProvider: ErrorModalProvider
    ) {}

    public saveOptIn(contactId: string, category: string, fromProfile: boolean): Observable<SuccessVO> {
        const data: any = {
            contactId: contactId,
            optIn: true,
            category: category,
            fromProfile: fromProfile
        };
        return this.save(data);
    }

    public saveOptOut(contactId: string): Observable<SuccessVO> {
        const data: any = {
            contactId: contactId,
            optIn: false
        };
        return this.save(data);
    }

    private save(data: any): Observable<SuccessVO> {
        const url = '/dl/data/org-opt-in/save';
        return this.httpClient.post<SuccessVO>(url, data, httpOptions).pipe(
            catchError(this.handleHTTPError<SuccessVO>()));
    }

    public handleHTTPError<T>(heading?: string, result?: T) {
        return (error: any): Observable<T> => {
            const vo = ErrorVO.toErrorVO(error);
            if (isNullOrUndefined(result)) {
                this.errorModalProvider.showError(vo, heading);
                return throwError(vo);
            }
            return of(result as T);
        };
    }

}
