import { ElementRef, OnInit } from '@angular/core';
import * as d3 from 'd3';
import { isNullOrUndefined } from 'util';
var NeedsPieGraphComponent = /** @class */ (function () {
    function NeedsPieGraphComponent() {
        this._width = 180;
        this._height = 180;
        this.colourList = ['#006593', '#E0861A', '#FFA437', '#1AA1E0', '#935409'];
        this.colour = d3.scaleOrdinal().range(this.colourList);
        this.currentArcs = [];
    }
    Object.defineProperty(NeedsPieGraphComponent.prototype, "data", {
        get: function () {
            return this._data;
        },
        set: function (val) {
            this._data = val;
            if (!isNullOrUndefined(this.pie)) {
                this.updateChart(this._data);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NeedsPieGraphComponent.prototype, "width", {
        get: function () {
            return this._width;
        },
        set: function (val) {
            this._width = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NeedsPieGraphComponent.prototype, "height", {
        get: function () {
            return this._height;
        },
        set: function (val) {
            this._height = val;
        },
        enumerable: true,
        configurable: true
    });
    NeedsPieGraphComponent.prototype.ngOnInit = function () {
        this.setChartConfigValues();
        this.createChart();
        this.initialDraw(this.data);
    };
    NeedsPieGraphComponent.prototype.setChartConfigValues = function () {
        if (isNullOrUndefined(this.width)) {
            this.width = 180;
        }
        if (isNullOrUndefined(this.height)) {
            this.height = 180;
        }
        this.radius = Math.min(this.width, this.height) / 2;
        if (this.radius < 50) {
            this.radius = 50;
        }
        this.arc = d3.arc().outerRadius(this.radius - 10).innerRadius(this.radius - 45);
    };
    /**
     * To avoid type hel we cast every thing to any
     */
    NeedsPieGraphComponent.prototype.createChart = function () {
        var element = this.chart.nativeElement;
        this.pie = d3.pie().sort(null).value(function (d) {
            var data = d;
            return data.premium_amount;
        });
        var translateStr = 'translate(' + (this.width / 2) + ',' + (this.height / 2) + ')';
        this.svg = d3.select(element).append('svg')
            .attr('width', this.width)
            .attr('height', this.height)
            .attr('style', 'display:block; margin:auto')
            .append('g').attr('transform', translateStr);
    };
    /**
     * To avoid type hel we cast every thing to any
     */
    NeedsPieGraphComponent.prototype.initialDraw = function (data) {
        var _this = this;
        var path = this.svg.selectAll('arc')
            .data(this.pie(data))
            .enter()
            .append('g')
            .attr('class', 'arc');
        path.append('path').attr('d', this.arc)
            .style('fill', function (d) {
            var dataItem = d.data;
            return _this.colour(dataItem.name);
        }).each(function (d) {
            _this.currentArcs.push(d);
        });
    };
    NeedsPieGraphComponent.prototype.updateChart = function (data) {
        var _this = this;
        var path = this.svg.selectAll('path').data(this.pie(data));
        path.transition().duration(1000).attrTween('d', function (d) {
            var interpolate = d3.interpolate(_this.currentArcs[d.index], d);
            _this.currentArcs[d.index] = interpolate(0);
            return function (t) {
                return _this.arc(interpolate(t));
            };
        });
    };
    return NeedsPieGraphComponent;
}());
export { NeedsPieGraphComponent };
