import { DisclosuresService } from './disclosures.service';
import { ErrorModalProvider } from '../error/error.modal.service';
import { MatDialog } from '@angular/material';
import { DisclosuresModalComponent } from './disclosures-modal.component';
import { isNullOrUndefined } from 'util';
import { WindowRef } from '../window.ref';
import * as i0 from "@angular/core";
import * as i1 from "./disclosures.service";
import * as i2 from "../error/error.modal.service";
import * as i3 from "@angular/material/dialog";
import * as i4 from "../window.ref";
var DisclosuresProvider = /** @class */ (function () {
    function DisclosuresProvider(disclosuresService, errorModalProvider, dialog, windowRef) {
        this.disclosuresService = disclosuresService;
        this.errorModalProvider = errorModalProvider;
        this.dialog = dialog;
        this.windowRef = windowRef;
        this.dialogConfig = {
            disableClose: true,
            position: { top: '20px' },
            maxWidth: '99vw',
            panelClass: 'dl-modal'
        };
        this.modalRef = null;
    }
    DisclosuresProvider.prototype.showDisclosure = function (title, page, category, data, type, callback) {
        this.getData(title, page, type, category, data, callback);
    };
    DisclosuresProvider.prototype.close = function () {
        if (!isNullOrUndefined(this.modalRef)) {
            this.modalRef.close();
        }
    };
    DisclosuresProvider.prototype.createModal = function (callback) {
        var _this = this;
        if (isNullOrUndefined(this.modalRef) || isNullOrUndefined(this.modalRef.componentInstance)) {
            this.modalRef = this.dialog.open(DisclosuresModalComponent, this.dialogConfig);
        }
        this.modalRef.afterClosed().subscribe(function () {
            if (!isNullOrUndefined(_this.modalRef)) {
                _this.windowRef.nativeWindow.scrollTo(0, 0);
            }
            _this.modalRef = null;
            if (!isNullOrUndefined(callback)) {
                callback.onDisclosuresClosed();
            }
        });
    };
    DisclosuresProvider.prototype.getData = function (title, page, type, category, data, callback, spId) {
        var _this = this;
        if (isNullOrUndefined(data)) {
            data = {};
        }
        // add the sales process id to the data
        if (!isNullOrUndefined(spId) && spId !== '') {
            data.sales_process_id = spId;
        }
        // get the disclosures from the server
        this.disclosuresService.getDisclosureGroup(category, page, type, data).subscribe(function (response) {
            _this.handleResult(title, page, type, category, callback, response.disclosures, spId);
        }, function () {
            if (!isNullOrUndefined(callback)) {
                callback.onDisclosuresFailed();
            }
        });
    };
    DisclosuresProvider.prototype.setModalItems = function (title, page, type, category, callback) {
        this.modalRef.disableClose = true;
        this.modalRef.componentInstance.saving = false;
        if (isNullOrUndefined(title) || title.length === 0) {
            this.modalRef.componentInstance.title = DisclosuresModalComponent.DEFAULT_TITLE;
        }
        else {
            this.modalRef.componentInstance.title = title;
        }
        this.modalRef.componentInstance.prefix = category;
        this.modalRef.componentInstance.category = category;
        this.modalRef.componentInstance.page = page;
        this.modalRef.componentInstance.type = type;
        this.modalRef.componentInstance.callback = callback;
    };
    DisclosuresProvider.prototype.handleResult = function (title, page, type, category, callback, result, spId) {
        var _this = this;
        // if there is an SP id apply it to all disclosures
        if (!isNullOrUndefined(spId) && spId !== '') {
            result.forEach(function (dis) { dis.sales_process_id = spId; });
        }
        // on entry already has modal open, the others we load data 1st then show modal
        if (isNullOrUndefined(this.modalRef)) {
            this.createModal(callback);
            callback.onDisclosuresLoaded();
            this.setModalItems(title, page, type, category, callback);
        }
        this.modalRef.componentInstance.disclosures = result;
        // remove the spinner. Needed when we load more than one disclosure.
        this.modalRef.componentInstance.saving = false;
        setTimeout(function () {
            _this.windowRef.nativeWindow.document.querySelector('mat-dialog-content').scrollTop = 0;
        });
    };
    DisclosuresProvider.ngInjectableDef = i0.defineInjectable({ factory: function DisclosuresProvider_Factory() { return new DisclosuresProvider(i0.inject(i1.DisclosuresService), i0.inject(i2.ErrorModalProvider), i0.inject(i3.MatDialog), i0.inject(i4.WindowRef)); }, token: DisclosuresProvider, providedIn: "root" });
    return DisclosuresProvider;
}());
export { DisclosuresProvider };
