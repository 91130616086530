import {Component} from '@angular/core';
import {Dynamic} from './component';
import {FormControl} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material';
import {DotOrgModalComponent} from '../dot-org-modal/dot-org-modal.component';
import {ProjectVO} from '../dot-org-modal/dot-org-data.service';
import {isNullOrUndefined} from 'util';
import {DotOrgVO, PageComponentVO} from '../page.data.vo';
import {DspService} from '../dsp.service';

@Component({
    selector: 'dotorg',
    templateUrl: './dot-org.component.html',
    styleUrls: ['./dot-org.component.scss']
})
export class DotOrgComponent extends Dynamic<PageComponentVO> {

    private dialogRef: MatDialogRef<DotOrgModalComponent>;
    selectedProject: ProjectVO;

    orgVO: DotOrgVO;

    constructor(private dialog: MatDialog,
                private dspService: DspService) {
        super();
    }

    showModal(): boolean {
        this.dialogRef = this.dialog.open(DotOrgModalComponent, {
            data: {
                title: 'Choose a charity',
                area: 'UK'
            },
            panelClass: ['dl-modal', 'dot-org-modal'],
            position: {top: '10px'},
            maxWidth: '98vw'
        });
        this.dialogRef.afterClosed().subscribe(res => {
            if (!isNullOrUndefined(res) && res !== false) {
                this.selectedProject = res;
                this.component.value = this.selectedProject.id;
                this.dspService.currentData.continueText = 'Continue';
            }
        });
        return false;
    }

    setupFromControl() {
        this.component.loaded = true;
        // tel my parent I'm loaded.
        this.loaded.emit(this.component.id);
    }

    getFormControl(): FormControl {
        return undefined;
    }


    onChange(): void {

    }

    processExtraData(): void {
        if (!isNullOrUndefined(this.component.extraInput)) {
            this.orgVO = this.component.extraInput as DotOrgVO;
        }
    }

    get amount(): string {
        if (!isNullOrUndefined(this.orgVO) && !isNullOrUndefined(this.orgVO.premium)) {
            return this.orgVO.premium;
        }
        return null;
    }

    get projectName(): string {
        if (!isNullOrUndefined(this.orgVO) && !isNullOrUndefined(this.orgVO.projectName)) {
            return this.orgVO.projectName;
        }
        if (!isNullOrUndefined(this.selectedProject)) {
            return this.selectedProject.projectName;
        }
        return null;
    }

    get toolTip(): string {
        return 'Each year we donate one of your premiums to a charitable cause. So while you look after your loved ones ' +
            'you also look after others. Different Life lets you choose your good cause.';
    }

    get clientName(): string {
        if (!isNullOrUndefined(this.orgVO) && !isNullOrUndefined(this.orgVO.clientName)) {
            return this.orgVO.clientName;
        }
        return null;
    }

}
