import {Dynamic} from './component';
import {FormControl} from '@angular/forms';
import {MatCheckboxChange} from '@angular/material';
import {Component} from '@angular/core';
import {isNullOrUndefined} from 'util';
import { PageComponentVO } from '../page.data.vo';

@Component({
    templateUrl: 'checkbox.component.html',
    styleUrls: ['checkbox.component.scss'],
    selector: 'checkbox'
})
export class CheckboxComponent extends Dynamic<PageComponentVO> {

    mustBeChecked: boolean;

    getFormControl(): FormControl {
        return undefined;
    }

    setupFromControl(): void {
        this.component.loaded = true;
        // tel my parent I'm loaded.
        this.loaded.emit(this.component.id);
    }

    processExtraData() {
        // if extra input is set to true then the check box must be checked for this control to be valid
        if (isNullOrUndefined(this.component.extraInput)) {
            this.mustBeChecked = false;
        } else {
            this.mustBeChecked = this.component.extraInput as boolean;
        }
        this.component.isValid = !this.mustBeChecked;
        this.change.emit(this.component.counter);
    }

    valueChange(change: MatCheckboxChange) {
        this.component.value = change.checked;
        if (this.mustBeChecked) {
            this.component.isValid = change.checked;
        } else {
            this.component.isValid = true;
        }
        this.change.emit(this.component.counter);
    }
}
