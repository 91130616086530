import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export var httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
var DotOrgDataService = /** @class */ (function () {
    function DotOrgDataService(http) {
        this.http = http;
    }
    DotOrgDataService.prototype.getActiveProjects = function (area) {
        return this.http.get("/dl/data/donation/" + area + "/projects");
    };
    DotOrgDataService.ngInjectableDef = i0.defineInjectable({ factory: function DotOrgDataService_Factory() { return new DotOrgDataService(i0.inject(i1.HttpClient)); }, token: DotOrgDataService, providedIn: "root" });
    return DotOrgDataService;
}());
export { DotOrgDataService };
