import {Pipe, PipeTransform} from '@angular/core';
import {DLCurrencyPipe} from './dl.currency.pipe';

@Pipe({name: 'dl_offer_text'})
export class DlOfferTextPipe implements PipeTransform {

    transform(values: OfferVO): string {
        let s = 'We';
        if (values.personName !== undefined && values.personName !== null && values.personName !== '') {
            let s = values.personName + ', we';
        }
        s += ' can offer you <em>' +
            new DLCurrencyPipe().transform(values.cover, true, 0) +
            '</em> ';
        if (values.productLink !== undefined && values.productLink !== null && values.productLink !== '') {
            s += '<a href="' + values.productLink + '" target="_blank"><em>' + values.productName + '</em></a>';
        } else {
            s += '<em class="primary-text">' + values.productName + '</em>';
        }
        if (values.isWol) {
            if (values.product === 'UK_LC_G55') {
                s += ', in the first 2 years just paid on accidental death,';
            }
        } else {
            s += ' if you die ';
            if (values.product === 'UK_AD') {
                s += 'in an accident ';
            }
            s += 'within <em>' + values.term + ' years</em> ';
        }
        s += ' for <em>' +
            new DLCurrencyPipe().transform(values.premium, true, 2) +
            ' per month</em>.';
        if (values.product === 'UK_LC_G18') {
            s += '<br>In the first 2 years the cover is paid on accidental death only.';
        }
        return s;
    }

}

export class OfferVO {
    product: string;
    term: number;
    cover: number;
    premium: number;
    personName: string;
    productName: string;
    isWol: boolean;
    productLink: string;
}
