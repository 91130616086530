import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import { isNullOrUndefined } from 'util';

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class LlcSummaryService {

    constructor(private http: HttpClient) {}

    public getPricing(request: LLCSimplePricingRequestVO, sp?: string): Observable<CoverOptionsVO> {
        if (!isNullOrUndefined(sp) && sp === 'LLC') return this.http.post<CoverOptionsVO>('/dl/data/pricing/simple-for-llc', request, httpOptions);
        return this.http.post<CoverOptionsVO>('/dl/data/pricing/simple-for-sp', request, httpOptions);
    }
}

export class LLCUtil {
    public static validation = {
        LLC_LC_S: 1,
        LLC_LC_C: 8,
        LLC_LC_E: 4,
    };
}

export class LLCSimplePricingRequestVO {
    spId: string;
    benefitId: string;
    product: string;
    age: number;
    gender: string;
}

export class CoverOptionsVO {
    constructor(status?: string) {
        this.status = status;
    }
    status: string;
    min: number;
    max: number;
    step: number;
    cover: number;
    error: string;
    product_master_id: string;
    pricing: any;
    options: {
        cover: number;
        premium: number;
    }[];
}

export class LLCDependantVO {
    id: string;
    name: string;
    first_name: string;
    last_name: string;
    id_or_dob: string;
    age: number;
    rel: string;
    product: string;
    product_master_id: string;
    pricing: any;
    benefit_amount: number;
    premium_amount: number;
    deleting: boolean;
    gender: string;
    parent_product_benefit_id: string;
}

export class Benefit {
    id: string;
    benefit_amount: any;
    benefit_start_date: string;
    benefit_status: string;
    discount_rate: any;
    name: string;
    premium_amount: any;
    pricing: {
        pricing_version: string;
    };
    product_code: string;
    product_master_id: string;
    pv_acquisition_factor: any;
    pv_claims_factor: any;
    pv_cost_vector: any;
    pv_donation_factor: number;
    pv_expense_factor: any;
    pv_fixed_expenses: any;
    pv_premiums_factor: any;
    sales_process_id: string;
    type: string;
}
