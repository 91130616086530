<div class="dl-row row">
    <div class="dl-col-md-8 col m8 s12">
        <div class="dl-row row">
            <div class="dl-container" style="min-height: 150px;">
                <div class="dl-col-sm-2 col s2"></div>
                <div class="dl-col-sm-8 col s8">
                    <h1>Password reset</h1>
                    <div class="dl-row row" *ngIf="action === 'error'">
                        <div class="dl-col-md-12 col s12">
                            <p>{{errorMessage}}</p>
                        </div>
                    </div>
                    <div *ngIf="action === 'forgot'">
                        <dl-request-password ></dl-request-password>
                    </div>
                    <div *ngIf="action === 'reset'">
                        <dl-set-password [orgUrl]="orgUrl" [oauthClientId]="oauthClientId" [userName]="userName" [token]="token" ></dl-set-password>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
