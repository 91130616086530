<div class="dl-payer" id="payerModal">
    <div mat-dialog-title class="dl-modal-header">
        <a class="close dl-pull-right right" (click)="cancel()"><span class="fa fa-times" tabindex="-1"></span></a>
        <h4 class="modal-title">{{title}}</h4>
    </div>
    <mat-dialog-content>
        <div class="dl-modal-body">
            <form [formGroup]="payerForm">
                <div class="dl-col-md-2 dl-col-sm-6 col m2 s6 dl-question" data-name="title">
                    <mat-form-field class="dl-full-width">
                        <mat-select
                            placeholder="Title of premium payer"
                            required
                            [tabIndex]="2"
                            [formControl]="payerForm.controls['title']">
                            <mat-option *ngFor="let item of salutations" [attr.data-val]="item.value" [value]="item.value">{{item.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="dl-col-md-2 dl-col-sm-6 col m2 s6 dl-question" data-name="firstName">
                    <mat-form-field class="dl-full-width">
                        <input
                            matInput
                            name="first_name"
                            type="text"
                            placeholder="Name of premium payer"
                            tabindex="3"
                            required
                            [formControl]="payerForm.controls['firstName']">
                    </mat-form-field>
                </div>
                <div class="dl-col-md-2 dl-col-sm-6 col m2 s6 dl-question" data-name="lastName">
                    <mat-form-field class="dl-full-width">
                        <input matInput
                               type="text"
                               name="last_name"
                               placeholder="Surname of premium payer"
                               tabindex="4"
                               required
                               [formControl]="payerForm.controls['lastName']">
                    </mat-form-field>
                </div>
                <div class="dl-row row">
                    <div class="dl-col-md-12 col s12" data-name="dob">
                        <mat-form-field class="dl-full-width">
                            <input matInput
                                   tabindex="5"
                                   placeholder="RSA ID number of premium payer"
                                   [formControl]="payerForm.controls['idDob']"
                            >
                            <mat-error align="start">Enter a Valid RSA-id number</mat-error>
                            <mat-hint align="start" *ngIf="payerForm.controls['idDob'].invalid && !payerForm.controls['idDob'].pristine">Enter a Valid RSA-id number</mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <div class="dl-row row">
                    <div class="dl-col-md-12 col s12" data-name="relation">
                        <mat-form-field class="dl-full-width">
                            <mat-select
                                    [tabIndex]="6"
                                    placeholder="Relationship to Policy Holder"
                                    [formControl]="payerForm.controls['relation']">
                                <mat-option
                                        *ngFor="let item of relationOptions"
                                        [attr.data-val]="item.value"
                                        [value]="item.value"
                                >{{item.label}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="dl-row row">
                    <div class="dl-col-md-12 col s12" data-name="email">
                        <mat-form-field class="dl-full-width">
                            <input matInput
                                   tabindex="7"
                                   placeholder="Email of premium payer"
                                   required
                                   [formControl]="payerForm.controls['email']"
                            >
                        </mat-form-field>
                    </div>
                </div>
                <div class="dl-row row">
                    <div class="dl-col-md-12 col s12" data-name="phone">
                        <mat-form-field class="dl-full-width">
                            <input matInput
                                   tabindex="8"
                                   placeholder="Phone of premium payer"
                                   required
                                   [formControl]="payerForm.controls['phone_number']"
                            >
                        </mat-form-field>
                    </div>
                </div>
                <div class="dl-col-md-2 dl-col-sm-6 col m2 s6 dl-question" data-name="notes">
                    <mat-form-field class="dl-full-width">
                        <input
                            matInput
                            name="notes"
                            type="text"
                            placeholder="Notes about premium payer"
                            tabindex="9"
                            required
                            [formControl]="payerForm.controls['notes']">
                    </mat-form-field>
                </div>
            </form>
        </div>
        <div class="dl-modal-footer">
            <div class="dl-container-no-padding">
                <div class="dl-row row">
                    <div class="dl-col-md-4 col m4 s12"></div>
                    <div class="dl-col-md-4 col m4 s12">
                        <button mat-raised-button class="dl-action-btn dl-full-width" (click)="cancel()" tabindex="4" id="payer-cancel">Cancel</button>
                    </div>
                    <div class="dl-col-md-4 col m4 s12">
                        <button mat-raised-button color="primary" class="dl-action-btn dl-full-width" (click)="save()" tabindex="3" [disabled]="payerForm.invalid" id="payer-save">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
</div>
