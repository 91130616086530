import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NeedsComponent } from './needs.component';
import { ReactiveFormsModule } from '@angular/forms';
import {
    MatButtonModule, MatCheckboxModule, MatDialogModule, MatInputModule, MatRadioModule,
    MatSelectModule, MatSliderModule, MatSlideToggleModule, MatTabsModule, MatTooltipModule
} from '@angular/material';
import {TextMaskModule} from 'angular2-text-mask';
import {HttpClientModule} from '@angular/common/http';
import {DisclosuresModule} from '../base/disclosures/disclosures.module';
import {ErrorModule} from '../base/error/error.module';
import {NeedsResultsComponent} from './needs.results.component';
import {NeedsPieGraphComponent} from './needs.pie.graph.component';
import {BaseModule} from '../base/base.module';

@NgModule({
    declarations: [
        NeedsComponent,
        NeedsResultsComponent,
        NeedsPieGraphComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatRadioModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        MatButtonModule,
        MatDialogModule,
        MatTabsModule,
        MatTooltipModule,
        TextMaskModule,
        HttpClientModule,
        DisclosuresModule,
        ErrorModule,
        MatSliderModule,
        BaseModule
    ],
    entryComponents: [
        NeedsComponent,
        NeedsResultsComponent
    ],
    exports: [
        NeedsComponent
    ]
})
export class NeedsModule {}

