var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, forwardRef, Renderer2 } from '@angular/core';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AsYouType, parsePhoneNumber } from 'libphonenumber-js';
import { isNullOrUndefined } from 'util';
import { LocaleHelper } from './locale.helper';
var PHONE_NUMBER_INPUT_CONTROL = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(function () { return PhoneNumberInputDirective; }),
    multi: true,
};
var PhoneNumberInputDirective = /** @class */ (function (_super) {
    __extends(PhoneNumberInputDirective, _super);
    function PhoneNumberInputDirective(renderer, element) {
        var _this = _super.call(this, renderer, element, true) || this;
        _this.element = element;
        _this._asYouType = new AsYouType(LocaleHelper.getCountryCode());
        return _this;
    }
    PhoneNumberInputDirective.prototype.writeValue = function (value) {
        var formatted = this.formatNumber(value);
        _super.prototype.writeValue.call(this, formatted);
    };
    PhoneNumberInputDirective.prototype.onInput = function (value) {
        if (!isNullOrUndefined(value) && typeof value === 'string') {
            var start = this.element.nativeElement.selectionStart;
            var moveCursor = false;
            if (start < value.length) {
                moveCursor = true;
            }
            var formatted = this.formatNumber(value);
            _super.prototype.writeValue.call(this, formatted);
            this.onChange(formatted);
            if (moveCursor) {
                this.element.nativeElement.setSelectionRange(start, start);
            }
        }
    };
    PhoneNumberInputDirective.prototype.formatNumber = function (value) {
        if (!isNullOrUndefined(value) && typeof value === 'string') {
            this._asYouType.reset();
            var number = this._asYouType.input(value);
            if (!isNullOrUndefined(number)) {
                return number;
            }
        }
        return value;
    };
    return PhoneNumberInputDirective;
}(DefaultValueAccessor));
export { PhoneNumberInputDirective };
var PhoneNumberValidator = /** @class */ (function () {
    function PhoneNumberValidator() {
    }
    PhoneNumberValidator.validator = function () {
        return function (control) {
            if (!isNullOrUndefined(control.value) && control.value !== '') {
                try {
                    var number = parsePhoneNumber(control.value, LocaleHelper.getCountryCode());
                    if (!isNullOrUndefined(number) && number.isValid()) {
                        // ZA rules seem to be producing invalid numbers, make sure it is at least the standard 10 digits
                        if (LocaleHelper.getCountryCode() === 'ZA') {
                            if (!new RegExp(TelUtil.telPattern).test(number.number)) {
                                return { phoneInvalid: true };
                            }
                        }
                        return null;
                    }
                    else {
                        return { phoneInvalid: true };
                    }
                }
                catch (e) {
                    return { phoneInvalid: true };
                }
            }
            // if nothing is entered regard the control as valid
            return null;
        };
    };
    return PhoneNumberValidator;
}());
export { PhoneNumberValidator };
var TelUtil = /** @class */ (function () {
    function TelUtil() {
    }
    TelUtil.telPattern = '^(?:[0-9]{3}|\\+27\\d{2}?\\s?|\\(\\+27\\d{2}\\)\\s?|\\([0-9]{3}\\)\\s?)(?:[\\-\\s]?\\d{3})(?:[\\-\\s]?\\d{4})$';
    return TelUtil;
}());
export { TelUtil };
