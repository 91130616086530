var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Dynamic } from './component';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { minMaxValidation } from '../../base/min.max.validator';
import { BmiDetailVO } from '../page.data.vo';
var BmiComponent = /** @class */ (function (_super) {
    __extends(BmiComponent, _super);
    function BmiComponent(fb) {
        var _this = _super.call(this) || this;
        _this.fb = fb;
        _this.heightM = 0;
        _this.heightIn = 0;
        _this.weightKg = 0;
        _this.weightLb = 0;
        _this.bmiValue = 0;
        _this.bmiData = new BmiDetailVO();
        _this.showM = true;
        _this.showKG = true;
        _this.heightMask = {
            mask: function (rawValue) {
                var test = rawValue.replace(/\D+/g, '');
                if (test.length > 1 && test.length <= 2) {
                    return [/[1|2]/, '.', /\d/, ' m'];
                }
                if (test.length >= 3) {
                    return [/[1|2]/, '.', /\d/, /\d/, ' m'];
                }
                return [/[1|2]/, ' m'];
            },
            guide: true
        };
        _this.setupForm();
        return _this;
    }
    BmiComponent.heightMetricValidator = function (min, max, comp) {
        return function (group) {
            var height = comp.heightM;
            var ftComponet = group.controls['heightIn'];
            var mComponet = group.controls['heightM'];
            if (min < height && height <= max) {
                ftComponet.setErrors(null);
                mComponet.setErrors(null);
                return;
            }
            var error = { invalidHeight: 'Invalid height' };
            ftComponet.setErrors(error);
            mComponet.setErrors(error);
        };
    };
    BmiComponent.weightMetricValidator = function (min, max, comp) {
        return function (group) {
            var weight = comp.weightKg;
            var lbComponet = group.controls['weightLb'];
            var kgComponet = group.controls['weightKg'];
            if (min < weight && weight <= max) {
                lbComponet.setErrors(null);
                kgComponet.setErrors(null);
                return null;
            }
            var error = { invalidWeight: 'Invalid weight' };
            lbComponet.setErrors(error);
            kgComponet.setErrors(error);
        };
    };
    BmiComponent.prototype.setupForm = function () {
        var _this = this;
        this.bmi = this.fb.group({
            heightSelector: ['m'],
            weightSelector: ['kg'],
            heightM: ['', [Validators.required]],
            weightKg: ['', [Validators.required]],
            heightFt: ['', [Validators.required, Validators.pattern('[0-9]*')]],
            heightIn: ['0', [Validators.required, minMaxValidation(0, 11)]],
            weightSt: ['', []],
            weightLb: ['0', [Validators.required]]
        }, { validator: [
                BmiComponent.heightMetricValidator(0.5, 2.5, this),
                BmiComponent.weightMetricValidator(20, 600, this)
            ] });
        this.bmi.get('heightSelector').valueChanges.subscribe(function (val) { return _this.heightSelectorChanged(val); });
        this.bmi.get('weightSelector').valueChanges.subscribe(function (val) { return _this.weightSelectorChanged(val); });
        this.bmi.get('heightM').valueChanges.subscribe(function (val) { return _this.heightCMChanged(val); });
        this.bmi.get('weightKg').valueChanges.subscribe(function (val) { return _this.weightKgChanged(val); });
        this.bmi.get('heightFt').valueChanges.subscribe(function (val) { return _this.heightImperialChange(val === '' ? null : val, null); });
        this.bmi.get('heightIn').valueChanges.subscribe(function (val) { return _this.heightImperialChange(null, val === '' ? null : val); });
        this.bmi.get('weightSt').valueChanges.subscribe(function (val) { return _this.weightImperialChange(val === '' ? null : val, null); });
        this.bmi.get('weightLb').valueChanges.subscribe(function (val) { return _this.weightImperialChange(null, val === '' ? null : val); });
        this.heightSelectorChanged('m');
        this.weightSelectorChanged('kg');
    };
    BmiComponent.prototype.setupFromControl = function () {
        this.formGroup.addControl('bmi', this.bmi);
        // we use the nested from to tel the parent of its validity
        this.component.isValid = true;
    };
    BmiComponent.prototype.getFormControl = function () {
        // this component will notify parent based on it own group
        return null;
    };
    BmiComponent.prototype.processExtraData = function () {
        if (!isNullOrUndefined(this.component)) {
            if (!isNullOrUndefined(this.component.extraInput)) {
                this.bmiData.qAnswerId = this.component.extraInput;
            }
            this.component.value = this.bmiData;
        }
    };
    BmiComponent.prototype.heightSelectorChanged = function (val) {
        if (val === 'm') {
            this.bmi.get('heightM').setValue(this.heightM === 0 ? '' : this.heightM);
            this.bmi.get('heightM').enable();
            this.bmi.get('heightFt').disable();
            this.bmi.get('heightIn').disable();
            this.showM = true;
        }
        else {
            var feet = 0;
            var inch = 0;
            if (!isNaN(this.heightIn) && this.heightIn !== 0) {
                feet = Math.floor(this.heightIn / 12);
                inch = Math.floor(this.heightIn % 12);
            }
            this.bmi.get('heightFt').setValue(feet === 0 ? '' : feet);
            this.bmi.get('heightIn').setValue(inch === 0 ? '0' : inch);
            this.bmi.get('heightM').disable();
            this.bmi.get('heightFt').enable();
            this.bmi.get('heightIn').enable();
            this.showM = false;
        }
    };
    BmiComponent.prototype.weightSelectorChanged = function (val) {
        if (val === 'kg') {
            this.bmi.get('weightKg').setValue(this.weightKg === 0 ? '' : this.weightKg);
            this.bmi.get('weightKg').enable();
            this.bmi.get('weightSt').disable();
            this.bmi.get('weightLb').disable();
            this.showKG = true;
        }
        else {
            var stone = 0;
            var pound = 0;
            if (!isNaN(this.weightLb) && this.weightLb !== 0) {
                stone = Math.floor(this.weightLb / 14);
                pound = Math.floor((this.weightLb % 14) * 100) / 100;
            }
            this.bmi.get('weightSt').setValue(stone === 0 ? '' : stone);
            this.bmi.get('weightLb').setValue(pound === 0 ? '0' : pound);
            this.bmi.get('weightKg').disable();
            this.bmi.get('weightSt').enable();
            this.bmi.get('weightLb').enable();
            this.showKG = false;
        }
    };
    BmiComponent.prototype.heightCMChanged = function (val) {
        if (!isNullOrUndefined(val) && val !== '') {
            this.heightM = val;
            this.heightIn = this.heightM * 39.37;
        }
        else {
            this.heightM = 0;
            this.heightIn = 0;
        }
        this.calculateBmi();
    };
    BmiComponent.prototype.weightKgChanged = function (val) {
        if (!isNullOrUndefined(val) && val !== '') {
            this.weightKg = val;
            this.weightLb = Math.round(this.weightKg * 2.205 * 100) / 100;
        }
        else {
            this.weightKg = 0;
            this.weightLb = 0;
        }
        this.calculateBmi();
    };
    BmiComponent.prototype.getImperialValue = function (value, field) {
        if (isNullOrUndefined(value) || isNaN(value)) {
            var inputVal = this.bmi.get(field).value;
            if (isNaN(inputVal) || inputVal === '') {
                return 0;
            }
            else {
                return parseInt(inputVal + '', 10);
            }
        }
        else {
            return parseInt(value + '', 10);
        }
    };
    BmiComponent.prototype.heightImperialChange = function (feet, inch) {
        var feetForCalc = this.getImperialValue(feet, 'heightFt');
        var inchForCalc = this.getImperialValue(inch, 'heightIn');
        this.heightIn = (feetForCalc * 12) + inchForCalc;
        if (!isNaN(this.heightIn) && this.heightIn !== 0) {
            this.heightM = Math.round(this.heightIn / 39.37 * 100) / 100;
        }
        this.calculateBmi();
        if (feet != null) {
            this.bmi.get('heightIn').markAsTouched();
        }
        else {
            this.bmi.get('heightFt').markAsTouched();
        }
    };
    BmiComponent.prototype.weightImperialChange = function (stone, pound) {
        var stoneForCalc = this.getImperialValue(stone, 'weightSt');
        var poundForCalc = this.getImperialValue(pound, 'weightLb');
        this.weightLb = (stoneForCalc * 14) + poundForCalc;
        if (!isNaN(this.weightLb) && this.weightLb !== 0) {
            this.weightKg = Math.round((this.weightLb / 2.205) * 100) / 100;
        }
        this.calculateBmi();
    };
    BmiComponent.prototype.calculateBmi = function () {
        if (this.weightKg !== 0 && this.heightM !== 0) {
            this.bmiValue = Math.floor(this.weightKg / (this.heightM * this.heightM));
        }
        else {
            this.bmiValue = 0;
        }
        this.populateComponentValue();
    };
    BmiComponent.prototype.populateComponentValue = function () {
        this.bmiData.heightM = this.heightM;
        this.bmiData.weightKG = this.weightKg;
        this.bmiData.heightImperial = this.heightInDisp;
        this.bmiData.weightImperial = this.weightLbDisp;
        this.bmiData.bmi = this.bmiValue;
        this.bmiData.showM = this.showM;
        this.bmiData.showKG = this.showKG;
        if (!isNullOrUndefined(this.component)) {
            if (isNullOrUndefined(this.component.value)) {
                this.component.value = this.bmiData;
            }
        }
    };
    Object.defineProperty(BmiComponent.prototype, "heightInDisp", {
        get: function () {
            return '' + Math.floor(this.heightIn / 12) + '"' + Math.floor(this.heightIn % 12) + '\'';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BmiComponent.prototype, "weightLbDisp", {
        get: function () {
            return '' + Math.floor(this.weightLb / 14) + 'st ' + (Math.floor((this.weightLb % 14) * 100) / 100) + 'lb';
        },
        enumerable: true,
        configurable: true
    });
    BmiComponent.prototype.getMaskFor = function (question) {
        if (question === 'height') {
            return this.heightMask;
        }
    };
    return BmiComponent;
}(Dynamic));
export { BmiComponent };
