import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ContactBlockService, ContactMeVO} from '../contact-block.service';
import {PhoneNumberValidator} from '../../base/phone-number';
import {DLUtil} from '../../base/dl.util';
import {isNullOrUndefined} from 'util';

@Component({
    selector: 'contact-block-expanded',
    templateUrl: './contact-block-expanded.component.html',
    styleUrls: ['./contact-block-expanded.component.scss']
})
export class ContactBlockExpandedComponent implements OnInit {

    success = false;
    busy = false;

    constructor(private fb: FormBuilder,
                private service: ContactBlockService) {
        this.initForm();
    }

    ngOnInit() {
    }

    // tslint:disable-next-line:member-ordering
    _campaignCode = '';
    @Input('campaign-code')
    set campaignCode(val: string) {
        this._campaignCode = val;
    }

    // tslint:disable-next-line:member-ordering
    _leadSourceDescription = 'Contact Block';
    @Input('lead-source-description')
    set leadSourceDescription(val: string) {
        this._leadSourceDescription = val;
    }

    // tslint:disable-next-line:member-ordering
    _providerId = '';
    @Input('provider-id')
    set providerId(val: string) {
        this._providerId = val;
    }

    // tslint:disable-next-line:member-ordering
    _referrer = '';
    @Input()
    set referrer(val: string) {
        this._referrer = val;
    }

    // tslint:disable-next-line:member-ordering
    _referrerCode = '';
    @Input('referrer-code')
    set referrerCode(val: string) {
        this._referrerCode = val;
    }

    private setBgColour(): void {
        this.rgbColour = 'rgba(' + this._bgColour + ', ' + this._opacity + ')';
    }

    public getBackGround(): object {
        return {'background-color': this.rgbColour};
    }

    // tslint:disable-next-line:member-ordering
    rgbColour = 'rgba(255, 255, 255, 0.6)';
    // tslint:disable-next-line:member-ordering
    _bgColour = '255,255,255';
    @Input('bg-colour')
    set bgColour(val: string) {
        this._bgColour = val;
        this.setBgColour();
    }

    // tslint:disable-next-line:member-ordering
    _opacity = 0.6;
    @Input()
    set opacity(val: number) {
        this._opacity = val;
        this.setBgColour();
    }

    // tslint:disable-next-line:member-ordering
    _heading1 = 'Need Assistance?';
    @Input('top-text-1')
    set heading1(val: string) {
        this._heading1 = val;
    }

    get heading1(): string {
        return this._heading1;
    }

    // tslint:disable-next-line:member-ordering
    _heading2 = '';
    @Input('top-text-2')
    set heading2(val: string) {
        this._heading2 = val;
    }

    get heading2(): string {
        return this._heading2;
    }

    // tslint:disable-next-line:member-ordering
    _bottomText1 = '';
    @Input('bottom-text-1')
    set bottomText1(val: string) {
        this._bottomText1 = val;
    }

    get bottomText1(): string {
        return this._bottomText1;
    }

    // tslint:disable-next-line:member-ordering
    _bottomText2 = '';
    @Input('bottom-text-2')
    set bottomText2(val: string) {
        this._bottomText2 = val;
    }

    get bottomText2(): string {
        return this._bottomText2;
    }

    // tslint:disable-next-line:member-ordering
    _buttonText = 'Please call me';
    @Input('button-text')
    set buttonText(val: string) {
        this._buttonText = val;
    }

    get buttonText(): string {
        return this._buttonText;
    }

    // tslint:disable-next-line:member-ordering
    _showEmail = false;
    @Input('show-email')
    set showEmail(val: boolean) {
        this._showEmail = val;
    }

    get showEmail(): boolean {
        return this._showEmail;
    }

    // tslint:disable-next-line:member-ordering
    _skipLead = false;
    @Input('skip-lead')
    set skipLead(val: boolean) {
        this._skipLead = val;
    }

    get skipLead(): boolean {
        return this._skipLead;
    }

    // tslint:disable-next-line:member-ordering
    cbForm: FormGroup;

    private initForm() {
        this.cbForm = this.fb.group({
            name: ['', [Validators.required]],
            surname: ['', [Validators.required]],
            cell: ['', [Validators.required, PhoneNumberValidator.validator()]],
            alt_cell: ['', [PhoneNumberValidator.validator()]],
            email: ['', [Validators.required, Validators.email, Validators.pattern(DLUtil.emailPattern)]],
        });
    }

    saveLead() {
        this.busy = true;
        const data = new ContactMeVO();
        const nameParts = this.cbForm.get('name').value.split(' ');
        if (nameParts.length > 1) {
            data.firstname = nameParts[0];
            for (let i = 1; i < nameParts.length; i++) {
                if (isNullOrUndefined(data.lastname)) {
                    data.lastname = nameParts[i];
                } else {
                    data.lastname += ' ' + nameParts[i];
                }
            }
        } else {
            data.firstname = this.cbForm.get('name').value;
        }
        data.leadtype = 'mlf';
        data.lastname = this.cbForm.get('surname').value;
        data.phonemobile = this.cbForm.get('cell').value.replace(/\D+/g, '');
        if (this.cbForm.get('email').value !== '') {
            data.email = this.cbForm.get('email').value;
        }
        if (this.cbForm.get('alt_cell').value !== '') {
            data.alt_phone = this.cbForm.get('alt_cell').value;
        }
        data.campaign_code = this._campaignCode;
        if (this._leadSourceDescription !== '') {
            data.leadsourcedescription = this._leadSourceDescription;
        }
        data.referrer_code = this._referrerCode;
        data.referrer = this._referrer;
        data.provider_id = this._providerId;

        this.service.submitCallMeRequest(data).subscribe(() => this.showSuccess(), () => this.handleFail());
    }

    private showSuccess(): void {
        this.success = true;
        this.busy = false;
    }

    private handleFail() {
        this.success = false;
        this.busy = false;
    }

}
