import {Injectable} from '@angular/core';
import {ErrorModalProvider} from '../error/error.modal.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs/index';
import {ErrorVO} from '../error/error.vo';

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
    providedIn: 'root'
})
export class PricingService {

    private postUrl = '/dl/data/pricing';

    constructor(private httpClient: HttpClient,
                private errorModalProvider: ErrorModalProvider) {
    }

    public requestPremium(request: SimplePremiumRequest): Observable<SimplePremiumResponse> {
        const simplePremiumUrl = this.postUrl + '/simple-premium';
        return this.httpClient
            .post<SimplePremiumResponse>(simplePremiumUrl, request, httpOptions)
            .pipe(catchError(this.handleError<SimplePremiumResponse>(this.errorModalProvider)));
    }

    public handleError<T>(errorDialogService: ErrorModalProvider, heading?: string) {
        return (error: any): Observable<T> => {
            const vo: ErrorVO = ErrorVO.toErrorVO(error);
            errorDialogService.showError(vo, heading);
            return throwError(error);
        };
    }

}

export class SimplePremiumRequest {
    productGroup: string;
    productCode: string;
    age: number;
    gender: string;
    smoker: string;
    term: number;
    bmiLoading: number;
    sumAssured: number;
}

export class SimplePremiumResponse {
    version: string;
    premium: number;
}
