<mat-card>
    <mat-card-content>
        <div *ngIf="isBusy" class="dl-loading absolute">
            <p>
                <span class="fa fa-circle-o-notch fa-spin"></span>
            </p>
        </div>
        <!-- <base-additional-products
            [spId]="spId"
            [benefitId]="benefitId"
            [leadName]="leadName"
            [products]="products"
            [eventCategory]="'DSP-' + productCode"
            (add)="add($event)"
            (edit)="edit($event.main, $event.product)"
            (remove)="remove($event)"></base-additional-products> -->

        <llc-summary
            [spId]="spId"
            [benefitId]="benefitId"
            [leadName]="leadName"
            [eventCategory]="'DSP-' + productCode"
            [dependants]="dependants"
            [dependantProducts]="getdependantProducts"
            [additionalProducts]="getAdditionalProducts"
            (add)="add($event.product, $event.type)"
            (edit)="edit($event.main, $event.dependant)"
            (remove)="remove($event.id, $event.type)"
        ></llc-summary>
    </mat-card-content>
</mat-card>
