var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { of } from 'rxjs';
import { catchError, timeoutWith } from 'rxjs/operators';
import { ValidationResVO } from '../../dsp/component/banking.validation.service';
import { AVSResultVO, DebicheckSupportedResVO } from '../../dsp/component/banking.validation.service';
import { HttpClient } from '@angular/common/http';
import { ErrorModalProvider } from '../error/error.modal.service';
import { BaseService } from '../base.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../error/error.modal.service";
var DlBankingValidationService = /** @class */ (function (_super) {
    __extends(DlBankingValidationService, _super);
    function DlBankingValidationService(httpClient, errorModalProvider) {
        var _this = _super.call(this, httpClient, errorModalProvider) || this;
        _this.validateServiceUrl = '/dl/data/banking/validate';
        _this.avsServiceUrl = '/dl/data/banking/avsValidation';
        _this.avsValidationResultUrl = '/dl/data/banking/avsStatus';
        _this.debicheckValidationServiceUrl = '/dl/data/banking/debicheck/supported';
        _this.debicheckValidationServiceNoAVSUrl = '/dl/data/banking/debicheck/supportedNoAVS';
        _this.riskAssessmentServiceUrl = '/dl/data/banking/riskAndKyc/assessment';
        _this.riskAndKycStatusUrl = '/dl/data/banking/riskAndKyc/status';
        _this.sendClientDocumentsUrl = '/dl/data/banking/send-client-documents-email';
        _this.overrideRequest = '/dl/override';
        return _this;
    }
    DlBankingValidationService.prototype.validateBanking = function (bankDetail) {
        var req = {
            account_number: bankDetail.accountNumber,
            branch_code: bankDetail.branchCode,
            account_type: bankDetail.accountType,
            ref: bankDetail.validationRef
        };
        return this.httpClient.post(this.validateServiceUrl, req, this.httpOptions())
            .pipe(timeoutWith(7000, this.dummyValidationSuccessResponse()), catchError(this.handleHTTPError()));
    };
    DlBankingValidationService.prototype.validateBankingAVS = function (bankDetail) {
        var req = {
            ref: bankDetail.validationRef,
            account_number: bankDetail.accountNumber,
            account_type: bankDetail.accountType,
            branch_code: bankDetail.branchCode
        };
        return this.httpClient.post(this.avsServiceUrl, req, this.httpOptions())
            .pipe(timeoutWith(20000, this.timeoutAvsErrorResponse()), catchError(this.handleHTTPError()));
    };
    DlBankingValidationService.prototype.getAvsValidationStatus = function (userRef) {
        var req = {
            userRef: userRef
        };
        return this.httpClient.post(this.avsValidationResultUrl, req, this.httpOptions())
            .pipe(catchError(this.handleHTTPError()));
    };
    DlBankingValidationService.prototype.dummyValidationSuccessResponse = function () {
        var res = new ValidationResVO();
        res.valid = true;
        return of(res);
    };
    DlBankingValidationService.prototype.timeoutAvsErrorResponse = function () {
        var res = new AVSResultVO();
        res.success = false;
        res.count = 0;
        res.errmsg = 'AVS Validation timed out';
        return of(res);
    };
    DlBankingValidationService.prototype.getDebicheckSupported = function (bankName, userRef) {
        var req = {
            bankName: bankName,
            userRef: userRef
        };
        return this.httpClient.post(this.debicheckValidationServiceUrl, req, this.httpOptions())
            .pipe(timeoutWith(7000, this.timeoutDebicheckValidationErrorResponse()), catchError(this.handleHTTPError()));
    };
    DlBankingValidationService.prototype.getDebicheckSupportedNoAVS = function (bankName) {
        var req = {
            bankName: bankName
        };
        return this.httpClient.post(this.debicheckValidationServiceNoAVSUrl, req, this.httpOptions())
            .pipe(timeoutWith(7000, this.timeoutDebicheckValidationErrorResponse()), catchError(this.handleHTTPError()));
    };
    DlBankingValidationService.prototype.sendOverrideRequest = function (overrideItemId, overrideContext, accountExists, acceptsDebits, idMatches, phoneValid, forAltPayer, relation, notes, bankSupportsRTAvs) {
        if (accountExists === void 0) { accountExists = false; }
        if (acceptsDebits === void 0) { acceptsDebits = false; }
        if (idMatches === void 0) { idMatches = false; }
        if (phoneValid === void 0) { phoneValid = false; }
        if (forAltPayer === void 0) { forAltPayer = false; }
        if (relation === void 0) { relation = 'Other'; }
        if (notes === void 0) { notes = ''; }
        if (bankSupportsRTAvs === void 0) { bankSupportsRTAvs = false; }
        var req = {
            override_item_type: overrideContext,
            overrideItemId: overrideItemId,
            override_page: 'ops-override',
            account_exists: accountExists,
            accepts_debits: acceptsDebits,
            id_number_matches: idMatches,
            phone_number_matches: phoneValid,
            for_alt_payer: forAltPayer,
            relation: relation,
            notes: notes,
            bankSupportsRTAvs: bankSupportsRTAvs
        };
        return this.httpClient.post('/dl/override-ops', req, this.httpOptions())
            .pipe(catchError(this.handleHTTPError()));
    };
    DlBankingValidationService.prototype.sendRiskOverrideRequest = function (overrideItemId, overrideContext, riskRating, incomeSource, kycReviewNeeded, isOver5Mil) {
        var req = {
            override_item_type: overrideContext,
            override_item_id: overrideItemId,
            override_page: 'risk-override',
            risk_rating: riskRating,
            income_source: incomeSource,
            kyc_review_needed: kycReviewNeeded,
            is_over_5_mil: isOver5Mil
        };
        return this.httpClient.post('/dl/override-risk', req, this.httpOptions())
            .pipe(catchError(this.handleHTTPError()));
    };
    DlBankingValidationService.prototype.checkOverrideStatus = function (policyID) {
        return this.httpClient.get(this.overrideRequest + '/' + policyID, this.httpOptions());
    };
    DlBankingValidationService.prototype.timeoutDebicheckValidationErrorResponse = function () {
        var res = new DebicheckSupportedResVO();
        res.success = false;
        res.msg = 'Debicheck Support Validation timed out';
        return of(res);
    };
    DlBankingValidationService.prototype.timeoutRiskAssessmentErrorResponse = function () {
        var res = new KycAndRiskAssessmentResponseVO();
        res.success = false;
        res.reason = 'Risk assessment timed out';
        return of(res);
    };
    DlBankingValidationService.prototype.performKycAndRiskAssessment = function (incomeSource, policyId, contactId) {
        var req = {
            incomeSource: incomeSource,
            policyId: policyId,
            contactId: contactId
        };
        return this.httpClient.post(this.riskAssessmentServiceUrl, req, this.httpOptions())
            .pipe(timeoutWith(7000, this.timeoutRiskAssessmentErrorResponse()), catchError(this.handleHTTPError()));
    };
    DlBankingValidationService.prototype.getKycAndRiskStatus = function (contactId, policyId) {
        return this.httpClient.get(this.riskAndKycStatusUrl + '/' + contactId + '/' + policyId, this.httpOptions())
            .pipe(timeoutWith(7000, this.timeoutRiskAssessmentErrorResponse()), catchError(this.handleHTTPError()));
    };
    DlBankingValidationService.prototype.sendEmailDocumentRequest = function (contactId) {
        return this.httpClient.get(this.sendClientDocumentsUrl + "/" + contactId, this.httpOptions())
            .pipe(catchError(this.handleHTTPError()));
    };
    DlBankingValidationService.ngInjectableDef = i0.defineInjectable({ factory: function DlBankingValidationService_Factory() { return new DlBankingValidationService(i0.inject(i1.HttpClient), i0.inject(i2.ErrorModalProvider)); }, token: DlBankingValidationService, providedIn: "root" });
    return DlBankingValidationService;
}(BaseService));
export { DlBankingValidationService };
var KycAndRiskAssessmentResponseVO = /** @class */ (function () {
    function KycAndRiskAssessmentResponseVO() {
    }
    return KycAndRiskAssessmentResponseVO;
}());
export { KycAndRiskAssessmentResponseVO };
