<mat-form-field class="dl-full-width">
    <input
        matInput
        tabindex="1"
        [name]="component.id"
        placeholder="{{component.name}}"
        [formControl]="ukPostcode"
        [attr.data-name]="component.id"
    >
    <mat-error *ngIf="ukPostcode.invalid">{{getErrorMessage()}}</mat-error>
</mat-form-field>
