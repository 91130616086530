
export class Payer {
    id: string;
    salutation: string;
    first_name: string;
    last_name: string;
    id_number: string;
    relation: string;
    email1: string;
    phone_mobile: string;
    policy_id: string;
    notes: string;

    constructor() {
    }

    public static copyOf(me: Payer): Payer {
        let copy = new Payer();
        copy.id = me.id;
        copy.salutation = me.salutation;
        copy.first_name = me.first_name;
        copy.last_name = me.last_name;
        copy.id_number = me.id_number;
        copy.relation = me.relation;
        copy.email1 = me.email1;
        copy.phone_mobile = me.phone_mobile;
        copy.policy_id = me.policy_id;
        copy.notes = me.notes;
        return copy;
    }
}
