import {Component} from '@angular/core';
import {isNullOrUndefined} from 'util';
import {RsaId, rsaIdDateOfBirthValidation, rsaIdValidation, RsaIdValidationCallback} from '../../base/rsa.id.validator';
import {FormControl, Validators} from '@angular/forms';
import {Dynamic} from './component';
import {NumberValidationVO, PageComponentVO} from '../page.data.vo';

@Component({
    selector: 'rsa-id-dob-input',
    templateUrl: 'rsa.id.component.html'
})
export class RsaIdDobComponent extends Dynamic<PageComponentVO> implements RsaIdValidationCallback {
    featureSS3217_COMPULSORY_BENEFICIARY_ID: boolean = true;

    rsaIdControl: FormControl = new FormControl('');

    onIdValidationChange(rsaId: RsaId): void {
        if (this.featureSS3217_COMPULSORY_BENEFICIARY_ID)
            !rsaId.isValid() ? this.rsaIdControl.setErrors({ 'invalid': true }) : this.rsaIdControl.setErrors(null);
    }

    getFormControl(): FormControl {
        let min;
        let max;
        if (!isNullOrUndefined(this.component.extraInput)) {
            min = (this.component.extraInput as NumberValidationVO).min;
            max = (this.component.extraInput as NumberValidationVO).max;
        }
        if (isNullOrUndefined(this.component.required) || this.component.required) {
            this.rsaIdControl.setValidators([Validators.required, Validators.pattern('[0-9]*'), this.featureSS3217_COMPULSORY_BENEFICIARY_ID ? rsaIdValidation(false, this, min, max) : rsaIdDateOfBirthValidation(min, max)]);
        } else {
            this.rsaIdControl.setValidators([Validators.pattern('[0-9]*'), rsaIdDateOfBirthValidation(min, max)]);
        }
        return this.rsaIdControl;
    }

    onChange(): void {
        this.component.isValid = this.rsaIdControl.valid;
        this.component.value = this.rsaIdControl.value;
        if (!isNullOrUndefined(this.change)) {
            this.change.emit(this.component.counter);
        }
    }

    get errorMessage(): string {
        if (this.rsaIdControl.hasError('too_young')) {
            return 'Sorry too young';
        } else if (this.rsaIdControl.hasError('too_old')) {
            return 'Sorry too old';
        }
        return this.featureSS3217_COMPULSORY_BENEFICIARY_ID ? 'Enter a Valid RSA-id' : 'Enter a Valid RSA-id or DOB : (yyyyMMdd)';
    }
}
