import {PageComponentVO, ValidationResult} from '../page.data.vo';
import {EventEmitter, Injectable, Input, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {isNullOrUndefined} from 'util';
import {Observable, Subject} from 'rxjs';

/**
 * Base dynamic class for all dynamic controllers
 */
export abstract class Dynamic<T extends PageComponentVO> implements DynamicControl {
    @Input()
    flowId: string;

    constructor() {
    }

    _formGroup: FormGroup;
    @Input()
    set formGroup(val: FormGroup) {
        this._formGroup = val;
        this.setupFromControl();
    }

    get formGroup() {
        return this._formGroup;
    }

    _component: T;
    @Input()
    set component(val: T) {
        this._component = val;
        this.processExtraData();
    }

    get component() {
        return this._component;
    }

    @Input()
    pageName: string;
    @Input()
    private _productCode: string;
    public get productCode(): string {
        return this._productCode;
    }
    public set productCode(value: string) {
        this._productCode = value;
    }
    @Input()
    groupId: string;
    
    @Input()
    private _spMode: string;
    public get spMode(): string {
        return this._spMode;
    }
    public set spMode(value: string) {
        this._spMode = value;
    }

    @Output()
    change: EventEmitter<number> = new EventEmitter();
    @Output()
    loaded: EventEmitter<string> = new EventEmitter();
    @Output()
    validated: EventEmitter<ValidationResult> = new EventEmitter();
    @Output()
    resume: EventEmitter<boolean> = new EventEmitter();
    @Output()
    altContinue: EventEmitter<string> = new EventEmitter();

    setupFromControl() {
        if (this.component.name === 'qq-mlf') {
            
        } else if (!isNullOrUndefined(this.formGroup) && !isNullOrUndefined(this.getFormControl())) {
            if (!isNullOrUndefined(this.formGroup.get(this.component.id))) {
                this.formGroup.get(this.component.id).reset();
            } else {
                this.formGroup.registerControl(this.component.id, this.getFormControl());
            }
            this.getFormControl().valueChanges.subscribe(
                () => this.onChange()
            );
            this.component.loaded = true;
            // tel my parent I'm loaded.
            this.loaded.emit(this.component.id);
        } else {
            throw new Error('Component setup failed');
        }
    }

    setInitialValue() {
        if (!isNullOrUndefined(this.getFormControl()) && !isNullOrUndefined(this.component.initValue) && this.component.initValue !== '') {
            this.getFormControl().setValue(this.component.initValue);
        }
    }

    /**
     * This is called from the loader to build up the form control
     * The function must take PageComponentVO as input.
     */
    setComponentData(data: PageComponentVO): void {
        (this.component as PageComponentVO) = data;
        this.formGroup = data.pageForm;
    }

    abstract getFormControl(): FormControl;

    onChange(): void {
    }

    processExtraData(): void {
    }

    validate(): boolean {
        return false;
    }
}

export interface DynamicControl {

    /**
     * Return true if the calling class needs to wait on the validation result
     * false means normal form validation will be enough
     * true the calling class will be notified via the validated event.
     * @returns {boolean}
     */
    validate(): boolean;

    setInitialValue(): void;
}

@Injectable({
    providedIn: 'root'
})
export class ParentEventService {

    private pageValidated = new Subject<boolean>();
    private pageBusy = new Subject<boolean>();

    getPageValidateObservable(): Observable<boolean> {
        return this.pageValidated.asObservable();
    }

    publishPageValidatedStatus(status: boolean): void {
        this.pageValidated.next(status);
    }

    getPageBusyObservable(): Observable<boolean> {
        return this.pageBusy.asObservable();
    }

    publishPageBusyStatus(status: boolean): void {
        this.pageBusy.next(status);
    }

}
