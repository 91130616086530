/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../base/date.component.ngfactory";
import * as i2 from "../../base/date.component";
import * as i3 from "@angular/common";
import * as i4 from "./date.component";
var styles_DateComponent = [];
var RenderType_DateComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DateComponent, data: {} });
export { RenderType_DateComponent as RenderType_DateComponent };
export function View_DateComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "dl-date-input", [], null, [[null, "dateChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dateChange" === en)) {
        var pd_0 = (_co.dobChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_DateComponent_0, i1.RenderType_DateComponent)), i0.ɵdid(1, 49152, null, 0, i2.DateComponent, [i3.DatePipe], { formControlRef: [0, "formControlRef"], name: [1, "name"], placeholder: [2, "placeholder"], dateFormat: [3, "dateFormat"], required: [4, "required"], minAge: [5, "minAge"], maxAge: [6, "maxAge"], tabIndex: [7, "tabIndex"] }, { dateChange: "dateChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.textControl; var currVal_1 = _co.component.id; var currVal_2 = _co.component.name; var currVal_3 = _co.extraInput.format; var currVal_4 = true; var currVal_5 = _co.extraInput.minAge; var currVal_6 = _co.extraInput.maxAge; var currVal_7 = "1"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_DateComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "date-input", [], null, null, null, View_DateComponent_0, RenderType_DateComponent)), i0.ɵdid(1, 49152, null, 0, i4.DateComponent, [], null, null)], null, null); }
var DateComponentNgFactory = i0.ɵccf("date-input", i4.DateComponent, View_DateComponent_Host_0, { flowId: "flowId", formGroup: "formGroup", component: "component", pageName: "pageName", _productCode: "_productCode", groupId: "groupId", _spMode: "_spMode" }, { change: "change", loaded: "loaded", validated: "validated", resume: "resume", altContinue: "altContinue" }, []);
export { DateComponentNgFactory as DateComponentNgFactory };
