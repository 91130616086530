var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Dynamic } from './component';
import { isNullOrUndefined } from 'util';
var CheckboxComponent = /** @class */ (function (_super) {
    __extends(CheckboxComponent, _super);
    function CheckboxComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CheckboxComponent.prototype.getFormControl = function () {
        return undefined;
    };
    CheckboxComponent.prototype.setupFromControl = function () {
        this.component.loaded = true;
        // tel my parent I'm loaded.
        this.loaded.emit(this.component.id);
    };
    CheckboxComponent.prototype.processExtraData = function () {
        // if extra input is set to true then the check box must be checked for this control to be valid
        if (isNullOrUndefined(this.component.extraInput)) {
            this.mustBeChecked = false;
        }
        else {
            this.mustBeChecked = this.component.extraInput;
        }
        this.component.isValid = !this.mustBeChecked;
        this.change.emit(this.component.counter);
    };
    CheckboxComponent.prototype.valueChange = function (change) {
        this.component.value = change.checked;
        if (this.mustBeChecked) {
            this.component.isValid = change.checked;
        }
        else {
            this.component.isValid = true;
        }
        this.change.emit(this.component.counter);
    };
    return CheckboxComponent;
}(Dynamic));
export { CheckboxComponent };
