import {SelectItem} from '../select.vo';
import {SelectBankingItem} from '../select.vo';
import {Payer} from '../payer/dl.payer.vo';

export class BankDetail {
    id: string;
    validationRef: string;
    bankName = '';
    branchCode = '';
    accountNumber = '';
    accountType = '';
    accountHolder = '';
    valid = false;
    extraInfo: BankingMetaData = new BankingMetaData();
    debitDay = '';
    coverStart = '';
    debitOrderDate = '';
    transUnionStrikeDay = '';
    paymentProvider = '';
    requestMandate = '';
    premiumPayerMode = '';
    payer: Payer;
    incomeSource = '';
    opsOverrideStatus?: string;
    opsOverrideId?: string;

    static copyMe(me: BankDetail): BankDetail {
        const copy: BankDetail = new BankDetail();
        copy.id = me.id;
        copy.validationRef = me.validationRef;
        copy.bankName = me.bankName;
        copy.branchCode = me.branchCode;
        copy.accountNumber = me.accountNumber;
        copy.accountType = me.accountType;
        copy.accountHolder = me.accountHolder;
        copy.valid = me.valid;
        copy.debitDay = me.debitDay;
        copy.coverStart = me.coverStart;
        copy.debitOrderDate = me.debitOrderDate;
        copy.transUnionStrikeDay = me.transUnionStrikeDay;
        copy.paymentProvider = me.paymentProvider;
        copy.requestMandate = me.requestMandate;
        copy.premiumPayerMode = me.premiumPayerMode;
        copy.payer = me.payer;
        return copy;
    }

    static updateMe(currentMe: BankDetail, newMe: BankDetail): void {
        currentMe.bankName = newMe.bankName;
        currentMe.branchCode = newMe.branchCode;
        currentMe.accountNumber = newMe.accountNumber;
        currentMe.accountType = newMe.accountType;
        currentMe.accountHolder = newMe.accountHolder;
        currentMe.valid = newMe.valid;
        currentMe.debitDay = newMe.debitDay;
        currentMe.coverStart = newMe.coverStart;
        currentMe.debitOrderDate = newMe.debitOrderDate;
        currentMe.transUnionStrikeDay = newMe.transUnionStrikeDay;
        currentMe.paymentProvider = newMe.paymentProvider;
        currentMe.requestMandate = newMe.requestMandate;
        currentMe.premiumPayerMode = newMe.premiumPayerMode;
        currentMe.payer = newMe.payer;
    }
}

export class BankingMetaData {
    bankNames: SelectBankingItem[] = [];
    accountTypes: SelectItem[] = [];
    incomeSources: SelectItem[] = [];
    debitDayList: SelectItem[] = [];
    coverDates: CoverDates[] = [];
    showRequestMandate = false;
}

export class CoverDates {
    coverStartDate = '';
    debitOrderDates: [string, string];
}

export class BankDetailStatus {
    valid = false;
    detail: BankDetail = null;
}

export class AvsValidationResult {
    status: number;
    statusDescription: string;
}
