var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Dynamic, ParentEventService } from './component';
import { OnDestroy } from '@angular/core';
import { AjaxService } from './ajax.service';
import { isNullOrUndefined } from 'util';
var AjaxButtonComponent = /** @class */ (function (_super) {
    __extends(AjaxButtonComponent, _super);
    function AjaxButtonComponent(parentEventService, ajaxService) {
        var _this = _super.call(this) || this;
        _this.parentEventService = parentEventService;
        _this.ajaxService = ajaxService;
        _this.isDisabled = false;
        _this.isBusy = false;
        _this.isLink = false;
        _this.parentBusySubscription = parentEventService.getPageBusyObservable().subscribe(function (event) {
            _this.isDisabled = event;
            if (_this.isBusy && !event) {
                _this.isBusy = false;
            }
        });
        return _this;
    }
    AjaxButtonComponent.prototype.setupFromControl = function () {
        this.component.loaded = true;
        if (this.component.valueExtra !== undefined && this.component.valueExtra === 'link') {
            this.isLink = true;
        }
        // tel my parent I'm loaded.
        this.loaded.emit(this.component.id);
    };
    AjaxButtonComponent.prototype.getFormControl = function () {
        return null;
    };
    AjaxButtonComponent.prototype.ngOnDestroy = function () {
        this.parentBusySubscription.unsubscribe();
    };
    AjaxButtonComponent.prototype.click = function () {
        var _this = this;
        if (!this.isBusy) {
            this.isBusy = true;
            this.parentEventService.publishPageBusyStatus(true);
            this.ajaxService.postRequest(this.component.value).subscribe(function (res) { return _this.handleResponse(res); }, function () { return _this.parentEventService.publishPageBusyStatus(false); });
        }
        return false;
    };
    AjaxButtonComponent.prototype.handleResponse = function (res) {
        this.parentEventService.publishPageBusyStatus(false);
        if (!isNullOrUndefined(this.component.extraInput) && this.component.extraInput !== '') {
            this.component.name = this.component.extraInput;
        }
        else {
            this.component.name = 'Done';
        }
    };
    return AjaxButtonComponent;
}(Dynamic));
export { AjaxButtonComponent };
