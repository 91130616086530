<div class="qq-component">
	<div class="dl-well" [style.background-color]="rgbaColor">
		<h2 class="qq-heading">{{inputData.title}}</h2>
		<div *ngIf="qqLoading" class="dl-loading">
			<p>
				Loading<br>
				<span class="fa fa-circle-o-notch fa-spin"></span>
			</p>
		</div>
		<div *ngIf="message" class="row">
			<div class="col s12">
				<p class="text-center">{{message}}</p>
			</div>
		</div>
		<ng-container *ngIf="!qqLoading && formFields && inputData.type === '7step'">
			<form [formGroup]="qqForm" class="qq-form" id="LC-QUICK-QUOTE">
				<div class="dl-row row" *ngIf="showProduct">
					<div class="dl-col-md-12 col s12">
						<mat-form-field class="dl-full-width">
							<mat-select [attr.name]="'product'" [attr.autocomplete]="autocomplete" [tabIndex]="1"
								[formControl]="qqForm.controls['product']" [panelClass]="inputData.theme"
								[attr.data-name]="'product'" id="LC-QQ-product"
								(selectionChange)="selectionChange('LC-QQ-product')" placeholder="Product">
								<mat-option *ngFor="let item of products" [attr.data-val]="item.value"
									[value]="item.value">{{item.label}}</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</div>
				<div *ngIf="inputData.embedded && formFields.id_number" class="dl-row row">
					<div class="dl-col-md-12 col s12">
						<mat-form-field class="dl-full-width">
							<input name="id_number" autocomplete="{{autocomplete}}" matInput
								placeholder="RSA Id / DOB (yymmdd)" [formControl]="qqForm.controls['rsaId']"
								[attr.data-name]="'idDOB'" [tabIndex]="1">
							<span matSuffix *ngIf="!qqForm.controls['rsaId'].pristine && qqForm.controls['rsaId'].valid"
								class="fa fa-check feedback"></span>
							<span matSuffix
								*ngIf="!qqForm.controls['rsaId'].pristine && !qqForm.controls['rsaId'].valid"
								class="fa fa-times feedback"></span>
							<mat-checkbox matSuffix (click)="sendNoId()" style="padding-left: 10px"
								[labelPosition]="'after'">
								<b>No Id</b>
							</mat-checkbox>
						</mat-form-field>
					</div>
				</div>
				<div *ngIf="formFields.age" class="dl-row row">
					<div class="dl-col-md-12 col s12" data-name="ageInput">
						<mat-form-field class="dl-full-width">
							<input matInput name="age" autocomplete="{{autocomplete}}" type="text" inputmode="numeric"
								placeholder="Age" [attr.data-name]="'age'" [attr.kings-watch]="'7STEP-QQ-age'"
								[formControl]="qqForm.controls['age']" [textMask]="numMask" [tabIndex]="1">
							<span matSuffix>years</span>
							<mat-checkbox matSuffix *ngIf="inputData.embedded" [labelPosition]="'after'"
								style="padding-left: 10px" (click)="sendTooOld()">
								<b>&gt;= 65</b>
							</mat-checkbox>
							<mat-error *ngIf="qqForm.controls['age'].invalid">{{getAgeErrorMessage()}}</mat-error>
						</mat-form-field>
					</div>
				</div>
				<div *ngIf="formFields.birth_date" class="dl-row row date">
					<div class="dl-col-md-12 col s12" data-name="birthDateInput">
						<dl-date-picker-input [name]="'birth_date'" [placeHolder]="'Birth date'"
							[formControlRef]="qqForm.controls['birth_date']" [isDob]="true" [minAge]="18" [maxAge]="80"
							[startView]="'multi-year'" [required]="true" [format]="'DD/MM/YYYY'"></dl-date-picker-input>
						<mat-error *ngIf="qqForm.controls['birth_date'].invalid">{{getAgeErrorMessage()}}</mat-error>
					</div>
				</div>
				<div *ngIf="formFields.income" class="dl-row row">
					<div class="dl-col-md-12 col s12" data-name="income">
						<mat-form-field class="dl-full-width">
							<input matInput name="income" autocomplete="{{autocomplete}}" type="text"
								inputmode="numeric" placeholder="Income" [formControl]="qqForm.controls['income']"
								[textMask]="numMask" [attr.data-name]="'income'" [attr.kings-watch]="'7STEP-QQ-income'"
								[tabIndex]="1">
							<span matSuffix>p.m.</span>
							<mat-checkbox *ngIf="inputData.embedded" matSuffix [labelPosition]="'after'"
								style="padding-left: 10px" (click)="sendNoIncome()">
								<b>No Income</b>
							</mat-checkbox>
						</mat-form-field>
					</div>
				</div>
				<div *ngIf="formFields.education" class="dl-row row">
					<div class="dl-col-md-12 col s12" data-name="education">
						<mat-form-field class="dl-full-width">
							<mat-select placeholder="Education" [tabIndex]="1"
								[formControl]="qqForm.controls['education']" [attr.data-name]="'education'"
								[attr.kings-watch]="'7STEP-QQ-education'" id="LC-QQ-education"
								(selectionChange)="selectionChange('LC-QQ-education')" [panelClass]="inputData.theme">
								<mat-option *ngFor="let item of education" [attr.data-val]="item.value"
									[value]="item.value">{{item.label}}</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</div>
				<div *ngIf="formFields.gender" class="dl-row row">
					<div class="dl-col-md-12 col s12" data-name="gender">
						<mat-radio-group align="start" [attr.kings-watch]="'7STEP-QQ-gender'"
							[formControl]="qqForm.controls['gender']" class="dl-full-width" name="LC-QQ-gender">
							<mat-radio-button *ngFor="let item of genderItems" color="primary"
								class="mat-button-toggle-2" [attr.data-val]="item.value" [attr.data-name]="'gender'"
								[value]="item.value" [tabIndex]="1">{{item.label}}</mat-radio-button>
						</mat-radio-group>
					</div>
				</div>
				<div *ngIf="formFields.smoker" class="dl-row row">
					<div class="dl-col-md-12 col s12" style="padding-top: 10px" data-name="smoker_status">
						<mat-radio-group align="start" [attr.kings-watch]="'7STEP-QQ-smoker'"
							[formControl]="qqForm.controls['smoker']" class="dl-full-width" name="LC-QQ-smoker_status">
							<mat-radio-button *ngFor="let item of smokerItems" color="primary"
								class="mat-button-toggle-2" [attr.data-val]="item.value" [attr.data-name]="'smoker'"
								[value]="item.value" [tabIndex]="1">{{item.label}}</mat-radio-button>
						</mat-radio-group>
					</div>
				</div>
				<div class="dl-row row dl-button-row">
					<div class="dl-col-md-12 col s12">
						<button mat-raised-button color="primary" class="dl-full-width dl-action-btn"
							style="margin-top: 15px; margin-bottom: 15px" tabindex="1" type="button"
							id="calculate_my_quote" (click)="getPricing()" [attr.data-event]="'true'"
							[attr.kings-watch]="'submit'" [attr.data-event-category]="'Quick Quote'"
							[attr.data-event-name]="'Calculate'" [attr.data-event-label]="'Quick Quote Calculate'"
							[disabled]=" qqForm.pristine || qqForm.invalid || loading || invalidLead">
							{{inputData.buttonText}}
							<i *ngIf="loading" class="fa fa-circle-o-notch fa-spin"></i>
						</button>
						<p *ngIf="leadInvalidated" class="dl-subscript">Marked Lead for invalidation</p>
					</div>
				</div>
			</form>
		</ng-container>
		<ng-container *ngIf="!qqLoading && qqMlfComponentFlag">
			<form [formGroup]="qqForm" class="qq-form" id="QUICK-QUOTE-MLF" (ngSubmit)="qqMlfSubmit($event)">
				<div class="dl-row row">
					<div class="dl-col-md-12 col s12">
						<mat-form-field class="dl-full-width">
							<input name="id_number" autocomplete="{{autocomplete}}" matInput placeholder="RSA Id"
								[formControl]="qqMlfForm.controls['rsaId']" [attr.data-name]="'rsaid'" [tabIndex]="1">
							<span matSuffix
								*ngIf="!qqMlfForm.controls['rsaId'].pristine && qqMlfForm.controls['rsaId'].valid"
								class="fa fa-check feedback"></span>
							<span matSuffix
								*ngIf="!qqMlfForm.controls['rsaId'].pristine && !qqMlfForm.controls['rsaId'].valid"
								class="fa fa-times feedback"></span>
						</mat-form-field>
					</div>
				</div>
				<div class="dl-row row">
					<div class="dl-col-md-12 col s12" data-name="income">
						<mat-form-field class="dl-full-width">
							<input matInput name="income" autocomplete="{{autocomplete}}" type="text"
								inputmode="numeric" placeholder="Income" [formControl]="qqMlfForm.controls['income']"
								[textMask]="numMask" [attr.data-name]="'income'" [tabIndex]="1">
							<span matSuffix>p.m.</span>
						</mat-form-field>
					</div>
				</div>
				<div class="dl-row row dl-button-row">
					<div class="dl-col-md-12 col s12">
						<button mat-raised-button color="primary" class="dl-full-width dl-action-btn"
							style="margin-top: 15px; margin-bottom: 15px" tabindex="1" type="submit"
							id="calculate_my_mlf_quote" [attr.data-event]="'true'"
							[attr.data-event-category]="'Quick Quote MLF'" [attr.data-event-name]="'Calculate QQ MLF'"
							[attr.data-event-label]="'Quick Quote MLF Calculate'"
							[disabled]=" qqMlfForm.pristine || qqMlfForm.invalid || loading">
							Calculate
							<i *ngIf="loading" class="fa fa-circle-o-notch fa-spin"></i>
						</button>
					</div>
				</div>
			</form>
		</ng-container>
	</div>
</div>