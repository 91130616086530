import {Component} from '@angular/core';
import {Dynamic} from './component';
import {FormControl} from '@angular/forms';
import { PageComponentVO } from '../page.data.vo';

@Component({
    selector: 'hidden-input',
    template: '<div style="display: none" [attr.data-val]="component.value"></div>'
})
export class HiddenComponent extends Dynamic<PageComponentVO> {

    getFormControl(): FormControl {
        return undefined;
    }

    setupFromControl(): void {
        this.component.loaded = true;
        this.loaded.emit(this.component.id);
    }

}

