import {Component, Input} from '@angular/core';
import {DisclosureVO} from './disclosures.vos';
import {isNullOrUndefined} from 'util';

@Component({
    selector: 'dl-disclosures',
    templateUrl: './disclosures.component.html'
})
export class DisclosuresComponent {

    @Input()
    disclosures: DisclosureVO[];

    constructor(
    ) {}

    public isValid(): boolean {
        if (isNullOrUndefined(this.disclosures) || this.disclosures.length === 0) {
            return false;
        }
        for (const disclosure of this.disclosures) {
            if (isNullOrUndefined(disclosure.answer) || disclosure.answer.length === 0) {
                return false;
            }
            if (!isNullOrUndefined(disclosure.stop_message) &&
                disclosure.stop_message.length > 0 && 
                disclosure.answer === 'no'
            ) {
                return false;
            }
        }
        return true;
    }

}
