import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {DonutService, GraphData, GraphItem} from './donut.service';
import {Log} from 'ng2-logger/browser';
import * as d3 from 'd3';
import {ScaleOrdinal} from 'd3-scale';
import {Pie, Arc, DefaultArcObject} from 'd3-shape';
import {BaseType, Selection} from 'd3-selection';

const log = Log.create('DonutComponent');

@Component({
  selector: 'donut-graph',
  template: `<div #chart id="chart_{{_id}}"></div>`
})
export class DonutComponent implements OnInit {

  @ViewChild('chart') chart: ElementRef;

  constructor(private donutService: DonutService) {
  }

  _id = 'chart';
  @Input()
  set id(val: string) {
    this._id = val;
  }

  _dataUrl: string;
  @Input('data-url')
  set dataUrl(val: string) {
    this._dataUrl = val;
  }

  _height = 400;
  @Input()
  set height(val: string) {
    this._height = parseInt(val, 10);
  }
  get heightNum(): number {
    return this._height;
  }
  get displayHeight(): number {
    return this.heightNum + 20;
  }

  _width = 400;
  @Input()
  set width(val: string) {
    this._width = parseInt(val, 10);
  }
  get widthNum(): number {
    return this._width;
  }
  get displayWidth(): number {
    return this.widthNum + 10;
  }

  _ringWidth = 20;
  @Input()
  set ringWidth(val: string) {
    this._ringWidth = parseInt(val, 10);
  }

  _showLabels = false;
  @Input()
  set showLabels(val: string) {
    if (val === 'true') {
      this._showLabels = true;
    }
  }

  _showTooltip = false;
  @Input()
  set showTooltip(val: string) {
    if (val === 'true') {
      this._showTooltip = true;
    }
  }

  _showCenterLabel = false;
  @Input()
  set showCenterLabel(val: string) {
    if (val === 'true') {
      this._showCenterLabel = true;
    }
  }

  colourList: string[] = [];
  colour: ScaleOrdinal<string, any>;
  pie: Pie<any, { valueOf(): number }>;
  svg: Selection<BaseType, any, BaseType, any>;
  path: Arc<any, DefaultArcObject>;
  label: Arc<any, DefaultArcObject>;
  radius: number;
  pathArea: any;

  static getDimensions(id): { w: number, h: number } {
    const el: any = document.getElementById(id);
    let w = 0, h = 0;
    if (el) {
      const dimensions = el.getBBox();
      w = dimensions.width;
      h = dimensions.height;
    } else {
      log.error('error: getDimensions() ' + id + ' not found.');
    }
    return {
      w: w,
      h: h
    };
  }

  private drawChart(data: GraphData) {
    log.data(JSON.stringify(data));
    // build up the colour list
    data.data.forEach(i => {
      this.colourList.push(i.colour);
    });

    this.colour = d3.scaleOrdinal().range(this.colourList);

    this.radius = Math.min(this.widthNum, this.heightNum) / 2;
    if (this.radius < 50) {
      this.radius = 50;
    }
    const element = this.chart.nativeElement;
    const translateStr = 'translate(' + (this.widthNum / 2) + ',' + (this.heightNum / 2) + ')';
    this.svg = d3.select(element).append('svg')
      .attr('width', this.displayWidth)
      .attr('height', this.displayHeight)
      .attr('style', 'display:block; margin:auto')
      .append('g').attr('transform', translateStr);

    this.pie = d3.pie().sort(null).value((input: any) => {
      const d = input as GraphItem;
      return d.value;
    });
    let innerRadius = this.radius - 20 - this._ringWidth;
    if (innerRadius < 15) {
      innerRadius = 15;
    }
    this.path = d3.arc().outerRadius(this.radius - 20).innerRadius(innerRadius).cornerRadius(2);
    this.label = d3.arc().outerRadius(this.radius - 40).innerRadius( this.radius - 40);
    const arc = this.svg.selectAll('.arc').data(this.pie(data.data as any)).enter().append('g').classed('arc', true);
    this.pathArea = arc.append('path')
      .attr('d', this.path as any)
      .attr('id', (d: any, i) => {
        const item = d.data as GraphItem;
        return 'arc-' + this._id + '_' + item.id;
      })
      .attr('style', 'fill-opacity: 0.85;')
      .attr('fill', (d: any) => {
        const item = d.data as GraphItem;
        return this.colour(item.id);
      });

    this.addLabels(arc, data.centerText);
    if (this._showTooltip) {
      this.addTooltipConfig(this.svg);
    }
    this.addMouseEvents();
  }

  private addTooltipConfig(el: any) {
    const tooltipg = el.append('g')
      .attr('class', 'tooltip')
      .attr('text-anchor', 'end')
      .attr('id', 'tooltip_' + this._id)
      .attr('style', 'opacity:0')
      .attr('transform', 'translate(-500,-500)');

    tooltipg.append('rect')
      .attr('class', 'tooltip-bg')
      .attr('id', 'tooltipRect_' + this._id)
      .attr('x', 0)
      .attr('width', 120)
      .attr('height', 40)
      .attr('opacity', 0.8)
      .attr('fill', '#000');

    tooltipg
      .append('text')
      .attr('class', 'tooltip-txt')
      .attr('id', 'tooltipText_' + this._id)
      .attr('x', 30)
      .attr('y', 15)
      .attr('fill', '#fff')
      .attr('font-size', 10)
      .attr('font-family', 'arial')
      .text(function (d, i) {
        return '';
      });
  }

  private addMouseEvents(): void {

    this.pathArea.on('mouseover', (data: any) => {
      const inputData = data.data as GraphItem;
      const currentEl: any = d3.select('#arc-' + this._id + '_' + inputData.id);
      currentEl.attr('style', 'fill-opacity:1;');
      if (this._showTooltip) {

        d3.select('#tooltip_' + this._id)
          .transition()
          .duration(120)
          .style('opacity', () => {
            return 1;
          });

        d3.selectAll('#tooltipText_' + this._id).text('');
        d3.selectAll('#tooltipText_' + this._id)
          .append('tspan')
          .attr('x', 0)
          .attr('y', 0)
          .attr('dy', '1.9em')
          .text(' ' + inputData.description);
        const dims = DonutComponent.getDimensions('tooltipText_' + this._id);

        d3.select('#tooltip_' + this._id)
          .attr('transform', function (d) {
            const mouseCoords = d3.mouse(d3.event.currentTarget);
            const xCo = mouseCoords[0] - (dims.w / 2);
            const yCo = mouseCoords[1] + 10;
            return 'translate(' + xCo + ',' + yCo + ')';
          });

        d3.selectAll('#tooltipText_' + this._id + ' tspan').attr('x', dims.w + 4);
        d3.selectAll('#tooltipRect_' + this._id)
          .attr('width', dims.w + 10)
          .attr('height', dims.h + 20);
      }
    });

    if (this._showTooltip) {
      this.pathArea.on('mousemove', (data: any) => {
        const dims2 = DonutComponent.getDimensions('tooltipText_' + this._id);
        d3.selectAll('#tooltip_' + this._id)
          .attr('transform', function (d) {
            const mouseCoords = d3.mouse(d3.event.currentTarget);
            const xCo = mouseCoords[0] - (dims2.w / 2);
            const yCo = mouseCoords[1] + 15;
            return 'translate(' + xCo + ',' + yCo + ')';
          });
      });
    }

    this.pathArea.on('mouseout', (data: any) => {
      const inputData = data.data as GraphItem;
      const currentEl = d3.select('#arc-' + this._id + '_' + inputData.id);
      currentEl.attr('style', 'fill-opacity:0.85;');
      if (this._showTooltip) {
        d3.select('#tooltip_' + this._id)
          .style('opacity', function () {
            return 0;
          });
        d3.select('#tooltip_' + this._id).attr('transform', function (d, i) {
          return 'translate(' + -500 + ',' + -500 + ')';
        });
      }
    });
  }

  private addLabels(arc: any, centerText: string): void {
    if (this._showLabels) {
      arc.append('text')
        .attr('class', 'label-txt')
        .attr('z-index', '1')
        .attr('dx', 30)
        .attr('dy', -5)
        .append('textPath')
        .attr('xlink:href', (d, i) => {
          const item = d.data as GraphItem;
          return '#arc-' + this._id + '_' + item.id;
        })
        .text((d) => {
          const item = d.data as GraphItem;
          return item.label;
        });
    }
    if (this._showCenterLabel && centerText != null && centerText.length !== 0) {
      this.svg.append('text')
        .attr('id', 'centerLabel_' + this._id)
        .attr('class', 'center-txt')
        .attr('transform', 'translate(' + 0 + ',' + 0 + ')')
        .text(centerText);
      const labelDim = DonutComponent.getDimensions('centerLabel_' + this._id);
      d3.selectAll('#centerLabel_' + this._id)
        .attr('transform', 'translate(' + (((labelDim.w / 2) * -1) - 5) + ',' + 10 + ')');
    }
  }

  ngOnInit(): void {
    log.info('init ' + this._height + ' :: ' + this._width);
    this.donutService.getData(this._dataUrl).subscribe((d) => this.drawChart(d));
  }
}
