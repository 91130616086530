import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {Dynamic} from './component';
import {isNullOrUndefined} from 'util';
import {FormControl} from '@angular/forms';
import {MatSlider} from '@angular/material';
import {DLUtil} from '../../base/dl.util';
import {BehaviorSubject, of} from 'rxjs';
import {CustomiseInputVO, CustomiseValueVO, PageComponentVO} from '../page.data.vo';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {PricingService, SimplePremiumRequest, SimplePremiumResponse} from '../../base/pricing/pricing.service';
import {OfferVO} from '../../base/dl.offer-text.pipe';

@Component({
    selector: 'customise-cover-input',
    templateUrl: 'customise-cover.component.html'
})
export class CustomiseCoverComponent extends Dynamic<PageComponentVO> implements OnInit {

    @ViewChild('termSlider') termSlider: MatSlider;
    @ViewChild('coverSlider') coverSlider: MatSlider;

    pricingService: PricingService;
    premiumsCache: Map<String, SimplePremiumResponse>;

    offer$: BehaviorSubject<OfferVO>;

    productGroup: string;
    in: CustomiseInputVO;
    initialValue: CustomiseValueVO;

    changeEvents$: EventEmitter<ChangeEvent>;

    constructor(pricingService: PricingService) {
        super();
        this.pricingService = pricingService;
        this.premiumsCache = new Map();
    }

    ngOnInit(): void {
        // We start out with valid values
        this.component.isValid = true;
        if (!isNullOrUndefined(this.change)) {
            this.change.emit(this.component.counter);
        }
        // SS-406 - fix slider bug by forcing an event on init of slider.
        this.changeEvents$.emit(new ChangeEvent(this.termSlider.value, this.coverSlider.value));
        this.component.isValid = false;
        if (!isNullOrUndefined(this.change)) {
            this.change.emit(this.component.counter);
        }
    }

    onChange(): void {
        this.changeEvents$.emit(new ChangeEvent(this.termSlider.value, this.coverSlider.value));
        this.component.isValid = false;
        if (!isNullOrUndefined(this.change)) {
            this.change.emit(this.component.counter);
        }
    }

    processExtraData(): void {
        this.in = this.component.extraInput as CustomiseInputVO;
    }

    setupFromControl() {
        this.productGroup = this.groupId + '_' + this.productCode;
        this.initialValue = this.component.value as CustomiseValueVO;
        // Cache initial values as pricing response
        const response = new SimplePremiumResponse();
        response.version = 'NA';
        response.premium = this.initialValue.premium;
        this.premiumsCache.set(this.initialValue.cover + '_' + this.initialValue.term, response);
        // Publish initial values to view
        const offer = this.newOfferVO();
        offer.term = this.initialValue.term;
        offer.cover = this.initialValue.cover;
        offer.premium = this.initialValue.premium;
        this.offer$ = new BehaviorSubject(offer);
        // Subscribe to slider change events
        this.termSlider.valueChange.subscribe(
            () => this.onChange()
        );
        this.coverSlider.valueChange.subscribe(
            () => this.onChange()
        );
        // Initialize an Observable for consolidated, debounced change events
        this.changeEvents$ = new EventEmitter();
        this.changeEvents$.pipe(
            debounceTime(400),
            distinctUntilChanged((a, b) => {
                return a.term === b.term && a.cover === b.cover;
            })
        ).subscribe(
            // Update premiums for change events
            ($event) => this.fetchPremium($event)
        );
        this.component.loaded = true;
        // tel my parent I'm loaded.
        this.loaded.emit(this.component.id);
    }

    getFormControl(): FormControl {
        return null;
    }

    formatTermForDisplay(value: number | null): string {
        return DLUtil.compactFormat(value, true);
    }

    formatCoverForDisplay(value: number | null): string {
        return DLUtil.compactFormat(value, false);
    }

    termUp() {
        this.incrementSlider(this.termSlider);
    }

    termDown() {
        this.decrementSlider(this.termSlider);
    }

    coverUp() {
        this.incrementSlider(this.coverSlider);
    }

    coverDown() {
        this.decrementSlider(this.coverSlider);
    }

    private fetchPremium($event: ChangeEvent) {
        this.offer$.next(null);
        const request = this.buildPremiumRequest($event.cover, $event.term);
        let observable;
        if (this.premiumsCache.has(request.sumAssured + '_' + request.term)) {
            observable = of(this.premiumsCache.get(request.sumAssured + '_' + request.term));
        } else {
            observable = this.pricingService.requestPremium(request);
        }
        observable.subscribe(
            (response) => this.applyUpdate($event, response, request)
        );
    }

    private applyUpdate($event: ChangeEvent, response: SimplePremiumResponse, request: SimplePremiumRequest) {
        const newValue = new CustomiseValueVO();
        newValue.term = $event.term;
        newValue.cover = $event.cover;
        newValue.premium = response.premium;
        this.component.value = newValue;

        this.premiumsCache.set(request.sumAssured + '_' + request.term, response);

        const offer = this.newOfferVO();
        offer.term = newValue.term;
        offer.cover = newValue.cover;
        offer.premium = newValue.premium;
        this.offer$.next(offer);

        this.component.isValid = true;
        if (!isNullOrUndefined(this.change)) {
            this.change.emit(this.component.counter);
        }
    }

    private incrementSlider(slider) {
        const newValue = Math.min(slider.value + slider.step, slider.max);
        slider.writeValue(newValue);
        this.onChange();
    }

    private decrementSlider(slider) {
        const newValue = Math.max(slider.value - slider.step, slider.min);
        slider.writeValue(newValue);
        this.onChange();
    }

    private buildPremiumRequest(sumAssured: number, term: number): SimplePremiumRequest {
        const request = new SimplePremiumRequest();
        request.productGroup = this.productGroup;
        request.productCode = this.in.product;
        request.age = this.in.age;
        request.gender = this.in.gender;
        request.smoker = this.in.smoker;
        request.bmiLoading = this.in.bmiLoading;
        if (term) {
            request.term = term;
        }
        request.sumAssured = sumAssured;
        return request;
    }

    private newOfferVO(): OfferVO {
        const offer = new OfferVO();
        offer.personName = this.in.personName;
        offer.product = this.in.product;
        offer.productName = this.in.productName;
        offer.isWol = this.in.isWol;
        return offer;
    }

}

class ChangeEvent {
    term: number;
    cover: number;

    constructor(term: number, cover: number) {
        this.term = term;
        this.cover = cover;
    }
}
