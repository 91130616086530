import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DLUtil} from '../base/dl.util';
import {RsaId, rsaIdValidation, RsaIdValidationCallback} from '../base/rsa.id.validator';
import {ResetPasswordInfo, ResetPasswordService} from './reset.password.service';
import {isNullOrUndefined} from 'util';

@Component({
    selector: 'dl-request-password',
    templateUrl: './request.password.component.html'
})
export class RequestPasswordComponent implements RsaIdValidationCallback {
    requestForm: FormGroup;

    showIdField = false;
    idErrorMessage = '';
    busy = false;
    showMailSent = false;
    errorMsg = '';

    constructor(private fb: FormBuilder,
                private resetPasswordService: ResetPasswordService) {
        this.setUpForm();
    }

    private setUpForm() {
        this.requestForm = this.fb.group({
            email: ['', [Validators.required, Validators.email, Validators.pattern(DLUtil.emailPattern)]],
            idNum: ['', [Validators.required, Validators.pattern(DLUtil.numberPattern), rsaIdValidation(false, this)]]
        });
        if (!this.showIdField) {
            this.requestForm.get('idNum').disable();
        }
    }

    onIdValidationChange(rsaId: RsaId): void {
        this.idErrorMessage = '';
        if (!rsaId.isValid()) {
            this.idErrorMessage = 'Id is not valid';
        }
    }

    sendRequest() {
        this.busy = true;
        const email = this.requestForm.get('email').value;
        const idNum = this.requestForm.get('idNum').value;
        this.resetPasswordService.sendRest(email, this.showIdField ? idNum : undefined)
            .subscribe(res => this.handleRequestResponse(res),
                () => this.busy = false);
    }

    private handleRequestResponse(res: ResetPasswordInfo): void {
        if (!isNullOrUndefined(res.error)) {
            // TODO: need to display the error message
        } else if (!isNullOrUndefined(res.requires_id_number) && (res.requires_id_number || res.requires_id_number === 'true')) {
            this.showIdField = true;
            this.requestForm.get('idNum').enable();
        } else {
            this.showMailSent = true;
        }
        this.busy = false;
    }
}
