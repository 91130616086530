<mat-card>
    <mat-card-title *ngIf="title" class="title">
        {{title}}
    </mat-card-title>
    <mat-card-content>
        <div [innerHTML]="content"></div>
        <div class="row" *ngIf="linkHref">
            <a
                class="right link"
                [href]="linkHref"
                target="_blank"
                [attr.data-event]="'true'"
                [attr.data-event-category]="'DSP-' + productCode"
                [attr.data-event-name]="pageName + ' - Link'"
                [attr.data-event-label]="'link to ' + linkText"
            >{{linkText}}</a>
        </div>
    </mat-card-content>
    <mat-card-actions *ngIf="actionValue">
        <div class="row">
            <div class="col s8 offset-s2 m6 offset-m3 l4 offset-l4">
                <button class="dl-full-width"
                        color="{{isDefaultBtn}}"
                    mat-raised-button
                    tabindex="1"
                    [attr.data-name]="actionValue"
                    [attr.data-event]="'true'"
                    [attr.data-event-category]="'DSP-' + productCode"
                    [attr.data-event-name]="pageName + ' - ' + actionValue"
                    [attr.data-event-label]="'Continue to ' + actionValue"
                    (click)="click()" [disabled]="isDisabled || isBusy">
                    {{actionText}}&nbsp;<i *ngIf="isBusy" class="fa fa-circle-o-notch fa-spin"></i>
                </button>
            </div>
        </div>
    </mat-card-actions>
</mat-card>
