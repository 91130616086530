<div class="needs-component">
    <div class="dl-well">
        <h2 class="needs-heading">{{title}}</h2>
        <div *ngIf="starting" class="dl-loading">
            <p>
                Loading<br>
                <span class="fa fa-circle-o-notch fa-spin"></span>
            </p>
        </div>
        <div *ngIf="message" class="row">
            <div class="col s12">
                <p class="text-center">{{message}}</p>
            </div>
        </div>
        <ng-container *ngIf="!starting && !message">
            <form [formGroup]="needsForm" class="needs-form">
                <div class="dl-row row">
                    <!-- name -->
                    <div class="dl-col-sm-6 col m6 s12 dl-question">
                        <mat-form-field class="dl-full-width">
                            <input
                                matInput
                                name="name"
                                type="text"
                                placeholder="Name"
                                [attr.data-name]="'name'"
                                [formControl]="needsForm.controls['name']"
                                [tabIndex]="1">
                        </mat-form-field>
                    </div>
                    <!-- email -->
                    <div class="dl-col-sm-6 col m6 s12 dl-question">
                        <mat-form-field class="dl-full-width">
                            <input
                                matInput
                                name="email"
                                type="email"
                                placeholder="Email"
                                [attr.data-name]="'email'"
                                [formControl]="needsForm.controls['email']"
                                [tabIndex]="1">
                            <mat-error align="start">Invalid email address</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="dl-row row">
                    <!-- cell -->
                    <div class="dl-col-sm-4 col m4 s12 dl-question">
                        <mat-form-field class="dl-full-width">
                            <input
                                matInput
                                phoneNumber
                                name="phone_mobile"
                                type="tel"
                                placeholder="Cell Number"
                                [tabIndex]="1"
                                [attr.data-name]="'cell'"
                                [formControl]="needsForm.controls['cell']">
                            <mat-error align="start">Format: 000 000 0000</mat-error>
                        </mat-form-field>
                    </div>
                    <!-- Age -->
                    <div class="dl-col-sm-2 col m2 s4 dl-question">
                        <mat-form-field class="dl-full-width">
                            <input
                                matInput
                                name="age"
                                type="text"
                                inputmode="numeric"
                                placeholder="Age"
                                [attr.data-name]="'age'"
                                [formControl]="needsForm.controls['age']"
                                [textMask]="numMask"
                                [tabIndex]="1">
                            <span matSuffix>years</span>
                            <mat-error *ngIf="needsForm.controls['age'].invalid">{{getAgeErrorMessage(needsForm, 'age')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <!-- education -->
                    <div class="dl-col-sm-6 col m6 s8 dl-question">
                        <mat-form-field class="dl-full-width">
                            <mat-select
                                [attr.data-name]="'education'"
                                [tabIndex]="1"
                                [formControl]="needsForm.controls['education']"
                                placeholder="Education">
                                    <mat-option *ngFor="let item of educationValues" [value]="item.value">{{item.label}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="dl-row row">
                    <!-- smoker -->
                    <div class="dl-col-sm-6 col m6 s12 dl-question row-padding">
                        <mat-radio-group [formControl]="needsForm.controls['smoke']" [attr.data-name]="'smoker-status'">
                            <mat-radio-button
                                *ngFor="let item of smokerValues"
                                [value]="item.value"
                                class="mat-button-toggle-2"
                                [tabIndex]="1">{{item.label}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <!-- gender -->
                    <div class="dl-col-sm-6 col m6 s12 dl-question row-padding">
                        <mat-radio-group [formControl]="needsForm.controls['gender']" [attr.data-name]="'gender'">
                            <mat-radio-button
                                *ngFor="let item of genderValues"
                                [value]="item.value"
                                class="mat-button-toggle-2"
                                [tabIndex]="1">{{item.label}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <div class="dl-row row">
                    <!-- loan -->
                    <div class="dl-col-sm-6 col m6 s12 dl-question row-padding">
                        <mat-radio-group [formControl]="needsForm.controls['homeLoan']" [attr.data-name]="'home-loan'">
                            <mat-radio-button
                                *ngFor="let item of homeLoanValues"
                                [value]="item.value"
                                class="mat-button-toggle-2"
                                [tabIndex]="1">{{item.label}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <!-- income -->
                    <div class="dl-col-sm-6 col m6 s12 dl-question">
                        <mat-form-field class="dl-full-width">
                            <input
                                matInput
                                name="income"
                                type="text"
                                inputmode="numeric"
                                placeholder="Income (Net)"
                                [attr.data-name]="'income'"
                                [formControl]="needsForm.controls['income']"
                                [textMask]="numMask"
                                [tabIndex]="1">
                            <span matSuffix>p.m</span>
                        </mat-form-field>
                    </div>
                </div>
                <div class="dl-row row">
                    <!-- Spouse column -->
                    <div class="dl-col-sm-6 col m6 s12">
                        <div class="dl-row row">
                            <div class="dl-col-sm-12 col s12 dl-question row-padding">
                                <mat-radio-group [formControl]="needsForm.controls['spouse']" [attr.data-name]="'spouse'">
                                    <mat-radio-button
                                        *ngFor="let item of spouseValues"
                                        [value]="item.value"
                                        class="mat-button-toggle-2"
                                        [tabIndex]="1">{{item.label}}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div *ngIf="hasSpouse">
                            <div class="dl-row row">
                                <div class="dl-col-sm-12 col s12 dl-question">
                                    <mat-form-field class="dl-full-width">
                                        <input
                                            matInput
                                            type="text"
                                            inputmode="numeric"
                                            placeholder="Partner Age"
                                            [attr.data-name]="'spouse-age'"
                                            [formControl]="needsForm.controls['spouseAge']"
                                            [textMask]="numMask"
                                            [tabIndex]="1">
                                        <span matSuffix>years</span>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="dl-row row">
                                <div class="dl-col-xs-12 col s12 dl-question">
                                    <mat-form-field class="dl-full-width">
                                        <input
                                            matInput
                                            name="partner_income"
                                            type="text"
                                            inputmode="numeric"
                                            placeholder="Partner Income (Net)"
                                            [attr.data-name]="'spouse-income'"
                                            [formControl]="needsForm.controls['spouseIncome']"
                                            [textMask]="numMask"
                                            [tabIndex]="1">
                                        <span matSuffix>p.m</span>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- dependents column -->
                    <div class="dl-col-sm-6 col m6 s12">
                        <div class="dl-row row" style="margin-bottom: 8px">
                            <div class="dl-col-sm-12 col s12 dl-question">
                                <mat-form-field class="dl-full-width">
                                    <mat-select
                                        [tabIndex]="1"
                                        [formControl]="needsForm.controls['dependents']"
                                        [attr.data-name]="'dependents'"
                                        placeholder="Child dependants">
                                            <mat-option *ngFor="let item of dependentsValues" [value]="item.value">{{item.label}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div *ngIf="dependentCount > 0">
                            <div class="dl-row row" *ngFor="let number of dependentsNumber">
                                <div class="dl-col-sm-12 col s12 dl-question">
                                    <mat-form-field class="dl-full-width">
                                        <input
                                            matInput
                                            name="child_{{number}}_age"
                                            type="text"
                                            inputmode="numeric"
                                            placeholder="Child {{number}} Age"
                                            [formControl]="dependentsForm.controls['childAge' + number]"
                                            [textMask]="numMask"
                                            [attr.data-name]="'childAge' + number"
                                            [tabIndex]="1">
                                        <span matSuffix>years</span>
                                        <mat-error *ngIf="dependentsForm.controls['childAge' + number].invalid">
                                            {{getAgeErrorMessage(dependentsForm, 'childAge' + number)}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dl-row row">
                    <div class="dl-col-sm-6 col m6 s12 dl-question">
                        <mat-checkbox
                            [formControl]="needsForm.controls['acceptTerms']"
                            [tabIndex]="1" [attr.data-name]="'accept-terms'">
                                I agree to these <a href="/terms/#insurance_calculator" target="_blank">terms</a>
                        </mat-checkbox>
                    </div>
                    <div class="dl-col-sm-6 col m6 s12">
                        <button
                            mat-raised-button
                            color="primary"
                            class="dl-full-width dl-action-btn"
                            [tabindex]="1"
                            type="button"
                            (click)="calculateNeeds()"
                            [attr.data-name]="'calculate'"
                            [attr.data-event]="true"
                            [attr.data-event-category]="'Calculator'"
                            [attr.data-event-name]="'Calculate'"
                            [attr.data-event-label]="'Calculator Calculate'"
                            [disabled]=" needsForm.pristine || needsForm.invalid">
                                {{buttonText}}
                                <i *ngIf="loading" class="fa fa-circle-o-notch fa-spin"></i>
                                <i *ngIf="!loading" class="fa fa-chevron-right"></i>
                        </button>
                    </div>
                </div>
            </form>
        </ng-container>
    </div>
</div>
