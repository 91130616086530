import { EventEmitter } from '@angular/core';
import { MatchMediaService } from './match.media.service';
import { DateAdapter } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { LocaleHelper } from './locale.helper';
import { DLUtil } from './dl.util';
var DatePickerComponent = /** @class */ (function () {
    function DatePickerComponent(matchMedia, adapter) {
        var _this = this;
        this.matchMedia = matchMedia;
        this.adapter = adapter;
        this._startView = 'month';
        this._dateControl = new FormControl('');
        this._required = false;
        this._format = 'DD/MM/YYYY';
        this._placeHolder = 'Date';
        this.valueChanged = new EventEmitter();
        this.adapter.setLocale(LocaleHelper.getCurrentLocale());
        this.formControlRef.valueChanges.subscribe(function () { return _this.dateInputChanged(); });
        setTimeout(function () { return _this.setupControl(); });
    }
    Object.defineProperty(DatePickerComponent.prototype, "initValue", {
        get: function () {
            return this._initValue;
        },
        set: function (val) {
            this._initValue = val;
            this._initDate = new Date(this._initValue);
            this.formControlRef.setValue(this.initValue);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatePickerComponent.prototype, "startView", {
        get: function () {
            return this._startView;
        },
        set: function (val) {
            this._startView = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatePickerComponent.prototype, "isDob", {
        get: function () {
            if (this._dob === null || this._dob === undefined) {
                return false;
            }
            return this._dob;
        },
        set: function (val) {
            this._dob = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatePickerComponent.prototype, "minAge", {
        get: function () {
            return this._minAge;
        },
        set: function (val) {
            var _this = this;
            this._minAge = val;
            setTimeout(function () { return _this.setupControl(); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatePickerComponent.prototype, "maxAge", {
        get: function () {
            return this._maxAge;
        },
        set: function (val) {
            var _this = this;
            this._maxAge = val;
            setTimeout(function () { return _this.setupControl(); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatePickerComponent.prototype, "formControlRef", {
        get: function () {
            return this._dateControl;
        },
        set: function (val) {
            var _this = this;
            if (val !== null && val !== undefined) {
                this._dateControl = val;
                if (val.value !== null && val.value !== undefined && val.value !== '') {
                    // always assume that we receive the original in ISO format.
                    this._initDate = new Date(val.value);
                }
            }
            setTimeout(function () { return _this.setupControl(); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatePickerComponent.prototype, "required", {
        get: function () {
            return this._required;
        },
        set: function (val) {
            var _this = this;
            this._required = val;
            setTimeout(function () { return _this.setupControl(); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatePickerComponent.prototype, "format", {
        get: function () {
            return this._format;
        },
        set: function (val) {
            this._format = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatePickerComponent.prototype, "placeHolder", {
        get: function () {
            return this._placeHolder;
        },
        set: function (val) {
            this._placeHolder = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatePickerComponent.prototype, "name", {
        get: function () {
            return this._name;
        },
        set: function (val) {
            this._name = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatePickerComponent.prototype, "locale", {
        get: function () {
            return LocaleHelper.getCurrentLocale();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatePickerComponent.prototype, "startDate", {
        get: function () {
            return this._startDate;
        },
        set: function (val) {
            this._startDate = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatePickerComponent.prototype, "displayDate", {
        get: function () {
            if (this.formControlRef.value !== null && this.formControlRef.value !== undefined) {
                if (typeof this.formControlRef.value === 'string') {
                    var date = new Date(this.formControlRef.value);
                    if (date != null) {
                        return date.toDateString();
                    }
                }
                else if ('toDateString' in this.formControlRef.value) {
                    return this.formControlRef.value.toDateString();
                }
                else if ('format' in this.formControlRef.value) {
                    return this.formControlRef.value.format('Do MMMM, YYYY');
                }
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    DatePickerComponent.validDate = function () {
        return function (control) {
            var theDate = control.value;
            if (theDate === null || theDate === undefined) {
                return { invalid: 'Invalid date' };
            }
            else {
                return null;
            }
        };
    };
    DatePickerComponent.ageBetween = function (comp) {
        return function (control) {
            if (comp.minAge !== null && comp.minAge !== undefined && comp.maxAge !== null && comp.maxAge !== undefined) {
                var theDate = control.value;
                if (theDate === null) {
                    return null;
                }
                var age = DLUtil.calculateAge(theDate);
                if (age >= comp.minAge && age <= comp.maxAge) {
                    return null;
                }
                return { invalidAge: 'Age must be between ' + comp.minAge + ' and ' + comp.maxAge };
            }
            return null;
        };
    };
    DatePickerComponent.prototype.isXs = function () {
        return this.matchMedia.is(['xs']);
    };
    DatePickerComponent.prototype.dateInputChanged = function () {
        if (this.formControlRef.value !== undefined && this.formControlRef.value !== null && this.formControlRef.value !== '') {
            var date = new Date(this.formControlRef.value);
            this.valueChanged.emit(date);
        }
        else {
            this.valueChanged.emit(null);
        }
    };
    DatePickerComponent.prototype.setupControl = function () {
        if (this.required) {
            this.formControlRef.setValidators([Validators.required, DatePickerComponent.validDate(), DatePickerComponent.ageBetween(this)]);
        }
        else {
            this.formControlRef.setValidators([DatePickerComponent.validDate(), DatePickerComponent.ageBetween(this)]);
        }
        if (this.isDob && this.minAge !== undefined && this.minAge !== null) {
            this.startDate = new Date();
            this.startDate.setFullYear(this.startDate.getFullYear() - this.minAge);
        }
    };
    return DatePickerComponent;
}());
export { DatePickerComponent };
