/**
 * This file contains all the needs calculator VOs
 **/

export class NeedsDataVO {
    id: string;
    name: string;
    age: number;
    income: number;
    education: string;
    smoker: string;
    has_homeloan: boolean;
    gender: string;
    debt: number;
    product_group: string;
    lead_id: string;
    education_cost: number;
    living_cost: number;
    needsDependent: NeedsDependentVO[] = [];
    lead: LeadDataVO;
    needsBenefits: NeedsBenefit[];
    needsBreakdown: NeedsBreakdown[];
    total: number;
    noteAsterisk = false;
    campaign_id: string;
}

export class NeedsDependentVO {
    id: string;
    name: string;
    age: number;
    income: number;
    education: string;
    smoker: string;
    type: string;
    dl_needs_id: string;
}

export class NeedsBenefit  {
    id: string;
    name: string;
    description: string;
    type: string;
    product_code: string;
    product_version: string;
    benefit_amount: number;
    premium_amount: number;
    dl_needs_id: string;
    requested_benefit_amount: number;
    noteAsterisk = false;
    toFromText: string;
}

export class NeedsBreakdown {
    id: string;
    name: string;
    type: string;
    amount: number;
    step: number;
    sub_type: string;
    display_name: string;
    original_amount: number;
    max_amount: number;
    dl_needs_id: string;
    static getControlName(name: string): string {
        return name.toLowerCase().replace(/\s/g, '');
    }
}

export class LeadDataVO {
    id: string;
    first_name: string;
    known_as: string;
    last_name: string;
    gender: string;
    phone_mobile: string;
    email1: string;
    refered_by: string;
    campaign_code: string;
    referrer_code: string;
    lead_type: string;
    lead_source: string;
    lead_source_description: string;
}

export class ProductGroupRequestVO {
    lead_id: string;
    campaign_code: string;
}

export class ProductGroupVO {
    productGroupCode: string;
    campaignId: string;
    message?: string;
}

export enum DependentType {
    PARTNER = 'partner',
    CHILD = 'child'
}

export class ResultResponse {
    lead_id: string;
    success: boolean;
    url: string;
}

export class ContinueResponse {
    spid: string;
    success: string;
    url: string;
}

export class NeedsSubmission {
    age: number;
    gender: string;
    income: number;
    education: string;
    smoker_status: string;
    qq_product = 'LC';
    qq_cover_or_premium = 'cover';
    qq_cover: number;
    qq_premium: number;
    product_code = 'LC';
    product_group: string;
    campaign_id: string;
    campaign_code: string;
    referrer: string;
    referrer_code: string;
    qq_source: string;
    force: boolean;
    lead: LeadDataVO;
    benefits: NeedsBenefit[];
    needs_id: string;
}
