/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./request.password.component.ngfactory";
import * as i2 from "./request.password.component";
import * as i3 from "@angular/forms";
import * as i4 from "./reset.password.service";
import * as i5 from "./set.password.component.ngfactory";
import * as i6 from "./set.password.component";
import * as i7 from "./login.service";
import * as i8 from "../base/window.ref";
import * as i9 from "@angular/common";
import * as i10 from "./reset.password.component";
var styles_ResetPasswordComponent = [];
var RenderType_ResetPasswordComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ResetPasswordComponent, data: {} });
export { RenderType_ResetPasswordComponent as RenderType_ResetPasswordComponent };
function View_ResetPasswordComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "dl-row row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "dl-col-md-12 col s12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessage; _ck(_v, 3, 0, currVal_0); }); }
function View_ResetPasswordComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "dl-request-password", [], null, null, null, i1.View_RequestPasswordComponent_0, i1.RenderType_RequestPasswordComponent)), i0.ɵdid(2, 49152, null, 0, i2.RequestPasswordComponent, [i3.FormBuilder, i4.ResetPasswordService], null, null)], null, null); }
function View_ResetPasswordComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "dl-set-password", [], null, null, null, i5.View_SetPasswordComponent_0, i5.RenderType_SetPasswordComponent)), i0.ɵdid(2, 49152, null, 0, i6.SetPasswordComponent, [i4.ResetPasswordService, i7.LoginService, i8.WindowRef], { orgUrl: [0, "orgUrl"], oauthClientId: [1, "oauthClientId"], token: [2, "token"], userName: [3, "userName"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.orgUrl; var currVal_1 = _co.oauthClientId; var currVal_2 = _co.token; var currVal_3 = _co.userName; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_ResetPasswordComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["class", "dl-row row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 12, "div", [["class", "dl-col-md-8 col m8 s12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 11, "div", [["class", "dl-row row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 10, "div", [["class", "dl-container"], ["style", "min-height: 150px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "div", [["class", "dl-col-sm-2 col s2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 8, "div", [["class", "dl-col-sm-8 col s8"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Password reset"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResetPasswordComponent_1)), i0.ɵdid(9, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResetPasswordComponent_2)), i0.ɵdid(11, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResetPasswordComponent_3)), i0.ɵdid(13, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.action === "error"); _ck(_v, 9, 0, currVal_0); var currVal_1 = (_co.action === "forgot"); _ck(_v, 11, 0, currVal_1); var currVal_2 = (_co.action === "reset"); _ck(_v, 13, 0, currVal_2); }, null); }
export function View_ResetPasswordComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "dl-reset-password", [], null, null, null, View_ResetPasswordComponent_0, RenderType_ResetPasswordComponent)), i0.ɵdid(1, 49152, null, 0, i10.ResetPasswordComponent, [], null, null)], null, null); }
var ResetPasswordComponentNgFactory = i0.ɵccf("dl-reset-password", i10.ResetPasswordComponent, View_ResetPasswordComponent_Host_0, { action: "action", orgUrl: "orgUrl", oauthClientId: "oauthClientId", token: "token", userName: "userName", errorMessage: "errorMessage" }, {}, []);
export { ResetPasswordComponentNgFactory as ResetPasswordComponentNgFactory };
