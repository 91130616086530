import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

import * as d3 from 'd3';
import {NeedsBenefit} from './needs.vo';
import {Arc, DefaultArcObject, Pie} from 'd3-shape';
import {BaseType, Selection} from 'd3-selection';
import {isNullOrUndefined} from 'util';

@Component({
    selector: 'pie-graph',
    template: `<div #chart></div>`
})
export class NeedsPieGraphComponent implements OnInit {

    @ViewChild('chart') chart: ElementRef;

    _data: NeedsBenefit[];
    @Input()
    set data(val: NeedsBenefit[]) {
        this._data = val;
        if (!isNullOrUndefined(this.pie)) {
            this.updateChart(this._data);
        }
    }
    get data(): NeedsBenefit[] {
        return this._data;
    }
    _width = 180;
    @Input()
    set width(val: number) {
        this._width = val;
    }
    get width(): number {
        return this._width;
    }
    _height = 180;
    @Input()
    set height(val: number) {
        this._height = val;
    }
    get height(): number {
        return this._height;
    }

    colourList: ReadonlyArray<string> = ['#006593', '#E0861A', '#FFA437', '#1AA1E0', '#935409'];
    colour = d3.scaleOrdinal().range(this.colourList);
    radius: number;
    arc: Arc<any, DefaultArcObject>;
    pie: Pie<any, { valueOf(): number }>;
    svg: Selection<BaseType, any, BaseType, any>;
    currentArcs: any[] = [];

    ngOnInit(): void {
        this.setChartConfigValues();
        this.createChart();
        this.initialDraw(this.data);
    }

    private setChartConfigValues() {
        if (isNullOrUndefined(this.width)) {
            this.width = 180;
        }
        if (isNullOrUndefined(this.height)) {
            this.height = 180;
        }
        this.radius = Math.min(this.width, this.height) / 2;
        if (this.radius < 50) {
            this.radius = 50;
        }
        this.arc = d3.arc().outerRadius(this.radius - 10).innerRadius(this.radius - 45);
    }

    /**
     * To avoid type hel we cast every thing to any
     */
    createChart(): void {
        const element = this.chart.nativeElement;

        this.pie = d3.pie().sort(null).value((d: any) => {
            const data = d as NeedsBenefit;
            return <any>data.premium_amount;
        });
        const translateStr = 'translate(' + (this.width / 2) + ',' +  (this.height / 2) + ')';
        this.svg = d3.select(element).append('svg')
            .attr('width', this.width)
            .attr('height', this.height)
            .attr('style', 'display:block; margin:auto')
            .append('g').attr('transform', translateStr);
    }

    /**
     * To avoid type hel we cast every thing to any
     */
    initialDraw(data: NeedsBenefit[]) {
        const path = this.svg.selectAll('arc')
            .data(this.pie(data as any))
            .enter()
            .append('g')
            .attr('class', 'arc');
        path.append('path').attr('d', <any> this.arc)
            .style('fill', (d: any) => {
                const dataItem = d.data as NeedsBenefit;
                return <any> this.colour(dataItem.name);
            }).each((d: any) => {
                this.currentArcs.push(d);
            });
    }

    updateChart(data: NeedsBenefit[]) {
        const path = this.svg.selectAll('path').data(this.pie(<any> data));
        path.transition().duration(1000).attrTween('d', (d: any) => {
            const interpolate = d3.interpolate(this.currentArcs[d.index], d);
            this.currentArcs[d.index] = interpolate(0);
            return (t) => {
                return this.arc(interpolate(t));
            };
        });
    }

}
