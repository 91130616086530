<mat-form-field class="dl-full-width">
    <input
        matInput
        tabindex="{{tabIndex}}"
        type="text"
        inputmode="numeric"
        [name]="name"
        placeholder="{{placeholder}}"
        [formControl]="formControlRef"
        [attr.data-name]="name"
        [textMask]="dateMask"
    >
    <mat-hint>{{formatHintOrFormattedDate}}</mat-hint>
    <mat-error *ngIf="formControlRef.getError('invalidDate')">Please enter a valid date in the format {{dateFormatDisplay}}</mat-error>
    <mat-error *ngIf="formControlRef.getError('invalidAge')">{{ formControlRef.getError('invalidAge') }}</mat-error>
</mat-form-field>
