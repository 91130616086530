import {Dynamic} from './component';
import {Component, Injector, Input} from '@angular/core';
import {BankingDetailVO, BankingValuesVO, PageComponentVO} from '../page.data.vo';
import {isNullOrUndefined} from 'util';
import {AVSResultVO, BankingValidationService, ValidationReqVO, ValidationResVO} from './banking.validation.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Payer} from '../../base/payer/dl.payer.vo';
import {DlPayerModalComponent} from '../../base/payer/dl.payer.modal.component';
import {MatDialog, MatDialogRef} from '@angular/material';
import {interval} from 'rxjs';
import {DlBankingValidationService} from '../../base/banking/dl.banking.validation.service';
import {ErrorModalProvider} from '../../base/error/error.modal.service';
import {PageComponent} from '../loader/page.component';
import {OverrideResponse} from '../../sp/payment/override/admin.override.service';
import {PaymentService} from '../../sp/payment/payment.service';
import {SpDataService} from '../../sp/sp.data.service';
import {BankDetail} from '../../base/banking/dl.banking.vo';
import {Log} from 'ng2-logger/browser';
import {WindowRef} from '../../base/window.ref';

const log = Log.create('dsp-banking-component');

@Component({
    selector: 'banking-input',
    templateUrl: 'banking.component.html'
})
export class BankingComponent extends Dynamic<PageComponentVO> {

    banking: FormGroup;

    overrideText = 'OPS: Override';
    canOverride = false;
    overrideBusy = false;
    overrideApproved = false;
    forAltPayer = false;
    altPayerApproved = false;

    bankingDetail: BankingDetailVO;
    bankingInput: BankingValuesVO;
    bankingValidationService: BankingValidationService;

    validationFailed = false;
    defaultPremiumPayerEnabled = true;
    policyId: string;
    canContinue = true;
    source = interval(1000);
    subscribe;

    modalData: Payer;
    payer: Payer;
    _showAltOptions = true; // TODO: showAltOptions should be set by a property pulled from life-web-oc features
    avsAccountIDMatch: boolean;
    avsAccountExists: boolean;
    avsAccountAcceptsDebits: boolean;
    avsAccountPhoneValid: boolean;
    bankSupportsRTAvs: boolean;

    private payerDialogRef: MatDialogRef<DlPayerModalComponent>;

    @Input()
    set showAltOptions(show: boolean) {
        if (!isNullOrUndefined(show)) {
            this._showAltOptions = show;
        }
    }
    get showAltOptions(): boolean {
        return (this._showAltOptions &&
                this.pageComponent._pageInfo.mode === 'embedded' &&
                !this.pageComponent._pageInfo.flowId.includes('MLF')
                );
    }

    constructor(public winRef: WindowRef,
                private injector: Injector,
                private fb: FormBuilder,
                private dialog: MatDialog,
                protected errorDialogService: ErrorModalProvider,
                private validationService: DlBankingValidationService,
                private pageComponent: PageComponent,
                private paymentService: PaymentService,
                private spDataService: SpDataService) {
        super();
        this.bankingValidationService = this.injector.get(BankingValidationService);
        this.setupForm();
    }

    setupForm(): void {
        this.banking = this.fb.group({
            bank: ['', [Validators.required]],
            branch: ['', [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(8)]],
            accountType: ['', Validators.required],
            accountNo: ['', [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(20)]],
            accountHolder: ['', Validators.required],
            altPremiumPayer: ['']
        });
        this.banking.get('bank').valueChanges.subscribe(() => this.bankSelected());
    }

    setupFromControl() {
        this.formGroup.addControl('banking', this.banking);
        // we use the nested from to tel the parent of its validity
        this.component.isValid = true;
    }

    getFormControl(): FormControl {
        // this is not needed as this control overrides default behaviour
        return null;
    }

    onChange(): void {
    }

    bankSelected(): void {
        this.bankingDetail.branchCode = this.getValueFromForm('bank');
        const selected = this.bankingInput.bankNames.find(item => item.id === this.bankingDetail.branchCode);
        this.bankingDetail.bankName = isNullOrUndefined(selected) ? '' : selected.display;
        setTimeout(() => {
            this.banking.get('branch').setValue(this.banking.get('bank').value);
        });
        this.bankSupportsRTAvs = isNullOrUndefined(selected) ? false : selected.realtimeAvsSupport;
    }

    private getValueFromForm(formKey: string): string {
        return this.banking.get(formKey).value;
    }

    fieldIsEmpty(field): boolean {
        if (field === '' || isNullOrUndefined(field)) {
            return true;
        }
        return false;
    }

    isStupidlyValid(req: ValidationReqVO): boolean {
        if (
            this.fieldIsEmpty(req.account_number) ||
            this.fieldIsEmpty(req.branch_code) ||
            this.fieldIsEmpty(req.account_type)
        ) {
            return false;
        }
        return true;
    }

    handleAVSNotSupported(req: ValidationReqVO) {

        console.log('AVS not supported. Handling.');
        // TODO: perhaps do CDV here? For now just check that all fields have something in them.
        if (this.isStupidlyValid(req)) {
            console.log('Basic validation passed.');
            this.avsValidated();
        } else {
            console.log('Basic validation failed.');
            this.avsFailed();
        }
    }

    validateAccount(): void {
        var res: AVSResultVO;
        const req: ValidationReqVO = {
            account_number: this.banking.get('accountNo').value,
            branch_code: this.banking.get('bank').value,
            account_type: this.banking.get('accountType').value,
            ref: this.bankingDetail.ref
        };
        if (this.bankSupportsRTAvs) {
            console.log('Bank supports AVS - doing AVS.');
            this.bankingValidationService.validateAccount(req).subscribe((res) => this.handleValidationCompleted(res));
        } else {
            this.handleAVSNotSupported(req);
        }
    }

    handleValidationCompleted(resultVO: AVSResultVO): void {
        // if (!resultVO.valid) {
        console.log('AVS requested.');
        if (!resultVO.success) {
            this.errorDialogService.showErrorMessage('Couldn\'t send message for validation', 'AVS Failed');
            // this.errorDialogService.showErrorMessage('Validation has failed to validate', 'Validation Failed');
            // this.pageComponent.busy = false;
            // this.pageComponent.disable = false;
        } else {
            // AVS
            this.pollUntilAvsFinished(resultVO.Response.userReference);
            // this.validationFailed = !resultVO.valid;
            // this.component.isValid = resultVO.valid;
            // this.validated.emit({componentId: this.component.id, success: resultVO.valid});
        }
        /*this.validationFailed = !res.valid;
        this.component.isValid = res.valid;
        this.validated.emit({componentId: this.component.id, success: res.valid});*/
    }

    pollUntilAvsFinished(userRef) {
        this.subscribe = this.source.subscribe(val => {
            // console.log(val);
            this.validationService.getAvsValidationStatus(userRef).subscribe (res => {
                // console.log(res);
                if (res.status === 3 || res.status === 4) {
                    console.log('AVS polling finished');
                    this.pollingFinishedFor(userRef, res);
                } else {
                    // console.log('no results yet');
                    if (val === 90) {
                        console.log('AVS response timeout - 90 seconds passed');
                        this.errorDialogService.showErrorMessage('No response received AVS validation', 'AVS Failed');
                        this.pollingFinishedFor(userRef, res);
                    }
                }
            }, () => {
                console.log('AVS polling had an error');
            });
        });
    }

    avsValidated() {
        console.log('Performing post-AVS actions - validated.');
        this.validationFailed = false;
        this.component.isValid = true;
        this.validated.emit({componentId: this.component.id, success: true});
    }

    avsFailed() {
        console.log('Performing post-AVS actions - failed.');
        this.pageComponent.busy = false;
        this.pageComponent.disable = false;
    }

    pollingFinishedFor(userRef, avsResult) {
        this.subscribe.unsubscribe();
        if (avsResult.status === 3) {
            if (avsResult.Response.accountExists === '00'
                && avsResult.Response.accountAcceptsDebits === '00'
                && avsResult.Response.accountIdMatch === '00') {
                /*if (avsResult.Response.accountIdMatch !== '00') {
                    this.errorDialogService.showErrorMessage('Please note that the ID number does not ' +
                        'match the one on record at the bank specified', 'Warning');
                }*/
                this.avsValidated();
            } else {

                if (this.pageComponent._pageInfo.mode === 'embedded') {
                    this.handleEmbeddedAvsFailure(avsResult);
                } else {
                    this.handleWebAvsFailure();
                }

                // re-enable the button from parent component
                this.pageComponent.busy = false;
                this.pageComponent.disable = false;

            }
        } else {
            // re-enable the button from parent component
            this.avsFailed();
        }
    }

    async handleWebAvsFailure() {
        if  (confirm('We were unable to verify your banking details. Would you like an agent to call you and assist?')) {
            let agentWillCall = (await this.pageComponent.updateCaseForWebAvsInvalid());
            console.log('Agent will call?: ' + agentWillCall);
            if (agentWillCall) {
                // Uplift case for agent to phone them
                this.errorDialogService.showErrorMessage(
                    'Please feel free to try another bank account, otherwise you can close the browser.',
                    'Thank you. An agent will phone you back shortly.');
            } else {
                this.errorDialogService.showErrorMessage(
                    'Please feel free to try another bank account, otherwise an agent will call you to assist.',
                    'Something went wrong.');
            }
        } else {
            // Let them re-enter their banking details.
            this.avsFailed();
        }
    }

    handleEmbeddedAvsFailure(avsResult) {
        let validationString: string;
        validationString = 'Account Exists: ' + String(avsResult.Response.accountExists === '00') + ', ';
        validationString += 'Account ID Match: ' + String(avsResult.Response.accountIdMatch === '00') + ', ';
        validationString += 'Account Accepts Debits: ' + String(avsResult.Response.accountAcceptsDebits === '00') + ', ';
        validationString += 'Phone Number Match: ' + String(avsResult.Response.phoneValid === '00') + ', ';

        this.setupOverrideValues(avsResult);
        // error
        if (avsResult.Response.accountExists !== '00' || avsResult.Response.accountAcceptsDebits !== '00') {
            validationString += '. You cannot override for these errors';
            this.canOverride = false;
            this.canContinue = false;
        } else {
            this.canOverride = true;
        }
        this.avsAccountExists = avsResult.Response.accountExists === '00';
        this.avsAccountIDMatch = avsResult.Response.accountIdMatch === '00';
        this.avsAccountAcceptsDebits = avsResult.Response.accountAcceptsDebits === '00';
        this.avsAccountPhoneValid = avsResult.Response.phoneValid === '00';

        this.errorDialogService.showErrorMessage(validationString, 'AVS Failed');
    }

    broadcastStateChanged(): void {
        if (!isNullOrUndefined(this.change)) {
            this.change.emit(this.component.counter);
        }
    }

    processExtraData(): void {
        this.bankingInput = this.component.extraInput as BankingValuesVO;
        this.bankingDetail = this.component.value as BankingDetailVO;

        this.banking.get('bank').setValue(this.bankingDetail.bankNameCode);
        this.banking.get('branch').setValue(this.bankingDetail.branchCode);
        this.banking.get('accountType').setValue(this.bankingDetail.accountType);
        this.banking.get('accountNo').setValue(this.bankingDetail.accountNo);
        this.banking.get('accountHolder').setValue(this.bankingDetail.accountHolder);

        this.component.isValid = this.banking.valid;

        this.broadcastStateChanged();
    }


    validate(): boolean {
        // called in parent (page.component.ts)
        // populate the needed data
        this.bankingDetail.accountHolder = this.banking.get('accountHolder').value;
        this.bankingDetail.accountNo = this.banking.get('accountNo').value;
        this.bankingDetail.accountType = this.banking.get('accountType').value;
        this.bankingDetail.branchCode = this.banking.get('branch').value;
        this.bankingDetail.bankName = this.bankingInput.bankNames.find(item => item.id === this.banking.get('bank').value).display;
        this.bankingDetail.bankNameCode = this.banking.get('accountHolder').value + '\'s bank account';
        if (this.defaultPremiumPayerEnabled) {
            this.bankingDetail.premiumPayerMode = 'default';
        }
        this.component.value = this.bankingDetail;

        // Save here to be able to AVS validate
        let bankDetail = this.buildBankDetailObject();
        this.paymentService.saveBanking(this.pageComponent._pageInfo.spId,
            bankDetail, this.pageComponent.pageInfo.policyId)
            .subscribe(res => {
                this.spDataService.continueBusy = false;
                this.validateAccount();
            });
        return true;
    }

    private setFormItemValue(fromItem: string, itemValue: any) {
        if (!isNullOrUndefined(itemValue) && itemValue !== '') {
            const options: any = {};
            /*if (this.skipChange) {
                options.emitEvent = false;
            }*/
            this.banking.get(fromItem).setValue(itemValue, options);
        }
    }

    setAltPremiumPayer(altPayerSelected: boolean) {
        if (altPayerSelected) {
            this.modalData = new Payer();
            this.openModal(false);
            log.info('Premium payer set to: ' + this.bankingDetail.premiumPayerMode);
        } else {
            this.bankingDetail.premiumPayerMode = 'default';
            log.info('Premium payer set to: ' + this.bankingDetail.premiumPayerMode);
            this.setFormItemValue('accountHolder', ' ');
            this.defaultPremiumPayerEnabled = true;
            this.canContinue = true;
            this.validationFailed = false;
            this.component.isValid = true;
            this.broadcastStateChanged();
        }
    }

    private openModal(edit: boolean) {
        this.payerDialogRef = this.dialog.open(DlPayerModalComponent,
            {
                panelClass: 'dl-modal',
                maxWidth: '99vw',
                position: {top: '20px'},
                data: {
                    payer: this.modalData,
                    title: (edit ? 'Edit ' : 'Add ') + 'Premium payer'
                }
            }
        );
        this.payerDialogRef.afterClosed().subscribe(res => {
            this.dialogClosed(res);
        });
    }

    dialogClosed(closeValue: string): void {
        if (closeValue === 'save') {
            this.payer = this.modalData;
            this.setFormItemValue('accountHolder', this.payer.first_name + ' ' + this.payer.last_name);
            this.bankingDetail.premiumPayerMode = 'alt';
            log.info('Premium payer set to: ', this.bankingDetail.premiumPayerMode);
            this.bankingDetail.payer = this.payer;
            this.bankingDetail.payer.policy_id = this.pageComponent.pageInfo.policyId;
            this.defaultPremiumPayerEnabled = false;

            let bankDetail = this.buildBankDetailObject();

            // We save banking here to make AVS use the alternative payer's ID etc.

            this.paymentService.saveBanking(this.pageComponent._pageInfo.spId,
                bankDetail, this.pageComponent.pageInfo.policyId)
                .subscribe(res => {
                    this.spDataService.continueBusy = false;
                });
            this.component.value = this.bankingDetail;
            this.canOverride = true;
            this.forAltPayer = true;
            this.canContinue = false;
            this.pageComponent.disable = true;
            // send a message to sidebar to populate alt payer details in contact section.
            if (!isNullOrUndefined(this.winRef.nativeWindow.parent)) {
                console.log('Sending message: ' + 'refresh_person');
                this.winRef.nativeWindow.parent.postMessage('refresh_person', '*');
            }
        }
    }

    private buildBankDetailObject(): BankDetail {
        let bankDetailObject = new BankDetail();
        bankDetailObject.id = this.bankingDetail.id;
        bankDetailObject.accountHolder = this.bankingDetail.accountHolder;
        bankDetailObject.accountNumber = this.bankingDetail.accountNo;
        bankDetailObject.accountType = this.bankingDetail.accountType;
        bankDetailObject.bankName = this.bankingDetail.bankName;
        bankDetailObject.branchCode = this.bankingDetail.branchCode;
        bankDetailObject.valid = this.bankingDetail.validated;
        bankDetailObject.premiumPayerMode = this.bankingDetail.premiumPayerMode;
        bankDetailObject.payer = this.bankingDetail.payer;
        bankDetailObject.valid = false;
        bankDetailObject.validationRef = this.bankingDetail.ref;

        return bankDetailObject;
    }

    requestOverride() {
        this.overrideBusy = true;
        this.overrideText = 'Requesting...';
        this.pageComponent.disable = true;
        if (isNullOrUndefined(this.payer)) {
            this.payer = new Payer();
            this.payer.relation = 'Not specified';
            this.payer.notes = 'Nothing noted';
        }
        this.validationService.sendOverrideRequest(
            this.pageComponent._pageInfo.spId,
            'dl_sales_process',
            this.avsAccountExists,
            this.avsAccountAcceptsDebits,
            this.avsAccountIDMatch,
            this.avsAccountPhoneValid,
            this.forAltPayer,
            this.payer.relation,
            this.payer.notes,
            this.bankSupportsRTAvs).subscribe( res => {
            if (res.id === '') {
                // log.info('Override request service failed : ' + res);
                this.overrideFailed();
            } else {
                // log.info('Override request response: ' + res);
                this.pollForOverrideStatus(res);
            }
        }, () => {
            this.overrideFailed();
        });
    }

    selectionChange(id: string) {
    }

    pollForOverrideStatus(res: OverrideResponse) {
        if (res.override_status === 'approved') {
            this.pollingOverrideSuccess();
            return;
        } else if (res.override_status === 'rejected') {
            this.overrideRejected();
            return;
        }
        this.overrideText = 'Awaiting approval';
        setTimeout(() => {
            this.validationService.checkOverrideStatus(res.id).subscribe(
                statusRes => this.pollForOverrideStatus(statusRes),
                err => {
                    // log.info('Update Check failed: Do another call', err);
                    /*let errorRes: OverrideResponse = new OverrideResponse();
                    errorRes.id = res.id;
                    errorRes.override_status = 'requested';
                    errorRes.error = 'Missed a call';
                    errorRes.approved = false;*/
                    this.pollForOverrideStatus(res);
                }
            );
        }, 1000);
    }

    pollingOverrideSuccess() {
        this.overrideApproved = true;
        this.overrideBusy = false;
        this.canOverride = false;
        this.pageComponent.pageValid = true;
        this.pageComponent.disable = false;
        if (this.forAltPayer) {
            this.forAltPayer = false;
            this.overrideText = 'Approved. Do Validation!';
            this.altPayerApproved = true;
        } else {
            this.validationFailed = false;
            this.component.isValid = true;
            this.validated.emit({componentId: this.component.id, success: true});
            this.overrideText = 'Approved';
        }
    }

    overrideFailed() {
        this.overrideApproved = false;
        this.overrideBusy = false;
        this.canOverride = true;
        this.pageComponent.busy = false;
        this.pageComponent.disable = true;
        this.overrideText = 'Failed. Request again...';
    }

    overrideRejected() {
        this.overrideApproved = false;
        this.overrideBusy = false;
        this.canOverride = true;
        this.pageComponent.busy = false;
        this.pageComponent.disable = true;
        this.overrideText = 'Override rejected';
        this.errorDialogService.showErrorMessage("Please speak to your TL", 'Override rejected');
    }

    setupOverrideValues(avsResultResponse) {
        // this.canOverride = true;
        this.avsAccountIDMatch = avsResultResponse.Response.accountIdMatch === '00';
        this.avsAccountPhoneValid = avsResultResponse.Response.phoneValid === '00';
    }
}
