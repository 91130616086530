import {Component} from '@angular/core';
import {QQService} from './qq.service';
import {CloseEventData, QQResult} from './qq.vo';
import {QQPricingRequestVO, QQPricingResponseVO} from './qq.pricing.vos';
import {BenefitCalculator} from '../base/benefit.calculator';
import {FormBuilder} from '@angular/forms';
import {OrgResultBlockComponent} from './dl.org.result.block.component';
import {SGResultBlockComponent} from './sg.result.block.component';
import {SelectItem} from '../base/select.vo';
import {WindowRef} from '../base/window.ref';
import {MatDialogRef} from '@angular/material';
import {MatchMediaService} from '../base/match.media.service';
import {QQContinue} from './qq.component';
import {QqDynamicLoadInput} from './qq.dynamic.component';
import {Benefit} from '../base/benefit';
import {QQResultBaseComponent} from './qq.result.base.component';

/**
 * The QQ result component.
 * This component is in charge of displaying the slider for a quick price
 */
@Component({
    selector: 'qq-result',
    templateUrl: './qq.result.component.html',
    styleUrls: ['./qq.result.component.scss']
})
export class QQResultComponent extends QQResultBaseComponent {

    componentData: QqDynamicLoadInput = null;

    constructor(
        qqService: QQService,
        winRef: WindowRef,
        fb: FormBuilder,
        public matchMediaService: MatchMediaService,
        public dialogRef: MatDialogRef<QQResultComponent>) {
        super(qqService, winRef, fb);
    }

    private calculateInitialValues(qqPricingRequestData: QQPricingRequestVO) {
        this.qqPricingRequestData = qqPricingRequestData;
        this.benefit.escalationFactor = 1;
        this.benefit.coverEscalationFactor = 1;
        this.benefit.discountRate = 0;
        // set sum assured to 4% of income
        this.benefit.cover = -(((qqPricingRequestData.income_gross_monthly * 0.04)
            * this.benefit.denominatorFactor) + this.benefit.numeratorFactor)
            / this.benefit.multiplierFactor;
        // for DC and CI30
        if (this.benefit.code !== 'LC') {
            this.benefit.cover = -(((qqPricingRequestData.income_gross_monthly * 0.02)
                * this.benefit.denominatorFactor) + this.benefit.numeratorFactor)
                / this.benefit.multiplierFactor;
        }
        // round up to the nearest increment
        this.benefit.cover = Math.ceil(this.benefit.cover / this.benefit.coverIncrements) * this.benefit.coverIncrements;
        // for SP90
        if (this.benefit.code === 'SP90') {
            this.benefit.cover = this.benefit.maxCover;
        }
        // limit cover to within min/max range
        if (this.benefit.maxCover < this.benefit.cover) {
            this.benefit.cover = this.benefit.maxCover;
        }
        if (this.benefit.minCover > this.benefit.cover) {
            this.benefit.cover = this.benefit.minCover;
        }
        this.benefit.premium = BenefitCalculator.calc('primaryPremiumNoDiscount', this.benefit);
    }

    public openResult(qqRequestData: QQPricingRequestVO, idNumber: string,
                      qqPricingResponse: QQPricingResponseVO, product: SelectItem, qqContinue: QQContinue) {
        this.idNumber = idNumber;
        this.qqContinue = qqContinue;
        this.qqForm.reset();
        this.resShowWarning = false;
        this.benefit = qqPricingResponse;
        this.benefit.code = product.value;
        this.benefit.name = product.label;
        this.benefit.coverOrPremium = 'Cover';
        this.calculateInitialValues(qqRequestData);
        if (this.inputData.securityGroup === 'DL') { // show org block
            this.componentData = {
                component: OrgResultBlockComponent,
                inputs: {
                    benefit: this.benefit as Benefit,
                    cause: this.inputData.causeText
                }
            };
        } else {
            this.componentData = { // show stangen placeholder.
                component: SGResultBlockComponent,
                inputs: {
                    benefit: this.benefit,
                    cause: ''
                }
            };
        }
    }

    public closeResultModal(event: CloseEventData) {
        this.resetButton();
        this.dialogRef.close(event);
    }

    protected handleQQResponse(response: QQResult) {
        if (response.success === false || response.success !== 'true') {
            this.resShowWarning = true;
            this.resetButton();
            return;
        }
        // lead capture close
        if (this.inputData.leadCapture) {
            this.dialogRef.close(CloseEventData.createCancelEvent('A Lead was successfully created'));
            return;
        }
        // if mobile show warning
        if (this.matchMediaService.is(['sm', 'xs']) && !this.inputData.embedded) {
            this.dialogRef.close(CloseEventData.createMobileWarningEvent(response));
            return;
        }
        // We are now moving to the SP:
        this.qqContinue.continueToPersonal(response);
    }

}
