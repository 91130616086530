import {Component} from '@angular/core';
import {Navigation} from './nav.component';
import {DspService} from '../dsp.service';

/**
 * Created by corneliusbotha on 2017/07/18.
 */

@Component({
    selector: 'sg-nav',
    templateUrl: 'sg.nav.component.html',
    styleUrls: ['sg.nav.component.scss']
})
export class SgNavComponent extends Navigation {
  constructor(public dspService: DspService) {
    super(dspService);
  }
}
