<form >
    <div class="row">
        <div class="col s12">
            <div id="card-number" #cardInfo class="stripe-element"></div>
        </div>
    </div>
    <div class="row">
        <div *ngIf="error && error !== ''" class="col s12 card-errors">
            <div>{{error}}</div>
        </div>
    </div>
</form>


