<div class="dl-error-modal">
    <div mat-dialog-title class="dl-modal-header">
        <h4 class="dl-modal-title">{{title}}<a class="close dl-pull-right right" (click)="confirm()"><i class="fa fa-times"></i></a></h4>
    </div>
    <div mat-dialog-content class="dl-modal-body dl-error">
        <div style="white-space: pre;">{{error.message}}</div>
        <mat-expansion-panel *ngIf="error.detail" style="margin-top: 15px;">
            <mat-expansion-panel-header>
                <mat-panel-title>Technical Detail</mat-panel-title>
            </mat-expansion-panel-header>
            <pre>{{error.detail | json}}</pre>
        </mat-expansion-panel>
    </div>
    <mat-dialog-actions align="end" class="dl-modal-footer">
        <button mat-raised-button color="primary" class="dl-action-btn" (click)="confirm()" tabindex="0">OK</button>
    </mat-dialog-actions>
</div>



