var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Dynamic, ParentEventService } from './component';
import { OnDestroy } from '@angular/core';
var AltContinueComponent = /** @class */ (function (_super) {
    __extends(AltContinueComponent, _super);
    function AltContinueComponent(parentEventService) {
        var _this = _super.call(this) || this;
        _this.parentEventService = parentEventService;
        _this.isDisabled = true;
        _this.isBusy = false;
        _this.parentValidSubscription = parentEventService.getPageValidateObservable().subscribe(function (event) { return _this.isDisabled = !event; });
        _this.parentBusySubscription = parentEventService.getPageBusyObservable().subscribe(function (event) {
            _this.isDisabled = event;
            if (_this.isBusy && !event) {
                _this.isBusy = false;
            }
        });
        return _this;
    }
    AltContinueComponent.prototype.setupFromControl = function () {
        this.component.loaded = true;
        // tel my parent I'm loaded.
        this.loaded.emit(this.component.id);
    };
    AltContinueComponent.prototype.getFormControl = function () {
        return null;
    };
    AltContinueComponent.prototype.click = function () {
        this.isBusy = true;
        this.altContinue.emit(this.component.value);
    };
    AltContinueComponent.prototype.ngOnDestroy = function () {
        this.parentValidSubscription.unsubscribe();
        this.parentBusySubscription.unsubscribe();
    };
    return AltContinueComponent;
}(Dynamic));
export { AltContinueComponent };
