var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { Dynamic } from './component';
import { isNullOrUndefined } from 'util';
import { Log } from 'ng2-logger/client';
var log = Log.create('ProductComponent');
/**
 * Created by corneliusbotha on 2017/07/20.
 */
var ProductComponent = /** @class */ (function (_super) {
    __extends(ProductComponent, _super);
    function ProductComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProductComponent.prototype.onTabEvent = function (event) {
        this.index = event.index;
        this.setSelected(event.index);
        this.checkValidity();
    };
    ProductComponent.prototype.setSelected = function (selectedIndex) {
        var products = this.component.extraInput;
        for (var i = 0; i < products.length; i++) {
            var product = products[i];
            if (selectedIndex === i) {
                this.component.value = product;
            }
            product.selected = selectedIndex === i;
        }
    };
    ProductComponent.prototype.ngOnInit = function () {
    };
    ProductComponent.prototype.checkValidity = function () {
        if (!isNullOrUndefined(this.component.value) && this.component.value !== '') {
            log.info('Checking validity', this.component.value);
            var product = this.component.value;
            this.component.isValid = product.detail[0].amountVal > 0;
        }
        else {
            this.component.isValid = false;
        }
        if (!isNullOrUndefined(this.change)) {
            this.change.emit(this.component.counter);
        }
    };
    ProductComponent.prototype.getFormControl = function () {
        return null;
    };
    ProductComponent.prototype.onChange = function () {
    };
    ProductComponent.prototype.setupFromControl = function () {
    };
    return ProductComponent;
}(Dynamic));
export { ProductComponent };
