<div class="row">
    <div class="col s12">
        <span *ngIf="offer$ | async as values; else loadingText" [innerHTML]="values | dl_offer_text">
        </span>
        <ng-template #loadingText>
            Recalculating...
        </ng-template>
    </div>
    <div class="col s12">
        <h3>Change cover amount</h3>
    </div>
    <div class="col s12 card-panel">
        <div class="col s12 hide-on-small-only">
            &nbsp;
        </div>
        <div class="col s2 hide-on-small-only">
            <button mat-raised-button class="right" (click)="coverDown()" style="min-width: 0;"><span class="fa fa-minus"></span>
            </button>
        </div>
        <div class="col m8 s12">
            <mat-slider #coverSlider
                        color="primary"
                        [thumbLabel]="true"
                        [displayWith]="formatCoverForDisplay"
                        [max]="in.coverMax"
                        [min]="in.coverMin"
                        [step]="in.coverStep"
                        [value]="initialValue.cover"
            >
            </mat-slider>
        </div>
        <div class="col s2 hide-on-small-only">
            <button mat-raised-button (click)="coverUp()" style="min-width: 0;"><span class="fa fa-plus"></span>
            </button>
        </div>
        <div class="col s12">
            <div class="col s2">
                <label class="s2 right"><span class="badge" style="margin-left: 0;">{{ in.coverMin | dl_compact_number }}</span></label>
            </div>
            <div class="col offset-s3 s2">
                <label class="s2 center"><span class="badge" style="margin-left: 0;">{{ coverSlider.value | dl_currency:true:0 }}</span></label>
            </div>
            <div class="col offset-s3 s2">
                <label class="s2 left"><span class="badge" style="margin-left: 0;">{{ in.coverMax | dl_compact_number }}</span></label>
            </div>
        </div>
        <div class="col s12 hide-on-med-and-up">
            <div class="col s6">
                <button mat-raised-button class="left" (click)="coverDown()" style="min-width: 0; width: 70%;"><span
                    class="fa fa-minus"></span>
                </button>
            </div>
            <div class="col s6">
                <button mat-raised-button class="right" (click)="coverUp()" style="min-width: 0;; width: 70%;"><span class="fa fa-plus"></span>
                </button>
            </div>
        </div>
    </div>
    <div class="col s12" [hidden]="in.isWol">
        <h3>Change term</h3>
    </div>
    <div class="col s12 card-panel" [hidden]="in.isWol">
        <div class="col s12 hide-on-small-only">
            &nbsp;
        </div>
        <div class="col s2 hide-on-small-only">
            <button mat-raised-button class="right" (click)="termDown()" style="min-width: 0;"><span class="fa fa-minus"></span>
            </button>
        </div>
        <div class="col m8 s12">
            <mat-slider #termSlider
                        color="primary"
                        [thumbLabel]="true"
                        [displayWith]="formatTermForDisplay"
                        [max]="in.termMax"
                        [min]="in.termMin"
                        [step]="1"
                        [value]="initialValue.term"
            >
            </mat-slider>
        </div>
        <div class="col s2 hide-on-small-only">
            <button mat-raised-button (click)="termUp()" style="min-width: 0;"><span class="fa fa-plus"></span>
            </button>
        </div>
        <div class="col s12">
            <div class="col s2">
                <label class="s2 right"><span class="badge" style="margin-left: 0;">{{ in.termMin }}</span></label>
            </div>
            <div class="col offset-s3 s2">
                <label class="s2 center"><span class="badge" style="margin-left: 0;">{{ termSlider.value }}&nbsp;years</span></label>
            </div>
            <div class="col offset-s3 s2">
                <label class="s2 left"><span class="badge" style="margin-left: 0;">{{ in.termMax }}</span></label>
            </div>
        </div>
        <div class="col s12 hide-on-med-and-up">
            <div class="col s6">
                <button mat-raised-button class="left" (click)="termDown()" style="min-width: 0; width: 70%;"><span
                    class="fa fa-minus"></span>
                </button>
            </div>
            <div class="col s6">
                <button mat-raised-button class="right" (click)="termUp()" style="min-width: 0;; width: 70%;"><span class="fa fa-plus"></span>
                </button>
            </div>
        </div>
    </div>
</div>
