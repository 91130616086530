/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./hidden.component";
var styles_HiddenComponent = [];
var RenderType_HiddenComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HiddenComponent, data: {} });
export { RenderType_HiddenComponent as RenderType_HiddenComponent };
export function View_HiddenComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["style", "display: none"]], [[1, "data-val", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.component.value; _ck(_v, 0, 0, currVal_0); }); }
export function View_HiddenComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "hidden-input", [], null, null, null, View_HiddenComponent_0, RenderType_HiddenComponent)), i0.ɵdid(1, 49152, null, 0, i1.HiddenComponent, [], null, null)], null, null); }
var HiddenComponentNgFactory = i0.ɵccf("hidden-input", i1.HiddenComponent, View_HiddenComponent_Host_0, { flowId: "flowId", formGroup: "formGroup", component: "component", pageName: "pageName", _productCode: "_productCode", groupId: "groupId", _spMode: "_spMode" }, { change: "change", loaded: "loaded", validated: "validated", resume: "resume", altContinue: "altContinue" }, []);
export { HiddenComponentNgFactory as HiddenComponentNgFactory };
