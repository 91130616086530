<div class="qq-modal" id="quickQuoteModal">
	<div mat-dialog-title class="dl-modal-header">
		<h4 class="dl-modal-title">
			{{benefit.name}}&nbsp;of&nbsp;<b class="text-primary"
				id="qq-cover-heading">{{benefit.cover | dl_currency:true:0}}</b>
			for&nbsp;<b class="text-primary"
				id="qq-premium-heading">{{benefit.premium | dl_currency:true:0}}</b>&nbsp;pm&nbsp;*
			<button type="button" class="close dl-pull-right right" tabindex="-1" (click)="close()">
				&times;<span class="sr-only">Close</span>
			</button>
		</h4>
	</div>
	<mat-dialog-content>
		<div class="dl-modal-body">
			<benefit-slider [benefit]="benefit"></benefit-slider>
			<dynamic_qq_block [componentData]="componentData"></dynamic_qq_block>
			<div class="dl-container">
				<div class="dl-row row" *ngIf="!inputData.embedded">
					<form [formGroup]="qqForm" novalidate id="LC-QUICK-QUOTE-RESULT">
						<div class="dl-col-md-3 col m3 s12">
							<mat-form-field class="dl-full-width" style="max-width: 100%">
								<input matInput type="text" name="fullname" id="fullname" placeholder="Name"
									[attr.kings-watch]="'7STEP-QQ-name'" [attr.data-name]="'name'"
									[formControl]="qqForm.controls['fullname']" [tabindex]="1">
							</mat-form-field>
						</div>
						<div class="dl-col-md-5 col m5 s12">
							<mat-form-field class="dl-full-width" style="max-width: 100%">
								<input matInput type="email" id="email" placeholder="Email" name="email"
									[attr.data-name]="'email'" [attr.kings-watch]="'7STEP-QQ-email'"
									[formControl]="qqForm.controls['email']" [tabindex]="1">
								<mat-checkbox *ngIf="inputData.leadCapture" matSuffix [labelPosition]="'after'"
									style="padding-left: 10px" (click)="setNoEmail()">
									<b> No Email</b>
								</mat-checkbox>
								<mat-error align="start">Invalid email address</mat-error>
							</mat-form-field>
						</div>
						<div class="dl-col-md-4 col m4 s12">
							<mat-form-field class="dl-full-width" style="max-width: 100%">
								<input matInput phoneNumber name="phone_mobile" id="cell" type="tel"
									placeholder="Cell Number" [attr.kings-watch]="'7STEP-QQ-cellphone_number'"
									[attr.data-name]="'cellphoneNumber'" [formControl]="qqForm.controls['cell']"
									[tabindex]="1">
								<mat-error align="start">Format: (000) 000-0000</mat-error>
							</mat-form-field>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div class="dl-modal-footer-no-border row">
			<div class="dl-col-sm-6"></div>
			<div class="dl-col-sm-6 col m6 s12 offset-m6">
				<button *ngIf="!resShowWarning" mat-raised-button color="primary" class="dl-full-width dl-action-btn"
					id="sign-me-up" type="button" tabindex="1" style="letter-spacing: 1px;"
					(click)="postToContinue(false)" [attr.data-event]="'true'"
					[attr.data-event-category]="'Quick Quote'" [attr.kings-watch]="'submit'"
					[attr.data-event-name]="'Continue'" [attr.data-event-label]="'Quick Quote Continue from result'"
					[disabled]="busy">
					Continue&nbsp;<span *ngIf="!resLoading" class="fa fa-chevron-right"></span>
					<i class="fa fa-circle-o-notch fa-spin" *ngIf="resLoading"></i>
				</button>
			</div>
			<div class="dl-col-sm-12 col s12" style="text-align: left;" *ngIf="resShowWarning">
				<div class="dl-alert dl-alert-danger dl-fade in" role="alert">
					<h4>We've detected a process already running!</h4>
					<p>Please note by continuing you will lose your current process. Please check that our
						website isn't open in another tab or window.</p>
					<p style="text-align: right">
						<button mat-raised-button class="dl-full-width dl-action-btn" id="qq_oops_close" type="button"
							[tabindex]="1" [disabled]="busy" [attr.data-event]="'true'"
							[attr.data-event-category]="'Quick Quote'" [attr.data-event-name]="'Warning Close'"
							[attr.data-event-label]="'Quick Quote Warning Close'" (click)="closeResultModal()">Oops, get
							me outa here!
						</button>
						<button mat-raised-button color="warn" class="dl-full-width dl-action-btn" id="qq_oops_continue"
							type="button" [disabled]="busy" [tabindex]="1" [attr.data-event]="'true'"
							[attr.data-event-category]="'Quick Quote'" [attr.data-event-name]="'Warning Continue'"
							[attr.data-event-label]="'Quick Quote Warning Continue'" (click)="postToContinue(true)">I'm
							ok with that, Continue&nbsp;
							<span *ngIf="!resLoading" class="fa fa-chevron-right"></span>
							<i class='fa fa-circle-o-notch fa-spin' *ngIf="resLoading"></i>
						</button>
					</p>
				</div>
			</div>
			<div class="dl-col-md-12 col s12">
				<p class="fine-print">*Estimate: complete the process to get an official quote. Not available
					for all occupations.</p>
			</div>
		</div>
	</mat-dialog-content>
</div>