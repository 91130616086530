var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ChangeDetectorRef, ElementRef, EventEmitter, NgZone, OnDestroy, OnInit } from '@angular/core';
import { StripeScriptService } from './stripe.script.service';
import { Log } from 'ng2-logger/client';
var log = Log.create('StripeComponent');
var StripeComponent = /** @class */ (function () {
    function StripeComponent(cd, zone, stripeScriptService) {
        var _this = this;
        this.cd = cd;
        this.zone = zone;
        this.stripeScriptService = stripeScriptService;
        this.cardHandler = this.onCardChange.bind(this);
        this.myState = { valid: false, busy: false };
        this.stateChange = new EventEmitter();
        this.stripeScriptService.injectStipeElement().then(function (res) { return _this.stripe = res; });
        this.submitSubscription = this.stripeScriptService.getSubmitTokenObservable().subscribe(function () { return _this.submitStripeControl(); });
    }
    Object.defineProperty(StripeComponent.prototype, "publishToken", {
        get: function () {
            return this._publishToken;
        },
        set: function (val) {
            this._publishToken = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StripeComponent.prototype, "postalCode", {
        get: function () {
            return this._postalCode;
        },
        set: function (val) {
            this._postalCode = val;
        },
        enumerable: true,
        configurable: true
    });
    StripeComponent.prototype.ngOnInit = function () {
        var _this = this;
        var elements;
        if (this.stripe === undefined) {
            // wait for the script to load
            setTimeout(function () { return _this.ngOnInit(); }, 500);
        }
        else {
            this.stripeInstance = this.stripe(this.publishToken);
            elements = this.stripeInstance.elements();
            if (this.postalCode !== null && this.postalCode !== undefined) {
                this.cardNumber = elements.create('card', {
                    value: {
                        postalCode: this.postalCode
                    },
                    hidePostalCode: true
                });
            }
            else {
                this.cardNumber = elements.create('card');
            }
            this.cardNumber.mount(this.cardInfo.nativeElement);
            this.cardNumber.addEventListener('change', this.cardHandler);
        }
    };
    StripeComponent.prototype.ngOnDestroy = function () {
        this.submitSubscription.unsubscribe();
        if (this.cardNumber !== null && this.cardNumber !== undefined) {
            this.cardNumber.removeEventListener('change', this.cardHandler);
            this.cardNumber.destroy();
        }
    };
    StripeComponent.prototype.onCardChange = function (error) {
        if (error !== null || error !== undefined) {
            if (error.complete && (error.error === null || error.error === undefined)) {
                this.error = null;
                this.myState.valid = true;
                this.myState.error = null;
            }
            else if (error.error !== null && error.error !== undefined) {
                this.error = error.error.message;
                this.myState.error = this.error;
                this.myState.valid = false;
            }
            else {
                this.error = '';
            }
        }
        this.cd.detectChanges();
        this.broadcastStateChanged();
    };
    StripeComponent.prototype.submitStripeControl = function () {
        var _this = this;
        this.myState.busy = true;
        this.stateChange.emit(this.myState);
        this.submit().then(function () {
            _this.broadcastStateChanged();
        });
    };
    StripeComponent.prototype.submit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, token, error;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.stripeInstance.createToken(this.cardNumber)];
                    case 1:
                        _a = _b.sent(), token = _a.token, error = _a.error;
                        if (error) {
                            log.error(error);
                            this.myState.valid = false;
                            this.myState.busy = false;
                            this.error = error.message;
                            this.myState.error = this.error;
                        }
                        else {
                            log.info('We have the token', token);
                            this.myState.valid = true;
                            this.myState.busy = false;
                            this.myState.error = null;
                            this.myState.token = token;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    StripeComponent.prototype.broadcastStateChanged = function () {
        var _this = this;
        this.stateChange.emit(this.myState);
        setTimeout(function () {
            _this.zone.run(function () {
                log.data('Do zone redraw');
            });
        });
    };
    return StripeComponent;
}());
export { StripeComponent };
