import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MatSelectChange, MatSliderChange, MAT_DIALOG_DATA } from "@angular/material";
import { isNull, isNullOrUndefined } from "util";
import { DLUtil } from "../base/dl.util";
import { minMaxValidation } from "../base/min.max.validator";
import { rsaIdValidation, RsaIdValidationCallback, RsaId } from "../base/rsa.id.validator";
import { WindowRef } from "../base/window.ref";
import { ContactBlockComponent } from "../contact-block/contact-block.component";
import { QQService } from "./qq.service";

@Component({
    selector: 'quick-quote-mlf',
    template: `
    <div class="row">
        <div class="dl-modal qq-mlf col s12">
            <mat-card>
                <mat-card-title class="title">Your Cover</mat-card-title>
                <mat-card-content>
                    <div class="row no-row-margin-bottom">
                        <div class="col s12">
                            <p style="margin-bottom: 20px;">We can offer you
                                <b>{{ cover | dl_currency:true:0 }}</b> cover for
                                <b>{{ premium | dl_currency:true:2 }}</b>&nbsp;p/m.
                            </p>
                        </div>
                    </div>
                    <div class="row no-row-margin-bottom">
                        <div class="col s12">
                            <mat-slider
                                [invert]="false"
                                [max]="maxCover"
                                [min]="minCover"
                                [step]="'5000'"
                                [thumbLabel]="true"
                                [displayWith]="formatDisplay"
                                [value]="cover"
                                (input)="updateCover($event)">
                            </mat-slider>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col s12" style="margin-top: -20px;">
                            <div class="left">{{minCover | dl_currency:true:0}}</div>
                            <div class="right">{{maxCover | dl_currency:true:0}}</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col s12"><small>Drag the slider to change the cover amount</small></div>
                    </div>
                    <div class="row">
                        <div class="col s12">
                            <table class="dl-table">
                                <thead>
                                <tr>
                                    <th class="text-left hide-on-small-and-down"></th>
                                    <th class="text-left hide-on-small-and-down">Cover Amount</th>
                                    <th class="text-right">Premium</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <ng-container *ngFor="let dependant of dependantsList; let idx = index">
                                    <tr>
                                        <td>{{dependant.type}} {{
                                            dependant.type === 'Spouse' ? '(' + spouseCount + '/1)'
                                            : dependant.type === 'Child' ? '(' + childCount + '/8)'
                                            : dependant.type === 'Spouse' ? '(' + parentsInLawsCount + '/4)' : ''
                                        }}</td>
                                        <td class="text-left hide-on-small-and-down">{{dependant.cover_amount | dl_currency:true:0}}</td>
                                        <td class="text-right">{{dependant.premium | dl_currency:true:2}}</td>
                                        <td class="text-right">
                                            <span *ngIf="idx === 0" class="hide-on-small-and-down spacer"></span>
                                            <span *ngIf="idx !== 0" class="hide-on-med-and-up"><br></span>
                                            <i *ngIf="idx !== 0" (click)="removeDependant(idx)" class="fa fa-times"></i>
                                        </td>
                                    </tr>
                                </ng-container>
                                <tfoot>
                                <tr>
                                    <td><b>Total</b></td>
                                    <td class="hide-on-small-and-down"></td>
                                    <td class="text-right"><b>{{totalPremium | dl_currency:true:2}}</b></td>
                                    <td></td>
                                </tr>
                                <tfoot>
                            </table>
                        </div>
                    </div>
                    <div class="row" *ngIf="!addDependantFlag">
                        <div class="col s12">
                            <p class="text-center">Check cover for additional lives to this policy?</p>
                        </div>
                        <div class="col s8 offset-s2 m6 offset-m3 l4 offset-l4">
                            <button class="dl-full-width"
                                mat-raised-button
                                tabindex="1"
                                (click)="addDependantFlag = true">Add</button>
                        </div>
                    </div>
                    <form [formGroup]="form" *ngIf="addDependantFlag">
                        <div class="row">
                            <div class="col s12 m4">
                                <mat-form-field class="dl-full-width">
                                    <input matInput
                                        (change)="getDependantPricing()"
                                        tabindex="1"
                                        [formControl]="form.controls['age']"
                                        placeholder="Age"
                                        [attr.data-name]="'age'"
                                    >
                                </mat-form-field>
                            </div>
                            <div class="col s12 m4">
                                <mat-form-field class="dl-full-width">
                                    <mat-select
                                        [formControl]="form.controls['relationship']"
                                        placeholder="Relationship"
                                        tabindex="1"
                                        [attr.data-name]="'relationship'"
                                        (selectionChange)="selectionChange($event)"
                                        [disabled]="!form.get('age').valid"
                                        >
                                        <mat-option value="MLF_S">Spouse</mat-option>
                                        <mat-option value="MLF_C">Child</mat-option>
                                        <mat-option value="MLF_E">Parents/In Laws</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col s12 m4">
                                <mat-radio-group (change)="getDependantPricing()" class="dl-full-width" [formControl]="form.controls['gender']" [disabled]="!form.get('relationship').valid">
                                    <mat-radio-button
                                        class="mat-button-toggle-2"
                                        color="primary"
                                        [tabIndex]="1"
                                        [value]="'M'"
                                        [attr.data-val]="'M'">Male</mat-radio-button>
                                    <mat-radio-button
                                        class="mat-button-toggle-2"
                                        color="primary"
                                        [tabIndex]="1"
                                        [value]="'F'"
                                        [attr.data-val]="'F'">Female</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div class="row no-row-margin-bottom" *ngIf="dependantOptions.length > 0">
                            <div class="col s12">
                                <p style="margin-bottom: 20px;">{{getRelationshipType}}
                                    <b>{{ coverDependant | dl_currency:true:0 }}</b> cover for
                                    <b>{{ premiumDependant | dl_currency:true:2 }}</b>&nbsp;p/m.
                                </p>
                            </div>
                        </div>
                        <div class="row no-row-margin-bottom" *ngIf="dependantOptions.length > 0">
                            <div class="col s12">
                                <mat-slider
                                    [invert]="false"
                                    [max]="maxCoverDependant"
                                    [min]="minCoverDependant"
                                    [step]="'5000'"
                                    [thumbLabel]="true"
                                    [displayWith]="formatDisplay"
                                    [value]="coverDependant"
                                    (input)="updateCoverDependant($event)">
                                </mat-slider>
                            </div>
                        </div>
                        <div class="row" *ngIf="dependantOptions.length > 0">
                            <div class="col s12"><small>Drag slider to change the cover amount</small></div>
                        </div>
                        <div class="row">
                            <div class="col s12 m3" *ngIf="dependantOptions.length > 0">
                                <button
                                    class="dl-full-width"
                                    (click)="addDependant()"
                                    mat-raised-button
                                    color="primary"
                                    tabindex="1"
                                    [attr.data-name]="'save'"
                                    [disabled]="(relationshipType === 'Spouse' && spouseCount === 1) || (relationshipType === 'Child' && childCount === 8) || (relationshipType === 'Parents/In Laws' && parentsInLawsCount === 4)"
                                    ><i class="fa fa-check"> Add</i></button>
                            </div>
                            <div class="col s12 m3">
                                <button
                                    class="dl-full-width"
                                    (click)="addDependantFlag = false"
                                    mat-raised-button
                                    color="primary"
                                    tabindex="1"
                                    [attr.data-name]="'save'"
                                    ><i class="fa fa-times"> Cancel</i></button>
                            </div>
                        </div>
                    </form>
                </mat-card-content>
                <div class="dl-modal-footer" *ngIf="qqMlfFooterFlag">
                    <div class="dl-container-no-padding">
                        <div class="row">
                            <div class="col m4 s12">
                            <button
                                (click)="contact()"
                                mat-raised-button
                                color="primary"
                                class="dl-action-btn dl-full-width"
                                tabindex="1"
                                >Call me</button>
                            </div>
                            <div class="col m4 s12"></div>
                            <div class="col m4 s12">
                                <button
                                    (click)="continue()"
                                    mat-raised-button
                                    color="primary"
                                    class="dl-action-btn dl-full-width"
                                    tabindex="1"
                                    [attr.data-name]="'save'"
                                    >Continue&nbsp;<i *ngIf="isLoading" class="fa fa-circle-o-notch fa-spin"></i></button>
                            </div>
                        </div>
                    </div>
                    <p class="fine-print">*Estimate: complete the process to get an official quote.</p>
                </div>
            </mat-card>
        </div>
    </div>
    `,
    styleUrls: ['./qq-mlf.component.scss']
})
export class QqMlfComponent implements OnInit, RsaIdValidationCallback {
    form: FormGroup;
    addDependantFlag: boolean = false;
    cover: number = 0;
    premium: number  = 0;
    isLoading: boolean = false;
    dependantsList: { type: string, cover_amount: number, premium: number }[] = [];
    relationshipType: string = '';
    dependantOptions: any = [];
    coverDependant: number = 0;
    premiumDependant: number = 0;
    spouseCount: number = 0;
    childCount: number = 0;
    parentsInLawsCount: number = 0;
    qqMlfFooterFlag: boolean = true;
    originalDependentOptions = this.dependantOptions;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, private readonly winRef: WindowRef, private readonly fb: FormBuilder, private readonly qqService: QQService) {
    }

    ngOnInit(): void {
        if (!isNullOrUndefined(this.data.pageInfo)) {
            if (this.data.pageInfo.type === 'mlf' && this.data.pageInfo.name === 'qq-mlf') {
                this.qqMlfFooterFlag = false;
            }
        }

        this.cover = this.data.pricingResponse.options[0].cover;
        this.premium = this.data.pricingResponse.options[0].premium;

        this.dependantsList.push({
            type: 'Main Member',
            cover_amount: this.data.pricingResponse.options[0].cover,
            premium: this.data.pricingResponse.options[0].premium
        })

        this.form = this.fb.group({
            age: ['', [Validators.pattern('[0-9]*'), Validators.required]],
            relationship: ['', Validators.required],
            gender: ['', Validators.required],
        });

        this.form.get('relationship').valueChanges.subscribe(relationship => {
                if (relationship === 'MLF_C') {
                    this.form.get('age').clearValidators();
                    this.form.get('age').setValidators([Validators.pattern('[0-9]*'), Validators.required, minMaxValidation(0, 64)])
                } else if (relationship === 'MLF_S') {
                    this.form.get('age').clearValidators();
                    this.form.get('age').setValidators([Validators.pattern('[0-9]*'), Validators.required, minMaxValidation(18, 64)])
                } else {
                    this.form.get('age').clearValidators();
                    this.form.get('age').setValidators([Validators.pattern('[0-9]*'), Validators.required, minMaxValidation(18, 75)])
                }
            });
    }

    get minCover(): number {
        return this.data.pricingResponse.options[0].cover;
    }

    get maxCover(): number {
        if (this.dependantOptions.length > 0) {
            const maxCover: number = this.maxCoverDependant;
    
            if (this.coverDependant > maxCover) {
                this.coverDependant = maxCover;
                this.dependantOptions.forEach(dependentOption => {
                    if (dependentOption.cover === maxCover) {
                        this.premiumDependant = dependentOption.premium;
                    }
                });
            }
        }

        return this.data.pricingResponse.options[this.data.pricingResponse.options.length - 1].cover;
    }

    get minCoverDependant(): number {
        return this.dependantOptions[0].cover;
    }

    get maxCoverDependant(): number {
        if (this.relationshipType === 'Spouse') {
            this.dependantOptions = this.originalDependentOptions.filter((data) => {
                return data.cover <= this.dependantsList[0].cover_amount;
            })
        }
        
        return this.dependantOptions[this.dependantOptions.length - 1].cover;
    }

    get increments(): number {
        return 5000;
    }

    get getRelationshipType(): string {
        return this.relationshipType;
    }

    get totalPremium(): number {
        let total = 0;
        if (!isNullOrUndefined(this.dependantsList)) {
            this.dependantsList.forEach((d) => {
                total += d.premium;
            });
        }
        return total;
    }

    formatDisplay(value: number | null): string {
        return DLUtil.compactFormat(value, false);
    }
    
    updateCover(val: MatSliderChange): void {
        this.data.pricingResponse.options.forEach((opt) => {
            if (val.value === opt.cover) {
                this.cover = opt.cover;
                this.premium = opt.premium;

                this.dependantsList[0].cover_amount = opt.cover;
                this.dependantsList[0].premium = opt.premium;
            }
        });
    }

    updateCoverDependant(val: MatSliderChange): void {
        this.dependantOptions.forEach((opt) => {
            if (val.value === opt.cover) {
                this.coverDependant = opt.cover;
                this.premiumDependant = opt.premium;
            }
        });
    }

    continue() {
        setTimeout(() => {
            this.winRef.nativeWindow.location = '/buy/funeral';
        }, 50);
    }

    addDependant() {
        const result = this.dependantsList.filter((dependent) => dependent.type === this.relationshipType);

        switch (this.relationshipType) {
            case 'Spouse': {
                if (result.length === 1) {
                    console.log('ERROR - Only allowed 1 spouse');
                } else {
                    this.dependantsList.push({ 
                        type: this.relationshipType,
                        cover_amount: this.coverDependant, // Options - Cover
                        premium: this.premiumDependant // Options - Premium
                    });

                    this.spouseCount++;
                }
            }
                break;
            case 'Child': {
                if (result.length === 8) {
                    console.log('ERROR - Only allowed 8 children');
                } else {
                    this.dependantsList.push({ 
                        type: this.relationshipType,
                        cover_amount: this.coverDependant, // Options - Cover
                        premium: this.premiumDependant // Options - Premium
                    });

                    this.childCount++;
                }
            }
                break;
            case 'Parents/In Laws': {
                if (result.length === 4) {
                    console.log('ERROR - Only allowed 8 Externals');
                } else {
                    this.dependantsList.push({ 
                        type: this.relationshipType,
                        cover_amount: this.coverDependant, // Options - Cover
                        premium: this.premiumDependant // Options - Premium
                    });

                    this.parentsInLawsCount++;
                }
            }
                break;
            default:
                break;
        }

        this.form.reset();
        this.dependantOptions = [];
    }

    removeDependant(index: number) {
        if (!isNullOrUndefined(this.dependantsList[index])) {
            delete this.dependantsList[index];
            this.dependantsList = this.dependantsList.filter((dependent) => !isNullOrUndefined(dependent));

            switch (this.relationshipType) {
                case 'Spouse': this.spouseCount--;
                    break;
                case 'Child': this.childCount--;
                    break;
                case 'Parents/In Laws': this.parentsInLawsCount--;
                    break;
                default:
                    break;
            }
        }
    }

    hideAddDependantForm() {
        this.addDependantFlag = true;
    }

    getDependantPricing() {
        if (this.form.get('age').valid && this.form.get('relationship').valid && this.form.get('gender').valid) {
            this.qqService.getMlfPricing({
                age: Number(this.form.get('age').value),
                gender: this.form.get('gender').value,
                assumed_income: 0,
                product_group: 'SG_MLF',
                product_code: this.form.get('relationship').value,
                main_member_info: this.data.mainMemberInformation
            }).subscribe(
                (response: any) => {
                    this.coverDependant = response.options[0].cover;
                    this.premiumDependant = response.options[0].premium;
                    this.dependantOptions = response.options;
                    this.originalDependentOptions = response.options;
                    console.log(response)
                }
            );
        }
    }

    onIdValidationChange(rsaId: RsaId): void {
        
    }

    selectionChange(event: MatSelectChange) {
        this.relationshipType = event.source.triggerValue;
        if (this.form.get('gender').valid) {
            this.getDependantPricing();
        }
    }

    contact() {
        this.dialog.open(ContactBlockComponent,
            {   
                disableClose: false,
                panelClass: 'dl-modal',
                maxWidth: '99vw',
            }
        );
    }
}