import {Injectable} from '@angular/core';
import {BaseService} from '../base/base.service';
import {HttpClient} from '@angular/common/http';
import {ErrorModalProvider} from '../base/error/error.modal.service';
import {catchError, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {SuccessVO} from '../sp/common.vos';
import {isNullOrUndefined} from 'util';
import {Log} from 'ng2-logger/browser';

const log = Log.create('dl-login-service');

@Injectable({
    providedIn: 'root'
})
export class LoginService extends BaseService {

    constructor(
        protected httpClient: HttpClient,
        protected errorModalProvider: ErrorModalProvider
    ) {
        super(httpClient, errorModalProvider);
    }

    public setPassword(data: LoginVO): Observable<SuccessVO> {
        let url = '/dl/data/setPassword';
        return this.httpClient.post<SuccessVO>(url, data, this.httpOptions()).pipe(
            catchError(this.handleHTTPError<any>())
        );
    }

    public checkPhone(phone: string, leadId: string, salesProcessId: string): Observable<SuccessVO> {
        let url = '/dl/data/checkLeadCell';
        let data: any = {
            sales_process_id: salesProcessId,
            lead_id: leadId,
            cell: phone
        };
        return this.httpClient.post(url, data, this.httpOptions()).pipe(
            catchError(this.handleHTTPError<any>())
        );
    }

    public checkDob(dob: string, leadId: string, salesProcessId: string): Observable<SuccessVO> {
        let url = '/dl/data/checkLeadDob';
        let data: any = {
            sales_process_id: salesProcessId,
            lead_id: leadId,
            dob: dob
        };
        return this.httpClient.post(url, data, this.httpOptions()).pipe(
            catchError(this.handleHTTPError<any>())
        );
    }

    public dlLoginCheck(baseUrl: string, oauthId: string, ssoUserId?: string): Observable<LoginResponse> {
        let url = baseUrl + '/api/dfuser/is_logged_in/?client_id=' + oauthId;
        if (!isNullOrUndefined(ssoUserId) && ssoUserId !== '') {
            url += '&user_login=' + ssoUserId;
        }
        return this.httpClient.get<LoginResponse>(url, this.httpOptions(true)).pipe(
            catchError(this.handleHTTPError<LoginResponse>())
        );
    }

    public checkUser(userName: string, password: string, idNumber?: string): Observable<LoginResponse> {
        let data: any = {
            username: userName,
            password: password,
        };
        if (!isNullOrUndefined(idNumber)) {
           data.id_number = idNumber;
        }
        return this.httpClient.post<LoginResponse>('/dl/data/login', data, this.httpOptions()).pipe(
            catchError(this.handleHTTPError<any>())
        );
    }

    public oauthCheck(data: LoginResponse): Observable<LoginResponse> {
        delete data.id;
        return this.httpClient.post<LoginResponse>('/dl/data/login', data, this.httpOptions()).pipe(
            catchError(this.handleHTTPError<any>())
        );

    }

    public loginToOrg(orgBaseUrl: string,
                      clientId: string,
                      ssoUserName: string,
                      passWord: string,
                      signInProcessor: SignInProcessor): Observable<LoginResponse> {
        const url = orgBaseUrl + '/sso.php';
        const data: any = {
            op: 'userpass',
            username: ssoUserName,
            password: passWord,
            client_id: clientId
        };
        return this.httpClient.post<LoginResponse>(url, data, this.httpOptions(true)).pipe(
            tap((orgRes) => {
                this.handleOrgLoginResult(orgRes, ssoUserName, signInProcessor, orgBaseUrl, clientId);
            })
        );
    }

    public logoutOrg(baseOrgUrl: string): Observable<LoginResponse> {
        let url = baseOrgUrl + '/api/dfuser/logout/';
        return this.httpClient.get<LoginResponse>(url, this.httpOptions(true)).pipe(
            catchError(this.handleHTTPError<LoginResponse>())
        );
    }

    public logout(signInProcessor?: SignInProcessor, orgUrl?: string): Observable<LoginResponse> {
        log.info('logout life');
        return this.httpClient.post<LoginResponse>('/dl/data/logout', null, this.httpOptions()).pipe(
            tap(() => {
                if (!isNullOrUndefined(orgUrl)) {
                    // log out of org
                    log.info('logout org');
                    this.logoutOrg(orgUrl).subscribe(() => {
                        if (!isNullOrUndefined(signInProcessor)) {
                            signInProcessor.processSignIn(false);
                        }
                    });
                }
            }),
            catchError(this.handleHTTPError<LoginResponse>())
        );
    }

    public loginCheck(signInProcessor: SignInProcessor, userName: string, orgUrl?: string, oauthClient?: string): void {
        if (!isNullOrUndefined(oauthClient) && orgUrl.length > 1) {
            this.doDlLoginCheck(orgUrl, oauthClient, userName, signInProcessor);
        } else {
            this.doSgLoginCheck(signInProcessor);
        }
    }

    /**
     * Check if a user is logged in all ready.
     * If so redirect to the correct page determined by the next action.
     */
    private doDlLoginCheck(orgUrl: string, oauthClientId: string, ssoUserName: string, signInProcessor: SignInProcessor) {
        this.dlLoginCheck(orgUrl, oauthClientId).subscribe(res => {
            if (!isNullOrUndefined(res.logged_in)) {
                if (this.isTrue(res.logged_in)) {
                    // Logged in we need to do the oauth dance.
                    // this response should return sso_user_id
                    this.processOAuthCode(true, res, ssoUserName, orgUrl, signInProcessor);
                    return;
                }
            } else {
                log.info('res.logged_in is null or undefined');
            }
            signInProcessor.processSignIn(false, 'Login check failed');
        }, (error) => {
            log.info('dlLoginCheck error');
            signInProcessor.processSignIn(false, 'Login check error');
        });
    }

    private doSgLoginCheck(signInProcessor: SignInProcessor) {
        signInProcessor.processSignIn(false);
    }

    private isTrue(data: string|boolean): boolean {
        return (
            (typeof data === 'string' && data === 'true') ||
            (typeof data === 'boolean' && data === true)
        );
    }

    private processOAuthCode(fromCheck: boolean, data: LoginResponse, ssoUserName, orgUrl: string, signInProcessor: SignInProcessor) {
        if (fromCheck && this.isTrue(data.logged_in) && !isNullOrUndefined(ssoUserName)) {
            let mustLogout = false;
            if (ssoUserName.indexOf('@') > 1) {
                if (isNullOrUndefined(data.email) || ssoUserName !== data.email) {
                    log.info('login check differing users: ' + ssoUserName + ' != ' + data.email);
                    mustLogout = true;
                }
            } else {
                if (ssoUserName !== data.login) {
                    log.info('login check differing users: ' + ssoUserName + ' != ' + data.login);
                    mustLogout = true;
                }
            }
            if (mustLogout) {
                // need to do a Logout and process
                this.logout(signInProcessor, orgUrl).subscribe(() => log.info('auto logged out'));
                return;
            }
        }

        if (!isNullOrUndefined(data.code)) {
            this.oauthCheck(data).subscribe(
                (res) => {
                    if (!isNullOrUndefined(res.error_detail)
                        && !isNullOrUndefined(res.error_detail.error)
                        && res.error_detail.error === 'invalid_grant') {
                        signInProcessor.processSignIn(false, 'Login timeout, please try again');
                        return;
                    }
                    if (!isNullOrUndefined(res.differentuser) && res.differentuser) {
                        log.info('oauth check differing users: ' + ssoUserName + ' != ' + data.login);
                        this.logout(signInProcessor, orgUrl).subscribe(() => log.info('auto logged out'));
                        return;
                    }
                    signInProcessor.processSignIn(true);
                },
                () => signInProcessor.processSignIn(false, 'Error communicating to the login server, please try again')
            );
        }
    }

    private handleOrgLoginResult(res: LoginResponse,
                                 ssoUserName: string,
                                 signInProcessor: SignInProcessor,
                                 orgUrl: string,
                                 oauthClientId: string) {
        if (isNullOrUndefined(res.message) && typeof res.Error !== 'undefined') {
            signInProcessor.processSignIn(false, 'Sorry there was an error on our server');
            return;
        }
        if (!isNullOrUndefined(res.code) && res.login) {
            res.logged_in = true;
            this.processOAuthCode(false, res, ssoUserName, orgUrl, signInProcessor);
        } else if (res.loggedin) {
            this.loginCheck(signInProcessor, ssoUserName, orgUrl, oauthClientId);
        } else {
            log.error('signinToOrg error (1)');
            log.error(JSON.stringify(res));
            let errorMsg = 'Failed to login, ';
            if (!isNullOrUndefined(res.message)) {
                errorMsg += res.message;
            } else {
                errorMsg += 'please check your details and try again';
            }
            signInProcessor.processSignIn(false, errorMsg);
        }
    }

    public getLoginActionForSP(spId: string): Observable<LoginAction> {
        const url = '/dl/data/sp/' + spId + '/web/login';
        return this.httpClient.get<LoginAction>(url, this.httpOptions()).pipe(
            catchError(this.handleHTTPError<LoginAction>())
        );
    }

}

export interface SignInProcessor {
    processSignIn(success: boolean, message?: string): void;
}

export interface LoginVO {
    action: string;
    sales_process_id: string;
    idnumber?: string;
    username?: string;
    password: string;
    token?: string;
}

export class ErrorVO {
    error: string;
}

export class LoginResponse {
    id?: string;
    code?: string;
    // slight difference between org and life
    login?: string;
    sso_user_login?: string;
    // slight difference between org and life
    loggedin?: boolean;
    logged_in?: boolean|string;
    known_as?: string;
    email?: string;
    first_name?: string;
    last_name?: string;
    display_name?: string;
    requires_id_number?: boolean;
    askID?: boolean;
    message?: string;
    errorMsg?: string;
    Error?: string;
    error_detail?: ErrorVO;
    differentuser?: boolean;
}

export interface LoginAction {
    payload: {
        web_step: string;
        login_action: string;
        username?: string;
        next_action?: string;
        lead_id?: string;
    };
}
