import {Injectable} from '@angular/core';
import {SpBaseService} from '../sp.service';
import {Observable} from 'rxjs';
import {PageDataVO, SpDataService} from '../sp.data.service';
import {catchError} from 'rxjs/operators';
import {isNullOrUndefined} from 'util';
import {BasePayLoad, SuccessVO} from '../common.vos';
import {OverrideVO} from './override/admin.override.service';
import {SpDisclosuresProvider} from '../sp.disclosures.provider.service';
import {HttpClient} from '@angular/common/http';
import {ErrorModalProvider} from '../../base/error/error.modal.service';
import {BankDetail} from '../../base/banking/dl.banking.vo';
import {Beneficiary} from '../../base/beneficiary/dl.beneficiary.vo';

export class BenefitSummary {
    id: string;
    type: string;
    name: string;
    code: string;
    amount: number;
    premium: number;
    premium_escalation: number;
    benefit_escalation: number;
    discount?: number;
}

export class PaymentSummary extends BasePayLoad {
    premium = 0;
    benefits: BenefitSummary[] = null;
    showBeneficiaries = true;
    beneficiariesBenefitId?: string;
    beneficiaries: Beneficiary[] = [];
    bankingDetail: BankDetail = null;
    existingCover: boolean;
    policyId: string;
    contactId: string;
    overrideApproved: boolean;
    overrideData: OverrideVO;

    static copyForSubmission(from: PaymentSummary): PaymentSummary {
        const to: PaymentSummary = new PaymentSummary();
        to.premium = from.premium;
        to.benefits = from.benefits;
        if (!isNullOrUndefined(from.beneficiaries) && from.beneficiaries.length > 0) {
            to.beneficiaries = from.beneficiaries;
        }
        to.bankingDetail = BankDetail.copyMe(from.bankingDetail);
        to.policyId = from.policyId;
        to.contactId = from.contactId;
        return to;
    }
}

@Injectable({
    providedIn: 'root'
})
export class PaymentService extends SpBaseService {

    constructor(
        httpClient: HttpClient,
        spDataService: SpDataService,
        errorModalProvider: ErrorModalProvider,
        disclosuresProvider: SpDisclosuresProvider
    ) {
        super(httpClient, spDataService, errorModalProvider, disclosuresProvider);
    }

    public savePayment(spId: string, data: PaymentSummary): Observable<PageDataVO> {
        const url = '/dl/data/sp/' + spId + '/payment';
        return this.httpClient.post<PageDataVO>(url, PaymentSummary.copyForSubmission(data), this.httpOptions()).pipe(
            catchError(this.handleHTTPError<PageDataVO>()));
    }

    public saveBeneficiaries(spId: string, benefitId: string, beneficiaries: Beneficiary[]): Observable<Beneficiary[]> {
        const url = '/dl/data/sp/' + spId + '/payment/benefit/' + benefitId + '/beneficiaries';
        return this.httpClient.post<Beneficiary[]>(url, beneficiaries, this.httpOptions()).pipe(
            catchError(this.handleHTTPError<Beneficiary[]>()));
    }

    public saveBanking(spId: string, banking: BankDetail, policyId: string): Observable<SuccessVO> {
        const url = '/dl/data/sp/' + spId + '/payment/banking/' + policyId;
        return this.httpClient.post<SuccessVO>(url, banking, this.httpOptions()).pipe(
            catchError(this.handleHTTPError<SuccessVO>()));
    }

    public activatePolicy(spId: string, data: PaymentSummary): Observable<SuccessVO> {
        const url = '/dl/data/sp/' + spId + '/payment/policy-activate';
        const dataToSend: any = {
            policyId: data.policyId,
            contactId: data.contactId
        };
        return this.httpClient.post<SuccessVO>(url, dataToSend, this.httpOptions()).pipe(
            catchError(this.handleHTTPError<SuccessVO>()));
    }

    public updateTheCaseForWebAvsInvalid(spId: string) {
        const url = '/dl/data/sp/case/webAvsFailed/' + spId;
        return this.httpClient.post<SuccessVO>(url, {}, this.httpOptions()).pipe(
            catchError(this.handleHTTPError<SuccessVO>())).toPromise();
    }
}
