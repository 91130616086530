import {Component, OnInit} from '@angular/core';
import {PageComponentVO, ProductValuesVO} from '../page.data.vo';
import {Dynamic} from './component';
import {isNullOrUndefined} from 'util';
import {FormControl} from '@angular/forms';
import { Log } from 'ng2-logger/client';

const log = Log.create('ProductComponent');

/**
 * Created by corneliusbotha on 2017/07/20.
 */
@Component({
    selector: 'product-input',
    templateUrl: 'product.component.html'
})
export class ProductComponent extends Dynamic<PageComponentVO> implements OnInit {
    index: number;

    onTabEvent(event: any): void {
        this.index = event.index;
        this.setSelected(event.index);
        this.checkValidity();
    }

    setSelected(selectedIndex: number) {
        let products: ProductValuesVO[] = this.component.extraInput as ProductValuesVO[];
        for (let i = 0; i < products.length; i++) {
            let product = products[i] as ProductValuesVO;
            if (selectedIndex === i) {
                this.component.value = product;
            }
            product.selected = selectedIndex === i;
        }
    }

    ngOnInit(): void {
    }

    private checkValidity(): void {
        if (!isNullOrUndefined(this.component.value) && this.component.value !== '') {
            log.info('Checking validity', this.component.value);
            let product: ProductValuesVO = this.component.value as ProductValuesVO;
            this.component.isValid = product.detail[0].amountVal > 0;
        } else {
            this.component.isValid = false;
        }
        if (!isNullOrUndefined(this.change)) {
            this.change.emit(this.component.counter);
        }
    }

    getFormControl(): FormControl {
        return null;
    }

    onChange(): void {
    }

    setupFromControl(): any {
    }
}


