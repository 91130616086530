/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./accordion";
import * as i2 from "@angular/common";
import * as i3 from "./shared";
var AccordionModuleNgFactory = i0.ɵcmf(i1.AccordionModule, [], function (_l) { return i0.ɵmod([i0.ɵmpd(512, i0.ComponentFactoryResolver, i0.ɵCodegenComponentFactoryResolver, [[8, []], [3, i0.ComponentFactoryResolver], i0.NgModuleRef]), i0.ɵmpd(4608, i2.NgLocalization, i2.NgLocaleLocalization, [i0.LOCALE_ID, [2, i2.ɵangular_packages_common_common_a]]), i0.ɵmpd(1073742336, i2.CommonModule, i2.CommonModule, []), i0.ɵmpd(1073742336, i3.SharedModule, i3.SharedModule, []), i0.ɵmpd(1073742336, i1.AccordionModule, i1.AccordionModule, [])]); });
export { AccordionModuleNgFactory as AccordionModuleNgFactory };
var styles_AccordionTab = [];
var RenderType_AccordionTab = i0.ɵcrt({ encapsulation: 2, styles: styles_AccordionTab, data: { "animation": [{ type: 7, name: "tabContent", definitions: [{ type: 0, name: "hidden", styles: { type: 6, styles: { height: "0" }, offset: null }, options: undefined }, { type: 0, name: "visible", styles: { type: 6, styles: { height: "*" }, offset: null }, options: undefined }, { type: 1, expr: "visible <=> hidden", animation: { type: 4, styles: null, timings: "400ms cubic-bezier(0.86, 0, 0.07, 1)" }, options: null }], options: {} }] } });
export { RenderType_AccordionTab as RenderType_AccordionTab };
function View_AccordionTab_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "ui-accordion-header-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.header; _ck(_v, 1, 0, currVal_0); }); }
function View_AccordionTab_2(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0), (_l()(), i0.ɵand(0, null, null, 0))], null, null); }
function View_AccordionTab_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "ui-accordion-content ui-widget-content"]], null, null, null, null, null)), i0.ɵncd(null, 1)], null, null); }
export function View_AccordionTab_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "ui-accordion-header ui-state-default ui-corner-all"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i2.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(2, { "ui-state-active": 0, "ui-state-disabled": 1 }), (_l()(), i0.ɵeld(3, 0, null, null, 6, "a", [["href", "#"], ["role", "tab"]], [[1, "id", 0], [1, "aria-controls", 0], [1, "aria-expanded", 0]], [[null, "click"], [null, "keydown.space"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.space" === en)) {
        var pd_1 = (_co.toggle($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["class", "ui-accordion-toggle-icon"]], null, null, null, null, null)), i0.ɵdid(5, 278528, null, 0, i2.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AccordionTab_1)), i0.ɵdid(7, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AccordionTab_2)), i0.ɵdid(9, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 4, "div", [["class", "ui-accordion-content-wrapper"], ["role", "region"]], [[1, "id", 0], [24, "@tabContent", 0], [1, "aria-hidden", 0], [1, "aria-labelledby", 0]], [[null, "@tabContent.done"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("@tabContent.done" === en)) {
        var pd_0 = (_co.onToggleDone($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(11, 278528, null, 0, i2.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(12, { "ui-accordion-content-wrapper-overflown": 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AccordionTab_3)), i0.ɵdid(14, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "ui-accordion-header ui-state-default ui-corner-all"; var currVal_1 = _ck(_v, 2, 0, _co.selected, _co.disabled); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_5 = "ui-accordion-toggle-icon"; var currVal_6 = (_co.selected ? _co.accordion.collapseIcon : _co.accordion.expandIcon); _ck(_v, 5, 0, currVal_5, currVal_6); var currVal_7 = !_co.hasHeaderFacet; _ck(_v, 7, 0, currVal_7); var currVal_8 = _co.hasHeaderFacet; _ck(_v, 9, 0, currVal_8); var currVal_13 = "ui-accordion-content-wrapper"; var currVal_14 = _ck(_v, 12, 0, (!_co.selected || _co.animating)); _ck(_v, 11, 0, currVal_13, currVal_14); var currVal_15 = (_co.lazy ? _co.selected : true); _ck(_v, 14, 0, currVal_15); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.id; var currVal_3 = (_co.id + "-content"); var currVal_4 = _co.selected; _ck(_v, 3, 0, currVal_2, currVal_3, currVal_4); var currVal_9 = (_co.id + "-content"); var currVal_10 = (_co.selected ? "visible" : "hidden"); var currVal_11 = !_co.selected; var currVal_12 = _co.id; _ck(_v, 10, 0, currVal_9, currVal_10, currVal_11, currVal_12); }); }
export function View_AccordionTab_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "p-accordionTab", [], null, null, null, View_AccordionTab_0, RenderType_AccordionTab)), i0.ɵdid(1, 180224, null, 1, i1.AccordionTab, [i1.Accordion], null, null), i0.ɵqud(603979776, 1, { headerFacet: 1 })], null, null); }
var AccordionTabNgFactory = i0.ɵccf("p-accordionTab", i1.AccordionTab, View_AccordionTab_Host_0, { header: "header", selected: "selected", disabled: "disabled" }, { selectedChange: "selectedChange" }, ["p-header", "*"]);
export { AccordionTabNgFactory as AccordionTabNgFactory };
var styles_Accordion = [];
var RenderType_Accordion = i0.ɵcrt({ encapsulation: 2, styles: styles_Accordion, data: {} });
export { RenderType_Accordion as RenderType_Accordion };
export function View_Accordion_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["role", "presentation"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i2.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵdid(2, 278528, null, 0, i2.NgStyle, [i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i0.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.styleClass; var currVal_1 = "ui-accordion ui-widget ui-helper-reset"; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.style; _ck(_v, 2, 0, currVal_2); }, null); }
export function View_Accordion_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "p-accordion", [], null, null, null, View_Accordion_0, RenderType_Accordion)), i0.ɵdid(1, 1228800, null, 1, i1.Accordion, [i0.ElementRef, i0.ChangeDetectorRef], null, null), i0.ɵqud(603979776, 1, { tabList: 1 })], null, null); }
var AccordionNgFactory = i0.ɵccf("p-accordion", i1.Accordion, View_Accordion_Host_0, { multiple: "multiple", style: "style", styleClass: "styleClass", expandIcon: "expandIcon", collapseIcon: "collapseIcon", lazy: "lazy", activeIndex: "activeIndex" }, { onClose: "onClose", onOpen: "onOpen" }, ["*"]);
export { AccordionNgFactory as AccordionNgFactory };
