/**
 * The Disclosures module
 */

import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DisclosuresComponent} from "./disclosures.component";
import {DisclosuresModalComponent} from './disclosures-modal.component';
import {ErrorModule} from '../error/error.module';
import {MatButtonModule, MatCheckboxModule, MatDialogModule, MatRadioModule} from '@angular/material';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatDialogModule,
        MatButtonModule,
        MatRadioModule,
        MatCheckboxModule,
        ErrorModule,
        BrowserAnimationsModule
    ],
    declarations: [
        DisclosuresComponent,
        DisclosuresModalComponent,
    ],
    entryComponents: [
        DisclosuresModalComponent
    ],
    exports: [
        DisclosuresComponent
    ]
})
export class DisclosuresModule {}
