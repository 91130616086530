var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Dynamic } from './component';
import { isNullOrUndefined } from 'util';
import { FormControl, Validators } from '@angular/forms';
var NumberComponent = /** @class */ (function (_super) {
    __extends(NumberComponent, _super);
    function NumberComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.textControl = new FormControl('');
        _this.min = undefined;
        _this.max = undefined;
        _this.validationMessage = undefined;
        return _this;
    }
    NumberComponent.prototype.onChange = function () {
        this.component.value = this.textControl.value;
        this.component.isValid = this.textControl.valid;
        if (!isNullOrUndefined(this.change)) {
            this.change.emit(this.component.counter);
        }
    };
    NumberComponent.prototype.getFormControl = function () {
        if (isNullOrUndefined(this.component.required) || this.component.required) {
            this.textControl.setValidators([Validators.required]);
        }
        return this.textControl;
    };
    NumberComponent.prototype.processExtraData = function () {
        var extraInput = this.component.extraInput;
        var validators = [];
        if (isNullOrUndefined(this.component.required) || this.component.required) {
            validators.push(Validators.required);
        }
        if (!isNullOrUndefined(extraInput)) {
            if (!isNullOrUndefined(extraInput.min)) {
                this.min = extraInput.min;
                validators.push(Validators.min(this.min));
            }
            if (!isNullOrUndefined(extraInput.max)) {
                this.max = extraInput.max;
                validators.push(Validators.max(this.max));
            }
        }
        this.textControl.setValidators(validators);
        this.validationMessage = this.buildValidationMessage();
    };
    NumberComponent.prototype.buildValidationMessage = function () {
        if (this.min === undefined && this.max === undefined) {
            return this.component.name + ' is required';
        }
        else if (this.min === undefined) {
            return this.component.name + ' must be ' + this.max + ' or less';
        }
        else if (this.max === undefined) {
            return this.component.name + ' must be ' + this.min + ' or more';
        }
        else {
            return this.component.name + ' must be between ' + this.min + ' and ' + this.max;
        }
    };
    NumberComponent.prototype.setInitialValue = function () {
        if (!isNullOrUndefined(this.getFormControl()) && !isNullOrUndefined(this.component.initValue) && this.component.initValue !== '') {
            this.getFormControl().setValue(parseInt(this.component.initValue, 10));
        }
    };
    return NumberComponent;
}(Dynamic));
export { NumberComponent };
