/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error.modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/expansion/typings/index.ngfactory";
import * as i3 from "@angular/material/expansion";
import * as i4 from "@angular/cdk/collections";
import * as i5 from "@angular/common";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/material/dialog";
import * as i8 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i9 from "@angular/material/button";
import * as i10 from "@angular/cdk/platform";
import * as i11 from "@angular/platform-browser/animations";
import * as i12 from "./error.modal.component";
var styles_ErrorModalDialog = [i0.styles];
var RenderType_ErrorModalDialog = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorModalDialog, data: {} });
export { RenderType_ErrorModalDialog as RenderType_ErrorModalDialog };
function View_ErrorModalDialog_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 13, "mat-expansion-panel", [["class", "mat-expansion-panel"], ["style", "margin-top: 15px;"]], [[2, "mat-expanded", null], [2, "mat-expansion-panel-spacing", null]], null, null, i2.View_MatExpansionPanel_0, i2.RenderType_MatExpansionPanel)), i1.ɵdid(1, 1753088, null, 1, i3.MatExpansionPanel, [[3, i3.MAT_ACCORDION], i1.ChangeDetectorRef, i4.UniqueSelectionDispatcher, i1.ViewContainerRef, i5.DOCUMENT], null, null), i1.ɵqud(335544320, 1, { _lazyContent: 0 }), i1.ɵprd(256, null, i3.MAT_ACCORDION, undefined, []), (_l()(), i1.ɵeld(4, 0, null, 0, 6, "mat-expansion-panel-header", [["class", "mat-expansion-panel-header"], ["role", "button"]], [[1, "id", 0], [1, "tabindex", 0], [1, "aria-controls", 0], [1, "aria-expanded", 0], [1, "aria-disabled", 0], [2, "mat-expanded", null], [40, "@expansionHeight", 0]], [[null, "click"], [null, "keydown"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5)._toggle() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5)._keydown($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MatExpansionPanelHeader_0, i2.RenderType_MatExpansionPanelHeader)), i1.ɵdid(5, 180224, null, 0, i3.MatExpansionPanelHeader, [i3.MatExpansionPanel, i1.ElementRef, i6.FocusMonitor, i1.ChangeDetectorRef], null, null), i1.ɵpod(6, { collapsedHeight: 0, expandedHeight: 1 }), i1.ɵpod(7, { value: 0, params: 1 }), (_l()(), i1.ɵeld(8, 0, null, 0, 2, "mat-panel-title", [["class", "mat-expansion-panel-header-title"]], null, null, null, null, null)), i1.ɵdid(9, 16384, null, 0, i3.MatExpansionPanelTitle, [], null, null), (_l()(), i1.ɵted(-1, null, ["Technical Detail"])), (_l()(), i1.ɵeld(11, 0, null, 1, 2, "pre", [], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), i1.ɵpid(0, i5.JsonPipe, []), (_l()(), i1.ɵand(0, null, null, 0))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).expanded; var currVal_1 = i1.ɵnov(_v, 1)._hasSpacing(); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 5).panel._headerId; var currVal_3 = (i1.ɵnov(_v, 5).disabled ? (0 - 1) : 0); var currVal_4 = i1.ɵnov(_v, 5)._getPanelId(); var currVal_5 = i1.ɵnov(_v, 5)._isExpanded(); var currVal_6 = i1.ɵnov(_v, 5).panel.disabled; var currVal_7 = i1.ɵnov(_v, 5)._isExpanded(); var currVal_8 = _ck(_v, 7, 0, i1.ɵnov(_v, 5)._getExpandedState(), _ck(_v, 6, 0, i1.ɵnov(_v, 5).collapsedHeight, i1.ɵnov(_v, 5).expandedHeight)); _ck(_v, 4, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_9 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform(_co.error.detail)); _ck(_v, 12, 0, currVal_9); }); }
export function View_ErrorModalDialog_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "dl-error-modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "dl-modal-header mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i7.MatDialogTitle, [[2, i7.MatDialogRef], i1.ElementRef, i7.MatDialog], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "h4", [["class", "dl-modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["class", "close dl-pull-right right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "fa fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "dl-modal-body dl-error mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i7.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["style", "white-space: pre;"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorModalDialog_1)), i1.ɵdid(12, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 4, "mat-dialog-actions", [["align", "end"], ["class", "dl-modal-footer mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(14, 16384, null, 0, i7.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(15, 0, null, null, 2, "button", [["class", "dl-action-btn"], ["color", "primary"], ["mat-raised-button", ""], ["tabindex", "0"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatButton_0, i8.RenderType_MatButton)), i1.ɵdid(16, 180224, null, 0, i9.MatButton, [i1.ElementRef, i10.Platform, i6.FocusMonitor, [2, i11.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["OK"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_3 = _co.error.detail; _ck(_v, 12, 0, currVal_3); var currVal_6 = "primary"; _ck(_v, 16, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.error.message; _ck(_v, 10, 0, currVal_2); var currVal_4 = (i1.ɵnov(_v, 16).disabled || null); var currVal_5 = (i1.ɵnov(_v, 16)._animationMode === "NoopAnimations"); _ck(_v, 15, 0, currVal_4, currVal_5); }); }
export function View_ErrorModalDialog_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "error-modal-dialog", [], null, null, null, View_ErrorModalDialog_0, RenderType_ErrorModalDialog)), i1.ɵdid(1, 49152, null, 0, i12.ErrorModalDialog, [i7.MatDialogRef], null, null)], null, null); }
var ErrorModalDialogNgFactory = i1.ɵccf("error-modal-dialog", i12.ErrorModalDialog, View_ErrorModalDialog_Host_0, {}, {}, []);
export { ErrorModalDialogNgFactory as ErrorModalDialogNgFactory };
