import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {ErrorVO} from './error.vo';

@Component({
  selector: 'error-modal-dialog',
  templateUrl: './error.modal.component.html',
  styleUrls: ['./error.modal.component.scss']
})
export class ErrorModalDialog {

    title: string;
    error: ErrorVO;

    constructor(public dialogRef: MatDialogRef<ErrorModalDialog>) {}

    confirm(): void {
        this.dialogRef.close();
    }
}
