import { ElementRef, OnInit } from '@angular/core';
import { DonutService } from './donut.service';
import { Log } from 'ng2-logger/browser';
import * as d3 from 'd3';
var log = Log.create('DonutComponent');
var DonutComponent = /** @class */ (function () {
    function DonutComponent(donutService) {
        this.donutService = donutService;
        this._id = 'chart';
        this._height = 400;
        this._width = 400;
        this._ringWidth = 20;
        this._showLabels = false;
        this._showTooltip = false;
        this._showCenterLabel = false;
        this.colourList = [];
    }
    Object.defineProperty(DonutComponent.prototype, "id", {
        set: function (val) {
            this._id = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DonutComponent.prototype, "dataUrl", {
        set: function (val) {
            this._dataUrl = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DonutComponent.prototype, "height", {
        set: function (val) {
            this._height = parseInt(val, 10);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DonutComponent.prototype, "heightNum", {
        get: function () {
            return this._height;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DonutComponent.prototype, "displayHeight", {
        get: function () {
            return this.heightNum + 20;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DonutComponent.prototype, "width", {
        set: function (val) {
            this._width = parseInt(val, 10);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DonutComponent.prototype, "widthNum", {
        get: function () {
            return this._width;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DonutComponent.prototype, "displayWidth", {
        get: function () {
            return this.widthNum + 10;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DonutComponent.prototype, "ringWidth", {
        set: function (val) {
            this._ringWidth = parseInt(val, 10);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DonutComponent.prototype, "showLabels", {
        set: function (val) {
            if (val === 'true') {
                this._showLabels = true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DonutComponent.prototype, "showTooltip", {
        set: function (val) {
            if (val === 'true') {
                this._showTooltip = true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DonutComponent.prototype, "showCenterLabel", {
        set: function (val) {
            if (val === 'true') {
                this._showCenterLabel = true;
            }
        },
        enumerable: true,
        configurable: true
    });
    DonutComponent.getDimensions = function (id) {
        var el = document.getElementById(id);
        var w = 0, h = 0;
        if (el) {
            var dimensions = el.getBBox();
            w = dimensions.width;
            h = dimensions.height;
        }
        else {
            log.error('error: getDimensions() ' + id + ' not found.');
        }
        return {
            w: w,
            h: h
        };
    };
    DonutComponent.prototype.drawChart = function (data) {
        var _this = this;
        log.data(JSON.stringify(data));
        // build up the colour list
        data.data.forEach(function (i) {
            _this.colourList.push(i.colour);
        });
        this.colour = d3.scaleOrdinal().range(this.colourList);
        this.radius = Math.min(this.widthNum, this.heightNum) / 2;
        if (this.radius < 50) {
            this.radius = 50;
        }
        var element = this.chart.nativeElement;
        var translateStr = 'translate(' + (this.widthNum / 2) + ',' + (this.heightNum / 2) + ')';
        this.svg = d3.select(element).append('svg')
            .attr('width', this.displayWidth)
            .attr('height', this.displayHeight)
            .attr('style', 'display:block; margin:auto')
            .append('g').attr('transform', translateStr);
        this.pie = d3.pie().sort(null).value(function (input) {
            var d = input;
            return d.value;
        });
        var innerRadius = this.radius - 20 - this._ringWidth;
        if (innerRadius < 15) {
            innerRadius = 15;
        }
        this.path = d3.arc().outerRadius(this.radius - 20).innerRadius(innerRadius).cornerRadius(2);
        this.label = d3.arc().outerRadius(this.radius - 40).innerRadius(this.radius - 40);
        var arc = this.svg.selectAll('.arc').data(this.pie(data.data)).enter().append('g').classed('arc', true);
        this.pathArea = arc.append('path')
            .attr('d', this.path)
            .attr('id', function (d, i) {
            var item = d.data;
            return 'arc-' + _this._id + '_' + item.id;
        })
            .attr('style', 'fill-opacity: 0.85;')
            .attr('fill', function (d) {
            var item = d.data;
            return _this.colour(item.id);
        });
        this.addLabels(arc, data.centerText);
        if (this._showTooltip) {
            this.addTooltipConfig(this.svg);
        }
        this.addMouseEvents();
    };
    DonutComponent.prototype.addTooltipConfig = function (el) {
        var tooltipg = el.append('g')
            .attr('class', 'tooltip')
            .attr('text-anchor', 'end')
            .attr('id', 'tooltip_' + this._id)
            .attr('style', 'opacity:0')
            .attr('transform', 'translate(-500,-500)');
        tooltipg.append('rect')
            .attr('class', 'tooltip-bg')
            .attr('id', 'tooltipRect_' + this._id)
            .attr('x', 0)
            .attr('width', 120)
            .attr('height', 40)
            .attr('opacity', 0.8)
            .attr('fill', '#000');
        tooltipg
            .append('text')
            .attr('class', 'tooltip-txt')
            .attr('id', 'tooltipText_' + this._id)
            .attr('x', 30)
            .attr('y', 15)
            .attr('fill', '#fff')
            .attr('font-size', 10)
            .attr('font-family', 'arial')
            .text(function (d, i) {
            return '';
        });
    };
    DonutComponent.prototype.addMouseEvents = function () {
        var _this = this;
        this.pathArea.on('mouseover', function (data) {
            var inputData = data.data;
            var currentEl = d3.select('#arc-' + _this._id + '_' + inputData.id);
            currentEl.attr('style', 'fill-opacity:1;');
            if (_this._showTooltip) {
                d3.select('#tooltip_' + _this._id)
                    .transition()
                    .duration(120)
                    .style('opacity', function () {
                    return 1;
                });
                d3.selectAll('#tooltipText_' + _this._id).text('');
                d3.selectAll('#tooltipText_' + _this._id)
                    .append('tspan')
                    .attr('x', 0)
                    .attr('y', 0)
                    .attr('dy', '1.9em')
                    .text(' ' + inputData.description);
                var dims_1 = DonutComponent.getDimensions('tooltipText_' + _this._id);
                d3.select('#tooltip_' + _this._id)
                    .attr('transform', function (d) {
                    var mouseCoords = d3.mouse(d3.event.currentTarget);
                    var xCo = mouseCoords[0] - (dims_1.w / 2);
                    var yCo = mouseCoords[1] + 10;
                    return 'translate(' + xCo + ',' + yCo + ')';
                });
                d3.selectAll('#tooltipText_' + _this._id + ' tspan').attr('x', dims_1.w + 4);
                d3.selectAll('#tooltipRect_' + _this._id)
                    .attr('width', dims_1.w + 10)
                    .attr('height', dims_1.h + 20);
            }
        });
        if (this._showTooltip) {
            this.pathArea.on('mousemove', function (data) {
                var dims2 = DonutComponent.getDimensions('tooltipText_' + _this._id);
                d3.selectAll('#tooltip_' + _this._id)
                    .attr('transform', function (d) {
                    var mouseCoords = d3.mouse(d3.event.currentTarget);
                    var xCo = mouseCoords[0] - (dims2.w / 2);
                    var yCo = mouseCoords[1] + 15;
                    return 'translate(' + xCo + ',' + yCo + ')';
                });
            });
        }
        this.pathArea.on('mouseout', function (data) {
            var inputData = data.data;
            var currentEl = d3.select('#arc-' + _this._id + '_' + inputData.id);
            currentEl.attr('style', 'fill-opacity:0.85;');
            if (_this._showTooltip) {
                d3.select('#tooltip_' + _this._id)
                    .style('opacity', function () {
                    return 0;
                });
                d3.select('#tooltip_' + _this._id).attr('transform', function (d, i) {
                    return 'translate(' + -500 + ',' + -500 + ')';
                });
            }
        });
    };
    DonutComponent.prototype.addLabels = function (arc, centerText) {
        var _this = this;
        if (this._showLabels) {
            arc.append('text')
                .attr('class', 'label-txt')
                .attr('z-index', '1')
                .attr('dx', 30)
                .attr('dy', -5)
                .append('textPath')
                .attr('xlink:href', function (d, i) {
                var item = d.data;
                return '#arc-' + _this._id + '_' + item.id;
            })
                .text(function (d) {
                var item = d.data;
                return item.label;
            });
        }
        if (this._showCenterLabel && centerText != null && centerText.length !== 0) {
            this.svg.append('text')
                .attr('id', 'centerLabel_' + this._id)
                .attr('class', 'center-txt')
                .attr('transform', 'translate(' + 0 + ',' + 0 + ')')
                .text(centerText);
            var labelDim = DonutComponent.getDimensions('centerLabel_' + this._id);
            d3.selectAll('#centerLabel_' + this._id)
                .attr('transform', 'translate(' + (((labelDim.w / 2) * -1) - 5) + ',' + 10 + ')');
        }
    };
    DonutComponent.prototype.ngOnInit = function () {
        var _this = this;
        log.info('init ' + this._height + ' :: ' + this._width);
        this.donutService.getData(this._dataUrl).subscribe(function (d) { return _this.drawChart(d); });
    };
    return DonutComponent;
}());
export { DonutComponent };
