/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./checkbox.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/tooltip";
import * as i3 from "@angular/cdk/overlay";
import * as i4 from "@angular/cdk/scrolling";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "../../../../node_modules/@angular/material/checkbox/typings/index.ngfactory";
import * as i9 from "@angular/forms";
import * as i10 from "@angular/material/checkbox";
import * as i11 from "@angular/platform-browser/animations";
import * as i12 from "@angular/common";
import * as i13 from "./checkbox.component";
var styles_CheckboxComponent = [i0.styles];
var RenderType_CheckboxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CheckboxComponent, data: {} });
export { RenderType_CheckboxComponent as RenderType_CheckboxComponent };
function View_CheckboxComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "i", [["aria-hidden", "true"], ["class", "fa fa-info-circle"]], null, [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).toggle() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 147456, [["me", 4]], 0, i2.MatTooltip, [i3.Overlay, i1.ElementRef, i4.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i5.Platform, i6.AriaDescriber, i6.FocusMonitor, i2.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i7.Directionality], [2, i2.MAT_TOOLTIP_DEFAULT_OPTIONS]], { message: [0, "message"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.component.info, ""); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CheckboxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "mat-checkbox", [["class", "dsp-check-button mat-checkbox"], ["color", "primary"]], [[1, "data-val", 0], [1, "kings-watch", 0], [1, "data-name", 0], [8, "id", 0], [2, "mat-checkbox-indeterminate", null], [2, "mat-checkbox-checked", null], [2, "mat-checkbox-disabled", null], [2, "mat-checkbox-label-before", null], [2, "_mat-animation-noopable", null]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.valueChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatCheckbox_0, i8.RenderType_MatCheckbox)), i1.ɵprd(5120, null, i9.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i10.MatCheckbox]), i1.ɵdid(2, 4374528, null, 0, i10.MatCheckbox, [i1.ElementRef, i1.ChangeDetectorRef, i6.FocusMonitor, i1.NgZone, [8, null], [2, i10.MAT_CHECKBOX_CLICK_ACTION], [2, i11.ANIMATION_MODULE_TYPE]], { color: [0, "color"], tabIndex: [1, "tabIndex"], id: [2, "id"] }, { change: "change" }), (_l()(), i1.ɵeld(3, 0, null, 0, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵted(-1, 0, ["\u00A0\u00A0"])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_CheckboxComponent_1)), i1.ɵdid(6, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_9 = "primary"; var currVal_10 = 1; var currVal_11 = i1.ɵinlineInterpolate(1, "", _co.component.id, ""); _ck(_v, 2, 0, currVal_9, currVal_10, currVal_11); var currVal_13 = _co.component.info; _ck(_v, 6, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.component.id; var currVal_1 = ((((_co.flowId + "-") + _co.pageName) + "-") + _co.component.id); var currVal_2 = _co.component.id; var currVal_3 = i1.ɵnov(_v, 2).id; var currVal_4 = i1.ɵnov(_v, 2).indeterminate; var currVal_5 = i1.ɵnov(_v, 2).checked; var currVal_6 = i1.ɵnov(_v, 2).disabled; var currVal_7 = (i1.ɵnov(_v, 2).labelPosition == "before"); var currVal_8 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_12 = _co.component.name; _ck(_v, 3, 0, currVal_12); }); }
export function View_CheckboxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "checkbox", [], null, null, null, View_CheckboxComponent_0, RenderType_CheckboxComponent)), i1.ɵdid(1, 49152, null, 0, i13.CheckboxComponent, [], null, null)], null, null); }
var CheckboxComponentNgFactory = i1.ɵccf("checkbox", i13.CheckboxComponent, View_CheckboxComponent_Host_0, { flowId: "flowId", formGroup: "formGroup", component: "component", pageName: "pageName", _productCode: "_productCode", groupId: "groupId", _spMode: "_spMode" }, { change: "change", loaded: "loaded", validated: "validated", resume: "resume", altContinue: "altContinue" }, []);
export { CheckboxComponentNgFactory as CheckboxComponentNgFactory };
