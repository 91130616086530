var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Dynamic } from './component';
import { Injector } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { AVSResultVO, BankingValidationService, ValidationReqVO } from './banking.validation.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Payer } from '../../base/payer/dl.payer.vo';
import { DlPayerModalComponent } from '../../base/payer/dl.payer.modal.component';
import { MatDialog } from '@angular/material';
import { interval } from 'rxjs';
import { DlBankingValidationService } from '../../base/banking/dl.banking.validation.service';
import { ErrorModalProvider } from '../../base/error/error.modal.service';
import { PageComponent } from '../loader/page.component';
import { PaymentService } from '../../sp/payment/payment.service';
import { SpDataService } from '../../sp/sp.data.service';
import { BankDetail } from '../../base/banking/dl.banking.vo';
import { Log } from 'ng2-logger/browser';
import { WindowRef } from '../../base/window.ref';
var log = Log.create('dsp-banking-component');
var BankingComponent = /** @class */ (function (_super) {
    __extends(BankingComponent, _super);
    function BankingComponent(winRef, injector, fb, dialog, errorDialogService, validationService, pageComponent, paymentService, spDataService) {
        var _this = _super.call(this) || this;
        _this.winRef = winRef;
        _this.injector = injector;
        _this.fb = fb;
        _this.dialog = dialog;
        _this.errorDialogService = errorDialogService;
        _this.validationService = validationService;
        _this.pageComponent = pageComponent;
        _this.paymentService = paymentService;
        _this.spDataService = spDataService;
        _this.overrideText = 'OPS: Override';
        _this.canOverride = false;
        _this.overrideBusy = false;
        _this.overrideApproved = false;
        _this.forAltPayer = false;
        _this.altPayerApproved = false;
        _this.validationFailed = false;
        _this.defaultPremiumPayerEnabled = true;
        _this.canContinue = true;
        _this.source = interval(1000);
        _this._showAltOptions = true; // TODO: showAltOptions should be set by a property pulled from life-web-oc features
        _this.bankingValidationService = _this.injector.get(BankingValidationService);
        _this.setupForm();
        return _this;
    }
    Object.defineProperty(BankingComponent.prototype, "showAltOptions", {
        get: function () {
            return (this._showAltOptions &&
                this.pageComponent._pageInfo.mode === 'embedded' &&
                !this.pageComponent._pageInfo.flowId.includes('MLF'));
        },
        set: function (show) {
            if (!isNullOrUndefined(show)) {
                this._showAltOptions = show;
            }
        },
        enumerable: true,
        configurable: true
    });
    BankingComponent.prototype.setupForm = function () {
        var _this = this;
        this.banking = this.fb.group({
            bank: ['', [Validators.required]],
            branch: ['', [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(8)]],
            accountType: ['', Validators.required],
            accountNo: ['', [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(20)]],
            accountHolder: ['', Validators.required],
            altPremiumPayer: ['']
        });
        this.banking.get('bank').valueChanges.subscribe(function () { return _this.bankSelected(); });
    };
    BankingComponent.prototype.setupFromControl = function () {
        this.formGroup.addControl('banking', this.banking);
        // we use the nested from to tel the parent of its validity
        this.component.isValid = true;
    };
    BankingComponent.prototype.getFormControl = function () {
        // this is not needed as this control overrides default behaviour
        return null;
    };
    BankingComponent.prototype.onChange = function () {
    };
    BankingComponent.prototype.bankSelected = function () {
        var _this = this;
        this.bankingDetail.branchCode = this.getValueFromForm('bank');
        var selected = this.bankingInput.bankNames.find(function (item) { return item.id === _this.bankingDetail.branchCode; });
        this.bankingDetail.bankName = isNullOrUndefined(selected) ? '' : selected.display;
        setTimeout(function () {
            _this.banking.get('branch').setValue(_this.banking.get('bank').value);
        });
        this.bankSupportsRTAvs = isNullOrUndefined(selected) ? false : selected.realtimeAvsSupport;
    };
    BankingComponent.prototype.getValueFromForm = function (formKey) {
        return this.banking.get(formKey).value;
    };
    BankingComponent.prototype.fieldIsEmpty = function (field) {
        if (field === '' || isNullOrUndefined(field)) {
            return true;
        }
        return false;
    };
    BankingComponent.prototype.isStupidlyValid = function (req) {
        if (this.fieldIsEmpty(req.account_number) ||
            this.fieldIsEmpty(req.branch_code) ||
            this.fieldIsEmpty(req.account_type)) {
            return false;
        }
        return true;
    };
    BankingComponent.prototype.handleAVSNotSupported = function (req) {
        console.log('AVS not supported. Handling.');
        // TODO: perhaps do CDV here? For now just check that all fields have something in them.
        if (this.isStupidlyValid(req)) {
            console.log('Basic validation passed.');
            this.avsValidated();
        }
        else {
            console.log('Basic validation failed.');
            this.avsFailed();
        }
    };
    BankingComponent.prototype.validateAccount = function () {
        var _this = this;
        var res;
        var req = {
            account_number: this.banking.get('accountNo').value,
            branch_code: this.banking.get('bank').value,
            account_type: this.banking.get('accountType').value,
            ref: this.bankingDetail.ref
        };
        if (this.bankSupportsRTAvs) {
            console.log('Bank supports AVS - doing AVS.');
            this.bankingValidationService.validateAccount(req).subscribe(function (res) { return _this.handleValidationCompleted(res); });
        }
        else {
            this.handleAVSNotSupported(req);
        }
    };
    BankingComponent.prototype.handleValidationCompleted = function (resultVO) {
        // if (!resultVO.valid) {
        console.log('AVS requested.');
        if (!resultVO.success) {
            this.errorDialogService.showErrorMessage('Couldn\'t send message for validation', 'AVS Failed');
            // this.errorDialogService.showErrorMessage('Validation has failed to validate', 'Validation Failed');
            // this.pageComponent.busy = false;
            // this.pageComponent.disable = false;
        }
        else {
            // AVS
            this.pollUntilAvsFinished(resultVO.Response.userReference);
            // this.validationFailed = !resultVO.valid;
            // this.component.isValid = resultVO.valid;
            // this.validated.emit({componentId: this.component.id, success: resultVO.valid});
        }
        /*this.validationFailed = !res.valid;
        this.component.isValid = res.valid;
        this.validated.emit({componentId: this.component.id, success: res.valid});*/
    };
    BankingComponent.prototype.pollUntilAvsFinished = function (userRef) {
        var _this = this;
        this.subscribe = this.source.subscribe(function (val) {
            // console.log(val);
            _this.validationService.getAvsValidationStatus(userRef).subscribe(function (res) {
                // console.log(res);
                if (res.status === 3 || res.status === 4) {
                    console.log('AVS polling finished');
                    _this.pollingFinishedFor(userRef, res);
                }
                else {
                    // console.log('no results yet');
                    if (val === 90) {
                        console.log('AVS response timeout - 90 seconds passed');
                        _this.errorDialogService.showErrorMessage('No response received AVS validation', 'AVS Failed');
                        _this.pollingFinishedFor(userRef, res);
                    }
                }
            }, function () {
                console.log('AVS polling had an error');
            });
        });
    };
    BankingComponent.prototype.avsValidated = function () {
        console.log('Performing post-AVS actions - validated.');
        this.validationFailed = false;
        this.component.isValid = true;
        this.validated.emit({ componentId: this.component.id, success: true });
    };
    BankingComponent.prototype.avsFailed = function () {
        console.log('Performing post-AVS actions - failed.');
        this.pageComponent.busy = false;
        this.pageComponent.disable = false;
    };
    BankingComponent.prototype.pollingFinishedFor = function (userRef, avsResult) {
        this.subscribe.unsubscribe();
        if (avsResult.status === 3) {
            if (avsResult.Response.accountExists === '00'
                && avsResult.Response.accountAcceptsDebits === '00'
                && avsResult.Response.accountIdMatch === '00') {
                /*if (avsResult.Response.accountIdMatch !== '00') {
                    this.errorDialogService.showErrorMessage('Please note that the ID number does not ' +
                        'match the one on record at the bank specified', 'Warning');
                }*/
                this.avsValidated();
            }
            else {
                if (this.pageComponent._pageInfo.mode === 'embedded') {
                    this.handleEmbeddedAvsFailure(avsResult);
                }
                else {
                    this.handleWebAvsFailure();
                }
                // re-enable the button from parent component
                this.pageComponent.busy = false;
                this.pageComponent.disable = false;
            }
        }
        else {
            // re-enable the button from parent component
            this.avsFailed();
        }
    };
    BankingComponent.prototype.handleWebAvsFailure = function () {
        return __awaiter(this, void 0, void 0, function () {
            var agentWillCall;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!confirm('We were unable to verify your banking details. Would you like an agent to call you and assist?')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.pageComponent.updateCaseForWebAvsInvalid()];
                    case 1:
                        agentWillCall = (_a.sent());
                        console.log('Agent will call?: ' + agentWillCall);
                        if (agentWillCall) {
                            // Uplift case for agent to phone them
                            this.errorDialogService.showErrorMessage('Please feel free to try another bank account, otherwise you can close the browser.', 'Thank you. An agent will phone you back shortly.');
                        }
                        else {
                            this.errorDialogService.showErrorMessage('Please feel free to try another bank account, otherwise an agent will call you to assist.', 'Something went wrong.');
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        // Let them re-enter their banking details.
                        this.avsFailed();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    BankingComponent.prototype.handleEmbeddedAvsFailure = function (avsResult) {
        var validationString;
        validationString = 'Account Exists: ' + String(avsResult.Response.accountExists === '00') + ', ';
        validationString += 'Account ID Match: ' + String(avsResult.Response.accountIdMatch === '00') + ', ';
        validationString += 'Account Accepts Debits: ' + String(avsResult.Response.accountAcceptsDebits === '00') + ', ';
        validationString += 'Phone Number Match: ' + String(avsResult.Response.phoneValid === '00') + ', ';
        this.setupOverrideValues(avsResult);
        // error
        if (avsResult.Response.accountExists !== '00' || avsResult.Response.accountAcceptsDebits !== '00') {
            validationString += '. You cannot override for these errors';
            this.canOverride = false;
            this.canContinue = false;
        }
        else {
            this.canOverride = true;
        }
        this.avsAccountExists = avsResult.Response.accountExists === '00';
        this.avsAccountIDMatch = avsResult.Response.accountIdMatch === '00';
        this.avsAccountAcceptsDebits = avsResult.Response.accountAcceptsDebits === '00';
        this.avsAccountPhoneValid = avsResult.Response.phoneValid === '00';
        this.errorDialogService.showErrorMessage(validationString, 'AVS Failed');
    };
    BankingComponent.prototype.broadcastStateChanged = function () {
        if (!isNullOrUndefined(this.change)) {
            this.change.emit(this.component.counter);
        }
    };
    BankingComponent.prototype.processExtraData = function () {
        this.bankingInput = this.component.extraInput;
        this.bankingDetail = this.component.value;
        this.banking.get('bank').setValue(this.bankingDetail.bankNameCode);
        this.banking.get('branch').setValue(this.bankingDetail.branchCode);
        this.banking.get('accountType').setValue(this.bankingDetail.accountType);
        this.banking.get('accountNo').setValue(this.bankingDetail.accountNo);
        this.banking.get('accountHolder').setValue(this.bankingDetail.accountHolder);
        this.component.isValid = this.banking.valid;
        this.broadcastStateChanged();
    };
    BankingComponent.prototype.validate = function () {
        var _this = this;
        // called in parent (page.component.ts)
        // populate the needed data
        this.bankingDetail.accountHolder = this.banking.get('accountHolder').value;
        this.bankingDetail.accountNo = this.banking.get('accountNo').value;
        this.bankingDetail.accountType = this.banking.get('accountType').value;
        this.bankingDetail.branchCode = this.banking.get('branch').value;
        this.bankingDetail.bankName = this.bankingInput.bankNames.find(function (item) { return item.id === _this.banking.get('bank').value; }).display;
        this.bankingDetail.bankNameCode = this.banking.get('accountHolder').value + '\'s bank account';
        if (this.defaultPremiumPayerEnabled) {
            this.bankingDetail.premiumPayerMode = 'default';
        }
        this.component.value = this.bankingDetail;
        // Save here to be able to AVS validate
        var bankDetail = this.buildBankDetailObject();
        this.paymentService.saveBanking(this.pageComponent._pageInfo.spId, bankDetail, this.pageComponent.pageInfo.policyId)
            .subscribe(function (res) {
            _this.spDataService.continueBusy = false;
            _this.validateAccount();
        });
        return true;
    };
    BankingComponent.prototype.setFormItemValue = function (fromItem, itemValue) {
        if (!isNullOrUndefined(itemValue) && itemValue !== '') {
            var options = {};
            /*if (this.skipChange) {
                options.emitEvent = false;
            }*/
            this.banking.get(fromItem).setValue(itemValue, options);
        }
    };
    BankingComponent.prototype.setAltPremiumPayer = function (altPayerSelected) {
        if (altPayerSelected) {
            this.modalData = new Payer();
            this.openModal(false);
            log.info('Premium payer set to: ' + this.bankingDetail.premiumPayerMode);
        }
        else {
            this.bankingDetail.premiumPayerMode = 'default';
            log.info('Premium payer set to: ' + this.bankingDetail.premiumPayerMode);
            this.setFormItemValue('accountHolder', ' ');
            this.defaultPremiumPayerEnabled = true;
            this.canContinue = true;
            this.validationFailed = false;
            this.component.isValid = true;
            this.broadcastStateChanged();
        }
    };
    BankingComponent.prototype.openModal = function (edit) {
        var _this = this;
        this.payerDialogRef = this.dialog.open(DlPayerModalComponent, {
            panelClass: 'dl-modal',
            maxWidth: '99vw',
            position: { top: '20px' },
            data: {
                payer: this.modalData,
                title: (edit ? 'Edit ' : 'Add ') + 'Premium payer'
            }
        });
        this.payerDialogRef.afterClosed().subscribe(function (res) {
            _this.dialogClosed(res);
        });
    };
    BankingComponent.prototype.dialogClosed = function (closeValue) {
        var _this = this;
        if (closeValue === 'save') {
            this.payer = this.modalData;
            this.setFormItemValue('accountHolder', this.payer.first_name + ' ' + this.payer.last_name);
            this.bankingDetail.premiumPayerMode = 'alt';
            log.info('Premium payer set to: ', this.bankingDetail.premiumPayerMode);
            this.bankingDetail.payer = this.payer;
            this.bankingDetail.payer.policy_id = this.pageComponent.pageInfo.policyId;
            this.defaultPremiumPayerEnabled = false;
            var bankDetail = this.buildBankDetailObject();
            // We save banking here to make AVS use the alternative payer's ID etc.
            this.paymentService.saveBanking(this.pageComponent._pageInfo.spId, bankDetail, this.pageComponent.pageInfo.policyId)
                .subscribe(function (res) {
                _this.spDataService.continueBusy = false;
            });
            this.component.value = this.bankingDetail;
            this.canOverride = true;
            this.forAltPayer = true;
            this.canContinue = false;
            this.pageComponent.disable = true;
            // send a message to sidebar to populate alt payer details in contact section.
            if (!isNullOrUndefined(this.winRef.nativeWindow.parent)) {
                console.log('Sending message: ' + 'refresh_person');
                this.winRef.nativeWindow.parent.postMessage('refresh_person', '*');
            }
        }
    };
    BankingComponent.prototype.buildBankDetailObject = function () {
        var bankDetailObject = new BankDetail();
        bankDetailObject.id = this.bankingDetail.id;
        bankDetailObject.accountHolder = this.bankingDetail.accountHolder;
        bankDetailObject.accountNumber = this.bankingDetail.accountNo;
        bankDetailObject.accountType = this.bankingDetail.accountType;
        bankDetailObject.bankName = this.bankingDetail.bankName;
        bankDetailObject.branchCode = this.bankingDetail.branchCode;
        bankDetailObject.valid = this.bankingDetail.validated;
        bankDetailObject.premiumPayerMode = this.bankingDetail.premiumPayerMode;
        bankDetailObject.payer = this.bankingDetail.payer;
        bankDetailObject.valid = false;
        bankDetailObject.validationRef = this.bankingDetail.ref;
        return bankDetailObject;
    };
    BankingComponent.prototype.requestOverride = function () {
        var _this = this;
        this.overrideBusy = true;
        this.overrideText = 'Requesting...';
        this.pageComponent.disable = true;
        if (isNullOrUndefined(this.payer)) {
            this.payer = new Payer();
            this.payer.relation = 'Not specified';
            this.payer.notes = 'Nothing noted';
        }
        this.validationService.sendOverrideRequest(this.pageComponent._pageInfo.spId, 'dl_sales_process', this.avsAccountExists, this.avsAccountAcceptsDebits, this.avsAccountIDMatch, this.avsAccountPhoneValid, this.forAltPayer, this.payer.relation, this.payer.notes, this.bankSupportsRTAvs).subscribe(function (res) {
            if (res.id === '') {
                // log.info('Override request service failed : ' + res);
                _this.overrideFailed();
            }
            else {
                // log.info('Override request response: ' + res);
                _this.pollForOverrideStatus(res);
            }
        }, function () {
            _this.overrideFailed();
        });
    };
    BankingComponent.prototype.selectionChange = function (id) {
    };
    BankingComponent.prototype.pollForOverrideStatus = function (res) {
        var _this = this;
        if (res.override_status === 'approved') {
            this.pollingOverrideSuccess();
            return;
        }
        else if (res.override_status === 'rejected') {
            this.overrideRejected();
            return;
        }
        this.overrideText = 'Awaiting approval';
        setTimeout(function () {
            _this.validationService.checkOverrideStatus(res.id).subscribe(function (statusRes) { return _this.pollForOverrideStatus(statusRes); }, function (err) {
                // log.info('Update Check failed: Do another call', err);
                /*let errorRes: OverrideResponse = new OverrideResponse();
                errorRes.id = res.id;
                errorRes.override_status = 'requested';
                errorRes.error = 'Missed a call';
                errorRes.approved = false;*/
                _this.pollForOverrideStatus(res);
            });
        }, 1000);
    };
    BankingComponent.prototype.pollingOverrideSuccess = function () {
        this.overrideApproved = true;
        this.overrideBusy = false;
        this.canOverride = false;
        this.pageComponent.pageValid = true;
        this.pageComponent.disable = false;
        if (this.forAltPayer) {
            this.forAltPayer = false;
            this.overrideText = 'Approved. Do Validation!';
            this.altPayerApproved = true;
        }
        else {
            this.validationFailed = false;
            this.component.isValid = true;
            this.validated.emit({ componentId: this.component.id, success: true });
            this.overrideText = 'Approved';
        }
    };
    BankingComponent.prototype.overrideFailed = function () {
        this.overrideApproved = false;
        this.overrideBusy = false;
        this.canOverride = true;
        this.pageComponent.busy = false;
        this.pageComponent.disable = true;
        this.overrideText = 'Failed. Request again...';
    };
    BankingComponent.prototype.overrideRejected = function () {
        this.overrideApproved = false;
        this.overrideBusy = false;
        this.canOverride = true;
        this.pageComponent.busy = false;
        this.pageComponent.disable = true;
        this.overrideText = 'Override rejected';
        this.errorDialogService.showErrorMessage("Please speak to your TL", 'Override rejected');
    };
    BankingComponent.prototype.setupOverrideValues = function (avsResultResponse) {
        // this.canOverride = true;
        this.avsAccountIDMatch = avsResultResponse.Response.accountIdMatch === '00';
        this.avsAccountPhoneValid = avsResultResponse.Response.phoneValid === '00';
    };
    return BankingComponent;
}(Dynamic));
export { BankingComponent };
