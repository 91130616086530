import {Dynamic} from './component';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {Component} from '@angular/core';
import {isNullOrUndefined} from 'util';
import {minMaxValidation} from '../../base/min.max.validator';
import {BmiDetailVO, PageComponentVO} from '../page.data.vo';

@Component({
    selector: 'bmi-input',
    templateUrl: 'bmi.component.html',
    styleUrls: ['bmi.component.scss']
})
export class BmiComponent extends Dynamic<PageComponentVO> {

    bmi: FormGroup;

    heightM = 0;
    heightIn = 0;
    weightKg = 0;
    weightLb = 0;
    bmiValue = 0;

    bmiData = new BmiDetailVO();

    showM = true;
    showKG = true;

    heightMask: any = {
        mask: function(rawValue: string) {
            const test = rawValue.replace(/\D+/g, '');
            if (test.length > 1 && test.length <= 2) {
                return [/[1|2]/, '.', /\d/, ' m'];
            }
            if (test.length >= 3) {
                return [/[1|2]/, '.', /\d/, /\d/, ' m'];
            }
            return [/[1|2]/, ' m'];
        },
        guide: true
    };

    constructor(private fb: FormBuilder) {
        super();
        this.setupForm();
    }

    static heightMetricValidator(min: number, max: number, comp: BmiComponent): ValidatorFn {
        return (group: FormGroup): {[key: string]: any} => {
            const height = comp.heightM;
            const ftComponet = group.controls['heightIn'];
            const mComponet = group.controls['heightM'];
            if (min < height && height <= max) {
                ftComponet.setErrors(null);
                mComponet.setErrors(null);
                return;
            }
            const error = {invalidHeight: 'Invalid height'};
            ftComponet.setErrors(error);
            mComponet.setErrors(error);
        };
    }

    static weightMetricValidator(min: number, max: number, comp: BmiComponent): ValidatorFn {
        return(group: FormGroup): {[key: string]: any} => {
            const weight = comp.weightKg;
            const lbComponet = group.controls['weightLb'];
            const kgComponet = group.controls['weightKg'];
            if (min < weight && weight <= max) {
                lbComponet.setErrors(null);
                kgComponet.setErrors(null);
                return null;
            }
            const error = {invalidWeight: 'Invalid weight'};
            lbComponet.setErrors(error);
            kgComponet.setErrors(error);
        };
    }

    private setupForm(): void {
        this.bmi = this.fb.group({
            heightSelector: ['m'],
            weightSelector: ['kg'],
            heightM: ['', [Validators.required]],
            weightKg: ['', [Validators.required]],
            heightFt: ['', [Validators.required, Validators.pattern('[0-9]*')]],
            heightIn: ['0', [Validators.required, minMaxValidation(0, 11)]],
            weightSt: ['', []],
            weightLb: ['0', [Validators.required]]
        }, {validator: [
            BmiComponent.heightMetricValidator(0.5, 2.5,  this),
            BmiComponent.weightMetricValidator(20, 600, this)
        ]});
        this.bmi.get('heightSelector').valueChanges.subscribe((val) => this.heightSelectorChanged(val));
        this.bmi.get('weightSelector').valueChanges.subscribe((val) => this.weightSelectorChanged(val));
        this.bmi.get('heightM').valueChanges.subscribe( (val) => this.heightCMChanged(val));
        this.bmi.get('weightKg').valueChanges.subscribe( (val) => this.weightKgChanged(val));
        this.bmi.get('heightFt').valueChanges.subscribe((val) => this.heightImperialChange(val === '' ? null : val, null));
        this.bmi.get('heightIn').valueChanges.subscribe((val) => this.heightImperialChange(null, val === '' ? null : val));
        this.bmi.get('weightSt').valueChanges.subscribe( (val) => this.weightImperialChange(val === '' ? null : val, null));
        this.bmi.get('weightLb').valueChanges.subscribe( (val) => this.weightImperialChange(null, val === '' ? null : val));

        this.heightSelectorChanged('m');
        this.weightSelectorChanged('kg');
    }

    setupFromControl() {
        this.formGroup.addControl('bmi', this.bmi);
        // we use the nested from to tel the parent of its validity
        this.component.isValid = true;
    }

    getFormControl(): FormControl {
        // this component will notify parent based on it own group
        return null;
    }

    processExtraData() {
        if (!isNullOrUndefined(this.component)) {
            if (!isNullOrUndefined(this.component.extraInput)) {
                this.bmiData.qAnswerId = this.component.extraInput as string;
            }
            this.component.value = this.bmiData;
        }
    }

    private heightSelectorChanged(val: string) {
        if (val === 'm') {
            this.bmi.get('heightM').setValue(this.heightM === 0 ? '' : this.heightM);
            this.bmi.get('heightM').enable();
            this.bmi.get('heightFt').disable();
            this.bmi.get('heightIn').disable();
            this.showM = true;
        } else {
            let feet = 0;
            let inch = 0;
            if (!isNaN(this.heightIn) && this.heightIn !== 0) {
                feet = Math.floor(this.heightIn / 12);
                inch = Math.floor(this.heightIn % 12);
            }
            this.bmi.get('heightFt').setValue(feet === 0 ? '' : feet);
            this.bmi.get('heightIn').setValue(inch === 0 ? '0' : inch);
            this.bmi.get('heightM').disable();
            this.bmi.get('heightFt').enable();
            this.bmi.get('heightIn').enable();
            this.showM = false;
        }
    }

    private weightSelectorChanged(val: string) {
        if (val === 'kg') {
            this.bmi.get('weightKg').setValue(this.weightKg === 0 ? '' : this.weightKg);
            this.bmi.get('weightKg').enable();
            this.bmi.get('weightSt').disable();
            this.bmi.get('weightLb').disable();
            this.showKG = true;
        } else {
            let stone = 0;
            let pound = 0;
            if (!isNaN(this.weightLb) && this.weightLb !== 0) {
                stone = Math.floor(this.weightLb / 14);
                pound = Math.floor((this.weightLb % 14) * 100) / 100;
            }
            this.bmi.get('weightSt').setValue(stone === 0 ? '' : stone);
            this.bmi.get('weightLb').setValue(pound === 0 ? '0' : pound);
            this.bmi.get('weightKg').disable();
            this.bmi.get('weightSt').enable();
            this.bmi.get('weightLb').enable();
            this.showKG = false;
        }
    }

    private heightCMChanged(val: string | number): void {
        if (!isNullOrUndefined(val) && val !== '') {
            this.heightM = val as number;
            this.heightIn = this.heightM * 39.37;
        } else {
            this.heightM = 0;
            this.heightIn = 0;
        }
        this.calculateBmi();
    }

    private weightKgChanged(val: string | number): void {
        if (!isNullOrUndefined(val) && val !== '') {
            this.weightKg = val as number;
            this.weightLb = Math.round(this.weightKg * 2.205 * 100) / 100;
        } else {
            this.weightKg = 0;
            this.weightLb = 0;
        }
        this.calculateBmi();
    }

    private getImperialValue(value: number, field: string): number {
        if (isNullOrUndefined(value) || isNaN(value)) {
            const inputVal = this.bmi.get(field).value;
            if (isNaN(inputVal) || inputVal === '') {
                return 0;
            } else {
                return parseInt(inputVal + '', 10);
            }
        } else {
            return parseInt(value + '', 10);
        }
    }

    private heightImperialChange(feet: number, inch: number): void {
        const feetForCalc = this.getImperialValue(feet, 'heightFt');
        const inchForCalc = this.getImperialValue(inch, 'heightIn');
        this.heightIn = (feetForCalc * 12) + inchForCalc;
        if (!isNaN(this.heightIn) && this.heightIn !== 0) {
            this.heightM = Math.round(this.heightIn / 39.37 * 100) / 100;
        }
        this.calculateBmi();
        if (feet != null) {
            this.bmi.get('heightIn').markAsTouched();
        } else {
            this.bmi.get('heightFt').markAsTouched();
        }
    }

    private weightImperialChange(stone: number, pound: number) {
        const stoneForCalc = this.getImperialValue(stone, 'weightSt');
        const poundForCalc = this.getImperialValue(pound, 'weightLb');
        this.weightLb = (stoneForCalc * 14) + poundForCalc;
        if (!isNaN(this.weightLb) && this.weightLb !== 0) {
            this.weightKg = Math.round((this.weightLb / 2.205) * 100) / 100;
        }
        this.calculateBmi();
    }

    private calculateBmi(): void {
        if (this.weightKg !== 0 && this.heightM !== 0) {
            this.bmiValue = Math.floor(this.weightKg / (this.heightM * this.heightM));
        } else {
            this.bmiValue = 0;
        }
        this.populateComponentValue();
    }

    private populateComponentValue(): void {
        this.bmiData.heightM = this.heightM;
        this.bmiData.weightKG = this.weightKg;
        this.bmiData.heightImperial = this.heightInDisp;
        this.bmiData.weightImperial = this.weightLbDisp;
        this.bmiData.bmi = this.bmiValue;
        this.bmiData.showM = this.showM;
        this.bmiData.showKG = this.showKG;
        if (!isNullOrUndefined(this.component)) {
            if (isNullOrUndefined(this.component.value)) {
                this.component.value = this.bmiData;
            }
        }
    }

    get heightInDisp(): string {
        return '' + Math.floor(this.heightIn / 12) + '"' + Math.floor(this.heightIn % 12) + '\'';
    }

    get weightLbDisp(): string {
        return '' + Math.floor(this.weightLb / 14) + 'st ' + (Math.floor((this.weightLb % 14) * 100) / 100) + 'lb';
    }

    getMaskFor(question): any {
        if (question === 'height') {
            return this.heightMask;
        }
    }
}
