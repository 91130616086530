import {Injectable} from '@angular/core';
import {BaseService} from '../base/base.service';
import {Observable} from 'rxjs';
import {isNullOrUndefined} from 'util';
import {HttpClient} from '@angular/common/http';
import {ErrorModalProvider} from '../base/error/error.modal.service';
import {catchError} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ResetPasswordService extends BaseService {

    constructor(httpClient: HttpClient,
                errorModalProvider: ErrorModalProvider) {
        super(httpClient, errorModalProvider);
    }

    public sendRest(email: string, idNum?: string): Observable<ResetPasswordInfo> {
        let postData: any;
        if (!isNullOrUndefined(idNum)) {
            postData = {
                email: email,
                id_number: idNum
            };
        } else {
            postData = {
                email: email
            };
        }
        return this.httpClient.post<ResetPasswordInfo>('/dl/data/sendReset', postData, this.httpOptions(false)).pipe(
            catchError(this.handleHTTPError<ResetPasswordInfo>())
        );
    }

    public resetPassword(data: ResetPasswordInfo): Observable<ResetPasswordInfo> {
        return this.httpClient.post<ResetPasswordInfo>('/dl/data/setPassword', data, this.httpOptions(false)).pipe(
            catchError(this.handleHTTPError<ResetPasswordInfo>())
        );
    }
}

export interface ResetPasswordInfo {
    id?: string;
    requires_id_number?: string|boolean;
    sso_user_login?: string;
    known_as?: string;
    error?: string;
    action?: string;
    username?: string;
    token?: string;
    password?: string;
    success?: boolean|string;
    message?: string;
}
