var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { isNullOrUndefined } from 'util';
import { rsaIdDateOfBirthValidation, rsaIdValidation } from '../../base/rsa.id.validator';
import { FormControl, Validators } from '@angular/forms';
import { Dynamic } from './component';
var RsaIdDobComponent = /** @class */ (function (_super) {
    __extends(RsaIdDobComponent, _super);
    function RsaIdDobComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.featureSS3217_COMPULSORY_BENEFICIARY_ID = true;
        _this.rsaIdControl = new FormControl('');
        return _this;
    }
    RsaIdDobComponent.prototype.onIdValidationChange = function (rsaId) {
        if (this.featureSS3217_COMPULSORY_BENEFICIARY_ID)
            !rsaId.isValid() ? this.rsaIdControl.setErrors({ 'invalid': true }) : this.rsaIdControl.setErrors(null);
    };
    RsaIdDobComponent.prototype.getFormControl = function () {
        var min;
        var max;
        if (!isNullOrUndefined(this.component.extraInput)) {
            min = this.component.extraInput.min;
            max = this.component.extraInput.max;
        }
        if (isNullOrUndefined(this.component.required) || this.component.required) {
            this.rsaIdControl.setValidators([Validators.required, Validators.pattern('[0-9]*'), this.featureSS3217_COMPULSORY_BENEFICIARY_ID ? rsaIdValidation(false, this, min, max) : rsaIdDateOfBirthValidation(min, max)]);
        }
        else {
            this.rsaIdControl.setValidators([Validators.pattern('[0-9]*'), rsaIdDateOfBirthValidation(min, max)]);
        }
        return this.rsaIdControl;
    };
    RsaIdDobComponent.prototype.onChange = function () {
        this.component.isValid = this.rsaIdControl.valid;
        this.component.value = this.rsaIdControl.value;
        if (!isNullOrUndefined(this.change)) {
            this.change.emit(this.component.counter);
        }
    };
    Object.defineProperty(RsaIdDobComponent.prototype, "errorMessage", {
        get: function () {
            if (this.rsaIdControl.hasError('too_young')) {
                return 'Sorry too young';
            }
            else if (this.rsaIdControl.hasError('too_old')) {
                return 'Sorry too old';
            }
            return this.featureSS3217_COMPULSORY_BENEFICIARY_ID ? 'Enter a Valid RSA-id' : 'Enter a Valid RSA-id or DOB : (yyyyMMdd)';
        },
        enumerable: true,
        configurable: true
    });
    return RsaIdDobComponent;
}(Dynamic));
export { RsaIdDobComponent };
