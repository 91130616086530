import {Component} from '@angular/core';
import {Validators} from '@angular/forms';
import {minMaxValidation} from '../../base/min.max.validator';
import {BeneficiariesComponent} from './beneficiaries.component';
import {DLUtil} from '../../base/dl.util';

@Component({
    selector: 'beneficiaries-uk',
    templateUrl: 'beneficiaries.component.html'
})
export class BeneficiariesUKComponent extends BeneficiariesComponent {

    protected buildForm() {
        this.hideId = true;
        this.hasEmail = true;
        this.canSkip = false;
        this.beneficiariesForm = this.fb.group({
            name: ['', Validators.required],
            relation: ['', Validators.required],
            email: ['', [Validators.email, Validators.pattern(DLUtil.emailPattern)]],
            percentage: ['', [Validators.required, minMaxValidation(1, 100)]]
        });
    }

}
