import {Component} from '@angular/core';
import {Dynamic} from './component';
import {FormControl, Validators} from '@angular/forms';
import {NumberValidationVO, PageComponentVO} from '../page.data.vo';
import {Log} from 'ng2-logger/client';
import {MatRadioChange} from '@angular/material';


const log = Log.create('PantSizeComponent');

@Component({
    selector: 'pantsize-input',
    styleUrls: ['pantsize.component.scss'],
    templateUrl: 'pantsize.component.html'
})

export class PantSizeComponent extends Dynamic<PageComponentVO> {
    min = 2;
    max = 50;

    radioGroupControl = new FormControl('');
    textControl: FormControl = new FormControl('', [Validators.min(this.min), Validators.max(this.max)]);
    pantSizeGroupControl = new FormControl('');

    validationMessage: string = undefined;
    selectorRadioText: Array<string> = ['Pant Size Number', 'Pant Size'];
    pantSizeSelectorValues: Array<string> = ['Extra Extra Small or Smaller', 'Extra Small', 'Small'
        , 'Medium', 'Large', 'Extra Large', 'Extra Extra Large', 'Extra Extra Extra Large or Larger'];
    currentMethodSelector = 0;

    onPantSizeSelectorChange(event: MatRadioChange): void {
        log.info('Pantsize Selector Value : ' + event.value);
        this.component.value = event.value;
        this.component.isValid = true;
        this.validationMessage = this.buildValidationMessage();
    }

    onPantSizeNumericChange(): void {
        log.info('Numeric Selector' + this.textControl.value);
        this.component.value = this.textControl.value;
        if (this.textControl.value >= 2 && this.textControl.value <= 50) {
            this.component.isValid = true;
        } else {
            this.component.isValid = false;
        }
        this.validationMessage = this.buildValidationMessage();
    }

    optionChange(event: MatRadioChange): void {
        this.component.isValid = false;
        log.info('Option Update Event' + event.value);
        this.currentMethodSelector = event.value === 'Pant Size' ? 1 : 2;
        this.validationMessage = this.buildValidationMessage();
    }

    getFormControl(): FormControl {
        return this.radioGroupControl;
    }

    processExtraData() {
        this.validationMessage = this.buildValidationMessage();
    }

    private buildValidationMessage(): string {
        if (this.currentMethodSelector <= 1) {
            return this.component.name + ' is required';
        } else if (this.currentMethodSelector === 2) {
            return this.component.name + ' must be between ' + this.min + ' and ' + this.max;
        }
    }

    setInitialValue() {
    }
}
