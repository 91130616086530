/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./info.component";
var styles_InfoComponent = [];
var RenderType_InfoComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InfoComponent, data: {} });
export { RenderType_InfoComponent as RenderType_InfoComponent };
export function View_InfoComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [], [[8, "innerHTML", 1], [1, "data-name", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.component.value; var currVal_1 = _co.component.id; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_InfoComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "info-view", [], null, null, null, View_InfoComponent_0, RenderType_InfoComponent)), i0.ɵdid(1, 49152, null, 0, i1.InfoComponent, [], null, null)], null, null); }
var InfoComponentNgFactory = i0.ɵccf("info-view", i1.InfoComponent, View_InfoComponent_Host_0, { flowId: "flowId", formGroup: "formGroup", component: "component", pageName: "pageName", _productCode: "_productCode", groupId: "groupId", _spMode: "_spMode" }, { change: "change", loaded: "loaded", validated: "validated", resume: "resume", altContinue: "altContinue" }, []);
export { InfoComponentNgFactory as InfoComponentNgFactory };
