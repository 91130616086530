import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorModalProvider } from '../base/error/error.modal.service';
import { Observable, throwError } from 'rxjs';
import { ErrorVO } from '../base/error/error.vo';
import { catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../base/error/error.modal.service";
var httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
var ContactBlockService = /** @class */ (function () {
    function ContactBlockService(http, errorModalProvider) {
        this.http = http;
        this.errorModalProvider = errorModalProvider;
    }
    ContactBlockService.prototype.submitCallMeRequest = function (leadData) {
        return this.http.post('/dl/data/contact-me', JSON.stringify(leadData), httpOptions).pipe(catchError(this.handleError()));
    };
    ContactBlockService.prototype.submitCallMeRequestNoEmail = function (leadData) {
        console.log('About to send a request to create an email and skip creating a lead');
        return this.http.post('/dl/data/contact-me-no-lead', JSON.stringify(leadData), httpOptions).pipe(catchError(this.handleError()));
    };
    ContactBlockService.prototype.handleError = function () {
        var _this = this;
        return function (error) {
            var vo = ErrorVO.toErrorVO(error);
            _this.errorModalProvider.showError(vo);
            return throwError(vo);
        };
    };
    ContactBlockService.ngInjectableDef = i0.defineInjectable({ factory: function ContactBlockService_Factory() { return new ContactBlockService(i0.inject(i1.HttpClient), i0.inject(i2.ErrorModalProvider)); }, token: ContactBlockService, providedIn: "root" });
    return ContactBlockService;
}());
export { ContactBlockService };
var ContactMeVO = /** @class */ (function () {
    function ContactMeVO() {
        this.leadtype = 'life_call_me';
        this.leadsource = 'web';
    }
    return ContactMeVO;
}());
export { ContactMeVO };
