<div [formGroup]="setPasswordForm">
    <div class="dl-row row" *ngIf="errorMsg !== ''">
        <div class="dl-col-md-12 col s12">
            <p class="mat-warn">{{ errorMsg }}</p>
        </div>
    </div>
    <div class="dl-row row">
        <div class="dl-col-md-12 col s12" data-name="login-password">
            <mat-form-field class="dl-full-width">
                <input
                    matInput
                    required
                    placeholder="Password"
                    id="password"
                    type="password"
                    formControlName="password">
            </mat-form-field>
        </div>
    </div>
    <div class="dl-row row">
        <div class="dl-col-md-12 col s12" data-name="login-password-confirm">
            <mat-form-field class="dl-full-width">
                <input
                    matInput
                    required
                    placeholder="Confirm your password"
                    id="confirmPassword"
                    type="password"
                    formControlName="confirmPassword">
            </mat-form-field>
        </div>
    </div>
    <div class="dl-row row">
        <div class="dl-col-md-12 col s12">
            <p class="help-block">{{passwordHelpText}}</p>
        </div>
    </div>
    <div class="dl-row row">
        <div class="dl-col-md-12 col s12">
            <button
                mat-raised-button
                color="primary"
                class="dl-full-width dl-action-btn dl-continue-btn"
                type="submit"
                id="login-continue"
                [attr.data-event]="'sendRequest'"
                [attr.data-event-category]="'Reset Password'"
                [attr.data-event-name]="'Reset Password'"
                [attr.data-event-label]="'Request reset password'"
                [disabled]="setPasswordForm.invalid"
                (click)="sendReset()">Reset Password&nbsp;
                <i *ngIf="!busy" class="fa fa-chevron-right"></i>
                <i *ngIf="busy" class="fa fa-circle-o-notch fa-spin"></i>
            </button>
        </div>
    </div>
</div>
