import {Dynamic} from './component';
import {Component} from '@angular/core';
import {isNullOrUndefined} from 'util';
import {FormControl, Validators} from '@angular/forms';
import {DLUtil} from '../../base/dl.util';
import { PageComponentVO } from '../page.data.vo';

@Component({
    selector: 'email-input',
    templateUrl: 'email.component.html'
})
export class EmailComponent extends Dynamic<PageComponentVO> {
    emailControl: FormControl = new FormControl('');

    onChange(): void {
        this.component.value = this.emailControl.value;
        this.component.isValid = this.emailControl.valid;
        if (!isNullOrUndefined(this.change)) {
            this.change.emit(this.component.counter);
        }
    }

    getFormControl(): FormControl {
        if (isNullOrUndefined(this.component.required) || this.component.required) {
            this.emailControl.setValidators([Validators.required, Validators.email, Validators.pattern(DLUtil.emailPattern)]);
        } else {
            this.emailControl.setValidators([Validators.email, Validators.pattern(DLUtil.emailPattern)]);
        }
        return this.emailControl;
    }
}
