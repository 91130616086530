import { EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PasswordMatchValidator } from '../base/passwordMatch.validator';
import { Log } from 'ng2-logger/browser';
import { isNullOrUndefined } from 'util';
import { LoginService } from './login.service';
import { WindowRef } from '../base/window.ref';
import { DLUtil } from '../base/dl.util';
var log = Log.create('dl-login');
var LoginComponent = /** @class */ (function () {
    function LoginComponent(loginService, winRef) {
        this.loginService = loginService;
        this.winRef = winRef;
        this._loginAction = 'username_password';
        this._standalone = true;
        this._autoRedirect = 'true';
        this._hideHeading = 'false';
        this._dateFormat = 'YYYY-MM-DD';
        this._legacy = '';
        this.moveToNext = new EventEmitter();
        this.requireId = false;
        this.loginLoadingBusy = true;
        this.continueBusy = false;
        this.telMask = DLUtil.telMask;
        this.setupForm();
    }
    Object.defineProperty(LoginComponent.prototype, "loginAction", {
        get: function () {
            return this._loginAction;
        },
        set: function (value) {
            this._loginAction = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "ssoUserName", {
        get: function () {
            return this._ssoUserName;
        },
        set: function (value) {
            this._ssoUserName = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "leadId", {
        get: function () {
            return this._leadId;
        },
        set: function (value) {
            this._leadId = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "nextAction", {
        get: function () {
            return this._nextAction;
        },
        set: function (value) {
            this._nextAction = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "orgUrl", {
        get: function () {
            return this._orgUrl;
        },
        set: function (value) {
            this._orgUrl = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "oauthClientId", {
        get: function () {
            return this._oauthClientId;
        },
        set: function (value) {
            this._oauthClientId = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "standalone", {
        get: function () {
            return this._standalone;
        },
        set: function (value) {
            this._standalone = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "salesProcessId", {
        get: function () {
            return this._salesProcessId;
        },
        set: function (value) {
            this._salesProcessId = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "autoRedirect", {
        get: function () {
            return this._autoRedirect;
        },
        set: function (value) {
            this._autoRedirect = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "hideHeading", {
        get: function () {
            return this._hideHeading;
        },
        set: function (value) {
            this._hideHeading = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "dateFormat", {
        get: function () {
            return this._dateFormat;
        },
        set: function (value) {
            this._dateFormat = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "setLegacy", {
        set: function (value) {
            this._legacy = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "getLegacy", {
        get: function () {
            return this._legacy === 'SG';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "showUsernameField", {
        get: function () {
            return this.loginAction === 'username_password';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "showIdField", {
        get: function () {
            return this.loginAction === 'set_password_with_id' || this.requireId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "showDOBField", {
        get: function () {
            return this.loginAction === 'set_password_with_dob' || this.loginAction === 'confirm_dob';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "showPhoneField", {
        get: function () {
            return this.loginAction === 'confirm_phone';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "showPassword", {
        get: function () {
            return this.showIdField || this.loginAction === 'set_password_with_dob' || this.showUsernameField;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "showConfirmPassword", {
        get: function () {
            return this.setPassword;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "passwordPlaceHolder", {
        get: function () {
            if (this.showConfirmPassword) {
                return 'Choose a password';
            }
            return 'Password';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "passwordHelpText", {
        get: function () {
            return PasswordMatchValidator.passwordPatternHelpText();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "subHeading", {
        get: function () {
            if (this.showIdField) {
                return 'Please confirm your ID number and choose a password to continue.';
            }
            if (this.loginAction === 'set_password_with_dob') {
                return 'Please confirm your birth date and choose a password to continue.';
            }
            if (this.loginAction === 'confirm_dob') {
                return 'Please confirm your birth date to continue.';
            }
            if (this.showPhoneField) {
                return 'Please enter your cell phone number to continue.';
            }
            if (this.showPassword) {
                return 'Please login to continue.';
            }
            return 'Continue to My Policy Dashboard.';
        },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.setupForm = function () {
        var _this = this;
        this.formGroup = new FormGroup({
            username: new FormControl('', [Validators.required]),
            phone: new FormControl('', [Validators.required]),
            idnumber: new FormControl('', [Validators.required, Validators.pattern(/[0-9]{13}/)]),
            dob: new FormControl('', [Validators.required]),
            password: new FormControl('', [
                Validators.required,
                Validators.pattern(PasswordMatchValidator.passwordPattern()),
            ]),
            confirmPassword: new FormControl('', [Validators.required]),
        }, PasswordMatchValidator.matchPasswords());
        this.formGroup.get('dob').valueChanges.subscribe(function () {
            _this.dob = new Date(_this.formGroup.get('dob').value);
        });
    };
    LoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        // need to do more or less the same as what is done on the current sp Login Component
        // disable all fields
        this.disableAll();
        // enable fields required for action
        if (this.standalone) {
            // if sp id 1st get login action...
            if (!isNullOrUndefined(this.salesProcessId)) {
                this.loginService.getLoginActionForSP(this.salesProcessId).subscribe(function (res) {
                    if (!isNullOrUndefined(res.payload)) {
                        if (!isNullOrUndefined(res.payload.login_action)) {
                            _this.loginAction = res.payload.login_action;
                        }
                        if (!isNullOrUndefined(res.payload.username)) {
                            _this.ssoUserName = res.payload.username;
                        }
                        if (!isNullOrUndefined(res.payload.next_action)) {
                            _this.nextAction = res.payload.next_action;
                        }
                        if (!isNullOrUndefined(res.payload.lead_id)) {
                            _this.leadId = res.payload.lead_id;
                        }
                    }
                    _this.init();
                }, function () {
                    _this.loginLoadingBusy = false;
                    _this.init();
                });
            }
            else {
                this.init();
            }
        }
    };
    LoginComponent.prototype.disableAll = function () {
        this.formGroup.get('phone').disable();
        this.formGroup.get('username').disable();
        this.formGroup.get('idnumber').disable();
        this.formGroup.get('dob').disable();
        this.formGroup.get('password').disable();
        this.formGroup.get('confirmPassword').disable();
    };
    LoginComponent.prototype.init = function () {
        var _this = this;
        if (this.showIdField) {
            log.info('set pass with id');
            this.setPassword = true;
            this.formGroup.get('idnumber').enable();
            this.formGroup.get('password').enable();
            this.formGroup.get('confirmPassword').enable();
            this.formGroup.get('username').setValue(this.ssoUserName);
            this.loginLoadingBusy = false;
        }
        else if (this.loginAction === 'set_password_with_dob') {
            log.info('set pass with dob');
            this.setPassword = true;
            this.formGroup.get('dob').enable();
            this.formGroup.get('password').enable();
            this.formGroup.get('confirmPassword').enable();
            this.formGroup.get('username').setValue(this.ssoUserName);
            this.loginLoadingBusy = false;
        }
        else if (this.showPhoneField) {
            log.info('show phone');
            this.setPassword = false;
            this.formGroup.get('phone').enable();
            this.loginLoadingBusy = false;
        }
        else if (this.loginAction === 'confirm_dob') {
            log.info('show dob');
            this.setPassword = false;
            this.formGroup.get('dob').enable();
            this.loginLoadingBusy = false;
        }
        else {
            log.info('show username / password');
            this.setPassword = false;
            this.formGroup.get('username').enable();
            this.formGroup.get('password').setValidators(Validators.required);
            this.formGroup.get('password').enable();
            setTimeout(function () {
                _this.loginService.loginCheck(_this, _this.ssoUserName, _this.orgUrl, _this.oauthClientId);
            });
        }
    };
    LoginComponent.prototype.processSignIn = function (success, message) {
        log.info('processSignIn - ' + success);
        if (success) {
            var url = null;
            if (!isNullOrUndefined(this.nextAction)) {
                log.info('next action ' + this.nextAction);
                if (this.nextAction === 'wallet') {
                    url = this.orgUrl + '/members/' + this.ssoUserName + '/wallet/';
                }
                else if (this.nextAction.substring(0, 4) === 'org/') {
                    url = this.orgUrl + '/' + this.nextAction.substring(4, this.nextAction.length);
                }
                else if (this.nextAction === 'profile') {
                    url = '/dl/profile';
                }
                else if (this.nextAction.indexOf('sp:') === 0) {
                    url = this.nextAction.substring(3);
                }
            }
            else if (!this.standalone) {
                var vo = { success: success, message: message };
                log.info('not standalone emitting moveToNext', vo);
                this.moveToNext.emit(vo);
                return;
            }
            if (isNullOrUndefined(url)) {
                log.info('No next action and we are not in a sp so load the profile');
                url = '/dl/profile';
            }
            if (this.autoRedirect === 'false' && this.loginLoadingBusy) {
                log.info('busy loading and not auto redirecting to ' + url);
                this.disableAll();
                this.loginAction = 'redirect_to_profile';
                this.redirectUrl = url;
                this.loginLoadingBusy = false;
            }
            else {
                log.info('redirect to ' + url);
                this.winRef.nativeWindow.location = url;
            }
        }
        else {
            if (!isNullOrUndefined(message)) {
                log.info('SignIn Error Message: ' + message);
                if (message === 'requires_id_number') {
                    this.requireId = true;
                    this.formGroup.get('idnumber').enable();
                    this.errorMessage = 'We found multiple accounts for that email address, please provide your id number too.';
                }
                else {
                    this.errorMessage = message;
                }
            }
            else {
                log.info('SignIn Error Message blank');
            }
            this.loginLoadingBusy = false;
            this.continueBusy = false;
        }
    };
    /**
     * Use this function to re init the login form. If not used as a standalone component and the data changes
     * Typically the app will populate all the needed data before this is called.
     */
    LoginComponent.prototype.initNonStandalone = function () {
        this.init();
    };
    LoginComponent.prototype.disableContinue = function () {
        return this.formGroup.invalid || this.loginLoadingBusy || this.continueBusy;
    };
    LoginComponent.prototype.processLogin = function () {
        var _this = this;
        this.continueBusy = true;
        if (this.setPassword) {
            log.info('set password');
            var data = {
                action: 'set',
                sales_process_id: this.salesProcessId,
                username: this.formGroup.get('username').value,
                password: this.formGroup.get('password').value,
            };
            if (this.showIdField) {
                data.idnumber = this.formGroup.get('idnumber').value;
            }
            else {
                data.action = 'set_with_dob';
                data.dob = null;
                if (!isNullOrUndefined(this.dob)) {
                    data.dob = DLUtil.formatDateToString(this.dob);
                }
            }
            this.loginService.setPassword(data).subscribe(function (res) {
                log.info('set password result', res);
                if (res.success) {
                    log.info('successfully set password, calling sign in');
                    _this.signInNoId();
                }
                else if (!isNullOrUndefined(res.message)) {
                    _this.errorMessage = res.message;
                    _this.continueBusy = false;
                }
            });
        }
        else if (this.showPhoneField) {
            log.info('check phone...');
            this.loginService.checkPhone(this.formGroup.get('phone').value, this.leadId, this.salesProcessId).subscribe(function (res) {
                if (!_this.standalone) {
                    _this.moveToNext.emit(res);
                    _this.continueBusy = false;
                }
                else {
                    _this.processSignIn(res.success, res.message);
                }
            }, function () { return _this.continueBusy = false; });
        }
        else if (this.showDOBField) {
            log.info('check dob...');
            this.loginService.checkDob(DLUtil.formatDateToString(this.dob), this.leadId, this.salesProcessId).subscribe(function (res) {
                if (!_this.standalone) {
                    _this.moveToNext.emit(res);
                    _this.continueBusy = false;
                }
                else {
                    _this.processSignIn(res.success, res.message);
                }
            }, function () { return _this.continueBusy = false; });
        }
        else if (this.showIdField) {
            log.info('signin with id...');
            this.signIn(this.formGroup.get('username').value, this.formGroup.get('password').value, this.formGroup.get('idnumber').value);
        }
        else if (this.loginAction === 'redirect_to_profile' && !isNullOrUndefined(this.redirectUrl)) {
            log.info('redirect to ' + this.redirectUrl);
            this.winRef.nativeWindow.location = this.redirectUrl;
        }
        else {
            log.info('signin no id...');
            this.signInNoId();
        }
    };
    LoginComponent.prototype.signInNoId = function () {
        this.signIn(this.formGroup.get('username').value, this.formGroup.get('password').value, null);
    };
    LoginComponent.prototype.signIn = function (userName, password, idnumber) {
        var _this = this;
        this.loginService.checkUser(userName, password, idnumber).subscribe(function (res) {
            _this.handleCheckUserResponse(res, userName, password);
        }, function () {
            _this.processSignIn(false, 'Error communicating to the login server, please try again');
        });
    };
    LoginComponent.prototype.handleCheckUserResponse = function (res, userName, password) {
        var _this = this;
        if (res.requires_id_number) {
            this.requireId = true;
            this.processSignIn(false, 'requires_id_number');
        }
        else if (!isNullOrUndefined(res.errorMsg)) {
            this.processSignIn(false, res.errorMsg);
        }
        else {
            if (!isNullOrUndefined(this.orgUrl) && this.orgUrl.length > 1) {
                if (!isNullOrUndefined(res.sso_user_login)) {
                    userName = res.sso_user_login;
                }
                this.loginService.loginToOrg(this.orgUrl, this.oauthClientId, userName, password, this)
                    .subscribe(function () { }, function () {
                    _this.processSignIn(false, 'Error communicating to the login server, please try again');
                });
            }
            else {
                // SG sign in.
                this.processSignIn(true);
            }
        }
    };
    LoginComponent.prototype.formParentClass = function () {
        return this.getLegacy ? 'm8' : '';
    };
    LoginComponent.prototype.processTraditionalLogin = function () {
        this.winRef.nativeWindow.location = 'https://portal.stangen.co.za:449/account/login';
    };
    return LoginComponent;
}());
export { LoginComponent };
