
import {Component} from '@angular/core';
import {Dynamic} from './component';
import {FormControl} from '@angular/forms';
import { PageComponentVO } from '../page.data.vo';

@Component({
    selector: 'info-view',
    templateUrl: 'info.component.html'
})
export class InfoComponent extends Dynamic<PageComponentVO> {


    setupFromControl() {
        this.component.loaded = true;
        // tel my parent I'm loaded.
        this.loaded.emit(this.component.id);
    }

    getFormControl(): FormControl {
        return null;
    }

    onChange(): void {
    }
}
