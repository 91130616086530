import { FormBuilder, Validators } from '@angular/forms';
import { ContactBlockService, ContactMeVO } from './contact-block.service';
import { isNullOrUndefined } from 'util';
import { DLUtil } from "../base/dl.util";
import { PhoneNumberValidator } from '../base/phone-number';
import { WindowRef } from '../base/window.ref';
var ContactBlockComponent = /** @class */ (function () {
    function ContactBlockComponent(fb, service, winRef) {
        this.fb = fb;
        this.service = service;
        this.winRef = winRef;
        this.rgbColour = 'rgba(255, 255, 255, 0.6)';
        this._bgColour = '255,255,255';
        this._opacity = 0.6;
        this._heading1 = 'Need assistance?';
        this._heading2 = '';
        this._bottomText1 = '';
        this._bottomText2 = '';
        this._buttonText = 'Please call me';
        this._showEmail = false;
        this._skipLead = false;
        this._control = '';
        this._campaignCode = '';
        this._leadSourceDescription = 'Contact Block';
        this._providerId = '';
        this._referrer = '';
        this._referrerCode = '';
        this.success = false;
        this.busy = false;
        this.initForm();
    }
    Object.defineProperty(ContactBlockComponent.prototype, "bgColour", {
        set: function (val) {
            this._bgColour = val;
            this.setBgColour();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactBlockComponent.prototype, "opacity", {
        set: function (val) {
            this._opacity = val;
            this.setBgColour();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactBlockComponent.prototype, "heading1", {
        get: function () {
            return this._heading1;
        },
        set: function (val) {
            this._heading1 = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactBlockComponent.prototype, "heading2", {
        get: function () {
            return this._heading2;
        },
        set: function (val) {
            this._heading2 = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactBlockComponent.prototype, "bottomText1", {
        get: function () {
            return this._bottomText1;
        },
        set: function (val) {
            this._bottomText1 = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactBlockComponent.prototype, "bottomText2", {
        get: function () {
            return this._bottomText2;
        },
        set: function (val) {
            this._bottomText2 = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactBlockComponent.prototype, "buttonText", {
        get: function () {
            return this._buttonText;
        },
        set: function (val) {
            this._buttonText = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactBlockComponent.prototype, "showEmail", {
        get: function () {
            return this._showEmail;
        },
        set: function (val) {
            this._showEmail = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactBlockComponent.prototype, "skipLead", {
        get: function () {
            return this._skipLead;
        },
        set: function (val) {
            this._skipLead = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactBlockComponent.prototype, "control", {
        get: function () {
            return this._control;
        },
        set: function (val) {
            this._control = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactBlockComponent.prototype, "campaignCode", {
        set: function (val) {
            this._campaignCode = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactBlockComponent.prototype, "leadSourceDescription", {
        set: function (val) {
            this._leadSourceDescription = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactBlockComponent.prototype, "providerId", {
        set: function (val) {
            this._providerId = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactBlockComponent.prototype, "referrer", {
        set: function (val) {
            this._referrer = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactBlockComponent.prototype, "referrerCode", {
        set: function (val) {
            this._referrerCode = val;
        },
        enumerable: true,
        configurable: true
    });
    ContactBlockComponent.prototype.setBgColour = function () {
        this.rgbColour = 'rgba(' + this._bgColour + ', ' + this._opacity + ')';
    };
    ContactBlockComponent.prototype.getBackGround = function () {
        return { "background-color": this.rgbColour };
    };
    ContactBlockComponent.prototype.initForm = function () {
        this.cbForm = this.fb.group({
            name: ['', [Validators.required]],
            cell: ['', [Validators.required, PhoneNumberValidator.validator()]],
            email: ['', [Validators.email, Validators.pattern(DLUtil.emailPattern)]]
        });
    };
    ContactBlockComponent.prototype.saveLead = function () {
        var _this = this;
        this.busy = true;
        var data = new ContactMeVO();
        var nameParts = this.cbForm.get('name').value.split(' ');
        if (nameParts.length > 1) {
            data.firstname = nameParts[0];
            for (var i = 1; i < nameParts.length; i++) {
                if (isNullOrUndefined(data.lastname)) {
                    data.lastname = nameParts[i];
                }
                else {
                    data.lastname += ' ' + nameParts[i];
                }
            }
        }
        else {
            data.firstname = this.cbForm.get('name').value;
        }
        data.phonemobile = this.cbForm.get('cell').value.replace(/\D+/g, '');
        if (this.cbForm.get('email').value !== '') {
            data.email = this.cbForm.get('email').value;
        }
        data.campaign_code = this._campaignCode;
        if (this._leadSourceDescription !== '') {
            data.leadsourcedescription = this._leadSourceDescription;
        }
        data.referrer_code = this._referrerCode;
        data.referrer = this._referrer;
        data.provider_id = this._providerId;
        if (this._control !== '') {
            data.control = this._control;
        }
        if (this._skipLead) {
            this.service.submitCallMeRequestNoEmail(data).subscribe(function () { return _this.showSuccess(); }, function () { return _this.handleFail(); });
        }
        else {
            this.service.submitCallMeRequest(data).subscribe(function () { return _this.showSuccess(); }, function () { return _this.handleFail(); });
        }
    };
    ContactBlockComponent.prototype.showSuccess = function () {
        this.winRef.nativeWindow.location = '/contact-thank-you';
        this.success = true;
        this.busy = false;
    };
    ContactBlockComponent.prototype.handleFail = function () {
        this.success = false;
        this.busy = false;
    };
    Object.defineProperty(ContactBlockComponent.prototype, "hasBottomText", {
        get: function () {
            return (!isNullOrUndefined(this.bottomText1) && this.bottomText1.length > 0) ||
                (!isNullOrUndefined(this.bottomText2) && this.bottomText2.length > 0);
        },
        enumerable: true,
        configurable: true
    });
    return ContactBlockComponent;
}());
export { ContactBlockComponent };
