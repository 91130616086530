<div class="dl-dot-org" cdk-trap-focus>
    <div mat-dialog-title class="dl-modal-header">
        <h4 class="dl-modal-title">
            {{title}}
            <a class="close right" (click)="dialogRef.close()" tabindex="-1" [attr.data-name]="'close-org-modal'">
                <i class="fa fa-times"></i>
            </a>
        </h4>
    </div>
    <div mat-dialog-content class="dl-modal-body">
        <div class="row">
            <div *ngIf="loading" class="col s12 center-align">
                <span style="font-size: 40px; color: #eee;" class="fa fa-circle-o-notch fa-spin"></span>
            </div>
            <div *ngIf="!loading && !projects" class="col s12 project">
                Unfortunately there are no active charities at the moment
            </div>
            <div *ngFor="let project of projects" class="col s12 m6">
                <mat-card class="project">
                    <mat-card-header>
                        <mat-card-title>{{project.projectName}}</mat-card-title>
                        <mat-card-subtitle *ngIf="project.parent">by {{project.parent.name}}</mat-card-subtitle>
                    </mat-card-header>
                    <img *ngIf="project.imageUrl" mat-card-image class="project-image" src="{{project.imageUrl}}" alt="{{project.projectName}}">
                    <mat-card-content>
                        {{project.projectDescription}}
                    </mat-card-content>
                    <mat-card-actions align="end">
                        <button mat-raised-button (click)="choseProject(project)" tabindex="-1">Choose</button>
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>
    </div>
</div>
