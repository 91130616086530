var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SpBaseService } from '../sp.service';
import { SpDataService } from '../sp.data.service';
import { catchError } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { BasePayLoad } from '../common.vos';
import { SpDisclosuresProvider } from '../sp.disclosures.provider.service';
import { HttpClient } from '@angular/common/http';
import { ErrorModalProvider } from '../../base/error/error.modal.service';
import { BankDetail } from '../../base/banking/dl.banking.vo';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../sp.data.service";
import * as i3 from "../../base/error/error.modal.service";
import * as i4 from "../sp.disclosures.provider.service";
var BenefitSummary = /** @class */ (function () {
    function BenefitSummary() {
    }
    return BenefitSummary;
}());
export { BenefitSummary };
var PaymentSummary = /** @class */ (function (_super) {
    __extends(PaymentSummary, _super);
    function PaymentSummary() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.premium = 0;
        _this.benefits = null;
        _this.showBeneficiaries = true;
        _this.beneficiaries = [];
        _this.bankingDetail = null;
        return _this;
    }
    PaymentSummary.copyForSubmission = function (from) {
        var to = new PaymentSummary();
        to.premium = from.premium;
        to.benefits = from.benefits;
        if (!isNullOrUndefined(from.beneficiaries) && from.beneficiaries.length > 0) {
            to.beneficiaries = from.beneficiaries;
        }
        to.bankingDetail = BankDetail.copyMe(from.bankingDetail);
        to.policyId = from.policyId;
        to.contactId = from.contactId;
        return to;
    };
    return PaymentSummary;
}(BasePayLoad));
export { PaymentSummary };
var PaymentService = /** @class */ (function (_super) {
    __extends(PaymentService, _super);
    function PaymentService(httpClient, spDataService, errorModalProvider, disclosuresProvider) {
        return _super.call(this, httpClient, spDataService, errorModalProvider, disclosuresProvider) || this;
    }
    PaymentService.prototype.savePayment = function (spId, data) {
        var url = '/dl/data/sp/' + spId + '/payment';
        return this.httpClient.post(url, PaymentSummary.copyForSubmission(data), this.httpOptions()).pipe(catchError(this.handleHTTPError()));
    };
    PaymentService.prototype.saveBeneficiaries = function (spId, benefitId, beneficiaries) {
        var url = '/dl/data/sp/' + spId + '/payment/benefit/' + benefitId + '/beneficiaries';
        return this.httpClient.post(url, beneficiaries, this.httpOptions()).pipe(catchError(this.handleHTTPError()));
    };
    PaymentService.prototype.saveBanking = function (spId, banking, policyId) {
        var url = '/dl/data/sp/' + spId + '/payment/banking/' + policyId;
        return this.httpClient.post(url, banking, this.httpOptions()).pipe(catchError(this.handleHTTPError()));
    };
    PaymentService.prototype.activatePolicy = function (spId, data) {
        var url = '/dl/data/sp/' + spId + '/payment/policy-activate';
        var dataToSend = {
            policyId: data.policyId,
            contactId: data.contactId
        };
        return this.httpClient.post(url, dataToSend, this.httpOptions()).pipe(catchError(this.handleHTTPError()));
    };
    PaymentService.prototype.updateTheCaseForWebAvsInvalid = function (spId) {
        var url = '/dl/data/sp/case/webAvsFailed/' + spId;
        return this.httpClient.post(url, {}, this.httpOptions()).pipe(catchError(this.handleHTTPError())).toPromise();
    };
    PaymentService.ngInjectableDef = i0.defineInjectable({ factory: function PaymentService_Factory() { return new PaymentService(i0.inject(i1.HttpClient), i0.inject(i2.SpDataService), i0.inject(i3.ErrorModalProvider), i0.inject(i4.SpDisclosuresProvider)); }, token: PaymentService, providedIn: "root" });
    return PaymentService;
}(SpBaseService));
export { PaymentService };
