export interface DisclosureGroupVO {
    id: string;
    name: string;
    action_description?: string;
    template_name?: string;
    disclosures: DisclosureVO[];
}

export interface DisclosureVO {
    id: string;
    name: string;
    type: string;
    content: string;
    step?: string;
    code?: string;
    stop_message?: string;
    action_event?: string;
    answer?: string;
    sales_process_id?: string;
}

export interface SuccessVO {
    success: boolean;
}

export interface StopVO {
    out_page: string;
}

export enum DisclosureType {
  ENTRY = 'Entry',
  EXIT = 'Exit',
  INFO = 'Info'
}

export enum DisclosureCategory {
  SALES_PROCESS = 'sales',
  CALCULATOR = 'calculator',
  PROFILE = 'profile'
}
