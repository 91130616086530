/**
 * Created by corneliusbotha on 2017/02/19.
 * VO class used to send the QQ request to pricing
 */
import {Benefit} from '../base/benefit';

export class QQPricingRequestVO {
    product_code: string;
    starting_age_year: number;
    birth_date: string;
    gender: string;
    smoker: string;
    income_gross_monthly: number;
    education: string;
    product_group: string;
    campaign_id: string;
    needs_id: string;
    needs_benefit_id: string;
    lead_id: string;
}

export interface QQPricingResponseVO extends Benefit {
    valid: boolean;
    qq_type?: string;
    needs_id?: string;
    needs_benefit_id?: string;
    errorReason?: string;
    version?: string;
    pricing_engine_version?: string;
}
