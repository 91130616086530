import {Component} from '@angular/core';
import {Dynamic} from './component';
import {isNullOrUndefined} from 'util';
import {FormControl, Validators} from '@angular/forms';
import { PageComponentVO } from '../page.data.vo';

/**
 * Created by corneliusbotha on 2017/07/19.
 * Basic input text component
 */

@Component({
    selector: 'select-input',
    templateUrl: 'select.component.html'
})
export class SelectComponent extends Dynamic<PageComponentVO> {

    selectControl: FormControl = new FormControl('', [Validators.required]);

    onChange(): void {
        this.component.isValid = this.selectControl.valid;
        this.component.value = this.selectControl.value;
        if (!isNullOrUndefined(this.change)) {
            this.change.emit(this.component.counter);
        }
    }

    getFormControl(): FormControl {
        return this.selectControl;
    }

    selectionChange(id: string) {
    }
}

