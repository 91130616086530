import { OnInit, EventEmitter } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MatSliderChange } from "@angular/material";
import { MLFModalData } from "../mlf-summary/mlf-dependant-modal.component";
import { CoverOptionsVO, MLFSummaryService, MLFUtil } from "../mlf-summary/mlf-summary.service";
import { Log } from 'ng2-logger/browser';
import { RsaId, rsaIdValidation } from "../rsa.id.validator";
import { isNullOrUndefined } from "util";
import { BehaviorSubject } from "rxjs";
import { DLUtil } from "../dl.util";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { ErrorVO } from "../error/error.vo";
import { LLCModalData } from "./llc-dependant-modal.component";
import { LLCDependantVO } from "./llc-summary.service";
var log = Log.create('LlcDependantComponent');
var LlcDependantComponent = /** @class */ (function () {
    function LlcDependantComponent(data, dialogRef, formBuilder, summaryService) {
        var _this = this;
        this.data = data;
        this.dialogRef = dialogRef;
        this.formBuilder = formBuilder;
        this.summaryService = summaryService;
        this.rsaId = new RsaId();
        this.isValid = false;
        this.isBusy = false;
        this.previouslyDuplicate = false;
        this.productDescriptions = {
            LLC_F_S: 'A <b>Spouse</b> is a person who is married to the policyholder in terms of law, common law, customary or as a partner (lived together for more than 12-months). Age Min: 18, Max: 65',
            LLC_F_C: 'A <b>Child</b> is a child born to the policyholder or spouse, or partner as well as legally adopted and guardian children. Age Max: 65',
            LLC_F_E: '<b>Parents / In-Laws</b> are the biological parents of the policyholder, or the biological parents of the policy holders spouse. Age Min: 18, Max: 75'
        };
        this.coverOptions$ = new BehaviorSubject(new CoverOptionsVO('invalid'));
        this.changeEvents$ = new EventEmitter();
        if (!isNullOrUndefined(this.data.dependant.benefit_amount)) {
            this.benefit_amount = this.data.dependant.benefit_amount;
        }
        if (!isNullOrUndefined(this.data.dependant.premium_amount)) {
            this.premium_amount = this.data.dependant.premium_amount;
        }
        this.formGroup = this.formBuilder.group({
            product: [this.data.dependant.product, [Validators.required]],
            first_name: [this.data.dependant.first_name, [Validators.required]],
            last_name: [this.data.dependant.last_name, [Validators.required]],
            gender: [this.data.dependant.gender, [Validators.required]],
            id_or_dob: [this.data.dependant.id_or_dob, [Validators.required, Validators.pattern('[0-9]*'), rsaIdValidation(true, this)]]
        });
        this.formGroup.get('first_name').valueChanges.pipe(debounceTime(450)).subscribe(function () { return _this.checkDuplicate(true); });
        this.formGroup.get('last_name').valueChanges.pipe(debounceTime(450)).subscribe(function () { return _this.checkDuplicate(true); });
        this.formGroup.get('id_or_dob').valueChanges.pipe(debounceTime(450)).subscribe(function () {
            _this.checkDuplicate(true);
            _this.rsaId.setId(_this.formGroup.get('id_or_dob').value);
            if (!isNullOrUndefined(_this.rsaId.getGender())) {
                _this.formGroup.get('gender').setValue((_this.rsaId.getGender() === 'Male' ? 'M' : 'F'));
            }
        });
        this.formGroup.get('product').valueChanges.subscribe(function () { return _this.productSelected(); });
        this.formGroup.get('gender').valueChanges.subscribe(function () { return _this.genderChanged(); });
        // Initialize an Observable for consolidated, debounced change events
        this.changeEvents$.pipe(debounceTime(650), distinctUntilChanged(function (a, b) {
            return a.rsaId.getId() === b.rsaId.getId() && a.product === b.product && a.gender === b.gender;
        })).subscribe(
        // Update premiums for change events
        function ($event) { return _this.fetchCoverOptions($event.rsaId, $event.product, $event.gender); });
    }
    LlcDependantComponent.prototype.ngOnInit = function () {
        try {
        }
        catch (error) {
            log.error(error);
        }
    };
    Object.defineProperty(LlcDependantComponent.prototype, "getTitleText", {
        get: function () {
            try {
                return !this.data.main ? (this.data.edit ? 'Edit' : 'Add') + " additional life" : 'Edit Main Member';
            }
            catch (error) {
                log.error(error);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LlcDependantComponent.prototype, "getAgeHint", {
        get: function () {
            try {
                return this.rsaId.isValidDOB() ? "Age: " + this.rsaId.getAge() + ", Birthdate: " + new Date(this.rsaId.getDOB()).toDateString() : '';
            }
            catch (error) {
                log.error(error);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LlcDependantComponent.prototype, "getProductHint", {
        get: function () {
            try {
                var product = this.formGroup.get('product').value;
                return !isNullOrUndefined(product) && !isNullOrUndefined(this.productDescriptions[product]) ? this.productDescriptions[product] : null;
            }
            catch (error) {
                log.error(error);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LlcDependantComponent.prototype, "getLeadName", {
        get: function () {
            try {
                return this.data.leadName;
            }
            catch (error) {
                log.error(error);
            }
        },
        enumerable: true,
        configurable: true
    });
    LlcDependantComponent.prototype.save = function () {
        this.isBusy = true;
        var data = new LLCModalData();
        DLUtil.copyFields(this.data, data);
        data.dependant = new LLCDependantVO();
        DLUtil.copyFields(this.data.dependant, data.dependant);
        DLUtil.copyFields(this.formGroup.value, data.dependant);
        data.dependant.benefit_amount = this.benefit_amount;
        data.dependant.premium_amount = this.premium_amount;
        data.dependant.product_master_id = this.product_master_id;
        data.dependant.pricing = this.pricing;
        data.dependant.age = this.rsaId.getAge();
        this.dialogRef.close(data);
    };
    LlcDependantComponent.prototype.cancel = function () {
        try {
            this.dialogRef.close('cancel');
        }
        catch (error) {
            log.error(error);
        }
    };
    LlcDependantComponent.prototype.getDisabledText = function (item) {
        try {
            return item.disabled && !isNullOrUndefined(MLFUtil.validation[item.id]) ? "(Max: " + MLFUtil.validation[item.id] + ")" : '';
        }
        catch (error) {
            log.error(error);
        }
    };
    LlcDependantComponent.prototype.formatDisplay = function (value) {
        try {
            return DLUtil.compactFormat(value, false);
        }
        catch (error) {
            log.error(error);
        }
    };
    LlcDependantComponent.prototype.updateCover = function (change) {
        try {
            this.benefit_amount = change.value;
            this.premium_amount = this.findPremiumForCover(change.value);
            this.formGroup.markAsDirty();
        }
        catch (error) {
            log.error(error);
        }
    };
    LlcDependantComponent.prototype.findPremiumForCover = function (cover) {
        var premium = 0;
        this.sliderPoints.forEach(function (option) {
            if (cover === option.cover) {
                premium = option.premium;
            }
        });
        return premium;
    };
    LlcDependantComponent.prototype.onIdValidationChange = function (rsaId) {
        if (!isNullOrUndefined(this.formGroup) &&
            !isNullOrUndefined(this.formGroup.get('product')) &&
            !isNullOrUndefined(this.formGroup.get('product').value) &&
            !isNullOrUndefined(this.formGroup.get('gender').value)) {
            var product = this.formGroup.get('product').value;
            var gender = this.formGroup.get('gender').value;
            this.changeEvents$.emit(new ChangeEvent(rsaId, product, gender));
        }
    };
    LlcDependantComponent.prototype.fetchCoverOptions = function (rsaId, product, inGender) {
        var _this = this;
        if (inGender === void 0) { inGender = null; }
        var age = 0;
        var gender = null;
        if (!this.data.main) {
            if (isNullOrUndefined(product) || product.length === 0) {
                log.info('no product selected');
                this.coverOptions$.next(new CoverOptionsVO('invalid'));
                return;
            }
            if (isNullOrUndefined(rsaId.getId()) || (rsaId.getId().length > 8 && !rsaId.isValid())) {
                log.info('id is invalid');
                this.coverOptions$.next(new CoverOptionsVO('invalid'));
                return;
            }
            if (!rsaId.isValidDOB() || isNullOrUndefined(product)) {
                log.info('dob is invalid or no product');
                this.coverOptions$.next(new CoverOptionsVO('invalid'));
                return;
            }
            age = rsaId.getAge();
            gender = isNullOrUndefined(rsaId.getGender()) ? inGender : rsaId.getGender() === 'Male' ? 'M' : 'F';
            if (isNullOrUndefined(gender)) {
                log.info('gender is invalid');
                this.coverOptions$.next(new CoverOptionsVO('invalid'));
                return;
            }
            if (this.checkDuplicate(false)) {
                log.info('');
                return;
            }
        }
        this.isValid = false;
        this.formGroup.disable({ onlySelf: true, emitEvent: false });
        this.coverOptions$.next(new CoverOptionsVO('loading'));
        var req = {
            spId: this.data.spId,
            benefitId: this.data.benefitId,
            product: product,
            age: age,
            gender: gender
        };
        this.summaryService.getPricing(req, 'LLC').subscribe(function (res) {
            _this.updateCoverOptions(rsaId, product, res);
            _this.formGroup.enable({ onlySelf: true, emitEvent: false });
        }, function (error) {
            _this.formGroup.enable({ onlySelf: true, emitEvent: false });
            var coverOptions = new CoverOptionsVO('invalid');
            coverOptions.error = ErrorVO.toErrorVO(error).message;
            _this.coverOptions$.next(coverOptions);
        });
    };
    LlcDependantComponent.prototype.updateCoverOptions = function (rsaId, product, res) {
        if (isNullOrUndefined(res)) {
            return;
        }
        if (res.hasOwnProperty('error')) {
            var coverOptions = new CoverOptionsVO('invalid');
            coverOptions.error = res.error;
            this.isValid = false;
            this.coverOptions$.next(coverOptions);
        }
        else {
            res.status = 'valid';
            if (!isNullOrUndefined(this.benefit_amount) && this.benefit_amount < res.max) {
                res.cover = this.benefit_amount;
            }
            else {
                res.cover = res.max;
            }
            this.sliderPoints = res.options;
            this.benefit_amount = res.cover;
            this.premium_amount = this.findPremiumForCover(res.cover);
            this.product_master_id = res.product_master_id;
            this.pricing = res.pricing;
            this.isValid = true;
            this.coverOptions$.next(res);
        }
    };
    LlcDependantComponent.prototype.checkDuplicate = function (fetch) {
        var _this = this;
        var isDuplicate = false;
        this.data.dependants.forEach(function (dependant) {
            if (_this.data.dependant.id !== dependant.id) {
                if (dependant.first_name === _this.formGroup.get('first_name').value &&
                    dependant.last_name === _this.formGroup.get('last_name').value &&
                    dependant.id_or_dob === _this.formGroup.get('id_or_dob').value &&
                    dependant.gender === _this.formGroup.get('gender').value) {
                    isDuplicate = true;
                    _this.isValid = false;
                    var coverOptions = new CoverOptionsVO('invalid');
                    coverOptions.error = 'Duplicate';
                    _this.coverOptions$.next(coverOptions);
                }
            }
        });
        if (!isDuplicate && fetch && this.previouslyDuplicate) {
            var coverOptions = new CoverOptionsVO('invalid');
            this.coverOptions$.next(coverOptions);
            var rsaId = new RsaId();
            rsaId.setId(this.formGroup.get('id_or_dob').value);
            var product = this.formGroup.get('product').value;
            var gender = this.formGroup.get('gender').value;
            this.fetchCoverOptions(rsaId, product, gender);
        }
        this.previouslyDuplicate = isDuplicate;
        return isDuplicate;
    };
    LlcDependantComponent.prototype.productSelected = function () {
        var rsaId = new RsaId();
        rsaId.setId(this.formGroup.get('id_or_dob').value);
        var gender = this.formGroup.get('gender').value;
        var product = this.formGroup.get('product').value;
        this.changeEvents$.emit(new ChangeEvent(rsaId, product, gender));
    };
    LlcDependantComponent.prototype.genderChanged = function () {
        var rsaId = new RsaId();
        rsaId.setId(this.formGroup.get('id_or_dob').value);
        var gender = this.formGroup.get('gender').value;
        if (!isNullOrUndefined(rsaId.getGender())) {
            gender = rsaId.getGender() === 'Male' ? 'M' : 'F';
        }
        if (gender !== this.formGroup.get('gender').value) {
            // Flip it back to what ID number says...
            this.formGroup.get('gender').setValue(gender);
            return;
        }
        var product = this.formGroup.get('product').value;
        this.changeEvents$.emit(new ChangeEvent(rsaId, product, gender));
    };
    return LlcDependantComponent;
}());
export { LlcDependantComponent };
var ChangeEvent = /** @class */ (function () {
    function ChangeEvent(rsaId, product, gender) {
        this.rsaId = rsaId;
        this.product = product;
        this.gender = gender;
    }
    return ChangeEvent;
}());
