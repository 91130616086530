import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import { isNullOrUndefined } from 'util';

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class MLFSummaryService {

    constructor(private http: HttpClient) {}

    public getPricing(request: MLFSimplePricingRequestVO, sp?: string): Observable<CoverOptionsVO> {
        if (!isNullOrUndefined(sp) && sp === 'LLC') return this.http.post<CoverOptionsVO>('/dl/data/pricing/simple-for-llc', request, httpOptions);
        return this.http.post<CoverOptionsVO>('/dl/data/pricing/simple-for-sp', request, httpOptions);
    }

}

export class MLFUtil {
    public static validation = {
        MLF_S: 1,
        MLF_C: 8,
        MLF_E: 4,
    };
}

export class MLFSimplePricingRequestVO {
    spId: string;
    benefitId: string;
    product: string;
    age: number;
    gender: string;
}

export class CoverOptionsVO {
    constructor(status?: string) {
        this.status = status;
    }
    status: string;
    min: number;
    max: number;
    step: number;
    cover: number;
    error: string;
    product_master_id: string;
    pricing: any;
    options: {
        cover: number;
        premium: number;
    }[];
}

export class MLFDependantVO {
    id: string;
    name: string;
    first_name: string;
    last_name: string;
    id_or_dob: string;
    age: number;
    rel: string;
    product: string;
    product_master_id: string;
    pricing: any;
    benefit_amount: number;
    premium_amount: number;
    deleting: boolean;
    gender: string;
}
