<div class="dl-row row" style="display: flex;">
    <div class="dl-col-md-8 col {{formParentClass()}} s12">
        <div class="dl-container" style="min-height: 150px;">
            <div *ngIf="loginLoadingBusy" class="dl-loading">
                <p>
                    Loading<br>
                    <span class="fa fa-circle-o-notch fa-spin"></span>
                </p>
            </div>
            <form [formGroup]="formGroup" class="login-form" novalidate>
                <div *ngIf="!loginLoadingBusy">
                    <h2 *ngIf="hideHeading !== 'true'">Welcome</h2>
                    <p>{{subHeading}}</p>
                    <div *ngIf="showUsernameField" class="dl-row row">
                        <div class="dl-col-md-6 col m6 s12" data-name="login-user-or-email">
                            <mat-form-field class="dl-full-width">
                                <input
                                    matInput
                                    required
                                    placeholder="Email or Username"
                                    id="username"
                                    type="text"
                                    tabindex="1"
                                    formControlName="username">
                            </mat-form-field>
                        </div>
                    </div>
                    <div *ngIf="showPhoneField" class="dl-row row">
                        <div class="dl-col-md-6 col m6 s12" data-name="login-phone-number">
                            <mat-form-field class="dl-full-width">
                                <input
                                    matInput
                                    phoneNumber
                                    required
                                    placeholder="Phone Number"
                                    id="phone"
                                    type="tel"
                                    tabindex="1"
                                    formControlName="phone">
                            </mat-form-field>
                        </div>
                    </div>
                    <div *ngIf="showIdField" class="dl-row row">
                        <div class="dl-col-md-6 col m6 s12" data-name="login-id-number">
                            <mat-form-field class="dl-full-width">
                                <!-- hack to get numeric pad to show type=tel, but still captured as text -->
                                <input
                                    matInput
                                    required
                                    placeholder="SA ID Number"
                                    id="idnumber"
                                    type="tel"
                                    tabindex="1"
                                    formControlName="idnumber">
                            </mat-form-field>
                        </div>
                    </div>
                    <div *ngIf="showDOBField" class="dl-row row">
                        <div class="dl-col-md-6 col m6 s12" data-name="login-dob">
                            <dl-date-picker-input
                                [formControlRef]="formGroup.controls['dob']"
                                [name]="'dob'"
                                [placeHolder]="'Birth date'"
                                [required]="true"
                                [isDob]="true"
                                [startView]="'multi-year'"
                                [minAge]="18"
                                [maxAge]="80"
                                [format]="dateFormat"
                            ></dl-date-picker-input>
                        </div>
                    </div>
                    <div *ngIf="showPassword" class="dl-row row">
                        <div class="dl-col-md-6 col m6 s12" data-name="login-password">
                            <mat-form-field class="dl-full-width">
                                <input
                                    matInput
                                    required
                                    [placeholder]="passwordPlaceHolder"
                                    id="password"
                                    type="password"
                                    tabindex="1"
                                    formControlName="password">
                            </mat-form-field>
                        </div>
                        <div *ngIf="showConfirmPassword" class="dl-col-md-6 col m6 s12" data-name="login-password-confirm">
                            <mat-form-field class="dl-full-width">
                                <input
                                    matInput
                                    required
                                    placeholder="Confirm your password"
                                    id="confirmPassword"
                                    type="password"
                                    tabindex="1"
                                    formControlName="confirmPassword">
                            </mat-form-field>
                        </div>
                    </div>
                    <div *ngIf="showConfirmPassword" class="dl-row row">
                        <div class="dl-col-md-12 col s12">
                            <p class="help-block">{{passwordHelpText}}</p>
                        </div>
                    </div>
                    <div class="dl-row row dl-action-row" style="margin-bottom: 20px">
                        <div *ngIf="showConfirmPassword" class="dl-col-md-6 col m6 s12"></div>
                        <div class="dl-col-md-6 col m6 s12">
                            <button
                                mat-raised-button
                                color="primary"
                                class="dl-full-width dl-action-btn dl-continue-btn"
                                type="submit"
                                id="login-continue"
                                tabindex="1"
                                [attr.data-event]="'true'"
                                [attr.data-event-category]="'Sales Process'"
                                [attr.data-event-name]="'Login Continue'"
                                [attr.data-event-label]="'Sales Process Login Continue'"
                                [disabled]="disableContinue()"
                                (click)="processLogin()">
                                Continue&nbsp;
                                <i *ngIf="!continueBusy" class="fa fa-chevron-right"></i>
                                <i *ngIf="continueBusy" class="fa fa-circle-o-notch fa-spin"></i>
                            </button>
                            <div>
                                <a *ngIf="showUsernameField" href="/dl/forgot" rel="nofollow" class="{{showConfirmPassword ? 'dl-pull-right right' : ''}}">Forgot your password?</a>
                                <span *ngIf="errorMessage" class="help-block mat-error {{showConfirmPassword ? 'text-right' : ''}}">{{errorMessage}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
