import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DspInjectable, DspService, httpOptions} from '../dsp.service';
import {HttpClient} from '@angular/common/http';
import {ErrorModalProvider} from '../../base/error/error.modal.service';
import {catchError} from 'rxjs/operators';
import {AjaxRequestVO, AjaxVO, PageInfoVO} from '../page.data.vo';

@Injectable({
    providedIn: 'root'
})
export class AjaxService extends DspInjectable  {
    private url = this.baseUrl + '/ajax';

    constructor(protected http: HttpClient,
                protected errorModalProvider: ErrorModalProvider,
                private dspService: DspService) {
        super();
    }

    public postRequest(val: AjaxVO): Observable<any> {
        const data = AjaxRequestVO.fromPageInfo(this.dspService.currentData, val);
        return this.http.post(this.url, data, httpOptions).pipe(
            catchError(this.handleError<PageInfoVO>(this.errorModalProvider))
        );
    }
}
