/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dot-org-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/card";
import * as i3 from "../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i4 from "@angular/common";
import * as i5 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "@angular/material/dialog";
import * as i11 from "./dot-org-modal.component";
import * as i12 from "./dot-org-data.service";
var styles_DotOrgModalComponent = [i0.styles];
var RenderType_DotOrgModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DotOrgModalComponent, data: {} });
export { RenderType_DotOrgModalComponent as RenderType_DotOrgModalComponent };
function View_DotOrgModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "col s12 center-align"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [["class", "fa fa-circle-o-notch fa-spin"], ["style", "font-size: 40px; color: #eee;"]], null, null, null, null, null))], null, null); }
function View_DotOrgModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "col s12 project"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Unfortunately there are no active charities at the moment "]))], null, null); }
function View_DotOrgModalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-card-subtitle", [["class", "mat-card-subtitle"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatCardSubtitle, [], null, null), (_l()(), i1.ɵted(2, null, ["by ", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.parent.name; _ck(_v, 2, 0, currVal_0); }); }
function View_DotOrgModalComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "img", [["class", "project-image mat-card-image"], ["mat-card-image", ""]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatCardImage, [], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.imageUrl, ""); var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.projectName, ""); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_DotOrgModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "col s12 m6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 18, "mat-card", [["class", "project mat-card"]], null, null, null, i3.View_MatCard_0, i3.RenderType_MatCard)), i1.ɵdid(2, 49152, null, 0, i2.MatCard, [], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 6, "mat-card-header", [["class", "mat-card-header"]], null, null, null, i3.View_MatCardHeader_0, i3.RenderType_MatCardHeader)), i1.ɵdid(4, 49152, null, 0, i2.MatCardHeader, [], null, null), (_l()(), i1.ɵeld(5, 0, null, 1, 2, "mat-card-title", [["class", "mat-card-title"]], null, null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i2.MatCardTitle, [], null, null), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, 1, 1, null, View_DotOrgModalComponent_4)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_DotOrgModalComponent_5)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, 0, 2, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(13, 16384, null, 0, i2.MatCardContent, [], null, null), (_l()(), i1.ɵted(14, null, [" ", " "])), (_l()(), i1.ɵeld(15, 0, null, 0, 4, "mat-card-actions", [["align", "end"], ["class", "mat-card-actions"]], [[2, "mat-card-actions-align-end", null]], null, null, null, null)), i1.ɵdid(16, 16384, null, 0, i2.MatCardActions, [], { align: [0, "align"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 2, "button", [["mat-raised-button", ""], ["tabindex", "-1"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.choseProject(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(18, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Choose"]))], function (_ck, _v) { var currVal_1 = _v.context.$implicit.parent; _ck(_v, 9, 0, currVal_1); var currVal_2 = _v.context.$implicit.imageUrl; _ck(_v, 11, 0, currVal_2); var currVal_5 = "end"; _ck(_v, 16, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.projectName; _ck(_v, 7, 0, currVal_0); var currVal_3 = _v.context.$implicit.projectDescription; _ck(_v, 14, 0, currVal_3); var currVal_4 = (i1.ɵnov(_v, 16).align === "end"); _ck(_v, 15, 0, currVal_4); var currVal_6 = (i1.ɵnov(_v, 18).disabled || null); var currVal_7 = (i1.ɵnov(_v, 18)._animationMode === "NoopAnimations"); _ck(_v, 17, 0, currVal_6, currVal_7); }); }
export function View_DotOrgModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["cdk-trap-focus", ""], ["class", "dl-dot-org"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "dl-modal-header mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i10.MatDialogTitle, [[2, i10.MatDialogRef], i1.ElementRef, i10.MatDialog], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "h4", [["class", "dl-modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["class", "close right"], ["tabindex", "-1"]], [[1, "data-name", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dialogRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "fa fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 8, "div", [["class", "dl-modal-body mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i10.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DotOrgModalComponent_1)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DotOrgModalComponent_2)), i1.ɵdid(13, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DotOrgModalComponent_3)), i1.ɵdid(15, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_3 = _co.loading; _ck(_v, 11, 0, currVal_3); var currVal_4 = (!_co.loading && !_co.projects); _ck(_v, 13, 0, currVal_4); var currVal_5 = _co.projects; _ck(_v, 15, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 4, 0, currVal_1); var currVal_2 = "close-org-modal"; _ck(_v, 5, 0, currVal_2); }); }
export function View_DotOrgModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dot-org-modal", [], null, null, null, View_DotOrgModalComponent_0, RenderType_DotOrgModalComponent)), i1.ɵdid(1, 114688, null, 0, i11.DotOrgModalComponent, [i10.MatDialogRef, i12.DotOrgDataService, i10.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DotOrgModalComponentNgFactory = i1.ɵccf("dot-org-modal", i11.DotOrgModalComponent, View_DotOrgModalComponent_Host_0, {}, {}, []);
export { DotOrgModalComponentNgFactory as DotOrgModalComponentNgFactory };
