var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Dynamic } from './component';
var InfoComponent = /** @class */ (function (_super) {
    __extends(InfoComponent, _super);
    function InfoComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    InfoComponent.prototype.setupFromControl = function () {
        this.component.loaded = true;
        // tel my parent I'm loaded.
        this.loaded.emit(this.component.id);
    };
    InfoComponent.prototype.getFormControl = function () {
        return null;
    };
    InfoComponent.prototype.onChange = function () {
    };
    return InfoComponent;
}(Dynamic));
export { InfoComponent };
