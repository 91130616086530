<p-accordion [activeIndex]="index" (onOpen)="onTabEvent($event)" (onClose)="onTabEvent($event)">
    <!-- disabled="{{product.detail[0].amount <= 0}}" -->
    <p-accordionTab *ngFor="let product of component.extraInput" [selected]="product.selected" class="{{product.class}}" [attr.data-name]="product.id">
        <p-header>
            <h3><span [innerHTML]="product.name"></span><i *ngIf="product.selected" class="fa fa-check-circle right" aria-hidden="true"></i></h3>
        </p-header>
        <p [innerHTML]="product.desc"></p>
        <div *ngFor="let item of product.detail" class="row">
            <div class="col s4 dl-text-center"><label>{{item.amount}}</label></div>
            <div class="col s8"><label [innerHTML]="item.description"></label></div>
        </div>
    </p-accordionTab>
</p-accordion>
