import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactBlockComponent } from './contact-block.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import {MatButtonModule, MatCheckboxModule, MatInputModule} from '@angular/material';
import {ReactiveFormsModule} from '@angular/forms';
import {TextMaskModule} from 'angular2-text-mask';
import {HttpClientModule} from '@angular/common/http';
import {BaseModule} from '../base/base.module';
import { ContactBlockExpandedComponent } from './contact-block-expanded/contact-block-expanded.component';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatButtonModule,
        TextMaskModule,
        HttpClientModule,
        BaseModule,
        MatCheckboxModule
    ],
  declarations: [
    ContactBlockComponent,
    ContactBlockExpandedComponent
  ],
  entryComponents: [
     ContactBlockComponent,
     ContactBlockExpandedComponent
  ],
})
export class ContactBlockModule { }
