import {FormGroup, Validators} from '@angular/forms';
import {isNullOrUndefined} from 'util';
import {SelectItem} from './select.vo';
import {PhoneNumberValidator} from './phone-number';
import {Moment} from 'moment';

export class DLUtil {

    public static telMask: any = {
        mask: ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    };

    public static emailPattern = '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$';

    public static numberPattern = '[0-9]*';

    public static verificationType: SelectItem[] = [
        {value: 'idNumber', label: 'Id Number'},
        {value: 'passportNumber', label: 'Passport'},
    ];

    public static salutations: SelectItem[] = [
        {value: 'Mr.', label: 'Mr'},
        {value: 'Ms.', label: 'Ms'},
        {value: 'Mrs.', label: 'Mrs'},
        {value: 'Dr.', label: 'Dr'},
        {value: 'Prof.', label: 'Prof'}
    ];

    public static relationOptions: SelectItem[] = [
        {value: 'spouse', label: 'Spouse'},
        {value: 'child', label: 'Child'},
        {value: 'parent', label: 'Parent'},
        {value: 'other', label: 'Other'}
    ];

    public static beneficiaryRelationOptions: SelectItem[] = [
        {value: 'spouse', label: 'Spouse'},
        {value: 'child', label: 'Child'},
        {value: 'parent', label: 'Parent'},
        {value: 'estate', label: 'Estate'},
        {value: 'other', label: 'Other'}
    ];

    public static beneficiaryChangeReasons: SelectItem[] = [
        {value: 'beneficiary_deceased', label: 'Beneficiary deceased'},
        {value: 'relationship_with_beneficiary_terminated', label: 'Relationship with beneficiary terminated'},
        {value: 'addition_to_beneficiaries', label: 'Addition to beneficiaries'},
        {value: 'change_benefit_payout_distribution', label: 'Change benefit payout distribution'},
        {value: 'other', label: 'Other'}
    ];

    public static numMask: any = {
        mask: function (rawValue: string) {
            const numValue = rawValue.replace(/\D+/g, '');
            const mask = [];
            for (let i = numValue.length - 1; i >= 0; i--) {
                if ((numValue.length - i) > 2 && (numValue.length - i) % 3 === 1) {
                    mask.unshift(',');
                }
                mask.unshift(/\d/);
            }
            return mask;
        }
    };

    public static numMaskWith2Decimal: any = {
        mask: function (rawValue: string) {
            const parts = rawValue.split('.');
            const numValue = parts[0].replace(/\D+/g, '');
            const mask = [];
            for (let i = numValue.length - 1; i >= 0; i--) {
                if ((numValue.length - i) > 2 && (numValue.length - i) % 3 === 1) {
                    mask.unshift(',');
                }
                mask.unshift(/\d/);
            }
            if (parts.length === 2) {
                const fraction = parseInt(parts[1].replace(/\D+/g, ''), 10);
                mask.push('.');
                if (fraction < 10) {
                    mask.push('0');
                } else {
                    mask.push(/\d/);
                }
                mask.push(/\d/);
            } else {
                mask.push('.');
                mask.push('0');
                mask.push('0');
            }
            return mask;
        }
    };


    public static buildFormGroup(data: any,
                                 options?: {
                                     allRequired?: boolean,
                                     required?: string[],
                                     notRequired?: string[],
                                     extraValidators?: {
                                         field: string,
                                         validators: Validators[]
                                     }[],
                                     ignore?: string[]}) {
        if (isNullOrUndefined(options)) {
            options = {};
        }
        if (isNullOrUndefined(options.allRequired)) {
            options.allRequired = false;
        }
        if (isNullOrUndefined(options.required)) {
            options.required = [];
        }
        if (isNullOrUndefined(options.notRequired)) {
            options.notRequired = [];
        }
        if (isNullOrUndefined(options.ignore)) {
            options.ignore = [];
        }
        const formGroup = {};
        for (const key in data) {
            if (key === 'id') {
                continue;
            }
            if (options.ignore.indexOf(key) >= 0) {
                continue;
            }
            const validators = [];
            if ((options.allRequired && options.notRequired.indexOf(key) === -1) || options.required.indexOf(key) >= 0) {
                validators.push(Validators.required);
            }
            if (key.indexOf('phone') >= 0 || key.indexOf('tel_no') >= 0) {
                validators.push(PhoneNumberValidator.validator());
            } else if (key.indexOf('email') >= 0) {
                validators.push(Validators.email);
                validators.push(Validators.pattern(DLUtil.emailPattern));
            } else if (key.indexOf('id_number') >= 0) {
                validators.push(Validators.pattern(DLUtil.numberPattern));
            }
            if (!isNullOrUndefined(options.extraValidators)) {
                options.extraValidators.forEach(v => {
                    if (v.field === key) {
                        v.validators.forEach(v2 => validators.push(v2));
                    }
                });
            }
            formGroup[key] = ['', validators];
        }
        return formGroup;
    }

    public static setFormFields(form: FormGroup, data: any) {
        for (const key in data) {
            if (form.contains(key) && !isNullOrUndefined(data[key])) {
                form.get(key).setValue(data[key]);
            }
        }
    }

    public static copyFields(source: any, destination: any, except?: string[]) {
        if (isNullOrUndefined(except)) {
            except = [];
        }
        for (const key in source) {
            if (!isNullOrUndefined(source[key]) && !(except.includes(key))) {
                destination[key] = source[key];
            }
        }
    }

    public static knownAsPopulator(form: FormGroup, firstNameField: string, knownAsField: string) {
        if (isNullOrUndefined(form.get(knownAsField).value) || form.get(knownAsField).value.length === 0) {
            form.get(firstNameField).valueChanges.subscribe(() => {
                const firstNames = form.get(firstNameField).value;
                if (!isNullOrUndefined(firstNames)) {
                    const knownAsControl = form.get(knownAsField);
                    if (knownAsControl.pristine) { // User has not changed it
                        const firstNamesList = firstNames.split(' ');
                        if (firstNamesList.length >= 0) {
                            knownAsControl.setValue(firstNamesList[0]);
                        }
                    }
                }
            });
        }
    }

    public static setTitleFromGender(gender: string, form: FormGroup, titleField: string) {
        const title = form.get(titleField).value;
        if (gender === 'Male' && (isNullOrUndefined(title) || title === '' || title === 'Ms.' || title === 'Mrs.')) {
            form.get(titleField).setValue('Mr.');
        } else if (gender === 'Female' && (isNullOrUndefined(title) || title === '' || title === 'Mr.')) {
            form.get(titleField).setValue('Ms.');
        }
    }

    public static compactFormat(value: number, showFraction: boolean): string {
        if (value > 999999) {
            return this.getCompactFormat(value, 1000000, 'M', true);
        } else if (value > 999) {
            return this.getCompactFormat(value, 1000, 'K', showFraction);
        } else {
            return Math.round(value).toString();
        }
    }

    private static getCompactFormat(val: number, divVal: number, postFix: string, showFraction: boolean): string {
        let[intValue, fraction = ''] = ((val / divVal) || '').toString().split('.');
        // remove tslint error in ide.
        intValue = intValue + '';
        fraction = fraction + '00000';
        if (showFraction) {
            return intValue + '.' + fraction.substring(0, 1) + postFix;
        } else {
            return intValue + postFix;
        }
    }

    public static formatDateToString(date: Date): string {
        let dateString = '' + date.getFullYear();
        dateString += (date.getMonth() < 9 ? '-0' : '-') + (date.getMonth() + 1);
        dateString += (date.getDate() < 10 ? '-0' : '-') + date.getDate();
        return dateString;
    }

    public static calculateAge(theDate: Moment): number {
        const today = new Date();
        let age = today.getFullYear() - theDate.get('year');
        const m = today.getMonth() - theDate.get('month');
        if (m < 0 || (m === 0 && today.getDate() < theDate.get('date'))) {
            age--;
        }
        return age;
    }

}
