import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {BaseProductsService} from './base-products.service';
import {isNullOrUndefined} from 'util';
import {Log} from 'ng2-logger/browser';
import {Benefit, EscalationCombination} from '../benefit';
import {Subject} from 'rxjs';

const log = Log.create('escalations-modal');

@Component({
    templateUrl: './product-escalations.component.html'
})
export class ProductEscalationsComponent {
    get escalationOptions(): EscalationCombination[] {
        return this._escalationOptions;
    }

    set escalationOptions(value: EscalationCombination[]) {
        this._escalationOptions = value;
        this._escalationOptions.map(e => {
            e.premium = undefined;
        });
        const benefit_code = this.benefit.code;
        const cover = this.benefit.cover;
        this.productService.getPremiumsForEscalationOptions(this.spId, benefit_code, cover, this._escalationOptions)
            .subscribe((premiums: any) => {
                this.escalationPremiums$.next(premiums.results[this.primaryCode()]);
            }, (error: any) => {
                log.error(error);
            });
    }

    loading = false;
    private _escalationOptions: EscalationCombination[];
    benefit: Benefit;
    spId: string;
    escalationPremiums$ = new Subject<any[]>();
    selectedOption: any;

    constructor(
        private productService: BaseProductsService,
        private dialogRef: MatDialogRef<ProductEscalationsComponent>) {
    }

    primaryCode() {
        return this.benefit.code.replace('_ACC', '');
    }

    isPrimaryBenefit() {
        return this.primaryCode() === this.benefit.code;
    }

    currentOption() {
        const feature = this.benefit.features.find((f => f.type === 'escalation_options'));
        return {
            cover: feature.params.sumAssuredIncrease.value,
            premium: feature.params.premiumIncrease.value
        };
    }

    public close(newBenefit?: Benefit): void {
        if (!isNullOrUndefined(newBenefit)) {
            this.dialogRef.close(newBenefit);
        } else {
            this.dialogRef.close();
        }
    }

    setSelectedOption(option) {
        this.selectedOption = option;
    }

    hasSelectedOption() {
        return !isNullOrUndefined(this.selectedOption);
    }

    public reprice() {
        const option = this.selectedOption;
        if (isNullOrUndefined(option)) {
            log.info('Nothing selected!');
            return;
        }
        const currentOption = this.currentOption();
        if (currentOption.premium === option.premium && currentOption.cover === option.cover) {
            log.info('Selected currently applied option!');
            return;
        }
        this.loading = true;
        log.info('reprice it: ', option);
        this.productService.reprice(this.spId, this.benefit.code, this.benefit.cover, option).subscribe((newBenefit: any) => {
            log.info('Repriced benefit: ', newBenefit);
            this.close(newBenefit);
        }, (error: any) => {
            log.error(error);
            this.loading = false;
        });
    }

}
