import {Dynamic} from './component';
import {FormControl, Validators} from '@angular/forms';
import {Component} from '@angular/core';
import {isNullOrUndefined} from 'util';
import { PageComponentVO } from '../page.data.vo';

@Component({
    templateUrl: 'radio.group.horizontal.component.html',
    styleUrls: ['radio.group.horizontal.component.scss'],
    selector: 'radio-group-horizontal-input'
})
export class RadioGroupHorizontalComponent extends Dynamic<PageComponentVO> {

    radioGroupControl = new FormControl('');

    onChange(): void {
        if (!isNullOrUndefined(this.radioGroupControl.value) && this.radioGroupControl.value !== '') {
            this.component.value = this.radioGroupControl.value;
        } else {
            this.component.value = '';
        }
        this.component.isValid = this.radioGroupControl.valid;
        if (!isNullOrUndefined(this.change)) {
            this.change.emit(this.component.counter);
        }
    }

    getFormControl(): FormControl {
        if (isNullOrUndefined(this.component.required) || this.component.required) {
            this.radioGroupControl.setValidators([Validators.required]);
        }
        return this.radioGroupControl;
    }
}
