import { DisclosuresService } from '../base/disclosures/disclosures.service';
import { ErrorModalProvider } from '../base/error/error.modal.service';
import { MatDialog } from '@angular/material';
import { DisclosuresModalComponent } from '../base/disclosures/disclosures-modal.component';
import { DisclosureCategory, DisclosureType } from '../base/disclosures/disclosures.vos';
import { isNullOrUndefined } from 'util';
import { SpDataService } from './sp.data.service';
import { WindowRef } from '../base/window.ref';
import * as i0 from "@angular/core";
import * as i1 from "../base/disclosures/disclosures.service";
import * as i2 from "../base/error/error.modal.service";
import * as i3 from "./sp.data.service";
import * as i4 from "@angular/material/dialog";
import * as i5 from "../base/window.ref";
// TODO:: need to find a way to extend the provider in the disclosures module.
var SpDisclosuresProvider = /** @class */ (function () {
    function SpDisclosuresProvider(disclosuresService, errorModalProvider, spDataService, dialog, windowRef) {
        this.disclosuresService = disclosuresService;
        this.errorModalProvider = errorModalProvider;
        this.spDataService = spDataService;
        this.dialog = dialog;
        this.windowRef = windowRef;
        this.dialogConfig = {
            disableClose: true,
            position: { top: '20px' },
            maxWidth: '99vw',
            panelClass: 'dl-modal'
        };
        this.modalRef = null;
    }
    SpDisclosuresProvider.prototype.setSpMode = function (mode) {
        this.disclosuresService.setSpMode(mode);
    };
    SpDisclosuresProvider.prototype.createModal = function () {
        var _this = this;
        if (isNullOrUndefined(this.modalRef) || isNullOrUndefined(this.modalRef.componentInstance)) {
            this.modalRef = this.dialog.open(DisclosuresModalComponent, this.dialogConfig);
        }
        this.modalRef.afterClosed().subscribe(function () {
            if (!isNullOrUndefined(_this.modalRef)) {
                _this.windowRef.nativeWindow.scrollTo(0, 0);
            }
            _this.modalRef = null;
        });
    };
    SpDisclosuresProvider.prototype.showEntry = function (title, page) {
        if (this.spDataService.getEntryDisclosures().length > 0) {
            // show modal then load data
            this.createModal();
            this.setModalItems(title, page, DisclosureType.ENTRY, null);
            this.handleResult(title, page, DisclosureType.ENTRY, null, this.spDataService.getEntryDisclosures());
        }
        else {
            if (!isNullOrUndefined(this.modalRef)) {
                this.modalRef.close();
            }
        }
    };
    SpDisclosuresProvider.prototype.showExit = function (title, page, data, callback) {
        this.getData(title, page, DisclosureType.EXIT, data, callback);
    };
    SpDisclosuresProvider.prototype.getData = function (title, page, type, data, callback) {
        var _this = this;
        // add the sales process id to the data
        if (isNullOrUndefined(data)) {
            data = {};
        }
        data.sales_process_id = this.spDataService.getSalesProcessID();
        // get the disclosures from the server
        this.disclosuresService.getDisclosureGroup(DisclosureCategory.SALES_PROCESS, page, type, data).subscribe(function (response) {
            _this.handleResult(title, page, type, callback, response.disclosures);
        });
    };
    SpDisclosuresProvider.prototype.setModalItems = function (title, page, type, callback) {
        this.modalRef.disableClose = true;
        this.modalRef.componentInstance.saving = false;
        if (isNullOrUndefined(title) || title.length === 0) {
            this.modalRef.componentInstance.title = DisclosuresModalComponent.DEFAULT_TITLE;
        }
        else {
            this.modalRef.componentInstance.title = title;
        }
        this.modalRef.componentInstance.prefix = DisclosureCategory.SALES_PROCESS;
        this.modalRef.componentInstance.category = DisclosureCategory.SALES_PROCESS;
        this.modalRef.componentInstance.page = page;
        this.modalRef.componentInstance.type = type;
        this.modalRef.componentInstance.callback = callback;
    };
    SpDisclosuresProvider.prototype.handleResult = function (title, page, type, callback, result) {
        var _this = this;
        // apply sp id to all disclosures
        result.forEach(function (dis) { dis.sales_process_id = _this.spDataService.getSalesProcessID(); });
        // on entry already has modal open, the others we load data 1st then show modal
        if (isNullOrUndefined(this.modalRef)) {
            this.createModal();
            callback.onDisclosuresLoaded();
            this.setModalItems(title, page, type, callback);
        }
        this.modalRef.componentInstance.disclosures = result;
        // remove the spinner. Needed when we load more than one disclosure.
        this.modalRef.componentInstance.saving = false;
        setTimeout(function () {
            _this.windowRef.nativeWindow.document.querySelector('mat-dialog-content').scrollTop = 0;
        });
    };
    SpDisclosuresProvider.prototype.close = function () {
        if (!isNullOrUndefined(this.modalRef)) {
            this.modalRef.close();
        }
    };
    SpDisclosuresProvider.prototype.setCanClose = function (can, canAlways) {
        if (canAlways === void 0) { canAlways = false; }
        if (!isNullOrUndefined(this.modalRef)) {
            this.modalRef.componentInstance.canClose = can;
            this.modalRef.componentInstance.canCloseAlways = canAlways;
        }
    };
    SpDisclosuresProvider.prototype.stopSavingSpinner = function () {
        if (!isNullOrUndefined(this.modalRef)) {
            this.modalRef.componentInstance.saving = false;
        }
    };
    SpDisclosuresProvider.ngInjectableDef = i0.defineInjectable({ factory: function SpDisclosuresProvider_Factory() { return new SpDisclosuresProvider(i0.inject(i1.DisclosuresService), i0.inject(i2.ErrorModalProvider), i0.inject(i3.SpDataService), i0.inject(i4.MatDialog), i0.inject(i5.WindowRef)); }, token: SpDisclosuresProvider, providedIn: "root" });
    return SpDisclosuresProvider;
}());
export { SpDisclosuresProvider };
