import {isNullOrUndefined} from 'util';
import {Log} from 'ng2-logger/client';

const log = Log.create('ErrorVO');

export class ErrorVO {
    private _message: string;
    detail: any;
    statusText: string;

    get message(): string {
        if (isNullOrUndefined(this._message) || this._message.length === 0) {
            if (!isNullOrUndefined(this.statusText) && this.statusText.length > 0) {
                return this.statusText;
            }
            return 'Unknown Error';
        }
        return this._message;
    }

    set message(value: string) {
        this._message = value;
    }

    public static toErrorVO(error: any) {
        log.info('Extract error from:', error);
        let vo: ErrorVO = new ErrorVO();
        if (!isNullOrUndefined(error.statusText)) {
            vo.statusText = error.statusText;
        }
        try {
            let errorDetail: any;
            if (!isNullOrUndefined(error.error)) {
                errorDetail = error.error;
            } else {
                errorDetail = error.json(); // can get rid of this once all converted from old http promise...
            }
            if (!isNullOrUndefined(errorDetail.detail)) {
                vo.detail = errorDetail.detail;
            } else if (!isNullOrUndefined(errorDetail.error_detail)) {
                vo.detail = errorDetail.error_detail;
            }
            if (typeof errorDetail === 'string') {
                vo.message = errorDetail;
            } else if (typeof errorDetail.message === 'string') {
                vo.message = errorDetail.message;
            } else if (typeof errorDetail.error === 'string') {
                vo.message = errorDetail.error;
            }
            if (isNullOrUndefined(vo.message) && !isNullOrUndefined(vo.detail) && typeof vo.detail.error === 'string') {
                vo.message = vo.detail.error;
            }
        } catch (e) {}
        return vo;
    }

}
