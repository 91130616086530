import {Component, Inject} from '@angular/core';
import {ErrorModalDialog} from '../error/error.modal.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RsaId, rsaIdValidation, RsaIdValidationCallback} from '../rsa.id.validator';
import {SelectItem} from '../select.vo';
import {DLUtil} from '../dl.util';
import {PhoneNumberValidator} from '../phone-number';
import {Payer} from './dl.payer.vo';

@Component({
    selector: 'payerModal',
    templateUrl: './dl.payer.modal.component.html',
    styleUrls: ['./dl.payer_modal.scss']
})
export class DlPayerModalComponent implements RsaIdValidationCallback{

    title: string;
    payer: Payer;
    payerForm: FormGroup;
    salutations: SelectItem[];
    relationOptions: SelectItem[];

    constructor(public dialogRef: MatDialogRef<ErrorModalDialog>,
                @Inject(MAT_DIALOG_DATA) public data: PayerInputData,
                private fb: FormBuilder) {
        this.title = data.title;
        this.payer = data.payer;
        this.salutations = DLUtil.salutations;
        this.relationOptions = DLUtil.relationOptions;
        this.setupForm();
    }

    private setupForm() {
        this.payerForm = this.fb.group({
            title: ['', [Validators.required]],
            firstName: [this.payer.first_name, Validators.required],
            lastName: [this.payer.last_name, Validators.required],
            idDob: [this.payer.id_number, [rsaIdValidation(false, this)]],
            relation: [this.payer.relation, [Validators.required]],
            email: [this.payer.email1, [Validators.pattern(DLUtil.emailPattern)]],
            phone_number: [this.payer.phone_mobile, [PhoneNumberValidator.validator()]],
            notes: [this.payer.notes, Validators.maxLength(50)]
        });
    }

    cancel(): void {
        this.dialogRef.close('cancel');
    }

    save(): void {
        this.payer.salutation = this.payerForm.get('title').value;
        this.payer.first_name = this.payerForm.get('firstName').value;
        this.payer.last_name = this.payerForm.get('lastName').value;
        this.payer.id_number = this.payerForm.get('idDob').value;
        this.payer.relation = this.payerForm.get('relation').value;
        this.payer.email1 = this.payerForm.get('email').value;
        this.payer.phone_mobile = this.payerForm.get('phone_number').value;
        this.payer.notes = this.payerForm.get('notes').value;
        this.dialogRef.close('save');
    }

    onIdValidationChange(rsaId: RsaId): void {
    }
}

export class PayerInputData {
    payer: Payer;
    title: string;
}
