import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {
    ContinueResponse,
    LeadDataVO,
    NeedsBreakdown,
    NeedsDataVO,
    NeedsSubmission,
    ProductGroupRequestVO,
    ProductGroupVO,
    ResultResponse
} from './needs.vo';
import {Observable, throwError} from 'rxjs';
import {ErrorVO} from '../base/error/error.vo';
import {ErrorModalProvider} from '../base/error/error.modal.service';
import {catchError, tap} from 'rxjs/operators';
import {isNullOrUndefined} from 'util';
import {Log} from 'ng2-logger/browser';

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};

const log = Log.create('NeedsService');

@Injectable({
    providedIn: 'root'
})
export class NeedsService {

    private _ptyName: string;
    private _needsResponseData: NeedsDataVO;
    private _productGroupVO: ProductGroupVO;
    private _leadData: LeadDataVO;
    private _benefitMap: any = {};
    private _hasSchoolStage = false;
    private _schoolStages: NeedsBreakdown[];
    private _debtAssumptions: NeedsBreakdown[];
    private _hasLivingExpenses = false;
    private _livingExpenses: NeedsBreakdown[];

    constructor(private http: HttpClient,
                private errorModalProvider: ErrorModalProvider) { }

    get ptyName(): string {
        return this._ptyName;
    }

    set ptyName(value: string) {
        this._ptyName = value;
    }

    public get needsResponse(): NeedsDataVO {
        return this._needsResponseData;
    }

    public get benefitMap(): any {
        return this._benefitMap;
    }

    public get hasSchoolStage(): boolean {
        return this._hasSchoolStage;
    }

    private set needsResponseData(val: NeedsDataVO) {
        this._needsResponseData = val;
        this._needsResponseData.noteAsterisk = false;
        let total = 0;
        for (const benefit of this._needsResponseData.needsBenefits) {
            total += benefit.premium_amount;
            this.benefitMap[benefit.product_code] = benefit;
            if (benefit.benefit_amount !== benefit.requested_benefit_amount) {
                if (benefit.product_code === 'SP90' && benefit.premium_amount > 0) {
                    // skip sp if we have a premium
                    continue;
                }
                benefit.noteAsterisk = true;
                this._needsResponseData.noteAsterisk = true;
                benefit.toFromText = 'from';
                if (benefit.benefit_amount < benefit.requested_benefit_amount) {
                    benefit.toFromText = 'to';
                }
            }
        }
        this._needsResponseData.total = total;
        this._schoolStages = this._needsResponseData.needsBreakdown.filter(b => b.type === 'education');
        this._hasSchoolStage = this._schoolStages.length > 0;
        this._debtAssumptions = this._needsResponseData.needsBreakdown.filter(b => b.type === 'debt');
        this._livingExpenses = this._needsResponseData.needsBreakdown.filter(b => b.type === 'living_expense');
        this._hasLivingExpenses = this._livingExpenses.length > 0;
    }

    public get productGroup(): string {
        if (this._productGroupVO != null) {
            return this._productGroupVO.productGroupCode;
        }
        return null;
    }

    public get schoolStages(): NeedsBreakdown[] {
        return this._schoolStages;
    }

    public get debtAssumptions(): NeedsBreakdown[] {
        return this._debtAssumptions;
    }

    public get hasLivingExpenses(): boolean {
        return this._hasLivingExpenses;
    }

    public get livingExpenses(): NeedsBreakdown[] {
        return this._livingExpenses;
    }

    public get campaignId(): string {
        if (this._productGroupVO != null) {
            return this._productGroupVO.campaignId;
        }
        return null;
    }

    public setLeadData(
            name: string,
            email: string,
            cell: string,
            leadId?: string,
            httpReferrer?: string,
            campaignCode?: string,
            referrerCode?: string): void {
        if (isNullOrUndefined(this._leadData)) {
            this._leadData = new LeadDataVO();
        }
        this._leadData.email1 = email;
        this._leadData.phone_mobile = cell;

        this.splitLeadName(this._leadData, name);

        if (!isNullOrUndefined(httpReferrer)) {
            this._leadData.refered_by = httpReferrer;
        }
        if (!isNullOrUndefined(campaignCode)) {
            this._leadData.campaign_code = campaignCode;
        }
        if (!isNullOrUndefined(referrerCode)) {
            this._leadData.referrer_code = referrerCode;
        }
        if (!isNullOrUndefined(leadId) && leadId !== '') {
            this._leadData.id = leadId;
        }
        this._leadData.lead_type = 'life_needs';
        this._leadData.lead_source = 'Calculator';
        this._leadData.lead_source_description = 'Lead from Life Calculator Calculate Button';
    }

    public splitLeadName(leadData: LeadDataVO, name: string): void {
        if (!isNullOrUndefined(name) && name !== '') {
            const nameParts = name.split(' ');
            if (nameParts.length > 1) {
                leadData.last_name = null;
                leadData.first_name = nameParts[0];
                for (let i = 1; i < nameParts.length; i++) {
                    if (isNullOrUndefined(leadData.last_name)) {
                        leadData.last_name = nameParts[i];
                    } else {
                        leadData.last_name += ' ' + nameParts[i];
                    }
                }
            } else {
                leadData.first_name = name;
            }
            leadData.known_as = leadData.first_name;
        }
    }

    public get leadData(): LeadDataVO {
        return this._leadData;
    }

    public clearResults() {
        this._needsResponseData = null;
        this._leadData = null;
        this._benefitMap = {};
        this._hasSchoolStage = false;
        this._schoolStages = [];
        this._debtAssumptions = [];
        this._hasLivingExpenses = false;
        this._livingExpenses = [];
    }

    /**
     * Get the needs results from the calculator
     * @param {NeedsDataVO} data
     * @returns {Observable<NeedsDataVO>}
     */
    public getNeedsInfo(data: NeedsDataVO): Observable<NeedsDataVO> {
        return this.http.post<NeedsDataVO>('/dl/data/needs/calculate', data, httpOptions)
            .pipe(
                tap(res => {
                    this.needsResponseData = res;
                }),
                catchError(this.handleError<NeedsDataVO>())
            );
    }

    public resolveProductGroup(data: ProductGroupRequestVO): Observable<ProductGroupVO> {
        return this.http.post<ProductGroupVO>('/dl/data/needs/details', data, httpOptions)
            .pipe(
                tap(res => {
                    if (res.message == null) {
                        this._productGroupVO = res;
                    }
                }),
                catchError(this.handleError<ProductGroupVO>())
            );
    }

    public sendCalcEmail(needsId: string, lead: LeadDataVO): Observable<ResultResponse> {
        return this.http.post<ResultResponse>('/dl/data/needs/calcEmail/' + needsId, lead, httpOptions)
            .pipe(
                tap(
                    res => {
                        this.leadData.id = res.lead_id;
                        this.needsResponse.lead_id = res.lead_id;
                }),
                catchError(this.handleError<ResultResponse>())
            );
    }

    public continueToSp(qqData: NeedsSubmission): Observable<ContinueResponse> {
        return this.http.post<ContinueResponse>('/dl/data/needs/calculator', qqData, httpOptions)
            .pipe(catchError(this.handleError<ContinueResponse>()));
    }

    private handleError<T>() {
        return (error: any):  Observable<T> => {
            const vo: ErrorVO = ErrorVO.toErrorVO(error);
            this.errorModalProvider.showError(vo);
            return throwError(error);
        };
    }
}
