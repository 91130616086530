/**
 * This component contains a link used to mail the draft policy docs
 */
import {Component, OnDestroy} from '@angular/core';
import {Dynamic, ParentEventService} from './component';
import {FormControl} from '@angular/forms';
import {Subscription} from 'rxjs';
import {AjaxService} from './ajax.service';
import {AjaxVO, PageComponentVO} from '../page.data.vo';

@Component({
    templateUrl: 'mail.draft.doc.info.component.html',
    selector: 'draft-doc-info',
})
export class MailDraftDocInfoComponent extends Dynamic<PageComponentVO> implements OnDestroy {

    isBusy = false;
    isDone = false;
    disabled = false;

    private parentBusySubscription: Subscription;


    constructor(private parentEventService: ParentEventService,
                private ajaxService: AjaxService) {
        super();
        this.parentBusySubscription = parentEventService.getPageBusyObservable().subscribe(event => {
            this.disabled = event;
            if (this.isBusy && !event) {
                this.isBusy = false;
            }
        });
    }

    setupFromControl() {
        this.component.loaded = true;
        this.loaded.emit(this.component.id);
    }

    getFormControl(): FormControl {
        return null;
    }

    ngOnDestroy(): void {
        this.parentBusySubscription.unsubscribe();
    }

    doCall(): boolean {
        if (!this.disabled && !this.isBusy) {
            this.isBusy = true;
            this.parentEventService.publishPageBusyStatus(true);
            this.ajaxService.postRequest(this.component.value as AjaxVO).subscribe(
                (res) => {
                    this.parentEventService.publishPageBusyStatus(false);
                    this.isDone = true;
                },
                () => this.parentEventService.publishPageBusyStatus(false)
            );
        }
        return false; // we dont want the browser to follow the href link
    }
}
