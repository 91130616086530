<div *ngIf="!component.value" class="dl-loading">
    <span class="fa fa-circle-o-notch fa-spin"></span>
</div>
<dl-disclosures #disclosuresView [disclosures]="component.value"></dl-disclosures>
<div class="dl-col-md-4 col s12 m4"></div>
<div class="dl-col-md-4 col s12 m4"></div>
<div class="dl-col-md-4 col s12 m4">
    <button
            mat-raised-button
            id="disclosures-modal-submit"
            color="primary"
            class="dl-full-width dl-action-btn"
            [disabled]="!canSave"
            [attr.data-event]="'true'"
            [attr.data-event-category]="category"
            [attr.data-event-name]="'Disclosure Continue'"
            [attr.data-event-label]="category + ' Disclosure Continue - ' + component.name"
            (click)="saveDisclosures()">
        Continue&nbsp;
        <i *ngIf="!saving" class="fa fa-chevron-right"></i>
        <i *ngIf="saving" class="fa fa-circle-o-notch fa-spin"></i>
    </button>
</div>