import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {PasswordMatchValidator} from '../base/passwordMatch.validator';
import {Log} from 'ng2-logger/browser';
import {isNullOrUndefined} from 'util';
import {LoginResponse, LoginService, SignInProcessor} from './login.service';
import {SuccessVO} from '../sp/common.vos';
import {WindowRef} from '../base/window.ref';
import {DLUtil} from '../base/dl.util';

const log = Log.create('dl-login');

@Component({
    selector: 'dl-login',
    templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, SignInProcessor {

    formGroup: FormGroup;

    _loginAction = 'username_password';
    @Input()
    set loginAction(value: string) {
        this._loginAction = value;
    }
    get loginAction(): string {
        return this._loginAction;
    }
    _ssoUserName: string;
    @Input()
    set ssoUserName(value: string) {
        this._ssoUserName = value;
    }
    get ssoUserName(): string {
        return this._ssoUserName;
    }
    _leadId: string;
    @Input()
    set leadId(value: string) {
        this._leadId = value;
    }
    get leadId(): string {
        return this._leadId;
    }
    _nextAction: string;
    @Input()
    set nextAction(value: string) {
        this._nextAction = value;
    }
    get nextAction(): string {
        return this._nextAction;
    }
    _orgUrl: string;
    @Input()
    set orgUrl(value: string) {
        this._orgUrl = value;
    }
    get orgUrl(): string {
        return this._orgUrl;
    }
    _oauthClientId: string;
    @Input()
    set oauthClientId(value: string) {
        this._oauthClientId = value;
    }
    get oauthClientId(): string {
        return this._oauthClientId;
    }
    _standalone = true;
    @Input()
    set standalone(value: boolean) {
        this._standalone = value;
    }
    get standalone(): boolean {
        return this._standalone;
    }
    _salesProcessId: string;
    @Input()
    set salesProcessId(value: string) {
        this._salesProcessId = value;
    }
    get salesProcessId(): string {
        return this._salesProcessId;
    }
    _autoRedirect = 'true';
    @Input('auto-redirect')
    set autoRedirect(value: string) {
        this._autoRedirect = value;
    }
    get autoRedirect(): string {
        return this._autoRedirect;
    }
    _hideHeading = 'false';
    @Input('hide-heading')
    set hideHeading(value: string) {
        this._hideHeading = value;
    }
    get hideHeading(): string {
        return this._hideHeading;
    }
    _dateFormat = 'YYYY-MM-DD';
    @Input('date-format')
    set dateFormat(value: string) {
        this._dateFormat = value;
    }
    get dateFormat(): string {
        return this._dateFormat;
    }

    _legacy = '';
    @Input('legacy')
    set setLegacy(value: string) {
        this._legacy = value;
    }
    get getLegacy(): boolean {
        return this._legacy === 'SG';
    }

    @Output()
    moveToNext = new EventEmitter<SuccessVO>();

    requireId = false;
    loginLoadingBusy = true;
    errorMessage: string;
    continueBusy = false;
    telMask: any = DLUtil.telMask;

    private setPassword: boolean;
    private redirectUrl: string;
    private dob: Date;

    get showUsernameField(): boolean {
        return this.loginAction === 'username_password';
    }

    get showIdField(): boolean {
        return this.loginAction === 'set_password_with_id' || this.requireId;
    }

    get showDOBField(): boolean {
        return this.loginAction === 'set_password_with_dob' || this.loginAction === 'confirm_dob';
    }

    get showPhoneField(): boolean {
        return this.loginAction === 'confirm_phone';
    }

    get showPassword(): boolean {
        return this.showIdField || this.loginAction === 'set_password_with_dob' || this.showUsernameField;
    }

    get showConfirmPassword(): boolean {
        return this.setPassword;
    }

    get passwordPlaceHolder(): string {
        if (this.showConfirmPassword) {
            return 'Choose a password';
        }
        return 'Password';
    }

    get passwordHelpText(): string {
        return PasswordMatchValidator.passwordPatternHelpText();
    }

    get subHeading(): string {
        if (this.showIdField) {
            return 'Please confirm your ID number and choose a password to continue.';
        }
        if (this.loginAction === 'set_password_with_dob') {
            return 'Please confirm your birth date and choose a password to continue.';
        }
        if (this.loginAction === 'confirm_dob') {
            return 'Please confirm your birth date to continue.';
        }
        if (this.showPhoneField) {
            return 'Please enter your cell phone number to continue.';
        }
        if (this.showPassword) {
            return 'Please login to continue.';
        }
        return 'Continue to My Policy Dashboard.';
    }

    constructor(private loginService: LoginService,
                private winRef: WindowRef) {
        this.setupForm();
    }

    private setupForm(): void {
        this.formGroup = new FormGroup({
            username: new FormControl('', [Validators.required]),
            phone: new FormControl('', [Validators.required]),
            idnumber: new FormControl('', [Validators.required, Validators.pattern(/[0-9]{13}/)]),
            dob: new FormControl('', [Validators.required]),
            password: new FormControl('', [
                Validators.required,
                Validators.pattern(PasswordMatchValidator.passwordPattern()),
            ]),
            confirmPassword: new FormControl('', [Validators.required]),
        }, PasswordMatchValidator.matchPasswords());
        this.formGroup.get('dob').valueChanges.subscribe(() => {
            this.dob = new Date(this.formGroup.get('dob').value);
        });
    }

    ngOnInit(): void {
        // need to do more or less the same as what is done on the current sp Login Component
        // disable all fields
        this.disableAll();
        // enable fields required for action
        if (this.standalone) {
            // if sp id 1st get login action...
            if (!isNullOrUndefined(this.salesProcessId)) {
                this.loginService.getLoginActionForSP(this.salesProcessId).subscribe(
                    res => {
                        if (!isNullOrUndefined(res.payload)) {
                            if (!isNullOrUndefined(res.payload.login_action)) {
                                this.loginAction = res.payload.login_action;
                            }
                            if (!isNullOrUndefined(res.payload.username)) {
                                this.ssoUserName = res.payload.username;
                            }
                            if (!isNullOrUndefined(res.payload.next_action)) {
                                this.nextAction = res.payload.next_action;
                            }
                            if (!isNullOrUndefined(res.payload.lead_id)) {
                                this.leadId = res.payload.lead_id;
                            }
                        }
                        this.init();
                    },
                    () => {
                        this.loginLoadingBusy = false;
                        this.init();
                    }
                );
            } else {
                this.init();
            }
        }
    }

    private disableAll(): void {
        this.formGroup.get('phone').disable();
        this.formGroup.get('username').disable();
        this.formGroup.get('idnumber').disable();
        this.formGroup.get('dob').disable();
        this.formGroup.get('password').disable();
        this.formGroup.get('confirmPassword').disable();
    }

    private init() {
        if (this.showIdField) {
            log.info('set pass with id');
            this.setPassword = true;
            this.formGroup.get('idnumber').enable();
            this.formGroup.get('password').enable();
            this.formGroup.get('confirmPassword').enable();
            this.formGroup.get('username').setValue(this.ssoUserName);
            this.loginLoadingBusy = false;
        } else if (this.loginAction === 'set_password_with_dob') {
            log.info('set pass with dob');
            this.setPassword = true;
            this.formGroup.get('dob').enable();
            this.formGroup.get('password').enable();
            this.formGroup.get('confirmPassword').enable();
            this.formGroup.get('username').setValue(this.ssoUserName);
            this.loginLoadingBusy = false;
        } else if (this.showPhoneField) {
            log.info('show phone');
            this.setPassword = false;
            this.formGroup.get('phone').enable();
            this.loginLoadingBusy = false;
        } else if (this.loginAction === 'confirm_dob') {
            log.info('show dob');
            this.setPassword = false;
            this.formGroup.get('dob').enable();
            this.loginLoadingBusy = false;
        } else {
            log.info('show username / password');
            this.setPassword = false;
            this.formGroup.get('username').enable();
            this.formGroup.get('password').setValidators(Validators.required);
            this.formGroup.get('password').enable();
            setTimeout(() => {
                this.loginService.loginCheck(this, this.ssoUserName, this.orgUrl, this.oauthClientId);
            });
        }
    }

    processSignIn(success: boolean, message?: string): void {
        log.info('processSignIn - ' + success);
        if (success) {
            let url = null;
            if (!isNullOrUndefined(this.nextAction)) {
                log.info('next action ' + this.nextAction);
                if (this.nextAction === 'wallet') {
                    url = this.orgUrl + '/members/' + this.ssoUserName + '/wallet/';
                } else if (this.nextAction.substring(0, 4) === 'org/') {
                    url = this.orgUrl + '/' + this.nextAction.substring(4, this.nextAction.length);
                } else if (this.nextAction === 'profile') {
                    url = '/dl/profile';
                } else if (this.nextAction.indexOf('sp:') === 0) {
                    url = this.nextAction.substring(3);
                }
            } else if (!this.standalone) {
                const vo: any = {success: success, message: message};
                log.info('not standalone emitting moveToNext', vo);
                this.moveToNext.emit(vo);
                return;
            }
            if (isNullOrUndefined(url)) {
                log.info('No next action and we are not in a sp so load the profile');
                url = '/dl/profile';
            }
            if (this.autoRedirect === 'false' && this.loginLoadingBusy) {
                log.info('busy loading and not auto redirecting to ' + url);
                this.disableAll();
                this.loginAction = 'redirect_to_profile';
                this.redirectUrl = url;
                this.loginLoadingBusy = false;
            } else {
                log.info('redirect to ' + url);
                this.winRef.nativeWindow.location = url;
            }
        } else {
            if (!isNullOrUndefined(message)) {
                log.info('SignIn Error Message: ' + message);
                if (message === 'requires_id_number') {
                    this.requireId = true;
                    this.formGroup.get('idnumber').enable();
                    this.errorMessage = 'We found multiple accounts for that email address, please provide your id number too.';
                } else {
                    this.errorMessage = message;
                }
            } else {
                log.info('SignIn Error Message blank');
            }
            this.loginLoadingBusy = false;
            this.continueBusy = false;
        }
    }

    /**
     * Use this function to re init the login form. If not used as a standalone component and the data changes
     * Typically the app will populate all the needed data before this is called.
     */
    public initNonStandalone(): void {
        this.init();
    }

    disableContinue(): boolean {
        return this.formGroup.invalid || this.loginLoadingBusy || this.continueBusy;
    }

    processLogin(): void {
        this.continueBusy = true;
        if (this.setPassword) {
            log.info('set password');
            const data: any = {
                action: 'set',
                sales_process_id: this.salesProcessId,
                username: this.formGroup.get('username').value,
                password: this.formGroup.get('password').value,
            };
            if (this.showIdField) {
                data.idnumber = this.formGroup.get('idnumber').value;
            } else {
                data.action = 'set_with_dob';
                data.dob = null;
                if (!isNullOrUndefined(this.dob)) {
                    data.dob = DLUtil.formatDateToString(this.dob);
                }
            }
            this.loginService.setPassword(data).subscribe(res => {
                log.info('set password result', res);
                if (res.success) {
                    log.info('successfully set password, calling sign in');
                    this.signInNoId();
                } else if (!isNullOrUndefined(res.message)) {
                    this.errorMessage = res.message;
                    this.continueBusy = false;
                }
            });
        } else if (this.showPhoneField) {
            log.info('check phone...');
            this.loginService.checkPhone(this.formGroup.get('phone').value, this.leadId, this.salesProcessId).subscribe(
                res => {
                    if (!this.standalone) {
                        this.moveToNext.emit(res);
                        this.continueBusy = false;
                    } else {
                        this.processSignIn(res.success, res.message);
                    }
                },
                () => this.continueBusy = false
            );
        } else if (this.showDOBField) {
            log.info('check dob...');
            this.loginService.checkDob(DLUtil.formatDateToString(this.dob), this.leadId, this.salesProcessId).subscribe(
                res => {
                    if (!this.standalone) {
                        this.moveToNext.emit(res);
                        this.continueBusy = false;
                    } else {
                        this.processSignIn(res.success, res.message);
                    }
                },
                () => this.continueBusy = false
            );
        } else if (this.showIdField) {
            log.info('signin with id...');
            this.signIn(this.formGroup.get('username').value, this.formGroup.get('password').value, this.formGroup.get('idnumber').value);
        } else if (this.loginAction === 'redirect_to_profile' && !isNullOrUndefined(this.redirectUrl)) {
            log.info('redirect to ' + this.redirectUrl);
            this.winRef.nativeWindow.location = this.redirectUrl;
        } else {
            log.info('signin no id...');
            this.signInNoId();
        }
    }

    private signInNoId() {
        this.signIn(this.formGroup.get('username').value, this.formGroup.get('password').value, null);
    }

    private signIn(userName: string, password: string, idnumber: string) {
        this.loginService.checkUser(userName, password, idnumber).subscribe(res => {
            this.handleCheckUserResponse(res, userName, password);
        },
        () => {
            this.processSignIn(false, 'Error communicating to the login server, please try again');
        });
    }

    private handleCheckUserResponse(res: LoginResponse, userName: string, password: string) {
        if (res.requires_id_number) {
            this.requireId = true;
            this.processSignIn(false, 'requires_id_number');
        } else if (!isNullOrUndefined(res.errorMsg)) {
            this.processSignIn(false, res.errorMsg);
        } else {
            if (!isNullOrUndefined(this.orgUrl) && this.orgUrl.length > 1) {
                if (!isNullOrUndefined(res.sso_user_login)) {
                    userName = res.sso_user_login;
                }
                this.loginService.loginToOrg(this.orgUrl, this.oauthClientId, userName, password, this)
                    .subscribe(() => {},
                    () => {
                        this.processSignIn(false, 'Error communicating to the login server, please try again');
                    });
            } else {
                // SG sign in.
                this.processSignIn(true);
            }
        }
    }

    formParentClass(): string {
        return this.getLegacy ? 'm8' : '';
    }

    processTraditionalLogin(): void {
        this.winRef.nativeWindow.location = 'https://portal.stangen.co.za:449/account/login';
    }
}

