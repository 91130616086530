<div class="dl-dsp-dotorg">
    <mat-card>
        <mat-card-title class="title">The doing good part</mat-card-title>
        <mat-card-content>
            <div class="row">
                <div class="col s12 center">
                    <p class="dl-dsp-dotorg-block">{{clientName}}, we will annually donate<br><em>{{amount}}</em><br> at no extra cost to you</p>
                </div>
            </div>
            <div *ngIf="!component.value" class="row">
                <div class="col s12">
                    <p>You can choose where this money goes by hitting "Select Charity"</p>
                    <p>You will be doing good, at no extra cost to you.</p>
                </div>
                <div class="row">
                    <div class="col s8 offset-s2 m6 offset-m3 l4 offset-l4">
                        <button
                            class="dl-full-width"
                            mat-raised-button
                            (click)="showModal()"
                            [attr.data-event]="'true'"
                            [attr.data-event-category]="'DSP-' + productCode"
                            [attr.data-event-name]="pageName + ' - .org choose project'"
                            [attr.data-event-label]="'.org choose project'"
                        >Select Charity</button>
                    </div>
                </div>
            </div>
            <div *ngIf="component.value" class="row">
                <div class="col s12">
                    <p>Thank you, our first annual donation of {{amount}} will go to <em>{{projectName}}</em>.</p>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
