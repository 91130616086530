import {Injectable} from '@angular/core';
import {Log} from 'ng2-logger/browser';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';

const log = Log.create('DonutService');

@Injectable({
  providedIn: 'root'
})
export class DonutService {

  constructor(private http: HttpClient) {}

  public getData(url: string): Observable<GraphData> {
    if (url === 'test') {
      return of(this.getTestData());
    } else if (url === 'test1') {
      return of(this.getTest1Data());
    } else {
      return this.http.get<GraphData>(url);
    }
  }

  public getTestData(): GraphData {
    log.info('Loading Test Data');
    const data = new GraphData();
    data.name = 'Test';
    data.centerText = 'Test Center';
    for (let i = 0; i < 4; i++) {
      const item = new GraphItem();
      item.id = 'test_' + i;
      item.label = 'Test label ' + i;
      item.description = 'Description - ' + i;
      item.value = 24 * (i + 1);
      switch (i) {
        case 0 : item.colour = '#f44842'; break;
        case 1 : item.colour = '#f4b841'; break;
        case 2 : item.colour = '#f4f141'; break;
        case 3 : item.colour = '#46f441'; break;
      }
      data.data.push(item);
    }
    return data;
  }

  public getTest1Data(): GraphData {
    log.info('Loading Test Data');
    const data = new GraphData();
    data.name = 'Test';
    data.centerText = '';
    for (let i = 0; i < 4; i++) {
      const item = new GraphItem();
      item.id = 'test2_' + i;
      item.label = 'Test 2 label ' + i;
      item.description = 'Description 2 - ' + i;
      item.value = 45 * (i + 10);
      switch (i) {
        case 0 : item.colour = '#f4b841'; break;
        case 1 : item.colour = '#f4f141'; break;
        case 2 : item.colour = '#f44842'; break;
        case 3 : item.colour = '#46f441'; break;
      }
      data.data.push(item);
    }
    return data;
  }
}

export class GraphItem {
  id: string;
  label: string;
  description: string;
  value: number;
  colour: string;
}

export class GraphData {
  name: string;
  centerText: string;
  data: GraphItem[] = [];
}


