var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Dynamic } from './component';
import { isNullOrUndefined } from 'util';
import { FormControl, Validators } from '@angular/forms';
var TextComponent = /** @class */ (function (_super) {
    __extends(TextComponent, _super);
    function TextComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.textControl = new FormControl('');
        return _this;
    }
    TextComponent.prototype.onChange = function () {
        this.component.value = this.textControl.value;
        this.component.isValid = this.textControl.valid;
        if (!isNullOrUndefined(this.change)) {
            this.change.emit(this.component.counter);
        }
    };
    TextComponent.prototype.getFormControl = function () {
        if (isNullOrUndefined(this.component.required) || this.component.required) {
            this.textControl.setValidators([Validators.required]);
        }
        return this.textControl;
    };
    return TextComponent;
}(Dynamic));
export { TextComponent };
