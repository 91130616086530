import {WindowRef} from './window.ref';
import {Injectable} from '@angular/core';

/**
 * A simple service that can be used to check screen size.
 * At the moment we only support sizes [lg,md,sm,xs]
 * This service can be extended for other html5 match media tasks as needed.
 */
@Injectable({
  providedIn: 'root'
})
export class MatchMediaService {

    /**
     * injecct ref to the window object. Dont want to use this directly in TS angular 2+
     * @param windowRef
     */
    constructor(private windowRef: WindowRef) {}

    private defaultRules = {
        lg: '(min-width: 1200px)',
        md: '(min-width: 992px) and (max-width: 1199px)',
        sm: '(min-width: 768px) and (max-width: 991px)',
        xs: '(max-width: 767px)'
    };

    /**
     * At the moment this method will only check for the Bootstrap 3 sizes [lg,md,sm,xs]
     * And will return false for all other size inputs.
     * @param sizes
     * @returns {boolean}
     */
    public is(sizes: string[]): boolean {
        for (let i = 0; i < sizes.length; i++) {
            if (this.defaultRules.hasOwnProperty(sizes[i])) {
                const rule = this.defaultRules[sizes[i]];

                if (this.windowRef.nativeWindow.matchMedia(rule).matches) {
                    return true;
                }
            }
        }
        return false;
    }
}
