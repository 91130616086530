import {Component, OnDestroy} from '@angular/core';
import {Dynamic, ParentEventService} from './component';
import {FormControl} from '@angular/forms';
import {isNullOrUndefined} from 'util';
import {Subscription} from 'rxjs/index';
import {CardInfoVO, PageComponentVO} from '../page.data.vo';

@Component({
    selector: 'info-card',
    templateUrl: './info-card.component.html',
    styleUrls: ['./info-card.component.scss']
})
export class InfoCardComponent extends Dynamic<PageComponentVO> implements OnDestroy {

    isBusy = false;
    isDisabled = true;
    private parentValidSubscription: Subscription;
    private parentBusySubscription: Subscription;

    constructor(protected parentEventService: ParentEventService) {
        super();
        this.parentValidSubscription = parentEventService.getPageValidateObservable().subscribe(event => this.isDisabled = !event);
        this.parentBusySubscription = parentEventService.getPageBusyObservable().subscribe(event => {
            this.isDisabled = event;
            if (this.isBusy && !event) {
                this.isBusy = false;
            }
        });

    }

    ngOnDestroy() {
        this.parentValidSubscription.unsubscribe();
        this.parentBusySubscription.unsubscribe();
    }

    setupFromControl() {
        this.component.loaded = true;
        // tel my parent I'm loaded.
        this.loaded.emit(this.component.id);
    }

    getFormControl(): FormControl {
        return null;
    }

    onChange(): void {
    }

    private getVO(): CardInfoVO {
        if (!isNullOrUndefined(this.component.extraInput)) {
            return this.component.extraInput as CardInfoVO;
        }
        return null;
    }

    get title(): string {
        if (!isNullOrUndefined(this.getVO()) && !isNullOrUndefined(this.getVO().title)) {
            return this.getVO().title;
        }
        return null;
    }

    get actionValue(): string {
        if (!isNullOrUndefined(this.getVO()) && !isNullOrUndefined(this.getVO().action) &&
                !isNullOrUndefined(this.getVO().action.value) && this.getVO().action.value.length > 0) {
            return this.getVO().action.value;
        }
        return null;
    }

    get actionText(): string {
        if (!isNullOrUndefined(this.getVO()) && !isNullOrUndefined(this.getVO().action) &&
                !isNullOrUndefined(this.getVO().action.text) && this.getVO().action.text.length > 0) {
            return this.getVO().action.text;
        }
        return 'Continue';
    }

    get isDefaultBtn(): string {
        if (!isNullOrUndefined(this.getVO()) && !isNullOrUndefined(this.getVO().action) &&
            !isNullOrUndefined(this.getVO().action.default) && this.getVO().action.default) {
            return 'primary';
        }
        return '';
    }

    get linkHref(): string {
        if (!isNullOrUndefined(this.getVO()) && !isNullOrUndefined(this.getVO().link) &&
                !isNullOrUndefined(this.getVO().link.href) && this.getVO().link.href.length > 0) {
            return this.getVO().link.href;
        }
        return null;
    }

    get linkText(): string {
        if (!isNullOrUndefined(this.getVO()) && !isNullOrUndefined(this.getVO().link) &&
                !isNullOrUndefined(this.getVO().link.text) && this.getVO().link.text.length > 0) {
            return this.getVO().link.text;
        }
        return 'Link';
    }

    get content(): string {
        return this.component.value as string;
    }

    click(): void {
        this.isBusy = true;
        this.altContinue.emit(this.actionValue);
    }

}
