import {Dynamic} from './component';
import {Component} from '@angular/core';
import {isNullOrUndefined} from 'util';
import {FormControl, Validators} from '@angular/forms';
import {PhoneNumberValidator} from '../../base/phone-number';
import { PageComponentVO } from '../page.data.vo';

@Component({
    selector: 'tel-input',
    templateUrl: 'tel.component.html'
})
export class TelComponent extends Dynamic<PageComponentVO> {

    telControl: FormControl = new FormControl('');
    flowId: string = document.querySelector('dl-dsp').getAttribute('flow-id');
    pageName: string = document.querySelector('dsp-page').getAttribute('page-name');

    onChange(): void {
        this.component.isValid = this.telControl.valid;
        this.component.value = this.telControl.value;
        if (!isNullOrUndefined(this.change)) {
            this.change.emit(this.component.counter);
        }
    }

    getFormControl(): FormControl {
        if (isNullOrUndefined(this.component.required) || this.component.required) {
            this.telControl.setValidators([Validators.required, PhoneNumberValidator.validator()]);
        } else {
            this.telControl.setValidators([PhoneNumberValidator.validator()]);
        }
        return this.telControl;
    }
}
