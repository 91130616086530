import {Validator, AbstractControl, NG_VALIDATORS, ValidatorFn} from '@angular/forms';
import {isNullOrUndefined, isNumber, isUndefined} from 'util';
import {Directive, Input} from '@angular/core';


/**
 * Created by corneliusbotha on 2017/02/19.
 * Class used to validate min max values.
 */
@Directive({
    selector: '[validateMinMax]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: MinMaxValidator, multi: true }
    ]
})
export class MinMaxValidator implements Validator {

    @Input('min') min: number;
    @Input('max') max: number;

    validate(c: AbstractControl): {[p: string]: any} {
        if (!isNullOrUndefined(c.value) && c.value !== '') {
            if (isNumber(c.value)) {
                let testVal: number = c.value;
                if (this.min !== null && testVal < this.min) {
                    return {'number_to_small': true};
                }
                if (this.max !== null && testVal > this.max) {
                    return {'number_to_big': true};
                }
            } else {
                return {'not_a_number': true};
            }
        }
        return null;
    }
}

export function minMaxValidation(min: number = null, max: number = null): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
        if (!isNullOrUndefined(control.value)) {
            let testVal = control.value;
            if (!isNumber(testVal) && !isUndefined(testVal.replace)) {
                testVal = parseInt(testVal.replace(/\D+/g, ''));
            }
            if (isNumber(testVal)) {
                if (min != null && testVal < min) {
                    return {'number_to_small': true};
                }
                if (max != null && testVal > max) {
                    return {'number_to_big': true};
                }
            } else {
                return {'not_a_number': true};
            }
        }
        return null;
    };
}
