import { QQService } from './qq.service';
import { CloseEventData, InputData, Lead, PostData, QQResult } from './qq.vo';
import { QQPricingRequestVO, QQPricingResponseVO } from './qq.pricing.vos';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SelectItem } from '../base/select.vo';
import { WindowRef } from '../base/window.ref';
import { QQContinue } from './qq.component';
import { DLUtil } from '../base/dl.util';
import { PhoneNumberValidator } from '../base/phone-number';
import { AfterViewInit } from '@angular/core';

// declare let KingsWatch: any;

/**
 * The QQ Base result component.
 * This component is in charge of displaying the slider for a quick price
 */
export abstract class QQResultBaseComponent implements AfterViewInit {

    qqContinue: QQContinue;
    qqPricingRequestData: QQPricingRequestVO;
    qqForm: FormGroup;
    benefit: QQPricingResponseVO;
    inputData = new InputData();
    resLoading: boolean;
    resShowWarning: boolean;
    idNumber: string;
    noEmail = false;

    constructor(
        public qqService: QQService,
        public winRef: WindowRef,
        public fb: FormBuilder) {
        this.qqForm = this.fb.group({
            fullname: [null, Validators.required],
            email: [null, Validators.compose([Validators.required, Validators.email, Validators.pattern(DLUtil.emailPattern)])],
            cell: [null, Validators.compose([Validators.required, PhoneNumberValidator.validator()])],
        });
        // set a few defaults
        this.resLoading = false;
        this.resShowWarning = false;
    }

    ngAfterViewInit() {
        try {
            // KingsWatch.aim();
        } catch (error) {
            console.log('Kings Watch failed to aim', error);
        }
    }

    public abstract openResult(qqRequestData: QQPricingRequestVO, idNumber: string,
        qqPricingResponse: QQPricingResponseVO, product: SelectItem, qqContinue: QQContinue);
    protected abstract handleQQResponse(response: QQResult);
    public abstract closeResultModal(event: CloseEventData);

    public postToContinue(force: boolean) {
        try {
            // KingsWatch.fire();
        } catch (error) {
            console.log('Kings Watch failed to fire', error);
        }

        this.setButtonForLoading();
        const postData = this.postData(force);
        this.qqService.continueToSP(postData).subscribe(
            res => this.handleQQResponse(res),
            () => this.handleQQResponseError()
        );
    }

    protected postData(force: boolean): PostData {
        const postData: PostData = new PostData();
        postData.qq_type = this.benefit.qq_type;
        if (this.inputData.leadId != null) {
            postData.leadid = this.inputData.leadId;
        }
        if (this.inputData.spId != null) {
            postData.spid = this.inputData.spId;
        } else if (this.winRef.nativeWindow.spid != null) {
            postData.spid = this.winRef.nativeWindow.spid;
        }
        if (this.inputData.needsId != null) {
            postData.needs_id = this.inputData.needsId;
        }
        postData.campaign_id = this.inputData.campaignId;
        postData.campaign_code = this.inputData.campaignCode;
        postData.referrer = this.inputData.httpReferrer;
        postData.referrer_code = this.inputData.referrerCode;
        postData.quickquote_source = this.inputData.source;
        postData.product_code = this.qqPricingRequestData.product_code;
        postData.product_group = this.qqPricingRequestData.product_group;
        postData.qq_product = this.qqPricingRequestData.product_code;
        postData.age = this.qqPricingRequestData.starting_age_year;
        postData.gender = this.qqPricingRequestData.gender;
        postData.income = this.qqPricingRequestData.income_gross_monthly;
        postData.education = this.qqPricingRequestData.education;
        postData.smoker_status = this.qqPricingRequestData.smoker;
        postData.pricing_engine_version = this.benefit.pricing_engine_version;
        postData.force = force;
        if (!this.inputData.embedded) {
            postData.embedded = false;
            postData.lead = new Lead(this.qqForm.get('fullname').value, this.qqForm.get('email').value, this.qqForm.get('cell').value);
            if (this.qqPricingRequestData.birth_date) {
                postData.lead.birth_date = this.qqPricingRequestData.birth_date;
            }
            postData.optionality = this.inputData.optionality;
            postData.lead_capture = this.inputData.leadCapture;
        } else {
            postData.embedded = true;
            postData.idnumber = this.idNumber;
        }
        postData.benefits = [];
        postData.benefits.push({
            id: this.benefit.needs_benefit_id,
            code: this.benefit.code,
            cover: this.benefit.cover,
            premium: this.benefit.premium,
            minCover: this.benefit.minCover,
            maxCover: this.benefit.maxCover,
            term: this.benefit.term
        });
        return postData;
    }

    protected resetButton() {
        this.resLoading = false;
    }

    protected setButtonForLoading() {
        this.resLoading = true;
    }


    public setNoEmail() {
        this.noEmail = !this.noEmail;
        if (this.noEmail) {
            this.qqForm.get('email').setValue('');
            this.qqForm.get('email').disable();
        } else {
            this.qqForm.get('email').enable();
        }
    }

    get busy(): boolean {
        return !this.inputData.embedded && (this.qqForm.invalid || this.qqForm.pristine || this.resLoading);
    }

    protected handleQQResponseError() {
        this.closeResultModal(CloseEventData.createErrorEvent('Failed to continue your Quote :('));
    }

    public close() {
        this.closeResultModal(CloseEventData.createCancelEvent());
    }

}
