import {Injectable} from '@angular/core';
import {BankDetail, AvsValidationResult} from './dl.banking.vo';
import {Observable, of} from 'rxjs';
import {catchError, map, timeoutWith} from 'rxjs/operators';
import {ValidationResVO} from '../../dsp/component/banking.validation.service';
import {AVSResultVO, DebicheckSupportedResVO} from '../../dsp/component/banking.validation.service';
import {HttpClient} from '@angular/common/http';
import {ErrorModalProvider} from '../error/error.modal.service';
import {BaseService} from '../base.service';
import {OverrideResponse} from '../../sp/payment/override/admin.override.service';

@Injectable({
    providedIn: 'root'
})
export class DlBankingValidationService extends BaseService {
    validateServiceUrl = '/dl/data/banking/validate';
    avsServiceUrl = '/dl/data/banking/avsValidation';
    avsValidationResultUrl = '/dl/data/banking/avsStatus';
    debicheckValidationServiceUrl = '/dl/data/banking/debicheck/supported';
    debicheckValidationServiceNoAVSUrl = '/dl/data/banking/debicheck/supportedNoAVS';
    riskAssessmentServiceUrl = '/dl/data/banking/riskAndKyc/assessment';
    riskAndKycStatusUrl = '/dl/data/banking/riskAndKyc/status';
    sendClientDocumentsUrl = '/dl/data/banking/send-client-documents-email';
    overrideRequest = '/dl/override';

    constructor(
        httpClient: HttpClient,
        errorModalProvider: ErrorModalProvider) {
        super(httpClient, errorModalProvider);
    }

    public validateBanking(bankDetail: BankDetail): Observable<ValidationResVO> {
        const req: ValidationReqVO = {
            account_number: bankDetail.accountNumber,
            branch_code: bankDetail.branchCode,
            account_type: bankDetail.accountType,
            ref: bankDetail.validationRef
        };
        return this.httpClient.post<ValidationResVO>(this.validateServiceUrl, req, this.httpOptions())
            .pipe(
                timeoutWith(7000, this.dummyValidationSuccessResponse()),
                catchError(this.handleHTTPError<ValidationResVO>())
            );
    }

    public validateBankingAVS(bankDetail: BankDetail): Observable<AVSResultVO> {
        const req: AVSReqVO = {
            ref: bankDetail.validationRef,
            account_number: bankDetail.accountNumber,
            account_type: bankDetail.accountType,
            branch_code: bankDetail.branchCode

        };
        return this.httpClient.post<AVSResultVO>(this.avsServiceUrl, req, this.httpOptions())
            .pipe(
                timeoutWith(20000, this.timeoutAvsErrorResponse()),
                catchError(this.handleHTTPError<AVSResultVO>())
            );
    }

    public getAvsValidationStatus(userRef: string): Observable<AvsValidationResult> {
        const req = {
            userRef: userRef
        };
        return this.httpClient.post<AvsValidationResult>(this.avsValidationResultUrl, req, this.httpOptions())
            .pipe(
                catchError(this.handleHTTPError<AvsValidationResult>())
            );
    }

    private dummyValidationSuccessResponse(): Observable<ValidationResVO> {
        const res = new ValidationResVO();
        res.valid = true;
        return of(res);
    }

    private timeoutAvsErrorResponse(): Observable<AVSResultVO> {
        const res = new AVSResultVO();
        res.success = false;
        res.count = 0;
        res.errmsg = 'AVS Validation timed out';
        return of(res);
    }

    public getDebicheckSupported(bankName: string, userRef: string): Observable<DebicheckSupportedResVO> {
        const req: DebicheckSupportedReqVO = {
            bankName: bankName,
            userRef: userRef
        };
        return this.httpClient.post<DebicheckSupportedResVO>(this.debicheckValidationServiceUrl, req, this.httpOptions())
            .pipe(
                timeoutWith(7000, this.timeoutDebicheckValidationErrorResponse()),
                catchError(this.handleHTTPError<DebicheckSupportedResVO>())
            );
    }

    public getDebicheckSupportedNoAVS(bankName: string): Observable<DebicheckSupportedResVO> {
        const req: DebicheckSupportedNoAVSReqVO = {
            bankName: bankName
        };
        return this.httpClient.post<DebicheckSupportedResVO>(this.debicheckValidationServiceNoAVSUrl, req, this.httpOptions())
            .pipe(
                timeoutWith(7000, this.timeoutDebicheckValidationErrorResponse()),
                catchError(this.handleHTTPError<DebicheckSupportedResVO>())
            );
    }

    public sendOverrideRequest(overrideItemId,
                               overrideContext,
                               accountExists = false,
                               acceptsDebits = false,
                               idMatches = false,
                               phoneValid = false,
                               forAltPayer = false,
                               relation = 'Other',
                               notes = '',
                               bankSupportsRTAvs = false): Observable<OverrideResponse> {
        let req = {
            override_item_type: overrideContext,
            overrideItemId: overrideItemId,
            override_page: 'ops-override',
            account_exists: accountExists,
            accepts_debits: acceptsDebits,
            id_number_matches: idMatches,
            phone_number_matches: phoneValid,
            for_alt_payer: forAltPayer,
            relation: relation,
            notes: notes,
            bankSupportsRTAvs: bankSupportsRTAvs
        };
        return this.httpClient.post<OverrideResponse>('/dl/override-ops', req, this.httpOptions())
            .pipe(
                catchError(this.handleHTTPError<OverrideResponse>())
            );
    }
    public sendRiskOverrideRequest(overrideItemId,
                               overrideContext,
                               riskRating,
                               incomeSource,
                               kycReviewNeeded,
                               isOver5Mil: boolean): Observable<OverrideResponse> {
        let req = {
            override_item_type: overrideContext,
            override_item_id: overrideItemId,
            override_page: 'risk-override',
            risk_rating: riskRating,
            income_source: incomeSource,
            kyc_review_needed: kycReviewNeeded,
            is_over_5_mil: isOver5Mil
        };
        return this.httpClient.post<OverrideResponse>('/dl/override-risk', req, this.httpOptions())
            .pipe(
                catchError(this.handleHTTPError<OverrideResponse>())
            );
    }

    public checkOverrideStatus(policyID): Observable<OverrideResponse> {
        return this.httpClient.get<OverrideResponse>(this.overrideRequest + '/' + policyID, this.httpOptions());
    }

    private timeoutDebicheckValidationErrorResponse(): Observable<DebicheckSupportedResVO> {
        const res = new DebicheckSupportedResVO();
        res.success = false;
        res.msg = 'Debicheck Support Validation timed out';
        return of(res);
    }

    private timeoutRiskAssessmentErrorResponse(): Observable<KycAndRiskAssessmentResponseVO> {
        const res = new KycAndRiskAssessmentResponseVO();
        res.success = false;
        res.reason = 'Risk assessment timed out';
        return of(res);
    }

    public performKycAndRiskAssessment(incomeSource: string,
                                       policyId: string,
                                       contactId: string): Observable<KycAndRiskAssessmentResponseVO> {
        const req: KycAndRiskAssessmentRequest = {
            incomeSource: incomeSource,
            policyId: policyId,
            contactId: contactId
        };
        return this.httpClient.post<KycAndRiskAssessmentResponseVO>(this.riskAssessmentServiceUrl, req, this.httpOptions())
            .pipe(
                timeoutWith(7000, this.timeoutRiskAssessmentErrorResponse()),
                catchError(this.handleHTTPError<KycAndRiskAssessmentResponseVO>())
            );
    }

    public getKycAndRiskStatus(contactId: string, policyId: string): Observable<KycAndRiskAssessmentResponseVO> {
        return this.httpClient.get<KycAndRiskAssessmentResponseVO>(this.riskAndKycStatusUrl + '/' + contactId + '/' + policyId, this.httpOptions())
            .pipe(
                timeoutWith(7000, this.timeoutRiskAssessmentErrorResponse()),
                catchError(this.handleHTTPError<KycAndRiskAssessmentResponseVO>())
            );
    }

    public sendEmailDocumentRequest(contactId: string): Observable<SendClientDocumentEmailResponse> {
        return this.httpClient.get<SendClientDocumentEmailResponse>(`${this.sendClientDocumentsUrl}/${contactId}`, this.httpOptions())
            .pipe(
                catchError(this.handleHTTPError<SendClientDocumentEmailResponse>())
            );
    }
}

interface ValidationReqVO {
    account_number: string;
    branch_code: string;
    account_type: string;
    ref: string;
}

interface AVSReqVO {
    ref: string;
    account_number: string;
    account_type: string;
    branch_code: string;
}

interface DebicheckSupportedReqVO {
    bankName: string;
    userRef: string;
}

interface DebicheckSupportedNoAVSReqVO {
    bankName: string;
}

export interface AVSResultVO {
    success: boolean;
    count: number;
    errmsg: string;
}

export interface ValidationResVO {
    valid: boolean;
}

export interface DebicheckSupportedResVO {
    success: boolean;
    msg: string;
    delayedSupported: boolean;
    realtimeSupported: boolean;
}

export interface OverrideRequest {
    success: boolean;
    msg: string;
}

export class KycAndRiskAssessmentResponseVO {
    success: boolean;
    reason: string;
    kyc_id: string;
    kyc_status: string;
    risk_rating: string;
    approved_override: boolean;
    rejected_override: boolean;
    review_required: boolean;
    is_over_5_mil: boolean;
}

interface KycAndRiskAssessmentRequest {
    incomeSource: string;
    policyId: string;
    contactId: string;
}

interface KycAndRiskStatusRequest {
    kycId: string;
}

export interface SendClientDocumentEmailResponse {
    data: Data;
}

export interface Data {
    id: string;
    type: string;
    // Can add more later, for now this is all we need.
}
