<div class="row" *ngIf="canSkip && beneficiaries.length <= 0">
    <div class="col s6 offset-s3">
        <button (click)="skip()" tabindex="-1" type="button" mat-raised-button class="dl-full-width"
            [disabled]="skipping">I'll do this later</button>
    </div>
</div>
<div *ngIf="beneficiaries.length > 0" class="row">
    <div class="col s12">
        <mat-list>
            <mat-list-item *ngFor="let item of beneficiaries">
                <div class="col s6">
                    {{item.name}}
                </div>
                <div class="col s3 align-right">
                    {{item.percentage}}&nbsp;%
                </div>
                <div class="col s3">
                    <div class="dsp-edit-group-icon right">
                        <button (click)="editItem(item)" mat-mini-fab [attr.data-name]="'beneficiary.add'"
                            tabindex="1"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;
                        <button (click)="removeItem(item)" mat-mini-fab [attr.data-name]="'beneficiary.remove'"
                            tabindex="1"><i class="fa fa-times" aria-hidden="true"></i></button>
                    </div>
                </div>
            </mat-list-item>
            <mat-list-item class="{{listWarnClass}}">
                <div class="col s6"><span *ngIf="showTotalError"><b>Total must be 100%</b></span></div>
                <div class="col s6" style="text-align: right"><span><b>Total:</b> {{total}}%</span></div>
            </mat-list-item>
        </mat-list>
    </div>
</div>
<div *ngIf="total !== 100 || editMode">
    <form [formGroup]="beneficiariesForm" [id]="flowId + '-' + 'BENEFICIARY'">
        <div class="row">
            <div class="col m6 s12">
                <mat-form-field class="dl-full-width">
                    <input tabindex="1" matInput placeholder="Name" [formControl]="beneficiariesForm.controls['name']"
                        [attr.data-name]="'beneficiary.name'">
                </mat-form-field>
            </div>
            <div class="col m6 s12">
                <mat-form-field class="dl-full-width">
                    <mat-select [tabIndex]="1" placeholder="Relationship to Policy Holder"
                        [formControl]="beneficiariesForm.controls['relation']" [attr.data-name]="'beneficiary.relation'"
                        id="relation" (selectionChange)="selectionChange('relation')">
                        <mat-option *ngFor="let rel of relationOptions" id="{{rel.value}}" [attr.data-val]="rel.value"
                            [value]="rel.value" [attr.data-name]="rel.value">{{rel.label}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col m8 s12" *ngIf="!hideId">
                <mat-form-field class="dl-full-width">
                    <input matInput tabindex="1" [placeholder]="featureSS3217_COMPULSORY_BENEFICIARY_ID ? 'RSA ID' : 'RSA ID or Date of Birth'"
                        [formControl]="beneficiariesForm.controls['idDob']" [attr.data-name]="'beneficiary.rsaid'">
                    <mat-error align="start">{{featureSS3217_COMPULSORY_BENEFICIARY_ID ? 'Enter a Valid RSA-id' : 'Enter a Valid RSA-id or DOB : (yyyyMMdd)'}}</mat-error>
                    <mat-hint align="start"
                        *ngIf="beneficiariesForm.controls['idDob'].invalid && !beneficiariesForm.controls['idDob'].pristine">{{featureSS3217_COMPULSORY_BENEFICIARY_ID ? 'Enter a Valid RSA-id' : 'Enter a Valid RSA-id or DOB : (yyyyMMdd)'}}
                    </mat-hint>
                </mat-form-field>
            </div>
            <div class="col m8 s12" *ngIf="hasEmail">
                <mat-form-field class="dl-full-width">
                    <input type="email" [name]="component.id" matInput tabindex="1" placeholder="Email"
                        [formControl]="beneficiariesForm.controls['email']" [attr.data-name]="'beneficiary.email'">
                </mat-form-field>
            </div>
            <div class="col m4 s12">
                <mat-form-field class="dl-full-width">
                    <input tabindex="1" matInput placeholder="Percentage" type="number"
                        [formControl]="beneficiariesForm.controls['percentage']"
                        [attr.data-name]="'beneficiary.percentage'">
                    <mat-error align="start">Not more than 100%</mat-error>
                    <mat-hint align="start"
                        *ngIf="beneficiariesForm.controls['percentage'].invalid && !beneficiariesForm.controls['percentage'].pristine">Not
                        more than 100%</mat-hint>
                </mat-form-field>
            </div>
            <div class="col m6 s12">
                <mat-form-field class="dl-full-width">
                    <input tabindex="1" matInput placeholder="Phone number" type="text"
                        [formControl]="beneficiariesForm.controls['phone_number']"
                        [attr.data-name]="'beneficiary.phone_number'">
                    <mat-error align="start"
                        *ngIf="beneficiariesForm.controls['phone_number'].invalid && !beneficiariesForm.controls['phone_number'].pristine">Invalid
                        phone number provided</mat-error>
                </mat-form-field>
            </div>
            <div class="col m6 s12">
                <mat-form-field class="dl-full-width">
                    <input tabindex="1" matInput placeholder="Email" type="email"
                        [formControl]="beneficiariesForm.controls['email']" [attr.data-name]="'beneficiary.email'">
                    <mat-error align="start"
                        *ngIf="beneficiariesForm.controls['email'].invalid && !beneficiariesForm.controls['email'].pristine">Invalid
                        email provided</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col m3 s4 offset-m9 offset-s8">
                <button (click)="addItem()" tabindex="1" type="button" mat-raised-button class="dl-full-width"
                    [disabled]="beneficiariesForm.pristine || beneficiariesForm.invalid"
                    [attr.data-name]="'beneficiary.save'"><span *ngIf="editMode">Save</span><span
                        *ngIf="!editMode">Add</span></button>
            </div>
        </div>
    </form>
</div>