<div class="bg-corporate-dark" style="border-radius: 15px;">
    <section class="mt-5 mr-2 ml-2 mb-2 qq-section" *ngIf="!showResults">
        <div class="container">
            <div class="row">
                <div class="col-md-12 pt-4">
                    <div class="text-light">
                        <div>
                            <h3>Please provide us with your age & gender to start your quote</h3>
                        </div>
                    </div>
                    <!-- <div class="text-light">
                        <div>
                            <p class="font-weight-lighter">Please provide your age & gender to start your quote</p>
                        </div>
                    </div> -->
                    <div class="pt-3">
                        <!-- <div class="form-group">
                            <input type="text"
                                   class="form-control rounded-0 py-4 px-3 border-0 bg-corporate-3 text-corporate-light"
                                   tabindex="1"
                                   [formControl]="rsaIdControl"
                                   [attr.data-name]="'idNumber'"
                                   id="InputID" placeholder="Your ID Number*">
                        </div> -->
                        <div class="form-group">
                            <div class="pt-1">
                                <mat-form-field class="dl-full-width" appearance="outline">
                                    <mat-label>Age</mat-label>
                                    <input type="number" matInput style="color: #fff;" [formControl]="ageControl" (keyup)="validate()">
                                </mat-form-field>
                            </div>
                            <div class="mb-3">
                                <label style="color: #fff;">Gender</label>
                                <br>
                                <mat-radio-group [formControl]="genderControl" (change)="validate()">
                                    <mat-radio-button value="M" style="color: #fff;">Male</mat-radio-button>
                                    <mat-radio-button value="F" style="color: #fff; margin-left: 16px;">Female</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div class="form-group pt-2" *ngIf="feedback !== ''">
                            <h3 class="form-control rounded-0 border-0 text-corporate-primary bg-corporate-dark">{{feedback}}</h3>
                        </div>
                        <button type="button"
                                class="text-corporate-dark bg-corporate-primary rounded-0 py-2 w-100 border-0"
                                [disabled]="!valid || loading"
                                tabindex="1"
                                style="margin-top: 15px; margin-bottom: 15px"
                                id="expressNext"
                                [attr.data-event]="'true'"
                                [attr.data-event-category]="'Submit'"
                                [attr.data-event-name]="'Submit'"
                                [attr.data-event-label]="'Submit'"
                                (click)="submit()">Next
                            <i *ngIf="loading" class="fa fa-circle-o-notch fa-spin"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="m-5 pt-2" *ngIf="showResults">
        <div class="container">
            <div class="row">
                <div class="text-corporate-light">
                    <div>
                        <h1>Your offers</h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="container pt-0 pb-3 pr-3 pl-3 border border-corporate-1">
            <div class="row">
                <div class="col-md-12">

                    <div>
                        <div class="form-group">
                            <!--label for="formControlRange">Range input</label>
                            <input type="range" class="form-control-range" id="formControlRange"-->
                            <mat-slider
                                [invert]="false"
                                [max]="maxCover"
                                [min]="minCover"
                                [step]="increments"
                                [thumbLabel]="true"
                                [displayWith]="formatDisplay"
                                [value]="benefit_amount"
                                (input)="updateCover($event)">
                            </mat-slider>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div>
                        <h4 class="text-corporate-1 pricing-text">{{minCover | dl_currency:true:'R':0}}</h4>
                    </div>
                </div>
                <div class="col">
                    <div>
                        <h4 class="text-right text-corporate-1 pricing-text">{{maxCover | dl_currency:true:'R':0}}</h4>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div>
                        <p class="font-weight-lighter text-corporate-light">Move slider to change amount...</p>
                    </div>
                </div>
            </div>

            <!--div class="row">
                <div class="col s12" style="margin-top: -20px;">
                    <div class="left">{{minCover | dl_currency:true:'R':0}}</div>
                    <div class="right">{{maxCover | dl_currency:true:'R':0}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col s12">
                    <small>Change slider to change the cover amount</small>
                </div>
            </div-->
            <div class="row mt-5">
                <div class="col">
                    <div>
                        <div class="text-center text-corporate-primary">
                            <h2><b>{{ benefit_amount | dl_currency:true:'R':0 }} Funeral Cover</b></h2>
                        </div>
                    </div>
                    <div>
                        <div class="text-center text-corporate-light">
                            <p class="font-weight-lighter">For only {{ premium_amount | dl_currency:true:'R':2 }} p/m</p>
                        </div>
                    </div>
                    <div>
                        <div class="text-center text-corporate-light">
                            <button class="bg-corporate-primary text-corporate-dark border-0 font-weight-medium py-2 px-4"
                                    [disabled]="!valid"
                                    tabindex="1"
                                    type="button"
                                    id="expressFuneralButton"
                                    [attr.data-event]="'true'"
                                    [attr.data-event-category]="'Submit'"
                                    [attr.data-event-name]="'Submit'"
                                    [attr.data-event-label]="'Submit'"
                                    (click)="buyNow()">Get this offer</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="m-4 pb-2" *ngIf="showResults">
        <div class="container">
            <div class="row d-flex justify-content-center pb-4">
                <div class="text-corporate-light">
                    <div class="circle-text align-middle justify-content-center">
                        <h4 class="bg-light rounded-circle text-corporate-dark w-100 h-100 d-flex justify-content-center">or</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="container pb-3 pr-3 pl-3 border border-corporate-1"
             style="margin-bottom: 15px">
            <div class="row mt-3">
                <div class="col">

                    <div>
                        <div class="text-center text-corporate-light">
                            <p class="font-weight-lighter">For {{more_premium | dl_currency:true:'R':0}} p/m get <b>up to <h2 style="display: inline"><span class="text-corporate-primary font-weight-bolder">{{more_cover | dl_currency:true:'R':0}} Life Cover</span></h2></b> assuming</p>
                            <p class="font-weight-lighter">standard life. Select <b>apply for more</b> to answer</p>
                            <p class="font-weight-lighter">a few more questions (it only takes 2 minutes).</p>
                        </div>
                    </div>
                    <!--div>
                        <div class="text-center text-corporate-primary">
                            <p class="text-corporate-light text-increase">Apply for more cover, get up to</p>
                            <h2 class="larger-heading">{{more_cover | dl_currency:true:'R':0}} cover</h2>
                        </div>
                    </div-->
                    <div>
                        <div class="text-center text-corporate-light">
                            <button class="bg-corporate-primary text-corporate-dark font-weight-medium border-0 py-2 px-4"
                                    [disabled]="!valid"
                                    tabindex="1"
                                    type="button"
                                    id="expressLifeCoverButton"
                                    [attr.data-event]="'true'"
                                    [attr.data-event-category]="'Submit'"
                                    [attr.data-event-name]="'Submit'"
                                    [attr.data-event-label]="'Submit'"
                                    (click)="moreCover()">Apply for more</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!--div class="col m12" *ngIf="showResults">
        <mat-card>
            <mat-card-title class="title">Your Cover</mat-card-title>
            <mat-card-content>
                <p style="margin-bottom: 20px;">{{name}}, we can offer you
                    <b>{{ benefit_amount | dl_currency:true:'R':0 }}</b> cover for
                    <b>{{ premium_amount | dl_currency:true:'R':2 }}</b>&nbsp;p/m.
                </p>
                <div class="row">
                    <div class="col s12">
                        <mat-slider
                            [invert]="false"
                            [max]="maxCover"
                            [min]="minCover"
                            [step]="increments"
                            [thumbLabel]="true"
                            [displayWith]="formatDisplay"
                            [value]="benefit_amount"
                            (input)="updateCover($event)">
                        </mat-slider>
                    </div>
                </div>
                <div class="row">
                    <div class="col s12" style="margin-top: -20px;">
                        <div class="left">{{minCover | dl_currency:true:'R':0}}</div>
                        <div class="right">{{maxCover | dl_currency:true:'R':0}}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col s12">
                        <small>Change slider to change the cover amount</small>
                    </div>
                </div>
            </mat-card-content>
            <div class="dl-row row dl-button-row">
                <div class="dl-col-md-6 col s6">
                    <button
                        [disabled]="!valid"
                        mat-raised-button
                        color="primary"
                        class="dl-full-width dl-action-btn"
                        style="margin-top: 15px; margin-bottom: 15px"
                        tabindex="1"
                        type="button"
                        [attr.data-event]="'true'"
                        [attr.data-event-category]="'Submit'"
                        [attr.data-event-name]="'Submit'"
                        [attr.data-event-label]="'Submit'"
                        (click)="moreCover()">
                        More cover
                    </button>
                </div>
                <div class="dl-col-md-6 col s6">
                    <button
                        [disabled]="!valid"
                        mat-raised-button
                        color="primary"
                        class="dl-full-width dl-action-btn"
                        style="margin-top: 15px; margin-bottom: 15px"
                        tabindex="1"
                        type="button"
                        [attr.data-event]="'true'"
                        [attr.data-event-category]="'Submit'"
                        [attr.data-event-name]="'Submit'"
                        [attr.data-event-label]="'Submit'"
                        (click)="buyNow()">
                        Buy now
                    </button>
                </div>
            </div>
        </mat-card>
    </div-->
</div>
