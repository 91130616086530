import {Benefit, BenefitAddon} from './benefit';
import {Log} from 'ng2-logger/browser';
/**
 * Created by corneliusbotha on 2017/02/21.
 * This class contains all the function for benefit premium calculations
 */

const log = Log.create('BenefitCalculator');

export class BenefitCalculator {

    private static primaryPremium(productData: Benefit, withDiscount: boolean): number {
        log.info('primaryPremium ' + productData.code);
        if (!productData.abModifier) {
            productData.abModifier = 1;
        }
        const baseCover = productData.cover / productData.coverEscalationFactor;
        let premium = -((productData.multiplierFactor * baseCover) + productData.numeratorFactor) / productData.denominatorFactor;
        if (withDiscount) {
            premium *= (1 - productData.discountRate);
        }
        premium *= productData.escalationFactor;
        premium *= productData.abModifier;
        premium = (Math.round(premium * 100) / 100); // ADD AB MODIFIER
        return premium;
    }

    private static primaryCover(productData: Benefit): number {
        log.info('primaryCover ' + productData.code);
        const basePremium = productData.premium / (1 - productData.discountRate) / productData.escalationFactor;
        const baseCover = -((basePremium * productData.denominatorFactor) + productData.numeratorFactor) / productData.multiplierFactor;
        let cover = baseCover * productData.coverEscalationFactor;
        cover = (Math.round(cover * 100) / 100);
        return cover;
    }

    private static percentageOfProducts(productData: Benefit, products: Benefit[], target?: boolean) {
        log.info('percentageOfProducts ' + productData.code);
        let total_premium = 0;
        if (products != null && products.length > 0) {
            products.forEach(product => {
                if (product.added && product.type === 'product') {
                    if (target != null && target === true) {
                        total_premium += product.newPremium;
                    } else {
                        total_premium += product.premium;
                    }
                    if (product.addons) {
                        product.addons.forEach(addon => {
                            if (addon.added) {
                                total_premium += addon.premium;
                            }
                        });
                    }
                }
            });
        }
        let premium = total_premium * productData.percentageOfTotal;
        premium = (Math.round(premium * 100) / 100);
        return premium;
    }

    private static noCalcPremium(productData: Benefit) {
        log.info('noCalcPremium ' + productData.code);
        return productData.premium;
    }

    private static noCalcCover(productData: Benefit) {
        log.info('noCalcCover ' + productData.code);
        return productData.cover;
    }

    private static addonAFPremium(productData: Benefit, addonData: BenefitAddon): number {
        log.info('addonAFPremium ' + productData.code + '-' + addonData.code);
        const baseCover = productData.cover / productData.coverEscalationFactor;
        const pfof = addonData.cover / productData.cover;
        const pacc = productData.multiplierFactor * pfof;
        const arc = (baseCover * addonData.funeralClaimsProbIncrease * pacc) / productData.denominatorFactor * -1;
        let premium = (10 + Math.ceil(arc)) * productData.escalationFactor;
        premium = (Math.round(premium * 100) / 100);
        return premium;
    }

    private static addonAFCover(productData: Benefit, addonData: BenefitAddon): number {
        log.info('addonAFCover ' + productData.code + '-' + addonData.code);
        const escMaxCover = addonData.maxCover * productData.coverEscalationFactor;
        let cover = Math.min(escMaxCover, Math.round(0.1 * productData.cover));
        cover = (Math.round(cover * 100) / 100);
        return cover;
    }

    private static addonDBPremium(productData: Benefit, addonData: BenefitAddon): number {
        log.info('addonDBPremium ' + productData.code + '-' + addonData.code);
        return 0;
    }

    private static addonDBCover(productData: Benefit, addonData: BenefitAddon): number {
        log.info('addonDBCover ' + productData.code + '-' + addonData.code);
        return 0;
    }

    private static addonTIBPremium(productData: Benefit, addonData: BenefitAddon): number {
        log.info('addonTIBPremium ' + productData.code + '-' + addonData.code);
        const baseCover = productData.cover / productData.coverEscalationFactor;
        const pacc = 0.01 * baseCover * productData.multiplierFactor;
        const arc = pacc / productData.denominatorFactor * -1;
        let premium = (10 + Math.ceil(arc)) * productData.escalationFactor;
        premium = (Math.round(premium * 100) / 100);
        return premium;
    }

    private static addonTIBCover(productData: Benefit, addonData: BenefitAddon) {
        log.info('addonTIBCover ' + productData.code + '-' + addonData.code);
        return -1; // dont show
    }

    private static addonDAPremium(productData: Benefit, addonData: BenefitAddon): number {
        log.info('addonDAPremium ' + productData.code + '-' + addonData.code);
        const addonBaseCover = addonData.cover / productData.coverEscalationFactor;
        let premium = -(addonBaseCover * addonData.multiplierFactor) / addonData.denominatorFactor * productData.escalationFactor;
        premium = (Math.round(premium * 100) / 100);
        return premium;
    }

    private static addonDACover(productData: Benefit, addonData: BenefitAddon): number {
        log.info('addonDACover ' + productData.code + '-' + addonData.code);
        let cover = Math.max(Math.min(
            productData.originalMaxCover * productData.coverEscalationFactor,
            (productData.cover * 2)) - productData.cover, 0);
        cover = (Math.round(cover * 100) / 100);
        return cover;
    }

    private static addonGABPremium(productData: Benefit, addonData: BenefitAddon): number {
        log.info('addonGABPremium ' + productData.code + '-' + addonData.code);
        const addonBaseCover = addonData.cover / productData.coverEscalationFactor;
        const pacc = 0.025 * addonBaseCover * productData.multiplierFactor;
        const arc = pacc / productData.denominatorFactor * -1;
        let premium = (10 + Math.ceil(arc)) * productData.escalationFactor;
        premium = (Math.round(premium * 100) / 100);
        return premium;
    }

    private static addonGABCover(productData: Benefit, addonData: BenefitAddon): number {
        log.info('addonGABCover ' + productData.code + '-' + addonData.code);
        let cover = Math.min(2 * productData.cover, productData.maxProductCover * productData.coverEscalationFactor) - productData.cover;
        cover = (Math.round(cover * 100) / 100);
        return cover;
    }

    private static addonRCPremium(productData: Benefit, addonData: BenefitAddon): number {
        log.info('addonRCPremium ' + productData.code + '-' + addonData.code);
        const rc = this.addonRCCover(productData, addonData) / productData.coverEscalationFactor
            * addonData.retrenchmentFactor / productData.denominatorFactor;
        let premium = (addonData.retrenchmentMargin + rc) * productData.escalationFactor;
        premium = (Math.round(premium * 100) / 100);
        return premium;
    }

    private static addonRCCover(productData: Benefit, addonData: BenefitAddon): number {
        log.info('addonRCCover ' + productData.code + '-' + addonData.code);
        let cover = Math.min(productData.cover, 40000 * productData.coverEscalationFactor);
        cover = (Math.round(cover * 100) / 100);
        return cover;
    }

    public static calc(calculateFunction: string, productData: Benefit, products?: Benefit[]) {
        const calcFunctions = {
            'primaryPremium': function () {
                return BenefitCalculator.primaryPremium(productData, true);
            },
            'primaryPremiumNoDiscount': function () {
                return BenefitCalculator.primaryPremium(productData, false);
            },
            'primaryCover': BenefitCalculator.primaryCover,
            'percentageOfProducts': BenefitCalculator.percentageOfProducts,
            'percentageOfProductsNoDiscount': BenefitCalculator.percentageOfProducts,
            'percentageOfProductsTarget':  function () {
                return BenefitCalculator.percentageOfProducts(productData, products, true);
            },
            'noCalcPremium': BenefitCalculator.noCalcPremium,
            'noCalcCover': BenefitCalculator.noCalcCover
        };
        if (calcFunctions.hasOwnProperty(calculateFunction)) {
            if (products != null) {
                return (calcFunctions[calculateFunction])(productData, products);
            }
            return (calcFunctions[calculateFunction])(productData);
        } else {
            log.error(calculateFunction + ' does not exist');
            return -1;
        }
    }

    public static calcAddon(calculateFunction: string, productData: Benefit, addonData: BenefitAddon): number {
        const calcAddonFunctions = {
            'addonAFPremium': BenefitCalculator.addonAFPremium,
            'addonAFCover': BenefitCalculator.addonAFCover,
            'addonTIBPremium': BenefitCalculator.addonTIBPremium,
            'addonTIBCover': BenefitCalculator.addonTIBCover,
            'addonDAPremium': BenefitCalculator.addonDAPremium,
            'addonDACover': BenefitCalculator.addonDACover,
            'addonGABPremium': BenefitCalculator.addonGABPremium,
            'addonGABCover': BenefitCalculator.addonGABCover,
            'addonRCPremium': BenefitCalculator.addonRCPremium,
            'addonRCCover': BenefitCalculator.addonRCCover
        };
        if (calcAddonFunctions.hasOwnProperty(calculateFunction)) {
            return (calcAddonFunctions[calculateFunction])(productData, addonData);
        } else {
            log.error(calculateFunction + ' does not exist');
            return -1;
        }
    }

    public static getCalc(calculateFunction: string): string {
        const calcFunctions = {
            'primaryPremium': BenefitCalculator.primaryPremium,
            'primaryCover': BenefitCalculator.primaryCover,
            'percentageOfProducts': BenefitCalculator.percentageOfProducts,
            'noCalcPremium': BenefitCalculator.noCalcPremium,
            'noCalcCover': BenefitCalculator.noCalcCover,
            'addonAFPremium': BenefitCalculator.addonAFPremium,
            'addonAFCover': BenefitCalculator.addonAFCover,
            'addonTIBPremium': BenefitCalculator.addonTIBPremium,
            'addonTIBCover': BenefitCalculator.addonTIBCover,
            'addonDAPremium': BenefitCalculator.addonDAPremium,
            'addonDACover': BenefitCalculator.addonDACover,
            'addonGABPremium': BenefitCalculator.addonGABPremium,
            'addonGABCover': BenefitCalculator.addonGABCover
        };
        if (calcFunctions.hasOwnProperty(calculateFunction)) {
            return (calcFunctions[calculateFunction]).toString();
        }
        return 'unknown function';
    }

    public static calculateDiscount(premium: number, discountRate: number): number {
        log.info('calculateDiscount');
        if (discountRate != null && discountRate > 0) {
            return Math.floor((premium / (1 - discountRate)) - premium);
        }
        return 0;
    }

    public static calculateTotalDiscount(benefits: Benefit[]): number {
        log.info('calculateTotalDiscount');
        let discount = 0;
        if (benefits != null) {
            benefits.forEach((benefit) => {
                if (benefit.added) {
                    discount += BenefitCalculator.calculateDiscount(benefit.premium, benefit.discountRate);
                }
            });
        }
        return Math.floor(discount);
    }

    public static calculateTotal(benefits: Benefit[]): number {
        log.info('calculateTotal');
        let total = 0;
        benefits.forEach(benefit => {
            if (benefit.added) {
                total += benefit.premium * 100;
                if (benefit.addons) {
                    benefit.addons.forEach(addon => {
                        if (addon.added) {
                            total += addon.premium * 100;
                        }
                    });
                }
            }
        });
        total = total / 100;
        return total;
    }

}
