import {FormControl} from '@angular/forms';
import {Component} from '@angular/core';
import {Dynamic} from './component';
import {WindowRef} from '../../base/window.ref';
import { PageComponentVO } from '../page.data.vo';

@Component({
    template: `
    <div class="row" *ngIf="!component.shareRow">
        <div class="col s12 m6 offset-m3">
            <button
              mat-raised-button
              class="mat-primary dl-full-width dl-action-btn"
              (click)="click()">{{component.name}}</button>
        </div>
    </div>
    <button *ngIf="component.shareRow"
            mat-raised-button
            class="mat-primary dl-full-width dl-action-btn"
            (click)="click()">{{component.name}}</button>
    `,
    selector: 'resume-button',
})
export class ResumeButtonComponent extends Dynamic<PageComponentVO> {

    constructor(private winRef: WindowRef) {
        super();
    }

    setupFromControl() {
        this.component.loaded = true;
        // tel my parent I'm loaded.
        this.loaded.emit(this.component.id);
    }

    getFormControl(): FormControl {
        return null;
    }

    onChange(): void {
    }

    click() {
        // We need to tel the parent to load the saved data.
        this.resume.emit(true);
    }
}
