import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ResetPasswordService } from './reset.password.service';
import { PasswordMatchValidator } from '../base/passwordMatch.validator';
import { isNullOrUndefined } from 'util';
import { WindowRef } from '../base/window.ref';
import { LoginService } from './login.service';
var SetPasswordComponent = /** @class */ (function () {
    function SetPasswordComponent(resetPasswordService, loginService, winRef) {
        this.resetPasswordService = resetPasswordService;
        this.loginService = loginService;
        this.winRef = winRef;
        this.busy = false;
        this.errorMsg = '';
        this.setupForm();
    }
    Object.defineProperty(SetPasswordComponent.prototype, "orgUrl", {
        get: function () {
            return this._orgUrl;
        },
        set: function (value) {
            this._orgUrl = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SetPasswordComponent.prototype, "oauthClientId", {
        get: function () {
            return this._oauthClientId;
        },
        set: function (value) {
            this._oauthClientId = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SetPasswordComponent.prototype, "token", {
        get: function () {
            return this._token;
        },
        set: function (val) {
            this._token = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SetPasswordComponent.prototype, "userName", {
        get: function () {
            return this._userName;
        },
        set: function (val) {
            this._userName = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SetPasswordComponent.prototype, "passwordHelpText", {
        get: function () {
            return PasswordMatchValidator.passwordPatternHelpText();
        },
        enumerable: true,
        configurable: true
    });
    SetPasswordComponent.prototype.setupForm = function () {
        this.setPasswordForm = new FormGroup({
            password: new FormControl('', [
                Validators.required,
                Validators.pattern(PasswordMatchValidator.passwordPattern()),
            ]),
            confirmPassword: new FormControl('', [Validators.required])
        }, PasswordMatchValidator.matchPasswords());
    };
    SetPasswordComponent.prototype.sendReset = function () {
        var _this = this;
        this.busy = true;
        var data = {
            action: 'reset',
            username: this.userName,
            token: this.token,
            password: this.setPasswordForm.get('password').value
        };
        this.resetPasswordService.resetPassword(data).subscribe(function (res) { return _this.handleResetResponse(res); }, function () { return _this.busy = false; });
    };
    SetPasswordComponent.prototype.handleResetResponse = function (res) {
        var _this = this;
        if (!isNullOrUndefined(res.success) && (res.success || res.success === 'true')) {
            if (!isNullOrUndefined(this.orgUrl) && this.orgUrl !== '') {
                this.loginService.loginToOrg(this.orgUrl, this.oauthClientId, this.userName, this.setPasswordForm.get('password').value, this).subscribe(function () { }, function () {
                    _this.processSignIn(false, 'Error communicating to the login server, please try again');
                });
            }
            else {
                this.processSignIn(true);
            }
        }
        else {
            this.processSignIn(false, res.message);
        }
    };
    SetPasswordComponent.prototype.processSignIn = function (success, message) {
        if (success) {
            this.winRef.nativeWindow.location = '/dl/profile';
        }
        else {
            this.errorMsg = message;
        }
        this.busy = false;
    };
    return SetPasswordComponent;
}());
export { SetPasswordComponent };
