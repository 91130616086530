var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Dynamic } from './component';
import { isNullOrUndefined } from 'util';
var CheckboxGroupComponent = /** @class */ (function (_super) {
    __extends(CheckboxGroupComponent, _super);
    function CheckboxGroupComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.checkItems = [];
        _this.selectedItems = [];
        return _this;
    }
    CheckboxGroupComponent.prototype.setupFromControl = function () {
        this.component.loaded = true;
        // tel my parent I'm loaded.
        this.loaded.emit(this.component.id);
    };
    CheckboxGroupComponent.prototype.getFormControl = function () {
        return null;
    };
    CheckboxGroupComponent.prototype.processExtraData = function () {
        var _this = this;
        var extraInput = this.component.extraInput;
        extraInput.forEach(function (item) {
            _this.checkItems.push({
                id: item.id,
                check: false,
                display: item.display,
                clearAll: item.clearAll,
                info: item.info
            });
        });
        if (this.selectedItems.length > 0) {
            this.component.isValid = true;
            this.change.emit(this.component.counter);
        }
    };
    CheckboxGroupComponent.prototype.itemChange = function (change, itemId) {
        var _this = this;
        var checkItem = this.checkItems.find(function (i) { return i.id === itemId; });
        checkItem.check = change.checked;
        if (checkItem.clearAll && checkItem.check) {
            this.checkItems.forEach(function (i) {
                if (i.id !== checkItem.id) {
                    i.check = false;
                }
            });
        }
        else if (checkItem.check) {
            var clearAllItem = this.checkItems.filter(function (i) { return i.clearAll; });
            if (!isNullOrUndefined(clearAllItem)) {
                clearAllItem.forEach(function (i) { return i.check = false; });
            }
        }
        this.selectedItems = [];
        this.checkItems.forEach(function (i) {
            if (i.check) {
                _this.selectedItems.push(i.id);
            }
        });
        this.component.value = this.selectedItems;
        this.component.isValid = this.selectedItems.length > 0;
        this.change.emit(this.component.counter);
    };
    return CheckboxGroupComponent;
}(Dynamic));
export { CheckboxGroupComponent };
