/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./dl.org.result.block.component.ngfactory";
import * as i2 from "./sg.result.block.component.ngfactory";
import * as i3 from "./dynamic.qq.result.block.component";
var styles_DynamicQQResultBlockComponent = [];
var RenderType_DynamicQQResultBlockComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DynamicQQResultBlockComponent, data: {} });
export { RenderType_DynamicQQResultBlockComponent as RenderType_DynamicQQResultBlockComponent };
export function View_DynamicQQResultBlockComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { dynamicComponentContainer: 0 }), (_l()(), i0.ɵeld(1, 16777216, [[1, 3], ["dynamicComponentContainer", 1]], null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_DynamicQQResultBlockComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "dynamic_qq_block", [], null, null, null, View_DynamicQQResultBlockComponent_0, RenderType_DynamicQQResultBlockComponent)), i0.ɵprd(8704, null, i0.ComponentFactoryResolver, i0.ɵCodegenComponentFactoryResolver, [[8, [i1.OrgResultBlockComponentNgFactory, i2.SGResultBlockComponentNgFactory]], [3, i0.ComponentFactoryResolver], i0.NgModuleRef]), i0.ɵdid(2, 49152, null, 0, i3.DynamicQQResultBlockComponent, [i0.ComponentFactoryResolver], null, null)], null, null); }
var DynamicQQResultBlockComponentNgFactory = i0.ɵccf("dynamic_qq_block", i3.DynamicQQResultBlockComponent, View_DynamicQQResultBlockComponent_Host_0, { componentData: "componentData" }, {}, []);
export { DynamicQQResultBlockComponentNgFactory as DynamicQQResultBlockComponentNgFactory };
