import { isNullOrUndefined } from 'util';
import { Log } from 'ng2-logger/client';
var log = Log.create('ErrorVO');
var ErrorVO = /** @class */ (function () {
    function ErrorVO() {
    }
    Object.defineProperty(ErrorVO.prototype, "message", {
        get: function () {
            if (isNullOrUndefined(this._message) || this._message.length === 0) {
                if (!isNullOrUndefined(this.statusText) && this.statusText.length > 0) {
                    return this.statusText;
                }
                return 'Unknown Error';
            }
            return this._message;
        },
        set: function (value) {
            this._message = value;
        },
        enumerable: true,
        configurable: true
    });
    ErrorVO.toErrorVO = function (error) {
        log.info('Extract error from:', error);
        var vo = new ErrorVO();
        if (!isNullOrUndefined(error.statusText)) {
            vo.statusText = error.statusText;
        }
        try {
            var errorDetail = void 0;
            if (!isNullOrUndefined(error.error)) {
                errorDetail = error.error;
            }
            else {
                errorDetail = error.json(); // can get rid of this once all converted from old http promise...
            }
            if (!isNullOrUndefined(errorDetail.detail)) {
                vo.detail = errorDetail.detail;
            }
            else if (!isNullOrUndefined(errorDetail.error_detail)) {
                vo.detail = errorDetail.error_detail;
            }
            if (typeof errorDetail === 'string') {
                vo.message = errorDetail;
            }
            else if (typeof errorDetail.message === 'string') {
                vo.message = errorDetail.message;
            }
            else if (typeof errorDetail.error === 'string') {
                vo.message = errorDetail.error;
            }
            if (isNullOrUndefined(vo.message) && !isNullOrUndefined(vo.detail) && typeof vo.detail.error === 'string') {
                vo.message = vo.detail.error;
            }
        }
        catch (e) { }
        return vo;
    };
    return ErrorVO;
}());
export { ErrorVO };
