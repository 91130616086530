import {Injectable} from '@angular/core';
import {DisclosureGroupVO, DisclosureVO, StopVO, SuccessVO} from './disclosures.vos';
import {ErrorVO} from '../error/error.vo';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {ErrorModalProvider} from '../error/error.modal.service';
import {Observable, throwError} from 'rxjs';

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class DisclosuresService {

    private baseUrl = '/dl/data/disclosures/';
    private sp_mode: string;

    constructor(
        private http: HttpClient,
        private errorModalProvider: ErrorModalProvider
    ) {}

    public setSpMode(mode: string): void {
        this.sp_mode = mode;
    }

    public getDisclosureGroup(prefix: string, page: string, type: string, data: any): Observable<DisclosureGroupVO> {
        const req = {
            type: type,
            data: data
        };
        return this.http.post<DisclosureGroupVO>('/dl/data/disclosureGroup/' + prefix + '/' + page + '/retrieve', req, httpOptions).pipe(
            catchError(this.handleError<DisclosureGroupVO>())
        );
    }

    public save(disclosures: DisclosureVO[]): Observable<SuccessVO> {
        return this.http.post<SuccessVO>(this.baseUrl + this.sp_mode + '/save', disclosures, httpOptions).pipe(
            catchError(this.handleError<SuccessVO>())
        );
    }

    public stop(prefix: string, page: string, type: string, disclosures: DisclosureVO[]): Observable<StopVO> {
        const req = {
            prefix: prefix,
            page: page,
            type: type,
            disclosures: disclosures
        };
        return this.http.post<StopVO>(this.baseUrl + this.sp_mode + '/stop', req, httpOptions).pipe(
            catchError(this.handleError<StopVO>())
        );
    }

    public handleError<T>() {
        return (error: any): Observable<T> => {
            const vo = ErrorVO.toErrorVO(error);
            this.errorModalProvider.showError(vo);
            return throwError(vo);
        };
    }
}
