import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
var AdditionalProductsService = /** @class */ (function () {
    function AdditionalProductsService(http) {
        this.http = http;
    }
    AdditionalProductsService.ngInjectableDef = i0.defineInjectable({ factory: function AdditionalProductsService_Factory() { return new AdditionalProductsService(i0.inject(i1.HttpClient)); }, token: AdditionalProductsService, providedIn: "root" });
    return AdditionalProductsService;
}());
export { AdditionalProductsService };
var AdditionalProductsUtil = /** @class */ (function () {
    function AdditionalProductsUtil() {
    }
    AdditionalProductsUtil.validation = {
        LLC_PPPH: 1,
        LLC_AF: 1,
    };
    return AdditionalProductsUtil;
}());
export { AdditionalProductsUtil };
// export class MLFSimplePricingRequestVO {
//     spId: string;
//     benefitId: string;
//     product: string;
//     age: number;
//     gender: string;
// }
var AdditionalProductVO = /** @class */ (function () {
    function AdditionalProductVO() {
    }
    return AdditionalProductVO;
}());
export { AdditionalProductVO };
