/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../base/accordion/accordion.ngfactory";
import * as i2 from "../../base/accordion/accordion";
import * as i3 from "../../base/accordion/shared.ngfactory";
import * as i4 from "../../base/accordion/shared";
import * as i5 from "@angular/common";
import * as i6 from "./product.component";
var styles_ProductComponent = [];
var RenderType_ProductComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProductComponent, data: {} });
export { RenderType_ProductComponent as RenderType_ProductComponent };
function View_ProductComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-check-circle right"]], null, null, null, null, null))], null, null); }
function View_ProductComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "col s4 dl-text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "col s8"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "label", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.amount; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.description; _ck(_v, 5, 0, currVal_1); }); }
function View_ProductComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "p-accordionTab", [], [[8, "className", 0], [1, "data-name", 0]], null, null, i1.View_AccordionTab_0, i1.RenderType_AccordionTab)), i0.ɵdid(1, 180224, [[1, 4]], 1, i2.AccordionTab, [i2.Accordion], { selected: [0, "selected"] }, null), i0.ɵqud(603979776, 2, { headerFacet: 1 }), (_l()(), i0.ɵeld(3, 0, null, 0, 5, "p-header", [], null, null, null, i3.View_HeaderComponent_0, i3.RenderType_HeaderComponent)), i0.ɵdid(4, 49152, [[2, 4]], 0, i4.HeaderComponent, [], null, null), (_l()(), i0.ɵeld(5, 0, null, 0, 3, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProductComponent_2)), i0.ɵdid(8, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(9, 0, null, 1, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, 1, 1, null, View_ProductComponent_3)), i0.ɵdid(11, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_2 = _v.context.$implicit.selected; _ck(_v, 1, 0, currVal_2); var currVal_4 = _v.context.$implicit.selected; _ck(_v, 8, 0, currVal_4); var currVal_6 = _v.context.$implicit.detail; _ck(_v, 11, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", _v.context.$implicit.class, ""); var currVal_1 = _v.context.$implicit.id; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.name; _ck(_v, 6, 0, currVal_3); var currVal_5 = _v.context.$implicit.desc; _ck(_v, 9, 0, currVal_5); }); }
export function View_ProductComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "p-accordion", [], null, [[null, "onOpen"], [null, "onClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onOpen" === en)) {
        var pd_0 = (_co.onTabEvent($event) !== false);
        ad = (pd_0 && ad);
    } if (("onClose" === en)) {
        var pd_1 = (_co.onTabEvent($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_Accordion_0, i1.RenderType_Accordion)), i0.ɵdid(1, 1228800, null, 1, i2.Accordion, [i0.ElementRef, i0.ChangeDetectorRef], { activeIndex: [0, "activeIndex"] }, { onClose: "onClose", onOpen: "onOpen" }), i0.ɵqud(603979776, 1, { tabList: 1 }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_ProductComponent_1)), i0.ɵdid(4, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.index; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.component.extraInput; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ProductComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "product-input", [], null, null, null, View_ProductComponent_0, RenderType_ProductComponent)), i0.ɵdid(1, 114688, null, 0, i6.ProductComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProductComponentNgFactory = i0.ɵccf("product-input", i6.ProductComponent, View_ProductComponent_Host_0, { flowId: "flowId", formGroup: "formGroup", component: "component", pageName: "pageName", _productCode: "_productCode", groupId: "groupId", _spMode: "_spMode" }, { change: "change", loaded: "loaded", validated: "validated", resume: "resume", altContinue: "altContinue" }, []);
export { ProductComponentNgFactory as ProductComponentNgFactory };
