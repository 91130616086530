import {Component} from '@angular/core';
import {Dynamic} from './component';
import {isNullOrUndefined} from 'util';
import {FormControl, Validators} from '@angular/forms';
import {NumberValidationVO, PageComponentVO} from '../page.data.vo';

@Component({
    selector: 'number-input',
    templateUrl: 'number.component.html'
})
export class NumberComponent extends Dynamic<PageComponentVO> {

    textControl: FormControl = new FormControl('');
    min: number = undefined;
    max: number = undefined;
    validationMessage: string = undefined;

    onChange(): void {
        this.component.value = this.textControl.value;
        this.component.isValid = this.textControl.valid;
        if (!isNullOrUndefined(this.change)) {
            this.change.emit(this.component.counter);
        }
    }

    getFormControl(): FormControl {
        if (isNullOrUndefined(this.component.required) || this.component.required) {
            this.textControl.setValidators([Validators.required]);
        }
        return this.textControl;
    }

    processExtraData() {
        const extraInput = this.component.extraInput as NumberValidationVO;
        const validators = [];
        if (isNullOrUndefined(this.component.required) || this.component.required) {
            validators.push(Validators.required);
        }
        if (!isNullOrUndefined(extraInput)) {
            if (!isNullOrUndefined(extraInput.min)) {
                this.min = extraInput.min;
                validators.push(Validators.min(this.min));
            }
            if (!isNullOrUndefined(extraInput.max)) {
                this.max = extraInput.max;
                validators.push(Validators.max(this.max));
            }
        }
        this.textControl.setValidators(validators);
        this.validationMessage = this.buildValidationMessage();
    }

    private buildValidationMessage(): string {
        if (this.min === undefined && this.max === undefined) {
            return this.component.name + ' is required';
        } else if (this.min === undefined) {
            return this.component.name + ' must be ' + this.max + ' or less';
        } else if (this.max === undefined) {
            return this.component.name + ' must be ' + this.min + ' or more';
        } else {
            return this.component.name + ' must be between ' + this.min + ' and ' + this.max;
        }
    }

    setInitialValue() {
        if (!isNullOrUndefined(this.getFormControl()) && !isNullOrUndefined(this.component.initValue) && this.component.initValue !== '') {
            this.getFormControl().setValue(parseInt(this.component.initValue, 10));
        }
    }

}
