import { isNullOrUndefined, isUndefined } from 'util';
import { Log } from 'ng2-logger/browser';
import * as i0 from "@angular/core";
var log = Log.create('PageData');
var PageRightBar = /** @class */ (function () {
    function PageRightBar() {
    }
    return PageRightBar;
}());
export { PageRightBar };
var PageDataVO = /** @class */ (function () {
    function PageDataVO() {
        this._secGroup = '';
        /** this will tell the app to behave as embedded, external or web */
        this._mode = 'web';
        this._titleSuffix = '';
        this._leadId = '';
        this._contactId = '';
        this._contactBlocked = false;
        this._policyId = '';
        this._crmUserId = '';
        this._ngoCode = '';
        this._entryDisclosures = [];
        this._loginPage = false;
        this._baseAuthUrl = '';
        this._oauthId = '';
        this._ssoLogin = '';
        log.info('Page Data constructed');
    }
    Object.defineProperty(PageDataVO.prototype, "secGroup", {
        get: function () {
            return this._secGroup;
        },
        set: function (value) {
            this._secGroup = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageDataVO.prototype, "salesProcessId", {
        get: function () {
            return this._salesProcessId;
        },
        set: function (value) {
            log.info('Page Data set SPID');
            if (!isUndefined(value)) {
                this._salesProcessId = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageDataVO.prototype, "mode", {
        get: function () {
            return this._mode;
        },
        set: function (value) {
            log.info('Page Data Set Mode');
            if (!isUndefined(value)) {
                this._mode = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageDataVO.prototype, "titleSuffix", {
        get: function () {
            return this._titleSuffix;
        },
        set: function (value) {
            this._titleSuffix = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageDataVO.prototype, "leadId", {
        get: function () {
            return this._leadId;
        },
        set: function (value) {
            log.info('Page Data set LeadID : ' + value);
            if (!isUndefined(value)) {
                this._leadId = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageDataVO.prototype, "contactId", {
        get: function () {
            return this._contactId;
        },
        set: function (value) {
            log.info('Page Data set contact ID : ' + value);
            if (!isUndefined(value)) {
                this._contactId = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageDataVO.prototype, "contactBlocked", {
        get: function () {
            return this._contactBlocked;
        },
        set: function (value) {
            if (!isUndefined(value)) {
                this._contactBlocked = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageDataVO.prototype, "policyId", {
        get: function () {
            return this._policyId;
        },
        set: function (value) {
            if (!isUndefined(value)) {
                this._policyId = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageDataVO.prototype, "crmUserId", {
        get: function () {
            return this._crmUserId;
        },
        set: function (value) {
            if (!isUndefined(value)) {
                this._crmUserId = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageDataVO.prototype, "ngoCode", {
        get: function () {
            return this._ngoCode;
        },
        set: function (value) {
            if (!isUndefined(value)) {
                this._ngoCode = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageDataVO.prototype, "payload", {
        get: function () {
            return this._payload;
        },
        set: function (value) {
            if (!isUndefined(value)) {
                this._payload = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageDataVO.prototype, "entryDisclosures", {
        get: function () {
            return this._entryDisclosures;
        },
        set: function (value) {
            this._entryDisclosures = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageDataVO.prototype, "rightbar", {
        get: function () {
            return this._rightbar;
        },
        set: function (value) {
            this._rightbar = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageDataVO.prototype, "gender", {
        get: function () {
            return this._gender;
        },
        set: function (value) {
            this._gender = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageDataVO.prototype, "loginPage", {
        get: function () {
            return this._loginPage;
        },
        set: function (value) {
            this._loginPage = value;
        },
        enumerable: true,
        configurable: true
    });
    PageDataVO.prototype.setLoginPage = function (value) {
        if (!isNullOrUndefined(value)) {
            if (value === true || value === 'true' || value === '1' || value === 1) {
                this._loginPage = true;
                return;
            }
        }
        this._loginPage = false;
    };
    Object.defineProperty(PageDataVO.prototype, "baseAuthUrl", {
        get: function () {
            return this._baseAuthUrl;
        },
        set: function (value) {
            this._baseAuthUrl = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageDataVO.prototype, "oauthId", {
        get: function () {
            return this._oauthId;
        },
        set: function (value) {
            this._oauthId = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageDataVO.prototype, "ssoLogin", {
        get: function () {
            return this._ssoLogin;
        },
        set: function (value) {
            this._ssoLogin = value;
        },
        enumerable: true,
        configurable: true
    });
    PageDataVO.prototype.toJsonVersion = function () {
        var jsonObj = {
            salesProcessId: this.salesProcessId,
            mode: this.mode,
            leadId: this.leadId,
            contactId: this.contactId,
            contactBlocked: this.contactBlocked,
            policyId: this.policyId,
            crmUserId: this.crmUserId,
            ngoCode: this.ngoCode,
            payload: this.payload,
        };
        return jsonObj;
    };
    return PageDataVO;
}());
export { PageDataVO };
var SpDataService = /** @class */ (function () {
    function SpDataService() {
        log.info('Constructed SpData Service');
        this.pageData = new PageDataVO();
    }
    /**
     * Get the current page state data
     * @returns {PageDataVO}
     */
    SpDataService.prototype.getPageData = function () {
        return this.pageData;
    };
    SpDataService.prototype.updateBaseData = function (baseData) {
        this.pageData.entryDisclosures = []; // always clear the entry disclosures
        for (var key in baseData) {
            var value = baseData[key];
            if (value != null) {
                this.pageData[key] = value;
            }
        }
    };
    SpDataService.prototype.setSalesProcessId = function (spId) {
        this.pageData.salesProcessId = spId;
    };
    SpDataService.prototype.setMode = function (mode) {
        this.pageData.mode = mode;
    };
    SpDataService.prototype.setLeadId = function (leadId) {
        this.pageData.leadId = leadId;
    };
    SpDataService.prototype.setContactId = function (contactId) {
        this.pageData.contactId = contactId;
    };
    SpDataService.prototype.setContactBlocked = function (contactBlocked) {
        this.pageData.contactBlocked = contactBlocked;
    };
    SpDataService.prototype.setPolicyId = function (policyId) {
        this.pageData.policyId = policyId;
    };
    SpDataService.prototype.getPolicyId = function () {
        return this.pageData.policyId;
    };
    SpDataService.prototype.setCrmUserId = function (crmUserId) {
        this.pageData.crmUserId = crmUserId;
    };
    SpDataService.prototype.setNgoCode = function (ngoCode) {
        this.pageData.ngoCode = ngoCode;
    };
    SpDataService.prototype.getSalesProcessID = function () {
        return this.pageData.salesProcessId;
    };
    SpDataService.prototype.getMode = function () {
        return this.pageData.mode;
    };
    SpDataService.prototype.isEmbedded = function () {
        console.log('isEmbedded: ' + this.pageData.mode);
        console.log('isEmbedded: ' + this.pageData.mode === 'embedded');
        if (!isNullOrUndefined(this.pageData.mode)) {
            return this.pageData.mode === 'embedded';
        }
        return false;
    };
    SpDataService.prototype.isExternal = function () {
        if (!isNullOrUndefined(this.pageData.mode)) {
            return this.pageData.mode === 'external';
        }
        return false;
    };
    SpDataService.prototype.getEntryDisclosures = function () {
        if (isNullOrUndefined(this.pageData.entryDisclosures)) {
            return [];
        }
        return this.pageData.entryDisclosures;
    };
    SpDataService.prototype.setEntryDisclosures = function (entryDisclosures) {
        this.pageData.entryDisclosures = entryDisclosures;
    };
    SpDataService.prototype.getTitleSuffix = function () {
        return this.pageData.titleSuffix;
    };
    Object.defineProperty(SpDataService.prototype, "loadingBusy", {
        get: function () {
            return this._loadingBusy;
        },
        set: function (value) {
            this._loadingBusy = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SpDataService.prototype, "continueBusy", {
        get: function () {
            return this._continueBusy;
        },
        set: function (value) {
            this._continueBusy = value;
        },
        enumerable: true,
        configurable: true
    });
    SpDataService.prototype.getGender = function () {
        return this.pageData.gender;
    };
    SpDataService.prototype.getSecurityGroup = function () {
        return this.pageData.secGroup;
    };
    SpDataService.prototype.getCaseType = function () {
        return this.caseTypeVal;
    };
    SpDataService.ngInjectableDef = i0.defineInjectable({ factory: function SpDataService_Factory() { return new SpDataService(); }, token: SpDataService, providedIn: "root" });
    return SpDataService;
}());
export { SpDataService };
