<dl-date-picker-input
    [name]="component.id"
    [placeHolder]="component.name"
    [formControlRef]="dateControl"
    [isDob]="dateValidationVO.dob"
    [minAge]="dateValidationVO.minAge"
    [maxAge]="dateValidationVO.maxAge"
    [startView]="dateValidationVO.pickerStartView"
    [required]="true"
    [format]="dateValidationVO.format"
></dl-date-picker-input>
