import {Dynamic, ParentEventService} from './component';
import {FormControl} from '@angular/forms';
import {Component, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import { PageComponentVO } from '../page.data.vo';

@Component({
    template: `
    <button
            mat-raised-button
            tabindex="1"
            class="dl-full-width dl-action-btn dl-alternate"
            [attr.data-name]="component.id"
            [attr.data-event]="'true'"
            [attr.data-event-category]="'DSP-' + productCode"
            [attr.data-event-name]="pageName + ' - Continue'"
            [attr.data-event-label]="'Continue to ' + component.value"
            (click)="click()" [disabled]="isDisabled || isBusy">
        {{component.name}}&nbsp;<i *ngIf="isBusy" class="fa fa-circle-o-notch fa-spin"></i>
    </button>
    `,
    selector: 'alt-continue-button',
})
export class AltContinueComponent extends Dynamic<PageComponentVO> implements OnDestroy {

    isDisabled = true;
    isBusy = false;
    private parentValidSubscription: Subscription;
    private parentBusySubscription: Subscription;

    constructor(private parentEventService: ParentEventService) {
        super();
        this.parentValidSubscription = parentEventService.getPageValidateObservable().subscribe(event => this.isDisabled = !event);
        this.parentBusySubscription = parentEventService.getPageBusyObservable().subscribe(event => {
            this.isDisabled = event;
            if (this.isBusy && !event) {
                this.isBusy = false;
            }
        });
    }

    setupFromControl() {
        this.component.loaded = true;
        // tel my parent I'm loaded.
        this.loaded.emit(this.component.id);
    }

    getFormControl(): FormControl {
        return null;
    }

    click(): void {
        this.isBusy = true;
        this.altContinue.emit(this.component.value as string);
    }

    ngOnDestroy() {
        this.parentValidSubscription.unsubscribe();
        this.parentBusySubscription.unsubscribe();
    }
}
