var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseService } from '../base/base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorModalProvider } from '../base/error/error.modal.service';
import { catchError, tap } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { Log } from 'ng2-logger/browser';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../base/error/error.modal.service";
var log = Log.create('dl-login-service');
var LoginService = /** @class */ (function (_super) {
    __extends(LoginService, _super);
    function LoginService(httpClient, errorModalProvider) {
        var _this = _super.call(this, httpClient, errorModalProvider) || this;
        _this.httpClient = httpClient;
        _this.errorModalProvider = errorModalProvider;
        return _this;
    }
    LoginService.prototype.setPassword = function (data) {
        var url = '/dl/data/setPassword';
        return this.httpClient.post(url, data, this.httpOptions()).pipe(catchError(this.handleHTTPError()));
    };
    LoginService.prototype.checkPhone = function (phone, leadId, salesProcessId) {
        var url = '/dl/data/checkLeadCell';
        var data = {
            sales_process_id: salesProcessId,
            lead_id: leadId,
            cell: phone
        };
        return this.httpClient.post(url, data, this.httpOptions()).pipe(catchError(this.handleHTTPError()));
    };
    LoginService.prototype.checkDob = function (dob, leadId, salesProcessId) {
        var url = '/dl/data/checkLeadDob';
        var data = {
            sales_process_id: salesProcessId,
            lead_id: leadId,
            dob: dob
        };
        return this.httpClient.post(url, data, this.httpOptions()).pipe(catchError(this.handleHTTPError()));
    };
    LoginService.prototype.dlLoginCheck = function (baseUrl, oauthId, ssoUserId) {
        var url = baseUrl + '/api/dfuser/is_logged_in/?client_id=' + oauthId;
        if (!isNullOrUndefined(ssoUserId) && ssoUserId !== '') {
            url += '&user_login=' + ssoUserId;
        }
        return this.httpClient.get(url, this.httpOptions(true)).pipe(catchError(this.handleHTTPError()));
    };
    LoginService.prototype.checkUser = function (userName, password, idNumber) {
        var data = {
            username: userName,
            password: password,
        };
        if (!isNullOrUndefined(idNumber)) {
            data.id_number = idNumber;
        }
        return this.httpClient.post('/dl/data/login', data, this.httpOptions()).pipe(catchError(this.handleHTTPError()));
    };
    LoginService.prototype.oauthCheck = function (data) {
        delete data.id;
        return this.httpClient.post('/dl/data/login', data, this.httpOptions()).pipe(catchError(this.handleHTTPError()));
    };
    LoginService.prototype.loginToOrg = function (orgBaseUrl, clientId, ssoUserName, passWord, signInProcessor) {
        var _this = this;
        var url = orgBaseUrl + '/sso.php';
        var data = {
            op: 'userpass',
            username: ssoUserName,
            password: passWord,
            client_id: clientId
        };
        return this.httpClient.post(url, data, this.httpOptions(true)).pipe(tap(function (orgRes) {
            _this.handleOrgLoginResult(orgRes, ssoUserName, signInProcessor, orgBaseUrl, clientId);
        }));
    };
    LoginService.prototype.logoutOrg = function (baseOrgUrl) {
        var url = baseOrgUrl + '/api/dfuser/logout/';
        return this.httpClient.get(url, this.httpOptions(true)).pipe(catchError(this.handleHTTPError()));
    };
    LoginService.prototype.logout = function (signInProcessor, orgUrl) {
        var _this = this;
        log.info('logout life');
        return this.httpClient.post('/dl/data/logout', null, this.httpOptions()).pipe(tap(function () {
            if (!isNullOrUndefined(orgUrl)) {
                // log out of org
                log.info('logout org');
                _this.logoutOrg(orgUrl).subscribe(function () {
                    if (!isNullOrUndefined(signInProcessor)) {
                        signInProcessor.processSignIn(false);
                    }
                });
            }
        }), catchError(this.handleHTTPError()));
    };
    LoginService.prototype.loginCheck = function (signInProcessor, userName, orgUrl, oauthClient) {
        if (!isNullOrUndefined(oauthClient) && orgUrl.length > 1) {
            this.doDlLoginCheck(orgUrl, oauthClient, userName, signInProcessor);
        }
        else {
            this.doSgLoginCheck(signInProcessor);
        }
    };
    /**
     * Check if a user is logged in all ready.
     * If so redirect to the correct page determined by the next action.
     */
    LoginService.prototype.doDlLoginCheck = function (orgUrl, oauthClientId, ssoUserName, signInProcessor) {
        var _this = this;
        this.dlLoginCheck(orgUrl, oauthClientId).subscribe(function (res) {
            if (!isNullOrUndefined(res.logged_in)) {
                if (_this.isTrue(res.logged_in)) {
                    // Logged in we need to do the oauth dance.
                    // this response should return sso_user_id
                    _this.processOAuthCode(true, res, ssoUserName, orgUrl, signInProcessor);
                    return;
                }
            }
            else {
                log.info('res.logged_in is null or undefined');
            }
            signInProcessor.processSignIn(false, 'Login check failed');
        }, function (error) {
            log.info('dlLoginCheck error');
            signInProcessor.processSignIn(false, 'Login check error');
        });
    };
    LoginService.prototype.doSgLoginCheck = function (signInProcessor) {
        signInProcessor.processSignIn(false);
    };
    LoginService.prototype.isTrue = function (data) {
        return ((typeof data === 'string' && data === 'true') ||
            (typeof data === 'boolean' && data === true));
    };
    LoginService.prototype.processOAuthCode = function (fromCheck, data, ssoUserName, orgUrl, signInProcessor) {
        var _this = this;
        if (fromCheck && this.isTrue(data.logged_in) && !isNullOrUndefined(ssoUserName)) {
            var mustLogout = false;
            if (ssoUserName.indexOf('@') > 1) {
                if (isNullOrUndefined(data.email) || ssoUserName !== data.email) {
                    log.info('login check differing users: ' + ssoUserName + ' != ' + data.email);
                    mustLogout = true;
                }
            }
            else {
                if (ssoUserName !== data.login) {
                    log.info('login check differing users: ' + ssoUserName + ' != ' + data.login);
                    mustLogout = true;
                }
            }
            if (mustLogout) {
                // need to do a Logout and process
                this.logout(signInProcessor, orgUrl).subscribe(function () { return log.info('auto logged out'); });
                return;
            }
        }
        if (!isNullOrUndefined(data.code)) {
            this.oauthCheck(data).subscribe(function (res) {
                if (!isNullOrUndefined(res.error_detail)
                    && !isNullOrUndefined(res.error_detail.error)
                    && res.error_detail.error === 'invalid_grant') {
                    signInProcessor.processSignIn(false, 'Login timeout, please try again');
                    return;
                }
                if (!isNullOrUndefined(res.differentuser) && res.differentuser) {
                    log.info('oauth check differing users: ' + ssoUserName + ' != ' + data.login);
                    _this.logout(signInProcessor, orgUrl).subscribe(function () { return log.info('auto logged out'); });
                    return;
                }
                signInProcessor.processSignIn(true);
            }, function () { return signInProcessor.processSignIn(false, 'Error communicating to the login server, please try again'); });
        }
    };
    LoginService.prototype.handleOrgLoginResult = function (res, ssoUserName, signInProcessor, orgUrl, oauthClientId) {
        if (isNullOrUndefined(res.message) && typeof res.Error !== 'undefined') {
            signInProcessor.processSignIn(false, 'Sorry there was an error on our server');
            return;
        }
        if (!isNullOrUndefined(res.code) && res.login) {
            res.logged_in = true;
            this.processOAuthCode(false, res, ssoUserName, orgUrl, signInProcessor);
        }
        else if (res.loggedin) {
            this.loginCheck(signInProcessor, ssoUserName, orgUrl, oauthClientId);
        }
        else {
            log.error('signinToOrg error (1)');
            log.error(JSON.stringify(res));
            var errorMsg = 'Failed to login, ';
            if (!isNullOrUndefined(res.message)) {
                errorMsg += res.message;
            }
            else {
                errorMsg += 'please check your details and try again';
            }
            signInProcessor.processSignIn(false, errorMsg);
        }
    };
    LoginService.prototype.getLoginActionForSP = function (spId) {
        var url = '/dl/data/sp/' + spId + '/web/login';
        return this.httpClient.get(url, this.httpOptions()).pipe(catchError(this.handleHTTPError()));
    };
    LoginService.ngInjectableDef = i0.defineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.inject(i1.HttpClient), i0.inject(i2.ErrorModalProvider)); }, token: LoginService, providedIn: "root" });
    return LoginService;
}(BaseService));
export { LoginService };
var ErrorVO = /** @class */ (function () {
    function ErrorVO() {
    }
    return ErrorVO;
}());
export { ErrorVO };
var LoginResponse = /** @class */ (function () {
    function LoginResponse() {
    }
    return LoginResponse;
}());
export { LoginResponse };
