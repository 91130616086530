import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ContactBlockService, ContactMeVO } from '../contact-block.service';
import { PhoneNumberValidator } from '../../base/phone-number';
import { DLUtil } from '../../base/dl.util';
import { isNullOrUndefined } from 'util';
var ContactBlockExpandedComponent = /** @class */ (function () {
    function ContactBlockExpandedComponent(fb, service) {
        this.fb = fb;
        this.service = service;
        this.success = false;
        this.busy = false;
        // tslint:disable-next-line:member-ordering
        this._campaignCode = '';
        // tslint:disable-next-line:member-ordering
        this._leadSourceDescription = 'Contact Block';
        // tslint:disable-next-line:member-ordering
        this._providerId = '';
        // tslint:disable-next-line:member-ordering
        this._referrer = '';
        // tslint:disable-next-line:member-ordering
        this._referrerCode = '';
        // tslint:disable-next-line:member-ordering
        this.rgbColour = 'rgba(255, 255, 255, 0.6)';
        // tslint:disable-next-line:member-ordering
        this._bgColour = '255,255,255';
        // tslint:disable-next-line:member-ordering
        this._opacity = 0.6;
        // tslint:disable-next-line:member-ordering
        this._heading1 = 'Need Assistance?';
        // tslint:disable-next-line:member-ordering
        this._heading2 = '';
        // tslint:disable-next-line:member-ordering
        this._bottomText1 = '';
        // tslint:disable-next-line:member-ordering
        this._bottomText2 = '';
        // tslint:disable-next-line:member-ordering
        this._buttonText = 'Please call me';
        // tslint:disable-next-line:member-ordering
        this._showEmail = false;
        // tslint:disable-next-line:member-ordering
        this._skipLead = false;
        this.initForm();
    }
    ContactBlockExpandedComponent.prototype.ngOnInit = function () {
    };
    Object.defineProperty(ContactBlockExpandedComponent.prototype, "campaignCode", {
        set: function (val) {
            this._campaignCode = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactBlockExpandedComponent.prototype, "leadSourceDescription", {
        set: function (val) {
            this._leadSourceDescription = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactBlockExpandedComponent.prototype, "providerId", {
        set: function (val) {
            this._providerId = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactBlockExpandedComponent.prototype, "referrer", {
        set: function (val) {
            this._referrer = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactBlockExpandedComponent.prototype, "referrerCode", {
        set: function (val) {
            this._referrerCode = val;
        },
        enumerable: true,
        configurable: true
    });
    ContactBlockExpandedComponent.prototype.setBgColour = function () {
        this.rgbColour = 'rgba(' + this._bgColour + ', ' + this._opacity + ')';
    };
    ContactBlockExpandedComponent.prototype.getBackGround = function () {
        return { 'background-color': this.rgbColour };
    };
    Object.defineProperty(ContactBlockExpandedComponent.prototype, "bgColour", {
        set: function (val) {
            this._bgColour = val;
            this.setBgColour();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactBlockExpandedComponent.prototype, "opacity", {
        set: function (val) {
            this._opacity = val;
            this.setBgColour();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactBlockExpandedComponent.prototype, "heading1", {
        get: function () {
            return this._heading1;
        },
        set: function (val) {
            this._heading1 = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactBlockExpandedComponent.prototype, "heading2", {
        get: function () {
            return this._heading2;
        },
        set: function (val) {
            this._heading2 = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactBlockExpandedComponent.prototype, "bottomText1", {
        get: function () {
            return this._bottomText1;
        },
        set: function (val) {
            this._bottomText1 = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactBlockExpandedComponent.prototype, "bottomText2", {
        get: function () {
            return this._bottomText2;
        },
        set: function (val) {
            this._bottomText2 = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactBlockExpandedComponent.prototype, "buttonText", {
        get: function () {
            return this._buttonText;
        },
        set: function (val) {
            this._buttonText = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactBlockExpandedComponent.prototype, "showEmail", {
        get: function () {
            return this._showEmail;
        },
        set: function (val) {
            this._showEmail = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactBlockExpandedComponent.prototype, "skipLead", {
        get: function () {
            return this._skipLead;
        },
        set: function (val) {
            this._skipLead = val;
        },
        enumerable: true,
        configurable: true
    });
    ContactBlockExpandedComponent.prototype.initForm = function () {
        this.cbForm = this.fb.group({
            name: ['', [Validators.required]],
            surname: ['', [Validators.required]],
            cell: ['', [Validators.required, PhoneNumberValidator.validator()]],
            alt_cell: ['', [PhoneNumberValidator.validator()]],
            email: ['', [Validators.required, Validators.email, Validators.pattern(DLUtil.emailPattern)]],
        });
    };
    ContactBlockExpandedComponent.prototype.saveLead = function () {
        var _this = this;
        this.busy = true;
        var data = new ContactMeVO();
        var nameParts = this.cbForm.get('name').value.split(' ');
        if (nameParts.length > 1) {
            data.firstname = nameParts[0];
            for (var i = 1; i < nameParts.length; i++) {
                if (isNullOrUndefined(data.lastname)) {
                    data.lastname = nameParts[i];
                }
                else {
                    data.lastname += ' ' + nameParts[i];
                }
            }
        }
        else {
            data.firstname = this.cbForm.get('name').value;
        }
        data.leadtype = 'mlf';
        data.lastname = this.cbForm.get('surname').value;
        data.phonemobile = this.cbForm.get('cell').value.replace(/\D+/g, '');
        if (this.cbForm.get('email').value !== '') {
            data.email = this.cbForm.get('email').value;
        }
        if (this.cbForm.get('alt_cell').value !== '') {
            data.alt_phone = this.cbForm.get('alt_cell').value;
        }
        data.campaign_code = this._campaignCode;
        if (this._leadSourceDescription !== '') {
            data.leadsourcedescription = this._leadSourceDescription;
        }
        data.referrer_code = this._referrerCode;
        data.referrer = this._referrer;
        data.provider_id = this._providerId;
        this.service.submitCallMeRequest(data).subscribe(function () { return _this.showSuccess(); }, function () { return _this.handleFail(); });
    };
    ContactBlockExpandedComponent.prototype.showSuccess = function () {
        this.success = true;
        this.busy = false;
    };
    ContactBlockExpandedComponent.prototype.handleFail = function () {
        this.success = false;
        this.busy = false;
    };
    return ContactBlockExpandedComponent;
}());
export { ContactBlockExpandedComponent };
