import {Benefit} from '../base/benefit';
import {Type} from '@angular/core';

export interface QqDynamicLoadInput {
  component: Type<QqDynamicComponent>;
  inputs: QqDynamicComponentInputs;
}

export interface QqDynamicComponentInputs {
  benefit: Benefit;
  cause: string;
}

export abstract class QqDynamicComponent {
  public abstract setBenefit(benefit: Benefit): void;
  public abstract setCauseText(cause: string): void;
}
