import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AbstractControl, FormControl, ValidatorFn, Validators} from '@angular/forms';
import {isNullOrUndefined} from 'util';
import {isAsciiLetter} from 'codelyzer/angular/styles/chars';
import {DatePipe} from '@angular/common';

@Component({
    selector: 'dl-date-input',
    templateUrl: 'date.component.html'
})
export class DateComponent {

    config = new DateComponentConfig();
    inputUpdated: EventEmitter<DateComponentConfig> = new EventEmitter();

    originalDate: Date;

    _textControl: FormControl = new FormControl('');
    @Input()
    set formControlRef(val: FormControl) {
        if (!isNullOrUndefined(val)) {
            this._textControl = val;
            if (!isNullOrUndefined(val.value) && val.value !== '') {
                // always assume that we receive the original in ISO format.
                this.originalDate = new Date(val.value);
                // empty out the original value until the control is ready for display
                val.setValue('');
            }
            this._textControl.valueChanges.subscribe(() => this.dateInputChanged());
        }
    }
    get formControlRef(): FormControl {
        return this._textControl;
    }

    _name = 'dobInput';
    @Input()
    set name(val: string) {
        this._name = val;
    }
    get name(): string {
        return this._name;
    }

    _placeHolder = 'Date of Birth';
    @Input()
    set placeholder(val: string) {
        this._placeHolder = val;
    }
    get placeholder(): string {
        return this._placeHolder;
    }

    @Input()
    set dateFormat(val: string) {
        this.config.dateFormat = val;
        this.inputUpdated.emit(this.config);
    }
    get dateFormat(): string {
        return this.config.dateFormat;
    }
    get dateFormatDisplay(): string {
        return this.config.dateFormat.toUpperCase();
    }

    @Input()
    set required(val: boolean) {
        this.config.required = val;
        this.inputUpdated.emit(this.config);
    }
    get required() {
        return this.config.required;
    }

    @Input()
    set minAge(val: number) {
        this.config.minAge = val;
        this.inputUpdated.emit(this.config);
    }
    get minAge(): number {
        return this.config.minAge;
    }

    @Input()
    set maxAge(val: number) {
        this.config.maxAge = val;
        this.inputUpdated.emit(this.config);
    }
    get maxAge(): number {
        return this.config.maxAge;
    }
    @Input()
    set tabIndex(val: string) {
        this.config.tabIndex = val;
    }
    get tabIndex(): string {
        return this.config.tabIndex;
    }

    get formatHintOrFormattedDate(): string {
        if (isNullOrUndefined(this.theDate)) {
            return this.dateFormatDisplay;
        } else {
            return this.theDate.toDateString();
        }
    }

    @Output()
    dateChange: EventEmitter<Date> = new EventEmitter();

    yearStart: number;
    monthStart: number;
    dayStart: number;
    dateMask: any;
    theDate: Date;

    static validDate(comp: DateComponent): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            const theDate = comp.stringToDate(control.value);
            if (theDate === null) {
                return {invalidDate: 'Invalid date'};
            } else {
                return null;
            }
        };
    }

    static ageBetween(min: number, max: number, comp: DateComponent): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            const theDate = comp.stringToDate(control.value);
            if (theDate === null) {
                return null;
            }
            const today = new Date();
            let age = today.getFullYear() - theDate.getFullYear();
            const m = today.getMonth() - theDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < theDate.getDate())) {
                age--;
            }
            if (age >= min && age <= max) {
                return null;
            }
            return {invalidAge: 'Age must be between ' + min + ' and ' + max};
        };
    }

    constructor(private datePipe: DatePipe) {
        this._textControl.valueChanges.subscribe(() => this.dateInputChanged());
        this.inputUpdated.subscribe(() => this.setupControl());
    }

    private setupControl(): void {
        if (isNullOrUndefined(this.dateFormat)) {
            this.dateFormat = 'yyyy-MM-dd';
        }
        this.yearStart = this.dateFormatDisplay.indexOf('Y');
        this.monthStart = this.dateFormatDisplay.indexOf('M');
        this.dayStart = this.dateFormatDisplay.indexOf('D');
        const validators = [DateComponent.validDate(this)];
        if (this.required) {
            validators.push(Validators.required);
        }
        if (!isNullOrUndefined(this.minAge) && !isNullOrUndefined(this.maxAge)) {
            validators.push(DateComponent.ageBetween(this.minAge, this.maxAge, this));
        }

        this.formControlRef.setValidators(validators);
        const mask = [];
        for (let i = 0; i < this.dateFormat.length; i++) {
            if (isAsciiLetter(this.dateFormat.charCodeAt(i))) {
                mask.push(/\d/);
            } else {
                mask.push(this.dateFormat[i]);
            }
        }
        this.dateMask = {
            mask: mask,
            keepCharPositions: true
        };
        if (!isNullOrUndefined(this.originalDate)) {
            // need to do it as part of a timeout.
            setTimeout(() => this.formControlRef.setValue(this.dateToString(this.originalDate)));
        }
    }

    dateInputChanged(): void {
        const inDateString = this.formControlRef.value;
        if (isNullOrUndefined(inDateString)) {
            return;
        }
        this.theDate = this.stringToDate(inDateString);
        this.dateChange.emit(this.theDate);
    }

    stringToDate(dateString: string): Date {
        if (isNullOrUndefined(dateString) || dateString.length !== this.dateFormat.length || dateString.indexOf('_') >= 0) {
            return null;
        }
        const yString = dateString.substring(this.yearStart, this.yearStart + 4);
        const mString = dateString.substring(this.monthStart, this.monthStart + 2);
        const dString = dateString.substring(this.dayStart, this.dayStart + 2);
        const yNumber = parseInt(yString, 10);
        const mNumber = parseInt(mString, 10) - 1;
        const dNumber = parseInt(dString, 10);
        const theDate = new Date(yNumber, mNumber, dNumber);
        if (isNaN(theDate.getTime())) {
            return null;
        }
        if (yNumber !== theDate.getFullYear() || mNumber !== theDate.getMonth() || dNumber !== theDate.getDate()) {
            // Prevent e.g. 31 Nov being created as 1 Dec
            return null;
        }
        return theDate;
    }

    dateToString(date: Date): string {
        return this.datePipe.transform(date, this.dateFormat);
    }

}

export class DateComponentConfig {
    dateFormat = 'yyyy-MM-dd';
    required = false;
    tabIndex = '1';
    minAge: number;
    maxAge: number;
}
