var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Dynamic } from './component';
import { DisclosureCategory } from 'src/app/base/disclosures/disclosures.vos';
import { DisclosuresComponent } from 'src/app/base/disclosures/disclosures.component';
import { DisclosuresService } from 'src/app/base/disclosures/disclosures.service';
var OkDisclosureComponent = /** @class */ (function (_super) {
    __extends(OkDisclosureComponent, _super); /* implements OnInit */
    function OkDisclosureComponent(disclosuresService) {
        var _this = _super.call(this) || this;
        _this.disclosuresService = disclosuresService;
        _this.saving = false;
        _this.category = DisclosureCategory.SALES_PROCESS;
        _this.disclosuresService.setSpMode(_this.spMode);
        return _this;
    }
    OkDisclosureComponent.prototype.saveDisclosures = function () {
        // const disc: string = this.page + this.type;
        var _this = this;
        this.saving = true;
        this.disclosuresService.setSpMode(this.spMode);
        this.disclosuresService.save(this.component.value).subscribe(function (res) {
            _this.handleSave(res.success);
        });
    };
    OkDisclosureComponent.prototype.handleSave = function (result) {
        if (result) {
            // We keep the button in a saving state if success
            // Really we should be checking if the parent is also still processing 
            // because of the altContinue emit action
            // but this should not make a difference
            // since we are navigated away once saving is done
            this.saving = true;
        }
        else {
            this.saving = false;
        }
        this.altContinue.emit();
    };
    Object.defineProperty(OkDisclosureComponent.prototype, "canSave", {
        get: function () {
            if (this.saving) {
                return false;
            }
            return this.disclosuresView.isValid();
        },
        enumerable: true,
        configurable: true
    });
    OkDisclosureComponent.prototype.setupFromControl = function () {
        this.component.loaded = true;
        // tel my parent I'm loaded.
        this.loaded.emit(this.component.id);
    };
    OkDisclosureComponent.prototype.getFormControl = function () {
        return null;
    };
    OkDisclosureComponent.prototype.onChange = function () {
        this.component.isValid = this.disclosuresView.isValid();
    };
    return OkDisclosureComponent;
}(Dynamic));
export { OkDisclosureComponent };
