var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { QQService } from './qq.service';
import { CloseEventData } from './qq.vo';
import { BenefitCalculator } from '../base/benefit.calculator';
import { FormBuilder } from '@angular/forms';
import { OrgResultBlockComponent } from './dl.org.result.block.component';
import { SGResultBlockComponent } from './sg.result.block.component';
import { WindowRef } from '../base/window.ref';
import { MatDialogRef } from '@angular/material';
import { MatchMediaService } from '../base/match.media.service';
import { QQResultBaseComponent } from './qq.result.base.component';
/**
 * The QQ result component.
 * This component is in charge of displaying the slider for a quick price
 */
var QQResultComponent = /** @class */ (function (_super) {
    __extends(QQResultComponent, _super);
    function QQResultComponent(qqService, winRef, fb, matchMediaService, dialogRef) {
        var _this = _super.call(this, qqService, winRef, fb) || this;
        _this.matchMediaService = matchMediaService;
        _this.dialogRef = dialogRef;
        _this.componentData = null;
        return _this;
    }
    QQResultComponent.prototype.calculateInitialValues = function (qqPricingRequestData) {
        this.qqPricingRequestData = qqPricingRequestData;
        this.benefit.escalationFactor = 1;
        this.benefit.coverEscalationFactor = 1;
        this.benefit.discountRate = 0;
        // set sum assured to 4% of income
        this.benefit.cover = -(((qqPricingRequestData.income_gross_monthly * 0.04)
            * this.benefit.denominatorFactor) + this.benefit.numeratorFactor)
            / this.benefit.multiplierFactor;
        // for DC and CI30
        if (this.benefit.code !== 'LC') {
            this.benefit.cover = -(((qqPricingRequestData.income_gross_monthly * 0.02)
                * this.benefit.denominatorFactor) + this.benefit.numeratorFactor)
                / this.benefit.multiplierFactor;
        }
        // round up to the nearest increment
        this.benefit.cover = Math.ceil(this.benefit.cover / this.benefit.coverIncrements) * this.benefit.coverIncrements;
        // for SP90
        if (this.benefit.code === 'SP90') {
            this.benefit.cover = this.benefit.maxCover;
        }
        // limit cover to within min/max range
        if (this.benefit.maxCover < this.benefit.cover) {
            this.benefit.cover = this.benefit.maxCover;
        }
        if (this.benefit.minCover > this.benefit.cover) {
            this.benefit.cover = this.benefit.minCover;
        }
        this.benefit.premium = BenefitCalculator.calc('primaryPremiumNoDiscount', this.benefit);
    };
    QQResultComponent.prototype.openResult = function (qqRequestData, idNumber, qqPricingResponse, product, qqContinue) {
        this.idNumber = idNumber;
        this.qqContinue = qqContinue;
        this.qqForm.reset();
        this.resShowWarning = false;
        this.benefit = qqPricingResponse;
        this.benefit.code = product.value;
        this.benefit.name = product.label;
        this.benefit.coverOrPremium = 'Cover';
        this.calculateInitialValues(qqRequestData);
        if (this.inputData.securityGroup === 'DL') {
            this.componentData = {
                component: OrgResultBlockComponent,
                inputs: {
                    benefit: this.benefit,
                    cause: this.inputData.causeText
                }
            };
        }
        else {
            this.componentData = {
                component: SGResultBlockComponent,
                inputs: {
                    benefit: this.benefit,
                    cause: ''
                }
            };
        }
    };
    QQResultComponent.prototype.closeResultModal = function (event) {
        this.resetButton();
        this.dialogRef.close(event);
    };
    QQResultComponent.prototype.handleQQResponse = function (response) {
        if (response.success === false || response.success !== 'true') {
            this.resShowWarning = true;
            this.resetButton();
            return;
        }
        // lead capture close
        if (this.inputData.leadCapture) {
            this.dialogRef.close(CloseEventData.createCancelEvent('A Lead was successfully created'));
            return;
        }
        // if mobile show warning
        if (this.matchMediaService.is(['sm', 'xs']) && !this.inputData.embedded) {
            this.dialogRef.close(CloseEventData.createMobileWarningEvent(response));
            return;
        }
        // We are now moving to the SP:
        this.qqContinue.continueToPersonal(response);
    };
    return QQResultComponent;
}(QQResultBaseComponent));
export { QQResultComponent };
