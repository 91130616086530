import {Injectable} from '@angular/core';
import {ErrorModalProvider} from '../base/error/error.modal.service';
import {ErrorVO} from '../base/error/error.vo';
import {PageDataVO, SpDataService} from './sp.data.service';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of, throwError} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';
import {SpDisclosuresProvider} from './sp.disclosures.provider.service';
import {BaseService} from '../base/base.service';
import {Log} from 'ng2-logger/browser';

const log = Log.create('sp-service');

export abstract class SpBaseService extends BaseService {

    constructor(
        protected httpClient: HttpClient,
        protected spDataService: SpDataService,
        protected errorModalProvider: ErrorModalProvider,
        private disclosuresProvider: SpDisclosuresProvider
    ) {
        super(httpClient, errorModalProvider);
    }

    public handleHTTPError<T>(heading?: string, result?: T) {
        return (error: any): Observable<T> => {
            let vo = ErrorVO.toErrorVO(error);
            if (result == null) {
                this.errorModalProvider.showError(vo, heading);
                this.spDataService.continueBusy = false;
                this.disclosuresProvider.stopSavingSpinner();
                return throwError(vo);
            }
            return of(result as T);
        };
    }

    public sendNavigation(step: string, code: string): void {
        const url = '/dl/data/sp/' + this.spDataService.getSalesProcessID() + '/saveNavigation';
        this.httpClient.post(url, {'step': step, 'code': code}, this.httpOptions()).subscribe();
    }

}

@Injectable({
    providedIn: 'root'
})
export class SalesProcessService extends SpBaseService {

    constructor(
        httpClient: HttpClient,
        spDataService: SpDataService,
        errorModalProvider: ErrorModalProvider,
        disclosuresProvider: SpDisclosuresProvider
    ) {
        super(httpClient, spDataService, errorModalProvider, disclosuresProvider);
    }

    public load(step: string, expired?: boolean): Observable<PageDataVO> {
        if (this.spDataService.getSalesProcessID() == null) {
            log.warn('no sp id');
            return of(null);
        }
        let url = '/dl/data/sp/' + this.spDataService.getSalesProcessID() + '/' + this.spDataService.getMode() + '/' + step;
        if (expired) {
            url += '?expired=true';
        }
        log.info('sp load data url: ' + url);
        return this.httpClient.get<PageDataVO>(url).pipe(
            tap(pageData => {
                this.spDataService.updateBaseData(pageData);
            }),
            catchError(this.handleHTTPError<PageDataVO>())
        );
    }

}

@Injectable({
    providedIn: 'root'
})
export class SalesProcessResolver implements Resolve<PageDataVO> {

    constructor(private salesProcessService: SalesProcessService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PageDataVO> {
        if (route.queryParams != null && route.queryParams.expired === 'true') {
            return this.salesProcessService.load(route.data.step, true);
        }
        return this.salesProcessService.load(route.data.step);
    }

}
