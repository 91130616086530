<div [formGroup]="bmi" [attr.data-bmi]="bmiValue">
    <div class="row" *ngIf="component.name">
        <div class="col s12 text-center">
            {{component.name}}
        </div>
    </div>
    <div class="row">
        <div class="col s12 m6">
            <div class="dl-dsp-radio-group">
                <div class="dl-dsp-question" >
                    <span>Height</span>&nbsp;&nbsp;<i class="fa fa-info-circle" aria-hidden="true" matTooltip="Measure height without shoes" #height="matTooltip" (click)="height.toggle()"></i>
                </div>
                <mat-radio-group class="dsp-radio-group dl-full-width" [formControl]="bmi.controls['heightSelector']">
                    <mat-radio-button
                        class="dsp-radio-button col s8"
                        color="primary"
                        [tabIndex]="1"
                        [value]="'m'"
                        [attr.data-val]="'m'">
                        [attr.data-name]="'metricM'"
                        <span>metres</span>
                    </mat-radio-button>
                    <mat-radio-button
                        class="dsp-radio-button col s4"
                        color="primary"
                        [tabIndex]="1"
                        [value]="'feet'"
                        [attr.data-val]="'feet'">
                        [attr.data-name]="'metricF'"
                        <span>feet&nbsp;&nbsp;</span>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="row" *ngIf="showM">
                <div class="col s12" >
                    <mat-form-field class="dl-full-width">
                        <input
                            tabindex="1"
                            matInput
                            [name]="'heightM'"
                            placeholder="height"
                            type="text"
                            inputmode="numeric"
                            [textMask]="getMaskFor('height')"
                            [formControl]="bmi.controls['heightM']"
                            [attr.data-name]="'heightM'"
                        >
                        <span matSuffix>m.</span>
                        <mat-hint *ngIf="heightInDisp">{{heightInDisp}}</mat-hint>
                        <mat-error *ngIf="bmi.controls['heightM'].getError('invalidHeight')" align="end">{{ bmi.controls['heightM'].getError('invalidHeight') }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row" *ngIf="!showM">
                <div class="col s6">
                    <mat-form-field class="dl-full-width">
                        <mat-label>height</mat-label>
                        <input
                            matInput
                            tabindex="1"
                            [name]="'heighFt'"
                            placeholder="feet"
                            type="numer"
                            inputmode="numeric"
                            [formControl]="bmi.controls['heightFt']"
                            [attr.data-name]="'heightFt'"
                        >
                        <span matSuffix>ft.</span>
                        <mat-error *ngIf="bmi.controls['heightFt'].getError('pattern')" align="end">Whole numbers only</mat-error>
                        <mat-hint *ngIf="heightM !== 0">{{heightM}}m</mat-hint>
                    </mat-form-field>
                </div>
                <div class="col s6">
                    <mat-form-field class="dl-full-width">
                        <mat-label></mat-label>
                        <input
                            tabindex="1"
                            matInput
                            [name]="'heightIn'"
                            placeholder="inches"
                            type="numer"
                            inputmode="numeric"
                            [formControl]="bmi.controls['heightIn']"
                            [attr.data-name]="'heightIn'"
                        >
                        <span matSuffix>in.</span>
                        <mat-error *ngIf="bmi.controls['heightIn'].getError('pattern')" align="end">0-11 allowed</mat-error>
                        <mat-error *ngIf="bmi.controls['heightIn'].getError('invalidHeight')">{{ bmi.controls['heightIn'].getError('invalidHeight') }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="col s12 m6">
            <div class="dl-dsp-radio-group">
                <div class="dl-dsp-question" >
                    <span>Weight</span>&nbsp;&nbsp;<i class="fa fa-info-circle" aria-hidden="true" matTooltip="Weigh before pregnancy. Use your normal weight, not the one the morning after you stopped eating for a day" #weight="matTooltip" (click)="weight.toggle()"></i>
                </div>
                <mat-radio-group class="dsp-radio-group dl-full-width" [formControl]="bmi.controls['weightSelector']">
                    <mat-radio-button
                        class="dsp-radio-button col s8"
                        color="primary"
                        [tabIndex]="1"
                        [value]="'kg'"
                        [attr.data-val]="'kg'">
                        [attr.data-name]="'measurementKg'"
                        <span>kilograms</span>
                    </mat-radio-button>
                    <mat-radio-button
                        class="dsp-radio-button col s4"
                        color="primary"
                        [tabIndex]="1"
                        [value]="'stone'"
                        [attr.data-val]="'stone'">
                        [attr.data-name]="'measurementSt'"
                        <span>stone</span>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="row" *ngIf="showKG">
                <div class="col s12">
                    <mat-form-field class="dl-full-width">
                        <input
                            tabindex="1"
                            matInput
                            [name]="'weightKg'"
                            placeholder="weight"
                            type="number"
                            inputmode="numeric"
                            [formControl]="bmi.controls['weightKg']"
                            [attr.data-name]="'weightKg'"
                        >
                        <span matSuffix>kg.</span>
                        <mat-hint *ngIf="weightLbDisp">{{weightLbDisp}}</mat-hint>
                        <mat-error *ngIf="bmi.controls['weightKg'].getError('invalidWeight')" align="end">{{ bmi.controls['weightKg'].getError('invalidWeight') }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row" *ngIf="!showKG">
                <div class="col s6">
                    <mat-form-field class="dl-full-width">
                        <mat-label>weight</mat-label>
                        <input
                            tabindex="1"
                            matInput
                            [name]="'weightSt'"
                            placeholder="stone"
                            type="number"
                            inputmode="numeric"
                            [formControl]="bmi.controls['weightSt']"
                            [attr.data-name]="'weightSt'"
                        >
                        <span matSuffix>st.</span>
                        <mat-hint *ngIf="weightKg !== 0">{{weightKg}}kg</mat-hint>
                    </mat-form-field>
                </div>
                <div class="col s6">
                    <mat-form-field class="dl-full-width">
                        <mat-label></mat-label>
                        <input
                            tabindex="1"
                            matInput
                            [name]="'weightLb'"
                            placeholder="pounds"
                            type="number"
                            inputmode="numeric"
                            [formControl]="bmi.controls['weightLb']"
                            [attr.data-name]="'weightLb'"
                        >
                        <span matSuffix>lb.</span>
                        <mat-error *ngIf="bmi.controls['weightLb'].getError('invalidWeight')">{{ bmi.controls['weightLb'].getError('invalidWeight') }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
</div>
