import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
/**
 * Lazy load the stripe js.
 */
var StripeScriptService = /** @class */ (function () {
    function StripeScriptService() {
        this.scriptSrc = 'https://js.stripe.com/v3/';
        this.submitForTokenSubscription = new Subject();
    }
    StripeScriptService.prototype.injectStipeElement = function () {
        var _this = this;
        if (window['Stripe']) {
            return Promise.resolve(window['Stripe']);
        }
        return new Promise(function (res, rej) {
            var elm = _this.getTargetScriptElement();
            var script = document.createElement('script');
            script.setAttribute('src', _this.scriptSrc);
            script.setAttribute('type', 'text/javascript');
            script.addEventListener('load', function () {
                _this.stripe = window['Stripe'];
                res(window['Stripe']);
            });
            elm.appendChild(script);
        });
    };
    StripeScriptService.prototype.getTargetScriptElement = function () {
        var elm = document.getElementsByTagName('head');
        if (elm.length) {
            return elm[0];
        }
        return document.getElementsByTagName('body')[0];
    };
    StripeScriptService.prototype.getSubmitTokenObservable = function () {
        return this.submitForTokenSubscription.asObservable();
    };
    StripeScriptService.prototype.submitCard = function () {
        this.submitForTokenSubscription.next(true);
    };
    StripeScriptService.ngInjectableDef = i0.defineInjectable({ factory: function StripeScriptService_Factory() { return new StripeScriptService(); }, token: StripeScriptService, providedIn: "root" });
    return StripeScriptService;
}());
export { StripeScriptService };
