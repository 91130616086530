<div class="dl-dsp-radio-group">
    <div class="dl-dsp-question" [attr.data-name]="component.name">
        <span *ngIf="component.valueExtra" [innerHTML]="component.valueExtra"></span>&nbsp;&nbsp;<i
            *ngIf="component.info" class="fa fa-info-circle" aria-hidden="true" matTooltip="{{component.info}}"
            #me="matTooltip" (click)="me.toggle()"></i>
    </div>
    <mat-radio-group class="dsp-radio-group" [formControl]="radioGroupControl"
        [name]="component.name.toLowerCase().replace(' ', '-')"
        [attr.kings-watch]="flowId + '-' + pageName + '-' + component.id">
        <mat-radio-button class="dsp-radio-button" color="primary" *ngFor="let item of component.extraInput"
            id="{{component.name.toLowerCase().replace(' ', '-') + '-' + item.id}}" [tabIndex]="1" [value]="item.id"
            [attr.data-val]="item.id" [attr.data-name]="component.name.toLowerCase().replace(' ', '-') + '-' + item.id">
            <span [innerHTML]="item.display"></span>&nbsp;&nbsp;<i *ngIf="item.info" class="fa fa-info-circle"
                aria-hidden="true" matTooltip="{{item.info}}" #me="matTooltip" (click)="me.toggle()"></i>
        </mat-radio-button>
    </mat-radio-group>
</div>
