import {Component} from '@angular/core';
import {Dynamic} from './component';
import {isNullOrUndefined} from 'util';
import {FormControl, Validators} from '@angular/forms';
import { PageComponentVO } from '../page.data.vo';

@Component({
    selector: 'text-input',
    templateUrl: 'text.component.html'
})
export class TextComponent extends Dynamic<PageComponentVO> {

    textControl: FormControl = new FormControl('');

    onChange(): void {
        this.component.value = this.textControl.value;
        this.component.isValid = this.textControl.valid;
        if (!isNullOrUndefined(this.change)) {
            this.change.emit(this.component.counter);
        }
    }

    getFormControl(): FormControl {
        if (isNullOrUndefined(this.component.required) || this.component.required) {
            this.textControl.setValidators([Validators.required]);
        }
        return this.textControl;
    }
}

