<mat-form-field class="dl-full-width" [attr.data-locale]="locale">
    <input
        matInput
        tabindex="1"
        [matDatepicker]="datePickerPopUp"
        placeholder="{{placeHolder}}"
        name="{{name}}"
        [formControl]="formControlRef"
        inputmode="numeric"
    />
    <mat-datepicker-toggle matSuffix="true" [for]="datePickerPopUp"></mat-datepicker-toggle>
    <mat-datepicker #datePickerPopUp [touchUi]="isXs()" [startView]="startView"  [startAt]="startDate"></mat-datepicker>
    <mat-hint *ngIf="formControlRef.value">{{displayDate}}</mat-hint>
    <mat-hint *ngIf="format && !formControlRef.value">{{format}}</mat-hint>
    <mat-error *ngIf="formControlRef.errors && formControlRef.errors['invalid']">Invalid Date ({{format}})</mat-error>
    <mat-error *ngIf="formControlRef.errors && formControlRef.errors['invalidAge']">{{formControlRef.errors['invalidAge']}}</mat-error>
</mat-form-field>
