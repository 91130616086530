var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Dynamic } from './component';
import { FormControl, Validators } from '@angular/forms';
import { isNullOrUndefined } from 'util';
var UkPostcodeComponent = /** @class */ (function (_super) {
    __extends(UkPostcodeComponent, _super);
    function UkPostcodeComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.ukPostcode = new FormControl('');
        _this.ukPostalCodeRegex = '^([Gg][Ii][Rr] ?0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z]{2}[0-9]{1,2})' +
            '|(([A-Za-z][0-9][A-Za-z])|([A-Za-z]{2}[0-9]?[A-Za-z])))) ?[0-9][A-Za-z]{2})$';
        return _this;
    }
    UkPostcodeComponent.prototype.onChange = function () {
        this.component.value = this.ukPostcode.value;
        this.component.isValid = this.ukPostcode.valid;
        if (!isNullOrUndefined(this.change)) {
            this.change.emit(this.component.counter);
        }
    };
    UkPostcodeComponent.prototype.getFormControl = function () {
        var validators = [];
        // if the data is not coming from the server this cause a nasty error
        if (isNullOrUndefined(this.component.required) || this.component.required) {
            validators = [Validators.required, Validators.pattern(this.ukPostalCodeRegex)];
        }
        else {
            validators = [Validators.pattern(this.ukPostalCodeRegex)];
        }
        this.ukPostcode.setValidators(validators);
        return this.ukPostcode;
    };
    UkPostcodeComponent.prototype.getErrorMessage = function () {
        return this.ukPostcode.hasError('required') ? 'You must enter a value' :
            this.ukPostcode.hasError('pattern') ? 'Invalid Code' :
                '';
    };
    return UkPostcodeComponent;
}(Dynamic));
export { UkPostcodeComponent };
