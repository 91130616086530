import {Component} from '@angular/core';
import {InfoCardComponent} from './info-card.component';
import {AjaxVO} from '../page.data.vo';
import {AjaxService} from './ajax.service';
import {ParentEventService} from './component';
import {MLFBaseSummaryComponent} from '../../base/mlf-summary/mlf-base-summary.component';
import {DspService} from '../dsp.service';
import {MLFDependantVO} from '../../base/mlf-summary/mlf-summary.service';
import {DLUtil} from '../../base/dl.util';
import {DropDownValuesVO} from '../../base/select.vo';

@Component({
    templateUrl: 'mlf-summary.component.html',
    selector: 'mlf-summary',
    styleUrls: ['./info-card.component.scss']
})
export class MLFSummaryComponent extends InfoCardComponent {

    constructor(private ajaxService: AjaxService,
                private dspService: DspService,
                parentEventService: ParentEventService) {
        super(parentEventService);
    }

    get dependants(): MLFDependantVO[] {
        return this.component.valueExtra as MLFDependantVO[];
    }

    set dependants(val: MLFDependantVO[]) {
        this.component.valueExtra = val;
    }

    get products(): DropDownValuesVO[] {
        return this.component.extraInput as DropDownValuesVO[];
    }

    get spId(): string {
        return this.dspService.currentData.spId;
    }

    get benefitId(): string {
        return this.dspService.currentData.benefitId;
    }

    get leadName(): string {
        return this.dspService.currentData.leadName;
    }

    add(dependant: MLFDependantVO): void {
        this.sendAjaxUpdateRequest(dependant, 'updateDependantBenefit');
    }

    edit(main: boolean, dependant: MLFDependantVO): void {
        let method = 'updateDependantBenefit';
        if (main) {
            method = 'updateMainBenefit';
        }
        this.sendAjaxUpdateRequest(dependant, method);
    }

    private sendAjaxUpdateRequest(dependant: MLFDependantVO, method: string) {
        this.isBusy = true;
        this.parentEventService.publishPageBusyStatus(this.isBusy);
        const req: AjaxVO = {
            method: method,
            data: {
                dependant: dependant
            }
        };
        this.ajaxService.postRequest(req).subscribe((res) => {
            let updated = false;
            this.dependants.forEach((d) => {
                if (d.id === res.id) {
                    updated = true;
                    DLUtil.copyFields(res, d);
                }
            });
            if (!updated) {
                this.dependants.push(res);
            }
            this.isBusy = false;
            this.parentEventService.publishPageBusyStatus(this.isBusy);
        }, () => {
            this.isBusy = false;
            this.parentEventService.publishPageBusyStatus(this.isBusy);
        });
    }

    remove(dependant: MLFDependantVO) {
        this.isBusy = true;
        this.parentEventService.publishPageBusyStatus(this.isBusy);
        const req: AjaxVO = {
            method: 'removeDependantBenefit',
            data: {
                id: dependant.id
            }
        };
        this.ajaxService.postRequest(req).subscribe((res) => {
            this.dependants = MLFBaseSummaryComponent.removeDependant(this.dependants, res.id);
            this.isBusy = false;
            this.parentEventService.publishPageBusyStatus(this.isBusy);
        });
    }

}
