/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i2 from "@angular/material/button";
import * as i3 from "@angular/cdk/platform";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "./link.button.component";
import * as i7 from "../../base/window.ref";
var styles_LinkButtonComponent = [];
var RenderType_LinkButtonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LinkButtonComponent, data: {} });
export { RenderType_LinkButtonComponent as RenderType_LinkButtonComponent };
export function View_LinkButtonComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "a", [["class", "mat-primary dl-full-width dl-action-btn"], ["mat-raised-button", ""], ["tabindex", "1"]], [[8, "href", 4], [1, "tabindex", 0], [1, "disabled", 0], [1, "aria-disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1)._haltDisabledEvents($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.click() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_MatAnchor_0, i1.RenderType_MatAnchor)), i0.ɵdid(1, 180224, null, 0, i2.MatAnchor, [i3.Platform, i4.FocusMonitor, i0.ElementRef, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵted(2, 0, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.component.value, ""); var currVal_1 = (i0.ɵnov(_v, 1).disabled ? (0 - 1) : (i0.ɵnov(_v, 1).tabIndex || 0)); var currVal_2 = (i0.ɵnov(_v, 1).disabled || null); var currVal_3 = i0.ɵnov(_v, 1).disabled.toString(); var currVal_4 = (i0.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.component.name; _ck(_v, 2, 0, currVal_5); }); }
export function View_LinkButtonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "link-button", [], null, null, null, View_LinkButtonComponent_0, RenderType_LinkButtonComponent)), i0.ɵdid(1, 49152, null, 0, i6.LinkButtonComponent, [i7.WindowRef], null, null)], null, null); }
var LinkButtonComponentNgFactory = i0.ɵccf("link-button", i6.LinkButtonComponent, View_LinkButtonComponent_Host_0, { flowId: "flowId", formGroup: "formGroup", component: "component", pageName: "pageName", _productCode: "_productCode", groupId: "groupId", _spMode: "_spMode" }, { change: "change", loaded: "loaded", validated: "validated", resume: "resume", altContinue: "altContinue" }, []);
export { LinkButtonComponentNgFactory as LinkButtonComponentNgFactory };
