import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class AdditionalProductsService {

    constructor(private http: HttpClient) {}

    // public getPricing(request: MLFSimplePricingRequestVO): Observable<CoverOptionsVO> {
    //     return this.http.post<CoverOptionsVO>('/dl/data/pricing/simple-for-sp', request, httpOptions); // Won't need this for the two addons
    // }

}

export class AdditionalProductsUtil {
    public static validation = {
        LLC_PPPH: 1,
        LLC_AF: 1,
    };
}

// export class MLFSimplePricingRequestVO {
//     spId: string;
//     benefitId: string;
//     product: string;
//     age: number;
//     gender: string;
// }


export class AdditionalProductVO {
    id: string;
    productId: string;
    code: string;
    name: string;
    description: string;
    cover: number;
    premium: number;
    deleting: boolean;
    disabled: boolean;
    selected: boolean;
    pricing: any;
}
