
import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Dynamic} from './component';
import {FormControl} from '@angular/forms';
import { PageComponentVO } from '../page.data.vo';
import { DisclosureVO, DisclosureCategory } from 'src/app/base/disclosures/disclosures.vos';
import { DisclosuresComponent } from 'src/app/base/disclosures/disclosures.component';
import { DisclosuresService } from 'src/app/base/disclosures/disclosures.service';
import { PageComponent } from '../loader/page.component';

export interface DisclosurePageComponentVO extends PageComponentVO {
    value: DisclosureVO[];
}

@Component({
    selector: 'ok-disclosure-view',
    templateUrl: 'ok-disclosure.component.html'
})
export class OkDisclosureComponent extends Dynamic<DisclosurePageComponentVO> /* implements OnInit */ {

    saving = false;
    category = DisclosureCategory.SALES_PROCESS;
    
    @ViewChild('disclosuresView')
    private disclosuresView: DisclosuresComponent;

    constructor(private disclosuresService: DisclosuresService) {
        super();
        this.disclosuresService.setSpMode(this.spMode);
    }


    saveDisclosures(): void {
        // const disc: string = this.page + this.type;

        this.saving = true;
        this.disclosuresService.setSpMode(this.spMode);

        this.disclosuresService.save(this.component.value).subscribe(res => {
            this.handleSave(res.success);
        });
    }

    private handleSave(result: boolean): void {
        if (result) {
            // We keep the button in a saving state if success
            // Really we should be checking if the parent is also still processing 
            // because of the altContinue emit action
            // but this should not make a difference
            // since we are navigated away once saving is done
            this.saving = true;
        } else {
            this.saving = false;
        }
        this.altContinue.emit();
    }

    get canSave(): boolean {
        if (this.saving) {
            return false;
        }
        return this.disclosuresView.isValid();
    }

    setupFromControl() {
        this.component.loaded = true;
        // tel my parent I'm loaded.
        this.loaded.emit(this.component.id);
    }

    getFormControl(): FormControl {
        return null;
    }

    onChange(): void {
        this.component.isValid = this.disclosuresView.isValid();
    }
}
