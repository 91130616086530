<div class="dl-modal" id="calcResultsModal">
    <div mat-dialog-title class="dl-modal-header">
        <h4 class="dl-modal-title">Results and Assumptions
            <a class="close dl-pull-right right" tabindex="-1" (click)="closeResultModal()">
                &times;<span class="sr-only">Close</span>
            </a>
        </h4>
    </div>
    <mat-dialog-content>
        <mat-tab-group [selectedIndex]="activeTab" (selectedTabChange)="tabChanged($event)">
            <mat-tab [tabIndex]="5">
                <ng-template mat-tab-label>
                    Results<i *ngIf="updating" class="fa fa-circle-o-notch fa-spin" style="margin-left: 5px" [attr.data-name]="'results-updating'"></i>
                </ng-template>
                <div class="dl-panel">
                    <div class="dl-panel-heading">
                        <div class="dl-row row">
                            <div class="dl-col-sm-12 col s12">
                                <h4>
                                    Insurance Summary
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div class="dl-panel-body">
                        <p>* Please note: a number of <a [tabIndex]="5" (click)="showAssumptions()" style="cursor: pointer">assumptions</a> have been used to assess your situation.
                            <a [tabIndex]="5" style="font-style: italic; cursor: pointer;" (click)="showAssumptions()">Adjust them here</a>
                        </p>
                        <div class="dl-row row">
                            <div class="dl-col-sm-6 col m6 s12 dl-hidden-xs hide-on-small-and-down">
                                <pie-graph [data]="needsService.needsResponse.needsBenefits" ></pie-graph>
                            </div>
                            <div class="dl-col-sm-6 col m6 s12">
                                <table class="dl-table" [attr.data-name]="'product-table'">
                                    <thead>
                                        <tr>
                                            <th class="dl-hidden-xs hide-on-small-and-down">&nbsp;</th>
                                            <th>Product</th>
                                            <th>Cover</th>
                                            <th class="text-right">Premium</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let benefit of needsService.needsResponse.needsBenefits">
                                            <td scope="row" class="dl-hidden-xs hide-on-small-and-down">
                                                <div [ngStyle]="rowStyle(benefit.product_code)"
                                                     style="width: 10px; height: 10px; margin: 6px 3px;"></div>
                                            </td>
                                            <td>{{benefit.description}}</td>
                                            <td class="text-right">{{benefit.benefit_amount | dl_currency: true: 0}}</td>
                                            <td class="text-right">{{benefit.premium_amount | dl_currency: true: 0}}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td class="dl-hidden-xs hide-on-small-and-down">&nbsp;</td>
                                            <td>Total</td>
                                            <td></td>
                                            <td class="text-right" >{{needsService.needsResponse.total | dl_currency: true: 0}}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                                <div *ngIf="needsService.needsResponse.noteAsterisk">** cover amount has been adjusted due to the product minimum or maximum allowed</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dl-panel">
                    <div class="dl-panel-heading">
                        <div class="dl-row row">
                            <div class="dl-col-sm-12 col s12">
                                <div [ngStyle]="rowStyle('LC')" style="width: 10px; height: 10px; margin: 1px 6px; display: inline-block;"></div>
                                <h4 style="display: inline-block;">Life Cover:&nbsp;</h4>
                                <p style="display: inline-block;">Lump-sum paid out in the event of your death:</p>
                                <span
                                    matTooltip="As far as insurance goes, life cover is pretty straight forward: if you die for any reason, the cover amount (or sum assured) is paid out to whoever you choose (your beneficiaries). With life cover in place, you’ll know that your family and financial commitments will be taken care of when you’re gone. That’s the {{ needsService.ptyName }} peace of mind."
                                    [matTooltipPosition]="'below'"
                                    [matTooltipClass]="'info-popup'"
                                    class="fa fa-question-circle-o"
                                    style="display: inline-block; margin-left: 6px">
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="dl-panel-body">
                        <div class="dl-row row">
                            <div class="dl-col-sm-6 col m6 s12">
                                <h4><span *ngIf="needsService.benefitMap.LC.noteAsterisk">{{ needsService.benefitMap.LC.requested_benefit_amount | dl_currency: true: 0 }} suggested but limited {{needsService.benefitMap.LC.toFromText}}</span> {{ needsService.benefitMap.LC.benefit_amount | dl_currency: true: 0 }} for {{ needsService.benefitMap.LC.premium_amount | dl_currency: true: 0 }} monthly<span *ngIf="needsService.benefitMap.LC.noteAsterisk">**</span></h4>
                                <small> Please feel free to edit the amount covered in the
                                    <a [tabIndex]="5" style="cursor: pointer" (click)="showAssumptions()">assumptions tab</a>
                                </small>
                                <small *ngIf="needsService.benefitMap.LC.noteAsterisk"><br/>** cover amount has been adjusted due to the product minimum or maximum allowed. {{ needsService.ptyName }} has a maximum cover amount of R10 Million</small>
                            </div>
                            <div class="dl-col-sm-6 col m6 s12">
                                <p>Made up of:</p>
                                <table class="dl-table">
                                    <tbody>
                                    <tr>
                                        <th>Education</th>
                                        <td class="text-right">{{ needsService.needsResponse.education_cost | dl_currency: true: 0 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Living</th>
                                        <td class="text-right">{{ needsService.needsResponse.living_cost | dl_currency: true: 0 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Debt</th>
                                        <td class="text-right">{{ needsService.needsResponse.debt | dl_currency: true: 0 }}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dl-panel">
                    <div class="dl-panel-heading">
                        <div class="dl-row row">
                            <div class="dl-col-sm-12 col s12">
                                <div [ngStyle]="rowStyle('SP90')" style="width: 10px; height: 10px; margin: 1px 6px; display: inline-block;"></div>
                                <h4 style="display: inline-block;">Salary Protection: </h4>
                                <p style="display: inline-block;">If you can no longer work and need to replace your salary</p>
                                <span
                                    matTooltip="Salary Protection will pay you an after tax monthly income if you are disabled through illness or injury and are unable to work. Salary Protection is determined at 75% of your current after tax salary."
                                    [matTooltipPosition]="'below'"
                                    [matTooltipClass]="'info-popup'"
                                    class="fa fa-question-circle-o"
                                    style="display: inline-block; margin-left: 6px">
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="dl-panel-body">
                        <div class="dl-row row">
                            <div class="dl-col-sm-12 col s12">
                                <h4 *ngIf="needsService.benefitMap.SP90.premium_amount > 0"><span *ngIf="needsService.benefitMap.SP90.noteAsterisk">{{ needsService.benefitMap.SP90.requested_benefit_amount | dl_currency:true:0 }} suggested but limited {{needsService.benefitMap.SP90.toFromText}}</span> {{ needsService.benefitMap.SP90.benefit_amount | dl_currency:true:0 }} for {{ needsService.benefitMap.SP90.premium_amount | dl_currency:true:0 }} monthly<span *ngIf="needsService.benefitMap.SP90.noteAsterisk">**</span>
                                </h4>
                                <h4 *ngIf="needsService.benefitMap.SP90.premium_amount == 0">We cannot offer this product as your income is too low</h4>
                                <small *ngIf="needsService.benefitMap.SP90.noteAsterisk"><br/>** cover amount has been adjusted due to the product minimum or maximum allowed. {{ needsService.ptyName }} has a maximum cover amount of R90,000 per month</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dl-panel">
                    <div class="dl-panel-heading">
                        <div class="dl-row row">
                            <div class="dl-col-sm-12 col s12">
                                <div [ngStyle]="rowStyle('CI30')" style="width: 10px; height: 10px; margin: 1px 6px; display: inline-block;"></div>
                                <h4 style="display: inline-block;">Critical Illness Cover</h4>
                                <span
                                    matTooltip="Critical Illness Cover pays you a lump sum if you’re diagnosed with a stroke, suffer a heart attack, undergo heart surgery or are diagnosed with cancer (and if you don’t die within 30 days of the first diagnosis)."
                                    [matTooltipPosition]="'below'"
                                    [matTooltipClass]="'info-popup'"
                                    class="fa fa-question-circle-o"
                                    style="display: inline-block; margin-left: 6px">
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="dl-panel-body">
                        <div class="dl-row row">
                            <div class="dl-col-sm-12 col s12">
                                <h4 *ngIf="needsService.benefitMap.CI30.premium_amount > 0"><span *ngIf="needsService.benefitMap.CI30.noteAsterisk">{{ needsService.benefitMap.CI30.requested_benefit_amount | dl_currency:true:0 }} suggested but limited {{needsService.benefitMap.CI30.toFromText}}</span> {{ needsService.benefitMap.CI30.benefit_amount | dl_currency:true:0 }} for {{ needsService.benefitMap.CI30.premium_amount | dl_currency:true:0 }} monthly<span *ngIf="needsService.benefitMap.CI30.noteAsterisk">**</span>
                                </h4>
                                <h4 *ngIf="needsService.benefitMap.CI30.premium_amount == 0">We cannot offer this product as your income is too low</h4>
                                <small *ngIf="needsService.benefitMap.CI30.noteAsterisk"><br/>** cover amount has been adjusted due to the product minimum or maximum allowed. {{ needsService.ptyName }} has a maximum cover amount of R5 Million</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dl-panel">
                    <div class="dl-panel-heading">
                        <div class="dl-row row">
                            <div class="dl-col-sm-12 col s12">
                                <div [ngStyle]="rowStyle('DC')" style="width: 10px; height: 10px; margin: 1px 6px; display: inline-block;"></div>
                                <h4 style="display: inline-block;">Disability Cover: </h4>
                                <p style="display: inline-block;">If you are disabled</p>
                                <span
                                    matTooltip="Disability Cover will provide a single lump sum payout if you are totally and permanently unable to perform your insured occupation (and any similar occupation to which you are suited by skill, education and training) due to a disability through illness or injury."
                                    [matTooltipPosition]="'below'"
                                    [matTooltipClass]="'info-popup'"
                                    class="fa fa-question-circle-o"
                                    style="display: inline-block; margin-left: 6px">
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="dl-panel-body">
                        <div class="dl-row row">
                            <div class="dl-col-sm-12 col s12">
                                <h4 *ngIf="needsService.benefitMap.DC.premium_amount > 0"><span *ngIf="needsService.benefitMap.DC.noteAsterisk">{{ needsService.benefitMap.DC.requested_benefit_amount | dl_currency:true:0 }} suggested but limited {{needsService.benefitMap.DC.toFromText}}</span> {{ needsService.benefitMap.DC.benefit_amount | dl_currency:true:0 }} for {{ needsService.benefitMap.DC.premium_amount | dl_currency:true:0 }} monthly<span *ngIf="needsService.benefitMap.DC.noteAsterisk">**</span>
                                </h4>
                                <h4 *ngIf="needsService.benefitMap.DC.premium_amount == 0">We cannot offer this product as your income is too low</h4>
                                <small *ngIf="needsService.benefitMap.DC.noteAsterisk"><br/>** cover amount has been adjusted due to the product minimum or maximum allowed. {{ needsService.ptyName }} has a maximum cover amount of R10 Million</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dl-panel">
                    <div class="dl-panel-heading">
                        <p><strong>* Please note:</strong> a number of assumptions have been used to assess your situation. Please feel free to edit <a style="cursor: pointer" (click)="showAssumptions()"> some of these.</a></p>
                    </div>
                </div>
                <form [formGroup]="leadForm">
                    <div class="dl-container">
                    <div class="dl-row row">
                        <div class="dl-col-sm-4 col m4 s12">
                            <mat-form-field class="dl-full-width">
                                <input
                                    matInput
                                    name="name"
                                    type="text"
                                    placeholder="Name"
                                    [attr.data-name]="'name'"
                                    [formControl]="leadForm.controls['name']"
                                    [tabIndex]="5">
                            </mat-form-field>
                        </div>
                        <div class="dl-col-sm-5 col m5 s12">
                            <mat-form-field class="dl-full-width">
                                <input
                                    matInput
                                    name="email"
                                    type="email"
                                    placeholder="Email"
                                    [attr.data-name]="'email'"
                                    [formControl]="leadForm.controls['email']"
                                    [tabIndex]="5">
                                <mat-error align="start">Invalid email address</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="dl-col-sm-3 col m3 s12">
                            <mat-form-field class="dl-full-width">
                                <input
                                    matInput
                                    phoneNumber
                                    name="phone_mobile"
                                    type="tel"
                                    placeholder="Cell Number"
                                    [attr.data-name]="'cell'"
                                    [tabIndex]="5"
                                    [formControl]="leadForm.controls['cell']">
                                <mat-error align="start">Format: 000 000 0000</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="dl-row row">
                        <div class="dl-col-md-4 col m4 s12"></div>
                        <div class="dl-col-md-4 col m4 s12">
                            <button
                                *ngIf="!resShowWarning"
                                mat-raised-button
                                class="dl-full-width dl-action-btn"
                                color="default"
                                id="needs_send_email"
                                type="button"
                                [tabIndex]="5"
                                [disabled]="loading || leadForm.pristine || leadForm.invalid || emailSent"
                                [attr.data-name]="'email-results'"
                                [attr.data-event]="true"
                                [attr.data-event-category]="'Calculator'"
                                [attr.data-event-name]="'Results Email'"
                                [attr.data-event-label]="'Calculator Results Email'"
                                (click)="sendNeedResult()">{{sendEmailText}}
                                <i *ngIf="sendingEmail" class="fa fa-circle-o-notch fa-spin"></i>
                                <i *ngIf="!sendingEmail && !emailSent" class="fa fa-chevron-right"></i>
                            </button>
                        </div>
                        <div class="dl-col-md-4 col m4 s12">
                            <button
                                *ngIf="!resShowWarning"
                                mat-raised-button
                                class="dl-full-width  dl-action-btn"
                                color="primary"
                                id="needs_continue"
                                type="button"
                                [tabIndex]="5"
                                [disabled]="loading || leadForm.pristine || leadForm.invalid"
                                [attr.data-name]="'continue'"
                                [attr.data-event]="true"
                                [attr.data-event-category]="'Calculator'"
                                [attr.data-event-name]="'Results Continue'"
                                [attr.data-event-label]="'Calculator Results Continue'"
                                (click)="continueToSp()">Continue
                                <i *ngIf="loading" class="fa fa-circle-o-notch fa-spin"></i>
                                <i *ngIf="!loading" class="fa fa-chevron-right"></i>
                            </button>
                        </div>
                        <div class="dl-col-sm-12 col s12" style="text-align: left;" *ngIf="resShowWarning">
                            <div class="dl-alert dl-alert-danger dl-fade in" role="alert">
                                <h4>We've detected a process already running!</h4>
                                <p>Please note by continuing you will lose your current process. Please check that our
                                    website isn't open in another tab or window.</p>
                                <p style="text-align: right">
                                    <button
                                        mat-raised-button
                                        class="dl-full-width dl-action-btn"
                                        id="qq_oops_close"
                                        type="button"
                                        [tabindex]="1"
                                        [disabled]="loading"
                                        [attr.data-event]="'true'"
                                        [attr.data-event-category]="'Quick Quote'"
                                        [attr.data-event-name]="'Warning Close'"
                                        [attr.data-event-label]="'Quick Quote Warning Close'"
                                        (click)="closeResultModal()">Oops, get me outa here!
                                    </button>
                                    <button
                                        mat-raised-button
                                        color="warn"
                                        class="dl-full-width dl-action-btn"
                                        id="qq_oops_continue"
                                        type="button"
                                        [disabled]="loading"
                                        [tabindex]="1"
                                        [attr.data-event]="'true'"
                                        [attr.data-event-category]="'Quick Quote'"
                                        [attr.data-event-name]="'Warning Continue'"
                                        [attr.data-event-label]="'Quick Quote Warning Continue'"
                                        (click)="postToContinue(true)">I'm ok with that, Continue&nbsp;
                                        <span *ngIf="!resLoading" class="fa fa-chevron-right"></span>
                                        <i class='fa fa-circle-o-notch fa-spin' *ngIf="resLoading"></i>
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>
                    </div>
                </form>
            </mat-tab>
            <mat-tab label="Assumptions" [tabIndex]="6">
                <div class="dl-panel" [attr.data-name]="'how-it-works'">
                    <div class="dl-panel-heading">
                        <div class="dl-row row">
                            <div class="dl-col-sm-12 col s12">
                                <h4>
                                    How it works
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div class="dl-panel-body">
                        <p>To provide you with an indication of your insurance requirements we have made a number of
                            assumptions regarding your financial circumstance. We encourage you to explore and alter
                            the assumptions below in-line with your financial realities.</p>
                    </div>
                </div>
                <div class="dl-panel" *ngIf="needsService.hasSchoolStage">
                    <div class="dl-panel-heading">
                        <div class="dl-row row">
                            <div class="dl-col-sm-12 col s12">
                                <h4>
                                    Expected annual education cost per child
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div class="dl-panel-body">
                        <form [formGroup]="schoolForm">
                            <div class="dl-row row">
                                <div class="dl-col-sm-6 col m6 s12" [attr.data-name]="'school-stages'">
                                    <h4 class="text-center">Average Annual Cost per school stage</h4>
                                    <div class="dl-row row" *ngFor="let item of needsService.schoolStages">
                                        <div class="dl-cal-sm-1 col s1"></div>
                                        <div class="dl-col-sm-11 col s11">
                                            <mat-form-field class="dl-full-width">
                                                <input
                                                    matInput
                                                    type="text"
                                                    [attr.data-type]="'school-stage'"
                                                    [attr.data-name]="item.display_name"
                                                    [placeholder]="item.display_name"
                                                    [tabIndex]="6"
                                                    [formControl]="schoolForm.controls[getControlName(item.display_name)]"
                                                    [textMask]="numMask">
                                                <span matSuffix>per annum</span>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                </div>
                                <div class="dl-col-sm-6 col m6 s12">
                                    <h4>Did you know</h4>
                                    <p>"Public school fees ... range from R19 000 a year to approximately R70 000 a year
                                        if a pupil is also boarding."<br/>
                                        "Pupils attending private schools however are either paying double and in some
                                        instances triple the amount that public schools charge."<br/>
                                        * Only 2012 fees listed. Re: <a
                                            href="http://www.moneyweb.co.za/archive/the-costly-choice-between-public-and-private-schoo/"
                                            target="_blank">moneyweb</a>
                                    </p>
                                    <div class="dl-row row">
                                        <div class="dl-col-sm-6 col m6 s12"></div>
                                        <div class="dl-col-sm-6 col m6 s12">
                                            <button
                                                mat-raised-button
                                                class="dl-full-width"
                                                color="primary"
                                                id="needs_results_school"
                                                type="button"
                                                [tabIndex]="6"
                                                [attr.data-name]="'recalculate'"
                                                (click)="showResults()">Recalculate<i class="fa fa-refresh" style="margin-left: 5px"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="dl-panel">
                    <div class="dl-panel-heading">
                        <div class="dl-row row">
                            <div class="dl-col-sm-12 col s12">
                                <h4>
                                    Debt Assumptions
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div class="dl-panel-body">
                        <div class="dl-row row" *ngFor="let debtItem of needsService.debtAssumptions">
                            <div class="col-md-12 col s12" style="margin-bottom: 5px" *ngIf="debtItem.max_amount != 0" [attr.data-type]="'debt-assumption'" [attr.data-name]="debtItem.display_name" >
                                <span [attr.data-name]="'slider-name'">{{debtItem.display_name}}<span [attr.data-name]="'slider-value'"> : {{debtItem.amount | dl_currency:true:0}}</span></span>
                                <mat-slider
                                    [displayWith]="formatForDisplay"
                                    [invert]="false"
                                    [max]="debtItem.max_amount"
                                    [min]="0"
                                    [step]="debtItem.step || 2000"
                                    [thumbLabel] = "true"
                                    [value]="debtItem.amount"
                                    (input)="updateBreakDownAmount(debtItem, $event)"
                                    [tabIndex]="6">
                                </mat-slider>
                                <label class="dl-pull-left left">R 0</label>
                                <label class="dl-pull-right right">{{debtItem.max_amount | dl_currency:true:0}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dl-panel" *ngIf="needsService.hasLivingExpenses">
                    <div class="dl-panel-heading">
                        <div class="dl-row row">
                            <div class="dl-col-sm-12 col s12">
                                <h4 style="display: inline-block;">
                                    Living Expense Assumptions
                                </h4>
                                <span
                                    matTooltip="Monthly Expenses: Gas and Electricity, Alarm &amp; Security, Water, Sewer and Garbage, Phone and Internet, Keeping &amp; Cleaning, Insurance, Grocery, Clothing, Personal Care, Medication, Car Insurance, Dining Out, Gym, Gifts, Entertainment and TV"
                                    [matTooltipPosition]="'below'"
                                    [matTooltipClass]="'info-popup'"
                                    class="fa fa-question-circle-o"
                                    style="display: inline-block; margin-left: 6px">
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="dl-panel-body">
                        <div class="dl-row row" *ngFor="let debtItem of needsService.livingExpenses">
                            <div class="col-md-12 col s12" style="margin-bottom: 5px" [attr.data-type]="'living-expense'" [attr.data-name]="debtItem.display_name">
                                <span [attr.data-name]="'slider-name'">{{debtItem.display_name}}<span [attr.data-name]="'slider-value'"> : {{debtItem.amount | dl_currency:true:0}}</span></span>
                                <mat-slider
                                    [displayWith]="formatForDisplay"
                                    [invert]="false"
                                    [max]="debtItem.max_amount"
                                    [min]="0"
                                    [step]="debtItem.step || 2000"
                                    [thumbLabel] = "true"
                                    [value]="debtItem.amount"
                                    (input)="updateBreakDownAmount(debtItem, $event)"
                                    [tabIndex]="6">
                                </mat-slider>
                                <label class="dl-pull-left left">R 0</label>
                                <label class="dl-pull-right right">{{debtItem.max_amount | dl_currency:true:0}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dl-container">
                    <div class="dl-row row">
                        <div class="dl-col-sm-8 col m8 s12"></div>
                        <div class="dl-col-sm-4 col m4 s12">
                            <button
                                mat-raised-button
                                class="dl-full-width "
                                color="primary"
                                id="needs_results"
                                type="button"
                                [attr.data-name]="'recalculate'"
                                [tabindex]="6"
                                (click)="showResults()">Recalculate<i class="fa fa-refresh" style="margin-left: 5px"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </mat-dialog-content>
</div>

