var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Dynamic } from './component';
import { WindowRef } from '../../base/window.ref';
var ResumeButtonComponent = /** @class */ (function (_super) {
    __extends(ResumeButtonComponent, _super);
    function ResumeButtonComponent(winRef) {
        var _this = _super.call(this) || this;
        _this.winRef = winRef;
        return _this;
    }
    ResumeButtonComponent.prototype.setupFromControl = function () {
        this.component.loaded = true;
        // tel my parent I'm loaded.
        this.loaded.emit(this.component.id);
    };
    ResumeButtonComponent.prototype.getFormControl = function () {
        return null;
    };
    ResumeButtonComponent.prototype.onChange = function () {
    };
    ResumeButtonComponent.prototype.click = function () {
        // We need to tel the parent to load the saved data.
        this.resume.emit(true);
    };
    return ResumeButtonComponent;
}(Dynamic));
export { ResumeButtonComponent };
