import {Component, Input} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ContactBlockService, ContactMeVO} from './contact-block.service';
import {isNullOrUndefined} from 'util';
import {DLUtil} from "../base/dl.util";
import {PhoneNumberValidator} from '../base/phone-number';
import {WindowRef} from '../base/window.ref';

@Component({
  selector: 'contact-block',
  templateUrl: './contact-block.component.html',
  styleUrls: ['./contact-block.component.scss']
})
export class ContactBlockComponent {
  cbForm: FormGroup;

  rgbColour = 'rgba(255, 255, 255, 0.6)';
  _bgColour = '255,255,255';
  @Input('bg-colour')
  set bgColour(val: string) {
    this._bgColour = val;
    this.setBgColour();
  }
  _opacity = 0.6;
  @Input()
  set opacity(val: number) {
    this._opacity = val;
    this.setBgColour();
  }

  _heading1 = 'Need assistance?';
  @Input('top-text-1')
  set heading1(val: string) {
    this._heading1 = val;
  }
  get heading1(): string {
    return this._heading1;
  }

  _heading2 = '';
  @Input('top-text-2')
  set heading2(val: string) {
    this._heading2 = val;
  }
  get heading2(): string {
    return this._heading2;
  }

  _bottomText1 = '';
  @Input('bottom-text-1')
  set bottomText1(val: string) {
    this._bottomText1 = val;
  }
  get bottomText1(): string {
    return this._bottomText1;
  }

  _bottomText2 = '';
  @Input('bottom-text-2')
  set bottomText2(val: string) {
    this._bottomText2 = val;
  }
  get bottomText2(): string {
    return this._bottomText2;
  }

  _buttonText = 'Please call me';
  @Input('button-text')
  set buttonText(val: string) {
    this._buttonText = val;
  }
  get buttonText(): string {
    return this._buttonText;
  }

    _showEmail = false;
    @Input('show-email')
    set showEmail(val: boolean) {
        this._showEmail = val;
    }
    get showEmail(): boolean {
        return this._showEmail;
    }

    _skipLead = false;
    @Input('skip-lead')
    set skipLead(val: boolean) {
        this._skipLead = val;
    }
    get skipLead(): boolean {
        return this._skipLead;
    }

    _control = '';
    @Input('control')
    set control(val: string) {
        this._control = val;
    }
    get control(): string {
        return this._control;
    }

  _campaignCode = '';
  @Input('campaign-code')
  set campaignCode(val: string) {
    this._campaignCode = val;
  }
  _leadSourceDescription = 'Contact Block';
  @Input('lead-source-description')
  set leadSourceDescription(val: string) {
    this._leadSourceDescription = val;
  }
  _providerId = '';
  @Input('provider-id')
  set providerId(val: string) {
    this._providerId = val;
  }

  _referrer = '';
  @Input()
  set referrer(val: string) {
    this._referrer = val;
  }
  _referrerCode = '';
  @Input('referrer-code')
  set referrerCode(val: string) {
    this._referrerCode = val;
  }

  private setBgColour(): void {
    this.rgbColour = 'rgba(' + this._bgColour + ', ' + this._opacity + ')';
  }

  public getBackGround(): object {
    return {"background-color": this.rgbColour};
  }

  success = false;
  busy = false;

  constructor(private fb: FormBuilder,
              private service: ContactBlockService, private readonly winRef: WindowRef) {
    this.initForm();
  }

  private initForm() {
    this.cbForm = this.fb.group({
      name: ['', [Validators.required]],
      cell: ['', [Validators.required, PhoneNumberValidator.validator()]],
      email: ['', [Validators.email, Validators.pattern(DLUtil.emailPattern)]]
    });
  }

  saveLead() {
    this.busy = true;
    const data = new ContactMeVO();
    const nameParts = this.cbForm.get('name').value.split(' ');
    if (nameParts.length > 1) {
      data.firstname = nameParts[0];
      for (let i = 1; i < nameParts.length; i++) {
        if (isNullOrUndefined(data.lastname)) {
          data.lastname = nameParts[i];
        } else {
          data.lastname += ' ' + nameParts[i];
        }
      }
    } else {
      data.firstname = this.cbForm.get('name').value;
    }
    data.phonemobile = this.cbForm.get('cell').value.replace(/\D+/g, '');
    if (this.cbForm.get('email').value !== '') {
      data.email = this.cbForm.get('email').value;
    }
    data.campaign_code = this._campaignCode;
    if (this._leadSourceDescription !== '') {
      data.leadsourcedescription = this._leadSourceDescription;
    }
    data.referrer_code = this._referrerCode;
    data.referrer = this._referrer;
    data.provider_id = this._providerId;
    if (this._control !== '') {
      data.control = this._control;
    }

    if (this._skipLead) {
        this.service.submitCallMeRequestNoEmail(data).subscribe(() => this.showSuccess(), () => this.handleFail());
    } else {
        this.service.submitCallMeRequest(data).subscribe(() => this.showSuccess(), () => this.handleFail());
    }
  }

  private showSuccess(): void {
    this.winRef.nativeWindow.location = '/contact-thank-you';
    this.success = true;
    this.busy = false;
  }

  private handleFail() {
    this.success = false;
    this.busy = false;
  }

  get hasBottomText():boolean {
      return (!isNullOrUndefined(this.bottomText1) && this.bottomText1.length > 0) ||
          (!isNullOrUndefined(this.bottomText2) && this.bottomText2.length > 0);

  }
}
