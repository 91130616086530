
    <div class="row" *ngIf="!component.shareRow">
        <div class="col s12 m6 offset-m3">
            <button
              mat-raised-button
              class="mat-primary dl-full-width dl-action-btn"
              (click)="click()">{{component.name}}</button>
        </div>
    </div>
    <button *ngIf="component.shareRow"
            mat-raised-button
            class="mat-primary dl-full-width dl-action-btn"
            (click)="click()">{{component.name}}</button>
    