<div class="dl-disclosure" id="notes-modal" cdk-trap-focus>
    <div mat-dialog-title class="dl-modal-header">
        <h4 class="dl-modal-title">{{data.title}}</h4>
    </div>
    <mat-dialog-content>
        <div class="dl-modal-body">
            <div class="dl-row row">
                <div class="col dl-col-lg-12 m12 s12">
                    <mat-form-field class="dl-full-width">
                        <textarea matInput class="dl-full-width" [(ngModel)]="data.notes"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="dl-modal-footer">
            <div class="dl-row row">
                <div class="dl-col-md-4 col s12 m4">
                    <button
                        mat-raised-button
                        color="primary"
                        class="dl-full-width dl-action-btn"
                        [mat-dialog-close]="data.notes" cdkFocusInitial>Ok</button>
                </div>
            </div>
        </div>
    </mat-dialog-content>
</div>
