<div>
    <mat-card>
        <mat-card-title class="title">Your Cover</mat-card-title>
        <mat-card-content>
            <div class="dl-row row">
                <div class="dl-col-md-12 text-center dl-toggle-calc col s12">
                    <label>Move the slider to change your </label>
                    <br class="dl-hidden-lg dl-hidden-md dl-hidden-sm hide-on-med-and-up">
                    <mat-radio-group name="rgCPSwitcher" [value]="coverOrPremium" [(ngModel)]="coverOrPremium" name="coverOrPremium"
                                     (change)="switchValues($event)">
                        <mat-radio-button [value]="'Cover'" [attr.data-name]="'coverOrPremium-Cover'">Cover</mat-radio-button>
                        <label> or </label>
                        <mat-radio-button [value]="'Premium'" [attr.data-name]="'coverOrPremium-Premium'">Premium</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <p style="margin-bottom: 20px;">We can offer you
                <b>{{ val.benefit_amount | dl_currency:true:0 }}</b> cover for
                <b>{{ val.premium_amount | dl_currency:true:2 }}</b>&nbsp;p/m.
            </p>

            <div class="row">
                <div class="col s12">
                    <mat-slider
                        *ngIf="isCover()"
                        [invert]="false"
                        [max]="maxCover"
                        [min]="minCover"
                        [step]="increments"
                        [thumbLabel]="true"
                        [displayWith]="formatDisplay"
                        [value]="val.benefit_amount"
                        (input)="updateCover($event)">
                    </mat-slider>
                    <mat-slider
                        *ngIf="isPremium()"
                        [invert]="false"
                        [max]="maxPremium"
                        [min]="minPremium"
                        [step]="increments"
                        [thumbLabel]="true"
                        [displayWith]="formatDisplay"
                        [value]="val.premium_amount"
                        (input)="updateCover($event)">
                    </mat-slider>
                </div>
            </div>
            <div class="row">
                <div class="col s12" style="margin-top: -20px;">
                    <div class="left">{{minCover | dl_currency:true:0}}</div>
                    <div class="right">{{maxCover | dl_currency:true:0}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col s12">
                    <small>Change slider to change the cover amount</small>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
    <mat-card *ngIf="val.product_code != 'CANCER_COVER'">
        <mat-card-title class="title">More Cover</mat-card-title>
        <mat-card-content>
            <!--<p style="margin-bottom: 20px;">-->
                <!--For only <b>{{ val.premium_amount | dl_currency:true:2 }}</b>&nbsp;p/m get up to-->
                <!--<b>{{fullCover | dl_currency:true:0 }}</b> cover-->
                <!--assuming a healthy life. Select <b>More Cover</b> to complete our full process (allow 15 minutes).-->
            <!--</p>-->
            <p class="font-weight-lighter">For {{val.premium_amount | dl_currency:true:'R':0}} p/m get <b>up to</b> {{fullCover | dl_currency:true:'R':0}} assuming</p>
            <p class="font-weight-lighter">healthy life. Select <b>apply for more</b> to complete</p>
            <p class="font-weight-lighter">our comprehensive (allow 15 minutes).</p>
        </mat-card-content>
    </mat-card>
</div>
