/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./info-card.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "@angular/common";
import * as i5 from "../../base/mlf-summary/mlf-base-summary.component.ngfactory";
import * as i6 from "../../base/mlf-summary/mlf-base-summary.component";
import * as i7 from "@angular/material/dialog";
import * as i8 from "./mlf-summary.component";
import * as i9 from "./ajax.service";
import * as i10 from "../dsp.service";
import * as i11 from "./component";
var styles_MLFSummaryComponent = [i0.styles];
var RenderType_MLFSummaryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MLFSummaryComponent, data: {} });
export { RenderType_MLFSummaryComponent as RenderType_MLFSummaryComponent };
function View_MLFSummaryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "dl-loading absolute"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [["class", "fa fa-circle-o-notch fa-spin"]], null, null, null, null, null))], null, null); }
export function View_MLFSummaryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "mat-card", [["class", "mat-card"]], null, null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i3.MatCard, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 5, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i3.MatCardContent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MLFSummaryComponent_1)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "mlf-base-summary", [], null, [[null, "add"], [null, "edit"], [null, "remove"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("add" === en)) {
        var pd_0 = (_co.add($event) !== false);
        ad = (pd_0 && ad);
    } if (("edit" === en)) {
        var pd_1 = (_co.edit($event.main, $event.dependant) !== false);
        ad = (pd_1 && ad);
    } if (("remove" === en)) {
        var pd_2 = (_co.remove($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i5.View_MLFBaseSummaryComponent_0, i5.RenderType_MLFBaseSummaryComponent)), i1.ɵdid(7, 49152, null, 0, i6.MLFBaseSummaryComponent, [i7.MatDialog], { leadName: [0, "leadName"], spId: [1, "spId"], benefitId: [2, "benefitId"], dependants: [3, "dependants"], products: [4, "products"], eventCategory: [5, "eventCategory"] }, { add: "add", edit: "edit", remove: "remove" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isBusy; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.leadName; var currVal_2 = _co.spId; var currVal_3 = _co.benefitId; var currVal_4 = _co.dependants; var currVal_5 = _co.products; var currVal_6 = ("DSP-" + _co.productCode); _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_MLFSummaryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mlf-summary", [], null, null, null, View_MLFSummaryComponent_0, RenderType_MLFSummaryComponent)), i1.ɵdid(1, 180224, null, 0, i8.MLFSummaryComponent, [i9.AjaxService, i10.DspService, i11.ParentEventService], null, null)], null, null); }
var MLFSummaryComponentNgFactory = i1.ɵccf("mlf-summary", i8.MLFSummaryComponent, View_MLFSummaryComponent_Host_0, { flowId: "flowId", formGroup: "formGroup", component: "component", pageName: "pageName", _productCode: "_productCode", groupId: "groupId", _spMode: "_spMode" }, { change: "change", loaded: "loaded", validated: "validated", resume: "resume", altContinue: "altContinue" }, []);
export { MLFSummaryComponentNgFactory as MLFSummaryComponentNgFactory };
