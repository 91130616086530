import {DLCurrencyPipe} from './dl.currency.pipe';
import {RSAIdValidator} from './rsa.id.validator';
import {MinMaxValidator} from './min.max.validator';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DlDatePipe} from './dl.date.pipe';
import {DlCompactNumberPipe} from './dl.compact-number.pipe';
import {DlOfferTextPipe} from './dl.offer-text.pipe';

@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    declarations: [
        MinMaxValidator,
        RSAIdValidator,
        DLCurrencyPipe,
        DlCompactNumberPipe,
        DlOfferTextPipe,
        DlDatePipe
    ],
    exports: [
        MinMaxValidator,
        RSAIdValidator,
        DLCurrencyPipe,
        DlCompactNumberPipe,
        DlOfferTextPipe,
        DlDatePipe
    ]
})
export class ValidatorModule {

    public static unmaskTell(tell: string): string {
        let exp = new RegExp('\\D+', 'g');
        return tell.replace(exp, '');
    }
}
