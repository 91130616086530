<div class="dl-dsp-check-group">
    <div class="dl-dsp-question" [attr.data-name]="component.name">
        <span *ngIf="component.valueExtra" [innerHTML]="component.valueExtra"></span>&nbsp;&nbsp;<i
            *ngIf="component.info" class="fa fa-info-circle" aria-hidden="true" matTooltip="{{component.info}}"
            #me="matTooltip" (click)="me.toggle()"></i>
    </div>
    <div class="dsp-check-group">
        <mat-checkbox *ngFor="let item of checkItems" class="dsp-check-button" color="primary" id="{{item.id}}"
            [tabIndex]="1" [checked]="item.check" [attr.data-val]="item.id" [attr.data-name]="item.id"
            [attr.kings-watch]="flowId + '-' + pageName + '-' + component.id" (change)="itemChange($event, item.id)">
            <span [innerHTML]="item.display"></span>&nbsp;&nbsp;<i *ngIf="item.info && item.info != ''"
                class="fa fa-info-circle" aria-hidden="true" matTooltip="{{item.info}}" #me="matTooltip"
                (click)="me.toggle()"></i>
        </mat-checkbox>
    </div>
</div>
