import {Benefit} from '../base/benefit';
import {Component} from '@angular/core';
import {QqDynamicComponent} from './qq.dynamic.component';

@Component({
    selector: 'sg-result-block',
    template: `<span></span>`
})
export class SGResultBlockComponent extends QqDynamicComponent {
    benefit: Benefit;

  public setBenefit(benefit: Benefit): void {
      this.benefit = benefit;
  }

  public setCauseText(cause: string): void {
  }
}
