var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SpBaseService } from '../sp.service';
import { catchError } from 'rxjs/operators';
import { SpDisclosuresProvider } from '../sp.disclosures.provider.service';
import { SpDataService } from '../sp.data.service';
import { HttpClient } from '@angular/common/http';
import { ErrorModalProvider } from '../../base/error/error.modal.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../sp.data.service";
import * as i3 from "../../base/error/error.modal.service";
import * as i4 from "../sp.disclosures.provider.service";
var ConfirmationService = /** @class */ (function (_super) {
    __extends(ConfirmationService, _super);
    function ConfirmationService(httpClient, spDataService, errorModalProvider, disclosuresProvider) {
        return _super.call(this, httpClient, spDataService, errorModalProvider, disclosuresProvider) || this;
    }
    ConfirmationService.prototype.saveReason = function (value) {
        var url = '/dl/data/sp/' + this.spDataService.getSalesProcessID() + '/confirmation';
        return this.httpClient.post(url, { 'reasons_purchased': value }, this.httpOptions()).pipe(catchError(this.handleHTTPError()));
    };
    ConfirmationService.prototype.createPostDocCase = function (notes) {
        var url = '/dl/data/sp/case/createPostDocCase/' + this.spDataService.getPolicyId();
        return this.httpClient.post(url, { 'notes': notes }, this.httpOptions()).pipe(catchError(this.handleHTTPError()));
    };
    ConfirmationService.prototype.createPostSaleServiceCase = function (notes) {
        var url = '/dl/data/sp/case/createServicingCase/' + this.spDataService.getPageData().contactId;
        return this.httpClient.post(url, { 'notes': notes }, this.httpOptions()).pipe(catchError(this.handleHTTPError()));
    };
    ConfirmationService.ngInjectableDef = i0.defineInjectable({ factory: function ConfirmationService_Factory() { return new ConfirmationService(i0.inject(i1.HttpClient), i0.inject(i2.SpDataService), i0.inject(i3.ErrorModalProvider), i0.inject(i4.SpDisclosuresProvider)); }, token: ConfirmationService, providedIn: "root" });
    return ConfirmationService;
}(SpBaseService));
export { ConfirmationService };
var ConfirmationPageData = /** @class */ (function () {
    function ConfirmationPageData() {
        this.pty_name = '';
        this.policy_number = '';
        this.servicing_telephone = '';
        this.general_telephone = '';
        this.home_url = '';
        this.set_password = false;
    }
    return ConfirmationPageData;
}());
export { ConfirmationPageData };
