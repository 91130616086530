import { EventEmitter } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { isAsciiLetter } from 'codelyzer/angular/styles/chars';
import { DatePipe } from '@angular/common';
var DateComponent = /** @class */ (function () {
    function DateComponent(datePipe) {
        var _this = this;
        this.datePipe = datePipe;
        this.config = new DateComponentConfig();
        this.inputUpdated = new EventEmitter();
        this._textControl = new FormControl('');
        this._name = 'dobInput';
        this._placeHolder = 'Date of Birth';
        this.dateChange = new EventEmitter();
        this._textControl.valueChanges.subscribe(function () { return _this.dateInputChanged(); });
        this.inputUpdated.subscribe(function () { return _this.setupControl(); });
    }
    Object.defineProperty(DateComponent.prototype, "formControlRef", {
        get: function () {
            return this._textControl;
        },
        set: function (val) {
            var _this = this;
            if (!isNullOrUndefined(val)) {
                this._textControl = val;
                if (!isNullOrUndefined(val.value) && val.value !== '') {
                    // always assume that we receive the original in ISO format.
                    this.originalDate = new Date(val.value);
                    // empty out the original value until the control is ready for display
                    val.setValue('');
                }
                this._textControl.valueChanges.subscribe(function () { return _this.dateInputChanged(); });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateComponent.prototype, "name", {
        get: function () {
            return this._name;
        },
        set: function (val) {
            this._name = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateComponent.prototype, "placeholder", {
        get: function () {
            return this._placeHolder;
        },
        set: function (val) {
            this._placeHolder = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateComponent.prototype, "dateFormat", {
        get: function () {
            return this.config.dateFormat;
        },
        set: function (val) {
            this.config.dateFormat = val;
            this.inputUpdated.emit(this.config);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateComponent.prototype, "dateFormatDisplay", {
        get: function () {
            return this.config.dateFormat.toUpperCase();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateComponent.prototype, "required", {
        get: function () {
            return this.config.required;
        },
        set: function (val) {
            this.config.required = val;
            this.inputUpdated.emit(this.config);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateComponent.prototype, "minAge", {
        get: function () {
            return this.config.minAge;
        },
        set: function (val) {
            this.config.minAge = val;
            this.inputUpdated.emit(this.config);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateComponent.prototype, "maxAge", {
        get: function () {
            return this.config.maxAge;
        },
        set: function (val) {
            this.config.maxAge = val;
            this.inputUpdated.emit(this.config);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateComponent.prototype, "tabIndex", {
        get: function () {
            return this.config.tabIndex;
        },
        set: function (val) {
            this.config.tabIndex = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateComponent.prototype, "formatHintOrFormattedDate", {
        get: function () {
            if (isNullOrUndefined(this.theDate)) {
                return this.dateFormatDisplay;
            }
            else {
                return this.theDate.toDateString();
            }
        },
        enumerable: true,
        configurable: true
    });
    DateComponent.validDate = function (comp) {
        return function (control) {
            var theDate = comp.stringToDate(control.value);
            if (theDate === null) {
                return { invalidDate: 'Invalid date' };
            }
            else {
                return null;
            }
        };
    };
    DateComponent.ageBetween = function (min, max, comp) {
        return function (control) {
            var theDate = comp.stringToDate(control.value);
            if (theDate === null) {
                return null;
            }
            var today = new Date();
            var age = today.getFullYear() - theDate.getFullYear();
            var m = today.getMonth() - theDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < theDate.getDate())) {
                age--;
            }
            if (age >= min && age <= max) {
                return null;
            }
            return { invalidAge: 'Age must be between ' + min + ' and ' + max };
        };
    };
    DateComponent.prototype.setupControl = function () {
        var _this = this;
        if (isNullOrUndefined(this.dateFormat)) {
            this.dateFormat = 'yyyy-MM-dd';
        }
        this.yearStart = this.dateFormatDisplay.indexOf('Y');
        this.monthStart = this.dateFormatDisplay.indexOf('M');
        this.dayStart = this.dateFormatDisplay.indexOf('D');
        var validators = [DateComponent.validDate(this)];
        if (this.required) {
            validators.push(Validators.required);
        }
        if (!isNullOrUndefined(this.minAge) && !isNullOrUndefined(this.maxAge)) {
            validators.push(DateComponent.ageBetween(this.minAge, this.maxAge, this));
        }
        this.formControlRef.setValidators(validators);
        var mask = [];
        for (var i = 0; i < this.dateFormat.length; i++) {
            if (isAsciiLetter(this.dateFormat.charCodeAt(i))) {
                mask.push(/\d/);
            }
            else {
                mask.push(this.dateFormat[i]);
            }
        }
        this.dateMask = {
            mask: mask,
            keepCharPositions: true
        };
        if (!isNullOrUndefined(this.originalDate)) {
            // need to do it as part of a timeout.
            setTimeout(function () { return _this.formControlRef.setValue(_this.dateToString(_this.originalDate)); });
        }
    };
    DateComponent.prototype.dateInputChanged = function () {
        var inDateString = this.formControlRef.value;
        if (isNullOrUndefined(inDateString)) {
            return;
        }
        this.theDate = this.stringToDate(inDateString);
        this.dateChange.emit(this.theDate);
    };
    DateComponent.prototype.stringToDate = function (dateString) {
        if (isNullOrUndefined(dateString) || dateString.length !== this.dateFormat.length || dateString.indexOf('_') >= 0) {
            return null;
        }
        var yString = dateString.substring(this.yearStart, this.yearStart + 4);
        var mString = dateString.substring(this.monthStart, this.monthStart + 2);
        var dString = dateString.substring(this.dayStart, this.dayStart + 2);
        var yNumber = parseInt(yString, 10);
        var mNumber = parseInt(mString, 10) - 1;
        var dNumber = parseInt(dString, 10);
        var theDate = new Date(yNumber, mNumber, dNumber);
        if (isNaN(theDate.getTime())) {
            return null;
        }
        if (yNumber !== theDate.getFullYear() || mNumber !== theDate.getMonth() || dNumber !== theDate.getDate()) {
            // Prevent e.g. 31 Nov being created as 1 Dec
            return null;
        }
        return theDate;
    };
    DateComponent.prototype.dateToString = function (date) {
        return this.datePipe.transform(date, this.dateFormat);
    };
    return DateComponent;
}());
export { DateComponent };
var DateComponentConfig = /** @class */ (function () {
    function DateComponentConfig() {
        this.dateFormat = 'yyyy-MM-dd';
        this.required = false;
        this.tabIndex = '1';
    }
    return DateComponentConfig;
}());
export { DateComponentConfig };
