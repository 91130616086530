var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Validators } from '@angular/forms';
import { minMaxValidation } from '../../base/min.max.validator';
import { BeneficiariesComponent } from './beneficiaries.component';
import { DLUtil } from '../../base/dl.util';
var BeneficiariesUKComponent = /** @class */ (function (_super) {
    __extends(BeneficiariesUKComponent, _super);
    function BeneficiariesUKComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BeneficiariesUKComponent.prototype.buildForm = function () {
        this.hideId = true;
        this.hasEmail = true;
        this.canSkip = false;
        this.beneficiariesForm = this.fb.group({
            name: ['', Validators.required],
            relation: ['', Validators.required],
            email: ['', [Validators.email, Validators.pattern(DLUtil.emailPattern)]],
            percentage: ['', [Validators.required, minMaxValidation(1, 100)]]
        });
    };
    return BeneficiariesUKComponent;
}(BeneficiariesComponent));
export { BeneficiariesUKComponent };
