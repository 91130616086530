import {Injectable} from '@angular/core';
import {QQPricingRequestVO, QQPricingResponseVO} from './qq.pricing.vos';
import {ErrorVO} from '../base/error/error.vo';
import {PostData, QQForm, QQResult} from './qq.vo';
import {ProductGroup} from '../base/benefit';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ErrorModalProvider} from '../base/error/error.modal.service';
import {catchError} from 'rxjs/operators';
import {Observable, of} from 'rxjs';

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};

/**
 * Created by corneliusbotha on 2017/02/19.
 * Service for QQ rest calls.
 */
@Injectable({
  providedIn: 'root'
})
export class QQService {

    constructor(
        private http: HttpClient,
        private errorModalProvider: ErrorModalProvider) {}

    private qqPricingUrl = '/dl/data/pricing/quick';
    private qqContinueToSPUrl = '/dl/data/needs/qq';
    private qqExternalUrl = '/dl/data/needs/external-qq';
    private qqPleaseCallMe = '/dl/data/needs/qqPleaseCall';

    public getPricing(req: QQPricingRequestVO): Observable<QQPricingResponseVO> {
        return this.http.post<QQPricingResponseVO>('/dl/data/needs/qq-price', JSON.stringify(req), httpOptions).pipe(
            catchError(this.handleError<QQPricingResponseVO>())
        );
    }

    public getMlfPricing(data) {
        const url = '/dl/data/needs/simple-qq';
        return this.http.post(url, JSON.stringify(data), httpOptions).pipe(
            catchError(this.handleError())
        );
    }

    public continueToSP(postData: PostData): Observable<QQResult> {
        return this.http.post<QQResult>('/dl/data/needs/qq', JSON.stringify(postData), httpOptions).pipe(
            catchError(this.handleError<QQResult>())
        );
    }

    public qqExternal(postData: PostData): Observable<QQResult> {
        return this.http.post<QQResult>(this.qqExternalUrl, JSON.stringify(postData), httpOptions).pipe(
            catchError(this.handleError<QQResult>())
        );
    }

    public requestCallMe(): Observable<any> {
        return this.http.put('/dl/data/needs/qq-please-call', '', httpOptions).pipe(
            catchError(this.handleError())
        );
    }

    public postInvalidReason(postData: PostData): Observable<any> {
        return this.http.post('/dl/data/needs/qq-error', JSON.stringify(postData), httpOptions).pipe(
            catchError(this.handleError())
        );
    }

    public getForm(embedded, lead_id: string, campaign_code: string): Observable<QQForm> {
        const data = {
            embedded: embedded,
            lead_id: lead_id,
            campaign_code: campaign_code
        };
        return this.http.post<QQForm>('/dl/data/needs/qq-form', data, httpOptions).pipe(
            catchError(this.handleError<QQForm>())
        );
    }

    private handleError<T>() {
        return (error: any):  Observable<T> => {
            let vo: ErrorVO = ErrorVO.toErrorVO(error);
            this.errorModalProvider.showError(vo);
            return of(null as T);
        };
    }

}

class ProductsRequestData {
    lead_id: string;
    campaign_code: string;
}
