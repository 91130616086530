import {Injectable} from '@angular/core';
import {DspInjectable, httpOptions} from '../dsp.service';
import {ErrorModalProvider} from '../../base/error/error.modal.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BankingValidationService extends DspInjectable {

    // AVS URL
    baseUrl = '/dl/data/banking/avsValidation';

    // CDV URL
    // baseUrl = '/dl/data/banking/validate';

    constructor(
        protected http: HttpClient,
        protected errorDialogService: ErrorModalProvider) {
        super();
    }

    // AVS
    public validateAccount(req: ValidationReqVO): Observable<AVSResultVO> {
        return this.http.post<AVSResultVO>(this.baseUrl, req, httpOptions).pipe(
            catchError(this.handleError<AVSResultVO>(this.errorDialogService, 'Validation Failed!'))
        );
    }

    // CDV
    // public validateAccount(req: ValidationReqVO): Observable<ValidationResVO> {
    //     return this.http.post<ValidationResVO>(this.baseUrl, req, httpOptions).pipe(
    //         catchError(this.handleError<ValidationResVO>(this.errorDialogService, 'Validation Failed!'))
    //     );
    // }
}

export class ValidationReqVO {
    account_number: string;
    branch_code: string;
    account_type: string;
    ref: string;
}

export class ValidationResVO {
    valid: boolean;
}

export class AVSReqVO {
    ref: string;
    account_number: string;
    account_type: string;
    branch_code: string;
}

export class DebicheckSupportedResVO {
    success: boolean;
    msg: string;
    delayedSupported: boolean;
    realtimeSupported: boolean;
}

export class DebicheckSupportedReqVO {
    bankName: string;
    userRef: string;
}

interface DebicheckSupportedNoAVSReqVO {
    bankName: string;
}

export class AVSResultVO {
    success: boolean;
    count: number;
    errmsg: string;
    Response: {
        userReference: string;
    };
}
