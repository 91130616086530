<div class="dsp-navbar">
    <nav>
        <div>
            <ul class="nav-icon-left">
                <li>
                    <a
                            mat-icon-button
                            matTooltip="Goto the start"
                            matTooltipPosition="right"
                            tabindex="1"
                            [attr.data-event]="'true'"
                            [attr.data-event-category]="'DSP-' + dspService.productCode"
                            [attr.data-event-name]="'Home'"
                            [attr.data-event-label]="'Home from ' + dspService.pageName"
                            (click)="takeMeHome()">
                        <i class="material-icons">home</i>
                    </a>
                </li>
            </ul>
            <ul class="nav-icon-right">
                <li>
                    <a
                            mat-icon-button
                            matTooltip="Contact us"
                            matTooltipPosition="left"
                            tabindex="1"
                            [attr.data-event]="'true'"
                            [attr.data-event-category]="'DSP-' + dspService.productCode"
                            [attr.data-event-name]="'Contact us'"
                            [attr.data-event-label]="'Contact us from ' + dspService.pageName"
                            (click)="contactMe()">
                        <i class="material-icons"><i class="material-icons">contact_phone</i></i>
                    </a>
                </li>
            </ul>
            <div>
                <span class="logo-holder">
                    <a href="/"><img alt="Stangen" class="logo" src="/assets/img/sg/stangen_portrait_black.svg"/></a>
                </span>
            </div>
        </div>
    </nav>
</div>
