var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { InfoCardComponent } from './info-card.component';
import { DlOfferTextPipe } from '../../base/dl.offer-text.pipe';
var OfferInfoCardComponent = /** @class */ (function (_super) {
    __extends(OfferInfoCardComponent, _super);
    function OfferInfoCardComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(OfferInfoCardComponent.prototype, "content", {
        get: function () {
            return new DlOfferTextPipe().transform(this.component.value);
        },
        enumerable: true,
        configurable: true
    });
    return OfferInfoCardComponent;
}(InfoCardComponent));
export { OfferInfoCardComponent };
