import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MinMaxValidator} from './min.max.validator';
import {RSAIdValidator} from './rsa.id.validator';
import {DLCurrencyPipe} from './dl.currency.pipe';
import {BenefitSliderComponent} from './benefit.slider.component';
import {CommonModule, DatePipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ValidatorModule} from './validator.module';
import {
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatSliderModule,
    MatTooltipModule
} from '@angular/material';
import {DlDatePipe} from './dl.date.pipe';
import {PageNotFoundComponent} from './page.notfound.component';
import {PhoneNumberInputDirective} from './phone-number';
import {DlCompactNumberPipe} from './dl.compact-number.pipe';
import {DlOfferTextPipe} from './dl.offer-text.pipe';
import {DateComponent} from './date.component';
import {TextMaskModule} from 'angular2-text-mask';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {DatePickerComponent} from './date.picker.component';

/**
 * Created by corneliusbotha on 2017/03/22.
 * The Base module for DL
 */

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatSliderModule,
        ValidatorModule,
        MatButtonModule,
        MatRadioModule,
        MatTooltipModule,
        TextMaskModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatMomentDateModule
    ],
    declarations: [
        BenefitSliderComponent,
        PageNotFoundComponent,
        PhoneNumberInputDirective,
        DateComponent,
        DatePickerComponent
    ],
    providers: [
        DatePipe
    ],
    exports: [
        MinMaxValidator,
        RSAIdValidator,
        DLCurrencyPipe,
        DlCompactNumberPipe,
        DlOfferTextPipe,
        DlDatePipe,
        BenefitSliderComponent,
        PageNotFoundComponent,
        PhoneNumberInputDirective,
        DateComponent,
        DatePickerComponent
    ]
})
export class BaseModule {

    public static unmaskTell(tell: string): string {
        let exp = new RegExp('\\D+', 'g');
        return tell.replace(exp, '');
    }
}
