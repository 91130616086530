import { Component, Inject, OnInit, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MatSliderChange, MAT_DIALOG_DATA } from "@angular/material";
import { MLFModalData } from "../mlf-summary/mlf-dependant-modal.component";
import { CoverOptionsVO, MLFDependantVO, MLFSimplePricingRequestVO, MLFSummaryService, MLFUtil } from "../mlf-summary/mlf-summary.service";
import { Log } from 'ng2-logger/browser';
import { RsaId, rsaIdValidation, RsaIdValidationCallback } from "../rsa.id.validator";
import { DropDownValuesVO } from "../select.vo";
import { isNullOrUndefined } from "util";
import { BehaviorSubject } from "rxjs";
import { DLUtil } from "../dl.util";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { ErrorVO } from "../error/error.vo";
import { LLCModalData } from "./llc-dependant-modal.component";
import { LLCDependantVO } from "./llc-summary.service";

const log = Log.create('LlcDependantComponent');

@Component({
    selector: 'llc-dependant',
    template: `
        <div class="dl-mlf-dependant">
            <div mat-dialog-title class="dl-modal-header">
                <a class="close dl-pull-right right" (click)="cancel()"><span class="fa fa-times" tabindex="-1"></span></a>
                <h4 class="modal-title">{{getTitleText}}</h4>
            </div>
            <mat-dialog-content>
                <div class="dl-modal-body">
                    <form [formGroup]="formGroup" id="dependant">
                        <div *ngIf="!this.data.main">
                            <div class="row">
                                <div class="col s12 m6" data-comp-id="dependant.first_name">
                                    <mat-form-field class="dl-full-width">
                                        <input matInput
                                            tabindex="1"
                                            placeholder="Name"
                                            [formControl]="formGroup.controls['first_name']"
                                            [attr.data-name]="'first_name'"
                                        >
                                    </mat-form-field>
                                </div>
                                <div class="col s12 m6" data-comp-id="dependant.last_name">
                                    <mat-form-field class="dl-full-width">
                                        <input matInput
                                            tabindex="1"
                                            placeholder="Surname"
                                            [formControl]="formGroup.controls['last_name']"
                                            [attr.data-name]="'last_name'"
                                        >
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col s12 m6" data-comp-id="dependant.id_number">
                                    <mat-form-field class="dl-full-width">
                                        <input matInput
                                            tabindex="1"
                                            placeholder="ID Number or Date of Birth (YYYYMMDD)"
                                            [formControl]="formGroup.controls['id_or_dob']"
                                            [attr.data-name]="'id_number'"
                                        >
                                        <mat-hint>{{getAgeHint}}</mat-hint>
                                        <mat-error align="start">Invalid RSA ID or Date of Birth (YYYYMMDD)</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col s12 m6" data-comp-id="dependant.product">
                                    <mat-form-field class="dl-full-width">
                                        <mat-select
                                            placeholder="Relationship"
                                            tabindex="1"
                                            [formControl]="formGroup.controls['product']"
                                            [attr.data-name]="'product'"
                                            id="product">
                                            <mat-option
                                                *ngFor="let item of data.products"
                                                [attr.data-val]="item.id"
                                                [disabled]="item.disabled"
                                                [value]="item.id">{{item.display}}{{getDisabledText(item)}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col s12 hide-on-med-and-up" style="margin-top: -20px;">
                                    <small [innerHTML]="getProductHint"></small>
                                </div>
                            </div>
                            <div class="row" style="margin-top: 10px">
                                <div class="col s12 m6" data-comp-id="dependant.gender">
                                    <mat-radio-group class="dl-full-width" [formControl]="formGroup.controls['gender']">
                                        <mat-radio-button
                                            class="mat-button-toggle-2"
                                            color="primary"
                                            [tabIndex]="1"
                                            [value]="'M'"
                                            [attr.data-val]="'M'">Male</mat-radio-button>
                                        <mat-radio-button
                                            class="mat-button-toggle-2"
                                            color="primary"
                                            [tabIndex]="1"
                                            [value]="'F'"
                                            [attr.data-val]="'F'">Female</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div class="col m6 hide-on-small-and-down" style="margin-top: -30px;">
                                    <small [innerHTML]="getProductHint"></small>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="coverOptions$ | async as values">
                            <div class="row">
                                <div class="col s12">
                                    <span *ngIf="values.status == 'loading'" class="fa fa-circle-o-notch fa-spin"></span>
                                    <mat-error *ngIf="values.error" >ERROR: {{ values.error }}</mat-error>
                                    <p *ngIf="values.status == 'invalid'">
                                        Enter ID number or date of birth, select gender and dependant relationship
                                    </p>
                                </div>
                            </div>
                            <div class="row" *ngIf="values.status == 'valid'" style="margin-top: 15px;">
                                <div class="col s12">
                                    <p>{{getLeadName}}, we can offer you
                                        <b>{{ benefit_amount | dl_currency:true:0 }}</b> cover for
                                        <span *ngIf="!this.data.main"> this dependant at </span>
                                        <b>{{ premium_amount | dl_currency:true:2 }}</b>&nbsp;p/m.
                                    </p>
                                </div>
                            </div>
                            <div *ngIf="values.status == 'valid' && values.min !== values.max">
                                <div class="row">
                                    <div class="col s12">
                                        <mat-slider
                                            [invert]="false"
                                            [max]="values.max"
                                            [min]="values.min"
                                            [step]="values.step"
                                            [thumbLabel]="true"
                                            [displayWith]="formatDisplay"
                                            [value]="values.cover"
                                            (input)="updateCover($event)">
                                        </mat-slider>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col s12" style="margin-top: -15px;">
                                        <div class="left">{{values.min | dl_currency:true:0}}</div>
                                        <div class="right">{{values.max | dl_currency:true:0}}</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col s12">
                                        <small>Change slider to change the cover amount</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="dl-modal-footer">
                    <div class="dl-container-no-padding">
                        <div class="row">
                            <div class="col m4 s12"></div>
                            <div class="col m4 s12">
                                <button
                                    mat-raised-button
                                    class="dl-action-btn dl-full-width"
                                    tabindex="-1"
                                    [attr.data-name]="'cancel'"
                                    (click)="cancel()">Cancel</button>
                            </div>
                            <div class="col m4 s12">
                                <button
                                    mat-raised-button
                                    color="primary"
                                    class="dl-action-btn dl-full-width"
                                    tabindex="1"
                                    [disabled]="!isValid || formGroup.pristine || formGroup.invalid"
                                    [attr.data-name]="'save'"
                                    (click)="save()">{{this.data.edit ? 'Save' : 'Add'}}&nbsp;<i *ngIf="isBusy" class="fa fa-circle-o-notch fa-spin"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-dialog-content>
        </div>
    `
})
export class LlcDependantComponent implements OnInit, RsaIdValidationCallback {
    public formGroup: FormGroup;
    public rsaId = new RsaId();
    public coverOptions$: BehaviorSubject<CoverOptionsVO>;
    public changeEvents$: EventEmitter<ChangeEvent>;
    public benefit_amount: number;
    public premium_amount: number;
    public sliderPoints: CoverOptionsVO['options'];
    public isValid: boolean = false;
    public isBusy: boolean = false;
    public previouslyDuplicate: boolean = false;
    public product_master_id: string;
    public pricing: any;
    public productDescriptions: any = {
        LLC_F_S: 'A <b>Spouse</b> is a person who is married to the policyholder in terms of law, common law, customary or as a partner (lived together for more than 12-months). Age Min: 18, Max: 65',
        LLC_F_C: 'A <b>Child</b> is a child born to the policyholder or spouse, or partner as well as legally adopted and guardian children. Age Max: 65',
        LLC_F_E: '<b>Parents / In-Laws</b> are the biological parents of the policyholder, or the biological parents of the policy holders spouse. Age Min: 18, Max: 75'
    };

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: MLFModalData,
        private dialogRef: MatDialogRef<LlcDependantComponent>,
        private formBuilder: FormBuilder,
        private summaryService: MLFSummaryService
    ) {
        this.coverOptions$ = new BehaviorSubject(new CoverOptionsVO('invalid'));
        this.changeEvents$ = new EventEmitter();

        if (!isNullOrUndefined(this.data.dependant.benefit_amount)) {
            this.benefit_amount = this.data.dependant.benefit_amount;
        }

        if (!isNullOrUndefined(this.data.dependant.premium_amount)) {
            this.premium_amount = this.data.dependant.premium_amount;
        }

        this.formGroup = this.formBuilder.group({
            product: [this.data.dependant.product, [Validators.required]],
            first_name: [this.data.dependant.first_name, [Validators.required]],
            last_name: [this.data.dependant.last_name, [Validators.required]],
            gender: [this.data.dependant.gender, [Validators.required]],
            id_or_dob: [this.data.dependant.id_or_dob, [Validators.required, Validators.pattern('[0-9]*'), rsaIdValidation(true, this)]]
        });
        
        this.formGroup.get('first_name').valueChanges.pipe(debounceTime(450)).subscribe(() => this.checkDuplicate(true));
        this.formGroup.get('last_name').valueChanges.pipe(debounceTime(450)).subscribe(() => this.checkDuplicate(true));
        this.formGroup.get('id_or_dob').valueChanges.pipe(debounceTime(450)).subscribe(() => {
            this.checkDuplicate(true);

            this.rsaId.setId(this.formGroup.get('id_or_dob').value);

            if (!isNullOrUndefined(this.rsaId.getGender())) {
                this.formGroup.get('gender').setValue((this.rsaId.getGender() === 'Male' ? 'M' : 'F'));
            }
        });

        this.formGroup.get('product').valueChanges.subscribe(() => this.productSelected());
        this.formGroup.get('gender').valueChanges.subscribe(() => this.genderChanged());
        // Initialize an Observable for consolidated, debounced change events
        this.changeEvents$.pipe(
            debounceTime(650),
            distinctUntilChanged((a, b) => {
                return a.rsaId.getId() === b.rsaId.getId() && a.product === b.product && a.gender === b.gender;
            })
        ).subscribe(
            // Update premiums for change events
            ($event) => this.fetchCoverOptions($event.rsaId, $event.product, $event.gender)
        );
    }

    public ngOnInit() {
        try {
        } catch (error) {
            log.error(error);
        }
    }

    get getTitleText(): string {
        try {
            return !this.data.main ? `${(this.data.edit ? 'Edit' : 'Add')} additional life` : 'Edit Main Member';
        } catch (error) {
            log.error(error)
        }
    }

    get getAgeHint(): string {
        try {
            return this.rsaId.isValidDOB() ? `Age: ${this.rsaId.getAge()}, Birthdate: ${new Date(this.rsaId.getDOB()).toDateString()}` : '';
        } catch (error) {
            log.error(error);
        }
    }

    get getProductHint(): string {
        try {
            const product = this.formGroup.get('product').value;

            return !isNullOrUndefined(product) && !isNullOrUndefined(this.productDescriptions[product]) ? this.productDescriptions[product] : null;
        } catch (error) {
            log.error(error);
        }
    }

    get getLeadName(): string {
        try {
            return this.data.leadName;
        } catch (error) {
            log.error(error);
        }
    }

    public save(): void {
        this.isBusy = true;

        const data = new LLCModalData();

        DLUtil.copyFields(this.data, data);

        data.dependant = new LLCDependantVO();

        DLUtil.copyFields(this.data.dependant, data.dependant);
        DLUtil.copyFields(this.formGroup.value, data.dependant);

        data.dependant.benefit_amount = this.benefit_amount;
        data.dependant.premium_amount = this.premium_amount;
        data.dependant.product_master_id = this.product_master_id;
        data.dependant.pricing = this.pricing;
        data.dependant.age = this.rsaId.getAge();

        this.dialogRef.close(data);
    }

    public cancel(): void {
        try {
            this.dialogRef.close('cancel');
        } catch (error) {
            log.error(error);
        }
    }

    public getDisabledText(item: DropDownValuesVO) {
        try {
            return item.disabled && !isNullOrUndefined(MLFUtil.validation[item.id]) ? `(Max: ${MLFUtil.validation[item.id]})` : '';
        } catch (error) {
            log.error(error);
        }
    }

    public formatDisplay(value: number | null): string {
        try {
            return DLUtil.compactFormat(value, false);
        } catch (error) {
            log.error(error);
        }
    }

    public updateCover(change: MatSliderChange): void {
        try {
            this.benefit_amount = change.value;
            this.premium_amount = this.findPremiumForCover(change.value);

            this.formGroup.markAsDirty();
        } catch (error) {
            log.error(error);
        }
    }

    public findPremiumForCover(cover: number): number {
        let premium = 0;

        this.sliderPoints.forEach((option: { cover: number, premium: number }) => {
            if (cover === option.cover) {
                premium = option.premium;
            }
        });

        return premium;
    }

    public onIdValidationChange(rsaId: RsaId): void {
        if (!isNullOrUndefined(this.formGroup) &&
            !isNullOrUndefined(this.formGroup.get('product')) &&
            !isNullOrUndefined(this.formGroup.get('product').value) &&
            !isNullOrUndefined(this.formGroup.get('gender').value)) {
            const product = this.formGroup.get('product').value as string;
            const gender = this.formGroup.get('gender').value as string;

            this.changeEvents$.emit(new ChangeEvent(rsaId, product, gender));
        }
    }

    public fetchCoverOptions(rsaId: RsaId, product: string, inGender: string = null) {
        let age:number = 0;
        let gender: string = null;

        if (!this.data.main) {
            if (isNullOrUndefined(product) || product.length === 0) {
                log.info('no product selected');

                this.coverOptions$.next(new CoverOptionsVO('invalid'));

                return;
            }
            if (isNullOrUndefined(rsaId.getId()) || (rsaId.getId().length > 8 && !rsaId.isValid())) {
                log.info('id is invalid');
                
                this.coverOptions$.next(new CoverOptionsVO('invalid'));

                return;
            }
            if (!rsaId.isValidDOB() || isNullOrUndefined(product)) {
                log.info('dob is invalid or no product');
                
                this.coverOptions$.next(new CoverOptionsVO('invalid'));

                return;
            }

            age = rsaId.getAge();

            gender = isNullOrUndefined(rsaId.getGender()) ? inGender : rsaId.getGender() === 'Male' ? 'M' : 'F';

            if (isNullOrUndefined(gender)) {
                log.info('gender is invalid');
                
                this.coverOptions$.next(new CoverOptionsVO('invalid'));

                return;
            }

            if (this.checkDuplicate(false)) {
                log.info('');

                return;
            }

        }

        this.isValid = false;

        this.formGroup.disable({ onlySelf: true, emitEvent: false });
        this.coverOptions$.next(new CoverOptionsVO('loading'));

        const req: MLFSimplePricingRequestVO = {
            spId: this.data.spId,
            benefitId: this.data.benefitId,
            product: product,
            age: age,
            gender: gender
        };

        this.summaryService.getPricing(req, 'LLC').subscribe((res) => {
            this.updateCoverOptions(rsaId, product, res);

            this.formGroup.enable({onlySelf: true, emitEvent: false});
        }, (error: any) => {
            this.formGroup.enable({onlySelf: true, emitEvent: false});

            const coverOptions = new CoverOptionsVO('invalid');
            
            coverOptions.error = ErrorVO.toErrorVO(error).message;

            this.coverOptions$.next(coverOptions);
        });
    }

    public updateCoverOptions(rsaId: RsaId, product: string, res: CoverOptionsVO) {
        if (isNullOrUndefined(res)) {
            return;
        }

        if (res.hasOwnProperty('error')) {
            const coverOptions = new CoverOptionsVO('invalid');
            coverOptions.error = res.error;
            this.isValid = false;
            this.coverOptions$.next(coverOptions);
        } else {
            res.status = 'valid';

            if (!isNullOrUndefined(this.benefit_amount) && this.benefit_amount < res.max) {
                res.cover = this.benefit_amount;
            } else {
                res.cover = res.max;
            }

            this.sliderPoints = res.options;
            this.benefit_amount = res.cover;
            this.premium_amount = this.findPremiumForCover(res.cover);
            this.product_master_id = res.product_master_id;
            this.pricing = res.pricing;
            this.isValid = true;
            this.coverOptions$.next(res);
        }
    }

    private checkDuplicate(fetch: boolean): boolean {
        let isDuplicate: boolean = false;
        
        this.data.dependants.forEach((dependant: MLFDependantVO )=> {
            if (this.data.dependant.id !== dependant.id) {
                if (dependant.first_name === this.formGroup.get('first_name').value &&
                    dependant.last_name === this.formGroup.get('last_name').value &&
                    dependant.id_or_dob === this.formGroup.get('id_or_dob').value &&
                    dependant.gender === this.formGroup.get('gender').value) {

                    isDuplicate = true;
                    this.isValid = false;

                    const coverOptions = new CoverOptionsVO('invalid');

                    coverOptions.error = 'Duplicate';

                    this.coverOptions$.next(coverOptions);
                }
            }
        });

        if (!isDuplicate && fetch && this.previouslyDuplicate) {
            const coverOptions = new CoverOptionsVO('invalid');

            this.coverOptions$.next(coverOptions);

            const rsaId = new RsaId();

            rsaId.setId(this.formGroup.get('id_or_dob').value);

            const product = this.formGroup.get('product').value;
            const gender = this.formGroup.get('gender').value;

            this.fetchCoverOptions(rsaId, product, gender);
        }

        this.previouslyDuplicate = isDuplicate;

        return isDuplicate;
    }

    public productSelected(): void {
        const rsaId = new RsaId();

        rsaId.setId(this.formGroup.get('id_or_dob').value);
        
        const gender = this.formGroup.get('gender').value;
        const product = this.formGroup.get('product').value;

        this.changeEvents$.emit(new ChangeEvent(rsaId, product, gender));
    }

    genderChanged(): void {
        const rsaId = new RsaId();

        rsaId.setId(this.formGroup.get('id_or_dob').value);

        let gender = this.formGroup.get('gender').value;

        if (!isNullOrUndefined(rsaId.getGender())) {
            gender = rsaId.getGender() === 'Male' ? 'M' : 'F';
        }

        if (gender !== this.formGroup.get('gender').value) {
            // Flip it back to what ID number says...
            this.formGroup.get('gender').setValue(gender);

            return;
        }

        const product = this.formGroup.get('product').value;
        
        this.changeEvents$.emit(new ChangeEvent(rsaId, product, gender));
    }
}

class ChangeEvent {
    rsaId: RsaId;
    product: string;
    gender: string;

    constructor(rsaId: RsaId, product: string, gender: string) {
        this.rsaId = rsaId;
        this.product = product;
        this.gender = gender;
    }
}