import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CloseEventData, InputData, Lead, PostData } from '../qq.vo';
import { QQService } from '../qq.service';
import { WindowRef } from '../../base/window.ref';
import { MatchMediaService } from '../../base/match.media.service';
import { MatDialogRef } from '@angular/material';
import { DLUtil } from '../../base/dl.util';
import { PhoneNumberValidator } from '../../base/phone-number';
import { OrgResultBlockComponent } from '../dl.org.result.block.component';
import { SGResultBlockComponent } from '../sg.result.block.component';
import { isNullOrUndefined } from 'util';
import { BenefitCalculator } from '../../base/benefit.calculator';
var QqResultNoSpComponent = /** @class */ (function () {
    function QqResultNoSpComponent(qqService, winRef, matchMediaService, fb, dialogRef) {
        this.qqService = qqService;
        this.winRef = winRef;
        this.matchMediaService = matchMediaService;
        this.fb = fb;
        this.dialogRef = dialogRef;
        this.componentData = null;
        this.noEmail = false;
        this.success = false;
        this.qqForm = this.fb.group({
            fullname: [null, Validators.required],
            email: [null, Validators.compose([Validators.email, Validators.pattern(DLUtil.emailPattern)])],
            cell: [null, Validators.compose([Validators.required, PhoneNumberValidator.validator()])],
            comments: [null]
        });
        // instantiate Benefit. or we get a js error because of AOT
        this.inputData = new InputData();
        // set a few defaults
        this.resLoading = false;
        this.resShowWarning = false;
    }
    QqResultNoSpComponent.prototype.ngOnInit = function () {
    };
    QqResultNoSpComponent.prototype.calculateInitialValues = function (qqPricingRequestData) {
        this.qqPricingRequestData = qqPricingRequestData;
        this.benefit.escalationFactor = 1;
        this.benefit.coverEscalationFactor = 1;
        this.benefit.discountRate = 0;
        // set sum assured to 4% of income
        this.benefit.cover = -(((qqPricingRequestData.income_gross_monthly * 0.04)
            * this.benefit.denominatorFactor) + this.benefit.numeratorFactor)
            / this.benefit.multiplierFactor;
        // for DC and CI30
        if (this.benefit.code !== 'LC') {
            this.benefit.cover = -(((qqPricingRequestData.income_gross_monthly * 0.02)
                * this.benefit.denominatorFactor) + this.benefit.numeratorFactor)
                / this.benefit.multiplierFactor;
        }
        // round up to the nearest increment
        this.benefit.cover = Math.ceil(this.benefit.cover / this.benefit.coverIncrements) * this.benefit.coverIncrements;
        // for SP90
        if (this.benefit.code === 'SP90') {
            this.benefit.cover = this.benefit.maxCover;
        }
        // limit cover to within min/max range
        if (this.benefit.maxCover < this.benefit.cover) {
            this.benefit.cover = this.benefit.maxCover;
        }
        if (this.benefit.minCover > this.benefit.cover) {
            this.benefit.cover = this.benefit.minCover;
        }
        this.benefit.premium = BenefitCalculator.calc('primaryPremiumNoDiscount', this.benefit);
    };
    QqResultNoSpComponent.prototype.openResult = function (inputData, qqRequestData, idNumber, qqPricingResponse, product, qqContinue) {
        this.inputData = inputData;
        this.idNumber = idNumber;
        this.qqContinue = qqContinue;
        this.qqForm.reset();
        this.resShowWarning = false;
        this.benefit = qqPricingResponse;
        this.benefit.code = product.value;
        this.benefit.name = product.label;
        this.benefit.coverOrPremium = 'Cover';
        this.calculateInitialValues(qqRequestData);
        if (this.inputData.securityGroup === 'DL') {
            this.componentData = {
                component: OrgResultBlockComponent,
                inputs: {
                    benefit: this.benefit,
                    cause: this.inputData.causeText
                }
            };
        }
        else {
            this.componentData = {
                component: SGResultBlockComponent,
                inputs: {
                    benefit: this.benefit,
                    cause: ''
                }
            };
        }
    };
    QqResultNoSpComponent.prototype.closeResultModal = function () {
        this.resetButton();
        this.dialogRef.close(CloseEventData.createCancelEvent());
    };
    QqResultNoSpComponent.prototype.postToContinue = function (force, type) {
        var _this = this;
        var postData = new PostData();
        postData.age = this.qqPricingRequestData.starting_age_year;
        postData.gender = this.qqPricingRequestData.gender;
        postData.income = this.qqPricingRequestData.income_gross_monthly;
        postData.education = this.qqPricingRequestData.education;
        postData.smoker_status = this.qqPricingRequestData.smoker;
        postData.pricing_engine_version = this.benefit.pricing_engine_version;
        postData.product_code = this.inputData.productCode;
        postData.product_group = this.qqPricingRequestData.product_group;
        postData.qq_product = this.qqPricingRequestData.product_code;
        postData.campaign_code = this.inputData.campaignCode;
        postData.referrer = this.inputData.httpReferrer;
        postData.referrer_code = this.inputData.referrerCode;
        postData.quickquote_source = this.inputData.source;
        postData.force = force;
        postData.is_external_qq = true;
        if (!this.inputData.embedded) {
            postData.embedded = false;
            var lead = new Lead(this.qqForm.get('fullname').value, this.qqForm.get('email').value, this.qqForm.get('cell').value);
            lead.setLeadType(type);
            postData.comments = this.qqForm.get('comments').value;
            postData.lead = lead;
            postData.optionality = this.inputData.optionality;
            postData.lead_capture = this.inputData.leadCapture;
        }
        else {
            postData.embedded = true;
            postData.idnumber = this.idNumber;
        }
        // postData.benefits = [this.benefit];
        postData.benefits = [];
        postData.benefits.push({
            id: this.benefit.needs_benefit_id,
            code: this.benefit.code,
            cover: this.benefit.cover,
            premium: this.benefit.premium,
            minCover: this.benefit.minCover,
            maxCover: this.benefit.maxCover,
            term: this.benefit.term
        });
        postData.leadid = this.inputData.leadId;
        if (!isNullOrUndefined(this.inputData.spId)) {
            postData.spid = this.inputData.spId;
        }
        else if (!isNullOrUndefined(this.winRef.nativeWindow.spid)) {
            postData.spid = this.winRef.nativeWindow.spid;
        }
        this.qqService.qqExternal(postData).subscribe(function (res) { return _this.handleQQResponse(res); }, function () { return _this.handleQQResponseError(); });
    };
    /**
     * continue with the submit after showing the warning.
     */
    QqResultNoSpComponent.prototype.continueLife = function () {
        this.setButtonForLoading();
        this.postToContinue(true, '');
        this.qqForm.reset();
    };
    /**
     * continue with the submit after showing the warning.
     */
    QqResultNoSpComponent.prototype.continueFuneral = function () {
        this.setButtonForLoading();
        this.postToContinue(true, 'mlf');
        this.qqForm.reset();
    };
    QqResultNoSpComponent.prototype.handleQQResponse = function (response) {
        if (response.success === false || response.success !== 'true') {
            this.resShowWarning = true;
            this.resetButton();
            return;
        }
        // lead capture close
        if (this.inputData.leadCapture) {
            this.dialogRef.close(CloseEventData.createCancelEvent('A Lead was successfully created'));
            return;
        }
        // if mobile show warning
        if (this.matchMediaService.is(['sm', 'xs']) && !this.inputData.embedded) {
            this.dialogRef.close(CloseEventData.createMobileWarningEvent(response));
            return;
        }
        // Showing success message
        this.handleQQResponseSuccess();
    };
    QqResultNoSpComponent.prototype.resetButton = function () {
        this.resLoading = false;
    };
    QqResultNoSpComponent.prototype.setButtonForLoading = function () {
        this.resLoading = true;
    };
    QqResultNoSpComponent.prototype.handleQQResponseSuccess = function () {
        this.resetButton();
        this.success = true;
    };
    QqResultNoSpComponent.prototype.handleQQResponseError = function () {
        this.resetButton();
        this.dialogRef.close(CloseEventData.createErrorEvent('Failed to continue your Quote :('));
    };
    QqResultNoSpComponent.prototype.setNoEmail = function () {
        this.noEmail = !this.noEmail;
        if (this.noEmail) {
            this.qqForm.get('email').setValue('');
            this.qqForm.get('email').disable();
        }
        else {
            this.qqForm.get('email').enable();
        }
    };
    Object.defineProperty(QqResultNoSpComponent.prototype, "busy", {
        get: function () {
            return !this.inputData.embedded && (this.qqForm.invalid || this.qqForm.pristine || this.resLoading);
        },
        enumerable: true,
        configurable: true
    });
    return QqResultNoSpComponent;
}());
export { QqResultNoSpComponent };
