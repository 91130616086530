<div class="dl-disclosure" id="disclosures-modal" cdk-trap-focus>
    <div mat-dialog-title class="dl-modal-header">
        <h4 class="dl-modal-title">{{title}}</h4>
    </div>
    <mat-dialog-content>
        <div class="dl-modal-body">
            <div *ngIf="!disclosures" class="dl-loading">
                <span class="fa fa-circle-o-notch fa-spin"></span>
            </div>
            <dl-disclosures #disclosuresView [disclosures]="disclosures"></dl-disclosures>
        </div>
        <div class="dl-modal-footer">
            <div class="dl-row row">
                <div class="dl-col-md-4 col s12 m4">
                    <button
                            mat-raised-button
                            *ngIf="showStop()"
                            id="disclosures-modal-exit"
                            color="warn"
                            class="dl-full-width dl-action-btn"
                            tabindex="2"
                            [disabled]="busy"
                            [attr.data-event]="'true'"
                            [attr.data-event-category]="category"
                            [attr.data-event-name]="'Disclosure Stop'"
                            [attr.data-event-label]="category + ' Disclosure Stop - ' + page + type"
                            (click)="stopAndExit()">
                        Stop application
                        <i *ngIf="stopping" class="fa fa-circle-o-notch fa-spin"></i>
                    </button>
                    <button
                            mat-raised-button
                            *ngIf="showClose()"
                            id="disclosures-modal-close"
                            color="default"
                            class="dl-full-width dl-action-btn"
                            tabindex="2"
                            [disabled]="busy"
                            [attr.data-event]="'true'"
                            [attr.data-event-category]="category"
                            [attr.data-event-name]="'Disclosure Close'"
                            [attr.data-event-label]="category + ' Disclosure Close - ' + page + type"
                            (click)="close()">
                        Close
                        <i *ngIf="stopping" class="fa fa-circle-o-notch fa-spin"></i>
                    </button>
                </div>
                <div class="dl-col-md-4 col s12 m4"></div>
                <div class="dl-col-md-4 col s12 m4">
                    <button
                            mat-raised-button
                            id="disclosures-modal-submit"
                            color="primary"
                            class="dl-full-width dl-action-btn"
                            [disabled]="!canSave"
                            [attr.data-event]="'true'"
                            [attr.data-event-category]="category"
                            [attr.data-event-name]="'Disclosure Continue'"
                            [attr.data-event-label]="category + ' Disclosure Continue - ' + page + type"
                            (click)="saveDisclosures()">
                        Continue&nbsp;
                        <i *ngIf="!saving" class="fa fa-chevron-right"></i>
                        <i *ngIf="saving" class="fa fa-circle-o-notch fa-spin"></i>
                    </button>
                </div>
            </div>
        </div>
    </mat-dialog-content>
</div>
