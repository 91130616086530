import {Dynamic} from './component';
import {FormControl} from '@angular/forms';
import {Component} from '@angular/core';
import {WindowRef} from '../../base/window.ref';
import { PageComponentVO } from '../page.data.vo';

@Component({
    template: `<a mat-raised-button
                  tabindex="1"
                  class='mat-primary dl-full-width dl-action-btn'
                  href='{{component.value}}'
                  (click)='click()'>{{component.name}}</a>`,
    selector: 'link-button',
})
export class LinkButtonComponent extends Dynamic<PageComponentVO> {

    constructor(private winRef: WindowRef) {
        super();
    }

    setupFromControl() {
        this.component.loaded = true;
        // tel my parent I'm loaded.
        this.loaded.emit(this.component.id);
    }

    getFormControl(): FormControl {
        return null;
    }

    onChange(): void {
    }

    click() {
        try {
            this.winRef.nativeWindow.displayWarning = false;
        } catch (e) {
            console.log('Cant switch of page load check');
        }
        return true;
    }
}
