import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {minMaxValidation} from '../base/min.max.validator';
import {BaseDropDownValues} from '../base/base.dropdown.values';
import {isNullOrUndefined} from 'util';
import {DependentType, NeedsDataVO, NeedsDependentVO, ProductGroupRequestVO} from './needs.vo';
import {NeedsService} from './needs.service';
import {SelectItem} from '../base/select.vo';
import {DisclosuresProvider} from '../base/disclosures/disclosures.provider';
import {DisclosureCategory, DisclosureType, DisclosureVO} from '../base/disclosures/disclosures.vos';
import {DisclosuresCallback} from '../base/disclosures/disclosures-modal.component';
import {Log} from 'ng2-logger/browser';
import {MatDialog, MatDialogRef} from '@angular/material';
import {NeedsResultsComponent} from './needs.results.component';
import {DLUtil} from '../base/dl.util';
import {PhoneNumberValidator} from '../base/phone-number';

const log = Log.create('NeedsComponent');

@Component({
    templateUrl: './needs.component.html',
    styleUrls: ['./needs.component.scss']
})
export class NeedsComponent implements OnInit, DisclosuresCallback {

    private _ptyName: string;
    private _title = 'Insurance Calculator';
    private _campaignCode: string = null;
    private _referrerCode: string = null;
    private _httpReferrer: string;
    private _source: string = null;
    private _buttonText = 'Calculate';
    private _leadId: string = null;

    @Input('pty-name')
    set ptyName(val: string) {
        this._ptyName = val;
    }
    get ptyName(): string {
        return this._ptyName;
    }
    @Input()
    set title(val: string) {
        this._title = val;
    }
    get title(): string {
        return this._title;
    }
    @Input('campaign-code')
    set campaignCode(val: string) {
        this._campaignCode = val;
    }
    get campaignCode(): string {
        return this._campaignCode;
    }
    @Input('referrer-code')
    set referrerCode(val: string) {
        this._referrerCode = val;
    }
    get referrerCode(): string {
        return this._referrerCode;
    }
    @Input('http-referrer')
    set httpReferrer(val: string) {
        this._httpReferrer = val;
    }
    get httpReferrer(): string {
        return this._httpReferrer;
    }
    @Input()
    set source(val: string) {
        this._source = val;
    }
    get source(): string {
        return this._source;
    }
    @Input('button-text')
    set buttonText(val: string) {
        this._buttonText = val;
    }
    get buttonText(): string {
        return this._buttonText;
    }
    @Input('lead-id')
    set leadId(val: string) {
        this._leadId = val;
    }
    get leadId(): string {
        return this._leadId;
    }

    needsForm: FormGroup;
    dependentsForm: FormGroup = null;
    numMask: any = DLUtil.numMask;
    educationValues: SelectItem[] = BaseDropDownValues.getEducationValues();
    smokerValues: SelectItem[] = [
        {label: 'Smoker', value: 'yes'},
        {label: 'Non Smoker', value: 'no'}
    ];
    homeLoanValues: SelectItem[] = [
        {label: 'Home Loan', value: 'yes'},
        {label: 'No Home Loan', value: 'no'}
    ];
    spouseValues: SelectItem[] = [
        {label: 'Partner', value: 'yes'},
        {label: 'No Partner', value: 'no'}
    ];
    genderValues: SelectItem[] = BaseDropDownValues.getGenderValues();
    dependentsValues: SelectItem[] = [];
    hasSpouse = false;
    dependentCount = 0;
    dependentsNumber: number[] = [];
    loading = false;
    disclosuresShown = false;
    private needsResults: MatDialogRef<NeedsResultsComponent>;
    starting = true;
    message: string;

    constructor(private fb: FormBuilder,
                private needsService: NeedsService,
                private disclosureProvider: DisclosuresProvider,
                private dialog: MatDialog) {
        this.init();
        this.createFrom();
    }

    private createFrom(): void {
        this.needsForm = this.fb.group({
            name: [''],
            email: ['', [Validators.email, Validators.pattern(DLUtil.emailPattern)]],
            cell: ['', [PhoneNumberValidator.validator()]],
            age: ['', [Validators.required, minMaxValidation(16, 64)]],
            spouse: ['', Validators.required],
            education: ['', Validators.required],
            smoke: ['', Validators.required],
            gender: ['', Validators.required],
            homeLoan: ['', Validators.required],
            income: ['', Validators.required],
            dependents: [this.dependentsValues[0].value, Validators.required],
            spouseAge: [''],
            spouseIncome: [''],
            acceptTerms: [false, Validators.requiredTrue]
        });

        // add listeners on the form controllers.
        this.needsForm.get('spouse').valueChanges.subscribe(() => this.onSpouseChange());
        this.needsForm.get('dependents').valueChanges.subscribe(() => this.updateDependentForm());
    }

    private init(): void {
        this.dependentsValues.push({label: 'None', value: 0});
        for (let i = 1; i <= 5; i++) {
            this.dependentsValues.push({label: '' + i, value: i});
        }
    }

    ngOnInit() {
        const productGroupReq = new ProductGroupRequestVO();
        productGroupReq.lead_id = isNullOrUndefined(this.leadId) ? '' : this.leadId;
        productGroupReq.campaign_code = isNullOrUndefined(this.campaignCode) ? '' : this.campaignCode;
        this.needsService.resolveProductGroup(productGroupReq).subscribe((res) => {
            log.info('Product group loaded ' + res.productGroupCode);
            this.starting = false;
            if (res.message != null) {
                this.message = res.message;
            }
        });
        this.needsService.ptyName = this.ptyName;
    }

    private onSpouseChange(): void {
        if (this.needsForm.get('spouse').value === 'yes') {
            this.hasSpouse = true;
            this.needsForm.get('spouseAge').setValidators([Validators.required, minMaxValidation(14, 110)]);
            this.needsForm.get('spouseIncome').setValidators([Validators.required]);
        } else {
            this.hasSpouse = false;
            this.needsForm.get('spouseAge').clearValidators();
            this.needsForm.get('spouseAge').reset();
            this.needsForm.get('spouseIncome').clearValidators();
            this.needsForm.get('spouseIncome').reset();
        }
    }

    private updateDependentForm() {
        this.dependentCount = this.needsForm.get('dependents').value as number;
        if (this.dependentCount === 0) {
            if (!isNullOrUndefined(this.needsForm.get('dependentAges'))) {
                this.needsForm.removeControl('dependentAges');
                this.dependentsForm = null;
            }
            this.dependentsNumber = [];
            return;
        }
        if (isNullOrUndefined(this.needsForm.get('dependentAges'))) {
            this.dependentsForm = this.fb.group({});
            this.needsForm.addControl('dependentAges', this.dependentsForm);
        } else {
            this.dependentsForm = this.needsForm.get('dependentAges') as FormGroup;
        }
        for (let i = 1; i <= 5; i++) {
            if (isNullOrUndefined(this.dependentsForm.get('childAge' + i)) && i <= this.dependentCount) {
                this.dependentsForm.addControl('childAge' + i, new FormControl('', [Validators.required, minMaxValidation(0, 65)]));
            }
            if (i > this.dependentCount && !isNullOrUndefined(this.dependentsForm.get('childAge' + i))) {
                this.dependentsForm.removeControl('childAge' + i);
            }
        }
        this.dependentsNumber = [];
        for (let i = 1; i <= this.dependentCount; i++) {
            this.dependentsNumber.push(i);
        }
    }

    public getAgeErrorMessage(formGroup: FormGroup, element: string) {
        if (formGroup.get(element).hasError('not_a_number')) {
            return 'Not a valid Age';
        } else if (formGroup.get(element).hasError('number_to_small')) {
            return 'Sorry too young';
        } else if (formGroup.get(element).hasError('number_to_big')) {
            return 'Sorry too old';
        }
        return '';
    }

    private populateDataVO(): NeedsDataVO {
        const dataVO = new NeedsDataVO();
        dataVO.age = Number(this.needsForm.get('age').value);
        dataVO.education = this.needsForm.get('education').value;
        if (this.needsForm.get('smoke').value === 'yes') {
            dataVO.smoker = 'S';
        } else {
            dataVO.smoker = 'N';
        }
        dataVO.gender = this.needsForm.get('gender').value;
        dataVO.has_homeloan = this.needsForm.get('homeLoan').value === 'yes';
        dataVO.income = Number(this.needsForm.get('income').value.replace(/\D+/g, ''));
        if (this.needsForm.get('spouse').value === 'yes') {
            // add spouse as a depended.
            const spouse = new NeedsDependentVO();
            spouse.age = Number(this.needsForm.get('spouseAge').value);
            spouse.income = Number(this.needsForm.get('spouseIncome').value.replace(/\D+/g, ''));
            spouse.type = DependentType.PARTNER;
            dataVO.needsDependent.push(spouse);
        }
        if (this.dependentCount > 0) {
            for (let i = 1; i <= this.dependentCount; i++) {
                const child = new NeedsDependentVO();
                child.age = Number(this.dependentsForm.get('childAge' + i).value);
                child.type = DependentType.CHILD;
                dataVO.needsDependent.push(child);
            }
        }
        dataVO.product_group = this.needsService.productGroup;
        dataVO.campaign_id = this.needsService.campaignId;
        this.addLeadData(dataVO);
        return dataVO;
    }

    private addLeadData(needsData: NeedsDataVO): void {

        this.needsService.setLeadData(
            this.needsForm.get('name').value,
            this.needsForm.get('email').value,
            this.needsForm.get('cell').value,
            this.leadId,
            this.httpReferrer,
            this.campaignCode,
            this.referrerCode);

        if (this.hasLeadData()) {
            needsData.lead = this.needsService.leadData;
        }
    }

    private hasLeadData(): boolean {
        if (!isNullOrUndefined(this.needsForm.get('name').value) && this.needsForm.get('name').value !== '') {
            return true;
        }
        if (!isNullOrUndefined(this.needsForm.get('email').value) && this.needsForm.get('email').value !== '') {
            return true;
        }
        if (!isNullOrUndefined(this.needsForm.get('cell').value) && this.needsForm.get('cell').value !== '') {
            return true;
        }
        if (!isNullOrUndefined(this.leadId) && this.leadId !== '') {
            return true;
        }
        return false;
    }

    public calculateNeeds(): void {
        this.loading = true;
        const dataToSend = this.populateDataVO();
        console.log(dataToSend);
        this.needsService.getNeedsInfo(dataToSend).subscribe(res => this.handleNeedsResult(res), () => this.resetButtons());
    }

    private resetButtons(): void {
        this.loading = false;
    }

    private handleNeedsResult(res: any) {
        console.log(res);
        if (!this.disclosuresShown) {
            this.disclosureProvider.showDisclosure(
                'A few things to keep in mind',
                'calculator',
                DisclosureCategory.CALCULATOR,
                null,
                DisclosureType.INFO,
                this);
        } else {
            this.resetButtons();
            this.showResults();
        }
        // open the needs dialog after this disclosures is closed. if this is a send attempt show the results.

    }

    private showResults(): void {
        log.info('Opening the results');
        this.needsResults = this.dialog.open(NeedsResultsComponent, {
            disableClose: true,
            position: {top: '20px'},
            panelClass: 'dl-modal',
            maxWidth: '99vw'
        });
        this.needsResults.afterClosed().subscribe(() => this.resetFormData());
    }

    private resetFormData() {
        this.needsForm.reset();
        this.disclosuresShown = false;
        this.needsService.clearResults();
        this.resetButtons();
    }

    onDisclosuresClosed(): void {
        this.disclosuresShown = true;
        this.showResults();
    }

    onDisclosuresClosing(disclosures: DisclosureVO[]): boolean {
        return false;
    }

    onDisclosuresLoaded(): void {
        this.resetButtons();
    }

    onDisclosuresSaved(disclosures: DisclosureVO[]): void {
    }

    onDisclosuresFailed(): void {
        this.resetButtons();
    }

}
