/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i2 from "@angular/material/button";
import * as i3 from "@angular/cdk/platform";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "@angular/common";
import * as i7 from "./alt.continue.component";
import * as i8 from "./component";
var styles_AltContinueComponent = [];
var RenderType_AltContinueComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AltContinueComponent, data: {} });
export { RenderType_AltContinueComponent as RenderType_AltContinueComponent };
function View_AltContinueComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-circle-o-notch fa-spin"]], null, null, null, null, null))], null, null); }
export function View_AltContinueComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "button", [["class", "dl-full-width dl-action-btn dl-alternate"], ["mat-raised-button", ""], ["tabindex", "1"]], [[1, "data-name", 0], [1, "data-event", 0], [1, "data-event-category", 0], [1, "data-event-name", 0], [1, "data-event-label", 0], [8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MatButton_0, i1.RenderType_MatButton)), i0.ɵdid(1, 180224, null, 0, i2.MatButton, [i0.ElementRef, i3.Platform, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, null), (_l()(), i0.ɵted(2, 0, [" ", "\u00A0"])), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_AltContinueComponent_1)), i0.ɵdid(4, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = (_co.isDisabled || _co.isBusy); _ck(_v, 1, 0, currVal_7); var currVal_9 = _co.isBusy; _ck(_v, 4, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.component.id; var currVal_1 = "true"; var currVal_2 = ("DSP-" + _co.productCode); var currVal_3 = (_co.pageName + " - Continue"); var currVal_4 = ("Continue to " + _co.component.value); var currVal_5 = (i0.ɵnov(_v, 1).disabled || null); var currVal_6 = (i0.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = _co.component.name; _ck(_v, 2, 0, currVal_8); }); }
export function View_AltContinueComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "alt-continue-button", [], null, null, null, View_AltContinueComponent_0, RenderType_AltContinueComponent)), i0.ɵdid(1, 180224, null, 0, i7.AltContinueComponent, [i8.ParentEventService], null, null)], null, null); }
var AltContinueComponentNgFactory = i0.ɵccf("alt-continue-button", i7.AltContinueComponent, View_AltContinueComponent_Host_0, { flowId: "flowId", formGroup: "formGroup", component: "component", pageName: "pageName", _productCode: "_productCode", groupId: "groupId", _spMode: "_spMode" }, { change: "change", loaded: "loaded", validated: "validated", resume: "resume", altContinue: "altContinue" }, []);
export { AltContinueComponentNgFactory as AltContinueComponentNgFactory };
