import { Injector } from '@angular/core';
import { QqComponent } from './qq/qq.component';
import { createCustomElement } from '@angular/elements';
import { ResetPasswordComponent } from './login/reset.password.component';
import { ContactBlockComponent } from './contact-block/contact-block.component';
import { LoginComponent } from './login/login.component';
import { LocaleHelper } from './base/locale.helper';
import { NeedsComponent } from './needs/needs.component';
import { DonutComponent } from './donut/donut.component';
import { ContactBlockExpandedComponent } from './contact-block/contact-block-expanded/contact-block-expanded.component';
import { LimitedLifeQuickQuoteComponent } from './sp/limited-life-cover-qq/limited-life-quick-quote.component';
var ɵ0 = function () { return LocaleHelper.getCurrentLocale(); };
var DlElementsModule = /** @class */ (function () {
    function DlElementsModule(injector) {
        this.injector = injector;
        console.log('DlElementsModule');
        LocaleHelper.registerLocales();
    }
    DlElementsModule.prototype.ngDoBootstrap = function () {
        console.log('DlElementsModule ngDoBootstrap');
        var QQElement = createCustomElement(QqComponent, { injector: this.injector });
        customElements.define('quick-quote', QQElement);
        var CbElement = createCustomElement(ContactBlockComponent, { injector: this.injector });
        customElements.define('contact-block', CbElement);
        var CbExpandedElement = createCustomElement(ContactBlockExpandedComponent, { injector: this.injector });
        customElements.define('contact-block-expanded', CbExpandedElement);
        var loginElement = createCustomElement(LoginComponent, { injector: this.injector });
        customElements.define('dl-login', loginElement);
        var resetPasswordElement = createCustomElement(ResetPasswordComponent, { injector: this.injector });
        customElements.define('dl-reset-password', resetPasswordElement);
        var needsElement = createCustomElement(NeedsComponent, { injector: this.injector });
        customElements.define('needs-calculator', needsElement);
        var donutElement = createCustomElement(DonutComponent, { injector: this.injector });
        customElements.define('donut-graph', donutElement);
        var llcQQElement = createCustomElement(LimitedLifeQuickQuoteComponent, { injector: this.injector });
        customElements.define('limited-life-quick-quote', llcQQElement);
    };
    return DlElementsModule;
}());
export { DlElementsModule };
export { ɵ0 };
