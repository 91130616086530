var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Dynamic } from './component';
import { MatDialog } from '@angular/material';
import { DotOrgModalComponent } from '../dot-org-modal/dot-org-modal.component';
import { isNullOrUndefined } from 'util';
import { DspService } from '../dsp.service';
var DotOrgComponent = /** @class */ (function (_super) {
    __extends(DotOrgComponent, _super);
    function DotOrgComponent(dialog, dspService) {
        var _this = _super.call(this) || this;
        _this.dialog = dialog;
        _this.dspService = dspService;
        return _this;
    }
    DotOrgComponent.prototype.showModal = function () {
        var _this = this;
        this.dialogRef = this.dialog.open(DotOrgModalComponent, {
            data: {
                title: 'Choose a charity',
                area: 'UK'
            },
            panelClass: ['dl-modal', 'dot-org-modal'],
            position: { top: '10px' },
            maxWidth: '98vw'
        });
        this.dialogRef.afterClosed().subscribe(function (res) {
            if (!isNullOrUndefined(res) && res !== false) {
                _this.selectedProject = res;
                _this.component.value = _this.selectedProject.id;
                _this.dspService.currentData.continueText = 'Continue';
            }
        });
        return false;
    };
    DotOrgComponent.prototype.setupFromControl = function () {
        this.component.loaded = true;
        // tel my parent I'm loaded.
        this.loaded.emit(this.component.id);
    };
    DotOrgComponent.prototype.getFormControl = function () {
        return undefined;
    };
    DotOrgComponent.prototype.onChange = function () {
    };
    DotOrgComponent.prototype.processExtraData = function () {
        if (!isNullOrUndefined(this.component.extraInput)) {
            this.orgVO = this.component.extraInput;
        }
    };
    Object.defineProperty(DotOrgComponent.prototype, "amount", {
        get: function () {
            if (!isNullOrUndefined(this.orgVO) && !isNullOrUndefined(this.orgVO.premium)) {
                return this.orgVO.premium;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DotOrgComponent.prototype, "projectName", {
        get: function () {
            if (!isNullOrUndefined(this.orgVO) && !isNullOrUndefined(this.orgVO.projectName)) {
                return this.orgVO.projectName;
            }
            if (!isNullOrUndefined(this.selectedProject)) {
                return this.selectedProject.projectName;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DotOrgComponent.prototype, "toolTip", {
        get: function () {
            return 'Each year we donate one of your premiums to a charitable cause. So while you look after your loved ones ' +
                'you also look after others. Different Life lets you choose your good cause.';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DotOrgComponent.prototype, "clientName", {
        get: function () {
            if (!isNullOrUndefined(this.orgVO) && !isNullOrUndefined(this.orgVO.clientName)) {
                return this.orgVO.clientName;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    return DotOrgComponent;
}(Dynamic));
export { DotOrgComponent };
