import { Component, OnInit } from '@angular/core';
import {MatDialogRef, MatSliderChange, MatTabChangeEvent} from '@angular/material';
import {NeedsService} from './needs.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {isNullOrUndefined} from 'util';
import {Log} from 'ng2-logger/browser';
import {LeadDataVO, NeedsBreakdown, NeedsSubmission} from './needs.vo';
import {minMaxValidation} from '../base/min.max.validator';
import {WindowRef} from '../base/window.ref';
import {DLUtil} from '../base/dl.util';
import {PhoneNumberValidator} from '../base/phone-number';

const log = Log.create('NeedsComponent');

@Component({
    selector: 'needs.results',
    templateUrl: './needs.results.component.html',
    styleUrls: ['./needs.results.component.scss']
})
export class NeedsResultsComponent implements OnInit {

    leadForm: FormGroup;
    schoolForm: FormGroup;
    numMask: any = DLUtil.numMask;

    sendEmailText = 'Email me this info';
    loading = false;
    sendingEmail = false;
    emailSent = false;
    updating = false;
    activeTab = 0;
    assumptionsChanged = false;
    resShowWarning = false;
    resLoading = false;

    constructor(
        public dialogRef: MatDialogRef<NeedsResultsComponent>,
        public needsService: NeedsService,
        private fb: FormBuilder,
        private windowRef: WindowRef) {
        this.leadForm = fb.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email, Validators.pattern(DLUtil.emailPattern)]],
            cell: ['', [Validators.required, PhoneNumberValidator.validator()]]
        });
        this.schoolForm = fb.group({});
    }


    ngOnInit() {
        if (!isNullOrUndefined(this.needsService.leadData)) {
            if (!isNullOrUndefined(this.needsService.leadData.first_name)) {
                let name = this.needsService.leadData.first_name;
                if (!isNullOrUndefined(this.needsService.leadData.last_name)
                    && this.needsService.leadData.last_name !== '') {
                    name += ' ' + this.needsService.leadData.last_name;
                }
                this.leadForm.get('name').setValue(name);
            }
            if (!isNullOrUndefined(this.needsService.leadData.phone_mobile)) {
                this.leadForm.get('cell').setValue(this.needsService.leadData.phone_mobile);
            }
            if (!isNullOrUndefined(this.needsService.leadData.email1)) {
                this.leadForm.get('email').setValue(this.needsService.leadData.email1);
            }
            this.leadForm.markAsDirty();
        }
        if (this.needsService.hasSchoolStage) {
            for (const item of this.needsService.schoolStages) {
                if (isNullOrUndefined(item.amount) || typeof item.amount !== 'number') {
                    item.amount = 0;
                } else {
                    item.amount = Number(item.amount.toFixed(0));
                }
                if (!isNullOrUndefined(this.schoolForm.get(this.getControlName(item.display_name)))) {
                    this.schoolForm.get(this.getControlName(item.display_name)).setValue(item.amount);
                } else {
                    this.schoolForm.addControl(this.getControlName(item.display_name),
                        new FormControl(item.amount, [Validators.required, minMaxValidation(0, 300000)]));
                }
                this.schoolForm.get(this.getControlName(item.display_name)).valueChanges.subscribe(() => this.assumptionsUpdated());
            }
        }
    }

    closeResultModal() {
        this.dialogRef.close();
    }

    rowStyle(code: string): object {
        switch (code) {
            case 'LC':
                return {'background-color': '#006593'};
            case 'SP90':
                return {'background-color': '#E0861A'};
            case 'DC':
                return {'background-color': '#1AA1E0'};
            case 'CI30':
                return {'background-color': '#FFA437'};
            default:
                return {'background-color': '#FFF'};
        }
    }

    showAssumptions() {
        this.activeTab = 1;
    }

    continueToSp(): void {
        log.info('Move to SP');
        this.loading = true;
        this.needsService.continueToSp(this.buildQqData(false)).subscribe((res) => {
            if (res.success === 'false') {
                this.resShowWarning = true;
                this.loading = false;
                return;
            } else {
                setTimeout(() => {
                    this.windowRef.nativeWindow.location = res.url;
                }, 50);
            }
        }, () => {
            this.loading = false;
        });
    }

    private buildQqData(force: boolean): NeedsSubmission {
        const qqData = new NeedsSubmission();
        const needsResult = this.needsService.needsResponse;
        qqData.age = needsResult.age;
        qqData.gender = needsResult.gender;
        qqData.income = needsResult.income;
        qqData.smoker_status = needsResult.smoker;
        qqData.education = needsResult.education;
        const benefit = needsResult.needsBenefits.find(b => b.product_code === 'LC');
        if (!isNullOrUndefined(benefit)) {
            qqData.qq_cover = benefit.benefit_amount;
            qqData.qq_premium = benefit.premium_amount;
        }
        qqData.product_code = 'LC';
        qqData.product_group = this.needsService.productGroup;
        qqData.campaign_id = this.needsService.campaignId;
        qqData.campaign_code = this.needsService.leadData.campaign_code;
        qqData.referrer = this.needsService.leadData.refered_by;
        qqData.referrer_code = this.needsService.leadData.referrer_code;
        qqData.qq_source = this.needsService.leadData.lead_source;
        qqData.lead = this.populateLead('Lead from Life Calculator Continue Button');
        qqData.benefits = this.needsService.needsResponse.needsBenefits;
        qqData.needs_id = this.needsService.needsResponse.id;
        if (force) {
            qqData.force = true;
        }
        return qqData;
    }

    public postToContinue(force: boolean) {
        this.resLoading = true;
        this.needsService.continueToSp(this.buildQqData(force)).subscribe((res) => {
                setTimeout(() => {
                    this.windowRef.nativeWindow.location = res.url;
                }, 50);
            }, () => {
                this.resLoading = false;
            }
        );
    }

    private populateLead(desc: string): LeadDataVO {
        const leadVo = this.needsService.leadData;
        leadVo.lead_source_description = desc;
        this.needsService.splitLeadName(leadVo, this.leadForm.get('name').value);
        leadVo.email1 = this.leadForm.get('email').value;
        leadVo.phone_mobile = this.leadForm.get('cell').value;
        if (!isNullOrUndefined(this.needsService.needsResponse.lead_id)) {
            leadVo.id = this.needsService.needsResponse.lead_id;
        }
        return leadVo;
    }

    sendNeedResult(): void {
        log.info('Send the email');
        this.sendEmailText = 'Sending email';
        this.sendingEmail = true;

        const leadVo = this.populateLead('Lead from Life Calculator Results Email');

        this.needsService.sendCalcEmail(this.needsService.needsResponse.id, leadVo).subscribe(() => {
            this.sendingEmail = false;
            this.emailSent = true;
            this.sendEmailText = 'Email Sent';
        }, () => {
            this.sendingEmail = false;
            this.emailSent = false;
            this.sendEmailText = 'Email me this info';
        });
    }

    tabChanged(tab: MatTabChangeEvent): void {
        log.info('selected tab : ' + tab.index);
        this.activeTab = tab.index;
        if (this.activeTab === 0 && this.assumptionsChanged) {
            this.updating = true;
            if (this.needsService.hasSchoolStage) {
                for (const item of this.needsService.schoolStages) {
                    if (this.schoolForm.get(this.getControlName(item.display_name)).value === '') {
                        item.amount = 0;
                    } else {
                        let amount = this.schoolForm.get(this.getControlName(item.display_name)).value;
                        if (typeof amount !== 'number') {
                            amount = amount.replace(/\D+/g, '');
                        }
                        item.amount = Number(amount);
                    }
                }
            }
            log.info('assumptions have changed, calling service');
            this.needsService.getNeedsInfo(this.needsService.needsResponse).subscribe(
                () => this.dataUpdated(),
                () => this.dataUpdated());
        }
    }

    getControlName(displayName: string): string {
        return NeedsBreakdown.getControlName(displayName);
    }

    updateBreakDownAmount(item: NeedsBreakdown, val: MatSliderChange): void {
        item.amount = val.value;
        this.assumptionsUpdated();
    }

    showResults(): void {
        this.activeTab = 0;
    }

    assumptionsUpdated(): void {
        log.info('mark assumptions as changed');
        this.assumptionsChanged = true;
    }

    dataUpdated(): void {
        this.assumptionsChanged = false;
        this.updating = false;
        if (this.emailSent) {
            this.emailSent = false;
            this.sendEmailText = 'Email me this info';
        }
    }

    formatForDisplay(value: number | null): string {
        return DLUtil.compactFormat(value, true);
    }

}
