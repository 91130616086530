<div id="contact-block" class="cb-from">
  <form [formGroup]="cbForm">
    <div class="cb-well" [ngStyle]="getBackGround()">
      <div *ngIf="!success">
        <div class="top-text">
          <h2 class="text-primary">{{heading1}}&nbsp;</h2>
          <h3 *ngIf="heading2 != ''">{{heading2}}</h3>
        </div>
        <div class="dl-row row">
          <div class="dl-col-sm-12 col s12">
            <mat-form-field class="dl-full-width">
              <input
                matInput
                type="text"
                name="name"
                id="name"
                placeholder="Enter name here"
                [formControl]="cbForm.controls['name']"
                [tabindex]="1">
            </mat-form-field>
          </div>
          <div class="dl-col-sm-12 col s12">
            <mat-form-field class="dl-full-width">
              <input
                matInput
                phoneNumber
                type="text"
                name="phone_mobile"
                id="contact-cell"
                placeholder="Contact Number"
                [formControl]="cbForm.controls['cell']"
                [tabindex]="1">
              <mat-error align="start">Format: 000 000 0000</mat-error>
            </mat-form-field>
          </div>
          <div class="dl-col-sm-12 col s12" *ngIf="showEmail">
            <mat-form-field class="dl-full-width">
              <input
                matInput
                type="email"
                id="email" placeholder="Email"
                name="email"
                [formControl]="cbForm.controls['email']"
                [tabindex]="1">
              <mat-error align="start">Invalid email address</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="dl-row row">
          <div class="dl-col-sm-12 col s12">
            <button
              mat-raised-button
              color="primary"
              class="dl-full-width dl-action-btn"
              id="contact-me"
              type="button"
              tabindex="1"
              style="letter-spacing: 1px;"
              [attr.data-event]="'true'"
              [attr.data-event-category]="'Contact Block'"
              [attr.data-event-name]="'Submit'"
              [attr.data-event-label]="'Contact Block Submit'"
              [disabled]="busy || cbForm.invalid || cbForm.pristine"
              (click)="saveLead()">
              {{buttonText}}&nbsp;&nbsp;<i class='fa fa-circle-o-notch fa-spin' *ngIf="busy"></i>
            </button>
          </div>
        </div>
        <div *ngIf="hasBottomText" class="bottom-text">
          <h3>{{bottomText1}}</h3>&nbsp;<h3 class="text-primary">{{bottomText2}}</h3>
        </div>
      </div>
      <div *ngIf="success" class="thank-you-block">
        <h2 class="text-primary">Thank you&nbsp;</h2>
        <h3>We will be in contact shortly</h3>
      </div>
    </div>

  </form>
</div>
