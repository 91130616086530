var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Log } from 'ng2-logger/browser';
import { isNullOrUndefined } from 'util';
import { AdditionalProductsModalComponent } from '../additional-products/additional-products-modal.component';
import { LlcDependantComponent } from './llc-dependant.component';
import { LLCDependantVO } from './llc-summary.service';
var log = Log.create('LlcSummaryComponent');
var LlcSummaryComponent = /** @class */ (function () {
    function LlcSummaryComponent(dialog) {
        this.dialog = dialog;
        this.add = new EventEmitter();
        this.edit = new EventEmitter();
        this.remove = new EventEmitter();
    }
    Object.defineProperty(LlcSummaryComponent.prototype, "getTotalPremium", {
        get: function () {
            try {
                var total_1 = 0;
                if (!isNullOrUndefined(this.additionalProducts) && !isNullOrUndefined(this.dependants)) {
                    this.additionalProducts.forEach(function (additionalProduct) {
                        if (additionalProduct.selected) {
                            total_1 += additionalProduct.premium;
                        }
                    });
                    this.dependants.forEach(function (dependant) {
                        total_1 += dependant.premium_amount;
                    });
                }
                return total_1;
            }
            catch (error) {
                log.error(error);
            }
        },
        enumerable: true,
        configurable: true
    });
    LlcSummaryComponent.prototype.clickAdd = function (type, addonCode) {
        try {
            type === 'addon'
                ? this.openModal(this.additionalProducts.find(function (additionalProduct) { return additionalProduct.code === addonCode; }), false, false, type)
                : this.openModal(this.dependantProducts, false, false, type);
        }
        catch (error) {
            log.error(error);
        }
    };
    LlcSummaryComponent.prototype.clickRemove = function (id, type) {
        try {
            this.remove.emit({ id: id, type: type });
        }
        catch (error) {
            log.error(error);
        }
    };
    LlcSummaryComponent.prototype.clickEdit = function (dependant, main) {
        this.openModal(this.dependantProducts, true, false, 'LLC_AL', dependant);
    };
    LlcSummaryComponent.prototype.openModal = function (product, editFlag, mainMemberFlag, type, dependant) {
        var _this = this;
        try {
            var data = {
                spId: this.spId,
                benefitId: this.benefitId,
                leadName: this.leadName,
                edit: editFlag,
                main: mainMemberFlag
            };
            data = type === 'addon' ? __assign({}, data, { product: product }) : __assign({}, data, { dependant: !editFlag ? new LLCDependantVO() : dependant, products: product, dependants: this.dependants });
            this.dialogRef = this.dialog.open(type === 'addon' ? AdditionalProductsModalComponent : LlcDependantComponent, {
                disableClose: true,
                panelClass: 'dl-modal',
                maxWidth: '99vw',
                position: { top: '20px' },
                data: data
            });
            this.dialogRef.beforeClose().subscribe(function (res) {
                if (res === 'cancel')
                    return;
                else
                    type === 'addon' ? _this.add.emit({ product: res.product, type: type }) : _this.add.emit({ product: res.dependant, type: type });
                if (res.edit)
                    _this.edit.emit({ main: res.main, dependant: res.dependant });
            });
        }
        catch (error) {
            log.error(error);
        }
    };
    LlcSummaryComponent.prototype.getBadge = function (additionalProduct) {
        try {
            if (!isNullOrUndefined(additionalProduct) && !isNullOrUndefined(additionalProduct.name) && additionalProduct.name.length >= 1)
                return additionalProduct.name.substr(0, 1).toUpperCase();
            if (!isNullOrUndefined(additionalProduct.dependant))
                return 'AL';
            return '?';
        }
        catch (error) {
            log.error(error);
        }
    };
    return LlcSummaryComponent;
}());
export { LlcSummaryComponent };
