import { OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialog, MatSelectChange, MatSliderChange } from "@angular/material";
import { isNullOrUndefined } from "util";
import { DLUtil } from "../base/dl.util";
import { minMaxValidation } from "../base/min.max.validator";
import { RsaIdValidationCallback, RsaId } from "../base/rsa.id.validator";
import { WindowRef } from "../base/window.ref";
import { ContactBlockComponent } from "../contact-block/contact-block.component";
import { QQService } from "./qq.service";
var QqMlfComponent = /** @class */ (function () {
    function QqMlfComponent(data, dialog, winRef, fb, qqService) {
        this.data = data;
        this.dialog = dialog;
        this.winRef = winRef;
        this.fb = fb;
        this.qqService = qqService;
        this.addDependantFlag = false;
        this.cover = 0;
        this.premium = 0;
        this.isLoading = false;
        this.dependantsList = [];
        this.relationshipType = '';
        this.dependantOptions = [];
        this.coverDependant = 0;
        this.premiumDependant = 0;
        this.spouseCount = 0;
        this.childCount = 0;
        this.parentsInLawsCount = 0;
        this.qqMlfFooterFlag = true;
        this.originalDependentOptions = this.dependantOptions;
    }
    QqMlfComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!isNullOrUndefined(this.data.pageInfo)) {
            if (this.data.pageInfo.type === 'mlf' && this.data.pageInfo.name === 'qq-mlf') {
                this.qqMlfFooterFlag = false;
            }
        }
        this.cover = this.data.pricingResponse.options[0].cover;
        this.premium = this.data.pricingResponse.options[0].premium;
        this.dependantsList.push({
            type: 'Main Member',
            cover_amount: this.data.pricingResponse.options[0].cover,
            premium: this.data.pricingResponse.options[0].premium
        });
        this.form = this.fb.group({
            age: ['', [Validators.pattern('[0-9]*'), Validators.required]],
            relationship: ['', Validators.required],
            gender: ['', Validators.required],
        });
        this.form.get('relationship').valueChanges.subscribe(function (relationship) {
            if (relationship === 'MLF_C') {
                _this.form.get('age').clearValidators();
                _this.form.get('age').setValidators([Validators.pattern('[0-9]*'), Validators.required, minMaxValidation(0, 64)]);
            }
            else if (relationship === 'MLF_S') {
                _this.form.get('age').clearValidators();
                _this.form.get('age').setValidators([Validators.pattern('[0-9]*'), Validators.required, minMaxValidation(18, 64)]);
            }
            else {
                _this.form.get('age').clearValidators();
                _this.form.get('age').setValidators([Validators.pattern('[0-9]*'), Validators.required, minMaxValidation(18, 75)]);
            }
        });
    };
    Object.defineProperty(QqMlfComponent.prototype, "minCover", {
        get: function () {
            return this.data.pricingResponse.options[0].cover;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QqMlfComponent.prototype, "maxCover", {
        get: function () {
            var _this = this;
            if (this.dependantOptions.length > 0) {
                var maxCover_1 = this.maxCoverDependant;
                if (this.coverDependant > maxCover_1) {
                    this.coverDependant = maxCover_1;
                    this.dependantOptions.forEach(function (dependentOption) {
                        if (dependentOption.cover === maxCover_1) {
                            _this.premiumDependant = dependentOption.premium;
                        }
                    });
                }
            }
            return this.data.pricingResponse.options[this.data.pricingResponse.options.length - 1].cover;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QqMlfComponent.prototype, "minCoverDependant", {
        get: function () {
            return this.dependantOptions[0].cover;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QqMlfComponent.prototype, "maxCoverDependant", {
        get: function () {
            var _this = this;
            if (this.relationshipType === 'Spouse') {
                this.dependantOptions = this.originalDependentOptions.filter(function (data) {
                    return data.cover <= _this.dependantsList[0].cover_amount;
                });
            }
            return this.dependantOptions[this.dependantOptions.length - 1].cover;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QqMlfComponent.prototype, "increments", {
        get: function () {
            return 5000;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QqMlfComponent.prototype, "getRelationshipType", {
        get: function () {
            return this.relationshipType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QqMlfComponent.prototype, "totalPremium", {
        get: function () {
            var total = 0;
            if (!isNullOrUndefined(this.dependantsList)) {
                this.dependantsList.forEach(function (d) {
                    total += d.premium;
                });
            }
            return total;
        },
        enumerable: true,
        configurable: true
    });
    QqMlfComponent.prototype.formatDisplay = function (value) {
        return DLUtil.compactFormat(value, false);
    };
    QqMlfComponent.prototype.updateCover = function (val) {
        var _this = this;
        this.data.pricingResponse.options.forEach(function (opt) {
            if (val.value === opt.cover) {
                _this.cover = opt.cover;
                _this.premium = opt.premium;
                _this.dependantsList[0].cover_amount = opt.cover;
                _this.dependantsList[0].premium = opt.premium;
            }
        });
    };
    QqMlfComponent.prototype.updateCoverDependant = function (val) {
        var _this = this;
        this.dependantOptions.forEach(function (opt) {
            if (val.value === opt.cover) {
                _this.coverDependant = opt.cover;
                _this.premiumDependant = opt.premium;
            }
        });
    };
    QqMlfComponent.prototype.continue = function () {
        var _this = this;
        setTimeout(function () {
            _this.winRef.nativeWindow.location = '/buy/funeral';
        }, 50);
    };
    QqMlfComponent.prototype.addDependant = function () {
        var _this = this;
        var result = this.dependantsList.filter(function (dependent) { return dependent.type === _this.relationshipType; });
        switch (this.relationshipType) {
            case 'Spouse':
                {
                    if (result.length === 1) {
                        console.log('ERROR - Only allowed 1 spouse');
                    }
                    else {
                        this.dependantsList.push({
                            type: this.relationshipType,
                            cover_amount: this.coverDependant,
                            premium: this.premiumDependant // Options - Premium
                        });
                        this.spouseCount++;
                    }
                }
                break;
            case 'Child':
                {
                    if (result.length === 8) {
                        console.log('ERROR - Only allowed 8 children');
                    }
                    else {
                        this.dependantsList.push({
                            type: this.relationshipType,
                            cover_amount: this.coverDependant,
                            premium: this.premiumDependant // Options - Premium
                        });
                        this.childCount++;
                    }
                }
                break;
            case 'Parents/In Laws':
                {
                    if (result.length === 4) {
                        console.log('ERROR - Only allowed 8 Externals');
                    }
                    else {
                        this.dependantsList.push({
                            type: this.relationshipType,
                            cover_amount: this.coverDependant,
                            premium: this.premiumDependant // Options - Premium
                        });
                        this.parentsInLawsCount++;
                    }
                }
                break;
            default:
                break;
        }
        this.form.reset();
        this.dependantOptions = [];
    };
    QqMlfComponent.prototype.removeDependant = function (index) {
        if (!isNullOrUndefined(this.dependantsList[index])) {
            delete this.dependantsList[index];
            this.dependantsList = this.dependantsList.filter(function (dependent) { return !isNullOrUndefined(dependent); });
            switch (this.relationshipType) {
                case 'Spouse':
                    this.spouseCount--;
                    break;
                case 'Child':
                    this.childCount--;
                    break;
                case 'Parents/In Laws':
                    this.parentsInLawsCount--;
                    break;
                default:
                    break;
            }
        }
    };
    QqMlfComponent.prototype.hideAddDependantForm = function () {
        this.addDependantFlag = true;
    };
    QqMlfComponent.prototype.getDependantPricing = function () {
        var _this = this;
        if (this.form.get('age').valid && this.form.get('relationship').valid && this.form.get('gender').valid) {
            this.qqService.getMlfPricing({
                age: Number(this.form.get('age').value),
                gender: this.form.get('gender').value,
                assumed_income: 0,
                product_group: 'SG_MLF',
                product_code: this.form.get('relationship').value,
                main_member_info: this.data.mainMemberInformation
            }).subscribe(function (response) {
                _this.coverDependant = response.options[0].cover;
                _this.premiumDependant = response.options[0].premium;
                _this.dependantOptions = response.options;
                _this.originalDependentOptions = response.options;
                console.log(response);
            });
        }
    };
    QqMlfComponent.prototype.onIdValidationChange = function (rsaId) {
    };
    QqMlfComponent.prototype.selectionChange = function (event) {
        this.relationshipType = event.source.triggerValue;
        if (this.form.get('gender').valid) {
            this.getDependantPricing();
        }
    };
    QqMlfComponent.prototype.contact = function () {
        this.dialog.open(ContactBlockComponent, {
            disableClose: false,
            panelClass: 'dl-modal',
            maxWidth: '99vw',
        });
    };
    return QqMlfComponent;
}());
export { QqMlfComponent };
