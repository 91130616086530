
    <button
            mat-raised-button
            tabindex="1"
            class="dl-full-width dl-action-btn dl-alternate"
            [attr.data-name]="component.id"
            [attr.data-event]="'true'"
            [attr.data-event-category]="'DSP-' + productCode"
            [attr.data-event-name]="pageName + ' - Continue'"
            [attr.data-event-label]="'Continue to ' + component.value"
            (click)="click()" [disabled]="isDisabled || isBusy">
        {{component.name}}&nbsp;<i *ngIf="isBusy" class="fa fa-circle-o-notch fa-spin"></i>
    </button>
    