import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LeadDataVO } from './needs.vo';
import { throwError } from 'rxjs';
import { ErrorVO } from '../base/error/error.vo';
import { ErrorModalProvider } from '../base/error/error.modal.service';
import { catchError, tap } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { Log } from 'ng2-logger/browser';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../base/error/error.modal.service";
var httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
var log = Log.create('NeedsService');
var NeedsService = /** @class */ (function () {
    function NeedsService(http, errorModalProvider) {
        this.http = http;
        this.errorModalProvider = errorModalProvider;
        this._benefitMap = {};
        this._hasSchoolStage = false;
        this._hasLivingExpenses = false;
    }
    Object.defineProperty(NeedsService.prototype, "ptyName", {
        get: function () {
            return this._ptyName;
        },
        set: function (value) {
            this._ptyName = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NeedsService.prototype, "needsResponse", {
        get: function () {
            return this._needsResponseData;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NeedsService.prototype, "benefitMap", {
        get: function () {
            return this._benefitMap;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NeedsService.prototype, "hasSchoolStage", {
        get: function () {
            return this._hasSchoolStage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NeedsService.prototype, "needsResponseData", {
        set: function (val) {
            this._needsResponseData = val;
            this._needsResponseData.noteAsterisk = false;
            var total = 0;
            for (var _i = 0, _a = this._needsResponseData.needsBenefits; _i < _a.length; _i++) {
                var benefit = _a[_i];
                total += benefit.premium_amount;
                this.benefitMap[benefit.product_code] = benefit;
                if (benefit.benefit_amount !== benefit.requested_benefit_amount) {
                    if (benefit.product_code === 'SP90' && benefit.premium_amount > 0) {
                        // skip sp if we have a premium
                        continue;
                    }
                    benefit.noteAsterisk = true;
                    this._needsResponseData.noteAsterisk = true;
                    benefit.toFromText = 'from';
                    if (benefit.benefit_amount < benefit.requested_benefit_amount) {
                        benefit.toFromText = 'to';
                    }
                }
            }
            this._needsResponseData.total = total;
            this._schoolStages = this._needsResponseData.needsBreakdown.filter(function (b) { return b.type === 'education'; });
            this._hasSchoolStage = this._schoolStages.length > 0;
            this._debtAssumptions = this._needsResponseData.needsBreakdown.filter(function (b) { return b.type === 'debt'; });
            this._livingExpenses = this._needsResponseData.needsBreakdown.filter(function (b) { return b.type === 'living_expense'; });
            this._hasLivingExpenses = this._livingExpenses.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NeedsService.prototype, "productGroup", {
        get: function () {
            if (this._productGroupVO != null) {
                return this._productGroupVO.productGroupCode;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NeedsService.prototype, "schoolStages", {
        get: function () {
            return this._schoolStages;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NeedsService.prototype, "debtAssumptions", {
        get: function () {
            return this._debtAssumptions;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NeedsService.prototype, "hasLivingExpenses", {
        get: function () {
            return this._hasLivingExpenses;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NeedsService.prototype, "livingExpenses", {
        get: function () {
            return this._livingExpenses;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NeedsService.prototype, "campaignId", {
        get: function () {
            if (this._productGroupVO != null) {
                return this._productGroupVO.campaignId;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    NeedsService.prototype.setLeadData = function (name, email, cell, leadId, httpReferrer, campaignCode, referrerCode) {
        if (isNullOrUndefined(this._leadData)) {
            this._leadData = new LeadDataVO();
        }
        this._leadData.email1 = email;
        this._leadData.phone_mobile = cell;
        this.splitLeadName(this._leadData, name);
        if (!isNullOrUndefined(httpReferrer)) {
            this._leadData.refered_by = httpReferrer;
        }
        if (!isNullOrUndefined(campaignCode)) {
            this._leadData.campaign_code = campaignCode;
        }
        if (!isNullOrUndefined(referrerCode)) {
            this._leadData.referrer_code = referrerCode;
        }
        if (!isNullOrUndefined(leadId) && leadId !== '') {
            this._leadData.id = leadId;
        }
        this._leadData.lead_type = 'life_needs';
        this._leadData.lead_source = 'Calculator';
        this._leadData.lead_source_description = 'Lead from Life Calculator Calculate Button';
    };
    NeedsService.prototype.splitLeadName = function (leadData, name) {
        if (!isNullOrUndefined(name) && name !== '') {
            var nameParts = name.split(' ');
            if (nameParts.length > 1) {
                leadData.last_name = null;
                leadData.first_name = nameParts[0];
                for (var i = 1; i < nameParts.length; i++) {
                    if (isNullOrUndefined(leadData.last_name)) {
                        leadData.last_name = nameParts[i];
                    }
                    else {
                        leadData.last_name += ' ' + nameParts[i];
                    }
                }
            }
            else {
                leadData.first_name = name;
            }
            leadData.known_as = leadData.first_name;
        }
    };
    Object.defineProperty(NeedsService.prototype, "leadData", {
        get: function () {
            return this._leadData;
        },
        enumerable: true,
        configurable: true
    });
    NeedsService.prototype.clearResults = function () {
        this._needsResponseData = null;
        this._leadData = null;
        this._benefitMap = {};
        this._hasSchoolStage = false;
        this._schoolStages = [];
        this._debtAssumptions = [];
        this._hasLivingExpenses = false;
        this._livingExpenses = [];
    };
    /**
     * Get the needs results from the calculator
     * @param {NeedsDataVO} data
     * @returns {Observable<NeedsDataVO>}
     */
    NeedsService.prototype.getNeedsInfo = function (data) {
        var _this = this;
        return this.http.post('/dl/data/needs/calculate', data, httpOptions)
            .pipe(tap(function (res) {
            _this.needsResponseData = res;
        }), catchError(this.handleError()));
    };
    NeedsService.prototype.resolveProductGroup = function (data) {
        var _this = this;
        return this.http.post('/dl/data/needs/details', data, httpOptions)
            .pipe(tap(function (res) {
            if (res.message == null) {
                _this._productGroupVO = res;
            }
        }), catchError(this.handleError()));
    };
    NeedsService.prototype.sendCalcEmail = function (needsId, lead) {
        var _this = this;
        return this.http.post('/dl/data/needs/calcEmail/' + needsId, lead, httpOptions)
            .pipe(tap(function (res) {
            _this.leadData.id = res.lead_id;
            _this.needsResponse.lead_id = res.lead_id;
        }), catchError(this.handleError()));
    };
    NeedsService.prototype.continueToSp = function (qqData) {
        return this.http.post('/dl/data/needs/calculator', qqData, httpOptions)
            .pipe(catchError(this.handleError()));
    };
    NeedsService.prototype.handleError = function () {
        var _this = this;
        return function (error) {
            var vo = ErrorVO.toErrorVO(error);
            _this.errorModalProvider.showError(vo);
            return throwError(error);
        };
    };
    NeedsService.ngInjectableDef = i0.defineInjectable({ factory: function NeedsService_Factory() { return new NeedsService(i0.inject(i1.HttpClient), i0.inject(i2.ErrorModalProvider)); }, token: NeedsService, providedIn: "root" });
    return NeedsService;
}());
export { NeedsService };
