import {Injectable} from '@angular/core';
import {PageInfoVO} from './page.data.vo';
import {ErrorModalProvider} from '../base/error/error.modal.service';
import {ErrorVO} from '../base/error/error.vo';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';
import {isNullOrUndefined} from 'util';
import {Observable, throwError} from 'rxjs';

/**
 * Created by corneliusbotha on 2017/07/18.
 */

export const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};

export abstract class DspInjectable {

    public baseUrl = '/dl/data/dsp';

    public handleError<T>(errorDialogService: ErrorModalProvider, heading?: string) {
        return (error: any):  Observable<T> => {
            const vo: ErrorVO = ErrorVO.toErrorVO(error);
            errorDialogService.showError(vo, heading);
            return throwError(error);
        };
    }
}

@Injectable({
  providedIn: 'root'
})
export class DspService extends DspInjectable {

    private postUrl = this.baseUrl + '/submit';

    private _groupId: string;
    set groupId(val: string) {
        this._groupId = val;
    }
    get groupId(): string {
        return this._groupId;
    }

    private _resumeStorage: PageInfoVO = null;
    set resumeStorage(data: PageInfoVO) {
        this._resumeStorage = data;
    }
    get resumeStorage(): PageInfoVO {
        return this._resumeStorage;
    }

    private _currentData: PageInfoVO;
    set currentData(data: PageInfoVO) {
        this._currentData = data;
    }
    get currentData(): PageInfoVO {
        return this._currentData;
    }

    get productCode(): string {
        if (!isNullOrUndefined(this._currentData)) {
            return this._currentData.productCode;
        }
        return null;
    }

    get pageName(): string {
        if (!isNullOrUndefined(this._currentData)) {
            return this._currentData.pageName;
        }
        return null;
    }

    constructor(
        protected http: HttpClient,
        protected errorModalProvider: ErrorModalProvider,
        ) {
        super();
    }

    public postPageData(pageData: PageInfoVO): Observable<PageInfoVO> {
        return this.http.post<PageInfoVO>(this.postUrl, pageData, httpOptions).pipe(
            tap(res => this.setCurrentData(res)),
            catchError(this.handleError<PageInfoVO>(this.errorModalProvider))
        );
    }

    public createPostDocCase(policyId: string, notes: string): Observable<any> {
        const url = '/dl/data/sp/case/createPostDocCase/' + policyId;
        return this.http.post(url, {'notes': notes}, httpOptions).pipe(
            catchError(this.handleError(this.errorModalProvider)));
      }

    public createPostSaleServiceCase(contactId: string, notes: string): Observable<any> {
        const url = '/dl/data/sp/case/createServicingCase/' + contactId;
        return this.http.post(url, {'notes': notes}, httpOptions).pipe(
        catchError(this.handleError(this.errorModalProvider)));
    }

    public updateTheCaseForWebAvsInvalid(spId: string) {
        const url = '/dl/data/sp/case/webAvsFailed/' + spId;
        return this.http.post(url, {}, httpOptions).pipe(
            catchError(this.handleError(this.errorModalProvider))).toPromise();
    }

    private setCurrentData(data: PageInfoVO): PageInfoVO {
        this.currentData = data;
        return this.currentData;
    }
}
