
    <div class="row">
        <div class="dl-modal qq-mlf col s12">
            <mat-card>
                <mat-card-title class="title">Your Cover</mat-card-title>
                <mat-card-content>
                    <div class="row no-row-margin-bottom">
                        <div class="col s12">
                            <p style="margin-bottom: 20px;">We can offer you
                                <b>{{ cover | dl_currency:true:0 }}</b> cover for
                                <b>{{ premium | dl_currency:true:2 }}</b>&nbsp;p/m.
                            </p>
                        </div>
                    </div>
                    <div class="row no-row-margin-bottom">
                        <div class="col s12">
                            <mat-slider
                                [invert]="false"
                                [max]="maxCover"
                                [min]="minCover"
                                [step]="'5000'"
                                [thumbLabel]="true"
                                [displayWith]="formatDisplay"
                                [value]="cover"
                                (input)="updateCover($event)">
                            </mat-slider>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col s12" style="margin-top: -20px;">
                            <div class="left">{{minCover | dl_currency:true:0}}</div>
                            <div class="right">{{maxCover | dl_currency:true:0}}</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col s12"><small>Drag the slider to change the cover amount</small></div>
                    </div>
                    <div class="row">
                        <div class="col s12">
                            <table class="dl-table">
                                <thead>
                                <tr>
                                    <th class="text-left hide-on-small-and-down"></th>
                                    <th class="text-left hide-on-small-and-down">Cover Amount</th>
                                    <th class="text-right">Premium</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <ng-container *ngFor="let dependant of dependantsList; let idx = index">
                                    <tr>
                                        <td>{{dependant.type}} {{
                                            dependant.type === 'Spouse' ? '(' + spouseCount + '/1)'
                                            : dependant.type === 'Child' ? '(' + childCount + '/8)'
                                            : dependant.type === 'Spouse' ? '(' + parentsInLawsCount + '/4)' : ''
                                        }}</td>
                                        <td class="text-left hide-on-small-and-down">{{dependant.cover_amount | dl_currency:true:0}}</td>
                                        <td class="text-right">{{dependant.premium | dl_currency:true:2}}</td>
                                        <td class="text-right">
                                            <span *ngIf="idx === 0" class="hide-on-small-and-down spacer"></span>
                                            <span *ngIf="idx !== 0" class="hide-on-med-and-up"><br></span>
                                            <i *ngIf="idx !== 0" (click)="removeDependant(idx)" class="fa fa-times"></i>
                                        </td>
                                    </tr>
                                </ng-container>
                                <tfoot>
                                <tr>
                                    <td><b>Total</b></td>
                                    <td class="hide-on-small-and-down"></td>
                                    <td class="text-right"><b>{{totalPremium | dl_currency:true:2}}</b></td>
                                    <td></td>
                                </tr>
                                <tfoot>
                            </table>
                        </div>
                    </div>
                    <div class="row" *ngIf="!addDependantFlag">
                        <div class="col s12">
                            <p class="text-center">Check cover for additional lives to this policy?</p>
                        </div>
                        <div class="col s8 offset-s2 m6 offset-m3 l4 offset-l4">
                            <button class="dl-full-width"
                                mat-raised-button
                                tabindex="1"
                                (click)="addDependantFlag = true">Add</button>
                        </div>
                    </div>
                    <form [formGroup]="form" *ngIf="addDependantFlag">
                        <div class="row">
                            <div class="col s12 m4">
                                <mat-form-field class="dl-full-width">
                                    <input matInput
                                        (change)="getDependantPricing()"
                                        tabindex="1"
                                        [formControl]="form.controls['age']"
                                        placeholder="Age"
                                        [attr.data-name]="'age'"
                                    >
                                </mat-form-field>
                            </div>
                            <div class="col s12 m4">
                                <mat-form-field class="dl-full-width">
                                    <mat-select
                                        [formControl]="form.controls['relationship']"
                                        placeholder="Relationship"
                                        tabindex="1"
                                        [attr.data-name]="'relationship'"
                                        (selectionChange)="selectionChange($event)"
                                        [disabled]="!form.get('age').valid"
                                        >
                                        <mat-option value="MLF_S">Spouse</mat-option>
                                        <mat-option value="MLF_C">Child</mat-option>
                                        <mat-option value="MLF_E">Parents/In Laws</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col s12 m4">
                                <mat-radio-group (change)="getDependantPricing()" class="dl-full-width" [formControl]="form.controls['gender']" [disabled]="!form.get('relationship').valid">
                                    <mat-radio-button
                                        class="mat-button-toggle-2"
                                        color="primary"
                                        [tabIndex]="1"
                                        [value]="'M'"
                                        [attr.data-val]="'M'">Male</mat-radio-button>
                                    <mat-radio-button
                                        class="mat-button-toggle-2"
                                        color="primary"
                                        [tabIndex]="1"
                                        [value]="'F'"
                                        [attr.data-val]="'F'">Female</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div class="row no-row-margin-bottom" *ngIf="dependantOptions.length > 0">
                            <div class="col s12">
                                <p style="margin-bottom: 20px;">{{getRelationshipType}}
                                    <b>{{ coverDependant | dl_currency:true:0 }}</b> cover for
                                    <b>{{ premiumDependant | dl_currency:true:2 }}</b>&nbsp;p/m.
                                </p>
                            </div>
                        </div>
                        <div class="row no-row-margin-bottom" *ngIf="dependantOptions.length > 0">
                            <div class="col s12">
                                <mat-slider
                                    [invert]="false"
                                    [max]="maxCoverDependant"
                                    [min]="minCoverDependant"
                                    [step]="'5000'"
                                    [thumbLabel]="true"
                                    [displayWith]="formatDisplay"
                                    [value]="coverDependant"
                                    (input)="updateCoverDependant($event)">
                                </mat-slider>
                            </div>
                        </div>
                        <div class="row" *ngIf="dependantOptions.length > 0">
                            <div class="col s12"><small>Drag slider to change the cover amount</small></div>
                        </div>
                        <div class="row">
                            <div class="col s12 m3" *ngIf="dependantOptions.length > 0">
                                <button
                                    class="dl-full-width"
                                    (click)="addDependant()"
                                    mat-raised-button
                                    color="primary"
                                    tabindex="1"
                                    [attr.data-name]="'save'"
                                    [disabled]="(relationshipType === 'Spouse' && spouseCount === 1) || (relationshipType === 'Child' && childCount === 8) || (relationshipType === 'Parents/In Laws' && parentsInLawsCount === 4)"
                                    ><i class="fa fa-check"> Add</i></button>
                            </div>
                            <div class="col s12 m3">
                                <button
                                    class="dl-full-width"
                                    (click)="addDependantFlag = false"
                                    mat-raised-button
                                    color="primary"
                                    tabindex="1"
                                    [attr.data-name]="'save'"
                                    ><i class="fa fa-times"> Cancel</i></button>
                            </div>
                        </div>
                    </form>
                </mat-card-content>
                <div class="dl-modal-footer" *ngIf="qqMlfFooterFlag">
                    <div class="dl-container-no-padding">
                        <div class="row">
                            <div class="col m4 s12">
                            <button
                                (click)="contact()"
                                mat-raised-button
                                color="primary"
                                class="dl-action-btn dl-full-width"
                                tabindex="1"
                                >Call me</button>
                            </div>
                            <div class="col m4 s12"></div>
                            <div class="col m4 s12">
                                <button
                                    (click)="continue()"
                                    mat-raised-button
                                    color="primary"
                                    class="dl-action-btn dl-full-width"
                                    tabindex="1"
                                    [attr.data-name]="'save'"
                                    >Continue&nbsp;<i *ngIf="isLoading" class="fa fa-circle-o-notch fa-spin"></i></button>
                            </div>
                        </div>
                    </div>
                    <p class="fine-print">*Estimate: complete the process to get an official quote.</p>
                </div>
            </mat-card>
        </div>
    </div>
    