import { EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
/**
 * Base dynamic class for all dynamic controllers
 */
var Dynamic = /** @class */ (function () {
    function Dynamic() {
        this.change = new EventEmitter();
        this.loaded = new EventEmitter();
        this.validated = new EventEmitter();
        this.resume = new EventEmitter();
        this.altContinue = new EventEmitter();
    }
    Object.defineProperty(Dynamic.prototype, "formGroup", {
        get: function () {
            return this._formGroup;
        },
        set: function (val) {
            this._formGroup = val;
            this.setupFromControl();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Dynamic.prototype, "component", {
        get: function () {
            return this._component;
        },
        set: function (val) {
            this._component = val;
            this.processExtraData();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Dynamic.prototype, "productCode", {
        get: function () {
            return this._productCode;
        },
        set: function (value) {
            this._productCode = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Dynamic.prototype, "spMode", {
        get: function () {
            return this._spMode;
        },
        set: function (value) {
            this._spMode = value;
        },
        enumerable: true,
        configurable: true
    });
    Dynamic.prototype.setupFromControl = function () {
        var _this = this;
        if (this.component.name === 'qq-mlf') {
        }
        else if (!isNullOrUndefined(this.formGroup) && !isNullOrUndefined(this.getFormControl())) {
            if (!isNullOrUndefined(this.formGroup.get(this.component.id))) {
                this.formGroup.get(this.component.id).reset();
            }
            else {
                this.formGroup.registerControl(this.component.id, this.getFormControl());
            }
            this.getFormControl().valueChanges.subscribe(function () { return _this.onChange(); });
            this.component.loaded = true;
            // tel my parent I'm loaded.
            this.loaded.emit(this.component.id);
        }
        else {
            throw new Error('Component setup failed');
        }
    };
    Dynamic.prototype.setInitialValue = function () {
        if (!isNullOrUndefined(this.getFormControl()) && !isNullOrUndefined(this.component.initValue) && this.component.initValue !== '') {
            this.getFormControl().setValue(this.component.initValue);
        }
    };
    /**
     * This is called from the loader to build up the form control
     * The function must take PageComponentVO as input.
     */
    Dynamic.prototype.setComponentData = function (data) {
        this.component = data;
        this.formGroup = data.pageForm;
    };
    Dynamic.prototype.onChange = function () {
    };
    Dynamic.prototype.processExtraData = function () {
    };
    Dynamic.prototype.validate = function () {
        return false;
    };
    return Dynamic;
}());
export { Dynamic };
var ParentEventService = /** @class */ (function () {
    function ParentEventService() {
        this.pageValidated = new Subject();
        this.pageBusy = new Subject();
    }
    ParentEventService.prototype.getPageValidateObservable = function () {
        return this.pageValidated.asObservable();
    };
    ParentEventService.prototype.publishPageValidatedStatus = function (status) {
        this.pageValidated.next(status);
    };
    ParentEventService.prototype.getPageBusyObservable = function () {
        return this.pageBusy.asObservable();
    };
    ParentEventService.prototype.publishPageBusyStatus = function (status) {
        this.pageBusy.next(status);
    };
    ParentEventService.ngInjectableDef = i0.defineInjectable({ factory: function ParentEventService_Factory() { return new ParentEventService(); }, token: ParentEventService, providedIn: "root" });
    return ParentEventService;
}());
export { ParentEventService };
