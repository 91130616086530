var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Dynamic } from './component';
import { BeneficiaryDetailVO } from '../page.data.vo';
import { isNullOrUndefined } from 'util';
import { FormBuilder, Validators } from '@angular/forms';
import { rsaIdDateOfBirthValidation, rsaIdValidation } from '../../base/rsa.id.validator';
import { minMaxValidation } from '../../base/min.max.validator';
import { DLUtil } from '../../base/dl.util';
import { PhoneNumberValidator } from '../../base/phone-number';
import { Log } from 'ng2-logger/client';
var log = Log.create('BeneficiariesComponent');
var BeneficiariesComponent = /** @class */ (function (_super) {
    __extends(BeneficiariesComponent, _super);
    function BeneficiariesComponent(fb) {
        var _this = _super.call(this) || this;
        _this.fb = fb;
        _this.counter = 0;
        _this.showTotalError = false;
        _this.listWarnClass = '';
        _this.editMode = false;
        _this.skipping = false;
        _this.canSkip = false;
        _this.hideId = false;
        _this.hasEmail = false;
        _this.featureSS3217_COMPULSORY_BENEFICIARY_ID = true;
        _this.relationOptions = [
            { value: 'spouse', label: 'Spouse' },
            { value: 'child', label: 'Child' },
            { value: 'parent', label: 'Parent' },
            { value: 'estate', label: 'Estate' },
            { value: 'other', label: 'Other' }
        ];
        _this.buildForm();
        return _this;
    }
    BeneficiariesComponent.prototype.selectionChange = function (id) {
        log.info("Value ID: " + id);
    };
    BeneficiariesComponent.prototype.onIdValidationChange = function (rsaId) {
        if (this.featureSS3217_COMPULSORY_BENEFICIARY_ID)
            !rsaId.isValid() ? this.beneficiariesForm.controls['idDob'].setErrors({ 'invalid': true }) : this.beneficiariesForm.controls['idDob'].setErrors(null);
    };
    BeneficiariesComponent.prototype.buildForm = function () {
        var _this = this;
        this.beneficiariesForm = this.fb.group({
            name: ['', Validators.required],
            relation: ['', Validators.required],
            idDob: ['', this.featureSS3217_COMPULSORY_BENEFICIARY_ID ? [Validators.required, rsaIdValidation(false, this)] : [rsaIdDateOfBirthValidation()]],
            percentage: ['', [
                    Validators.required, minMaxValidation(1, 100),
                    Validators.pattern('^(100|[0-9]{1,2})(\\.[0-9]{1,2})?$')
                ]],
            email: ['', [Validators.email, Validators.pattern(DLUtil.emailPattern)]],
            phone_number: ['', [Validators.required, PhoneNumberValidator.validator()]]
        });
        this.beneficiariesForm.controls.relation.valueChanges.subscribe(function (relationValue) {
            _this.changeFormEstateFields(relationValue);
        });
    };
    BeneficiariesComponent.prototype.processExtraData = function () {
        // init the model
        if (isNullOrUndefined(this.component.value)) {
            this.component.value = [];
        }
        this.beneficiaries = this.component.value;
        this.counter = this.beneficiaries.length;
        this.total = 0;
        for (var i = 0; i < this.beneficiaries.length; i++) {
            this.beneficiaries[i].counter = i;
            // this is a work around as the percentage gets sent to the app as a string.
            var temp = this.beneficiaries[i].percentage.toString();
            this.beneficiaries[i].percentage = parseFloat(temp);
            this.total += this.beneficiaries[i].percentage;
        }
        this.validateTotal();
    };
    BeneficiariesComponent.prototype.addItem = function () {
        var _this = this;
        var beneficiary;
        if (this.editMode) {
            beneficiary = this.beneficiaries.find(function (item) { return item.counter === _this.editCounter; });
            this.editMode = false;
        }
        else {
            beneficiary = new BeneficiaryDetailVO();
            beneficiary.counter = this.counter++;
            this.beneficiaries.push(beneficiary);
        }
        if (this.beneficiariesForm.get('relation').value === 'estate') {
            this.beneficiaries = [];
        }
        beneficiary.name = this.beneficiariesForm.get('name').value;
        beneficiary.personal_relation = this.beneficiariesForm.get('relation').value;
        if (!this.hideId) {
            beneficiary.idDob = this.beneficiariesForm.get('idDob').value;
        }
        if (this.hasEmail) {
            beneficiary.email = this.beneficiariesForm.get('email').value;
        }
        beneficiary.percentage = parseInt(this.beneficiariesForm.get('percentage').value);
        beneficiary.phone_number = this.beneficiariesForm.get('phone_number').value;
        beneficiary.email = this.beneficiariesForm.get('email').value;
        this.validateTotal();
        this.beneficiariesForm.reset();
    };
    BeneficiariesComponent.prototype.removeItem = function (item) {
        // remove the item from the list.
        this.beneficiaries = this.beneficiaries.filter(function (ben) { return ben.counter !== item.counter; });
        this.editMode = false;
        this.editCounter = null;
        this.validateTotal();
    };
    BeneficiariesComponent.prototype.editItem = function (beneficiary) {
        this.editMode = true;
        this.editCounter = beneficiary.counter;
        this.beneficiariesForm.get('name').setValue(beneficiary.name);
        this.beneficiariesForm.get('relation').setValue(beneficiary.personal_relation);
        if (!this.hideId) {
            this.beneficiariesForm.get('idDob').setValue(beneficiary.idDob);
        }
        if (this.hasEmail) {
            this.beneficiariesForm.get('email').setValue(beneficiary.email);
        }
        this.beneficiariesForm.get('percentage').setValue(beneficiary.percentage);
        this.beneficiariesForm.get('phone_number').setValue(beneficiary.phone_number);
        this.beneficiariesForm.get('email').setValue(beneficiary.email);
    };
    BeneficiariesComponent.prototype.validateTotal = function () {
        this.total = 0;
        for (var i = 0; i < this.beneficiaries.length; i++) {
            this.total += this.beneficiaries[i].percentage;
        }
        if (this.total !== 100) {
            this.showTotalError = true;
            this.listWarnClass = 'dsp-list-warn';
        }
        else {
            this.listWarnClass = '';
            this.showTotalError = false;
        }
        this.component.isValid = this.beneficiaries.length > 0 && !this.showTotalError;
        this.component.value = this.beneficiaries;
        if (!isNullOrUndefined(this.change)) {
            this.change.emit(this.component.counter);
        }
    };
    BeneficiariesComponent.prototype.changeFormEstateFields = function (value) {
        log.info('Value: ' + value);
        switch (value) {
            case 'estate':
                this.beneficiariesForm.get('idDob').disable();
                this.beneficiariesForm.get('email').disable();
                this.beneficiariesForm.get('phone_number').disable();
                this.beneficiariesForm.get('percentage').setValue(100);
                this.beneficiariesForm.get('percentage').disable();
                break;
            default:
                this.beneficiariesForm.get('idDob').enable();
                this.beneficiariesForm.get('email').enable();
                this.beneficiariesForm.get('phone_number').enable();
                this.beneficiariesForm.get('percentage').enable();
        }
    };
    BeneficiariesComponent.prototype.getFormControl = function () {
        return null;
    };
    BeneficiariesComponent.prototype.skip = function () {
        // this.skipping = true;
        // this.validated.emit({ success: true, componentId: this.component.id });
    };
    BeneficiariesComponent.prototype.onChange = function () { };
    BeneficiariesComponent.prototype.setupFromControl = function () { };
    return BeneficiariesComponent;
}(Dynamic));
export { BeneficiariesComponent };
