var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, AfterViewInit } from '@angular/core';
import { InputData, PostData } from './qq.vo';
import { rsaIdValidation } from '../base/rsa.id.validator';
import { isNullOrUndefined, isUndefined } from 'util';
import { MatDialog } from '@angular/material';
import { QQMobileWarningComponent } from './qq.mobile.warning.component';
import { QQResultComponent } from './qq.result.component';
import { FormBuilder, Validators } from '@angular/forms';
import { QQPricingRequestVO } from './qq.pricing.vos';
import { WindowRef } from '../base/window.ref';
import { QQService } from './qq.service';
import { ErrorModalProvider } from '../base/error/error.modal.service';
import { minMaxValidation } from '../base/min.max.validator';
import { DLUtil } from '../base/dl.util';
import { QqResultNoSpComponent } from './qq.result.no.sp/qq.result.no.sp.component';
import { QqMlfComponent } from './qq-mlf.modal.component';
import { Dynamic, ParentEventService } from './../dsp/component/component';
import { DspService } from '../dsp/dsp.service';
var QqComponent = /** @class */ (function (_super) {
    __extends(QqComponent, _super);
    function QqComponent(winRef, qqService, errorModalProvider, dialog, windowRef, parentEventService, fb, dspService) {
        var _this = _super.call(this) || this;
        _this.winRef = winRef;
        _this.qqService = qqService;
        _this.errorModalProvider = errorModalProvider;
        _this.dialog = dialog;
        _this.windowRef = windowRef;
        _this.parentEventService = parentEventService;
        _this.fb = fb;
        _this.dspService = dspService;
        _this.qqMlfComponentFlag = false;
        _this._external = false;
        _this.showProduct = true;
        _this.smokerItems = [
            { label: 'Smoker', value: 'S' },
            { label: 'Non Smoker', value: 'N' }
        ];
        _this.genderItems = [
            { label: 'Female', value: 'F' },
            { label: 'Male', value: 'M' }
        ];
        _this.products = [];
        _this.qqLoading = true;
        _this.education = [
            { label: 'No Matric', value: 'no_matric' },
            { label: 'Matric', value: 'matric' },
            { value: 'matric_certificate', label: 'Matric plus certificate' },
            { value: 'matric_3_yr_diploma', label: '3 yr Diploma' },
            { value: 'matric_4_yr_diploma', label: '4 yr Diploma' },
            { value: 'degree_3_yr', label: '3 yr Degree' },
            { value: 'degree_4_yr', label: '4 yr Degree' },
            { value: 'degree_post_grad', label: 'Postgraduate Degree' }
        ];
        _this.loading = false;
        _this.noId = false;
        _this.toOld = false;
        _this.noIncome = false;
        _this.leadInvalidated = false;
        _this.mlfIdData = {
            dob: '',
            age: '',
            gender: ''
        };
        _this.inputData = new InputData();
        _this.createForm();
        _this.numMask = {
            mask: function (rawValue) {
                var numValue = rawValue.replace(/\D+/g, '');
                var mask = [];
                for (var i = numValue.length - 1; i >= 0; i--) {
                    if ((numValue.length - i) > 2 && (numValue.length - i) % 3 === 1) {
                        mask.unshift(',');
                    }
                    mask.unshift(/\d/);
                }
                return mask;
            }
        };
        return _this;
    }
    QqComponent.prototype.getFormControl = function () {
        return;
    };
    Object.defineProperty(QqComponent.prototype, "qqType", {
        set: function (val) {
            this.inputData.type = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QqComponent.prototype, "qqTitle", {
        // inputs
        set: function (val) {
            this.inputData.title = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QqComponent.prototype, "securityGroup", {
        set: function (val) {
            this.inputData.securityGroup = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QqComponent.prototype, "productCode", {
        set: function (val) {
            this.inputData.productCode = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QqComponent.prototype, "buttonText", {
        set: function (val) {
            this.inputData.buttonText = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QqComponent.prototype, "bgColour", {
        set: function (val) {
            this.inputData.bgColour = val;
            this.rgbaColor = 'rgba(' + this.inputData.bgColour + ',' + this.inputData.opacity + ')';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QqComponent.prototype, "opacity", {
        set: function (val) {
            this.inputData.opacity = val;
            this.rgbaColor = 'rgba(' + this.inputData.bgColour + ',' + this.inputData.opacity + ')';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QqComponent.prototype, "campaignCode", {
        set: function (val) {
            this.inputData.campaignCode = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QqComponent.prototype, "referrerCode", {
        set: function (val) {
            this.inputData.referrerCode = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QqComponent.prototype, "httpReferrer", {
        set: function (val) {
            this.inputData.httpReferrer = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QqComponent.prototype, "causeText", {
        set: function (val) {
            this.inputData.causeText = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QqComponent.prototype, "source", {
        set: function (val) {
            this.inputData.source = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QqComponent.prototype, "leadId", {
        set: function (val) {
            this.inputData.leadId = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QqComponent.prototype, "spId", {
        set: function (val) {
            this.inputData.spId = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QqComponent.prototype, "optionality", {
        set: function (val) {
            this.inputData.optionality = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QqComponent.prototype, "leadCapture", {
        set: function (val) {
            this.inputData.leadCapture = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QqComponent.prototype, "_class", {
        set: function (val) {
            this.inputData.theme = '';
            if (val.indexOf('dark') > -1) {
                this.inputData.theme = 'dark';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QqComponent.prototype, "index", {
        set: function (val) {
            this._external = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QqComponent.prototype, "embedded", {
        set: function (val) {
            this.setEmbedded(val);
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Called on init.
     */
    QqComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.rgbaColor = 'rgba(' + this.inputData.bgColour + ',' + this.inputData.opacity + ')';
        this.setEmbedded(this.inputData.embedded);
        if (this.inputData.type === 'mlf') {
            this.qqMlfComponentFlag = true;
            this.qqMlfForm = this.fb.group({
                rsaId: ['', [Validators.pattern('[0-9]*'), rsaIdValidation(true, this)]],
                income: ['', [Validators.required, minMaxValidation(0, 999999999)]]
            });
            this.qqLoading = false;
        }
        else if (!isNullOrUndefined(this.component)) {
            if ((this.component.type === 'mlf' && this.component.name === 'qq-mlf')) {
                this.qqMlfComponentFlag = true;
                this.qqMlfForm = this.fb.group({
                    rsaId: ['', [Validators.pattern('[0-9]*'), rsaIdValidation(true, this)]],
                    income: ['', [Validators.required, minMaxValidation(0, 999999999)]]
                });
                this.qqMlfForm.valueChanges.subscribe(function (formChange) {
                    if (_this.qqMlfForm.get('rsaId').valid && _this.qqMlfForm.get('income').valid) {
                        _this.component.isValid = true;
                    }
                    else {
                        _this.component.isValid = true;
                    }
                });
                this.qqLoading = false;
            }
        }
        else {
            this.qqService.getForm(this.inputData.embedded, this.inputData.leadId, this.inputData.campaignCode).subscribe(function (res) { return _this.initializeQQ(res); }, function () {
                _this.qqLoading = false;
                _this.qqForm.disable();
            });
        }
    };
    QqComponent.prototype.ngAfterViewInit = function () {
        try {
            // KingsWatch.aim();
        }
        catch (error) {
            console.log('Kings Watch failed to aim', error);
        }
    };
    QqComponent.prototype.setEmbedded = function (embed) {
        this.inputData.embedded = embed;
        if (embed) {
            this.qqForm.get('rsaId').enable();
        }
        else {
            this.qqForm.get('rsaId').disable();
        }
    };
    QqComponent.prototype.createForm = function () {
        this.qqForm = this.fb.group({
            product: ['', Validators.required],
            rsaId: ['', [Validators.required, Validators.pattern('[0-9]*'), rsaIdValidation(true, this, 18, 64)]],
            age: ['', [Validators.required, minMaxValidation(18, 64)]],
            birth_date: ['', [Validators.required, minMaxValidation(18, 80)]],
            income: ['', Validators.required],
            education: [this.education[0].value, Validators.required],
            smoker: ['', Validators.required],
            gender: ['', Validators.required]
        });
    };
    QqComponent.prototype.initializeQQ = function (qqForm) {
        var _this = this;
        if (qqForm && qqForm.products) {
            this.productGroupCode = qqForm.productGroupCode;
            this.inputData.campaignId = qqForm.campaignId;
            this.inputData.orgProjects = qqForm.orgProjects;
            this.products = qqForm.products;
            qqForm.products.forEach(function (product) {
                if (product.value === _this.inputData.productCode) {
                    _this.qqForm.get('product').setValue(product.value);
                    _this.qqForm.get('product').disable();
                    _this.showProduct = false;
                }
            });
            if (this.showProduct) {
                if (qqForm.products.length > 0) {
                    this.inputData.productCode = qqForm.products[0].value;
                    this.qqForm.get('product').setValue(this.inputData.productCode);
                    if (qqForm.products.length === 1) {
                        this.qqForm.get('product').disable();
                        this.showProduct = false;
                    }
                }
                else {
                    this.errorModalProvider.showErrorMessage('There are no products for the Quick Quote');
                    this.qqForm.disable();
                }
            }
            this.formFields = qqForm.fields;
            if (!qqForm.fields.age) {
                this.qqForm.get('age').disable();
            }
            if (!qqForm.fields.id_number) {
                this.qqForm.get('rsaId').disable();
            }
            if (!qqForm.fields.income) {
                this.qqForm.get('income').disable();
            }
            if (!qqForm.fields.education) {
                this.qqForm.get('education').disable();
            }
            if (!qqForm.fields.gender) {
                this.qqForm.get('gender').disable();
            }
            if (!qqForm.fields.smoker) {
                this.qqForm.get('smoker').disable();
            }
            if (!qqForm.fields.birth_date) {
                this.qqForm.get('birth_date').disable();
            }
        }
        else {
            this.message = qqForm.message;
        }
        this.qqLoading = false;
    };
    QqComponent.prototype.getPricing = function () {
        var _this = this;
        this.loading = true;
        try {
            // KingsWatch.fire();
        }
        catch (error) {
            console.log('Kings Watch failed to fire', error);
        }
        this.qqRequest = new QQPricingRequestVO();
        this.qqRequest.product_code = this.qqForm.get('product').value;
        if (this.formFields.age && this.qqForm.get('age').value) {
            this.qqRequest.starting_age_year = this.qqForm.get('age').value;
        }
        if (this.formFields.birth_date && this.qqForm.get('birth_date').value) {
            var date = new Date(this.qqForm.get('birth_date').value);
            this.qqRequest.birth_date = DLUtil.formatDateToString(date);
        }
        if (this.formFields.gender && this.qqForm.get('gender').value) {
            this.qqRequest.gender = this.qqForm.get('gender').value;
        }
        if (this.formFields.smoker && this.qqForm.get('smoker').value) {
            this.qqRequest.smoker = this.qqForm.get('smoker').value;
        }
        if (this.formFields.income && this.qqForm.get('income').value) {
            if (this.qqForm.get('income').value.replace == null) {
                this.qqRequest.income_gross_monthly = this.qqForm.get('income').value;
            }
            else {
                this.qqRequest.income_gross_monthly = this.qqForm.get('income').value.replace(/\D+/g, '');
            }
        }
        if (this.formFields.education && this.qqForm.get('education').value) {
            this.qqRequest.education = this.qqForm.get('education').value;
        }
        this.qqRequest.product_group = this.productGroupCode;
        if (this.inputData.leadId != null) {
            this.qqRequest.lead_id = this.inputData.leadId;
        }
        if (this.inputData.campaignId != null) {
            this.qqRequest.campaign_id = this.inputData.campaignId;
        }
        if (this.inputData.needsId != null) {
            this.qqRequest.needs_id = this.inputData.needsId;
        }
        if (this.inputData.needsBenefitId != null) {
            this.qqRequest.needs_benefit_id = this.inputData.needsBenefitId;
        }
        this.qqService.getPricing(this.qqRequest).subscribe(function (response) { return _this.handlePricingResult(response); }, function () { return _this.loading = false; });
    };
    QqComponent.prototype.qqMlfSubmit = function (event) {
        var _this = this;
        event.preventDefault();
        this.loading = true;
        this.qqService.getMlfPricing({
            id: this.qqMlfForm.get('rsaId').value,
            dob: this.mlfIdData.dob,
            age: this.mlfIdData.age,
            gender: this.mlfIdData.gender,
            assumed_income: Number(this.qqMlfForm.get('income').value.replace(/\D+/g, '')),
            product_group: 'SG_MLF',
            product_code: 'MLF'
        }).subscribe(function (response) {
            _this.handlePricingResult(response, event);
        }, function () { return _this.loading = false; });
    };
    // ====== function called in embedded only =========//
    QqComponent.prototype.sendNoId = function () {
        if (this.noId) {
            this.noId = false;
            this.qqForm.get('rsaId').enable();
        }
        else {
            this.noId = true;
            this.qqForm.get('rsaId').disable();
            this.sendInvalidReason('Citizenship: Not RSA Citizen', { 'idnumber': 'no id' });
        }
    };
    QqComponent.prototype.onIdValidationChange = function (rsaId) {
        if (this.inputData.type === 'mlf') {
            this.mlfIdData.dob = rsaId.getDOB(),
                this.mlfIdData.age = rsaId.getAge();
            this.mlfIdData.gender = rsaId.getGender();
        }
        else if (!isNullOrUndefined(this.component)) {
            if ((this.component.type === 'mlf' && this.component.name === 'qq-mlf')) {
                this.mlfIdData.dob = rsaId.getDOB(),
                    this.mlfIdData.age = rsaId.getAge();
                this.mlfIdData.gender = rsaId.getGender();
            }
        }
        else {
            if (!isUndefined(rsaId.getAge())) {
                this.qqForm.get('age').setValue(rsaId.getAge());
            }
        }
    };
    QqComponent.prototype.sendTooOld = function () {
        if (this.toOld) {
            this.toOld = false;
            this.qqForm.get('age').enable();
        }
        else {
            this.toOld = true;
            this.qqForm.get('age').disable();
            this.sendInvalidReason('Age: Over 65');
        }
    };
    QqComponent.prototype.getAgeErrorMessage = function () {
        if (this.qqForm.get('age').hasError('not_a_number')) {
            return 'Not a valid Age';
        }
        else if (this.qqForm.get('age').hasError('number_to_small')) {
            return 'Sorry too young';
        }
        else if (this.qqForm.get('age').hasError('number_to_big')) {
            return 'Sorry too old';
        }
        return '';
    };
    QqComponent.prototype.sendNoIncome = function () {
        if (this.noIncome) {
            this.noIncome = false;
            this.qqForm.get('income').enable();
        }
        else {
            this.noIncome = true;
            this.qqForm.get('income').disable();
            this.sendInvalidReason('Income: None');
        }
    };
    Object.defineProperty(QqComponent.prototype, "invalidLead", {
        get: function () {
            return (this.noId || this.toOld || this.noIncome);
        },
        enumerable: true,
        configurable: true
    });
    QqComponent.prototype.sendInvalidReason = function (reason, values) {
        var _this = this;
        var postData = new PostData();
        postData.age = this.qqForm.get('age').value;
        postData.gender = this.qqForm.get('gender').value;
        if (isUndefined(this.qqForm.get('income').value.replace)) {
            postData.income = this.qqForm.get('income').value;
        }
        else {
            postData.income = this.qqForm.get('income').value.replace(/\D+/g, '');
        }
        postData.education = this.qqForm.get('education').value;
        postData.smoker_status = this.qqForm.get('smoker').value;
        postData.product_code = this.qqForm.get('product').value;
        postData.campaign_code = this.inputData.campaignCode;
        postData.referrer = this.inputData.httpReferrer;
        postData.referrer_code = this.inputData.referrerCode;
        postData.quickquote_source = this.inputData.source;
        postData.leadid = this.inputData.leadId;
        postData.idnumber = this.qqForm.get('rsaId').value;
        if (!isUndefined(values)) {
            for (var key in values) {
                if (values.hasOwnProperty(key)) {
                    postData[key] = values[key];
                }
            }
        }
        postData.error = reason;
        // noinspection JSIgnoredPromiseFromCall
        this.qqService.postInvalidReason(postData).subscribe(function () {
            _this.leadInvalidated = true;
            // post a message to auto wrap sidebar case
            var data = 'invalidate_lead_id';
            if (reason.toLowerCase().indexOf('income') >= 0) {
                data = 'invalidate_lead_income';
            }
            else if (reason.toLowerCase().indexOf('age') >= 0) {
                data = 'invalidate_lead_age';
            }
            if (!isNullOrUndefined(_this.winRef.nativeWindow.parent)) {
                console.log('Sending message: ' + data);
                _this.winRef.nativeWindow.parent.postMessage(data, '*');
            }
        });
    };
    QqComponent.prototype.handlePricingResult = function (response, event) {
        var _this = this;
        if (event) {
            event.preventDefault();
        }
        this.loading = false;
        if (response !== null && response.qq_type === 'MLF') {
            this.qqMlfResult = this.dialog.open(QqMlfComponent, { data: {
                    pricingResponse: response,
                    mainMemberInformation: {
                        id: this.qqMlfForm.get('rsaId').value,
                        dob: this.mlfIdData.dob,
                        age: this.mlfIdData.age,
                        gender: this.mlfIdData.gender,
                        assumed_income: Number(this.qqMlfForm.get('income').value.replace(/\D+/g, '')),
                    },
                    pageInfo: this.component
                },
                disableClose: false,
                panelClass: 'dl-modal',
                maxWidth: '99vw',
            });
        }
        else if (response != null && response.valid) {
            // Got a valid response back. Need to open the cool slider modal.
            this.inputData.needsId = response.needs_id;
            this.inputData.needsBenefitId = response.needs_benefit_id;
            var product = void 0;
            for (var i = 0; i < this.products.length; i++) {
                if (this.products[i].value === this.qqForm.get('product').value) {
                    product = this.products[i];
                    break;
                }
            }
            var modalConfig = {
                position: { top: '20px' },
                panelClass: 'dl-modal',
                maxWidth: '99vw'
            };
            if (this._external) {
                this.qqNoSpResult = this.dialog.open(QqResultNoSpComponent, {
                    panelClass: 'dl-modal',
                    maxWidth: '99vw'
                });
                this.qqNoSpResult.disableClose = true;
                this.qqNoSpResult.componentInstance.openResult(this.inputData, this.qqRequest, this.qqForm.get('rsaId').value, response, product, this);
                this.qqNoSpResult.afterClosed().subscribe(function (closeEvent) {
                    document.body.style.overflowY = 'inherit';
                    _this.resultModalClose(closeEvent);
                    _this.qqNoSpResult = undefined;
                });
            }
            else {
                this.qqResult = this.dialog.open(QQResultComponent, modalConfig);
                this.qqResult.disableClose = true;
                this.qqResult.componentInstance.inputData = this.inputData;
                this.qqResult.componentInstance.openResult(this.qqRequest, this.qqForm.get('rsaId').value, response, product, this);
                this.qqResult.afterClosed().subscribe(function (closeEvent) {
                    document.body.style.overflowY = 'inherit';
                    _this.resultModalClose(closeEvent);
                    _this.qqResult = undefined;
                });
            }
            document.body.style.overflowY = 'hidden';
        }
        else if (response != null && response.errorReason != null) {
            this.errorModalProvider.showErrorMessage(response.errorReason, 'Information');
        }
    };
    QqComponent.prototype.resultModalClose = function (closeEvent) {
        var _this = this;
        this.qqResult = undefined;
        if (closeEvent.cancel) {
            if (this.inputData.leadCapture) {
                if (!isNullOrUndefined(closeEvent.message)) {
                    this.errorModalProvider.showErrorMessage(closeEvent.message, 'Thank you');
                }
                // reset qq input
                this.qqForm.reset();
                this.qqForm.get('product').setValue(this.inputData.productCode);
            }
            return;
        }
        if (closeEvent.error) {
            this.errorModalProvider.showErrorMessage(closeEvent.message, 'Information');
            return;
        }
        if (closeEvent.mobileWarning) {
            setTimeout(function () {
                _this.qqMobileWarning = _this.dialog.open(QQMobileWarningComponent, {
                    panelClass: 'dl-modal',
                    maxWidth: '99vw'
                });
                _this.qqMobileWarning.disableClose = true;
                _this.qqMobileWarning.componentInstance.showWarning(closeEvent.qqResponse, _this);
                _this.qqMobileWarning.afterClosed().subscribe(function (mobileWarningCloseEvent) {
                    document.body.style.overflowY = 'inherit';
                    _this.qqMobileWarning = undefined;
                    if (closeEvent.info || closeEvent.error) {
                        _this.errorModalProvider.showErrorMessage(mobileWarningCloseEvent.message, 'Information');
                    }
                });
                document.body.style.overflowY = 'hidden';
                // may need to send a Google Tag Custom event for this modal...
            });
        }
    };
    QqComponent.prototype.continueToPersonal = function (result) {
        var _this = this;
        setTimeout(function () {
            _this.winRef.nativeWindow.location = result.url;
        }, 50);
    };
    Object.defineProperty(QqComponent.prototype, "autocomplete", {
        get: function () {
            if (this.inputData.embedded) {
                // according to Mozilla.org set the value to an invalid option to fool the browser, their example is nope
                return 'nope';
            }
            return 'on';
        },
        enumerable: true,
        configurable: true
    });
    QqComponent.prototype.selectionChange = function (id) {
    };
    return QqComponent;
}(Dynamic));
export { QqComponent };
