var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { DspInjectable, httpOptions } from '../dsp.service';
import { ErrorModalProvider } from '../../base/error/error.modal.service';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../base/error/error.modal.service";
var BankingValidationService = /** @class */ (function (_super) {
    __extends(BankingValidationService, _super);
    // CDV URL
    // baseUrl = '/dl/data/banking/validate';
    function BankingValidationService(http, errorDialogService) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.errorDialogService = errorDialogService;
        // AVS URL
        _this.baseUrl = '/dl/data/banking/avsValidation';
        return _this;
    }
    // AVS
    BankingValidationService.prototype.validateAccount = function (req) {
        return this.http.post(this.baseUrl, req, httpOptions).pipe(catchError(this.handleError(this.errorDialogService, 'Validation Failed!')));
    };
    BankingValidationService.ngInjectableDef = i0.defineInjectable({ factory: function BankingValidationService_Factory() { return new BankingValidationService(i0.inject(i1.HttpClient), i0.inject(i2.ErrorModalProvider)); }, token: BankingValidationService, providedIn: "root" });
    return BankingValidationService;
}(DspInjectable));
export { BankingValidationService };
var ValidationReqVO = /** @class */ (function () {
    function ValidationReqVO() {
    }
    return ValidationReqVO;
}());
export { ValidationReqVO };
var ValidationResVO = /** @class */ (function () {
    function ValidationResVO() {
    }
    return ValidationResVO;
}());
export { ValidationResVO };
var AVSReqVO = /** @class */ (function () {
    function AVSReqVO() {
    }
    return AVSReqVO;
}());
export { AVSReqVO };
var DebicheckSupportedResVO = /** @class */ (function () {
    function DebicheckSupportedResVO() {
    }
    return DebicheckSupportedResVO;
}());
export { DebicheckSupportedResVO };
var DebicheckSupportedReqVO = /** @class */ (function () {
    function DebicheckSupportedReqVO() {
    }
    return DebicheckSupportedReqVO;
}());
export { DebicheckSupportedReqVO };
var AVSResultVO = /** @class */ (function () {
    function AVSResultVO() {
    }
    return AVSResultVO;
}());
export { AVSResultVO };
