var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { QqDynamicComponent } from './qq.dynamic.component';
var SGResultBlockComponent = /** @class */ (function (_super) {
    __extends(SGResultBlockComponent, _super);
    function SGResultBlockComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SGResultBlockComponent.prototype.setBenefit = function (benefit) {
        this.benefit = benefit;
    };
    SGResultBlockComponent.prototype.setCauseText = function (cause) {
    };
    return SGResultBlockComponent;
}(QqDynamicComponent));
export { SGResultBlockComponent };
