var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, NgZone } from '@angular/core';
import { Dynamic } from './component';
import { Log } from 'ng2-logger/client';
import { StripeScriptService } from '../../base/stripe/stripe.script.service';
var log = Log.create('StripeElementComponent');
var StripeElementComponent = /** @class */ (function (_super) {
    __extends(StripeElementComponent, _super);
    function StripeElementComponent(cd, zone, stripeScript) {
        var _this = _super.call(this) || this;
        _this.cd = cd;
        _this.zone = zone;
        _this.stripeScript = stripeScript;
        _this.busy = false;
        _this.cardValidation = false;
        return _this;
    }
    StripeElementComponent.prototype.getFormControl = function () {
        // This component will mage its own validation.
        return null;
    };
    StripeElementComponent.prototype.onStripeElementStatusChange = function (status) {
        this.busy = status.busy;
        this.error = status.error;
        this.component.isValid = status.valid;
        if (status.token !== null && status.token !== undefined) {
            this.component.value = status.token;
        }
        if (!status.busy) {
            if (this.cardValidation) {
                this.broadCastCardSubmitted();
            }
            else {
                this.broadCastStateChanged();
            }
        }
    };
    // need to override
    StripeElementComponent.prototype.setupFromControl = function () {
        this.stripInput = this.component.extraInput;
        this.component.isValid = false;
        this.component.loaded = true;
        this.loaded.emit(this.component.id);
    };
    StripeElementComponent.prototype.validate = function () {
        this.cardValidation = true;
        this.stripeScript.submitCard();
        return true;
    };
    StripeElementComponent.prototype.broadCastStateChanged = function () {
        var _this = this;
        this.change.emit(this.component.counter);
        setTimeout(function () {
            _this.zone.run(function () {
                log.data('Do zone redraw');
            });
        });
    };
    StripeElementComponent.prototype.broadCastCardSubmitted = function () {
        this.validated.emit({ componentId: this.component.id, success: this.component.isValid });
        this.cardValidation = false;
    };
    return StripeElementComponent;
}(Dynamic));
export { StripeElementComponent };
