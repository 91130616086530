var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { Log } from 'ng2-logger/browser';
import { FormControl, Validators } from '@angular/forms';
import { rsaIdValidation } from '../../base/rsa.id.validator';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../base/base.service';
import { ErrorModalProvider } from '../../base/error/error.modal.service';
import { MatDialog } from '@angular/material';
import { DLUtil } from '../../base/dl.util';
import { WindowRef } from '../../base/window.ref';
var log = Log.create('king-price-ll-life-id');
var LimitedLifeQuickQuoteComponent = /** @class */ (function (_super) {
    __extends(LimitedLifeQuickQuoteComponent, _super);
    function LimitedLifeQuickQuoteComponent(httpClient, errorModalProvider, dialog, winRef) {
        var _this = _super.call(this, httpClient, errorModalProvider) || this;
        _this.httpClient = httpClient;
        _this.errorModalProvider = errorModalProvider;
        _this.dialog = dialog;
        _this.winRef = winRef;
        _this.rsaIdControl = new FormControl('', [Validators.required, Validators.pattern('[0-9]*'), rsaIdValidation(false, _this)]);
        _this.ageControl = new FormControl('', [Validators.required, Validators.pattern('[0-9]*'), Validators.max(64), Validators.min(18)]);
        _this.genderControl = new FormControl('', [Validators.required]);
        _this.valid = false;
        _this.feedback = '';
        _this.assumedIncome = 10000;
        _this.showResults = false;
        _this.loading = false;
        return _this;
        // console.log('Constructor called');
    }
    Object.defineProperty(LimitedLifeQuickQuoteComponent.prototype, "component", {
        get: function () {
            return this._component;
        },
        set: function (value) {
            this._component = value;
        },
        enumerable: true,
        configurable: true
    });
    LimitedLifeQuickQuoteComponent.prototype.ngOnInit = function () {
        this.valid = false;
        // console.log('On init method called');
    };
    LimitedLifeQuickQuoteComponent.prototype.getFormControl = function () {
        return this.rsaIdControl;
    };
    LimitedLifeQuickQuoteComponent.prototype.onIdValidationChange = function (rsaId) {
        this.valid = rsaId.isValid();
        if (rsaId.isValid()) {
            this.feedback = rsaId.getDOB() + ', aged ' + rsaId.getAge() + ' years';
            this.id = rsaId.getId();
            this.dob = rsaId.getDOB();
            this.age = rsaId.getAge();
            this.dobMonth = rsaId.getDOBMonth();
        }
        else {
            this.feedback = 'Provided ID number is invalid';
        }
    };
    LimitedLifeQuickQuoteComponent.prototype.validate = function () {
        this.feedback = !this.ageControl.valid ? 'Age should be from 18 - 64' : !this.genderControl.valid ? 'Please select a gender' : '';
        this.valid = this.ageControl.valid && this.genderControl.valid ? true : false;
    };
    LimitedLifeQuickQuoteComponent.prototype.submit = function () {
        var _this = this;
        this.loading = true;
        var url = '/dl/data/needs/simple-qq';
        this.httpClient.post(url, {
            // 'id': this.id,
            // 'dob': this.dob,
            'age': this.ageControl.value,
            'gender': this.genderControl.value,
            'assumed_income': this.assumedIncome,
            'product_group': 'SG_LLC_F',
            'product_code': 'LLC_F'
        }, this.httpOptions()).subscribe(function (res) { return _this.handleSuccess(res); }, function () { return catchError(_this.handleHTTPError()); });
    };
    LimitedLifeQuickQuoteComponent.prototype.extractGender = function () {
        switch (this.genderControl.value) {
            case 'M': return 'Male';
            case 'F': return 'Female';
        }
        // const genderChar = this.id.substring(6, 7);
        // return Number(genderChar) >= 5 ? 'Male' : 'Female';
    };
    LimitedLifeQuickQuoteComponent.prototype.handleSuccess = function (response) {
        this.options = response['options'];
        this.showResults = true;
        this.loading = false;
        this.initialSliderValues();
    };
    LimitedLifeQuickQuoteComponent.prototype.initialSliderValues = function () {
        if (this.options.length > 1) {
            var initial = Math.ceil(this.options.length * 1);
            this.premium_amount = this.options[initial - 1].premium;
            this.benefit_amount = this.options[initial - 1].cover;
            this._more_cover = this.options[initial - 1].more_cover;
            this._more_premium = this.options[initial - 1].more_premium;
        }
    };
    LimitedLifeQuickQuoteComponent.prototype.updateCover = function ($event) {
        var _this = this;
        this.options.forEach(function (opt) {
            if ($event.value === opt.cover) {
                _this.benefit_amount = opt.cover;
                _this.premium_amount = opt.premium;
                _this._more_cover = opt.more_cover;
                _this._more_premium = opt.more_premium;
            }
        });
    };
    Object.defineProperty(LimitedLifeQuickQuoteComponent.prototype, "name", {
        get: function () {
            return 'Hello';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LimitedLifeQuickQuoteComponent.prototype, "minCover", {
        get: function () {
            return this.options[0].cover;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LimitedLifeQuickQuoteComponent.prototype, "maxCover", {
        get: function () {
            return this.options[this.options.length - 1].cover;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LimitedLifeQuickQuoteComponent.prototype, "increments", {
        get: function () {
            if (this.options.length > 1) {
                return (this.options[1].cover - this.options[0].cover);
            }
            return 5000;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LimitedLifeQuickQuoteComponent.prototype, "more_cover", {
        get: function () {
            return this._more_cover;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LimitedLifeQuickQuoteComponent.prototype, "more_premium", {
        get: function () {
            return this._more_premium;
        },
        enumerable: true,
        configurable: true
    });
    LimitedLifeQuickQuoteComponent.prototype.formatDisplay = function (value) {
        return DLUtil.compactFormat(value, false);
    };
    LimitedLifeQuickQuoteComponent.prototype.moreCover = function () {
        this.continueToLife('/buy/express-life-cover');
        // this.navigate('/buy/express-life-cover');
    };
    LimitedLifeQuickQuoteComponent.prototype.buyNow = function () {
        this.continueToLife('/buy/express-life-funeral');
        // this.navigate('/buy/express-life-funeral');
    };
    LimitedLifeQuickQuoteComponent.prototype.continueToLife = function (location) {
        var _this = this;
        this.loading = true;
        var url = '/dl/data/needs/limited-life-qq-continue-to-life';
        this.httpClient.post(url, {
            'person': {
                // 'id': this.id,
                // 'dob': this.dob,
                'age': this.ageControl.value,
                'gender': this.genderControl.value,
                'assumed_income': this.assumedIncome,
            },
            // 'id': this.id, 
            'selected_premium': this.premium_amount,
            'selected_cover': this.benefit_amount
        }, this.httpOptions()).subscribe(function (res) { return _this.navigate(location); });
    };
    LimitedLifeQuickQuoteComponent.prototype.navigate = function (url) {
        this.winRef.nativeWindow.location = url;
    };
    return LimitedLifeQuickQuoteComponent;
}(BaseService));
export { LimitedLifeQuickQuoteComponent };
