<div [formGroup]="banking">
    <div *ngIf="showAltOptions" class="dl-row row">
        <div class="dl-col-sm-6 col s6">
            <mat-radio-group tabIndex="2" aria-label="PremiumPayerSelection"
                [formControl]="banking.controls['altPremiumPayer']" data-name="premiumPayerMode"
                [attr.kings-watch]="flowId + '-' + pageName + '-' + 'banking.premium-payer'">
                <mat-radio-button (change)="setAltPremiumPayer(false)" value="defaultPremiumPayer"
                    [checked]="defaultPremiumPayerEnabled">Policy holder will pay the premium</mat-radio-button>
                &nbsp;&nbsp;
                <mat-radio-button (change)="setAltPremiumPayer(true)" value="altPremiumPayer">Someone else will pay the
                    premium</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="row">
        <div class="col s12 m6" data-comp-id="bank.bank">
            <mat-form-field class="dl-full-width">
                <mat-select tabindex="1" placeholder="Select your bank" [formControl]="banking.controls['bank']"
                    [attr.data-name]="'banking.bank'" id="bank"
                    [attr.kings-watch]="flowId + '-' + pageName + '-' + 'banking.bank'"
                    (selectionChange)="selectionChange('bank')">
                    <mat-option *ngFor="let item of bankingInput.bankNames" [value]="item.id" id="{{item.id}}"
                        [attr.data-name]="item.id" [attr.data-val]="item.id">{{item.display}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col s12 m6" data-comp-id="bank.branch">
            <mat-form-field class="dl-full-width">
                <input tabindex="1" [formControl]="banking.controls['branch']" matInput
                    [attr.kings-watch]="flowId + '-' + pageName + '-' + 'banking.branch'" placeholder="Branch code"
                    [attr.data-name]="'banking.branch'">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col s12 m6" data-comp-id="bank.accountType">
            <mat-form-field class="dl-full-width">
                <mat-select tabindex="1" [formControl]="banking.controls['accountType']" placeholder="Account type"
                    [attr.data-name]="'banking.account-type'"
                    [attr.kings-watch]="flowId + '-' + pageName + '-' + 'banking.account-type'" id="accountType"
                    (selectionChange)="selectionChange('accountType')">
                    <mat-option *ngFor="let item of bankingInput.accountTypes" [value]="item.id" id="{{item.id}}"
                        [attr.data-name]="item.id" [attr.data-val]="item.id">{{item.display}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col s12 m6" data-comp-id="bank.accountNo">
            <mat-form-field class="dl-full-width">
                <input tabindex="1" [formControl]="banking.controls['accountNo']" matInput
                    [attr.kings-watch]="flowId + '-' + pageName + '-' + 'banking.account-number'"
                    placeholder="Account number" [attr.data-name]="'banking.account-number'">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col s12 m6" data-comp-id="bank.accountHolder">
            <mat-form-field class="dl-full-width">
                <input tabindex="1" [formControl]="banking.controls['accountHolder']" matInput
                    placeholder="Account holder"
                    [attr.kings-watch]="flowId + '-' + pageName + '-' + 'banking.account-holder'"
                    [attr.data-name]="'banking.account-holder'">
            </mat-form-field>
        </div>
        <div *ngIf="canOverride || overrideApproved" class="col s12 m6 dl-fade in">
            <button mat-raised-button color="primary" tabindex="2" type="button" id="validate-banking-failed-override"
                [disabled]="overrideBusy || overrideApproved" (click)="requestOverride()">{{overrideText}}&nbsp;
                <span *ngIf="overrideApproved && !overrideBusy"><i class="fa fa-check"></i></span>
                <span *ngIf="!banking.pristine && !overrideApproved && !overrideBusy"><i class="fa fa-times"></i></span>
                <span *ngIf="overrideBusy"><i class="fa fa-circle-o-notch fa-spin"></i></span>
            </button>
        </div>
    </div>
</div>
