<div *ngIf="!isLink">
    <button
        mat-raised-button
        tabindex="1"
        class="dl-full-width dl-alternate"
        [attr.data-name]="component.id"
        [attr.data-event]="'true'"
        [attr.data-event-category]="'DSP-' + productCode"
        [attr.data-event-name]="pageName + ' - AJAX ' + component.id"
        [attr.data-event-label]="'AJAX ' + component.name"
        (click)="click()" [disabled]="isDisabled || isBusy">
        {{component.name}}&nbsp;<i *ngIf="isBusy" class="fa fa-circle-o-notch fa-spin"></i>
    </button>
</div>
<div *ngIf="isLink">
    <a
        href="#"
        tabindex="1"
        class="dl-full-width"
        [attr.data-name]="component.id"
        [attr.data-event]="'true'"
        [attr.data-event-category]="'DSP-' + productCode"
        [attr.data-event-name]="pageName + ' - AJAX ' + component.id"
        [attr.data-event-label]="'AJAX ' + component.name"
        (click)="click()">
        {{component.name}}&nbsp;<i *ngIf="isBusy" class="fa fa-circle-o-notch fa-spin"></i>
    </a>
</div>
