<div *ngFor="let disclosure of disclosures" class="dis-question">
    <label class="dis-question-head" [innerHTML]="disclosure.name"></label>
    <div class="dl-row row">
        <p class="col {{disclosure.type == 'YesNoMaybe' ? 'dl-col-lg-9 m9 s12' : 'dl-col-lg-10 m10 s12'}} disclosure-wording" [innerHTML]="disclosure.content"></p>
        <div class="col {{disclosure.type == 'YesNoMaybe' ? 'dl-col-lg-3 m3 s12' : 'dl-col-lg-2 m2 s12'}} answer-buttons-wrapper">
            <mat-checkbox
                *ngIf="disclosure.type == 'Ok'"
                (change)="disclosure.answer = $event.checked ? 'ok' : ''">OK</mat-checkbox>
            <mat-radio-group
                *ngIf="disclosure.type != 'Ok'"
                align="start"
                (change)="disclosure.answer = $event.value">
                <mat-radio-button [attr.data-val]="'yes'" value="yes">Yes</mat-radio-button>
                <mat-radio-button *ngIf="disclosure.type == 'YesNoMaybe'" [attr.data-val]="'dont_know'" value="dont_know">Maybe</mat-radio-button>
                <mat-radio-button  [attr.data-val]="'no'" value="no">No</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div *ngIf="disclosure.answer == 'no'" class="dl-row row">
        <p class="col sm12 dl-col-lg-12 disclosure-stop-message help-block">{{disclosure.stop_message}}</p>
    </div>
</div>
