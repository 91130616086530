import { Validators } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { PhoneNumberValidator } from './phone-number';
var DLUtil = /** @class */ (function () {
    function DLUtil() {
    }
    DLUtil.buildFormGroup = function (data, options) {
        if (isNullOrUndefined(options)) {
            options = {};
        }
        if (isNullOrUndefined(options.allRequired)) {
            options.allRequired = false;
        }
        if (isNullOrUndefined(options.required)) {
            options.required = [];
        }
        if (isNullOrUndefined(options.notRequired)) {
            options.notRequired = [];
        }
        if (isNullOrUndefined(options.ignore)) {
            options.ignore = [];
        }
        var formGroup = {};
        var _loop_1 = function (key) {
            if (key === 'id') {
                return "continue";
            }
            if (options.ignore.indexOf(key) >= 0) {
                return "continue";
            }
            var validators = [];
            if ((options.allRequired && options.notRequired.indexOf(key) === -1) || options.required.indexOf(key) >= 0) {
                validators.push(Validators.required);
            }
            if (key.indexOf('phone') >= 0 || key.indexOf('tel_no') >= 0) {
                validators.push(PhoneNumberValidator.validator());
            }
            else if (key.indexOf('email') >= 0) {
                validators.push(Validators.email);
                validators.push(Validators.pattern(DLUtil.emailPattern));
            }
            else if (key.indexOf('id_number') >= 0) {
                validators.push(Validators.pattern(DLUtil.numberPattern));
            }
            if (!isNullOrUndefined(options.extraValidators)) {
                options.extraValidators.forEach(function (v) {
                    if (v.field === key) {
                        v.validators.forEach(function (v2) { return validators.push(v2); });
                    }
                });
            }
            formGroup[key] = ['', validators];
        };
        for (var key in data) {
            _loop_1(key);
        }
        return formGroup;
    };
    DLUtil.setFormFields = function (form, data) {
        for (var key in data) {
            if (form.contains(key) && !isNullOrUndefined(data[key])) {
                form.get(key).setValue(data[key]);
            }
        }
    };
    DLUtil.copyFields = function (source, destination, except) {
        if (isNullOrUndefined(except)) {
            except = [];
        }
        for (var key in source) {
            if (!isNullOrUndefined(source[key]) && !(except.includes(key))) {
                destination[key] = source[key];
            }
        }
    };
    DLUtil.knownAsPopulator = function (form, firstNameField, knownAsField) {
        if (isNullOrUndefined(form.get(knownAsField).value) || form.get(knownAsField).value.length === 0) {
            form.get(firstNameField).valueChanges.subscribe(function () {
                var firstNames = form.get(firstNameField).value;
                if (!isNullOrUndefined(firstNames)) {
                    var knownAsControl = form.get(knownAsField);
                    if (knownAsControl.pristine) {
                        var firstNamesList = firstNames.split(' ');
                        if (firstNamesList.length >= 0) {
                            knownAsControl.setValue(firstNamesList[0]);
                        }
                    }
                }
            });
        }
    };
    DLUtil.setTitleFromGender = function (gender, form, titleField) {
        var title = form.get(titleField).value;
        if (gender === 'Male' && (isNullOrUndefined(title) || title === '' || title === 'Ms.' || title === 'Mrs.')) {
            form.get(titleField).setValue('Mr.');
        }
        else if (gender === 'Female' && (isNullOrUndefined(title) || title === '' || title === 'Mr.')) {
            form.get(titleField).setValue('Ms.');
        }
    };
    DLUtil.compactFormat = function (value, showFraction) {
        if (value > 999999) {
            return this.getCompactFormat(value, 1000000, 'M', true);
        }
        else if (value > 999) {
            return this.getCompactFormat(value, 1000, 'K', showFraction);
        }
        else {
            return Math.round(value).toString();
        }
    };
    DLUtil.getCompactFormat = function (val, divVal, postFix, showFraction) {
        var _a = ((val / divVal) || '').toString().split('.'), intValue = _a[0], _b = _a[1], fraction = _b === void 0 ? '' : _b;
        // remove tslint error in ide.
        intValue = intValue + '';
        fraction = fraction + '00000';
        if (showFraction) {
            return intValue + '.' + fraction.substring(0, 1) + postFix;
        }
        else {
            return intValue + postFix;
        }
    };
    DLUtil.formatDateToString = function (date) {
        var dateString = '' + date.getFullYear();
        dateString += (date.getMonth() < 9 ? '-0' : '-') + (date.getMonth() + 1);
        dateString += (date.getDate() < 10 ? '-0' : '-') + date.getDate();
        return dateString;
    };
    DLUtil.calculateAge = function (theDate) {
        var today = new Date();
        var age = today.getFullYear() - theDate.get('year');
        var m = today.getMonth() - theDate.get('month');
        if (m < 0 || (m === 0 && today.getDate() < theDate.get('date'))) {
            age--;
        }
        return age;
    };
    DLUtil.telMask = {
        mask: ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    };
    DLUtil.emailPattern = '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$';
    DLUtil.numberPattern = '[0-9]*';
    DLUtil.verificationType = [
        { value: 'idNumber', label: 'Id Number' },
        { value: 'passportNumber', label: 'Passport' },
    ];
    DLUtil.salutations = [
        { value: 'Mr.', label: 'Mr' },
        { value: 'Ms.', label: 'Ms' },
        { value: 'Mrs.', label: 'Mrs' },
        { value: 'Dr.', label: 'Dr' },
        { value: 'Prof.', label: 'Prof' }
    ];
    DLUtil.relationOptions = [
        { value: 'spouse', label: 'Spouse' },
        { value: 'child', label: 'Child' },
        { value: 'parent', label: 'Parent' },
        { value: 'other', label: 'Other' }
    ];
    DLUtil.beneficiaryRelationOptions = [
        { value: 'spouse', label: 'Spouse' },
        { value: 'child', label: 'Child' },
        { value: 'parent', label: 'Parent' },
        { value: 'estate', label: 'Estate' },
        { value: 'other', label: 'Other' }
    ];
    DLUtil.beneficiaryChangeReasons = [
        { value: 'beneficiary_deceased', label: 'Beneficiary deceased' },
        { value: 'relationship_with_beneficiary_terminated', label: 'Relationship with beneficiary terminated' },
        { value: 'addition_to_beneficiaries', label: 'Addition to beneficiaries' },
        { value: 'change_benefit_payout_distribution', label: 'Change benefit payout distribution' },
        { value: 'other', label: 'Other' }
    ];
    DLUtil.numMask = {
        mask: function (rawValue) {
            var numValue = rawValue.replace(/\D+/g, '');
            var mask = [];
            for (var i = numValue.length - 1; i >= 0; i--) {
                if ((numValue.length - i) > 2 && (numValue.length - i) % 3 === 1) {
                    mask.unshift(',');
                }
                mask.unshift(/\d/);
            }
            return mask;
        }
    };
    DLUtil.numMaskWith2Decimal = {
        mask: function (rawValue) {
            var parts = rawValue.split('.');
            var numValue = parts[0].replace(/\D+/g, '');
            var mask = [];
            for (var i = numValue.length - 1; i >= 0; i--) {
                if ((numValue.length - i) > 2 && (numValue.length - i) % 3 === 1) {
                    mask.unshift(',');
                }
                mask.unshift(/\d/);
            }
            if (parts.length === 2) {
                var fraction = parseInt(parts[1].replace(/\D+/g, ''), 10);
                mask.push('.');
                if (fraction < 10) {
                    mask.push('0');
                }
                else {
                    mask.push(/\d/);
                }
                mask.push(/\d/);
            }
            else {
                mask.push('.');
                mask.push('0');
                mask.push('0');
            }
            return mask;
        }
    };
    return DLUtil;
}());
export { DLUtil };
