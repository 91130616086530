import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/index';

export const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class DotOrgDataService {

  constructor(private http: HttpClient) { }

  public getActiveProjects(area: string): Observable<ProjectVO[]> {
      return this.http.get<ProjectVO[]>(`/dl/data/donation/${area}/projects`);
  }

}

export interface ProjectVO {
    id: string;
    projectName: string;
    projectDescription: string;
    startDate: Date;
    endDate: Date;
    requiredAmount: number;
    fundedAmount: number;
    category: string;
    imageUrl: string;
    videoUrl: string;
    parent: {
        name: string;
    };
}
