import { WindowRef } from './window.ref';
import * as i0 from "@angular/core";
import * as i1 from "./window.ref";
/**
 * A simple service that can be used to check screen size.
 * At the moment we only support sizes [lg,md,sm,xs]
 * This service can be extended for other html5 match media tasks as needed.
 */
var MatchMediaService = /** @class */ (function () {
    /**
     * injecct ref to the window object. Dont want to use this directly in TS angular 2+
     * @param windowRef
     */
    function MatchMediaService(windowRef) {
        this.windowRef = windowRef;
        this.defaultRules = {
            lg: '(min-width: 1200px)',
            md: '(min-width: 992px) and (max-width: 1199px)',
            sm: '(min-width: 768px) and (max-width: 991px)',
            xs: '(max-width: 767px)'
        };
    }
    /**
     * At the moment this method will only check for the Bootstrap 3 sizes [lg,md,sm,xs]
     * And will return false for all other size inputs.
     * @param sizes
     * @returns {boolean}
     */
    MatchMediaService.prototype.is = function (sizes) {
        for (var i = 0; i < sizes.length; i++) {
            if (this.defaultRules.hasOwnProperty(sizes[i])) {
                var rule = this.defaultRules[sizes[i]];
                if (this.windowRef.nativeWindow.matchMedia(rule).matches) {
                    return true;
                }
            }
        }
        return false;
    };
    MatchMediaService.ngInjectableDef = i0.defineInjectable({ factory: function MatchMediaService_Factory() { return new MatchMediaService(i0.inject(i1.WindowRef)); }, token: MatchMediaService, providedIn: "root" });
    return MatchMediaService;
}());
export { MatchMediaService };
