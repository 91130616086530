import {Injectable} from '@angular/core';
import {Stripe} from './stripe.types';
import {Observable, Subject} from 'rxjs';

/**
 * Lazy load the stripe js.
 */
@Injectable({
    providedIn: 'root'
})
export class StripeScriptService {

    scriptSrc = 'https://js.stripe.com/v3/';
    stripe: Stripe;

    submitForTokenSubscription = new Subject<boolean>();

    public injectStipeElement(): Promise<Stripe> {
        if (window['Stripe']) {
            return Promise.resolve(window['Stripe']);
        }

        return new Promise((res, rej) => {
            const elm = this.getTargetScriptElement();
            const script = document.createElement('script');
            script.setAttribute('src', this.scriptSrc);
            script.setAttribute('type', 'text/javascript');

            script.addEventListener('load', () => {
                this.stripe = window['Stripe'];
                res( window['Stripe'] );
            });

            elm.appendChild(script);
        });
    }

    private getTargetScriptElement(): any {
        const elm = document.getElementsByTagName('head');
        if (elm.length) {
            return elm[0];
        }
        return document.getElementsByTagName('body')[0];
    }

    public getSubmitTokenObservable(): Observable<boolean> {
        return this.submitForTokenSubscription.asObservable();
    }

    public submitCard(): void {
        this.submitForTokenSubscription.next(true);
    }
}
