import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ErrorModalProvider} from './error/error.modal.service';
import {ErrorVO} from './error/error.vo';
import {Observable, of, throwError} from 'rxjs';
import {isNullOrUndefined} from 'util';


export abstract class BaseService {

    protected httpOptions(withCredentials?: boolean) {
        if (!isNullOrUndefined(withCredentials) && withCredentials) {
            return {headers: new HttpHeaders({'Content-Type': 'application/json'}), withCredentials: true};
        }
        return {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    }

    protected constructor(
        protected httpClient: HttpClient,
        protected errorModalProvider: ErrorModalProvider
    ) {}

    public handleHTTPError<T>(heading?: string, result?: T) {
        return (error: any): Observable<T> => {
            const vo = ErrorVO.toErrorVO(error);
            if (isNullOrUndefined(result)) {
                this.errorModalProvider.showError(vo, heading);
                return throwError(vo);
            }
            return of(result as T);
        };
    }

    public handleHTTPErrorEmbedded<T>(embedded: boolean, heading?: string, result?: T) {
        return (error: any): Observable<T> => {
            const vo = ErrorVO.toErrorVO(error);
            if (isNullOrUndefined(result)) {
                if (embedded) {
                    this.errorModalProvider.showError(vo, heading);
                } else {
                    let err = 'Sorry, something went wrong validating your details. An agent will contact you';
                    this.errorModalProvider.showErrorMessage(err);
                }
                return throwError(vo);
            }
            return of(result as T);
        };
    }
}
