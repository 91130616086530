import {Component, ViewChild, AfterViewInit} from '@angular/core';
import {DisclosureCategory, DisclosureType, DisclosureVO} from './disclosures.vos';
import {DisclosuresService} from './disclosures.service';
import {MatDialogRef} from '@angular/material';
import {isNullOrUndefined} from 'util';
import {DisclosuresComponent} from "./disclosures.component";

export interface DisclosuresCallback {
    onDisclosuresLoaded(): void;
    onDisclosuresSaved(disclosures: DisclosureVO[]): void;
    onDisclosuresClosed(): void;
    onDisclosuresClosing(disclosures: DisclosureVO[]): boolean;
    onDisclosuresFailed(): void;
}

@Component({
    selector: 'disclosures-modal',
    templateUrl: './disclosures-modal.component.html'
})
export class DisclosuresModalComponent {

    static readonly DEFAULT_TITLE = 'Important Information';

    prefix: string;
    page: string;
    type: string;
    disclosures: DisclosureVO[];
    saving = false;
    stopping = false;
    title: string = DisclosuresModalComponent.DEFAULT_TITLE;
    callback: DisclosuresCallback;
    canClose = false;
    canCloseAlways = false;
    _category: string;
    set category(val: string) {
      if (val === DisclosureCategory.SALES_PROCESS) {
        this._category = 'Sales Process';
      } else {
        this._category = val;
      }
    }
    get category(): string {
      if (isNullOrUndefined(this._category)) {
        this.category = DisclosureCategory.SALES_PROCESS;
      }
      return this._category;
    }

    @ViewChild('disclosuresView')
    private disclosuresView: DisclosuresComponent;

    constructor(
        private disclosuresService: DisclosuresService,
        public dialogRef: MatDialogRef<DisclosuresModalComponent>
    ) {}

    get busy(): boolean {
        return this.saving || this.stopping;
    }

    get canSave(): boolean {
        if (this.saving || this.stopping) {
            return false;
        }
        return this.disclosuresView.isValid();
    }

    showStop(): boolean {
        return !this.canClose && this.containsStop();
    }

    showClose(): boolean {
        return this.canClose && this.containsStop() || this.callback && this.canCloseAlways;
    }

    private containsStop(): boolean {
        if (isNullOrUndefined(this.disclosures) || this.disclosures.length === 0) {
            return false;
        }
        for (const disclosure of this.disclosures) {
            if (!isNullOrUndefined(disclosure.stop_message) &&
                    disclosure.stop_message.length > 0 && disclosure.answer === 'no') {
                return true;
            }
        }
        return false;
    }

    saveDisclosures(): void {
        const disc: string = this.page + this.type;

        this.saving = true;
        if (this.type !== DisclosureType.INFO) {
          this.disclosuresService.save(this.disclosures).subscribe(res => {
            this.handleSave(res.success);
          });
        } else {
          this.handleSave(true);
        }
    }

    private handleSave(result: boolean): void {
        if (result) {
            // we keep the saving spinner running on the exit disclosure
            // as the page will be saving its data and redirecting to the next page
            if (this.type !== DisclosureType.EXIT) {
                this.saving = false;
                this.dialogRef.close();
            } else {
                this.callback.onDisclosuresSaved(this.disclosures);
            }
        } else {
            this.saving = false;
        }
    }

    public stopAndExit(): void {
        this.stopping = true;
        this.disclosuresService.stop(this.prefix, this.page, this.type, this.disclosures).subscribe(res => {
            location.href = res.out_page;
        });
    }

    public close(): void {
        if (!isNullOrUndefined(this.callback)) {
            if (this.callback.onDisclosuresClosing(this.disclosures)) {
                this.stopAndExit();
                return;
            } else {
                this.callback.onDisclosuresClosed();
            }
        }
        this.dialogRef.close();
    }

    public setDisclosureCategory(category: DisclosureCategory) {
      this.category = category;
    }
}
