import { CloseEventData } from './qq.vo';
import { QQService } from './qq.service';
import { MatDialogRef } from '@angular/material';
import { WindowRef } from '../base/window.ref';
/**
 * Created by corneliusbotha on 2017/02/24.
 * Modal component that will pop up to give mobile user a few extra options
 */
var QQMobileWarningComponent = /** @class */ (function () {
    function QQMobileWarningComponent(winRef, qqService, qqWarningDialogRef) {
        this.winRef = winRef;
        this.qqService = qqService;
        this.qqWarningDialogRef = qqWarningDialogRef;
        this.loadingCall = false;
        this.continuing = false;
        this.pleaseCallBtnText = 'Please call me';
    }
    QQMobileWarningComponent.prototype.showWarning = function (qqResult, qqContinue) {
        this.continuing = false;
        this.qqResult = qqResult;
        this.qqContinue = qqContinue;
    };
    QQMobileWarningComponent.prototype.resetCallMeButtonAndHide = function () {
        this.loadingCall = false;
        this.pleaseCallBtnText = this.tmpPleaseCallBtnText;
    };
    QQMobileWarningComponent.prototype.close = function () {
        this.qqWarningDialogRef.close(CloseEventData.createCancelEvent());
    };
    QQMobileWarningComponent.prototype.callMe = function () {
        var _this = this;
        this.tmpPleaseCallBtnText = this.pleaseCallBtnText;
        this.pleaseCallBtnText = 'Sending info... ';
        this.loadingCall = true;
        this.qqService.requestCallMe().subscribe(function () {
            _this.resetCallMeButtonAndHide();
            _this.qqWarningDialogRef.close(CloseEventData.createInvoEvent('Thank you, we will be in contact shortly.'));
        }, function () {
            _this.resetCallMeButtonAndHide();
            _this.qqWarningDialogRef.close(CloseEventData.createErrorEvent('We regrettably could not process your request.'));
        });
    };
    QQMobileWarningComponent.prototype.emailMe = function () {
        this.qqWarningDialogRef.close(CloseEventData.createInvoEvent('Thank you, we have sent you an email with a link in it. ' +
            'You can, at any time, continue this process on a larger device.'));
    };
    QQMobileWarningComponent.prototype.continueToSp = function () {
        this.continuing = true;
        // We are now moving to the SP:
        this.qqContinue.continueToPersonal(this.qqResult);
    };
    Object.defineProperty(QQMobileWarningComponent.prototype, "busy", {
        get: function () {
            return this.continuing || this.loadingCall;
        },
        enumerable: true,
        configurable: true
    });
    return QQMobileWarningComponent;
}());
export { QQMobileWarningComponent };
