import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatchMediaService} from './match.media.service';
import {DateAdapter} from '@angular/material';
import {AbstractControl, FormControl, ValidatorFn, Validators} from '@angular/forms';
import {Moment} from 'moment';
import {LocaleHelper} from './locale.helper';
import {DLUtil} from './dl.util';

@Component({
    selector: 'dl-date-picker-input',
    templateUrl: 'date.picker.component.html'
})
export class DatePickerComponent {

    constructor(private matchMedia: MatchMediaService,
                private adapter: DateAdapter<any>) {
        this.adapter.setLocale(LocaleHelper.getCurrentLocale());
        this.formControlRef.valueChanges.subscribe(() => this.dateInputChanged());
        setTimeout(() => this.setupControl());
    }

    _initDate: Date;
    _initValue: string;
    @Input()
    set initValue(val: string) {
        this._initValue = val;
        this._initDate = new Date(this._initValue);
        this.formControlRef.setValue(this.initValue);
    }
    get initValue() {
        return this._initValue;
    }

    _startView: 'month' | 'year' | 'multi-year' = 'month';
    @Input()
    set startView(val: 'month' | 'year' | 'multi-year') {
        this._startView = val;
    }
    get startView() {
        return this._startView;
    }

    _dob: boolean;
    @Input()
    set isDob(val: boolean) {
        this._dob = val;
    }
    get isDob() {
        if (this._dob === null || this._dob === undefined) {
            return false;
        }
        return this._dob;
    }

    _minAge: number;
    @Input()
    set minAge(val: number) {
        this._minAge = val;
        setTimeout(() => this.setupControl());
    }
    get minAge() {
        return this._minAge;
    }

    _maxAge: number;
    @Input()
    set maxAge(val: number) {
        this._maxAge = val;
        setTimeout(() => this.setupControl());
    }
    get maxAge() {
        return this._maxAge;
    }

    _dateControl: FormControl = new FormControl('');
    @Input()
    set formControlRef(val: FormControl) {
        if (val !== null && val !== undefined) {
            this._dateControl = val;
            if (val.value !== null && val.value !== undefined && val.value !== '') {
                // always assume that we receive the original in ISO format.
                this._initDate = new Date(val.value);
            }
        }
        setTimeout(() => this.setupControl());
    }
    get formControlRef(): FormControl {
        return this._dateControl;
    }

    _required = false;
    @Input()
    set required(val: boolean) {
        this._required = val;
        setTimeout(() => this.setupControl());
    }
    get required() {
        return this._required;
    }

    _format = 'DD/MM/YYYY';
    @Input()
    set format(val: string) {
        this._format = val;
    }
    get format() {
        return this._format;
    }

    _placeHolder = 'Date';
    @Input()
    set placeHolder(val: string) {
        this._placeHolder = val;
    }
    get placeHolder() {
        return this._placeHolder;
    }

    _name: string;
    @Input()
    set name(val: string) {
        this._name = val;
    }
    get name() {
        return this._name;
    }

    get locale(): string {
        return LocaleHelper.getCurrentLocale();
    }

    _startDate: Date;
    set startDate(val: Date) {
        this._startDate = val;
    }
    get startDate() {
        return this._startDate;
    }

    get displayDate(): string {
        if (this.formControlRef.value !== null && this.formControlRef.value !== undefined) {
            if (typeof this.formControlRef.value === 'string') {
                const date = new Date(this.formControlRef.value);
                if (date != null) {
                    return date.toDateString();
                }
            } else if ('toDateString' in this.formControlRef.value) {
                return this.formControlRef.value.toDateString();
            } else if ('format' in this.formControlRef.value) {
                return this.formControlRef.value.format('Do MMMM, YYYY');
            }
        }
        return null;
    }

    @Output()
    valueChanged = new EventEmitter<Date>();

    static validDate(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            const theDate = control.value as Moment;
            if (theDate === null || theDate === undefined) {
                return {invalid: 'Invalid date'};
            } else {
                return null;
            }
        };
    }

    static ageBetween(comp: DatePickerComponent): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (comp.minAge !== null && comp.minAge !== undefined && comp.maxAge !== null && comp.maxAge !== undefined) {
                const theDate = control.value as Moment;
                if (theDate === null) {
                    return null;
                }
                const age = DLUtil.calculateAge(theDate);
                if (age >= comp.minAge && age <= comp.maxAge) {
                    return null;
                }
                return {invalidAge: 'Age must be between ' + comp.minAge + ' and ' + comp.maxAge};
            }
            return null;
        };
    }

    isXs() {
        return this.matchMedia.is(['xs']);
    }

    private dateInputChanged() {
        if (this.formControlRef.value !== undefined && this.formControlRef.value !== null && this.formControlRef.value !== '') {
            const date = new Date(this.formControlRef.value);
            this.valueChanged.emit(date);
        } else {
            this.valueChanged.emit(null);
        }
    }

    private setupControl() {
        if (this.required) {
            this.formControlRef.setValidators([Validators.required, DatePickerComponent.validDate(), DatePickerComponent.ageBetween(this)]);
        } else {
            this.formControlRef.setValidators([DatePickerComponent.validDate(), DatePickerComponent.ageBetween(this)]);
        }
        if (this.isDob && this.minAge !== undefined && this.minAge !== null) {
            this.startDate = new Date();
            this.startDate.setFullYear(this.startDate.getFullYear() - this.minAge);
        }
    }


}
