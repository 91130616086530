import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {ReactiveFormsModule} from '@angular/forms';
import {BaseModule} from '../base.module';
import {MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatRadioModule, MatDialogModule,
    MatAutocompleteModule} from '@angular/material';
import {DlBankingComponent} from './dl.banking.component';
import {DlPayerModalComponent} from '../payer/dl.payer.modal.component';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BaseModule,
        MatSelectModule,
        MatInputModule,
        MatButtonModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatRadioModule,
        MatDialogModule,
        MatAutocompleteModule
    ],
    declarations: [
        DlBankingComponent,
        DlPayerModalComponent
    ],
    exports: [
        DlBankingComponent
    ],
    entryComponents: [
        DlPayerModalComponent
    ]
})
export class DlBankingModule {}
