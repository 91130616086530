var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter, OnInit } from '@angular/core';
import { Dynamic } from './component';
import { isNullOrUndefined } from 'util';
import { MatSlider } from '@angular/material';
import { DLUtil } from '../../base/dl.util';
import { BehaviorSubject, of } from 'rxjs';
import { CustomiseValueVO } from '../page.data.vo';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { PricingService, SimplePremiumRequest, SimplePremiumResponse } from '../../base/pricing/pricing.service';
import { OfferVO } from '../../base/dl.offer-text.pipe';
var CustomiseCoverComponent = /** @class */ (function (_super) {
    __extends(CustomiseCoverComponent, _super);
    function CustomiseCoverComponent(pricingService) {
        var _this = _super.call(this) || this;
        _this.pricingService = pricingService;
        _this.premiumsCache = new Map();
        return _this;
    }
    CustomiseCoverComponent.prototype.ngOnInit = function () {
        // We start out with valid values
        this.component.isValid = true;
        if (!isNullOrUndefined(this.change)) {
            this.change.emit(this.component.counter);
        }
        // SS-406 - fix slider bug by forcing an event on init of slider.
        this.changeEvents$.emit(new ChangeEvent(this.termSlider.value, this.coverSlider.value));
        this.component.isValid = false;
        if (!isNullOrUndefined(this.change)) {
            this.change.emit(this.component.counter);
        }
    };
    CustomiseCoverComponent.prototype.onChange = function () {
        this.changeEvents$.emit(new ChangeEvent(this.termSlider.value, this.coverSlider.value));
        this.component.isValid = false;
        if (!isNullOrUndefined(this.change)) {
            this.change.emit(this.component.counter);
        }
    };
    CustomiseCoverComponent.prototype.processExtraData = function () {
        this.in = this.component.extraInput;
    };
    CustomiseCoverComponent.prototype.setupFromControl = function () {
        var _this = this;
        this.productGroup = this.groupId + '_' + this.productCode;
        this.initialValue = this.component.value;
        // Cache initial values as pricing response
        var response = new SimplePremiumResponse();
        response.version = 'NA';
        response.premium = this.initialValue.premium;
        this.premiumsCache.set(this.initialValue.cover + '_' + this.initialValue.term, response);
        // Publish initial values to view
        var offer = this.newOfferVO();
        offer.term = this.initialValue.term;
        offer.cover = this.initialValue.cover;
        offer.premium = this.initialValue.premium;
        this.offer$ = new BehaviorSubject(offer);
        // Subscribe to slider change events
        this.termSlider.valueChange.subscribe(function () { return _this.onChange(); });
        this.coverSlider.valueChange.subscribe(function () { return _this.onChange(); });
        // Initialize an Observable for consolidated, debounced change events
        this.changeEvents$ = new EventEmitter();
        this.changeEvents$.pipe(debounceTime(400), distinctUntilChanged(function (a, b) {
            return a.term === b.term && a.cover === b.cover;
        })).subscribe(
        // Update premiums for change events
        function ($event) { return _this.fetchPremium($event); });
        this.component.loaded = true;
        // tel my parent I'm loaded.
        this.loaded.emit(this.component.id);
    };
    CustomiseCoverComponent.prototype.getFormControl = function () {
        return null;
    };
    CustomiseCoverComponent.prototype.formatTermForDisplay = function (value) {
        return DLUtil.compactFormat(value, true);
    };
    CustomiseCoverComponent.prototype.formatCoverForDisplay = function (value) {
        return DLUtil.compactFormat(value, false);
    };
    CustomiseCoverComponent.prototype.termUp = function () {
        this.incrementSlider(this.termSlider);
    };
    CustomiseCoverComponent.prototype.termDown = function () {
        this.decrementSlider(this.termSlider);
    };
    CustomiseCoverComponent.prototype.coverUp = function () {
        this.incrementSlider(this.coverSlider);
    };
    CustomiseCoverComponent.prototype.coverDown = function () {
        this.decrementSlider(this.coverSlider);
    };
    CustomiseCoverComponent.prototype.fetchPremium = function ($event) {
        var _this = this;
        this.offer$.next(null);
        var request = this.buildPremiumRequest($event.cover, $event.term);
        var observable;
        if (this.premiumsCache.has(request.sumAssured + '_' + request.term)) {
            observable = of(this.premiumsCache.get(request.sumAssured + '_' + request.term));
        }
        else {
            observable = this.pricingService.requestPremium(request);
        }
        observable.subscribe(function (response) { return _this.applyUpdate($event, response, request); });
    };
    CustomiseCoverComponent.prototype.applyUpdate = function ($event, response, request) {
        var newValue = new CustomiseValueVO();
        newValue.term = $event.term;
        newValue.cover = $event.cover;
        newValue.premium = response.premium;
        this.component.value = newValue;
        this.premiumsCache.set(request.sumAssured + '_' + request.term, response);
        var offer = this.newOfferVO();
        offer.term = newValue.term;
        offer.cover = newValue.cover;
        offer.premium = newValue.premium;
        this.offer$.next(offer);
        this.component.isValid = true;
        if (!isNullOrUndefined(this.change)) {
            this.change.emit(this.component.counter);
        }
    };
    CustomiseCoverComponent.prototype.incrementSlider = function (slider) {
        var newValue = Math.min(slider.value + slider.step, slider.max);
        slider.writeValue(newValue);
        this.onChange();
    };
    CustomiseCoverComponent.prototype.decrementSlider = function (slider) {
        var newValue = Math.max(slider.value - slider.step, slider.min);
        slider.writeValue(newValue);
        this.onChange();
    };
    CustomiseCoverComponent.prototype.buildPremiumRequest = function (sumAssured, term) {
        var request = new SimplePremiumRequest();
        request.productGroup = this.productGroup;
        request.productCode = this.in.product;
        request.age = this.in.age;
        request.gender = this.in.gender;
        request.smoker = this.in.smoker;
        request.bmiLoading = this.in.bmiLoading;
        if (term) {
            request.term = term;
        }
        request.sumAssured = sumAssured;
        return request;
    };
    CustomiseCoverComponent.prototype.newOfferVO = function () {
        var offer = new OfferVO();
        offer.personName = this.in.personName;
        offer.product = this.in.product;
        offer.productName = this.in.productName;
        offer.isWol = this.in.isWol;
        return offer;
    };
    return CustomiseCoverComponent;
}(Dynamic));
export { CustomiseCoverComponent };
var ChangeEvent = /** @class */ (function () {
    function ChangeEvent(term, cover) {
        this.term = term;
        this.cover = cover;
    }
    return ChangeEvent;
}());
