<dl-date-input
    [formControlRef]="textControl"
    [name] = "component.id"
    [placeholder]="component.name"
    [required]="true"
    [minAge]="extraInput.minAge"
    [maxAge]="extraInput.maxAge"
    [dateFormat]="extraInput.format"
    [tabIndex]="'1'"
    (dateChange)="dobChange($event)"
></dl-date-input>
