<div class="qq-modal" id="quickQuoteModal">
    <div mat-dialog-title class="dl-modal-header">
        <h4 class="dl-modal-title">
            {{benefit.name}}&nbsp;of&nbsp;<b class="text-primary" id="qq-cover-heading">{{benefit.cover | dl_currency:true:0}}</b>
            for&nbsp;<b class="text-primary" id="qq-premium-heading">{{benefit.premium | dl_currency:true:0}}</b>&nbsp;pm&nbsp;*
            <button type="button" class="close dl-pull-right right" tabindex="-1" (click)="closeResultModal()">
                &times;<span class="sr-only">Close</span>
            </button>
        </h4>
    </div>
    <mat-dialog-content>
        <div class="dl-modal-body">
            <benefit-slider [benefit]="benefit"></benefit-slider>
            <dynamic_qq_block [componentData]="componentData"></dynamic_qq_block>
            <div class="dl-container">
                <div class="dl-row row" *ngIf="!inputData.embedded">
                    <form [formGroup]="qqForm" novalidate>
                        <div class="dl-col-md-3 col m3 s12">
                            <mat-form-field class="dl-full-width" style="max-width: 100%">
                                <input
                                    matInput
                                    type="text"
                                    name="fullname"
                                    id="fullname"
                                    placeholder="Name"
                                    [formControl]="qqForm.controls['fullname']"
                                    [tabindex]="1">
                            </mat-form-field>
                        </div>
                        <div class="dl-col-md-5 col m5 s12">
                            <mat-form-field class="dl-full-width" style="max-width: 100%">
                                <input
                                    matInput
                                    type="email"
                                    id="email" placeholder="Email"
                                    name="email"
                                    [formControl]="qqForm.controls['email']"
                                    [tabindex]="1">
                                <mat-checkbox
                                    *ngIf="inputData.leadCapture"
                                    matSuffix
                                    [labelPosition]="'after'"
                                    style="padding-left: 10px"
                                    (click)="setNoEmail()">
                                    <b> No Email</b></mat-checkbox>
                                <mat-error align="start">Invalid email address</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="dl-col-md-4 col m4 s12">
                            <mat-form-field class="dl-full-width" style="max-width: 100%">
                                <input
                                    matInput
                                    phoneNumber
                                    name="phone_mobile"
                                    id="cell"
                                    type="tel"
                                    placeholder="Cell Number"
                                    [formControl]="qqForm.controls['cell']"
                                    [tabindex]="1">
                                <mat-error align="start">Format: (000) 000-0000</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="dl-col-md-12 col m12 s12">
                            <mat-form-field class="dl-full-width" style="max-width: 100%">
                                <input
                                    matInput
                                    comments
                                    name="comments"
                                    id="comments"
                                    type="text"
                                    placeholder="Any extra comments? (optional)"
                                    [formControl]="qqForm.controls['comments']"
                                    [tabindex]="1">
                            </mat-form-field>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="dl-modal-footer-no-border row">
            <div class="col m12 s12">
                <h4 class="text-center text-primary strong">Type of lead to create</h4>
            </div>
        </div>
        <div class="dl-modal-footer-no-border row"
             *ngIf="!success">
            <div class="col m6 s12">
                <button
                    *ngIf="!resShowWarning"
                    mat-raised-button
                    color="primary"
                    class="dl-full-width dl-action-btn"
                    type="button"
                    tabindex="1"
                    style="letter-spacing: 1px;"
                    (click)="continueFuneral()"
                    [attr.data-event]="'true'"
                    [attr.data-event-category]="'Quick Quote'"
                    [attr.data-event-name]="'Continue'"
                    [attr.data-event-label]="'Quick Quote Continue from result'"
                    [disabled]="busy">
                    Funeral&nbsp;
                    <i class="fa fa-circle-o-notch fa-spin" *ngIf="resLoading"></i>
                </button>
            </div>
            <div class="col m6 s12">
                <button
                    *ngIf="!resShowWarning"
                    mat-raised-button
                    color="primary"
                    class="dl-full-width dl-action-btn"
                    type="button"
                    tabindex="1"
                    style="letter-spacing: 1px;"
                    (click)="continueLife()"
                    [attr.data-event]="'true'"
                    [attr.data-event-category]="'Quick Quote'"
                    [attr.data-event-name]="'Continue'"
                    [attr.data-event-label]="'Quick Quote Continue from result'"
                    [disabled]="busy">
                    Life&nbsp;
                    <i class="fa fa-circle-o-notch fa-spin" *ngIf="resLoading"></i>
                </button>
            </div>
            <div class="dl-col-md-12 col s12">
                <p class="fine-print">*Estimate: complete the process to get an official quote. Not available
                    for all occupations.</p>
            </div>
        </div>
        <div class="dl-modal-footer-no-border row"
             *ngIf="success">
            <div class="dl-col-sm-12"></div>
            <div class="dl-col-sm-12 col m12 s12">
                <button
                    mat-raised-button
                    color="primary"
                    class="dl-full-width dl-action-btn"
                    type="button"
                    tabindex="1"
                    style="letter-spacing: 1px;"
                    (click)="closeResultModal()"
                    [attr.data-event]="'true'"
                    [attr.data-event-category]="'Quick Quote'"
                    [attr.data-event-name]="'Continue'"
                    [attr.data-event-label]="'Quick Quote Continue from result'">
                    Lead created&nbsp;<span class="fa fa-times"></span>
                </button>
            </div>
            <div class="dl-col-md-12 col s12">
                <p class="fine-print">*Estimate: complete the process to get an official quote. Not available
                    for all occupations.</p>
            </div>
        </div>
    </mat-dialog-content>
</div>
