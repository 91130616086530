import { ErrorVO } from '../base/error/error.vo';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorModalProvider } from '../base/error/error.modal.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../base/error/error.modal.service";
var httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
/**
 * Created by corneliusbotha on 2017/02/19.
 * Service for QQ rest calls.
 */
var QQService = /** @class */ (function () {
    function QQService(http, errorModalProvider) {
        this.http = http;
        this.errorModalProvider = errorModalProvider;
        this.qqPricingUrl = '/dl/data/pricing/quick';
        this.qqContinueToSPUrl = '/dl/data/needs/qq';
        this.qqExternalUrl = '/dl/data/needs/external-qq';
        this.qqPleaseCallMe = '/dl/data/needs/qqPleaseCall';
    }
    QQService.prototype.getPricing = function (req) {
        return this.http.post('/dl/data/needs/qq-price', JSON.stringify(req), httpOptions).pipe(catchError(this.handleError()));
    };
    QQService.prototype.getMlfPricing = function (data) {
        var url = '/dl/data/needs/simple-qq';
        return this.http.post(url, JSON.stringify(data), httpOptions).pipe(catchError(this.handleError()));
    };
    QQService.prototype.continueToSP = function (postData) {
        return this.http.post('/dl/data/needs/qq', JSON.stringify(postData), httpOptions).pipe(catchError(this.handleError()));
    };
    QQService.prototype.qqExternal = function (postData) {
        return this.http.post(this.qqExternalUrl, JSON.stringify(postData), httpOptions).pipe(catchError(this.handleError()));
    };
    QQService.prototype.requestCallMe = function () {
        return this.http.put('/dl/data/needs/qq-please-call', '', httpOptions).pipe(catchError(this.handleError()));
    };
    QQService.prototype.postInvalidReason = function (postData) {
        return this.http.post('/dl/data/needs/qq-error', JSON.stringify(postData), httpOptions).pipe(catchError(this.handleError()));
    };
    QQService.prototype.getForm = function (embedded, lead_id, campaign_code) {
        var data = {
            embedded: embedded,
            lead_id: lead_id,
            campaign_code: campaign_code
        };
        return this.http.post('/dl/data/needs/qq-form', data, httpOptions).pipe(catchError(this.handleError()));
    };
    QQService.prototype.handleError = function () {
        var _this = this;
        return function (error) {
            var vo = ErrorVO.toErrorVO(error);
            _this.errorModalProvider.showError(vo);
            return of(null);
        };
    };
    QQService.ngInjectableDef = i0.defineInjectable({ factory: function QQService_Factory() { return new QQService(i0.inject(i1.HttpClient), i0.inject(i2.ErrorModalProvider)); }, token: QQService, providedIn: "root" });
    return QQService;
}());
export { QQService };
var ProductsRequestData = /** @class */ (function () {
    function ProductsRequestData() {
    }
    return ProductsRequestData;
}());
