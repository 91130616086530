import {Dynamic} from './component';
import {FormControl} from '@angular/forms';
import {Component} from '@angular/core';
import {MatCheckboxChange} from '@angular/material';
import {isNullOrUndefined} from 'util';
import {DropDownValuesVO} from '../../base/select.vo';
import { PageComponentVO } from '../page.data.vo';

@Component({
    templateUrl: 'checkbox.group.component.html',
    styleUrls: ['checkbox.group.component.scss'],
    selector: 'checkbox-group'
})
export class CheckboxGroupComponent extends Dynamic<PageComponentVO> {

    checkItems: CheckBoxData[] = [];
    selectedItems: string[] = [];

    setupFromControl(): void {
        this.component.loaded = true;
        // tel my parent I'm loaded.
        this.loaded.emit(this.component.id);
    }

    getFormControl(): FormControl {
        return null;
    }

    processExtraData() {
        const extraInput = this.component.extraInput as DropDownValuesVO[];
        extraInput.forEach(item => {
            this.checkItems.push(
                {
                    id: item.id,
                    check: false,
                    display: item.display,
                    clearAll: item.clearAll,
                    info: item.info
                }
            );
        });
        if (this.selectedItems.length > 0) {
            this.component.isValid = true;
            this.change.emit(this.component.counter);
        }
    }

    itemChange(change: MatCheckboxChange, itemId: string) {
        const checkItem = this.checkItems.find(i => i.id === itemId);
        checkItem.check = change.checked;

        if (checkItem.clearAll && checkItem.check) {
            this.checkItems.forEach(i => {
                if (i.id !== checkItem.id) {
                    i.check = false;
                }
            });
        } else if (checkItem.check) {
            const clearAllItem = this.checkItems.filter(i => i.clearAll);
            if (!isNullOrUndefined(clearAllItem)) {
                clearAllItem.forEach(i => i.check = false);
            }
        }
        this.selectedItems = [];
        this.checkItems.forEach(i => {
            if (i.check) {
                this.selectedItems.push(i.id);
            }
        });
        this.component.value = this.selectedItems;
        this.component.isValid = this.selectedItems.length > 0;
        this.change.emit(this.component.counter);
    }
}

export interface CheckBoxData {
    id: string;
    check: boolean;
    display: string;
    clearAll: boolean;
    info: string;
}
