import {Component} from '@angular/core';
import {NumberValidationVO, PageComponentVO, RsaIdDetailVO} from '../page.data.vo';
import {isNullOrUndefined} from 'util';
import {RsaId, rsaIdValidation, RsaIdValidationCallback} from '../../base/rsa.id.validator';
import {FormControl, Validators} from '@angular/forms';
import {Dynamic} from './component';

@Component({
    selector: 'rsa-id-input',
    templateUrl: 'rsa.id.component.html'
})
export class RsaIdComponent extends Dynamic<PageComponentVO> implements RsaIdValidationCallback {

    rsaIdControl: FormControl = new FormControl('', [Validators.required, Validators.pattern('[0-9]*'), rsaIdValidation(false, this)]);

    /**
     * Populate the extra data we can get from the validator
     * @param {RsaId} rsaId
     */
    onIdValidationChange(rsaId: RsaId): void {
        let valueExtra = this.component.valueExtra as RsaIdDetailVO;
        if (isNullOrUndefined(valueExtra)) {
            valueExtra = new RsaIdDetailVO;
            this.component.valueExtra = valueExtra;
        }
        valueExtra.from(rsaId);
    }

    getFormControl(): FormControl {
        let min;
        let max;
        if (!isNullOrUndefined(this.component.extraInput)) {
            min = (this.component.extraInput as NumberValidationVO).min;
            max = (this.component.extraInput as NumberValidationVO).max;
        }
        this.rsaIdControl.setValidators([Validators.required, Validators.pattern('[0-9]*'), rsaIdValidation(false, this, min, max)]);
        return this.rsaIdControl;
    }

    onChange(): void {
        this.component.isValid = this.rsaIdControl.valid;
        this.component.value = this.rsaIdControl.value;
        if (!isNullOrUndefined(this.change)) {
            this.change.emit(this.component.counter);
        }
    }

    get errorMessage(): string {
        if (this.rsaIdControl.hasError('too_young')) {
            return 'Sorry too young';
        } else if (this.rsaIdControl.hasError('too_old')) {
            return 'Sorry too old';
        }
        return 'Not a valid RSA ID Number';
    }

}
