import {
  Component, ComponentFactoryResolver, EventEmitter, Injector, Input, Output, ReflectiveInjector, Type, ViewChild,
  ViewContainerRef
} from '@angular/core';
import {SgNavComponent} from './sg.nav.component';
import {PageInfoVO} from '../page.data.vo';
import {DlNavComponent} from './dl.nav.component';
import {DspService} from '../dsp.service';
import {isNullOrUndefined} from 'util';
/**
 * Created by corneliusbotha on 2017/07/18.
 */

export abstract class Navigation {

    @Output()
    navEvent: EventEmitter<PageInfoVO> = new EventEmitter();

    constructor(public dspService: DspService) {}

    setNavEventEmitter(emitter: EventEmitter<PageInfoVO>): void {
        this.navEvent = emitter;
    }

    takeMeHome() {
        let page = new PageInfoVO();
        page.continueValue = 'toHome';
        this.navEvent.emit(page);
    }

    contactMe() {
        if (isNullOrUndefined(this.dspService.resumeStorage)
                || this.dspService.currentData.pageName !== 'CONTACT_ME') {
            this.dspService.resumeStorage = this.dspService.currentData;
        }
        let page = this.dspService.currentData;
        page = PageInfoVO.copy(page);
        page.continueValue = 'toContactMe';
        this.navEvent.emit(page);
    }
}

@Component({
    selector: 'nav-bar',
    template: '<div #navContentContainer></div>'
})
export class NavComponent {
    @ViewChild('navContentContainer', {read: ViewContainerRef}) navContainer: ViewContainerRef;

    currentNav: any = null;

    @Output()
    navEvent: EventEmitter<PageInfoVO> = new EventEmitter();

    @Input()
    set navComp(data: {component: Type<Navigation>}) {
        if (!data) {
            return;
        }
        // We create a factory out of the component we want to create
        const factory = this.resolver.resolveComponentFactory(data.component);
        const injector = Injector.create({providers: [], parent: this.navContainer.parentInjector});
        // We create the component using the factory and the injector
        const component = factory.create(injector);
        component.instance.setNavEventEmitter(this.navEvent);
        // We insert the component into the dom container
        this.navContainer.insert(component.hostView);
        // We can destroy the old component by calling destroy
        if (this.currentNav) {
            this.currentNav.destroy();
        }
        this.currentNav = component;
    }

    constructor(private resolver: ComponentFactoryResolver) {}
}
