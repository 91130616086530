import {Directive, ElementRef, forwardRef, Renderer2} from '@angular/core';
import {AbstractControl, DefaultValueAccessor, NG_VALUE_ACCESSOR, ValidatorFn} from '@angular/forms';
import {environment} from '../../environments/environment';
import {AsYouType, CountryCode, parsePhoneNumber} from 'libphonenumber-js';
import {isNullOrUndefined} from 'util';
import {LocaleHelper} from './locale.helper';

const PHONE_NUMBER_INPUT_CONTROL = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PhoneNumberInputDirective),
    multi: true,
};

@Directive({
    host: {
        '(input)': 'onInput($event.target.value)',
        '(blur)': 'onTouched()'
    },
    selector: 'input[phoneNumber]',
    providers: [PHONE_NUMBER_INPUT_CONTROL]
})
export class PhoneNumberInputDirective extends DefaultValueAccessor {

    private _asYouType: AsYouType;

    constructor(renderer: Renderer2, private element: ElementRef) {
        super(renderer, element, true);
        this._asYouType = new AsYouType(LocaleHelper.getCountryCode() as CountryCode);
    }

    writeValue(value: any): void {
        const formatted = this.formatNumber(value);
        super.writeValue(formatted);
    }

    onInput(value: any) {
        if (!isNullOrUndefined(value) && typeof value === 'string') {
            const start = this.element.nativeElement.selectionStart;
            let moveCursor = false;
            if (start < value.length) {
                moveCursor = true;
            }
            const formatted = this.formatNumber(value);
            super.writeValue(formatted);
            this.onChange(formatted);
            if (moveCursor) {
                this.element.nativeElement.setSelectionRange(start, start);
            }
        }
    }

    private formatNumber(value: any): any {
        if (!isNullOrUndefined(value) && typeof value === 'string') {
            this._asYouType.reset();
            const number = this._asYouType.input(value);
            if (!isNullOrUndefined(number)) {
                return number;
            }
        }
        return value;
    }

}

export class PhoneNumberValidator {

    static validator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!isNullOrUndefined(control.value) && control.value !== '') {
                try {
                    const number = parsePhoneNumber(control.value, LocaleHelper.getCountryCode() as CountryCode);
                    if (!isNullOrUndefined(number) && number.isValid()) {
                        // ZA rules seem to be producing invalid numbers, make sure it is at least the standard 10 digits
                        if (LocaleHelper.getCountryCode() === 'ZA') {
                            if (!new RegExp(TelUtil.telPattern).test(number.number)) {
                                return {phoneInvalid: true};
                            }
                        }
                        return null;
                    } else {
                        return {phoneInvalid: true};
                    }
                } catch (e) {
                    return {phoneInvalid: true};
                }
            }
            // if nothing is entered regard the control as valid
            return null;
        };
    }

}

export class TelUtil {
    public static telPattern =
        '^(?:[0-9]{3}|\\+27\\d{2}?\\s?|\\(\\+27\\d{2}\\)\\s?|\\([0-9]{3}\\)\\s?)(?:[\\-\\s]?\\d{3})(?:[\\-\\s]?\\d{4})$';
}
