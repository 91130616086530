import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Component} from '@angular/core';

@Component({
    selector: 'p-header',
    template: '<ng-content></ng-content>'
})
export class HeaderComponent {}

@NgModule({
    imports: [CommonModule],
    exports: [HeaderComponent],
    declarations: [HeaderComponent]
})
export class SharedModule { }
