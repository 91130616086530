import {NgModule} from '@angular/core';
import {DonutComponent} from './donut.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';


@NgModule({
  declarations: [
    DonutComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule
  ],
  entryComponents: [
    DonutComponent
  ],
  exports: [
    DonutComponent
  ]
})
export class DonutModule {}
