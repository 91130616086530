/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./needs.pie.graph.component";
var styles_NeedsPieGraphComponent = [];
var RenderType_NeedsPieGraphComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NeedsPieGraphComponent, data: {} });
export { RenderType_NeedsPieGraphComponent as RenderType_NeedsPieGraphComponent };
export function View_NeedsPieGraphComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { chart: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["chart", 1]], null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_NeedsPieGraphComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "pie-graph", [], null, null, null, View_NeedsPieGraphComponent_0, RenderType_NeedsPieGraphComponent)), i0.ɵdid(1, 114688, null, 0, i1.NeedsPieGraphComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NeedsPieGraphComponentNgFactory = i0.ɵccf("pie-graph", i1.NeedsPieGraphComponent, View_NeedsPieGraphComponent_Host_0, { data: "data", width: "width", height: "height" }, {}, []);
export { NeedsPieGraphComponentNgFactory as NeedsPieGraphComponentNgFactory };
