var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Dynamic } from './component';
import { isNullOrUndefined } from 'util';
import { FormControl, Validators } from '@angular/forms';
import { DLUtil } from '../../base/dl.util';
var EmailComponent = /** @class */ (function (_super) {
    __extends(EmailComponent, _super);
    function EmailComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.emailControl = new FormControl('');
        return _this;
    }
    EmailComponent.prototype.onChange = function () {
        this.component.value = this.emailControl.value;
        this.component.isValid = this.emailControl.valid;
        if (!isNullOrUndefined(this.change)) {
            this.change.emit(this.component.counter);
        }
    };
    EmailComponent.prototype.getFormControl = function () {
        if (isNullOrUndefined(this.component.required) || this.component.required) {
            this.emailControl.setValidators([Validators.required, Validators.email, Validators.pattern(DLUtil.emailPattern)]);
        }
        else {
            this.emailControl.setValidators([Validators.email, Validators.pattern(DLUtil.emailPattern)]);
        }
        return this.emailControl;
    };
    return EmailComponent;
}(Dynamic));
export { EmailComponent };
