import {Pipe, PipeTransform} from '@angular/core';
import {DLUtil} from './dl.util';

@Pipe({name: 'dl_compact_number'})
export class DlCompactNumberPipe implements PipeTransform {

    transform(value: number, showFraction = false): string {
        return DLUtil.compactFormat(value, showFraction);
    }

}
