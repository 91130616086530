var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { OnInit } from '@angular/core';
import { InfoCardComponent } from './info-card.component';
import { AjaxService } from './ajax.service';
import { ParentEventService } from './component';
import { DspService } from '../dsp.service';
import { Log } from 'ng2-logger/browser';
import { AdditionalProductsModalData } from 'src/app/base/additional-products/additional-products-modal.component';
var log = Log.create('LlcAdditionalProductsComponent');
var LlcAdditionalProductsComponent = /** @class */ (function (_super) {
    __extends(LlcAdditionalProductsComponent, _super);
    function LlcAdditionalProductsComponent(ajaxService, dspService, parentEventService) {
        var _this = _super.call(this, parentEventService) || this;
        _this.ajaxService = ajaxService;
        _this.dspService = dspService;
        _this.dependants = [];
        _this.dependantProducts = [];
        _this.additionalProducts = [];
        return _this;
    }
    LlcAdditionalProductsComponent.prototype.ngOnInit = function () {
        this.additionalProducts = this.component.value.filter(function (addtionalProduct) {
            return addtionalProduct.code === 'LLC_LC' || addtionalProduct.code === 'LLC_AF' || addtionalProduct.code === 'LLC_PPPH';
        });
        this.dependantProducts = this.component.value.filter(function (dependantProduct) {
            return dependantProduct.id === 'LLC_F_S' || dependantProduct.id === 'LLC_F_C' || dependantProduct.id === 'LLC_F_E';
        });
        var indexOfAcceleratedFuneral = this.additionalProducts.findIndex(function (additionalProduct) { return additionalProduct.code === 'LLC_AF'; });
        if (this.additionalProducts.filter(function (additionalProduct) {
            return additionalProduct.code === 'LLC_LC' || additionalProduct.code === 'LLC_AF';
        }).length === 2) {
            this.additionalProducts[indexOfAcceleratedFuneral] = __assign({}, this.additionalProducts[indexOfAcceleratedFuneral], __assign({}, (new AdditionalProductsModalData()).product), { selected: true });
        }
        this.add(this.additionalProducts[indexOfAcceleratedFuneral], 'addon');
    };
    Object.defineProperty(LlcAdditionalProductsComponent.prototype, "getAdditionalProducts", {
        get: function () {
            return this.additionalProducts;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LlcAdditionalProductsComponent.prototype, "getdependantProducts", {
        get: function () {
            return this.dependantProducts;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LlcAdditionalProductsComponent.prototype, "getDependants", {
        get: function () {
            return this.dependants;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LlcAdditionalProductsComponent.prototype, "spId", {
        get: function () {
            log.info('SPID : ', this.dspService.currentData.spId);
            return this.dspService.currentData.spId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LlcAdditionalProductsComponent.prototype, "benefitId", {
        get: function () {
            log.info('BenefitID : ', this.dspService.currentData.benefitId);
            return this.dspService.currentData.benefitId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LlcAdditionalProductsComponent.prototype, "leadName", {
        get: function () {
            log.info('LeadName : ', this.dspService.currentData.leadName);
            return this.dspService.currentData.leadName;
        },
        enumerable: true,
        configurable: true
    });
    LlcAdditionalProductsComponent.prototype.add = function (product, type) {
        var _this = this;
        try {
            this.parentEventService.publishPageBusyStatus(true);
            this.ajaxService.postRequest({
                method: type === 'addon' ? 'createAddon' : 'updateDependantBenefit',
                data: type === 'addon' ? {
                    product: product
                } : {
                    dependant: product
                }
            }).subscribe(function (response) {
                switch (type) {
                    case 'addon':
                        {
                            var index = _this.additionalProducts.findIndex(function (additionalProduct) { return additionalProduct.code === response.product; });
                            var name_1 = _this.additionalProducts[index].name;
                            _this.additionalProducts[index] = Object.assign(_this.additionalProducts[index], response);
                            _this.additionalProducts[index].name = name_1;
                        }
                        break;
                    case 'dependant':
                        {
                            var index = _this.dependants.findIndex(function (dependant) { return dependant.id === response.id; });
                            index === -1 ? _this.dependants.push(response) : Object.assign(_this.dependants[index], response);
                            _this.dependantProducts = _this.dependantProducts.map(function (dependantProduct) {
                                if ((_this.dependants.reduce(function (counter, _a) {
                                    var product = _a.product;
                                    return product === 'LLC_F_S' ? counter += 1 : counter;
                                }, 0) === 1 && dependantProduct.id === 'LLC_F_S') ||
                                    (_this.dependants.reduce(function (counter, _a) {
                                        var product = _a.product;
                                        return product === 'LLC_F_C' ? counter += 1 : counter;
                                    }, 0) === 8 && dependantProduct.id === 'LLC_F_C') ||
                                    (_this.dependants.reduce(function (counter, _a) {
                                        var product = _a.product;
                                        return product === 'LLC_F_E' ? counter += 1 : counter;
                                    }, 0) === 4 && dependantProduct.id === 'LLC_F_E'))
                                    dependantProduct.disabled = true;
                                else
                                    dependantProduct.disabled = false;
                                return dependantProduct;
                            });
                        }
                        break;
                    default:
                        break;
                }
                _this.parentEventService.publishPageBusyStatus(false);
            });
        }
        catch (error) {
            log.error(error);
        }
    };
    LlcAdditionalProductsComponent.prototype.edit = function (main, dependant) {
        var method = 'updateDependantBenefit';
        if (main) {
            method = 'updateMainBenefit';
        }
        this.sendAjaxUpdateRequest(dependant, method);
    };
    LlcAdditionalProductsComponent.prototype.sendAjaxUpdateRequest = function (product, method) {
        var _this = this;
        this.parentEventService.publishPageBusyStatus(true);
        var req;
        if (product.code === 'LLC_AF' || product.code === 'LLC_PPPH') {
            req = {
                method: method,
                data: {
                    product: product
                }
            };
            this.ajaxService.postRequest(req).subscribe(function (res) {
                var index = _this.additionalProducts.findIndex(function (additionalProduct) { return additionalProduct.code === res.product; });
                var name = _this.additionalProducts[index].name;
                _this.additionalProducts[index] = Object.assign(_this.additionalProducts[index], res);
                _this.additionalProducts[index].name = name;
                _this.isBusy = false;
                _this.parentEventService.publishPageBusyStatus(_this.isBusy);
            }, function () {
                _this.isBusy = false;
                _this.parentEventService.publishPageBusyStatus(_this.isBusy);
            });
        }
        else {
            req = {
                method: method,
                data: {
                    dependant: product
                }
            };
            this.ajaxService.postRequest(req).subscribe(function (res) {
                var index = _this.dependants.findIndex(function (dependant) { return dependant.id === res.id; });
                index === -1 ? _this.dependants.push(res) : Object.assign(_this.dependants[index], res);
                _this.isBusy = false;
                _this.parentEventService.publishPageBusyStatus(_this.isBusy);
            }, function () {
                _this.isBusy = false;
                _this.parentEventService.publishPageBusyStatus(_this.isBusy);
            });
        }
    };
    LlcAdditionalProductsComponent.prototype.remove = function (id, type) {
        var _this = this;
        try {
            this.parentEventService.publishPageBusyStatus(true);
            this.ajaxService.postRequest({
                method: type === 'addon' ? 'removeAddon' : 'removeDependantBenefit',
                data: {
                    id: id
                }
            }).subscribe(function (res) {
                var index;
                switch (type) {
                    case 'addon':
                        {
                            index = _this.additionalProducts.findIndex(function (additionalProduct) { return additionalProduct.id === res.id; });
                            _this.additionalProducts[index].id = null;
                            _this.additionalProducts[index].selected = false;
                        }
                        break;
                    case 'dependant':
                        {
                            _this.dependants = _this.dependants.filter(function (dependant) {
                                return dependant.id !== id;
                            });
                        }
                        break;
                    default:
                        break;
                }
                _this.parentEventService.publishPageBusyStatus(false);
            });
        }
        catch (error) {
            log.error(error);
        }
    };
    return LlcAdditionalProductsComponent;
}(InfoCardComponent));
export { LlcAdditionalProductsComponent };
