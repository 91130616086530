import {Injector, LOCALE_ID, NgModule} from '@angular/core';
import {QqModule} from './qq/qq.module';
import {ContactBlockModule} from './contact-block/contact-block.module';
import {LoginModule} from './login/login.module';
import {QqComponent} from './qq/qq.component';
import {createCustomElement} from '@angular/elements';
import {ResetPasswordComponent} from './login/reset.password.component';
import {ContactBlockComponent} from './contact-block/contact-block.component';
import {LoginComponent} from './login/login.component';
import {LocaleHelper} from './base/locale.helper';
import {NeedsModule} from './needs/needs.module';
import {NeedsComponent} from './needs/needs.component';
import {DonutModule} from './donut/donut.module';
import {DonutComponent} from './donut/donut.component';
import {ContactBlockExpandedComponent} from './contact-block/contact-block-expanded/contact-block-expanded.component';
import {LimitedLifeQuickQuoteComponent} from './sp/limited-life-cover-qq/limited-life-quick-quote.component';

@NgModule({
    imports: [
        QqModule,
        ContactBlockModule,
        LoginModule,
        NeedsModule,
        DonutModule
    ],
    providers: [{
        provide: LOCALE_ID, useFactory: () => LocaleHelper.getCurrentLocale()
    }]
})
export class DlElementsModule {

    constructor(private injector: Injector) {
        console.log('DlElementsModule');
        LocaleHelper.registerLocales();
    }

    ngDoBootstrap() {
        console.log('DlElementsModule ngDoBootstrap');
        const QQElement = createCustomElement(QqComponent, {injector: this.injector});
        customElements.define('quick-quote', QQElement);
        const CbElement = createCustomElement(ContactBlockComponent, {injector: this.injector});
        customElements.define('contact-block', CbElement);
        const CbExpandedElement = createCustomElement(ContactBlockExpandedComponent, {injector: this.injector});
        customElements.define('contact-block-expanded', CbExpandedElement);
        const loginElement = createCustomElement(LoginComponent, {injector: this.injector});
        customElements.define('dl-login', loginElement);
        const resetPasswordElement = createCustomElement(ResetPasswordComponent, {injector: this.injector});
        customElements.define('dl-reset-password', resetPasswordElement);
        const needsElement = createCustomElement(NeedsComponent, {injector: this.injector});
        customElements.define('needs-calculator', needsElement);
        const donutElement = createCustomElement(DonutComponent, {injector: this.injector});
        customElements.define('donut-graph', donutElement as any);
        const llcQQElement = createCustomElement(LimitedLifeQuickQuoteComponent, {injector: this.injector});
        customElements.define('limited-life-quick-quote', llcQQElement as any);
    }
}
