var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { RsaIdDetailVO } from '../page.data.vo';
import { isNullOrUndefined } from 'util';
import { rsaIdValidation } from '../../base/rsa.id.validator';
import { FormControl, Validators } from '@angular/forms';
import { Dynamic } from './component';
var RsaIdComponent = /** @class */ (function (_super) {
    __extends(RsaIdComponent, _super);
    function RsaIdComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.rsaIdControl = new FormControl('', [Validators.required, Validators.pattern('[0-9]*'), rsaIdValidation(false, _this)]);
        return _this;
    }
    /**
     * Populate the extra data we can get from the validator
     * @param {RsaId} rsaId
     */
    RsaIdComponent.prototype.onIdValidationChange = function (rsaId) {
        var valueExtra = this.component.valueExtra;
        if (isNullOrUndefined(valueExtra)) {
            valueExtra = new RsaIdDetailVO;
            this.component.valueExtra = valueExtra;
        }
        valueExtra.from(rsaId);
    };
    RsaIdComponent.prototype.getFormControl = function () {
        var min;
        var max;
        if (!isNullOrUndefined(this.component.extraInput)) {
            min = this.component.extraInput.min;
            max = this.component.extraInput.max;
        }
        this.rsaIdControl.setValidators([Validators.required, Validators.pattern('[0-9]*'), rsaIdValidation(false, this, min, max)]);
        return this.rsaIdControl;
    };
    RsaIdComponent.prototype.onChange = function () {
        this.component.isValid = this.rsaIdControl.valid;
        this.component.value = this.rsaIdControl.value;
        if (!isNullOrUndefined(this.change)) {
            this.change.emit(this.component.counter);
        }
    };
    Object.defineProperty(RsaIdComponent.prototype, "errorMessage", {
        get: function () {
            if (this.rsaIdControl.hasError('too_young')) {
                return 'Sorry too young';
            }
            else if (this.rsaIdControl.hasError('too_old')) {
                return 'Sorry too old';
            }
            return 'Not a valid RSA ID Number';
        },
        enumerable: true,
        configurable: true
    });
    return RsaIdComponent;
}(Dynamic));
export { RsaIdComponent };
