import {Component, OnInit} from '@angular/core';
import {InfoCardComponent} from './info-card.component';
import {AjaxVO} from '../page.data.vo';
import {AjaxService} from './ajax.service';
import {ParentEventService} from './component';
import {BaseAdditionalProductsComponent} from '../../base/additional-products/base-additional-products.component';
import {DspService} from '../dsp.service';
import {DLUtil} from '../../base/dl.util';
import {AdditionalProductVO} from '../../base/additional-products/additional-products.service';
import {Log} from 'ng2-logger/browser';
import { AdditionalProductsModalData } from 'src/app/base/additional-products/additional-products-modal.component';
import { LLCDependantVO } from 'src/app/base/llc-summary/llc-summary.service';
import { MLFDependantVO } from 'src/app/base/mlf-summary/mlf-summary.service';
import { MLFModalData } from 'src/app/base/mlf-summary/mlf-dependant-modal.component';
import { isNullOrUndefined } from 'util';
import { DropDownValuesVO } from 'src/app/base/select.vo';

const log = Log.create('LlcAdditionalProductsComponent');

@Component({
    templateUrl: 'llc-additional-products.component.html',
    selector: 'llc-additional-products',
    styleUrls: ['./info-card.component.scss']
})
export class LlcAdditionalProductsComponent extends InfoCardComponent implements OnInit {
    public dependants: LLCDependantVO[] = [];
    public dependantProducts: DropDownValuesVO[] = [];
    public additionalProducts: AdditionalProductVO[] = [];

    constructor(private ajaxService: AjaxService,
                private dspService: DspService,
                parentEventService: ParentEventService) {
        super(parentEventService);
    }
    ngOnInit(): void {
        this.additionalProducts = (this.component.value as AdditionalProductVO[]).filter((addtionalProduct: AdditionalProductVO) => {
            return addtionalProduct.code === 'LLC_LC' || addtionalProduct.code === 'LLC_AF' || addtionalProduct.code === 'LLC_PPPH';
        });
        this.dependantProducts = (this.component.value as DropDownValuesVO[]).filter((dependantProduct: DropDownValuesVO) => {
            return dependantProduct.id === 'LLC_F_S' || dependantProduct.id === 'LLC_F_C' || dependantProduct.id === 'LLC_F_E';
        });
        
        const indexOfAcceleratedFuneral: number = this.additionalProducts.findIndex((additionalProduct: AdditionalProductVO) => additionalProduct.code === 'LLC_AF');

        if (this.additionalProducts.filter((additionalProduct: AdditionalProductVO) => {
            return additionalProduct.code === 'LLC_LC' || additionalProduct.code === 'LLC_AF';
        }).length === 2) {
            this.additionalProducts[indexOfAcceleratedFuneral] = {
                ...this.additionalProducts[indexOfAcceleratedFuneral], ...{ ...(new AdditionalProductsModalData()).product },
                selected: true
            };
        }

        this.add(this.additionalProducts[indexOfAcceleratedFuneral], 'addon');
    }

    get getAdditionalProducts(): AdditionalProductVO[] {
        return this.additionalProducts;
    }

    get getdependantProducts(): DropDownValuesVO[] {
        return this.dependantProducts;
    }
    
    get getDependants(): LLCDependantVO[] {
        return this.dependants;
    }

    get spId(): string {
        log.info('SPID : ', this.dspService.currentData.spId);
        return this.dspService.currentData.spId;
    }

    get benefitId(): string {
        log.info('BenefitID : ', this.dspService.currentData.benefitId);
        return this.dspService.currentData.benefitId;
    }

    get leadName(): string {
        log.info('LeadName : ', this.dspService.currentData.leadName);
        return this.dspService.currentData.leadName;
    }

    add(product: AdditionalProductVO | LLCDependantVO, type: string): void {
        try {
            this.parentEventService.publishPageBusyStatus(true);

            this.ajaxService.postRequest({
                method: type === 'addon' ? 'createAddon' : 'updateDependantBenefit',
                data: type === 'addon' ? {
                    product
                } : {
                    dependant: product
                }
            }).subscribe((response) => {
                switch (type) {
                    case 'addon': {
                        const index: number = this.additionalProducts.findIndex((additionalProduct: AdditionalProductVO) => additionalProduct.code === response.product);
                        const name: string = this.additionalProducts[index].name;
                        
                        this.additionalProducts[index] = Object.assign(this.additionalProducts[index], response);

                        this.additionalProducts[index].name = name;
                    }
                        break;
                    case 'dependant': {
                        const index: number = this.dependants.findIndex((dependant: LLCDependantVO) => dependant.id === response.id);

                        index === -1 ? this.dependants.push(response) : Object.assign(this.dependants[index], response);

                        this.dependantProducts = this.dependantProducts.map((dependantProduct: DropDownValuesVO) => {
                            if (
                                (this.dependants.reduce((counter, { product }) => product === 'LLC_F_S' ? counter += 1 : counter, 0) === 1 && dependantProduct.id === 'LLC_F_S') ||
                                (this.dependants.reduce((counter, { product }) => product === 'LLC_F_C' ? counter += 1 : counter, 0) === 8 && dependantProduct.id === 'LLC_F_C') ||
                                (this.dependants.reduce((counter, { product }) => product === 'LLC_F_E' ? counter += 1 : counter, 0) === 4 && dependantProduct.id === 'LLC_F_E')
                            )
                                dependantProduct.disabled = true;
                            else 
                                dependantProduct.disabled = false;

                            return dependantProduct;
                        });
                    }
                        break;
                    default:
                        break;
                }

                this.parentEventService.publishPageBusyStatus(false);
            });
        } catch (error) {
            log.error(error);
        }
    }

    edit(main: boolean, dependant: LLCDependantVO): void {
        let method = 'updateDependantBenefit';
        if (main) {
            method = 'updateMainBenefit';
        }
        this.sendAjaxUpdateRequest(dependant, method);
    }

    private sendAjaxUpdateRequest(product: AdditionalProductVO | LLCDependantVO, method: string) {
        this.parentEventService.publishPageBusyStatus(true);
        let req: AjaxVO;

        if ((product as AdditionalProductVO).code === 'LLC_AF' || (product as AdditionalProductVO).code === 'LLC_PPPH') {
            req = {
                method,
                data: {
                    product
                }
            }
            
            this.ajaxService.postRequest(req).subscribe((res) => {
                const index: number = this.additionalProducts.findIndex((additionalProduct: AdditionalProductVO) => additionalProduct.code === res.product);
                const name: string = this.additionalProducts[index].name;
                
                this.additionalProducts[index] = Object.assign(this.additionalProducts[index], res);

                this.additionalProducts[index].name = name;

                this.isBusy = false;
                this.parentEventService.publishPageBusyStatus(this.isBusy);
            }, () => {
                this.isBusy = false;
                this.parentEventService.publishPageBusyStatus(this.isBusy);
            });
        } else {
            req = {
                method,
                data: {
                    dependant: product
                }
            }

            this.ajaxService.postRequest(req).subscribe((res) => {
                const index: number = this.dependants.findIndex((dependant: LLCDependantVO) => dependant.id === res.id);

                index === -1 ? this.dependants.push(res) : Object.assign(this.dependants[index], res);

                this.isBusy = false;
                this.parentEventService.publishPageBusyStatus(this.isBusy);
            }, () => {
                this.isBusy = false;
                this.parentEventService.publishPageBusyStatus(this.isBusy);
            });
        }
    }

    remove(id: string, type: string) {
        try {
            this.parentEventService.publishPageBusyStatus(true);

            this.ajaxService.postRequest({
                method: type === 'addon' ? 'removeAddon' : 'removeDependantBenefit',
                data: {
                    id
                }
            }).subscribe((res) => {
                let index: number;

                switch (type) {
                    case 'addon':{
                        index = this.additionalProducts.findIndex((additionalProduct: AdditionalProductVO) => additionalProduct.id === res.id);

                        this.additionalProducts[index].id = null;
                        this.additionalProducts[index].selected = false;
                    }
                        break;
                    case 'dependant':{
                        this.dependants = this.dependants.filter((dependant: LLCDependantVO) => {
                            return dependant.id !== id;
                        });
                    }
                        break;
                    default:
                        break;
                }

                this.parentEventService.publishPageBusyStatus(false);
            });
        } catch (error) {
            log.error(error);
        }
    }
}
