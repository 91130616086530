var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Dynamic } from './component';
import { FormControl } from '@angular/forms';
import { MatchMediaService } from '../../base/match.media.service';
import { DateAdapter } from '@angular/material/core';
import { DateValidationVO } from '../page.data.vo';
import { LocaleHelper } from '../../base/locale.helper';
import { DLUtil } from '../../base/dl.util';
var DatePickerComponent = /** @class */ (function (_super) {
    __extends(DatePickerComponent, _super);
    function DatePickerComponent(matchMedia, adapter) {
        var _this = _super.call(this) || this;
        _this.matchMedia = matchMedia;
        _this.adapter = adapter;
        _this.dateControl = new FormControl('');
        _this._dateValidationVO = new DateValidationVO();
        _this.adapter.setLocale(LocaleHelper.getCountryCode());
        return _this;
    }
    Object.defineProperty(DatePickerComponent.prototype, "dateValidationVO", {
        get: function () {
            return this._dateValidationVO;
        },
        set: function (val) {
            this._dateValidationVO = val;
        },
        enumerable: true,
        configurable: true
    });
    DatePickerComponent.prototype.onChange = function () {
        if (this.dateControl.value !== undefined && this.dateControl.value !== null && this.dateControl.value !== '') {
            var date = new Date(this.dateControl.value);
            this.component.value = DLUtil.formatDateToString(date);
        }
        else {
            this.component.value = '';
        }
        this.component.isValid = this.dateControl.valid;
        if (this.change !== null && this.change !== undefined) {
            this.change.emit(this.component.counter);
        }
    };
    DatePickerComponent.prototype.getFormControl = function () {
        return this.dateControl;
    };
    DatePickerComponent.prototype.setInitialValue = function () {
        if (this.component.initValue !== undefined && this.component.initValue !== null && this.component.initValue !== '') {
            var date = new Date(this.component.initValue);
            this.dateControl.setValue(date);
        }
        this.dateValidationVO = this.component.extraInput;
        if (this.dateValidationVO.pickerStartView === undefined || this.dateValidationVO.pickerStartView === null) {
            this.dateValidationVO.pickerStartView = 'month';
        }
    };
    return DatePickerComponent;
}(Dynamic));
export { DatePickerComponent };
