import {PipeTransform, Pipe} from '@angular/core';
import {getLocaleCurrencySymbol} from '@angular/common';
import {LocaleHelper} from './locale.helper';
/**
 * A currency formatter to use a given Symbol n2 default formatter no longer supports it.
 */

@Pipe({name: 'dl_currency'})
export class DLCurrencyPipe implements PipeTransform {

    private readonly DECIMAL_SEPARATOR: string;
    private readonly THOUSANDS_SEPARATOR: string;

    constructor() {
        this.DECIMAL_SEPARATOR = '.';
        this.THOUSANDS_SEPARATOR = ',';
    }

    /**
     * Transform the number
     * @param value
     * @param showSymbol show currency symbol
     * @param fractionSize places after the .
     * @returns {string}
     */
    transform(value: number | string, showSymbol = false, fractionSize = 2): string {
        const theFloat = parseFloat((value || '0').toString());
        const formattedNumber = theFloat.toFixed(fractionSize);
        // noinspection TypeScriptValidateTypes
        let [integer, fraction = ''] = formattedNumber.split(this.DECIMAL_SEPARATOR);

        let output = '';
        if (showSymbol) {
            output = getLocaleCurrencySymbol(LocaleHelper.getCurrentLocale());
        }

        if (fractionSize > 0) {
            fraction = this.DECIMAL_SEPARATOR + fraction;
        }

        integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR);
        if (integer.length === 0) {
            integer = '0';
        }

        output += integer + fraction;

        return output;
    }

}
