/**
 * Confirmation info component.
 */
import {Component, Inject, Injector, NgZone, ViewChild} from '@angular/core';
import {SpPage} from '../sp-page';
import {ConfirmationPageData, ConfirmationService} from './confirmation.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatTabGroup} from '@angular/material';
import {Log} from 'ng2-logger/browser';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {WebStep} from '../sp-routing.module';
import {PasswordMatchValidator} from '../../base/passwordMatch.validator';
import {isNullOrUndefined} from 'util';
import {LoginService, SignInProcessor} from '../../login/login.service';
import {WindowRef} from '../../base/window.ref';
import {NameValue} from '../../base/select.vo';

const log = Log.create(WebStep.CONFIRMATION);

export interface DialogData {
    title: string;
    notes: string;
}

export interface MoveIpDialogData {
    dates: NameValue[];
}

@Component({
    selector: 'confirmation',
    templateUrl: './confirmation.component.html'
})
export class ConfirmationComponent extends SpPage<any> implements SignInProcessor {

    @ViewChild('tabGroup')
    tabGroup: MatTabGroup;
    formGroup: FormGroup;
    data: ConfirmationPageData;
    showSetPassword: boolean = true;
    buttonText: string = 'Set Password';
    formSubmitted: boolean = false;
    showCaseCreationOptions: boolean = false;
    addressNotes = '';
    caseNotes = '';
    newIpDate = '';

    constructor(
            injector: Injector,
            private confirmationService: ConfirmationService,
            private loginService: LoginService,
            private winRef: WindowRef,
            private ngZone: NgZone,
            public dialog: MatDialog) {
        super(injector);
        this.data = new ConfirmationPageData();
        this.formGroup = new FormGroup({
            password: new FormControl('', [
                Validators.required,
                Validators.pattern(PasswordMatchValidator.passwordPattern()),
            ]),
            confirmPassword: new FormControl('', [Validators.required]),
            reason: new FormControl(),
            createPostDocCase: new FormControl(false),
            createPostSaleCase: new FormControl(false),
            createMoveOfIpCase: new FormControl(false)
        }, PasswordMatchValidator.matchPasswords());
        this.winRef.nativeWindow.spLoginWrapper = {component: this, zone: this.ngZone};
    }

    setPayload(data: ConfirmationPageData): void {
        this.setTitle(WebStep.CONFIRMATION);
        this.data = data;
        if  (!data.set_password || this.spDataService.isExternal() || this.spDataService.isEmbedded()) {
            this.showSetPassword = false;
            this.buttonText = 'Save';
            this.formGroup.get('password').disable();
            this.formGroup.get('confirmPassword').disable();
        }
        if (this.spDataService.isEmbedded()) {
            this.showCaseCreationOptions = true;
        }
    }

    onPostDocsChanged(): void {
        log.info('post doc clicked');
        if (this.getBooleanValueFromForm('createPostDocCase')) {
            log.info('post doc clicked and true');
            const dialogRef = this.dialog.open(NotesDialogComponent, {
                disableClose: true,
                position: {top: '20px'},
                maxWidth: '99vw',
                panelClass: 'dl-modal',
                data: {title: 'Please specify the address', notes: ''}
            });

            dialogRef.afterClosed().subscribe(result => {
                console.log('The dialog was closed: ' + result);
                this.addressNotes = 'Address: \n' + result;
            });
        }
    }

    onPostSaleCaseChanged(): void {
        log.info('post sale clicked');
        if (this.getBooleanValueFromForm('createPostSaleCase')) {
            log.info('post sale clicked and true');
            const dialogRef = this.dialog.open(NotesDialogComponent, {
                disableClose: true,
                position: {top: '20px'},
                maxWidth: '99vw',
                panelClass: 'dl-modal',
                data: {title: 'What does the client need assistance with?', notes: ''}
            });

            dialogRef.afterClosed().subscribe(result => {
                console.log('The dialog was closed: ' + result);
                this.caseNotes = result;
            });
        }
    }

    getWebStep(): WebStep {
        return WebStep.CONFIRMATION;
    }

    hasExitDisclosures(): boolean  {
        return false;
    }

    continueToNext(): void {
        this.formGroup.disable();
        this.spDataService.continueBusy = true;
        this.formSubmitted = false;
        if (this.isReasonPopulated()) {
            this.confirmationService.saveReason(this.formGroup.get('reason').value).subscribe(() => {
                if (this.showSetPassword) {
                    this.setPassword();
                } else {
                    this.spDataService.continueBusy = false;
                    this.formSubmitted = true;
                }
            });
        } else if (this.showSetPassword) {
            this.setPassword();
        }

        if (this.getBooleanValueFromForm('createPostDocCase')) {
            log.info('creating post doc case');
            this.confirmationService.createPostDocCase(this.addressNotes).subscribe(() => {
                log.info('post doc case created');
            });
        }

        if (this.getBooleanValueFromForm('createPostSaleCase')) {
            log.info('creating post sale case');
            this.confirmationService.createPostSaleServiceCase(this.caseNotes).subscribe(() => {
                log.info('post sale case created');
            });
        }
    }

    private getBooleanValueFromForm(formKey: string): boolean {
        return this.formGroup.get(formKey).value as boolean;
    }

    private setPassword(): void {
        this.loginService.setPassword({
            action: 'set',
            sales_process_id: this.spDataService.getSalesProcessID(),
            idnumber: this.data.id_number,
            username: this.data.username,
            password: this.formGroup.get('password').value,
        }).subscribe(res => {
            log.info('set password result', res);
            this.spDataService.continueBusy = false;
            this.formSubmitted = true;
            if (res.success) {
                log.info('successfully set password, calling signin');
                this.signin(this.data.username, this.formGroup.get('password').value);
            } else if (!isNullOrUndefined(res.message)) {
                log.info('Error setting password', res.message);
            }
        });
    }

    private signin(userName: string, password: string) {
        this.loginService.checkUser(userName, password).subscribe(res => {
            if (!isNullOrUndefined(this.spDataService.getPageData().baseAuthUrl)
                    && this.spDataService.getPageData().baseAuthUrl.length > 1) {
                this.loginService.loginToOrg(
                    this.spDataService.getPageData().baseAuthUrl,
                    this.spDataService.getPageData().oauthId, userName, password, this)
                        .subscribe(() => {},
                            () => {
                                this.processSignIn(false, 'Error communicating to the login server, please try again');
                        });
            } else {
                // SG sign in.
                this.processSignIn(true);
            }
        }, () => {
            this.processSignIn(false, 'Error communicating to the login server, please try again');
        });
    }

    processSignIn(success: boolean, message?: string) {
        if (success) {
            log.info('redirect to profile');
            this.winRef.nativeWindow.location = '/dl/profile';
        } else {
            log.info('there was an error', message);
            this.spDataService.continueBusy = false;
        }
    }

    getExitDisclosureData(): any {
        return null;
    }

    getDisclosureTitle(type: string): string {
        return null;
    }

    disableContinue(): boolean {
        if (this.formGroup.invalid || this.formSubmitted) {
            return true;
        }
        // if password disabled there must be a value in the reason
        return (this.formGroup.get('password').disabled && !this.isReasonPopulated());
    }

    private isReasonPopulated(): boolean {
        return !isNullOrUndefined(this.formGroup.get('reason').value) && this.formGroup.get('reason').value.length > 0;
    }

    get passwordHelpText(): string {
        return PasswordMatchValidator.passwordPatternHelpText();
    }

    get embeded(): boolean {
        return this.spDataService.isExternal() || this.spDataService.isEmbedded();
    }

    get hasDifferentOrg(): boolean {
        return !isNullOrUndefined(this.data.org_opt_in);
    }

}

@Component({
    selector: 'notes-dialog',
    templateUrl: 'notes-dialog.html',
})
export class NotesDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<NotesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

    onNoClick(): void {
        this.dialogRef.close();
    }

}
