import {registerLocaleData} from '@angular/common';
import localeZA from '@angular/common/locales/en-ZA';
import localeGB from '@angular/common/locales/en-GB';
import {isUndefined} from 'util';

export class LocaleHelper {

    public static defaultLocaleId = 'en-ZA';

    public static implementedLocales = [
        {id: 'en-ZA', countryCode: 'ZA', locale: localeZA},
//        {id: 'en-GB', countryCode: 'GB', locale: localeGB},
    ];

    public static registerLocales(): void {
        LocaleHelper.implementedLocales.forEach(locale => {
            registerLocaleData(locale.locale, locale.id);
        });
    }

    public static getCurrentLocale(): string {
        // Retrieve localeId from the url if any; otherwise, default to 'en-ZA'.
        // The first time the app loads, check the browser language.
        if (localStorage) {
            const storedLocaleId = <string>localStorage.getItem('__diffLocaleId');
            if (storedLocaleId != null) {
                return storedLocaleId;
            }
        }
        if (window['__diffLocaleId']) {
            return window['__diffLocaleId'];
        }
        const loc = LocaleHelper.implementedLocales.find(locale => {
            return navigator.language === locale.id;
        });
        if (!isUndefined(loc)) {
            return loc.id;
        }
        return this.defaultLocaleId;
    }

    static getCountryCode(): string {
        const currentLocale = LocaleHelper.getCurrentLocale();
        const loc = LocaleHelper.implementedLocales.find(locale => {
            return currentLocale === locale.id;
        });
        return isUndefined(loc) ? 'ZA' : loc.countryCode;
    }
}
