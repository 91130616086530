/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./sg.result.block.component";
var styles_SGResultBlockComponent = [];
var RenderType_SGResultBlockComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SGResultBlockComponent, data: {} });
export { RenderType_SGResultBlockComponent as RenderType_SGResultBlockComponent };
export function View_SGResultBlockComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [], null, null, null, null, null))], null, null); }
export function View_SGResultBlockComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sg-result-block", [], null, null, null, View_SGResultBlockComponent_0, RenderType_SGResultBlockComponent)), i0.ɵdid(1, 49152, null, 0, i1.SGResultBlockComponent, [], null, null)], null, null); }
var SGResultBlockComponentNgFactory = i0.ɵccf("sg-result-block", i1.SGResultBlockComponent, View_SGResultBlockComponent_Host_0, {}, {}, []);
export { SGResultBlockComponentNgFactory as SGResultBlockComponentNgFactory };
